import './fonts/fonts.scss'
import './styles/App.scss'
import './awsSetupConfig' // eslint-disable-line
import webSocketInstance from './WebSocketService' // eslint-disable-line
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { BrowserRouter, HashRouter, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import * as Sentry from '@sentry/browser'
import { Layout } from 'antd'
import { Trans } from '@lingui/macro'

import { withLDProvider } from 'launchdarkly-react-client-sdk';

// Redux
import { Provider, useDispatch } from 'react-redux'

import { PersistenceProvider } from '@top-legal/datastore'

import PreLoadData from './PreLoadData'
import { GetHistory, historyPrivate } from './BrowserHistory'

// components
import { ErrorPageNoRouter } from './components/Error/ErrorPage'
import EmptyHeader from './components/Layouts/EmptyHeader'
import Routes from './Routes'
import useMediaQueries from './hooks/useMediaQueries'
import HubspotOverrides from './HubspotOverrides'
import { Device, DeviceContext, RemToPxContext, ScrollbarWidthContext } from './GlobalContext'
import { store } from './ReduxStore'
import { UserAndCompanyDataContextProvider } from './components/Layouts/Constants'
import { useAdditionalProviders } from './AdditionalProviders'


// setting the warn level for the amplify logger
if (process.env.NODE_ENV === 'development') {
  (window as any).LOG_LEVEL = 'DEBUG'
} else {
  (window as any).LOG_LEVEL = 'ERROR'
}


webSocketInstance.initWebSocket()


//  Add segment analysis
const pageViewed = () => {
  if ((window as any).analytics && (window as any).analytics.page) {
    (window as any).analytics.page()
  }
}
historyPrivate.listen(pageViewed)
pageViewed()

if (process.env.NODE_ENV === 'development') {
  if (!(historyPrivate.push as any).__GUARD__) {
    const { push } = historyPrivate
    const wrappedPush = (...args) => {
      console.log('History push by:') // eslint-disable-line
      console.trace() // eslint-disable-line
      ;(push as any)(...args)
    }
    ;(wrappedPush as any).__GUARD__ = true
    historyPrivate.push = wrappedPush
  }
  if (!(historyPrivate.replace as any).__GUARD__) {
    const { replace } = historyPrivate
    const wrappedReplace = (...args) => {
      console.log('History replace by:') // eslint-disable-line
      console.trace() // eslint-disable-line
      ;(replace as any)(...args)
    }
    ;(wrappedReplace as any).__GUARD__ = true
    historyPrivate.replace = wrappedReplace
  }
}

//  Init sentry only when we deploy to Dev or Prod not on localhost
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://11ac309aca804f979f48e3fb0525df49@sentry.io/1419958',
    ...(process.env.SENTRY_CONFIG as any),
    //  Disable shitty UnHandledPromiseRejection errors
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    integrations: [new Sentry.Integrations.GlobalHandlers({ onunhandledrejection: false })]
  })
}

// ==================================================================================================

/**
 * Create a asyncForEach function helper
 * @param callback the forEach callback function
 * @returns {Promise<any>}
 * @usage await [1, 2, 3].asyncForEach(async (number) => { await dbRequest(...) })
 */
// eslint-disable-next-line
Object.defineProperty(Array.prototype, 'asyncForEach', {
  value (callback) {
    if (typeof callback !== 'function') {
      throw Error('callback must be a function for asyncForEach')
    }
    return Promise.all(this.map(callback))
  },
  configurable: true,
  writable: true
})

//= =================================================================================================

const ErrorPageNoRouterAny = ErrorPageNoRouter as any

const App: React.FC<{ useHashRouter?: boolean }> = ({ useHashRouter }) => {
  useMemo(() => {
    (window as any).APP_VERSION = process.env.APP_VERSION
  }, [])

  useAdditionalProviders()

  //  Compute scrollbarWidth and save in state + add the current device class to body
  const [scrollbarWidth, setScrollbarWidth] = useState(0)
  const [remToPx, setRemToPx] = useState(0)
  const device = useMediaQueries<Device>(['(max-width: 767px)', '(max-width: 1200px)'], ['phone', 'tablet'], 'desktop')
  useLayoutEffect(() => {
    //  Updating body class to having device class also for modals
    ['phone', 'tablet', 'desktop'].forEach(cls => document.body.classList.remove(cls))
    document.body.classList.add(device)

    //  Add browser info in style
    document.body.className += ` ${(window as any).get_browser_info()}`.toLowerCase()

    //  Update the scrollbarWidth
    const div = document.createElement('div')
    div.style.overflowY = 'scroll'
    document.body.appendChild(div)
    setTimeout(() => {
      const width = div.offsetWidth - div.clientWidth
      setScrollbarWidth(width)
      document.body.style.setProperty('--scrollbar-width', `${width}px`)
      div.remove()
    })
  }, [device])

  useLayoutEffect(() => {
    let timeout
    const handler = () => {
      timeout = undefined
      const remRatio = parseFloat(getComputedStyle(document.documentElement).fontSize)
      setRemToPx(remRatio)
      document.body.style.setProperty('--rem-to-px', `${remRatio}`)
    }

    const timeoutHandler = () => {
      if (timeout) {
        clearTimeout(timeout)
      }
      setTimeout(handler, 200)
    }

    window.addEventListener('resize', timeoutHandler)
    timeoutHandler()
    return () => window.removeEventListener('resize', timeoutHandler)
  }, [])

  const Router = useHashRouter ? HashRouter : BrowserRouter
  return (
    <PersistenceProvider
      gqlEndpointUrl={process.env.GQL_ENDPOINT as string}
      indexedDbNotSupported={(
        <Layout>
          <EmptyHeader />
          <ErrorPageNoRouterAny
            description={(
              <Trans>
                The application cannot be started.<br />
                Your current browser do not provide a proper storage system for top.legal.<br />
                <i>Are you in private navigation mode?</i>
              </Trans>
            )}
            noButton
          />
        </Layout>
      )}
      onReady={useCallback(() => { (window as any)._appMounted = true }, [])}
    >
      <RemToPxContext.Provider value={remToPx}>
        <DeviceContext.Provider value={device}>
          <ScrollbarWidthContext.Provider value={scrollbarWidth}>
            <Provider store={store}>
              <Router history={historyPrivate}>
                <GetHistory />
                <PreLoadData />
                <QueryParamProvider adapter={ReactRouter5Adapter} options={{ updateType: 'replaceIn' }}>
                  <UserAndCompanyDataContextProvider>
                    <Routes />
                  </UserAndCompanyDataContextProvider>
                </QueryParamProvider>
              </Router>
            </Provider>
            <div className='appVersion'>{process.env.APP_VERSION}</div>
            <HubspotOverrides />
          </ScrollbarWidthContext.Provider>
        </DeviceContext.Provider>
      </RemToPxContext.Provider>
    </PersistenceProvider>
  )
}

export default withLDProvider({
  clientSideID: process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID!
})(App)
