import React, { useMemo } from 'react'

import { InputNumber, InputNumberProps } from 'antd'
import { CustomInput } from '../Types'


const controlCodes = ['ArrowLeft', 'ArrowRight', 'Delete', 'Backspace']
const validNumberRegex = /[0-9.,]/

const formatter = new Intl.NumberFormat('de-DE')
type Type = Required<Pick<InputNumberProps<number>, 'formatter' | 'parser' | 'onKeyDown'>>
export const useNumberInputExtraProps = (onKeyDown: InputNumberProps<number>['onKeyDown']): Type => useMemo(() => ({
  formatter: value => (value ? formatter.format(value) : ''),
  parser: value => {
    if (value) {
      const float = parseFloat(value.replace(/,/g, 'ß').replace(/[^\dß]/g, '').replace('ß', '.'))
      return value[0] === '-' ? -float : float
    }
    return 0
  },
  onKeyDown: evt => {
    if (!controlCodes.includes(evt.code) && !validNumberRegex.exec(evt.key)) {
      evt.preventDefault()
    }
    onKeyDown?.(evt)
  }
}), [onKeyDown])

const NumberInput: React.FC<InputNumberProps<number> & CustomInput<number>> = props => (
  <InputNumber {...props} {...useNumberInputExtraProps(props.onKeyDown)} />
)

export default NumberInput
