import React, { useContext, useMemo, useState } from 'react'
import { Button } from 'antd'
import { Trans } from '@lingui/macro'

import InputUsersAndTeams from '../../../../../../SharedComponents/FormComponents/InputUsersAndTeams'

import InstanceUsersContext from '../Context/InstanceUsersContext'


const InviteInternalMembers: React.FC = () => {
  const { internRoles, updateInternalRole } = useContext(InstanceUsersContext)
  const [showInvite, setShowInvite] = useState(false)
  const [inviteLoading, setInviteLoading] = useState(false)

  //  Get an array of selected userID to filter out of the invite
  const selectedUsers = useMemo<string[]>(() => {
    const arr: string[] = []
    internRoles.forEach(({ userID }) => {
      if (!/^(org|team)-/.test(userID)) {
        arr.push(userID)
      }
    })
    return arr
  }, [internRoles])


  return (
    <div className='buttonWrapper'>
      {showInvite ? (
        <InputUsersAndTeams
          autoFocus
          onBlur={() => setShowInvite(false)}
          onChange={async ([userID]) => {
            setShowInvite(false)
            setInviteLoading(true)
            try {
              await updateInternalRole(userID, 'Viewer')
            } catch {}
            setInviteLoading(false)
          }}
          selectOnly
          value={selectedUsers}
        />
      ) : (
        <Button
          className='internal'
          loading={inviteLoading}
          onClick={() => setShowInvite(true)}
          type='primary'
        >
          <Trans>Add internal user</Trans>
        </Button>
      )}
    </div>
  )
}

export default InviteInternalMembers
