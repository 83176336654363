import React, { useEffect } from 'react'

const styleOverrides = `
/*  Hubspot chat button styling overrides  */
.top-legal > #hubspot-messages-iframe-container {
  --scale-override: calc(var(--rem-to-px) / 20);
}

.top-legal:not(.hubspotOpened) > #hubspot-messages-iframe-container {
  /*  Sorry old school px mode for hubspot  */
  width: 60px !important;
  height: 60px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  overflow: hidden !important;
  border-radius: 100px;
  left: calc(3rem - 30px) !important;
  bottom: calc(3rem - 30px) !important;
  
  transform-origin: 50% 50%;
  transform: scale(var(--scale-override));
}

.top-legal.hubspotOpened > #hubspot-messages-iframe-container {
  left: calc(3rem - (44px * var(--scale-override))) !important;
  bottom: calc(3rem - (44px * var(--scale-override))) !important;

  transform-origin: 0 100%;
  transform: scale(var(--scale-override));
}

.top-legal:not(.hubspotOpened) > #hubspot-messages-iframe-container > iframe {
  width: 90px !important;
  height: 90px !important;
  position: initial !important;
}
`

const HubspotOverrides = () => {
  useEffect(() => {
    let destructor

    let tries = 0
    const handleHubspot = () => {
      tries += 1
      const hubspotContainer = document.getElementById('hubspot-messages-iframe-container')

      if (hubspotContainer) {
        const styleElement = document.createElement('style') as any
        styleElement.type = 'text/css'
        if (styleElement.styleSheet) {
          styleElement.styleSheet.cssText = styleOverrides
        } else {
          styleElement.appendChild(document.createTextNode(styleOverrides))
        }
        document.body.appendChild(styleElement)

        const observer = new MutationObserver(mutations => {
          for (const mutation of mutations) {
            const elm = mutation.target as any
            if (mutation.type === 'attributes' && elm.classList?.contains('shadow-container')) {
              if (elm.classList.contains('active')) {
                document.body.classList.add('hubspotOpened')
              } else {
                document.body.classList.remove('hubspotOpened')
              }
            }
          }
        })
        observer.observe(hubspotContainer, { attributes: true, childList: true, subtree: true })

        if (hubspotContainer.querySelector('div.shadow-container.active')) {
          document.body.classList.add('hubspotOpened')
        }

        destructor = () => {
          observer.disconnect()
          styleElement.remove()
        }
      } else if (tries < 10) {
        setTimeout(handleHubspot, 500)
      }
    }

    setTimeout(handleHubspot, 500)

    return () => destructor?.()
  }, [])

  return null
}

export default HubspotOverrides
