import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getPlateActions, PlatePlugin } from '@udecode/plate-core'

import { Node } from 'slate'
import * as Y from 'yjs'
import { WebsocketProvider } from 'y-websocket'
import { slateYjsOriginSymbol as slateYjsSymbol, SyncElement, useCursors, withCursor, withYjs } from 'slate-yjs'

import { computeSnapshotDelta, DeltaTree } from './YjsDelta'
import { applySnapshotPatch, computeSnapshotPatch, newDoc } from '../../core/yjsUtils/SlateToYjsDoc'


interface SlateYjsPluginProps {
  onlineMode?: {
    webSocketEndpoint: string
    documentID: string
  }
}

export interface SlateYjsPluginReturn {
  slateYjsPlugin: PlatePlugin
  isOnline: boolean
  sharedType: Y.Array<SyncElement>
  undoManager: Y.UndoManager
  setCurrentUser: (userID: string) => void
  setInitialSnapshot: () => void
  getDelta: (initSnapshot?: Y.Snapshot) => DeltaTree | undefined
  getSnapshot: () => string | undefined
  applySnapshotPatch: (patch: string, origin?: any) => void
  prevSnapshotRef: React.MutableRefObject<Y.Snapshot>
  provider?: WebsocketProvider
  getContent: () => Node[]
}

// eslint-disable-next-line id-length,@typescript-eslint/no-empty-function
const fakeAwareness = { on: () => {} }

const emptyDeps = []
const useSlateYjsPlugin = (editorID: string, { onlineMode }: SlateYjsPluginProps = {}, docDependencies: any[] = emptyDeps): SlateYjsPluginReturn => {
  //  Extract onlineMode props to avoid the requirement to memoize this object in the parent
  const { webSocketEndpoint, documentID } = onlineMode || {}
  const [isOnline, setIsOnline] = useState(false)


  /** ******************************************************************
   *                Setup the doc and shared type
   ****************************************************************** */
  const prevSnapshotRef = useRef<Y.Snapshot>(Y.emptySnapshot)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const doc = useMemo(() => newDoc(), [editorID, ...docDependencies])
  const userStore = useMemo(() => new Y.PermanentUserData(doc), [doc])
  const sharedType = useMemo(() => doc.getArray<SyncElement>('doc'), [doc])
  const undoManager = useMemo(() => new Y.UndoManager(sharedType, {
    deleteFilter: () => false,
    trackedOrigins: new Set<any>([slateYjsSymbol])
  }), [sharedType])
  const provider = useMemo(() => {
    if (webSocketEndpoint && documentID) {
      return new WebsocketProvider(
        webSocketEndpoint,
        documentID,
        doc,
        { connect: false }
      )
    }
    return undefined
  }, [webSocketEndpoint, documentID, doc])


  /** ******************************************************************
   *                  Create helper functions
   ****************************************************************** */
  const setInitialSnapshot = useCallback(() => {
    prevSnapshotRef.current = Y.snapshot(doc)
  }, [doc])

  const getDelta = useCallback(
    (initSnapshot?: Y.Snapshot) => computeSnapshotDelta(doc, sharedType, prevSnapshotRef.current, initSnapshot),
    [doc, sharedType]
  )
  const getSnapshot = useCallback(
    () => computeSnapshotPatch(doc, prevSnapshotRef.current),
    [doc]
  )
  const applySnapshotPatch_ = useCallback(
    (patch: string, origin?: any) => applySnapshotPatch(doc, patch, origin),
    [doc]
  )
  const getContent = useCallback(
    () => sharedType.toJSON(),
    [sharedType]
  )


  /** ******************************************************************
   *            function for setting our current user
   ****************************************************************** */
  const setCurrentUser = useCallback((userID: string) => {
    userStore.setUserMapping(doc, doc.clientID, userID)
    provider?.awareness.setLocalState({ userID })
  }, [doc, provider?.awareness, userStore])


  /** ******************************************************************
   *            Listening for real-time events
   ****************************************************************** */
  useEffect(() => {
    if (provider) {
      provider.on('status', ({ status }: { status: string }) => {
        setIsOnline(status === 'connected')
      })
      provider.connect()

      return () => provider.disconnect()
    }

    return undefined
  }, [provider, sharedType])


  /** ******************************************************************
   *                  Build the plugin
   ****************************************************************** */
  //  Use a fake editor to just pass needed values
  //  With SlatePlugin the editor is dynamically passed in callbacks but a hooks have to be called in the same order
  const { decorate } = useCursors({ sharedType, awareness: (provider && provider.awareness) || fakeAwareness } as any)
  const slateYjsPlugin = useMemo<PlatePlugin>(() => ({
    key: 'slateYjsPlugin',
    withOverrides: (editor: any) => {
      let wrapped = withYjs(editor, sharedType, { synchronizeValue: false })
      if (provider) {
        wrapped = withCursor(wrapped, provider.awareness)
      }
      //  Disable undo for now
      // wrapped.undo = () => undoManager.undo()
      // wrapped.redo = () => undoManager.redo()
      const noop = () => {} // eslint-disable-line
      wrapped.undo = noop
      wrapped.redo = noop
      return wrapped
    },
    decorate: provider ? (() => decorate) : undefined
  }), [decorate, provider, sharedType])

  //  The plugin is based on withOverrides that needs the editor to be reset
  const firstRef = useRef(true)
  useMemo(() => {
    if (firstRef.current) {
      firstRef.current = false
      return
    }
    setTimeout(() => getPlateActions(editorID).resetEditor())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorID, slateYjsPlugin])


  /** ******************************************************************
   *                  Return everything
   ****************************************************************** */
  return {
    slateYjsPlugin,
    isOnline,
    sharedType,
    undoManager,
    provider,
    setCurrentUser,
    setInitialSnapshot,
    getDelta,
    getSnapshot,
    applySnapshotPatch: applySnapshotPatch_,
    prevSnapshotRef,
    getContent
  }
}

export default useSlateYjsPlugin
