import {
  ADD_COMMENT,
  ADD_COMMENT_FROM_WS,
  DISPLAY_SAVE_REGISTRATION_MODAL,
  EMPTY_CONTRACT_EDITING_STORE,
  FETCHING_CONTRACT_DATA,
  FETCHING_SHARED_CONTRACTS,
  GET_ALL_CONTRACTS,
  GET_CONTRACTS_BY_STATUS,
  GET_SHARED_CONTRACTS,
  POSTING_COMMENT,
  SAVE_CONTRACT,
  SHOW_REGISTRATION_MODAL,
  UPDATE_CONTRACT_STATUS,
  UPDATE_SELECTED_TAB
} from './ContractActions'

const initState = () => ({
  contractEditing: {
    fieldsResponse: {}
  },
  allContracts: {},
  sharedContracts: [],
  fetchingContractData: false,
  displayRegistrationModal: false,
  fetchingSharedContracts: false,
  postingComment: false,
  showRegistrationModal: false,
  draftContracts: {},
  negotiationContracts: {},
  acceptedContracts: {},
  rejectedContracts: {},
  selectedTab: 'General'
})

const REDUCER_ACTIONS = {
  [SAVE_CONTRACT]: (state, payload, { __NEED_INVITE__ }) => {
    if (!__NEED_INVITE__) {
      delete payload.__NEED_INVITE__
    }

    state.contractEditing = payload
  },
  [EMPTY_CONTRACT_EDITING_STORE]: state => {
    state.contractEditing = {
      fieldsResponse: {}
    }
  },
  [GET_ALL_CONTRACTS]: (state, payload) => {
    state.allContracts = payload
  },
  [DISPLAY_SAVE_REGISTRATION_MODAL]: (state, payload) => {
    state.displayRegistrationModal = payload
  },
  [FETCHING_CONTRACT_DATA]: (state, payload) => {
    state.fetchingContractData = payload
  },
  [FETCHING_SHARED_CONTRACTS]: (state, payload) => {
    state.fetchingSharedContracts = payload
  },
  [GET_SHARED_CONTRACTS]: (state, payload) => {
    state.sharedContracts = payload
  },
  [ADD_COMMENT]: (state, { comment, owner, section }) => {
    state.contractEditing.comments = { ...(state.contractEditing.comments || {}) }
    state.contractEditing.comments[section] = [...(state.contractEditing.comments[section] || []), comment]
    // if (allParticipants) {
    //   state.contractEditing.comments.allParticipants = allParticipants
    // }
    if (!state.contractEditing.comments.allParticipants || !state.contractEditing.comments.allParticipants[comment.commentedBy]) {
      state.contractEditing.comments.allParticipants = {
        ...(state.contractEditing.comments.allParticipants || {}),
        [owner.userID]: owner
      }
    }
  },
  [ADD_COMMENT_FROM_WS]: (state, {
    comment, owner, section, contractID
  }) => {
    if (state.allContracts[contractID]) {
      state.allContracts[contractID].comments = { ...(state.allContracts[contractID].comments || {}) }
      state.allContracts[contractID].comments[section] = [...(state.allContracts[contractID].comments[section] || []), comment]
      if (!state.allContracts[contractID].comments.allParticipants || !state.allContracts[contractID].comments.allParticipants[comment.commentedBy]) {
        state.allContracts[contractID].comments.allParticipants = {
          ...(state.allContracts[contractID].comments.allParticipants || {}),
          owner
        }
      }
    }
  },
  [POSTING_COMMENT]: (state, value) => {
    state.postingComment = value
  },
  [SHOW_REGISTRATION_MODAL]: (state, value) => {
    state.showRegistrationModal = value
  },
  [GET_CONTRACTS_BY_STATUS]: (state, { allContracts, tableName }) => {
    state[tableName] = allContracts
  },
  [UPDATE_CONTRACT_STATUS]: (state, { contractID, contractStatus }) => {
    const indexInAllContracts = state.allContracts.Items.findIndex(contract => contract.contractID === contractID)
    state.allContracts.Items[indexInAllContracts].contractStatus = contractStatus
    if (contractStatus !== 'General') {
      state[`${contractStatus.toLowerCase()}Contracts`].Items = [
        ...(state[`${contractStatus.toLowerCase()}Contracts`].Items || []),
        state.allContracts.Items[indexInAllContracts]
      ]
    }
    if (state.selectedTab !== 'General' && state.selectedTab !== contractStatus) {
      const indexInCurrentTab = state[`${state.selectedTab.toLowerCase()}Contracts`].Items.findIndex(contract => contract.contractID === contractID)
      if (indexInCurrentTab > -1) { state[`${state.selectedTab.toLowerCase()}Contracts`].Items.splice(indexInCurrentTab, 1) }
    }
  },
  [UPDATE_SELECTED_TAB]: (state, value) => {
    state.selectedTab = value
  }
}

export default function (state = initState(), action) {
  state = { ...state }
  const handler = REDUCER_ACTIONS[action.type]
  if (handler) {
    state = handler(state, action.payload, action) || state
  }
  return state
}
