import React, { useContext, useMemo } from 'react'
import { useParams } from 'react-router'

//  Signature images
import signaturePendingEN from '../../../../img/signaturePendingEN.svg'
import signaturePendingDE from '../../../../img/signaturePendingDE.svg'
import signatureStampEN from '../../../../img/signatureStampEN.svg'
import signatureStampDE from '../../../../img/signatureStampDE.svg'

//  Contexts
import { ContractContext, TemplateContext } from '../Contexts'

import { UserAvatarByID } from '../../../Organisations/UserRoleDisplay/UserAvatarList'
import { SidebarStateContext } from '../../../../ContractTemplateRoutes'
import { PhoneSidebarContext } from '../Sidebar/PhoneSidebar'
import { FormatText } from '../Sidebar/SidebarToolboxes/Commenting/CommentDisplay'
import { SignatureStampInfo } from './Utils/getSignatureStampsInfo'
import { IdPrefixContext } from '../../ContractDiplay/ContractLayout'
import { DeviceContext } from '../../../../GlobalContext'

const signatureTrans = {
  en: 'Not signed',
  de: 'Nicht unterzeichnet'
}

const getDisplayDate = (lang: string, dateStr?: string): string => {
  if (dateStr) {
    const date = new Date(dateStr)
    if (!Number.isNaN(date)) {
      return new Intl.DateTimeFormat(lang, {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
      }).format(date)
    }
  }
  return ''
}


interface SignatureStampProps {
  signatureInfo: SignatureStampInfo
  userSignature?: any
}

const ensureLang = (lang: string): string => (lang === 'de' ? 'de' : 'en')

const SignatureStamp : React.FC<SignatureStampProps> = ({ signatureInfo, userSignature }) => {
  const idPrefix = useContext(IdPrefixContext)
  const { lang: userLang } = useParams()
  const { template: { lang: templateLang } } : any = useContext(TemplateContext)
  const lang = ensureLang(templateLang || userLang)
  const { contract } : any = useContext(ContractContext)
  const sidebarStateContext = useContext(SidebarStateContext)
  const isPhone = useContext(DeviceContext) === 'phone'
  const { openSidebarRef } = useContext<any>(PhoneSidebarContext)


  //  Update the signature holder when reinviting someone to sign
  // const updateSignatureHolder = useCallback(values => {
  //   const { signaturesHolder } = contract
  //   const signatures = [...signaturesHolder.signatures]
  //   const index = signatures.findIndex(({ signatureID }) => signatureID === signatureInfo.signatureID)
  //   signatures[index] = {
  //     ...signatures[index],
  //     ...values
  //   }
  //   return updateContract({ signaturesHolder: { ...signaturesHolder, signatures } }, false)
  // }, [contract, signatureInfo.signatureID, updateContract])

  const onSignatureClick = useMemo(() => {
    if (contract.contractID !== '__preview__') {
      return () => {
        if (isPhone) {
          openSidebarRef.current('users')
        } else if (sidebarStateContext.current?.openedToolbox !== 'users') {
          sidebarStateContext.current?.switchToolbox('users')
        }
      }
    }
    return undefined
  }, [contract.contractID, isPhone, openSidebarRef, sidebarStateContext])


  const signedDate = getDisplayDate(lang, signatureInfo.signedDate)
  const signatureStamp = (() => {
    if (lang === 'de') {
      if (signedDate) {
        return signatureStampDE
      }
      return signaturePendingDE
    }
    if (signedDate) {
      return signatureStampEN
    }
    return signaturePendingEN
  })()


  const mySignature = userSignature && signatureInfo.signatureID === userSignature.signatureID
  const blink = (!signedDate && !contract.signingExtension && mySignature && 'blink-outline') || ''
  const signer = signatureInfo.userAccess || signatureInfo.partyID
  return (
    <div
      className={`signatureStamp ${signedDate ? 'signed' : 'pending'} ${blink}`}
      id={`${idPrefix}${signer}-signing-card`}
    >
      {signer && <UserAvatarByID autoSide className='signatureAvatar' size='1.5rem' userID={signer} />}
      <img alt='signature stamp' onClick={onSignatureClick} src={signatureStamp} />
      <div className='signatureDescriptionContainer' onClick={onSignatureClick}>
        <p className='signatureName'><FormatText text={signatureInfo.displayName} /></p>
        <p className='signatureDate'>{signedDate || signatureTrans[lang] || signatureTrans.en}</p>
      </div>
    </div>
  )


  /**
   * Display the other parties
   */
  // let userAvatar = signature.userAccess && <UserAvatarByID className='signatureAvatar' size='1.5rem' userID={signature.userAccess} />
  // if (!userAvatar) {
  //   if (signature.token) {
  //     userAvatar = (
  //       <UserAvatar
  //         className='signatureAvatar'
  //         size='1.5rem'
  //         tooltipProps={{
  //           title: (
  //             <div className='signatureAvatarTooltip'>
  //               <p><b><Trans>Invited user</Trans></b></p>
  //               {signature.inviteEmail && (
  //                 <p className='email'>
  //                   <b><Trans>Email</Trans></b> <a href={`mailto:${signature.inviteEmail}`}>{signature.inviteEmail}</a>
  //                 </p>
  //               )}
  //               <p className='link'>
  //                 <b><Trans>Invite link</Trans></b> <span {...copyLinkProps(lang, signature)}><Trans>Click to copy</Trans></span>
  //               </p>
  //               {!signatureDate && (
  //                 <p>{signature.securityLevel === 'authenticated' ? <Trans>Waiting for signup</Trans> : <Trans>Waiting for signature</Trans>}</p>
  //               )}
  //               {signature.tokenData && signature.tokenData.deleteAfter && (
  //                 <p><Trans>Expires {moment(signature.tokenData.deleteAfter * 1000).fromNow()}</Trans></p>
  //               )}
  //               {signature.inviteEmail && (
  //                 <ConfirmButton
  //                   block
  //                   confirmMessage={<Trans>Are you sure you want to resend an invite email?</Trans>}
  //                   ghost
  //                   icon={<SendOutlined />}
  //                   onClick={() => loadingModalFeedback({
  //                     loadingTitle: <Trans>Resending invite email</Trans>,
  //
  //                     successTitle: <Trans>Email sent</Trans>,
  //
  //                     errorTitle: <Trans>Failed to resend invite email</Trans>,
  //                     errorDescription: <Trans>An unexpected error occurred while trying to resend the invite. Please try again later.</Trans>,
  //
  //                     autoSuccessClose: 2000
  //                   })(async () => {
  //                     const values = await RestService('POST', `/user/invite/${signature.token}/resendInvite`)
  //                     await updateSignatureHolder(values)
  //                   })}
  //                   placement='bottom'
  //                   size='small'
  //                   type='primary'
  //                 >
  //                   <Trans>Resend invite?</Trans>
  //                 </ConfirmButton>
  //               )}
  //             </div>
  //           )
  //         }}
  //       />
  //     )
  //   }
  // }
}

export default SignatureStamp
