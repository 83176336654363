import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { t, Trans } from '@lingui/macro'
import { Button, Dropdown, Form, Input, Menu, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import moment from 'moment'

import { useSaveCommentInStore } from '@top-legal/datastore'

import { DatePicker } from '../../../../../SharedComponents/DateTimePicker'
import CustomFormWrapper from '../../../../../SharedComponents/CustomFormWrapper'
import { UserAvatarByID, UserFullName } from '../../../../../Organisations/UserRoleDisplay/UserAvatarList'
import { saveComment } from '../../../../../Organisations/redux/OrganisationsActions'

import { PartCommentContext } from './PartComment'
import { CommentTitleDisplay } from './CommentDisplay'
import { RoleTag } from '../../../../../Organisations/UsersManagement'
import { Comment, CommentsToolboxConfigContext, StoreComment, TaskComment } from './Types'
import { UserAndCompanyDataContext } from '../../../../../Layouts/Constants'
import { DeviceContext } from '../../../../../../GlobalContext'
import { noop } from '../../../../../Defaults'


interface NewCommentProps {
  close?: (comment?: StoreComment) => void
  focus?: boolean
  childID?: string
  initialData?: Comment
  className?: string
  visible?: boolean
  noTask?: boolean
}
export const closeDrawerContext = React.createContext<any>({})

const TaskSwitch: React.FC<any> = ({ value, onChange }) => (
  <Button
    className='noBorder taskSwitch' ghost onClick={() => onChange(!value)} size='small'
    type='default'
  >
    {value ? <Trans>Back</Trans> : <Trans>Create a task</Trans>}
  </Button>
)


const NewComment: React.FC<NewCommentProps> = ({
  close = noop,
  focus,
  className,
  childID,
  initialData,
  noTask: noTaskP
}) => {
  const isPhone = useContext(DeviceContext) === 'phone'
  const { noTask: noTaskC } = useContext(CommentsToolboxConfigContext)
  const noTask = noTaskP || noTaskC
  const { user, company } = useContext(UserAndCompanyDataContext)
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const input = useRef<Input.TextArea>()
  const dispatch = useDispatch()
  const { instanceType, instanceID, part, side } = useContext(PartCommentContext)
  const roles = useSelector(state => state.organisation.instanceRoles[instanceID])
  const instanceRoles = useMemo<{ [key: string]: string }>(() => {
    const tmp = {}

    ;((roles || {}).instanceRoles || []).forEach(({ userID, role }) => {
      if (!userID.startsWith('org') && !userID.startsWith('team')) {
        tmp[userID] = role
      }
    })

    return tmp
  }, [roles])

  useEffect(() => {
    if (!isPhone && focus && input.current && input.current.focus) {
      input.current.focus({
        cursor: 'all'
      })
    }
  }, [focus, isPhone])

  const formRef = useRef<any>()
  useEffect(() => {
    const handler = () => {
      if (!formRef.current || !formRef.current.getFieldValue('text')) {
        close?.()
      }
    }
    if (!isPhone) {
      document.addEventListener('click', handler)
      return () => document.removeEventListener('click', handler)
    }
    return undefined
  }, [isPhone, close])

  const preDefinedTask = useMemo(() => [
    t`Fill-in data`,
    t`Propose new terms`,
    t`Review paragraph`,
    t`Approve changes`,
    t`Revise formulations`,
    t`Elaborate possible alternatives`
  ], [])

  const TextInput = useCallback(
    props => (
      <Input.TextArea
        ref={input}
        {...props}
        autoSize={{
          minRows: 2,
          maxRows: 6
        }}
        onKeyDown={evt => {
          if (evt.key === 'Enter' && evt.ctrlKey) {
            evt.preventDefault()
            props.form.submit()
          }
          if (!isPhone && evt.key === 'Escape') {
            evt.preventDefault()
            close?.()
          }
        }}
        placeholder={props.isTask ? t`Task` : t`Comment`}
      />
    ),
    [close, isPhone]
  )
  const TextInputWrapped = useCallback(
    ({ isTask, ...props }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [visible, setVisible] = useState(false)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const divRef = useRef<HTMLDivElement>(null)

      if (isTask) {
        return (
          <>
            <Dropdown
              overlay={
                <Menu>
                  {preDefinedTask.map(text => (
                    <Menu.Item
                      key={text}
                      onMouseDown={() => {
                        props.form.setFieldsValue({ text })
                        setTimeout(() => {
                          if (input.current && input.current.focus) {
                            input.current.focus({
                              cursor: 'end'
                            })
                          }
                        })
                      }}
                    >
                      {text}
                    </Menu.Item>
                  ))}
                </Menu>
              }
              placement='bottomRight'
              visible={visible}
            >
              <div onBlur={() => setVisible(false)} onClick={() => setVisible(true)} onKeyDown={() => setVisible(false)}>
                <TextInput {...props} />
              </div>
            </Dropdown>
          </>
        )
      }
      return <TextInput {...props} />
    },
    [TextInput, preDefinedTask]
  )

  const saveCommentInStore = useSaveCommentInStore()

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <CustomFormWrapper
      layout='vertical'
      onSubmit={async ({ text, task, assignedTo, dueDate }, form) => {
        setLoading(true)

        const comment = {
          instanceType,
          instanceID,
          childID: childID || part.sectionID,
          organisationID: side === 'internal' ? company.organisationID : '__EVERYONE__',
          ...((initialData || {}) as any),
          text: text.replace(/\n+$/g, ''),
          // Task
          task,
          assignedTo,
          dueDate
        }

        try {
          await dispatch(saveComment(comment))
          await saveCommentInStore(comment)
          close?.(comment as unknown as StoreComment)
          form.resetFields()
          if (initialData && (initialData as any).assignedTo) {
            (window as any).notification.success({ message: <Trans>The task has been updated</Trans> })
          }
          if (!isPhone && input.current) {
            input.current.focus()
          }
        } catch (errorComment) {
          console.error('error posting comment', errorComment)
        }

        setLoading(false)
      }}
    >
      <Form.Item noStyle shouldUpdate>
        {form => {
          formRef.current = form

          const actions = (
            <div className='commentCardFormActions'>
              <Button
                disabled={(!initialData || !(initialData as any).text) && !form.isFieldTouched('text')}
                htmlType='submit'
                icon={<CheckOutlined />}
                loading={loading}
                onClick={() => setTimeout(() => close?.(), 2000)}
                size='small'
                type='primary'
              >
                <Trans>Save</Trans>
              </Button>
              {close !== noop && (
                <Button
                  ghost icon={<CloseOutlined />} loading={loading} onClick={() => close?.()}
                  size='small' type='primary'
                >
                  <Trans>Cancel</Trans>
                </Button>
              )}
            </div>
          )

          const isTask = (initialData && (initialData as TaskComment).assignedTo) || form.getFieldValue('task')
          return (
            <div className={className ? 'comment task' : 'comment formCard'} onClick={evt => evt.stopPropagation()}>
              {initialData && initialData.userID ? (
                <CommentTitleDisplay comment={initialData} />
              ) : (
                <div className='commentTitle'>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore */}
                  <UserAvatarByID autoSide noTooltip size='2rem' userID={user.userID} />
                  <div className='titleWrapper'>
                    <span className='username'>
                      <UserFullName userID={user.userID} />
                    </span>
                    <span className='date'>
                      <Trans>Just now</Trans>
                    </span>
                  </div>
                </div>
              )}
              <Form.Item
                initialValue={(initialData && (initialData as any).text) || ''}
                name='text'
                rules={[
                  {
                    required: true,
                    message: <Trans>Please specify a comment</Trans>
                  }
                ]}
              >
                <TextInputWrapped className='simple-line' form={form} isTask={isTask} />
              </Form.Item>
              {isTask && (
                <>
                  <Form.Item
                    initialValue={(initialData && (initialData as any).assignedTo) || user.userID}
                    label={<Trans>Assign to:</Trans>}
                    name='assignedTo'
                    required
                  >
                    <Select className='simple-line'>
                      <Select.OptGroup label={<Trans>From document</Trans>}>
                        {Object.entries(instanceRoles).map(([userID, role]) => (
                          <Select.Option key={userID} value={userID}>
                            <div className='taskUserSelection'>
                              <UserAvatarByID autoSide noTooltip size='1.1rem' userID={userID} />
                              <span>
                                <UserFullName userID={userID} />
                              </span>
                              <RoleTag role={role} />
                            </div>
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                      <Select.OptGroup label={<Trans>Others</Trans>}>
                        {Object.keys(company.members).map(userID => (instanceRoles[userID] ? null : (
                          <Select.Option key={userID} value={userID}>
                            <div className='taskUserSelection'>
                              <UserAvatarByID autoSide noTooltip size='1.1rem' userID={userID} />
                              <span>
                                <UserFullName userID={userID} />
                              </span>
                            </div>
                          </Select.Option>
                        )))}
                      </Select.OptGroup>
                    </Select>
                  </Form.Item>
                  <Form.Item initialValue={initialData && (initialData as any).dueDate} label={<Trans>Due date:</Trans>} name='dueDate'>
                    <DatePicker className='simple-line' disabledDate={date => date <= moment()} />
                  </Form.Item>
                </>
              )}
              <div className='commentCardFormActionsWrapper'>
                {actions}
                {(!initialData || !initialData.userID) && !noTask && (
                  <Form.Item className='taskFormItem' name='task'>
                    <TaskSwitch />
                  </Form.Item>
                )}
              </div>
            </div>
          )
        }}
      </Form.Item>
    </CustomFormWrapper>
  )
}

export default NewComment
