import React, { useCallback, useMemo } from 'react'
import { t, Trans } from '@lingui/macro'
import { AuditOutlined, ControlOutlined, PullRequestOutlined, UserSwitchOutlined } from '@ant-design/icons'

import {
  Contract,
  ContractingParty,
  Event,
  InputField,
  List,
  Template,
  useContractEventsCollection,
  useContractingPartiesCollection,
  useContractsListBaseQuery,
  useFieldsCollection,
  useList,
  useTemplateListBaseQuery
} from '@top-legal/datastore'

import { Filter, UseListing } from '../Types'
import ListRow from '../../SharedComponents/ListingComponents/ListRow'
import ContractRowComponents from './ContractRowComponents'
import ContractActions from './ContractActions'
import ContractContainerWrapper from './ContractContainerWrapper'
import { emptyArray, emptyObject, getContractStatus, hasKeyWordsFactory, useTagsFilter } from '../SearchHelpers'
import { canRenderFieldType } from '../../Contract/ContractEditor/ContractSummary'
import { buildContractActivityBoxes } from './ContractActivities'


const useContractListing: UseListing<Contract> = () => {
  //  Contracts data
  const getContracts = useContractsListBaseQuery()
  const [contracts = emptyArray, loadC] = useList<Contract, {}, List<Contract>>(
    useMemo(() => getContracts.sort({ dateUpdated: 'desc' }), [getContracts])
  )

  //  Templates name mapping
  const getTemplates = useTemplateListBaseQuery()
  const [
    [templateNameMapping = emptyObject, tmplLangCategory = emptyObject] = emptyArray,
    loadT
  ] = useList<Template, {}, [{ [templateID: string]: string }, { [templateID: string]: { lang?: string, category?: string } }]>(
    useMemo(() => getTemplates.sort({ dateUpdated: 'desc' }), [getTemplates]),
    useCallback(array => {
      const nMap: any = {}
      const tMap: any = {}
      array.forEach(({ templateID, name, lang, category }) => {
        if (name) { nMap[templateID] = name }
        tMap[templateID] = { lang, category }
      })
      return [nMap, tMap] as any
    }, [])
  )

  //  Parties name mapping
  const partiesCollection = useContractingPartiesCollection()
  const [partiesNameMapping = emptyObject, loadP] = useList<ContractingParty, {}, { [partyID: string]: string }>(
    useMemo(() => partiesCollection.find().sort({ updatedAt: 'desc' }), [partiesCollection]),
    useCallback(array => {
      const map: any = {}
      array.forEach(({ partyID, type, firstName, lastName }) => {
        if (type === 'person' && firstName && lastName) {
          map[partyID] = `${firstName} ${lastName}`
        }
      })
      return map
    }, [])
  )

  //  Compute fields
  const fieldsCollection = useFieldsCollection()
  const [inputFieldsToName = emptyObject, loadI] = useList<InputField, {}, { [fieldID: string]: string }>(
    useMemo(() => fieldsCollection.find(), [fieldsCollection]),
    useCallback(fields => {
      const mapping: any = {}
      fields.forEach(({ inputFieldID, name, type }) => {
        if (name && canRenderFieldType(type)) {
          mapping[inputFieldID] = name
        }
      })
      return mapping
    }, [])
  )


  /** *******************************************************************
   *                      Transform instances
   ******************************************************************* */
  //  Compute event mapping for contracts
  const eventsCollection = useContractEventsCollection()
  const [eventMapping = emptyObject, loadE] = useList<Event, any, { [contractID: string]: Event[] }>(
    useMemo(
      () => eventsCollection.find().sort({ date: 'asc' }),
      [eventsCollection]
    ),
    useCallback((events: List<Event>) => {
      const mapping: any = {}
      events.forEach(event => {
        if (!mapping[event.objectID]) {
          mapping[event.objectID] = []
        }
        mapping[event.objectID].push(event)
      })
      return mapping
    }, [])
  )

  //  Attach activities to contracts
  const instances = useMemo(() => contracts.map(contract => {
    const events = eventMapping[contract.contractID] || emptyArray
    ;(contract as any).nbEvents = events.length
    ;(contract as any).activities = buildContractActivityBoxes(contract, events)
    //  Bind template lang & category to the contract
    Object.entries(tmplLangCategory[contract.editingOrgininalTemplateID || contract.templateID] || emptyObject).forEach(([key, value]) => {
      (contract as any)[key] = value
    })
    return contract
  }), [contracts, eventMapping, tmplLangCategory])


  /** *******************************************************************
   *                      Filters
   ******************************************************************* */
  const tagFilter = useTagsFilter<Contract>(instances)
  const filters = useMemo<Filter<Contract>[]>(() => [{
    //  Template name filter
    key: 'templateID',
    title: <Trans>Playbook</Trans>,
    icon: <PullRequestOutlined />,
    items: templateNameMapping,
    filter: (item, value) => (item.editingOrgininalTemplateID || item.templateID) === value
  }, {
    //  Status filter
    key: 'status',
    title: <Trans>Contract status</Trans>,
    icon: <AuditOutlined />,
    items: {
      drafting: t`Drafting`,
      negotiate: t`Negotiate`,
      approval: t`Approval`,
      signing: t`Signing`,
      signed: t`Signed`,
      imported: t`Uploaded`
    },
    filter: (item, value) => getContractStatus(item) === value
  }, {
    //  Contracting parties
    key: 'party',
    title: <Trans>Contracting party</Trans>,
    icon: <UserSwitchOutlined />,
    items: partiesNameMapping,
    filter: (item, value) => {
      if (item.parties) {
        return Object.values<any>(item.parties).some(data => {
          if (typeof data === 'string') {
            return data === value
          }
          if (data && data.userID) {
            return data.userID === value
          }
          if (data && data.userAccess) {
            return data.userAccess === value
          }
          return data && (
            data.partyID === value
            || data.contactID === value
            || (Array.isArray(data.additionalUsers) && data.additionalUsers.some(add => add.partyID === value || add.contactID === value))
          )
        })
      }
      return false
    }
  }, tagFilter, {
    //  Key terms
    key: 'term',
    title: <Trans>Term</Trans>,
    icon: <ControlOutlined />,
    items: inputFieldsToName,
    filter: (item: any, value) => item.fieldsResponse?.[value] != null
  }], [templateNameMapping, partiesNameMapping, tagFilter, inputFieldsToName])


  /** *******************************************************************
   *                      Return config
   ******************************************************************* */
  const loading = loadC || loadE || loadI || loadP || loadT
  return useMemo<ReturnType<UseListing<Contract>>>(() => ({
    key: 'contracts',
    title: <Trans>Contracts</Trans>,
    icon: <AuditOutlined />,
    instances,
    filters,
    stringSearch: (contract: any, split) => {
      const hasKeyWords = hasKeyWordsFactory([...split])
      return hasKeyWords(contract.contractName) || hasKeyWords(contract.templateMetaData?.name)
    },
    rowKey: contract => contract.contractID + ((contract as any).nbEvents),
    // eslint-disable-next-line react/display-name
    ListRow: props => <ListRow {...props} {...ContractRowComponents} />,
    ContainerWrapper: ContractContainerWrapper,
    Actions: ContractActions,
    loading
  }), [filters, instances, loading])
}

export default useContractListing
