import React, { ReactNode, useCallback, useRef } from 'react'
import { Form, FormInstance, FormProps } from 'antd'
import { Trans } from '@lingui/macro'

import './CustomFormStyles.scss'

/** ******************************************************************
 *            Props & Defaults definitions
 ****************************************************************** */
export interface CustomFormProps {
  onSubmit: (values: any, form: FormInstance) => Promise<any>
  onFail?: (errors: any) => void
  initialValues?: any
}

export const requiredRules = [{ required: true, message: <Trans>This field is required</Trans> }]


/** ******************************************************************
 *            Custom form with error handler
 ****************************************************************** */
const CustomForm: React.FC<CustomFormProps & Omit<FormProps, 'onFinish' | 'onFinishFailed'> & { children?: ReactNode | ((form: FormInstance) => ReactNode)}> = ({
  onSubmit, onFail,
  initialValues = {},
  children,
  ...props
}) => {
  const [form] = Form.useForm()
  const divRef = useRef<HTMLDivElement>(null)

  // ==================================================================================================

  const handleForm = useCallback(async (values, errors) => {
    const showError = () => {
      const onComplete = () => {
        divRef.current?.removeEventListener('animationend', onComplete)
        divRef.current?.classList.remove('shakeAnimation')

        if (errors && typeof onFail === 'function') {
          onFail(errors)
        }
      }

      if (divRef.current) {
        divRef.current.addEventListener('animationend', onComplete)
        divRef.current.classList.add('shakeAnimation')
      } else {
        onComplete()
      }
    }

    if (errors) {
      showError()
      console.error('Form completion error', errors)
    } else {
      try {
        await onSubmit(values, form)
      } catch (error) {
        showError()
        console.error('Form onSubmit error', error)
      }
    }
  }, [form, onFail, onSubmit])

  // ==================================================================================================

  return (
    <div ref={divRef}>
      <Form
        {...props}
        form={form}
        initialValues={initialValues}
        onFinish={handleForm as any}
        onFinishFailed={({ values, errorFields }) => handleForm(values, errorFields)}
      >
        {(typeof children === 'function' && !(children as any).$$typeof) ? (children as any)(form) : children}
      </Form>
    </div>
  )
}

export default CustomForm
