import React, { useMemo, useRef, useState } from 'react'
import { t, Trans } from '@lingui/macro'
import { Button, Collapse, Form, Input, Pagination } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

//  Custom components
import CustomFormWrapper from '../../../../SharedComponents/CustomFormWrapper'
import { LocalLoadingAlert } from '../../../../Alert/LoadingAlert'
import useClauseSearchInstance from '../../../../Search/SearchInstances/ClauseSearchInstance'
import { useHighlight } from '../../../../Listing/SearchHelpers'

//  Styles
import './SectionSearchStyles.scss'

export const isOrganisationFieldsContext = React.createContext(false)

const SectionSearchToolbox = () => {
  /** ******************************************************************
   *                    Some state to manage the search
   ****************************************************************** */
  const [collapseKey, setCollapseKey] = useState('form')
  const [searchResult, setSearchResult] = useState([])
  const [firstIndex, setFirstIndex] = useState(0)
  const [current, setCurrent] = useState(1)
  const [search, setSearch] = useState('')
  const scrollRef = useRef(null)

  /** *******************************************************************
   *                      Clause search
   ******************************************************************* */
  const { InstanceRendering, filterInstances, getID, AdditionalFilters, loading, loadingText } = useClauseSearchInstance()
  const Highlight = useHighlight(search)

  const results = useMemo(() => searchResult.slice(firstIndex, firstIndex + 20).map(instance => (
    <div
      key={getID(instance)}
      draggable
      onDragStart={async event => {
        event.dataTransfer.dropEffect = 'copy'
        event.dataTransfer.effectAllowed = 'copy'

        event.dataTransfer.setData('text/plain', ' ') // Required for having Drag & Drop
        event.dataTransfer.setData('application/x-top-legal-section', JSON.stringify(instance))
        if (Array.isArray(instance.content)) {
          const string = JSON.stringify(instance.content)
          const encoded = window.btoa(encodeURIComponent(string))
          event.dataTransfer.setData('application/x-slate-fragment', encoded)
        }
      }}
    >
      <InstanceRendering Highlight={Highlight} instance={instance} search={search} />
    </div>
  )), [Highlight, InstanceRendering, firstIndex, getID, search, searchResult])


  /** *******************************************************************
   *                      Render the toolbox
   ******************************************************************* */
  return (
    <LocalLoadingAlert description={loadingText} lightTheme loading={loading}>
      <Collapse
        accordion activeKey={collapseKey} bordered={false} onChange={key => {
          if (key) {
            setCollapseKey(key)
          }
        }}
      >
        <Collapse.Panel key='form' className='searchPanel' header={t`Search input`}>
          <CustomFormWrapper
            className='searchForm'
            onSubmit={({ text }) => {
              setFirstIndex(0)
              setCurrent(1)
              setSearch(text)
              const array = filterInstances(text)
              setSearchResult(array)
              if (array.length > 0) {
                setCollapseKey('results')
              }
            }}
          >
            <fieldset>
              <legend><Trans>Search for text</Trans></legend>
              <Form.Item initialValue='' name='text'>
                <Input
                  className='simple-line'
                  placeholder={t`Enter text`}
                />
              </Form.Item>
            </fieldset>
            <AdditionalFilters />
            <Button block htmlType='submit' icon={<SearchOutlined />} type='primary'>
              <Trans>Search</Trans>
            </Button>
          </CustomFormWrapper>
        </Collapse.Panel>

        {/* ShowResult collapse */}
        <Collapse.Panel key='results' header={t`${searchResult.length} paragraphs`}>
          <div ref={scrollRef} className='searchResults'>
            <isOrganisationFieldsContext.Provider value={true}>
              {results}
            </isOrganisationFieldsContext.Provider>
          </div>
          {searchResult.length > 0 && (
            <Pagination
              className='pagination'
              current={current}
              defaultCurrent={1}
              onChange={page => {
                setFirstIndex((page - 1) * 20)
                setCurrent(page)
                setTimeout(() => {
                  scrollRef.current.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                  })
                }, 200)
              }}
              pageSize={20}
              simple
              style={{ marginTop: '5px', alignContent: 'center' }}
              total={searchResult.length}
            />
          )}
        </Collapse.Panel>
      </Collapse>
    </LocalLoadingAlert>
  )
}

export default {
  key: 'search',
  className: 'searchSectionToolbox',
  text: <Trans>Search</Trans>,
  helpText: <Trans>Search clauses from your templates library.</Trans>,
  icon: <SearchOutlined />,
  Component: SectionSearchToolbox
}
