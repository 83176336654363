import { Dispatch, SetStateAction, useCallback, useState } from 'react'

const useBrowserStorage = <T extends unknown>(key: string, initialValue: T, storage = window.sessionStorage): [T, Dispatch<SetStateAction<T>>] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = storage.getItem(key)

      // Parse stored json or if none return DefaultSlateContent
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return DefaultSlateContent
      console.error('[use-local-storage]', error)
      return initialValue
    }
  })

  const setValue = useCallback<Dispatch<SetStateAction<T>>>(value => setStoredValue(oldData => {
    // Allow value to be a function so we have same API as useState
    const valueToStore = value instanceof Function ? value(oldData) : value

    setTimeout(() => {
      try {
        // Save to local storage
        if (valueToStore == null) {
          storage.removeItem(key)
        } else {
          storage.setItem(key, JSON.stringify(valueToStore))
        }
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.error('[use-local-storage]', error)
      }
    })

    return valueToStore
  }), [key, storage])

  return [storedValue, setValue]
}

export default useBrowserStorage
