import React, { useContext, useLayoutEffect } from 'react'
import { Trans } from '@lingui/macro'
import { Button } from 'antd'

import { CloseOutlined } from '@ant-design/icons'
import { UserAvatarByID, UserFullName } from './UserRoleDisplay/UserAvatarList'
import loadingModalFeedback from '../Alert/LoadingModalFeedback'
import RestService from '../../RestService'
import { LANGS_AVAILABLE } from '../../Routes'
import { DeviceContext } from '../../GlobalContext'

const redirectTo = (link: string) => {
  const lang = (new RegExp(`/(${LANGS_AVAILABLE.join('|')})/`)).exec(window.location.href)?.[1]
  window.location.href = `/${lang || 'en'}/${link}`
}


// ==================================================================================================


export const entityEndorsementAction = (entityID?: string): Promise<void> => loadingModalFeedback(entityID ? {
  loadingTitle: <Trans>Preparing user rights simulation.</Trans>,
  successTitle: <Trans>Simulation session started.</Trans>,
  errorTitle: <Trans>The simulation of the user rights cannot be started.</Trans>,
  onSuccessClose: () => redirectTo('dashboard'),
  autoSuccessClose: 1000
} : {
  loadingTitle: <Trans>Ending simulation, please wait.</Trans>,
  successTitle: <Trans>Simulation session stopped.</Trans>,
  errorTitle: <Trans>The simulation of the user rights cannot be ended.</Trans>,
  onSuccessClose: () => redirectTo('organisation-user-management'),
  autoSuccessClose: 1000
})(() => RestService('POST', '/organisation/simulate-rights', { entityID }))


// ==================================================================================================


const TempEntityEndorsementBar: React.FC<{ entID: string }> = ({ entID }) => {
  const isPhone = useContext(DeviceContext) === 'phone'
  useLayoutEffect(() => {
    const { body } = document
    body.classList.add('withEntityEndorsementBar')
    return () => body.classList.remove('withEntityEndorsementBar')
  }, [])

  return (
    <div className='entityEndorsementBar'>
      <h1>
        <UserAvatarByID className='intern' size='2rem' userID={entID} />
        {isPhone ? (
          <span><Trans><UserFullName userID={entID} />'s rights</Trans></span>
        ) : (
          <span><Trans>You are now simulating <UserFullName userID={entID} />'s rights</Trans></span>
        )}
      </h1>
      <Button ghost onClick={() => entityEndorsementAction()} type='primary'>
        {isPhone ? <CloseOutlined /> : <Trans>Close</Trans>}
      </Button>
    </div>
  )
}

export default TempEntityEndorsementBar
