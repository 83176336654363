import React, { useContext } from 'react'

import { UserAndCompanyDataContext } from '../../../../../../Layouts/Constants'
import { UserAvatar, userFullName } from '../../../../../../Organisations/UserRoleDisplay/UserAvatarList'

import './CurrentUserCardStyles.scss'


const CurrentUserCard: React.FC<{ user: any }> = ({ user }) => {
  const { company: { members, teams } } = useContext(UserAndCompanyDataContext)
  const role = user.role || user.roleInCompany || teams?.[members?.[user.userID]?.team]?.name

  return (
    <div className='currentUserCard'>
      <UserAvatar autoSide noTooltip size='2rem' user={user} />
      <div className='userDetails'>
        <h3>{userFullName(user)}</h3>
        {role && <p>{role}</p>}
      </div>
    </div>
  )
}

export default CurrentUserCard
