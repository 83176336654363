import React, { useContext } from 'react'
import { Trans } from '@lingui/macro'

import { ConditionalText, InputField } from '@top-legal/datastore'
import { RenderOnlyEditor, isInlineConditional, ParagraphType } from '@top-legal/editor'

import { ModalRefContext } from '../../../TemplateEntities/TemplateEntitiesForms/TemplateEntitiesFormsModal'
import { dateTypes, timeTypes } from '../../../../../Template/TemplateHelperFunctions'
import { displayDate } from '../../../../../Template/_TemplateHelperFunctions'

import { Identity, Search } from './Types'
import EntityTitle from './EntityTitle'


export const SimplifiedText: React.FC<{ value: any } & Search> = ({
  value,
  search
}) => {
  if (Array.isArray(value)) {
    let finalValue = [...value].slice(0, 1)

    if (value.some(elm => typeof elm.text === 'string')) {
      finalValue = [{
        type: ParagraphType,
        children: value
      }]
    } else if (finalValue[0] && ['ul', 'ol'].includes(finalValue[0].type)) {
      finalValue[0] = {
        ...finalValue[0],
        children: [...finalValue[0].children].slice(0, 1)
      }
      if (finalValue[0].children[0]) {
        finalValue[0].children[0] = {
          ...finalValue[0].children[0],
          children: [...finalValue[0].children[0].children].slice(0, 1)
        }
      }
    }

    return (
      <span className='simplifiedText'>
        <RenderOnlyEditor nodes={finalValue} search={search} />
      </span>
    )
  }

  return <span className='simplifiedText'>{value || null}</span>
}


export const DisplayConditionalValues: React.FC<{ entity: ConditionalText, field: InputField, lang: string } & Search> = ({
  entity,
  field,
  lang,
  search
}) => {
  const displayText = value => (
    <td className='conditionalTextValue'>
      <SimplifiedText search={search} value={value} />
    </td>
  )

  let children: any = null

  if (field.type === 'list') {
    const fieldValues = field.values[lang] || field.values

    if (Object(entity.values) === entity.values) {
      children = Object.entries(entity.values)
        .map(([key, value]) => (
          <tr key={key}>
            <td className='conditionalFieldValue'>{fieldValues[key]}</td>
            {displayText(value)}
          </tr>
        ))
    }
  } else if (/(date|time|number|amount|listOfFormattedText)/.test(field.type)) {
    let getLabel: any = () => <Trans>Unknown</Trans>
    if (/(date|time)/.test(field.type)) {
      getLabel = item => {
        const timeType = (field.type === 'date' ? dateTypes : timeTypes)[item.type]
        if (field.type === 'date') {
          if (entity.isRelativeToContractFillingDate) {
            if (item.value < 0) {
              return <Trans>{-item.value} past {timeType}</Trans>
            }
            if (item.value === 0) {
              return <Trans>today</Trans>
            }
            return <Trans>{item.value} next {timeType}</Trans>
          }
          return <>{'<='} {displayDate(item.value)}</>
        }
        return <>{item.value} {timeType}</>
      }
    } else if (/(number|listOfFormattedText)/.test(field.type)) {
      getLabel = item => <>{'<='} {item.value}</>
    }

    if (Array.isArray(entity.values)) {
      children = entity.values.map((element, index) => (
        <tr key={index}>
          <td className='conditionalFieldValue'>{getLabel(element)}</td>
          {displayText(element.text)}
        </tr>
      ))
    }
  } else if (field.type === 'yesNo') {
    children = []
    if (entity.values && entity.values.yes) {
      children.push(
        <tr key='yes'>
          <td className='conditionalFieldValue'><Trans>Yes</Trans></td>
          {displayText(entity.values.yes)}
        </tr>
      )
    }
    if (entity.values && entity.values.no) {
      children.push(
        <tr key='no'>
          <td className='conditionalFieldValue'><Trans>No</Trans></td>
          {displayText(entity.values.no)}
        </tr>
      )
    }
  }
  return children as any
}


const DisplayConditional: React.FC<{ cond: ConditionalText, field: InputField, lang: string, inlinesOnly?: boolean, noActions?: boolean } & Search> = ({
  cond,
  field,
  lang,
  inlinesOnly,
  noActions,
  search,
  Highlight = Identity
}) => {
  const { modalRef } = useContext<any>(ModalRefContext)

  return (
    <EntityTitle
      className='conditionalText'
      entityKey={(cond as any).key}
      entityTitle={cond.name}
      entityType='conditionalText'
      noDrag={inlinesOnly && !isInlineConditional(cond)}
      onClick={() => !noActions && modalRef.current.createOrEditConditionalText(undefined, (cond as any).key, undefined, 1)}
      version={cond.version}
    >
      <span className='conditionalTitle'>[<Highlight text={cond.name} />]</span>
      <table className='conditionalValues'>
        <tbody>
          <DisplayConditionalValues
            Highlight={Highlight}
            entity={cond}
            field={field}
            lang={lang}
            search={search}
          />
        </tbody>
      </table>
    </EntityTitle>
  )
}

export default DisplayConditional
