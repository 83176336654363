import React, { useContext } from 'react'
import { Card } from 'antd'

import './StatisticsBoxStyles.scss'
import { DeviceContext } from '../../GlobalContext'

const StatisticsBox = ({ title, Component, width, height, data, boxKey, templates, mobileHeight = height, ...props }) => {
  const device = useContext(DeviceContext)
  const maxWidth = { phone: 2, tablet: 4, desktop: 12 }[device]
  const styles = {
    gridColumn: `span ${Math.min(maxWidth, width)}`
    // gridRow: device === 'phone' ? undefined : `span ${height}`
  }
  return (
    <Card bordered={false} className={`statisticsBoxContent ${boxKey}`} style={styles} title={title}>
      <Component {...props} data={data} templates={templates} />
    </Card>
  )
}

export default StatisticsBox
