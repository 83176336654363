import React, { useContext } from 'react'

import { GoogleFontSelectorProvider } from '@top-legal/react-helpers'

import { ContractContext } from '../Contexts'
import { Signature } from './Utils/Types'
import {
  useHasChangesToVerify,
  VerifyChangesDrawer
} from '../Sidebar/SidebarToolboxes/UserManagement/VerifyBeforeSigningDrawer'
import SigningPhoneDrawer from './SigningPhoneDrawer'
import SigningPinLessDrawer from './SigningPinLessDrawer'

import '../Sidebar/SidebarToolboxes/SigningToolboxStyles.scss'


interface SigningDrawerProps {
  signature: Signature
  visible: boolean
  close: () => void
  onFinishSigning?: () => void
}


const SigningDrawer: React.FC<SigningDrawerProps> = props => {
  const { contract: { signaturesHolder: { signingFlow = 'pinLess' } = {} } } = useContext<any>(ContractContext)
  const [changesHasBeenVerified, setChangesVerified] = useHasChangesToVerify(props.visible)

  return (
    <>
      <VerifyChangesDrawer
        close={props.close}
        onConfirm={setChangesVerified}
        visible={props.visible && !changesHasBeenVerified}
      />
      {signingFlow === 'pinLess' ? (
        <SigningPinLessDrawer {...props} visible={props.visible && changesHasBeenVerified} />
      ) : (
        <SigningPhoneDrawer {...props} visible={props.visible && changesHasBeenVerified} />
      )}
    </>
  )
}


const googleFontOptions = {
  //  Allow all fonts of handwriting
  // fontCategories: ['handwriting'],
  //  Restrict to predefined list
  fontList: ['Sacramento', 'Shadows Into Light', 'Cookie', 'Give You Glory', 'Meddon', 'Indie Flower', 'Nothing You Could Do']
}

const SigningDrawerWithFonts: React.FC<SigningDrawerProps> = props => {
  const { contract } = useContext<any>(ContractContext)

  const content = <SigningDrawer {...props} />

  //  In case of contract in signing mode we need to wrap with google fonts
  if (contract.signaturesHolder?.withHandwritingSignature) {
    return <GoogleFontSelectorProvider {...googleFontOptions}>{content}</GoogleFontSelectorProvider>
  }

  return content
}

export default SigningDrawerWithFonts
