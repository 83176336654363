import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Collapse, Skeleton } from 'antd'
import CaretRightOutlined from '@ant-design/icons/lib/icons/CaretRightOutlined'
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined'

import {
  Contract,
  InputField,
  Template,
  useContractsCollection,
  useDoc,
  useFieldsCollection,
  useTemplatesCollection
} from '@top-legal/datastore'

import ContractAssistantContext, { ContractAssistantContextProps } from './ContractAssistantContext'
import useGetAssistantSteps, { AssistantStep } from './useGetAssistantSteps'
import AssistantFieldInput from './assistantFieldsInputs/AssistantFieldInput'
import FieldError from './assistantFieldsInputs/FieldError'

import incrementWithGlobalFields from './utils/incrementWithGlobalFields/incrementWithGlobalFields'
import getFieldValue from './utils/getFieldValue'
import { buildMap } from './useGetGetters'

import './ContractAssistantStyles.scss'


export interface ExtraStep {
  key: string
  title: React.ReactNode
  required: boolean
  done: boolean
  children: React.ReactNode
}

export interface ContractAssistantWithExtraSteps extends ContractAssistantContextProps {
  before?: ExtraStep[]
  after?: ExtraStep[]
  onStats?: (stats: { done: number, total: number }) => void
}

export interface ContractAssistantWrapperProps extends Omit<ContractAssistantWithExtraSteps, 'template' | 'contract' | 'fieldsMap' | 'getCombinedListSteps'> {
  templateID: string
  contractID: string
}


type FMap = Map<string, InputField>

const CollapseHeader: React.FC<{ done: boolean }> = ({ done, children }) => (
  <>
    <h3>{children}</h3>
    <CheckOutlined className={done ? 'done' : ''} />
  </>
)

const expandIcon = ({ isActive }: any) => <CaretRightOutlined rotate={isActive ? 90 : 0} />


/** ****************************************************************************************
 *            Contract assistant part, render a collapse with each field
 **************************************************************************************** */
const emptyArray = []
const ContractAssistant: React.FC<ContractAssistantWithExtraSteps & { steps: AssistantStep[] }> = ({
  fieldsMap, onFieldOpened, initialFieldID,
  steps, before = emptyArray, after = emptyArray,
  onStats
}) => {
  const { contract: { fieldsResponse } } = useContext(ContractAssistantContext)
  const [activeField, setActiveField] = useState(initialFieldID || '')
  const firstUnfilledRef = useRef(activeField)

  //  Build additional before steps
  const [beforeSteps, beforeDone] = useMemo(() => {
    let allDone = true

    const steps = before.map(({ key, title, done, children }) => {
      if (!done && firstUnfilledRef.current !== key) {
        allDone = false
        firstUnfilledRef.current = key
        setActiveField(key)
      }

      return (
        <Collapse.Panel key={key} header={<CollapseHeader done={done}>{title}</CollapseHeader>}>
          {children}
        </Collapse.Panel>
      )
    })

    if (allDone) {
      firstUnfilledRef.current = ''
    }

    return [steps, allDone]
  }, [before])


  const [fieldSteps, fieldsToSection, nbDone, total] = useMemo(
    () => {
      let numberFields = 0
      let numberCompleted = 0
      const map: Record<string, string> = {}

      const goNextField = () => {
        firstUnfilledRef.current = ''
        setActiveField('')
      }

      const children = steps.map(({ sectionID, fieldIDs }) => fieldIDs.map(fieldID => {
        const [baseKey] = fieldID.split('.')
        const field = fieldsMap.get(baseKey)
        map[fieldID] = sectionID

        if (field) {
          const [value, child] = getFieldValue(fieldsMap, fieldID, fieldsResponse, false) || []
          const done = value != null

          if (!firstUnfilledRef.current && !done) {
            firstUnfilledRef.current = fieldID
            setActiveField(fieldID)
          }

          numberFields += 1
          if (done) { numberCompleted += 1 }

          return (
            <Collapse.Panel key={fieldID} header={<CollapseHeader done={done}>{child?.name || '-'}</CollapseHeader>}>
              <AssistantFieldInput field={field} fieldKey={fieldID} goNextField={goNextField} />
            </Collapse.Panel>
          )
        }

        if (process.env.NODE_ENV !== 'production') {
          console.error('Cannot find the field', fieldID)
          return <FieldError>Cannot find the field {fieldID}</FieldError>
        }

        return null
      })) as any

      return [children, map, numberCompleted, numberFields]
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fieldsMap, fieldsResponse, steps, activeField]
  )


  //  Build the additional after steps
  const allDone = beforeDone && nbDone === total
  const afterSteps = useMemo(() => after.map(({ key, title, done, children }) => {
    if (allDone && !done) {
      firstUnfilledRef.current = key
      setActiveField(key)
    }

    return (
      <Collapse.Panel key={key} header={<CollapseHeader done={done}>{title}</CollapseHeader>}>
        {children}
      </Collapse.Panel>
    )
  }), [after, allDone])


  //  Update statistics to the parent
  useEffect(() => {
    const count = (acc, { done, required }) => acc + ((!required || done) ? 1 : 0)
    onStats?.({
      done: nbDone + before.reduce(count, 0) + after.reduce(count, 0),
      total: total + before.length + after.length
    })
  }, [onStats, nbDone, total, before, after])

  useEffect(
    () => onFieldOpened?.(activeField, fieldsToSection[activeField]),
    [activeField, fieldsToSection, onFieldOpened]
  )


  //  return the collapse
  return (
    <div className='contractAssistant'>
      <Collapse
        accordion
        activeKey={activeField}
        expandIcon={expandIcon}
        ghost
        onChange={(val: any) => {
          if (val !== activeField) {
            setActiveField(val)
          }
        }}
      >
        {beforeSteps}
        {fieldSteps}
        {afterSteps}
      </Collapse>
    </div>
  )
}
export const ContractAssistantInternal = ContractAssistant


//


/** ****************************************************************************************
 *           Create a wrapper that load the fields from the dependencies
 **************************************************************************************** */
export type ContractAssistantProps = Omit<ContractAssistantWithExtraSteps, 'fieldsMap' | 'getCombinedListSteps'>
const ContractAssistantWithData: React.FC<ContractAssistantProps> = props => {
  const { getTemplateSteps, getCombinedListSteps, loading: assistantLoading, flaternisation } = useGetAssistantSteps(props.template)
  const { template, contract: { fieldsResponse }, currentCompanyName, currentPersonName } = props
  const { lang, fieldsData } = template as any
  const steps = useMemo(() => getTemplateSteps(fieldsResponse), [getTemplateSteps, fieldsResponse])

  //  Gather the fields
  const [fieldLoading, setFieldLoading] = useState(true)
  const [fieldsMap, setFieldsMap] = useState<FMap>(new Map())

  const fields = flaternisation?.fieldsDependencies
  const fieldsCollection = useFieldsCollection()
  useMemo(() => {
    if (Object(fieldsData) === fieldsData) {
      setFieldsMap(incrementWithGlobalFields(buildMap(fieldsData, 'inputFieldID'), lang, currentCompanyName, currentPersonName))
      setFieldLoading(false)
    } else if (fields) {
      fieldsCollection.findByIds(Object.keys(fields))
        .then(map => setFieldsMap(incrementWithGlobalFields(map, lang, currentCompanyName, currentPersonName)))
        .finally(() => setFieldLoading(false))
    }
  }, [fieldsData, fields, fieldsCollection, lang, currentCompanyName, currentPersonName])

  const context = useMemo<ContractAssistantContextProps>(
    () => ({ ...props, fieldsMap, getCombinedListSteps }),
    [props, fieldsMap, getCombinedListSteps]
  )

  if (assistantLoading || fieldLoading) {
    return <Skeleton active />
  }

  return (
    <ContractAssistantContext.Provider value={context}>
      <ContractAssistant {...context} steps={steps} />
    </ContractAssistantContext.Provider>
  )
}

export default ContractAssistantWithData


//


/** ****************************************************************************************
 *                  Create a second wrapper to load data from the IDs
 **************************************************************************************** */
const toJSON = value => value.toJSON()
export const ContractAssistantWrapper: React.FC<ContractAssistantWrapperProps> = ({
  templateID, contractID, ...props
}) => {
  const templatesCollection = useTemplatesCollection()
  const [template, setTemplate] = useState<Template>()
  useMemo(() => {
    templatesCollection.findOne(templateID).exec().then(tpl => tpl && setTemplate(tpl.toJSON() as Template))
  }, [templateID, templatesCollection])

  const defaultContract = useMemo(() => ({ contractID, fieldsResponse: {} } as Contract), [contractID])
  const contractsCollection = useContractsCollection()
  const [contract] = useDoc<Contract>(
    useMemo(() => contractsCollection.findOne(contractID), [contractID, contractsCollection]),
    toJSON
  )

  if (!template) {
    return <Skeleton active />
  }

  return (
    <ContractAssistantWithData
      {...props}
      contract={contract || defaultContract}
      template={template}
    />
  )
}
