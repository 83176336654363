import React, { useCallback, useMemo, useRef, useState } from 'react'
import { t, Trans } from '@lingui/macro'
import { Button, Card, Collapse, Drawer, Form, Input } from 'antd'
import { CheckOutlined, EditOutlined, LockOutlined, PlusOutlined } from '@ant-design/icons'

import { ContractingParty, saveInStore, useContractingPartiesCollection, useList } from '@top-legal/datastore'

//  Components
import loadingModalFeedback from '../../../Alert/LoadingModalFeedback'
import { GoogleAutoCompleteAddress, PhoneInput, Select } from '../../../SharedComponents/FormComponents'
import CustomFormWrapper, { requiredRules } from '../../../SharedComponents/CustomFormWrapper'
import RestService from '../../../../RestService'
import CreateCompanyForm from './CreateCompanyForm'
import { OwningTeamFormItem } from '../../../ContactList/ContactList'
import { emptyObject } from '../../../Listing/SearchHelpers'

interface FormWrapperProps {
  initialValue?: any
  onFinish: (party: any) => void
}

export interface CreateFormProps {
  initialValue?: any
  visible: boolean
  onClose: () => void
  onFinish: (party: any) => void
}

interface PersonFormProps extends CreateFormProps {
  userAddressRequired?: boolean
  companyRequired?: boolean
}

// =================================================================================================
const PhoneInputTSX = PhoneInput as any
const GoogleAutoCompleteAddressTSX = GoogleAutoCompleteAddress as any


// =================================================================================================


export const savingCounterParty = () => loadingModalFeedback({
  loadingTitle: <Trans>Saving counterparty</Trans>,
  successTitle: <Trans>Counterparty saved</Trans>,
  errorTitle: <Trans>Failed to save your counterparty</Trans>,
  errorDescription: <Trans>An unexpected error occurred while saving your counterparty. Please try again later.</Trans>,
  autoSuccessClose: 2000
  // eslint-disable-next-line no-return-assign
})


// =================================================================================================


export const FormWrapper: React.FC<FormWrapperProps> = ({
  initialValue,
  onFinish,
  children
}) => {
  const partiesCollection = useContractingPartiesCollection()

  return (
    <CustomFormWrapper
      className='formCard'
      initialValues={initialValue}
      onSubmit={useCallback(values => savingCounterParty()(async () => {
        const data = { ...(initialValue || {}), ...values }
        let party
        if (data.partyID) {
          party = await RestService('PUT', `/contact/${data.partyID}`, data)
        } else {
          party = await RestService('POST', '/contact', data)
        }
        await saveInStore(partiesCollection, party)
        onFinish(party)
      }), [initialValue, onFinish, partiesCollection])}
    >
      {form => (
        <>
          {(typeof children === 'function' && !(children as any).$$typeof) ? (children as any)(form) : children}
          <div className='formDesign'>
            <Button
              className='buttonCentred' htmlType='submit' icon={<CheckOutlined />}
              type='primary'
            >
              <Trans>Save counterparty</Trans>
            </Button>
          </div>
        </>
      )}
    </CustomFormWrapper>
  )
}


// =================================================================================================


// =================================================================================================


const CreateContactForm : React.FC<PersonFormProps> = ({
  initialValue: initialValue_,
  visible,
  onClose,
  onFinish,
  userAddressRequired,
  companyRequired
}) => {
  //  Safe guard of RxDoc
  const initialValue = useMemo(() => initialValue_ && ((initialValue_.toJSON && initialValue_.toJSON()) || initialValue_), [initialValue_])

  const formRef = useRef<any>()
  const [showCreateCompany, setShowCreateCompany] = useState(false)

  const createNewButton = useMemo(() => (
    <Button
      block
      icon={<PlusOutlined />}
      onClick={evt => {
        evt.preventDefault()
        evt.stopPropagation()
        setShowCreateCompany(true)
      }}
      type='primary'
    >
      <Trans>Create new</Trans>
    </Button>
  ), [])

  const selectProps = useMemo(() => ({
    alwaysShown: elm => elm === createNewButton,
    selectable: elm => elm !== createNewButton
  }), [createNewButton])

  const partiesCollection = useContractingPartiesCollection()
  const [allCompaniesName = emptyObject] = useList<ContractingParty, any, { [partyID: string]: string }>(
    useMemo(() => partiesCollection.find().where('type').eq('company').sort({ updatedAt: 'desc' }), [partiesCollection]),
    useCallback(allCompanies => {
      const mapping: any = {}
      allCompanies.forEach(contact => {
        if (contact.name) {
          mapping[contact.partyID] = contact.name
        }
      })
      mapping.__CREATE_NEW__ = createNewButton
      return mapping
    }, [createNewButton])
  )

  return (
    <>
      <Drawer
        className='drawerContact'
        destroyOnClose
        footer={null}
        maskClosable={false}
        onClose={onClose}
        // Create a new contact on top.legal.
        title={<><EditOutlined className='headerIcon' twoToneColor='#3DBD7D' />{initialValue ? (<Trans>Edit contact</Trans>) : (<Trans>Add contact</Trans>)}</>}
        visible={visible}
        zIndex={10010}
      >
        <FormWrapper initialValue={initialValue} onFinish={onFinish}>
          {form => {
            formRef.current = form
            return (
              <Card title={initialValue ? (<Trans>contact</Trans>) : (<Trans>New contact</Trans>)}>
                <Form.Item hidden initialValue='person' name='type'>
                  <Input type='hidden' />
                </Form.Item>
                <Form.Item name='firstName' rules={requiredRules}>
                  <Input className='simple-line' placeholder={`${t`First name`}*`} />
                </Form.Item>
                <Form.Item name='lastName' rules={requiredRules}>
                  <Input className='simple-line' placeholder={`${t`Last name`}*`} />
                </Form.Item>
                <Form.Item
                  name='email'
                  rules={[...requiredRules, {
                    type: 'email',
                    message: <Trans>Please provide a valid email</Trans>
                  }]}
                >
                  <Input className='simple-line' placeholder={`${t`Email`}*`} type='email' />
                </Form.Item>
                <Form.Item name='phoneNumber'>
                  <PhoneInputTSX className='simple-line' />
                </Form.Item>
                {userAddressRequired ? (
                  <GoogleAutoCompleteAddressTSX form={form} initialValue={initialValue?.address || {}} prefix='address' required />
                ) : (
                  <Collapse accordion bordered={false} className='styleCollapse'>
                    <Collapse.Panel key='1' header={<b><Trans>User address</Trans></b>}>
                      <GoogleAutoCompleteAddressTSX form={form} initialValue={initialValue?.address || {}} prefix='address' />
                    </Collapse.Panel>
                  </Collapse>
                )}
                <h5 className='styleTitle'><Trans>Attach contact to a company</Trans>{companyRequired && '*'}</h5>
                <Form.Item name='companyPartyID' rules={companyRequired ? requiredRules : undefined}>
                  <Select
                    {...selectProps}
                    className='simple-line'
                    items={allCompaniesName}
                    placeholder={<><Trans>Match company</Trans>{companyRequired && '*'}</>}
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <OwningTeamFormItem initialValue={initialValue} />
              </Card>
            )
          }}
        </FormWrapper>
      </Drawer>
      <CreateCompanyForm
        onClose={() => setShowCreateCompany(false)}
        onFinish={({ partyID }) => {
          if (formRef.current) {
            formRef.current.setFieldsValue({ companyPartyID: partyID })
          }
          setShowCreateCompany(false)
        }}
        visible={showCreateCompany}
      />
    </>
  )
}

export default CreateContactForm
