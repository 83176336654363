import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

const Portal: React.FC<{ cssSelector: string, position?: 'first' | 'last' }> = ({
  cssSelector, position = 'last', children
}) => {
  const [elem, setElem] = useState<HTMLDivElement>()
  const triedRef = useRef(0)

  const findElement = useCallback(() => {
    triedRef.current += 1
    const root = document.querySelector(cssSelector)

    if (root) {
      if (position === 'first') {
        const div = document.createElement('div')
        div.className = 'portalContainer'

        root.insertBefore(div, root.childNodes[0])
        setElem(div)
      } else {
        setElem(root as any)
      }
    } else if (triedRef.current < 5) {
      setTimeout(findElement, 100)
    }
  }, [cssSelector, position])

  //  Fire search of element at rendering time
  useLayoutEffect(() => {
    findElement()
  }, [findElement])

  //  Cleanup at unmount
  useLayoutEffect(() => () => {
    triedRef.current = 0
    if (elem && position === 'first') {
      elem.remove()
    }
  }, [position, elem])

  return elem ? ReactDOM.createPortal(children, elem) : null
}

export default Portal