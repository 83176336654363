import { useCallback, useContext } from 'react'

import { ContractEditorContextProps } from '@top-legal/editor'

import { ModalRefContext } from '../../TemplateEntities/TemplateEntitiesForms/TemplateEntitiesFormsModal'

const useOnTemplateEntityClick: ContractEditorContextProps['useOnTemplateEntityClick'] = () => {
  const { modalRef } = useContext<any>(ModalRefContext)

  return useCallback((entityType, key) => {
    const modalInstance = modalRef?.current

    if (modalInstance) {
      if (entityType === 'inputField') {
        modalInstance.editInputField(key)
      }
      if (entityType === 'conditionalText') {
        modalInstance.createOrEditConditionalText(undefined, key, undefined, 1)
      }
    }
  }, [modalRef])
}

export default useOnTemplateEntityClick
