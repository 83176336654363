import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { t, Trans } from '@lingui/macro'
import { Button, Form, Input } from 'antd'
import { InputRef } from 'rc-input/lib/interface'
import { CommentOutlined, SendOutlined } from '@ant-design/icons'
import moment from 'moment'
import Portal from '../../../../SharedComponents/Portal'
import { ExternalContext } from '../../../../ExternalViews/ExternalContext'
import { defaultUser, UserAndCompanyDataContext } from '../../../../Layouts/Constants'
import CustomFormWrapper from '../../../../SharedComponents/CustomFormWrapper'
import { UserAvatarByID, UserFullName } from '../../../../Organisations/UserRoleDisplay/UserAvatarList'
import { FormatText } from './Commenting/CommentDisplay'

import LoadingFeedback from '../../../../Alert/LoadingFeedback'
import RestService from '../../../../../RestService'

import '../../../../ChatBot/ChatBotStyles.scss'
import './ChatToolboxStyles.scss'
import { DeviceContext } from '../../../../../GlobalContext'

interface Message {
  userID: string,
  date: string,
  contractID: string,
  text: string
}

const ChattersAvatars: React.FC<{ messages: any, token: string | undefined }> = ({ messages, token }) => {
  const chatters: any[] = [...new Set(messages.map(item => item.userID))]
  const externChatters: any[] = chatters.filter(userID => (userID.startsWith('party-') || userID.startsWith('contact-') || userID === defaultUser.userID))
  const internChatters: any[] = chatters.filter(item => !externChatters.includes(item))
  return (
    <div className={`chattersAvatars ${token ? 'extern' : 'intern'}`}>
      <div className='externChatters'>
        {
          externChatters
          && externChatters.slice(0, 9).map(userID => (
            <UserAvatarByID
              key={userID}
              className='extern'
              size='1.6rem'
              userID={userID}
            />
          ))
        }
      </div>
      <div className='internChatters'>
        {
          internChatters
          && internChatters.slice(0, 9).map(userID => (
            <UserAvatarByID
              key={userID}
              className='intern'
              size='1.6rem'
              userID={userID}
            />
          ))
        }
      </div>
    </div>
  )
}

const ChatToolbox: React.FC<any> = ({ instanceID, currentBoxRef }) => {
  const { tokenData = {} } = useContext(ExternalContext)
  const { token } = tokenData
  const isPhone = useContext(DeviceContext) === 'phone'
  const { user, company } = useContext(UserAndCompanyDataContext)
  const containerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<InputRef>(null)

  const [msgLoading, setMsgLoading] = useState(false)
  const [postLoading, setPostLoading] = useState(false)

  const [messages, setMessages] = useState<Message[]>([])
  const [newNotification, setNewNotification] = useState(false)

  const lastLoad = useRef<string>('')

  const timeoutRef = useRef<NodeJS.Timeout>()
  const loadMessages = useCallback(async () => {
    if (currentBoxRef.current === 'chat') {
      setNewNotification(false)
    }

    setMsgLoading(true)
    const { messages: newMsg, newLastLoad, users } = token
      ? await RestService('GET', `/token/${token}/chat?lastLoad=${lastLoad.current}&markAsRead=${(currentBoxRef.current === 'chat')}`, null, false)
      : await RestService('GET', `/chat/${instanceID}?lastLoad=${lastLoad.current}&markAsRead=${(currentBoxRef.current === 'chat')}`)
    lastLoad.current = newLastLoad
    const newMessage = newMsg.find(msg => (msg.date > users[user.userID !== defaultUser.userID ? user.userID : token]))
    if (newMessage) {
      setNewNotification(old => old || currentBoxRef.current !== 'chat')
    }
    if (newMsg.length > 0) {
      setMessages(old => [...old, ...newMsg])
    }
    setMsgLoading(false)

    //  Next reload in 10sec
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = undefined
    }
    timeoutRef.current = setTimeout(loadMessages, 10000)
  }, [currentBoxRef, token, instanceID, user.userID])

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useEffect(() => {
    loadMessages()

    //  Cleanup loading timeout when component is unmounted
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = undefined
      }
    }
  }, [loadMessages])

  const numberMsgRef = useRef(0)
  useLayoutEffect(() => {
    !isPhone && inputRef.current?.focus()
    if (containerRef.current && messages.length !== numberMsgRef.current) {
      numberMsgRef.current = messages.length
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  })

  return (
    <div className='externalChat'>
      <Portal cssSelector='#chatAvatars'><ChattersAvatars messages={messages} token={token} />
      </Portal>
      {newNotification && (
        <Portal cssSelector='#notificationDot'><span className='notification-dot' />
        </Portal>
      )}
      <div ref={containerRef} className={`conversationChat ${token ? 'extern' : ''}`}>
        <LoadingFeedback loading={msgLoading}><Trans>Up to date</Trans></LoadingFeedback>
        {messages.map((msg, index) => {
          const isExternal = (msg.userID.startsWith('party-') || msg.userID.startsWith('contact-') || msg.userID === defaultUser.userID)
          const externalSides = isExternal ? 'right' : 'left'
          const internalSides = (company.members[msg.userID] ? 'right' : 'left')
          if (index > 0 && (messages[index].userID === messages[index - 1].userID) && ((new Date(messages[index].date).valueOf() - new Date(messages[index - 1].date).valueOf()) < 180000)) {
            return (
              <div key={msg.date} className={`conversationMessage ${token ? externalSides : internalSides} same`}>
                <div className='messageContent'>
                  <p><FormatText text={msg.text} /></p>
                </div>
              </div>
            )
          }
          return (
            <div key={msg.date} className={`conversationMessage ${token ? externalSides : internalSides}`}>
              <div className='top-message'>
                <UserAvatarByID
                  className={isExternal ? 'extern' : 'intern'}
                  size='1.9rem'
                  userID={msg.userID}
                />
                <div className='messageDetails'>
                  <p className='date'>{moment(msg.date).calendar()}</p>
                  <p className='username'><UserFullName userID={msg.userID} /></p>
                </div>
              </div>
              <div className='messageContent'>
                <p><FormatText text={msg.text} /></p>
              </div>
            </div>
          )
        })}
      </div>
      <CustomFormWrapper
        className='submitBarChat visible'
        onSubmit={async ({ text }, form) => {
          setPostLoading(true)
          try {
            const msg: Message = {
              userID: user.userID,
              date: (new Date()).toISOString(),
              contractID: instanceID,
              text
            }
            if (msg.text.trim()) {
              if (token) {
                await RestService('POST', `/token/${token}/chat`, msg, false)
              } else {
                await RestService('POST', `/chat/${instanceID}`, msg)
              }
              setMessages(old => [...old, msg])
              form.resetFields()
            }
          } catch (err) {
            console.error('Error when posting message', err)
          }
          setPostLoading(false)
        }}
      >
        {form => (
          <>
            <Form.Item name='text'>
              <Input.TextArea
                ref={inputRef}
                autoSize={{ minRows: 1, maxRows: 5 }}
                disabled={postLoading}
                onKeyDown={evt => {
                  if (evt.key === 'Enter' && !evt.shiftKey) {
                    evt.preventDefault()
                    form.submit()
                  }
                }}
                placeholder={t`Chat`}
              />
            </Form.Item>
            <Button htmlType='submit' icon={<SendOutlined />} loading={postLoading} type='primary' />
          </>
        )}
      </CustomFormWrapper>
    </div>
  )
}

export default (instanceID: string) => ({
  key: 'chat',
  className: 'chatToolbox',
  text: <Trans>Chat</Trans>,
  helpText: <Trans>Stay in touch with your counter party to make your deal happen.</Trans>,
  icon: <CommentOutlined />,
  // eslint-disable-next-line react/display-name
  Component: props => <ChatToolbox {...props} instanceID={instanceID} />
})
