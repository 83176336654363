import React, { useContext, useMemo } from 'react'
import { useParams } from 'react-router'
import ExternalContextProvider, { ExternalContext } from './ExternalContext'
import ExternalContractEditing from './Editor/ExternalContractEditing'
import ExternalSigning from './ExternalPageSigning'
import ErrorPage from '../Error/ErrorPage'
import EmptyHeader from '../Layouts/EmptyHeader'
import { LangSwitchButtons } from '../LoginRegistration/LoginRegistrationPage'

const ErrorPageWrapped: React.FC = () => (
  <>
    <EmptyHeader><div><LangSwitchButtons /></div></EmptyHeader>
    <ErrorPage />
  </>
)

const ExternalContractPageTokenSwitch: React.FC = () => {
  const { tokenData } = useContext(ExternalContext)
  const Component = useMemo(() => {
    if (tokenData?.externalRole) {
      return {
        commenting: ExternalContractEditing,
        redlining: ExternalContractEditing,
        signing: ExternalSigning
      }[tokenData?.externalRole] || ErrorPageWrapped
    }
    return ErrorPageWrapped
  }, [tokenData?.externalRole])

  return <Component />
}

const ExternalPageSwitch: React.FC = () => {
  const { tokenOrContractID } = useParams()

  if (typeof tokenOrContractID !== 'string' || !tokenOrContractID.startsWith('token-')) {
    return <ErrorPageWrapped />
  }

  return (
    <ExternalContextProvider tokenID={tokenOrContractID}>
      <ExternalContractPageTokenSwitch />
    </ExternalContextProvider>
  )
}

export default ExternalPageSwitch
