import React from 'react'
import { useRouteMatch } from 'react-router'
import { Trans } from '@lingui/macro'
import { EditOutlined } from '@ant-design/icons'
import ButtonLink from '../../SharedComponents/ButtonLink'

import './FeedbackBoxStyles.scss'

const FeedbackBox = () => {
  const { url } = useRouteMatch()
  return (
    <div className='feedbackBox'>
      <h2><Trans>Would you like to see more statistics on this dashboard?</Trans></h2>
      <p><Trans>Your feedback and any idea are welcome, just submit it in the support section.</Trans></p>
      <ButtonLink href={url.replace('analytics', 'support?tab=feedback')} icon={<EditOutlined />} type='primary'><Trans>Submit a feedback</Trans></ButtonLink>
    </div>
  )
}

export default {
  key: 'feedback',
  title: <Trans>Feedback</Trans>,
  Component: FeedbackBox,
  width: 4,
  height: 5
}
