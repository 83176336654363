import React, { useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { Progress } from 'antd'
import { getConversion } from '../statisticsUtils'

import './ConversionStyles.scss'

const Conversion = ({ data }) => {
  const conversionRate = useMemo(() => getConversion(data), [data])

  return (
    <div className='chart'>
      <Progress
        className='phoneOptimisation'
        percent={Math.floor(conversionRate.signed * 100 / (conversionRate.signed + conversionRate.frozen))} strokeColor={{
          '0%': '#108ee9',
          '100%': '#87d068'
        }}
        type='circle'
      />
      <div className='numbers'>
        <h1> <Trans>{conversionRate.signed} Signed</Trans></h1>
        <div className='spaceSign' />
        <h1 className='sentSign'><Trans>{(conversionRate.signed + conversionRate.frozen)} Total sent to sign</Trans></h1>
      </div>
    </div>
  )
}

export default {
  key: 'conversion',
  title: <Trans>Contract Signing Rate</Trans>,
  Component: Conversion,
  width: 4,
  height: 5
}
