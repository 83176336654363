import React, { useCallback } from 'react'
import { Collection, Model, TypeConfiguration } from '../Types'
import { saveInStore } from '../Helpers'
import DatabaseContext from '../DatabaseContext'

export interface Comment extends Model {
  id: string // RxDB id concatenation
  instanceID: string
  instanceType: string
  userID: string
  date: string
  lastUpdated: string
  organisationID: string
  childID?: string

  //  Text & Task
  text?: string

  //  Task
  assignedTo?: string
  systemTask?: boolean
  done?: boolean

  //  Delta / Redlining
  delta?: any
  sectionVersion?: number
  rate?: string
  managedDate?: string
  managedBy?: string
  fromExternal?: boolean
  externalRate?: string
  externalyManagedDate?: string
  externalyManagedBy?: string
  appliedDate?: string
}

const config: () => TypeConfiguration<Comment> = () => JSON.parse(JSON.stringify({
  schema: {
    version: 0,
    primaryKey: 'id',
    type: 'object',
    properties: {
      appliedDate: {
        type: 'string'
      },
      assignedTo: {
        type: 'string'
      },
      childID: {
        type: 'string'
      },
      date: {
        type: 'string'
      },
      delta: {},
      done: {
        type: 'boolean'
      },
      externalRate: {
        type: 'string'
      },
      externalyManagedBy: {
        type: 'string'
      },
      externalyManagedDate: {
        type: 'string'
      },
      fromExternal: {
        type: 'boolean'
      },
      id: {
        type: 'string'
      },
      instanceID: {
        type: 'string'
      },
      instanceType: {
        type: 'string'
      },
      lastUpdated: {
        type: 'string'
      },
      managedBy: {
        type: 'string'
      },
      managedDate: {
        type: 'string'
      },
      organisationID: {
        type: 'string'
      },
      rate: {
        type: 'string'
      },
      sectionVersion: {
        type: 'number'
      },
      systemTask: {
        type: 'boolean'
      },
      text: {
        type: 'string'
      },
      userID: {
        type: 'string'
      }
    },
    required: [
      'id',
      'instanceID',
      'instanceType',
      'userID',
      'date',
      'lastUpdated',
      'organisationID'
    ],
    indexes: ['date']
  }
} as TypeConfiguration<Comment>))

export const otherCommentsConfig = { ...config(), key: 'other-comments' }
export const contractCommentsConfig = { ...config(), key: 'contracts-comments' }
export const templateCommentsConfig = { ...config(), key: 'templates-comments' }

export interface CommentsDataset {
  'other-comments': Comment[]
  'contracts-comments': Comment[]
  'templates-comments': Comment[]
}

export const useOtherCommentsCollection = (): Collection<Comment> => React.useContext(DatabaseContext)[otherCommentsConfig.key]
export const useContractCommentsCollection = (): Collection<Comment> => React.useContext(DatabaseContext)[contractCommentsConfig.key]
export const useTemplateCommentsCollection = (): Collection<Comment> => React.useContext(DatabaseContext)[templateCommentsConfig.key]

export const useSaveCommentInStore = () => {
  const database = React.useContext(DatabaseContext)

  return useCallback(async (update: Comment) => {
    const { instanceID } = update
    if (typeof instanceID === 'string') {
      //  Need to create the primary key
      update.id = `${update.instanceID}-${update.date}`

      if (instanceID.startsWith('contract-')) {
        await saveInStore<Comment>(database[contractCommentsConfig.key], update)
      } else if (instanceID.startsWith('template-')) {
        await saveInStore<Comment>(database[templateCommentsConfig.key], update)
      } else {
        await saveInStore<Comment>(database[otherCommentsConfig.key], update)
      }
    }
  }, [database])
}

export const commentsGQLQuery = `
{
  items {
    instanceID
    childID
    userID
    instanceType
    date
    lastUpdated
    organisationID
  
    # Text & Task
    text
  
    # Task
    assignedTo
    dueDate
    systemTask
    done
  
    # Delta / Redlining
    delta
    sectionVersion
    rate
    managedDate
    managedBy
    appliedDate
    fromExternal
    externalRate
    externalyManagedDate
    externalyManagedBy
  }
  nextToken
}`
