import React from 'react'

import { AssistantInputProps } from '../Types'

export const InputQuestionBox: React.FC = ({ children }) => (
  <div className='contractAssistantQuestion'>{children}</div>
)


const InputQuestion: React.FC<Pick<AssistantInputProps, 'field'>> = ({ field }) => (field.question ? (
  <InputQuestionBox>
    <p>{field.question}</p>
  </InputQuestionBox>
) : null)

export default InputQuestion
