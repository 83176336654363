import React, { useLayoutEffect } from 'react'
import { useFocused, useSelected } from 'slate-react'

const SelectedEntity: React.FC<{ Elem: React.ElementType, elmRef?: React.MutableRefObject<any> } & { [key: string]: any }> = ({
  Elem, elmRef, ...props
}) => {
  const focus = useFocused()
  const selected = useSelected()

  useLayoutEffect(() => {
    const elem = elmRef?.current
    if (elem && focus && selected) {
      elem.classList.add('selected')
      return () => elem.classList.remove('selected')
    }
    return undefined
  }, [elmRef, focus, selected])

  return <Elem ref={elmRef} {...props} />
}

export default SelectedEntity
