import React, { useCallback, useContext, useRef, useState } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { Trans } from '@lingui/macro'
import { CloseOutlined, ProfileOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { ContractContext, TemplateContext } from '../Contexts'

import LayoutSidebar from '../../../Layouts/LayoutSidebar'
import { StickyMainContent } from '../../../Layouts/MainLayout/MainContainer'

import './TableOfContentStyles.scss'


const getSectionTitle = (sectionID: string, onClick: (sectionID: string) => void, sectionMapping: any) => {
  const shortID = sectionID.split('-').pop() as string
  const props: any = { onClick: () => onClick(sectionID) }

  const html = `<span class="sectionReference" data-key="${shortID}" contenteditable="false"></span>`
  props.draggable = 'true'
  props.onDragStart = event => {
    event.dataTransfer.dropEffect = 'copy'
    event.dataTransfer.effectAllowed = 'copy'
    event.dataTransfer.setData('text/html', html)
  }

  const displayName = sectionMapping[shortID]
  return displayName ? <h4 {...props}>{displayName}</h4> : null
}

const RenderSections: React.FC<{ sections: any[], onClick: (sectionID: string) => void }> = ({ sections, onClick }) => {
  const { sectionMapping } = useContext<any>(TemplateContext)

  return sections.map(
    ({ sectionID, subSections }) => {
      const sectionTitle = sectionID && getSectionTitle(sectionID, onClick, sectionMapping)

      if (sectionTitle) {
        return (
          <div key={sectionID} className='tableOfContentSection'>
            {sectionTitle}
            {Array.isArray(subSections) && subSections.length > 0 && (
              <div className='tableOfContentSubSections'>
                <RenderSections onClick={onClick} sections={subSections} />
              </div>
            )}
          </div>
        )
      }
      return null
    }
  ) as any
}

const TableOfContent = ({ isSmall, previous, next, idPrefix, suffix = '' }) => {
  const { template: { sections, annexes, header, footer, parties, annexesTitle }, readOnly: tplReadOnly } = useContext<any>(TemplateContext)
  const { contract } = useContext<any>(ContractContext)
  const [isOpen, setIsOpen] = useState(false)
  const [, setActiveSection] = useQueryParam('activeSection', StringParam)

  /**
   * On click handler for scrolling to the desired part
   */
  const doingNextRef = useRef(false)
  const onClick = useCallback((sectionID: string, block: ScrollLogicalPosition = 'center') => {
    setActiveSection(sectionID)

    //  Trigger a scroll lately
    setTimeout(() => {
      let element = document.getElementById(`${idPrefix}${sectionID}`)
      element = (element && element.querySelector('.sectionText')) || element
      if (element) {
        element.classList.add('blink-outline')
        setTimeout(() => element?.classList.remove('blink-outline'), 1000)

        if (isSmall) {
          if (!doingNextRef.current) {
            doingNextRef.current = true
            next(() => {
              element?.scrollIntoView({ behavior: 'smooth', block, inline: 'center' })
              doingNextRef.current = false
            })
          }
        } else {
          element.scrollIntoView({ behavior: 'smooth', block, inline: 'center' })
        }
      }
    }, tplReadOnly ? 1000 : 2000)
  }, [idPrefix, isSmall, next, setActiveSection, tplReadOnly])

  /**
   * Finally render the component
   */
  return (
    <LayoutSidebar
      className='templateEditPage-toolbox'
      isOpen={isOpen}
      position='left'
      sidebarName={`tableOfContentSidebar${suffix}`}
    >
      <StickyMainContent>
        <div className='templateEditPage-toolboxSwitcher'>
          <Tooltip placement='right' title={<Trans>Table of content</Trans>}>
            <Button
              icon={<ProfileOutlined />}
              onClick={() => (isSmall ? previous() : setIsOpen(old => !old))}
              size='large'
              type='default'
            />
          </Tooltip>
        </div>
        <div className='templateEditPage-toolboxContent'>
          <div className='sectionsTree tableOfContents'>
            {!isSmall && (
              <Button
                className='close-button noBorder'
                ghost
                icon={<CloseOutlined />}
                onClick={() => setIsOpen(false)}
                type='default'
              />
            )}
            <h1 className='TOCHeader'>
              <Trans>Table of content</Trans>
            </h1>
            <div className='sectionListWrapper'>
              {/* Render anchor for each cover document */}
              {
                contract
                && Array.isArray(contract.covers)
                && contract.covers.length > 0
                && (
                  <>
                    <h3 onClick={() => onClick('covers', 'center')}><Trans>Covers</Trans></h3>
                    {contract.covers.map(({ key }, index) => (
                      <div key={key} className='tableOfContentSection'>
                        <h4 onClick={() => onClick(key, 'start')}>
                          <Trans>Cover n°{index + 1}</Trans>
                        </h4>
                      </div>
                    ))}
                  </>
                )
              }

              {/* Render header */}
              <h3 onClick={() => onClick(header)}><Trans>First page</Trans></h3>

              {/* Render sections */}
              {(Array.isArray(sections) && sections.length > 0) && (
                <RenderSections onClick={onClick} sections={sections} />
              )}

              {/* Render footer */}
              <h3 onClick={() => onClick(footer)}><Trans>Last page</Trans></h3>

              {/* Render signature anchor */}
              {(((
                contract && contract.signaturesHolder
                && Array.isArray(contract.signaturesHolder.signatures)
                && contract.signaturesHolder.signatures.length > 0)
              ) || (
                Object(parties) === parties && Object.keys(parties).some(key => key.includes('signature'))
              ) || (contract && Object(contract.parties) === contract.parties)) && (
                <div className='tableOfContentSection'>
                  <h3 onClick={() => onClick('signatures')}><Trans>Signatures</Trans></h3>
                </div>
              )}

              {(Array.isArray(annexes) && annexes.length > 0) && (
                <>
                  {annexesTitle && (
                    <div className='tableOfContentSection'>
                      <h3 onClick={() => onClick('annexes')}>{annexesTitle}</h3>
                    </div>
                  )}
                  <RenderSections onClick={onClick} sections={annexes} />
                </>
              )}

              {/* Render anchor for each additional document */}
              {
                contract
                && Array.isArray(contract.additionalDocuments)
                && contract.additionalDocuments.length > 0
                && (
                  <>
                    <h3 onClick={() => onClick('additionalDocuments', 'center')}><Trans>Additional documents</Trans></h3>
                    {contract.additionalDocuments.map(({ key }, index) => (
                      <div key={key} className='tableOfContentSection'>
                        <h4 onClick={() => onClick(key, 'start')}>
                          <Trans>Document n°{index + 1}</Trans>
                        </h4>
                      </div>
                    ))}
                  </>
                )
              }
            </div>
          </div>
        </div>
      </StickyMainContent>
    </LayoutSidebar>
  )
}

export default TableOfContent
