import React, { useContext, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { t, Trans } from '@lingui/macro'
import { CheckOutlined, CloseOutlined, EditOutlined, ProfileOutlined } from '@ant-design/icons'
import { Button, Tooltip, Typography } from 'antd'

import { ContractContext } from '../Contexts'
import { IdPrefixContext } from '../../ContractDiplay/ContractLayout'
import LayoutSidebar from '../../../Layouts/LayoutSidebar'
import { StickyMainContent } from '../../../Layouts/MainLayout/MainContainer'

import './TableOfContentV2Styles.scss'
import {noop} from "../../../Defaults";


interface TOCProps {
  defaultOpened?: boolean
}


/** *******************************************************************
 *             Some utils components & functions
 ******************************************************************* */

/**
 * On click handler for scrolling to the desired part
 */
const onTitleClick = (idPrefix: string, sectionID: string, block: ScrollLogicalPosition = 'center') => {
  let element = document.getElementById(`${idPrefix}${sectionID}`)
  element = (element && element.querySelector('.sectionText')) || element
  if (element) {
    element.classList.add('blink-outline')
    setTimeout(() => element?.classList.remove('blink-outline'), 1000)
    element.scrollIntoView({ behavior: 'smooth', block, inline: 'center' })
  }
}

interface EditableTitleProps {
  sectionID: string
  propName: string
  index?: number
}

const EditableTitle: React.FC<EditableTitleProps> = ({ sectionID, propName, index, children }) => {
  const idPrefix = useContext<string>(IdPrefixContext)
  const { contract, updateContract } = useContext<any>(ContractContext)
  const contractRef = useRef(contract)
  contractRef.current = contract

  const [edit, setEdit] = useState(false)

  return (
    <div className='editableTitle' onBlur={() => setEdit(false)}>
      <Typography.Title
        editable={useMemo(() => ({
          onChange: name => {
            let value
            if (index != null) {
              value = [...contractRef.current[propName]]
              value[index] = { ...value[index], name }
            } else {
              value = { ...contractRef.current[propName], name }
            }

            updateContract({ [propName]: value }, !!contractRef.current.contractID)
          },
          editing: edit,
          onStart: () => setEdit(true),
          onCancel: () => setEdit(false),
          onEnd: () => setEdit(false),
          icon: (
            <Button
              className='noBorder'
              ghost
              icon={<EditOutlined />}
            />
          )
        }), [edit, index, propName, updateContract])}
        level={4}
        onClick={() => onTitleClick(idPrefix, sectionID, 'start')}
      >
        {edit ? children : <span>{children}</span>}
      </Typography.Title>
    </div>
  )
}


// ===========================================================================================


// ===========================================================================================


/** *******************************************************************
 *             Table of content V2 base componennt
 ******************************************************************* */
const emptyObject: any = {}
const TableOfContentV2Base: React.FC<TOCProps> = ({ defaultOpened, children }) => {
  const idPrefix = useContext<string>(IdPrefixContext)
  const { contract: { covers, additionalDocuments, parties, signaturesHolder } = emptyObject } = useContext<any>(ContractContext)
  const [isOpen, setIsOpen] = useState(defaultOpened || false)

  /**
   * Finally render the component
   */
  return (
    <LayoutSidebar
      className='tableOfContentV2'
      isOpen={isOpen}
      position='left'
      sidebarName='tableOfContentV2'
    >
      <StickyMainContent>
        <div className='toolboxSwitcher'>
          <Tooltip placement='right' title={<Trans>Table of content</Trans>}>
            <Button
              icon={<ProfileOutlined />}
              onClick={() => setIsOpen(old => !old)}
              size='large'
            />
          </Tooltip>
        </div>
        <div className='toolboxContent'>
          <div className='toolboxTile'>
            <h1><Trans>Table of content</Trans></h1>
            <Tooltip placement='top' title={<Trans>Close</Trans>}>
              <Button
                className='noBorder'
                ghost
                icon={<CloseOutlined />}
                onClick={() => setIsOpen(false)}
              />
            </Tooltip>
          </div>

          <div className='toolboxContentInner'>
            {/* Render anchor for each cover document */}
            {Array.isArray(covers) && covers.length > 0 && (
              <>
                <h3 onClick={() => onTitleClick(idPrefix, 'covers', 'center')}><Trans>Covers</Trans></h3>
                {covers.map(({ key, name }, index) => (
                  <div key={`${key}${name}`} className='tableOfContentItem done'>
                    <CheckOutlined />
                    <EditableTitle index={index} propName='covers' sectionID={key}>
                      {name || t`Cover n°${index + 1}`}
                    </EditableTitle>
                  </div>
                ))}
              </>
            )}

            {/* Render the main content */}
            {children}

            {/* Render signature anchor */}
            {(
              (
                signaturesHolder && Array.isArray(signaturesHolder.signatures) && signaturesHolder.signatures.length > 0
              ) || Object(parties) === parties
            ) && (
              <h3 onClick={() => onTitleClick(idPrefix, 'signatures')}><Trans>Signatures</Trans></h3>
            )}

            {/* Render anchor for each additional document */}
            {Array.isArray(additionalDocuments) && additionalDocuments.length > 0 && (
              <>
                <h3 onClick={() => onTitleClick(idPrefix, 'additionalDocuments', 'center')}><Trans>Additional documents</Trans></h3>
                {additionalDocuments.map(({ key, name }, index) => (
                  <div key={`${key}${name}`} className='tableOfContentItem done'>
                    <CheckOutlined />
                    <EditableTitle index={index} propName='additionalDocuments' sectionID={key}>
                      {name || t`Document n°${index + 1}`}
                    </EditableTitle>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </StickyMainContent>
    </LayoutSidebar>
  )
}


// ===========================================================================================


// ===========================================================================================


/** *******************************************************************
 *                Pdf contract table of content
 ******************************************************************* */
export const PdfContractTableOfContent: React.FC<TOCProps> = props => {
  const idPrefix = useContext<string>(IdPrefixContext)
  const { contract: { pdfSigningFile } = emptyObject } = useContext<any>(ContractContext)

  return (
    <TableOfContentV2Base {...props}>
      {pdfSigningFile && (
        <>
          <h3 onClick={() => onTitleClick(idPrefix, 'pdfSigningFile', 'start')}><Trans>Uploaded Contract</Trans></h3>
          <div className='tableOfContentItem done'>
            <CheckOutlined />
            <EditableTitle propName='pdfSigningFile' sectionID='pdfSigningFile'>
              {pdfSigningFile.name || t`Uploaded Contract`}
            </EditableTitle>
          </div>
        </>
      )}
    </TableOfContentV2Base>
  )
}
