/**
 * Product & Service field configuration (based on stripe format)
 * Specs: https://docs.google.com/document/d/15g2LaTZ40qzfU9rXHQ6xW5QSJ6553k-kWRpcJ5N90Uk/edit#heading=h.61thmppex9ui
 */

import { Node } from 'slate'

interface ProductBase {
  productName: string
  productDescription?: string
  unitLabel?: string
  currency: 'eur' | 'usd' | 'gbp'
  subscriptionType: 'recurring' | 'oneTime'
  billingPeriod: 'daily' | 'weekly' | 'monthly' | 'threeMonths' | 'sixMonths' | 'yearly'

  isMeteredUsage: boolean
  meteredMetric?: 'sum' | 'recent' | 'latest' | 'max'
  tax?: number
  taxMode?: 'inclusive' | 'exclusive'
}

interface ProductStandard extends ProductBase {
  pricingModel: 'standard'
  fixedPrice: number
}

interface ProductPackage extends ProductBase {
  pricingModel: 'package'
  unitPrice: number
  unitNumber: number
}

interface Tier {
  max: number
  unit?: number
  flat?: number
}

interface ProductTiers extends ProductBase {
  pricingModel: 'graduated' | 'volume'
  pricingTiers: Tier[]
}

export type ProductField = ProductStandard | ProductPackage | ProductTiers

export type ProductFieldResponse = { [productIndex: string]: number } | undefined


// ==================================================================================================


export const translations = {
  en: {
    desc: 'DESCRIPTION',
    qty: 'QTY',
    unitPrice: 'UNIT PRICE',
    flatFee: 'FLAT FEE',
    amount: 'AMOUNT',
    from: 'From',
    per: unit => `Per ${unit}`,
    perX: (num, unit) => `Per ${num} ${unit}`,
    forX: (num, unit) => `For ${num} ${unit}`,
    billing: {
      daily: 'daily',
      weekly: 'weekly',
      monthly: 'monthly',
      threeMonths: '3 months',
      sixMonths: '6 months',
      yearly: 'yearly'
    },
    recurring: billingPeriod => `Recurring subscription with ${translations.en.billing[billingPeriod] || 'unknown'} billing period`,
    usage: 'Usage\n based',
    units: 'units',
    subTotal: 'Sub total',
    tax: 'Tax',
    total: 'Total'
  },
  de: {
    desc: 'BESCHREIBUNG',
    qty: 'MENGE',
    unitPrice: 'STÜCKPREIS',
    flatFee: 'PAUSCHALPREIS',
    amount: 'BETRAG',
    from: 'Ab',
    per: unit => `Pro ${unit}`,
    perX: (num, unit) => `Pro ${num} ${unit}`,
    forX: (num, unit) => `Für ${num} ${unit}`,
    billing: {
      daily: 'täglichem',
      weekly: 'wöchentlichem',
      monthly: 'monatlichem',
      threeMonths: '3 Monaten',
      sixMonths: '6 Monaten',
      yearly: 'jährlichem'
    },
    recurring: billingPeriod => `Wiederkehrendes Abonnement mit ${translations.de.billing[billingPeriod] || 'unbekanntem'} Abrechnungszeitraum`,
    usage: 'Nutzungs-\n abhängig',
    units: 'Stück',
    subTotal: 'Gesamtbetrag Netto',
    tax: 'Umsatzsteuer',
    total: 'Gesamtbetrag Brutto'
  }
}


// ==================================================================================================


/** *************************************************************************
 *                 Render list of products
 ************************************************************************** */
export const renderProductsToSlate = (products: ProductField[], fieldResponse: ProductFieldResponse, lang): Node | undefined => {
  const trans = translations[lang] || translations.en

  let hasFlatPrice = false
  let hasUnitPrice = false
  let hasFixedUnit = false
  let tax, taxMode // eslint-disable-line one-var, one-var-declaration-per-line

  //  Find out how many columns we do need
  products.forEach(product => {
    if (product) {
      if (!product.isMeteredUsage) {
        hasFixedUnit = true
      }

      if (product.pricingModel === 'standard' || product.pricingModel === 'package') {
        hasUnitPrice = true
      } else if (product.pricingModel === 'graduated' || product.pricingModel === 'volume') {
        const { pricingTiers } = product as ProductTiers
        pricingTiers.forEach(({ unit, flat }) => {
          if (flat) {
            hasFlatPrice = true
          }
          if (unit) {
            hasUnitPrice = true
          }
        })
      }

      if (product.tax) { tax = product.tax } // eslint-disable-line prefer-destructuring
      if (product.taxMode) { taxMode = product.taxMode } // eslint-disable-line prefer-destructuring
    }
  })

  //  Product name & desc     |     Unit price     |     Flat price     |     QTY & Total  //  If no fixed units found we can generate a more compact table
  const nbColumns = 1 + (hasUnitPrice ? 1 : 0) + (hasFlatPrice ? 1 : 0) + ((fieldResponse && hasFixedUnit) ? 2 : 0)
  const descAdded: any = {}
  let totalAmount = 0

  const newTableRow = (texts?: string[], cell = 'td'): Node => {
    const row: Node = { type: 'tr', children: [] }

    let index = 0
    const addCell = (extra: any = { style: 'white-space: nowrap;', align: 'right' }) => {
      row.children.push({
        type: cell,
        children: [{
          type: 'p',
          children: [{ text: texts?.[index] || '' }],
          ...extra
        }]
      })
      index += 1
    }

    //  Add description
    addCell({ align: 'left' })
    //  Qty
    if (fieldResponse && hasFixedUnit) { addCell() }
    if (hasUnitPrice) { addCell() }
    if (hasFlatPrice) { addCell() }
    //  Total
    if (fieldResponse && hasFixedUnit) { addCell() }

    return row
  }


  const formatQuantity = (num: number) => new Intl.NumberFormat('de').format(num)
  const formatCurrency = (product: ProductField, number: number) => new Intl.NumberFormat('de', {
    style: 'currency',
    currency: product.currency.toUpperCase()
  }).format(number)

  const setColumnText = (row: Node, index: number, text: string) => {
    //  Row > td > p > text
    row.children[index].children[0].children[0].text = text
  }


  // ==================================================================================================


  /** *************************************************************************
   *                 Render each product type
   ************************************************************************** */
  const renderStandardProduct = (product: ProductStandard, row: Node, value?: number): Node[] => {
    if (!value || product.isMeteredUsage) {
      const texts = [
        trans.per(product.unitLabel || trans.units),
        formatCurrency(product, product.fixedPrice)
      ]

      if (fieldResponse && product.isMeteredUsage) {
        if (hasFixedUnit) {
          texts.splice(1, 0, '')
        }
        setColumnText(row, 1, trans.usage)
      }

      return [row, newTableRow(texts)]
    }

    const quantity = formatQuantity(value)
    setColumnText(row, 1, quantity)

    const texts = [
      trans.forX(quantity, product.unitLabel || trans.units),
      quantity,
      formatCurrency(product, product.fixedPrice)
    ]

    //  Flat fee
    if (hasFlatPrice) {
      texts.push('')
    }

    //  Total
    const total = value * product.fixedPrice
    totalAmount += total
    texts.push(formatCurrency(product, total))

    return [row, newTableRow(texts)]
  }


  // ==================================================================================================


  const renderPackageProduct = (product: ProductPackage, row: Node, value?: number): Node[] => {
    if (!value || product.isMeteredUsage) {
      const texts = [
        trans.perX(formatQuantity(product.unitNumber), product.unitLabel || trans.units),
        formatCurrency(product, product.unitPrice)
      ]

      if (fieldResponse && product.isMeteredUsage) {
        if (hasFixedUnit) {
          texts.splice(1, 0, '')
        }
        setColumnText(row, 1, trans.usage)
      }

      return [row, newTableRow(texts)]
    }

    //  Add real quantity and compute billedQuantity
    setColumnText(row, 1, formatQuantity(value))
    const billedQuantity = Math.ceil(value / product.unitNumber)

    const texts = [
      trans.perX(formatQuantity(product.unitNumber), product.unitLabel || trans.units),
      formatQuantity(billedQuantity),
      formatCurrency(product, product.unitPrice)
    ]

    //  Flat fee
    if (hasFlatPrice) {
      texts.push('')
    }

    //  Total
    const total = billedQuantity * product.unitPrice
    totalAmount += total
    texts.push(formatCurrency(product, total))

    return [row, newTableRow(texts)]
  }


  // ==================================================================================================

  const getTierProductLabel = (product: ProductTiers, index: number): string => {
    const max = formatQuantity(product.pricingTiers[index].max)
    const min = formatQuantity((product.pricingTiers[index - 1] || { max: 0 }).max + 1)

    if (index === product.pricingTiers.length - 1) {
      return `${trans.from} ${min} ${product.unitLabel || trans.units}`
    }

    return `${min} - ${max} ${product.unitLabel || trans.units}`
  }


  const renderTierProductDetail = (product: ProductTiers, row: Node): Node[] => {
    const nodes: Node[] = [row]

    if (fieldResponse && product.isMeteredUsage && nbColumns > 1) {
      setColumnText(row, 1, trans.usage)
    }

    product.pricingTiers.forEach(({ unit, flat }, index) => {
      const texts: string[] = []

      //  Label
      texts.push(getTierProductLabel(product, index))

      //  Qty
      if (fieldResponse && hasFixedUnit) {
        texts.push('')
      }

      //  Unit
      if (unit != null) {
        texts.push(formatCurrency(product, unit))
      } else if (hasUnitPrice) {
        texts.push('')
      }

      //  Flat
      if (flat != null) {
        texts.push(formatCurrency(product, flat))
      }

      //  Save the row
      nodes.push(newTableRow(texts))
    })

    return nodes
  }


  // ==================================================================================================


  const renderGraduatedProduct = (product: ProductTiers, row: Node, value?: number): Node[] => {
    if (!value || product.isMeteredUsage) {
      return renderTierProductDetail(product, row)
    }

    setColumnText(row, 1, formatQuantity(value))

    let graduatedPrice = 0
    let prevMax = 0
    const nodes: Node[] = [row]

    for (let index = 0; index < product.pricingTiers.length && value > 0; index += 1) {
      const { max, unit, flat } = product.pricingTiers[index]
      const tierQty = max - prevMax

      const texts: string[] = []

      //  Label
      texts.push(getTierProductLabel(product, index))

      //  Qty
      const quantity = (index < product.pricingTiers.length - 1) ? Math.min(value, tierQty) : value
      texts.push(formatQuantity(quantity))

      //  Unit
      if (unit != null) {
        texts.push(formatCurrency(product, unit))
      } else if (hasUnitPrice) {
        texts.push('')
      }

      //  Flat
      if (flat != null) {
        texts.push(formatCurrency(product, flat))
      } else if (hasFlatPrice) {
        texts.push('')
      }

      //  Total
      const total = quantity * (unit || 0) + (flat || 0)
      graduatedPrice += total
      texts.push(formatCurrency(product, total))

      //  Save the row
      nodes.push(newTableRow(texts))
      prevMax = max
      value -= tierQty
    }

    //  Add the total to the first row
    setColumnText(row, nbColumns - 1, formatCurrency(product, graduatedPrice))

    totalAmount += graduatedPrice

    return nodes
  }


  // ==================================================================================================


  const renderVolumeProduct = (product: ProductTiers, row: Node, value?: number): Node[] => {
    if (!value || product.isMeteredUsage) {
      return renderTierProductDetail(product, row)
    }

    const quantity = formatQuantity(value)
    let tierIndex = product.pricingTiers.length - 1

    for (let idx = product.pricingTiers.length - 2; idx >= 0 && value <= product.pricingTiers[idx].max; idx -= 1) {
      tierIndex = idx
    }


    const { unit, flat } = product.pricingTiers[tierIndex]
    const texts: string[] = []

    //  Label
    texts.push(trans.forX(quantity, product.unitLabel || trans.units))

    //  Qty
    texts.push(quantity)
    setColumnText(row, 1, quantity)

    //  Unit
    if (unit != null) {
      texts.push(formatCurrency(product, unit))
    } else if (hasUnitPrice) {
      texts.push('')
    }

    //  Flat
    if (flat != null) {
      texts.push(formatCurrency(product, flat))
    } else if (hasFlatPrice) {
      texts.push('')
    }

    //  Total
    const total = value * (unit || 0) + (flat || 0)
    totalAmount += total
    texts.push(formatCurrency(product, total))

    //  Save the row
    return [row, newTableRow(texts)]
  }


  // ==================================================================================================


  /** *************************************************************************
   *                 Render a product
   ************************************************************************** */
  const renderProductToSlate = (product: ProductField, value?: number): Node[] | undefined => {
    const row: Node = newTableRow()

    //  Complete with product title
    row.children[0].children[0].children[0] = { text: `${product.productName} (${trans.per(product.unitLabel || trans.units)})`, bold: true }

    //  Complete with the description if needed
    if (product.productDescription && !descAdded[product.productName]) {
      row.children[0].children.push({
        type: 'p',
        children: [{ text: product.productDescription, italic: true }]
      })
      descAdded[product.productName] = 1
    }

    if (product.pricingModel === 'standard') {
      return renderStandardProduct(product, row, value)
    }
    if (product.pricingModel === 'package') {
      return renderPackageProduct(product, row, value)
    }
    if (product.pricingModel === 'graduated') {
      return renderGraduatedProduct(product, row, value)
    }
    if (product.pricingModel === 'volume') {
      return renderVolumeProduct(product, row, value)
    }

    return undefined
  }


  // ==================================================================================================


  const children: Node[] = []

  //  Generate table body
  products.forEach((product, index) => {
    if (product) {
      const nodes = renderProductToSlate(product, fieldResponse?.[index])
      if (nodes) {
        if (children.length > 0) {
          children.push(newTableRow()) // Blank row between products
        }
        children.push(...nodes)
      }
    }
  })

  //  Return the table
  if (children.length > 0) {
    //  Generate table header
    const texts = [trans.desc]
    if (fieldResponse && hasFixedUnit) {
      texts.push(trans.qty)
    }
    if (hasUnitPrice) {
      texts.push(trans.unitPrice)
    }
    if (hasFlatPrice) {
      texts.push(trans.flatFee)
    }
    if (fieldResponse && hasFixedUnit) {
      texts.push(trans.amount)
    }
    children.splice(0, 0, newTableRow(texts, 'th'))

    //  Add recurring row in footer
    const pdt = products.find(elm => elm)
    const isRecurring = pdt && pdt.subscriptionType === 'recurring'
    if (pdt && (fieldResponse || isRecurring)) {
      const row = newTableRow([isRecurring ? trans.recurring(pdt.billingPeriod) : ''], 'th')

      if (fieldResponse && hasFixedUnit) {
        if (tax && taxMode) {
          const subTotalRow = newTableRow(undefined, 'th')
          setColumnText(subTotalRow, nbColumns - 2, trans.subTotal)

          const taxRow = newTableRow(undefined, 'th')
          setColumnText(taxRow, nbColumns - 2, `${trans.tax} ${tax}%`)

          let taxAmount, subTotalAmount // eslint-disable-line one-var, one-var-declaration-per-line
          if (taxMode === 'inclusive') {
            subTotalAmount = totalAmount / (1 + (tax / 100))
            taxAmount = totalAmount - subTotalAmount
          } else {
            subTotalAmount = totalAmount
            taxAmount = subTotalAmount * (tax / 100)
            totalAmount += taxAmount
          }

          setColumnText(subTotalRow, nbColumns - 1, formatCurrency(pdt, subTotalAmount))
          children.push(subTotalRow)
          setColumnText(taxRow, nbColumns - 1, formatCurrency(pdt, taxAmount))
          children.push(taxRow)
        }

        setColumnText(row, nbColumns - 1, formatCurrency(pdt, totalAmount))
        if (nbColumns - 2 > 0 || !isRecurring) {
          setColumnText(row, nbColumns - 2, trans.total)
        }
      }

      children.push(row)
    }

    return {
      type: 'table',
      withHeader: true,
      children
    }
  }

  return undefined
}


// ==================================================================================================


/** *************************************************************************
 *                 Render a summary of list of products
 ************************************************************************** */
export const renderProductsSummary = (products: ProductField[], fieldResponse: ProductFieldResponse, lang): string => {
  const arr: string[] = []
  const trans = translations[lang] || translations.en

  products.forEach((product, index) => {
    const qty = fieldResponse?.[index]

    if (product && !product.isMeteredUsage && qty != null) {
      const formatQuantity = (num: number) => new Intl.NumberFormat('de').format(num)
      const formatCurrency = (product: ProductField, number: number) => new Intl.NumberFormat('de', {
        style: 'currency',
        currency: product.currency.toUpperCase()
      }).format(number)

      let price = 0
      if (product.pricingModel === 'standard') {
        price = qty * product.fixedPrice
      } else if (product.pricingModel === 'package') {
        price = Math.ceil(qty / product.unitNumber) * product.unitPrice
      } else if (product.pricingModel === 'graduated') {
        let prevMax = 0
        let qty2 = qty

        for (let idx = 0; idx < product.pricingTiers.length && qty2 > 0; idx += 1) {
          const { max, unit, flat } = product.pricingTiers[idx]
          const tierQty = max - prevMax
          const quantity = Number.isFinite(tierQty) ? Math.min(qty2, tierQty) : qty2

          price += quantity * (unit || 0) + (flat || 0)
          prevMax = max
          qty2 -= tierQty
        }
      } else if (product.pricingModel === 'volume') {
        let tierIndex = product.pricingTiers.length - 1

        for (let idx = product.pricingTiers.length - 2; idx >= 0 && qty <= product.pricingTiers[idx].max; idx -= 1) {
          tierIndex = idx
        }

        const { unit, flat } = product.pricingTiers[tierIndex]
        price = qty * (unit || 0) + (flat || 0)
      }

      arr.push(`${product.productName}: ${trans.forX(formatQuantity(qty), product.unitLabel || trans.units)} ${formatCurrency(product, price)}`)
    }
  })

  return arr.join('\n')
}
