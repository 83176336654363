import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { t, Trans } from '@lingui/macro'
import { Button, Card, Form, Input, Select } from 'antd'

import { CloseOutlined } from '@ant-design/icons'
import { ExternalContext } from '../ExternalContext'
import CustomFormWrapper, { requiredRules } from '../../SharedComponents/CustomFormWrapper'
import loadingModalFeedback from '../../Alert/LoadingModalFeedback'

import { getInviteEmailTexts } from '../../Contract/ContractEditor/InviteContactToContract/InviteFormDrawer'
import { inviteExternalParty } from '../../Contract/redux/ContractActions'

const expertises = {
  manager: <Trans>Manager</Trans>,
  sales: <Trans>Sales</Trans>,
  legal: <Trans>Legal</Trans>,
  dataPrivacy: <Trans>Data privacy</Trans>,
  compliance: <Trans>Compliance</Trans>,
  other: <Trans>Other</Trans>
}

interface InviteFormProps {
  onFinish: () => void
  onCancel: () => void
}

const ExternalInviteForm: React.FC<InviteFormProps> = ({ onFinish, onCancel }) => {
  const { tokenData, setTokenData } = useContext(ExternalContext)
  const [values, setValues] = useState<any>({})
  const [step, setStep] = useState(0)

  const cancelButton = (
    <Button
      className='noBorder'
      ghost
      icon={<CloseOutlined />}
      onClick={() => {
        setValues({})
        setStep(0)
        onCancel()
      }}
    />
  )

  const userDisplayName = `${tokenData.contact.firstName} ${tokenData.contact.lastName}`
  const emailTexts = useMemo(() => getInviteEmailTexts(userDisplayName, tokenData.instanceTitle), [tokenData.instanceTitle, userDisplayName])

  const dispatch = useDispatch()
  const onSubmit = useCallback(async data => {
    let currentStep
    setStep(old => {
      currentStep = old
      return 1
    })

    if (currentStep === 1) {
      return loadingModalFeedback({
        loadingTitle: <Trans>Inviting the new user</Trans>,

        successTitle: <Trans>The user has been invited</Trans>,
        successDescription: <Trans>We just sent an invite email to the user.</Trans>,

        errorTitle: <Trans>Error while inviting the user</Trans>,
        errorDescription: <Trans>We got an unexpected error while inviting the user.</Trans>,

        autoSuccessClose: 4000
      })(async () => {
        let promise
        setValues(old => {
          promise = dispatch(inviteExternalParty({ ...old, ...data }))
          return {}
        })
        await promise

        onFinish()
        setStep(0)
      })
    }

    setValues(old => ({ ...old, ...data }))
    return undefined
  }, [dispatch, onFinish])

  if (step === 0) {
    return (
      <Card
        className='betterCard inviteFormCard'
        extra={cancelButton}
        title={<Trans>Invite a user</Trans>}
      >
        <CustomFormWrapper onSubmit={onSubmit}>
          <Form.Item name='firstName' rules={requiredRules}>
            <Input className='simple-line' placeholder={t`First name`} />
          </Form.Item>
          <Form.Item name='lastName' rules={requiredRules}>
            <Input className='simple-line' placeholder={t`Last name`} />
          </Form.Item>
          <Form.Item name='email' rules={requiredRules}>
            <Input className='simple-line' placeholder={t`Email address`} type='email' />
          </Form.Item>
          <Form.Item name='expertise' rules={requiredRules}>
            <Select className='simple-line' placeholder={t`Select expertise`}>
              {Object.entries(expertises).map(([key, value]) => (
                <Select.Option key={key} value={key}>{value}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Button block htmlType='submit' type='primary'>
            <Trans>Next</Trans>
          </Button>
        </CustomFormWrapper>
      </Card>
    )
  }

  return (
    <Card
      className='betterCard inviteFormCard'
      extra={cancelButton}
      title={<Trans>Invite a user</Trans>}
    >
      <CustomFormWrapper onSubmit={onSubmit}>
        <p><Trans>Your custom message:</Trans></p>
        <Form.Item initialValue={emailTexts.signing} name='inviteText' rules={requiredRules}>
          <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} className='simple-line' />
        </Form.Item>
        <Button block htmlType='submit' type='primary'>
          <Trans>Invite user</Trans>
        </Button>
      </CustomFormWrapper>
    </Card>
  )
}

export default ExternalInviteForm
