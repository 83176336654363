import React from 'react'

import { Section } from '@top-legal/datastore'
import { Delta, SectionEditorProps } from '@top-legal/editor'


export type UpdateSection = (data: Partial<Section>) => Promise<any>
export interface ContractEditorActions {
  //  Update a section with some partial changes
  updateSection?: UpdateSection
  //  Update from the editing area (yDoc update & deltas)
  updateContent?: SectionEditorProps['saveUpdate']
  //  Possibility to add some buttons in the floating area on the right
  floatingButtons?: React.ReactNode
  //  When we blur the editing area
  onEditingBlur?: () => void
  //  Might be great to be able to do some cleaning or extra saving when unload
  onSectionDismount?: () => void
}

export type UseContractEditorActions = (
  rootKey: string,
  path: number[],
  section: Section,
  setSaving: React.Dispatch<React.SetStateAction<boolean>>,
  setInitialDeltas: React.Dispatch<React.SetStateAction<Delta | undefined>>,
  setResetRef: React.Dispatch<React.SetStateAction<number>>
) => ContractEditorActions

export interface ContractEditorSectionContextProps {
  useContractEditorActions: UseContractEditorActions
}

const emptyHook = () => ({})
const ContractEditorSectionContext = React.createContext<ContractEditorSectionContextProps>({
  useContractEditorActions: emptyHook
})

export default ContractEditorSectionContext
