import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button, Card, Dropdown, Empty, Image, Input, Menu, Modal, notification, Tabs, Tag, Tooltip
} from 'antd'
import { t, Trans } from '@lingui/macro'
import {
  AppstoreOutlined,
  AudioFilled,
  AudioOutlined,
  CameraOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  GlobalOutlined,
  MoreOutlined,
  PictureFilled,
  PictureOutlined,
  SearchOutlined,
  UploadOutlined,
  VideoCameraFilled,
  VideoCameraOutlined
} from '@ant-design/icons'
import moment from 'moment'

import BackgroundPoster from './img/video_poster.png'

import { ConfirmMenuItem } from '../SharedComponents/FormComponents'
import { UserAvatarByID } from '../Organisations/UserRoleDisplay/UserAvatarList'
import AudioVideoPlayerRecorder from './AudioVideoPlayerRecorder'
import MainContainer from '../Layouts/MainLayout/MainContainer'
import { withUserData } from '../Layouts/AuthenticatedPage'
import { UserAndCompanyDataContext } from '../Layouts/Constants'
import CreateNewMediaModal from './CreateNewMediaModal'
import CreateAudioVideoMedia, { downloadProtectedMedia } from './CreateAudioVideoMedia'
import { updateMedia } from '../Organisations/redux/OrganisationsActions'
import UploadAudioVideoFile from './UploadAudioVideoFile'

import './MediaLibrayStyles.scss'
import { useHighlight } from '../Listing/SearchHelpers'
import { store } from '../../ReduxStore'

export interface Media {
    mediaID: string
    fileKey: string
    type: string
    title: string
    description: string
    createdDate: string
    createdBy: string
    posterID?: string
    hidden?: boolean

    //  Pre-signed urls from backend
    posterUrl?: string,
    url?: string
}

interface MediaSelect {
    onSelect?: (mediaID: string) => void
}

export const mediaTags = {
  image: <Tag color='orange'><PictureOutlined /></Tag>,
  video: <Tag color='green'><VideoCameraOutlined /></Tag>,
  audio: <Tag color='blue'><AudioOutlined /></Tag>
}

const DefaultHighlight: React.FC<{ text: string }> = ({ text }) => <span>{text}</span>

export const MediaCard: React.FC<MediaSelect & { media: Media | string, Highlight?: React.FC<{ text: string }>, viewerOnly?: boolean }> = ({
  media: mediaP,
  onSelect,
  Highlight = DefaultHighlight,
  viewerOnly
}) => {
  const mediaLibrary: { [key: string]: Media } = useSelector(state => state.organisation.mediaLibrary)
  const media: Media | undefined = typeof mediaP === 'string' ? mediaLibrary[mediaP] : mediaP
  const [editTile, setEditTitle] = useState(false)
  const { company } = useContext(UserAndCompanyDataContext)

  const tag = media && media.type && mediaTags[media.type.split('/')[0]]
  const [url, setUrl] = useState<string>()
  const [titleMedia, setTitleMedia] = useState('')
  const [posterURL, setPosterURL] = useState<string>()

  useEffect(() => {
    if (media) {
      setTitleMedia(media.title)
      if (media.url) {
        setUrl(media.url)
      } else {
        downloadProtectedMedia(media.fileKey).then(setUrl).catch(() => setUrl(undefined))
      }

      if (media.posterUrl) {
        setPosterURL(media.posterUrl)
      }
      if (company.invitePosterKey) {
        downloadProtectedMedia(company.invitePosterKey).then(setPosterURL).catch(() => setPosterURL(undefined))
      }
    } else {
      setPosterURL(BackgroundPoster)
    }
  }, [company.invitePosterKey, media, mediaLibrary])

  if (!media) {
    return null
  }

  const content = (
    <div className='videoViewer'>
      {media.type === 'image' && (url ? (
        <Image
          className='mediaImage'
          loading='lazy'
          onError={() => setUrl(undefined)}
          placeholder={<span className='mediaImage placeholder'><PictureFilled /></span>}
          src={url}
        />
      ) : (
        <span className='mediaImage placeholder'><PictureFilled /></span>
      ))}
      {media.type !== 'image' && (url ? (
        <div className='mediaAudioVideo'>
          <AudioVideoPlayerRecorder
            audioOnly={media.type.startsWith('audio')}
            file={{ url, type: media.type }}
            poster={posterURL}
          />
        </div>
      ) : (
        <span className='mediaAudioVideo placeholder'>
          {media.type.startsWith('video') ? <VideoCameraFilled /> : <AudioFilled />}
        </span>
      ))}
    </div>
  )

  if (viewerOnly) {
    return <div className='mediaViewer'>{content}</div>
  }

  const MenuCard = () => {
    const dispatch = useDispatch()
    if (!editTile) {
      return (
        <Dropdown
          overlay={(
            <Menu>
              <Menu.Item icon={<CheckOutlined />} onClick={onSelect ? () => onSelect(media.mediaID) : undefined}>
                <Trans>Select this video</Trans>
              </Menu.Item>
              <Menu.Item icon={<EditOutlined />} onClick={() => setEditTitle(true)}>
                <Trans>Change title</Trans>
              </Menu.Item>
              <ConfirmMenuItem
                confirmMessage={<Trans>Are you sure you want to delete the video?</Trans>}
                danger
                onClick={async () => {
                  try {
                    await dispatch(updateMedia({
                      ...media,
                      hidden: true
                    }))
                    notification.close('teamRenameLoading')
                    notification.success({
                      message: <Trans>media deleted</Trans>
                    })
                  } catch (err) {
                    console.error('Error when deleting the media', err)
                    notification.close('mediatitleLoading')
                  }
                }}
              >
                <DeleteOutlined />
                <span><Trans>Delete video</Trans></span>
              </ConfirmMenuItem>
            </Menu>
          )}
          trigger={['click']}
        >
          <Button className='noBorder' ghost icon={<MoreOutlined /> }type='default' />
        </Dropdown>
      )
    }

    return (
      <div className='checkEditTitle'>
        <Button
          className='noBorder' ghost icon={<CheckOutlined />}
          onClick={async () => {
            setEditTitle(false)
            if (titleMedia !== media.title) {
              try {
                await dispatch(updateMedia({ ...media, title: titleMedia }))
                notification.close('teamRenameLoading')
                notification.success({
                  message: <Trans>Media renamed</Trans>
                })
              } catch (err) {
                console.error('Error when changing the media title', err)
                notification.close('mediatitleLoading')
              }
            }
          }}
        />
        <Button
          className='noBorder' danger ghost icon={<CloseOutlined />}
          onClick={() => setEditTitle(false)}
        />
      </div>
    )
  }
  return (
    <Card
      bordered={false}
      className='mediaCard'
      extra={<MenuCard />}
      hoverable
      size='small'
      title={!editTile ? (
        <h3>{titleMedia}</h3>
      ) : (
        <Input
          defaultValue={titleMedia}
          onChange={title => { setTitleMedia(title.target.value) }}
        />
      )}
    >
      {content}
      <Card.Meta
        avatar={<UserAvatarByID autoSide size='2rem' userID={media.createdBy} />}
        description={(
          <>
            <div className='mediaContent'>
              <h4 className='mediaDate'>{moment(media.createdDate).format('lll')}</h4>
              {media.description && media.description.split('\n').map((txt, index) => (
                <p key={index}><Highlight text={txt} /></p>
              ))}
            </div>
            <div className='extrathings'>{tag}</div>
          </>
        )}
      />
    </Card>
  )
}

interface MediaLibraryProps extends MediaSelect {
    media?: 'image' | 'video' | 'audio' | 'audioVideo',
}

const MediaLibrary: React.FC<MediaLibraryProps> = ({
  media,
  onSelect,
  children
}) => {
  const mediaLibrary: Media[] = useSelector(state => Object.values(state.organisation.mediaLibrary)).filter(media_ => media_.hidden === false)
  const [search, setSearch] = useState('')
  const Highlight = useHighlight(search)

  const displayMedias = (array: Media[]) => {
    if (search) {
      const split = search.toLowerCase().split(' ')
      array = array.filter(media_ => {
        const all = `${media_.title || ''} ${media_.description || ''}`.toLowerCase()
        return split.some(txt => all.includes(txt))
      })
    }

    if (array.length === 0) {
      return <Empty description={<Trans>No media available</Trans>} />
    }
    return (
      <div className='mediaCards'>
        {/* //  */}
        {array.map(media_ => (
          <MediaCard
            key={media_.mediaID} Highlight={Highlight} media={media_}
            onSelect={onSelect}
          />
        ))}
      </div>
    )
  }

  return (
    <div className='mediaLibrary'>
      <Input
        addonBefore={<SearchOutlined />}
        className='simple-line mediaLibrarySearchBar'
        onChange={evt => setSearch(evt.target.value)}
        placeholder={t`Search media`}
        value={search}
      />
      <Tabs>
        {(!media || media === 'audioVideo') && (
          <Tabs.TabPane key='all' tab={<span><GlobalOutlined /> <Trans>All</Trans></span>}>
            {displayMedias(media === 'audioVideo' ? mediaLibrary.filter(media_ => media_.type !== 'image') : mediaLibrary)}
          </Tabs.TabPane>
        )}
        {(!media || media === 'image') && (
          <Tabs.TabPane key='images' tab={<span><PictureOutlined /> <Trans>Images</Trans></span>}>
            {displayMedias(mediaLibrary.filter(media_ => media_.type === 'image'))}
          </Tabs.TabPane>
        )}
        {(!media || media === 'video' || media === 'audioVideo') && (
          <Tabs.TabPane key='videos' tab={<span><VideoCameraOutlined /> <Trans>Videos</Trans></span>}>
            {displayMedias(mediaLibrary.filter(media_ => media_.type.startsWith('video')))}
          </Tabs.TabPane>
        )}
        {(!media || media === 'audio' || media === 'audioVideo') && (
          <Tabs.TabPane key='audios' tab={<span><AudioOutlined /> <Trans>Audios</Trans></span>}>
            {displayMedias(mediaLibrary.filter(media_ => media_.type.startsWith('audio')))}
          </Tabs.TabPane>
        )}
      </Tabs>
      {children}
    </div>
  )
}


export const saveRecordedMediaToLibrary = async media => {
  const date = moment().format('lll')
  try {
    await store.dispatch(updateMedia({
      ...media, hidden: false, title: date
    }))
    ;(window as any).notification.success({
      message: <Trans>Media saved</Trans>
    })
  } catch (err) {
    console.error('Failed to create a new media', err)
    throw err
  }
}


interface MediaLibrarySelectorProps {
  media: 'image' | 'video' | 'audio' | 'audioVideo'
  value?: string
  onChange?: (val: string | undefined) => void
  onStartRecord?: () => void
  onFinish?: () => void
  defaultVideo?: React.ReactNode
}

const EmptyVideo: React.FC = () => (
  <div className='vimeoVideo emptyVideoContainer'>
    <Empty description={<Trans>No content provided yet.</Trans>} />
  </div>
)

export const MediaLibrarySelector: React.FC<MediaLibrarySelectorProps> = ({
  media,
  value,
  onChange,
  onStartRecord,
  onFinish,
  defaultVideo = <EmptyVideo />
}) => {
  const mediaLibrary: { [key: string]: Media } = useSelector(state => state.organisation.mediaLibrary)
  const [modalOpen, setModalOpen] = useState(false)
  const [createNew, setCreateNew] = useState(false)
  const [uploadFile, setUploadFile] = useState(false)
  const [audio, setAudio] = useState(false)
  const currentMedia = value && mediaLibrary[value]

  useEffect(() => {
    if (uploadFile) {
      setUploadFile(false)
    }
  }, [uploadFile])

  const recordType = (
    <Menu>
      <Menu.Item
        key='1'
        icon={<CameraOutlined />}
        onClick={() => {
          setModalOpen(false)
          setCreateNew(true)
          setAudio(false)
        }}
      >
        <Trans>Record video</Trans>
      </Menu.Item>
      <Menu.Item
        key='2'
        icon={<AudioOutlined />}
        onClick={() => {
          setModalOpen(false)
          setCreateNew(true)
          setAudio(true)
        }}
      >
        <Trans>Record audio</Trans>
      </Menu.Item>
    </Menu>
  )

  const videoAudioImport = (
    <Menu>
      <Menu.Item icon={<AppstoreOutlined />} onClick={() => setModalOpen(true)}>
        <Trans>From Library</Trans>
      </Menu.Item>
      <Menu.Item icon={<UploadOutlined />} onClick={() => setUploadFile(true)}>
        <Trans>Upload File</Trans>
      </Menu.Item>
    </Menu>
  )

  if (!createNew) {
    return (
      <div className='mediaSelector'>
        {currentMedia && (
          <Tooltip title={<Trans>Remove current entry</Trans>} trigger={['hover']}>
            <Button
              className='deleteVideoButton'
              danger
              ghost
              icon={<DeleteOutlined />}
              onClick={() => onChange?.(undefined)}
              type='primary'
            />
          </Tooltip>
        )}
        {currentMedia && <MediaCard media={currentMedia} viewerOnly />}
        {!currentMedia && defaultVideo}
        {(currentMedia && currentMedia.hidden) ? (
          <Button.Group className='autoFit'>
            <Button onClick={() => saveRecordedMediaToLibrary(media)} type='default'>
              <Trans>Save to library</Trans>
            </Button>
            <Button
              className='redoVideo'
              onClick={() => onChange?.(undefined)}
              type='primary'
            >
              <Trans>Change video</Trans>
            </Button>
          </Button.Group>
        ) : (
          <Button.Group className='autoFit'>
            <Dropdown overlay={videoAudioImport} overlayClassName='mediaSelectorDropdown' trigger={['click']}>
              <Button type='default'>
                <Trans>Use existing</Trans>
              </Button>
            </Dropdown>
            <Dropdown overlay={recordType} overlayClassName='mediaSelectorDropdown' trigger={['click']}>
              <Button type='primary'>
                <Trans>Record new</Trans>
              </Button>
            </Dropdown>
          </Button.Group>
        )}

        <Modal
          className='bigModal mediaSelectorModal'
          destroyOnClose
          footer={null}
          onCancel={() => setModalOpen(false)}
          visible={modalOpen}
          zIndex={10300}
        >
          <MediaLibrary
            media={media}
            onSelect={mediaID => {
              setModalOpen(false)
              onChange?.(mediaID)
              onFinish?.()
            }}
          >
            <Button className='mediaSelectorCreateNew' onClick={() => setModalOpen(false)}>
              <Trans>Back</Trans>
            </Button>
          </MediaLibrary>
        </Modal>
        <UploadAudioVideoFile
          filePrefix=''
          next={data => {
            onChange?.(data)
            onFinish?.()
          }}
          visible={uploadFile}
        />
      </div>
    )
  }

  return (
    <CreateAudioVideoMedia
      audio={audio}
      filePrefix=''
      next={data => {
        setCreateNew(false)
        onChange?.(data)
        onFinish?.()
      }}
      onStartRecord={onStartRecord}
      visible
    />
  )
}

const MediaLibraryPage: React.FC = () => {
  const [media, setMedia] = useState<'image' | 'audioVideo'>()

  return (
    <MainContainer
      mainContentClass='mediaLibraryPage'
      topbarContent={(
        <>
          <div className='topbarMainContent'>
            <PictureOutlined className='headerIcon' twoToneColor='#3DBD7D' />
            <h1 className='title'><Trans>Media library</Trans></h1>
          </div>
          <div className='topbarActions contractListingActions'>
            <Dropdown
              overlay={(
                <Menu>
                  <Menu.Item onClick={() => setMedia('image')}>
                    <PictureOutlined />
                    <span><Trans>Image</Trans></span>
                  </Menu.Item>
                  <Menu.Item onClick={() => setMedia('audioVideo')}>
                    <VideoCameraOutlined />
                    <span><Trans>Audio / Video</Trans></span>
                  </Menu.Item>
                </Menu>
              )}
              trigger={['click']}
            >
              <Button ghost icon={<UploadOutlined />} type='default'>
                <Trans>Create new</Trans>
              </Button>
            </Dropdown>
          </div>
        </>
      )}
    >
      <MediaLibrary />
      <CreateNewMediaModal
        media={media || 'image'}
        onCancel={() => setMedia(undefined)}
        onFinish={() => setMedia(undefined)}
        visible={!!media}
      />
    </MainContainer>
  )
}

const Wrapped: React.FC<any> = props => {
  const Component = useMemo(() => withUserData(MediaLibraryPage), [])
  return <Component {...props} />
}

export default Wrapped
