const getTypeIndex = ({ userID }) => {
  if (userID.startsWith('org-')) {
    return 3
  }
  if (userID.startsWith('team-')) {
    return 2
  }
  return 1
}

const ROLES = ['Owner', 'FullAccess', 'Contributor', 'Reporter', 'Viewer']

/**
 * Sort function return
 *   -1 for having roleA before roleB
 *   0 when equals
 *   +1 for having roleA after roleB
 */
export const compareRoles = (roleA, roleB) => {
  if (roleA.role === roleB.role) {
    return getTypeIndex(roleA) - getTypeIndex(roleB)
  }
  return ROLES.indexOf(roleA.role) - ROLES.indexOf(roleB.role)
}

export const compareOrganisation = (roleA, roleB) => {
  if (roleA.organisationID === roleB.organisationID) {
    return compareRoles(roleA, roleB)
  }
  if (roleA.organisationID === '__EVERYONE__') {
    return -1
  }
  return 1
}
