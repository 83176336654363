export const noop = (): any => null

export const emptyObj: Record<string, any> = {}

export const emptyArr: any[] = []

export const emptyMap: Map<any, any> = new Map()

export const blank = (): any => emptyObj

export const cancelPropagation = evt => evt?.stopPropagation?.()
