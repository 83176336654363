/* eslint-disable max-len,react/display-name,max-lines */
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import {
  CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, ExclamationCircleFilled, PlusOutlined, SyncOutlined
} from '@ant-design/icons'
import {
  Button, Checkbox, Col, Input, Menu, Popconfirm, Popover, Radio, Row, Select as AntSelect, Switch, Tag, TreeSelect
} from 'antd'
import { Trans } from '@lingui/macro'
import IMask from 'imask/esm/imask'
import { ConsoleLogger as Logger } from '@aws-amplify/core'
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc'
import renderHTML from 'react-render-html'
import ReactPhoneInput from 'react-phone-input-2'
import './PhoneInput.scss'
import useDebounceSaving from '../../../hooks/useDebounceSaving'
//  Styles
import './FormComponents.scss'
import {noop} from "../../Defaults";

//  Re export
export { default as TreeEdition } from './TreeEdition'
export { default as TreeEditionObjectValueType } from './TreeEditionObjectValueType'
export { default as FileUploader } from './FileUploader'
export { default as GoogleAutoCompleteAddress } from './GoogleAutocompleteAddress'

const logger = new Logger('FormComponents')

// ==================================================================================================

// Sortable List Component

const SortableItem = SortableElement(({ value }) => (
  <Row align='middle' className='sortable-component-list-item' justify='center' type='flex'>
    <Col span={22}>
      {value}
    </Col>
    <Col span={2}>
      <div className='drag-icon' />
      <div className='drag-icon' />
      <div className='drag-icon' />
    </Col>
  </Row>
))

const SortableList = SortableContainer(({ items, language }) => (
  <ul className='sortableList'>
    {items.map((value, index) => (
      <SortableItem
        key={value.key}
        index={index}
        useDragHandle
        value={typeof value.text === 'object' ? value.text[language] : value.text}
      />
    ))}
  </ul>
))

export class SortableListComponent extends React.Component {
  constructor (props) {
    super(props)
    this.props.onChange([...this.props.items].map(obj => obj.key))
  }

  render () {
    const sorted = [...this.props.items]
    if (this.props.value) {
      const order = {}
      this.props.value.forEach((key, index) => {
        order[key] = index
      })
      sorted.sort((elementA, elementB) => order[elementA.key] - order[elementB.key])
    }

    return (
      <fieldset className='sortableComponent'>
        <legend><Trans>Arrange the individual fields by Drag & Drop</Trans></legend>
        <span className='categoryTop'><Trans>Very important</Trans></span>
        <SortableList
          items={sorted}
          language={this.props.language}
          onSortEnd={sortElement => {
            const newVal = arrayMove(sorted, sortElement.oldIndex, sortElement.newIndex)
              .map(obj => obj.key)
            this.props.onChange(newVal)
          }}
        />
        <span className='categoryBottom'><Trans>Unimportant</Trans></span>
      </fieldset>
    )
  }
}

// ==================================================================================================
// ==================================================================================================
/**
 * Function Component returns a class
 * @param {JSX} AntDesignElement
 * @param {boolean} icons
 * @param {boolean} autoSubmit
 */
const CheckRadioGroupFactory = (AntDesignElement, icons, autoSubmit = false) => props => {
  /**
   * the __order__ property contains an array providing the order of how the icons
   * are ordered eventually. The array is then assigned to keys
   */
  const keys = props.items.__order__ || Object.keys(props.items)

  // if the icons are set to true return a icon with the radio group
  if (icons) {
    return (
      <AntDesignElement.Group
        className='formCheckRadioGroup withIcons'
        onChange={props.onChange}
        value={props.value !== '' ? props.value : null}
      >
        {
          /**
           * looping over the items provided by the order array, if provided, else
           * loop over keys as the come
           */
          keys.map(key => {
            if (key === '__order__') {
              return null
            }

            // assigning the current item, the order is determined by the array provided under __order__
            const currentItem = props.items[key]

            let Element = ''
            if (currentItem.svg) {
              Element = renderHTML(currentItem.svg) // <span dangerouslySetInnerHTML={{ __html: currentItem.svg }} />
            } else if (currentItem.image) {
              Element = <img alt={`${key} icon`} src={currentItem.image} />
            }


            let textLabel

            if (props.label) {
              if (typeof currentItem[props.label] === 'object') {
                textLabel = currentItem[props.label][props.language]
              } else {
                textLabel = currentItem[props.label]
              }
            } else {
              textLabel = currentItem
            }

            /**
             * if the auto submit flag is set, execute the function provided.
             * else the function is submitted the stanard way (onSubmit)
             */
            if (autoSubmit) {
              return (
                <Col
                  key={key} lg={keys.length === 2 ? 8 : 6} md={6} sm={8}
                  xs={11}
                >
                  <AntDesignElement
                    onClick={() => {
                      props.clickIcon()
                    }}
                    value={key}
                  >
                    {Element}
                    <span>{textLabel}</span>
                  </AntDesignElement>
                </Col>
              )
            }

            return (
              <Col
                key={key} lg={keys.length === 2 ? 8 : 6} md={10} sm={8}
                xs={11}
              >
                <AntDesignElement value={key}>
                  {Element}
                  <span>{textLabel}</span>
                </AntDesignElement>
              </Col>
            )
          })
        }
      </AntDesignElement.Group>
    )
  }

  return (
    <AntDesignElement.Group
      className='formCheckRadioGroup'
      onChange={props.onChange}
      value={props.value !== '' ? props.value : null}
    >
      {
        /**
         * generating the items for the radio group
         */
      }
      {
        keys.map(key => {
          if (key === '__order__') {
            return null
          }

          /**
           * creating the elements for the group element.
           * key is the item key of items. Items contains the full set of information.
           */
          let elementText
          if (props.label && props.label !== 'text') {
            if (typeof props.items[key][props.label] === 'object') {
              elementText = props.items[key][props.label][props.language]
            } else {
              elementText = props.items[key][props.label]
            }
          } else if (typeof props.items[key] === 'object') {
            if (props.items[key].text && typeof props.items[key].text === 'object') {
              const selectedLanguage = props.language || 'de'
              elementText = props.items[key].text[selectedLanguage]
            } else {
              elementText = props.items[key].text
            }
          } else {
            elementText = props.items[key]
          }

          return <AntDesignElement key={key} value={key}>{elementText}</AntDesignElement>
        })
      }
    </AntDesignElement.Group>
  )
}

/**
 * feeding the and design element to a custom component. The second params is the
 * presence of icons when rendering.
 */
export const CheckboxGroup = CheckRadioGroupFactory(Checkbox, false)
export const RadioGroup = CheckRadioGroupFactory(Radio, false)
export const CheckboxGroupIcons = CheckRadioGroupFactory(Checkbox, true)
export const RadioGroupIcons = CheckRadioGroupFactory(Radio, true)
export const RadioGroupIconsClick = CheckRadioGroupFactory(Radio, true, true)
export const CheckboxBoolean = ({ onChange = null, value = null, ...props }) => (
  <Checkbox {...props} checked={value} onChange={useCallback(evt => (onChange ? onChange(evt.target.checked) : null), [onChange])} />
)

export const InlineRadioGroupButtons = props => {
  const {
    value, items, onChange, ...otherProps
  } = props
  return (
    <Radio.Group {...otherProps} buttonStyle='solid' onChange={onChange} value={`${value}`}>
      {Object.keys(items)
        .map(radioKey => <Radio.Button key={radioKey} value={radioKey}>{items[radioKey]}</Radio.Button>)}
    </Radio.Group>
  )
}


// ==================================================================================================
// ==================================================================================================
/**
 * extended select component
 */
export class Select extends React.Component {
  constructor (props) {
    super(props)
    this.focus = this.focus.bind(this)
    this.tree = React.createRef()
    this.logger = new Logger('Shared select')
  }

  focus () {
    this.tree.current.focus()
  }

  // ==================================================================================================
  /**
   * generates the individual elements of the selector
   * @param {object} root is the items fed to the tree select component
   * @param {function|string} label returns an React.Element if text is available as a property
   */
  generateTree = (root, label, filter = null, language = 'de', path = []) => {
    // children key defaults to items
    const childrenKey = this.props.childrenKey || 'items'

    // the key path of the current value
    const currentKey = path.join('.')

    // checking if the children key can be found as a property in the roots item list
    if (root[childrenKey]) {
      const keys = root[childrenKey].__order__ || Object.keys(root[childrenKey])

      const childrenNodes = keys && keys.length > 0 && keys.map(
        key => this.generateTree(root[childrenKey][key], label, filter, language, [...path, key])
      )
        .filter(elm => elm)

      if (!filter || (childrenNodes && childrenNodes.length > 0) || filter(root)) {
        this.hasOnlyRootChildren = false

        const selectable = this.props.selectable ? this.props.selectable(root, path) : true

        // returning the child tree node elements
        return (
          <TreeSelect.TreeNode
            key={currentKey}
            alwaysShown={this.props.alwaysShown ? this.props.alwaysShown(root, path) : null}
            className={selectable ? '' : 'notSelectable'}
            selectable={selectable}
            title={(typeof label === 'function' ? label(root, path) : root[label]) || path.slice(-1) || ''}
            value={currentKey}
          >
            {childrenNodes.length > 0 ? childrenNodes : null}
          </TreeSelect.TreeNode>
        )
      }
      return null
    }
    /**
     * checking if root is valid react element, if not checking if label is a function, if the label is a function
     * pass the root object to the label function, sitting on the parent element (which in this case returns an React.Element)
     * if the property text is availalbe
     *
     */
    // eslint-disable-next-line
    const text = root.$$typeof ? root : (typeof label === 'function' ? label(root, path) : root[label])


    /**
     * if text is defined or if the root is a string, effectively in can be an object or a string
     */
    if (text || typeof root === 'string') {
      /**
       * filter is not provided in this case, hence is null, and thus the react element is returned
       */
      let title = text
      if (Object(title) === title) {
        if (!title.$$typeof) {
          title = title[language] || ''
        }
      }

      const selectable = this.props.selectable ? this.props.selectable(root, path) : true

      return (!filter || filter(root)) && (
        <TreeSelect.TreeNode
          key={currentKey}
          alwaysShown={this.props.alwaysShown ? this.props.alwaysShown(root, path) : null}
          className={selectable ? '' : 'notSelectable'}
          selectable={selectable}
          title={title || root}
          value={currentKey}
        />
      )
    }
    /**
     * if all the other tests have failed go one level deeper, and map through the items of root
     */
    return Object.keys(root).map(
      key => this.generateTree(root[key], label, filter, language, [...path, key])
    ).filter(elm => elm)
  }

  // ==================================================================================================
  render () {
    const {
      noSearch, collapsed, label, items, filter, language, dropdownClassName,
      alwaysShown, selectable, // eslint-disable-line
      ...otherProps
    } = this.props

    const { value } = this.props

    logger.debug('the tree value: ', value)

    this.hasOnlyRootChildren = true

    // generating the select components with styles etc.
    const children = this.generateTree(items, label || '__null__', filter, language)

    return (
      <TreeSelect
        {...otherProps}
        ref={this.tree}
        dropdownClassName={[(dropdownClassName || ''), (this.hasOnlyRootChildren ? 'selectOnlyRootChildren' : '')].join(' ')}
        dropdownStyle={{
          maxHeight: '40vh',
          overflow: 'auto',
          zIndex: 11000
        }}
        filterTreeNode={(inputValue, node) => (
          node.props.alwaysShown || node.props.value.toLowerCase().includes(inputValue.toLowerCase())
          || (typeof node.props.title === 'string' && node.props.title.toLowerCase().includes(inputValue.toLowerCase()))
        )}
        showSearch={!noSearch}
        treeDefaultExpandAll={!collapsed}
        value={value === '' ? null : value}
      >
        {children}
      </TreeSelect>
    )
  }
}

// ==================================================================================================
/**
 * input switch component. Placeholder is placed inside the span elements.
 */

export const SwitchInput = ({
  placeholder,
  value = false,
  checkedChildren = <b><Trans>Yes</Trans></b>,
  unCheckedChildren = <b><Trans>No</Trans></b>,
  className = '',
  ...otherProps
}) => (
  <span className={className}>
    {placeholder}
    <Switch
      {...otherProps}
      checked={value}
      checkedChildren={checkedChildren}
      unCheckedChildren={unCheckedChildren}
    />
  </span>
)

// ==================================================================================================
/**
 * class renders input field if the name is equity to this.props.name. The name and edit propoerties
 * are set to equal when the user clicks on edit. Else a plan text with the name is shown.
 */
export class SimpleTextEdit extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      value: null,
      touched: false
    }
  }

  // ==================================================================================================
  render () {
    const isEdit = this.props.edit === this.props.name
    const innerComponent = () => {
      if (isEdit) {
        const Element = this.props.element || Input
        const elementProps = this.props.elementProps || {}

        return (
          <Element
            {...elementProps}
            className='editInput'
            onChange={value => {
              if (value.target) {
                this.setState({
                  value: value.target.value,
                  touched: true
                })
              } else {
                this.setState({
                  value,
                  touched: true
                })
              }
            }}
            placeholder={this.props.name}
            style={{
              display: 'inline',
              width: 'auto',
              flexGrow: '1'
            }}
            value={this.state.touched ? this.state.value : this.props.value}
          />
        )
      }
      /**
       * renders either the value provided or the children of the element
       */
      return this.props.children || this.props.value
    }

    return (
      <Row
        align='top' className='simpleTextEdit' gutter={16} justify='center'
        type='flex'
      >
        <Col className='labelContainer' span={3}>
          <label>
            {this.props.name}
            :&nbsp;
          </label>
        </Col>
        <Col span={19}>
          {innerComponent()}
        </Col>
        <Col span={2}>
          <Button
            className='actionButton'
            icon={isEdit ? <CheckOutlined /> : <EditOutlined />}
            onClick={() => {
              if (isEdit) {
                // setting the edit property back to null, hence the input field is hidden, and the plain text is rendered
                this.props.onEdit('')
                this.props.save(this.state.touched ? this.state.value : this.props.value)
              } else {
                this.props.onEdit(this.props.name)
              }
            }}
            size='small'
            type='primary'
          >
            {isEdit ? 'Save' : 'Edit'}
          </Button>
        </Col>
      </Row>
    )
  }
}

export const InputLink = ({ value, onChange, className = '', ...props }) => {
  const [protocol, setProtocol] = useState('https://')
  const [link, setLink] = useState('')

  const setData = useMemo(() => (newPropsLink, noDefault) => {
    const split = newPropsLink.split('://')
    if (split.length >= 2) {
      setProtocol(`${split.shift()}://`)
      setLink(split.join('://'))
    } else {
      !noDefault && setProtocol('https://')
      setLink(newPropsLink)
    }
  }, [])

  const { saveTimeout, forceSave } = useDebounceSaving(
    500,
    value,
    `${protocol}${link}`,
    setData,
    onChange
  )

  const protocolSelect = (
    <AntSelect
      {...props}
      className='protocolSelect'
      onBlur={forceSave}
      onChange={val => {
        setProtocol(val, true)
        saveTimeout()
      }}
      value={protocol}
    >
      <AntSelect.Option value='https://'>https://</AntSelect.Option>
      <AntSelect.Option value='http://'>http://</AntSelect.Option>
    </AntSelect>
  )

  return (
    <Input
      {...props}
      addonBefore={protocolSelect}
      className={`inputLink ${className}`}
      onBlur={forceSave}
      onChange={evt => {
        setData(evt.target.value)
        saveTimeout()
      }}
      value={link}
    />
  )
}


// ==================================================================================================

/** ******************************************************
 *            Input mask
 ****************************************************** */
// Doc https://imask.js.org/
const emptyStr = ''
export const InputMask = (
  {
    mask,
    maskChar = '_',
    lazyMask = false,
    value,
    onChange,
    ...props
  }
) => {
  const inputRef = useRef(null)
  const internalValue = value || emptyStr
  const prevRef = useRef(internalValue)
  const [masked, setMasked] = useState()

  const onChangeRef = useRef(onChange)
  onChangeRef.current = onChange

  useLayoutEffect(() => {
    if (inputRef.current && inputRef.current.input) {
      setMasked(old => {
        if (old && old.destroy) {
          old.destroy()
        }

        return IMask(inputRef.current.input, {
          mask,
          placeholderChar: maskChar,
          lazy: lazyMask
        })
      })
    }
  }, [mask, maskChar, lazyMask])

  useLayoutEffect(() => {
    if (masked) {
      masked.on('accept', () => {
        const next = masked.value || emptyStr
        if (onChangeRef.current && prevRef.current !== next) {
          prevRef.current = next
          onChangeRef.current(next)
        }
      })
      return () => masked.destroy()
    }
    return undefined
  }, [masked])

  useMemo(() => {
    if (masked && internalValue !== prevRef.current) {
      masked.value = internalValue
    }
  }, [masked, internalValue])

  return (
    <Input ref={inputRef} className='simple-line' {...props} value={internalValue} />
  )
}

// ==================================================================================================

export const PhoneInput = ({ value = null, onChange = noop, disabled = false, displayOnly = false }) => {
  const divRef = useRef(null)
  const resizeObserver = useMemo(() => new ResizeObserver(([entry]) => {
    entry.target.style.setProperty('--flag-scale', (parseFloat(window.getComputedStyle(document.body).fontSize) * 1.2) / 20)
  }), [])
  useLayoutEffect(() => {
    const elem = divRef.current
    if (elem) {
      resizeObserver.observe(elem)
      return () => resizeObserver.unobserve(elem)
    }
    return undefined
  }, [resizeObserver])

  return (
    <div ref={divRef}>
      <ReactPhoneInput
        containerClass={`phoneInput ${disabled ? 'disabled' : ''} ${displayOnly ? 'displayOnly' : ''}`}
        copyNumbersOnly={false}
        country='de'
        disabled={disabled}
        dropdownClass='phoneInputDropdown'
        enableSearch
        inputClass='ant-input simple-line'
        onChange={useCallback(newValue => onChange(`+${newValue}`), [onChange])}
        value={value}
      />
    </div>
  )
}

// ==================================================================================================


/**
 * creates a wrapper and inserts the input fields for a radio or checkbox group as children.
 */
export const ListEdition = ({ value, onChange, InputComponent }) => {
  const prefix = useMemo(() => Date.now(), [Array.isArray(value) && value.length])

  const update = (index, newValue) => {
    const newList = [...value]
    newList[index] = newValue
    onChange(newList)
  }

  const add = index => {
    const newList = [...value]
    newList.splice(index, 0, '')
    onChange(newList)
  }

  const remove = index => {
    const newList = [...value]
    newList.splice(index, 1)
    onChange(newList)
  }

  return (
    <div className='inputListEdition'>
      {Array.isArray(value) && (
        value.map((label, inputFieldIndex) => (
          <div key={`${prefix}-${inputFieldIndex}`} className='inputListWrapper'>
            <InputComponent
              defaultValue={label}
              index={inputFieldIndex}
              onChange={event => update(inputFieldIndex, (event && event.target) ? event.target.value : event)}
            />
            <Button
              className='deleteInputItem'
              danger
              ghost
              icon={<DeleteOutlined />}
              onClick={() => remove(inputFieldIndex)}
            />
          </div>
        ))
      )}
      <Button
        className='addInputItem'
        icon={<PlusOutlined />}
        onClick={() => add((Array.isArray(value) && value.length) || 0)}
        type='primary'
      >
        <Trans>Add option</Trans>
      </Button>
    </div>
  )
}

// ==================================================================================================
/**
 * Button with a popover element
 * @param {object} props
 */
export const ConfirmButton = props => {
  const [loading, setLoading] = useState(false)

  // taking out the props, so it does not cause an error when passing the
  // remaining props to the button
  const { onClick, confirmMessage, disabled, loading: loading_, placement, ...innerProps } = props

  const InnerButton = ({ onClick: buttonOnClick, ...buttonProps }) => (
    <Button
      {...buttonProps} disabled={disabled} loading={loading || loading_}
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()
        buttonOnClick(event)
      }}
    />
  )

  return (
    <Popconfirm
      cancelText={<Trans>No</Trans>}
      disabled={loading || disabled || loading_}
      okText={<Trans>Yes</Trans>}
      onConfirm={async event => {
        setLoading(true)
        try {
          await onClick(event)
        } finally {
          setLoading(false)
        }
      }}
      overlayClassName='confirmButtonTooltip'
      placement={placement || 'topLeft'}
      title={confirmMessage || <Trans>Are you sure to want delete this item?</Trans>}
    >
      <InnerButton {...innerProps} />
    </Popconfirm>
  )
}

export const ConfirmMenuItem = ({
  onClick,
  placement = 'bottomRight',
  confirmMessage = <Trans>Are you sure to want delete this item?</Trans>,
  className = '',
  children,
  ...props
}) => {
  const [visible, setVisible] = useState(false)
  const visibleClick = param => {
    const evt = param.domEvent || param
    evt.preventDefault()
    evt.stopPropagation()
    setVisible(bool => !bool)
  }

  return (
    <Menu.Item {...props} className={`dangerMenuItem ${className || ''}`} onClick={visibleClick}>
      <Popover
        content={(
          <>
            <div className='ant-popover-message'>
              <ExclamationCircleFilled />
              <div className='ant-popover-message-title'>
                {confirmMessage}
              </div>
            </div>
            <div className='ant-popover-buttons'>
              <Button onClick={visibleClick} size='small'>
                <Trans>No</Trans>
              </Button>
              <Button
                onClick={evt => {
                  visibleClick(evt)
                  onClick()
                }} size='small' type='primary'
              >
                <Trans>Yes</Trans>
              </Button>
            </div>
          </>
        )}
        overlayClassName='confirmButtonTooltip'
        placement={placement}
        visible={visible}
      >
        {children}
      </Popover>
    </Menu.Item>
  )
}

// ==================================================================================================
const DInput = React.forwardRef(({ addTag, ...props }, ref) => {
  const onChange = evt => addTag(evt.target.value)
  return (<Input {...props} ref={ref} onBlur={onChange} onPressEnter={onChange} />)
})

export const TagListInput = ({
  value = [], onAdd = noop, addText = <Trans>Add tag</Trans>, onDelete = noop, onChange = noop, max, InputTag = DInput, readOnly
}) => {
  const [inputVisible, setInputVisible] = useState(false)
  const inputRef = useRef()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (inputVisible) {
      inputRef.current && inputRef.current.focus()
    }
  }, [inputVisible])

  const addTag = async text => {
    setLoading(true)
    if (text) {
      try {
        await onAdd(text)
        const newTags = [...value, text]
        await onChange(newTags)
      } catch (err) {
        console.error('Failed to add a tag', text)
      }
    }
    setInputVisible(false)
    setLoading(false)
  }

  let addTagElm = null
  if (!max || value.length < max) {
    addTagElm = inputVisible ? (
      <InputTag
        ref={inputRef}
        addTag={addTag}
        className='tagInputAdd'
        disabled={loading}
        size='small'
      />
    ) : (
      <Button
        className={`ant-tag tagsListAdd ${readOnly ? 'disabled' : 'tags'}`}
        disabled={readOnly}
        onClick={() => !readOnly && !loading && setInputVisible(true)}
      >
        <span>{loading ? <SyncOutlined spin sync /> : <PlusOutlined />} {addText}</span>
      </Button>
    )
  }

  return (
    <div className='tagsList'>
      {
        value.map((text, index) => (
          <Tag
            key={text}
            className={readOnly ? 'disabled' : ''}
            color='#5678a4'
            onClick={async () => {
              if (!readOnly && !loading) {
                const newTags = [...value]
                try {
                  setLoading(true)
                  await onDelete(text)
                  newTags.splice(index, 1)
                  await onChange(newTags)
                  setLoading(false)
                } catch (err) {
                  console.error('Failed to delete a tag', text, index)
                }
              }
            }}
          >
            <span><span>{text}</span>{loading ? <SyncOutlined spin sync /> : <CloseOutlined />}</span>
          </Tag>
        ))
      }
      {addTagElm}
    </div>
  )
}
