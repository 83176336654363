import React, { useMemo } from 'react'
import { InputNumber, InputNumberProps } from 'antd'

import { CustomInput } from '../Types'
import { useNumberInputExtraProps } from './NumberInput'


const useAmountInputExtraProps: typeof useNumberInputExtraProps = onKeyDown => {
  const numberInputExtraProps = useNumberInputExtraProps(onKeyDown)
  return useMemo(() => ({
    ...numberInputExtraProps,
    onKeyDown: evt => {
      if (evt.key === '-' && (evt.target as any).selectionStart !== 0) {
        evt.preventDefault()
      } else {
        numberInputExtraProps.onKeyDown(evt)
      }
    }
  }), [numberInputExtraProps])
}

const AmountInput: React.FC<InputNumberProps<number> & CustomInput<number>> = props => (
  <InputNumber {...props} {...useAmountInputExtraProps(props.onKeyDown)} />
)

export default AmountInput
