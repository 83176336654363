import React, { useContext, useMemo } from 'react'
import { Button, Card, Dropdown, Menu, Tooltip } from 'antd'
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons'
import { t, Trans } from '@lingui/macro'

import { UserAndCompanyDataContext } from '../../../../../../Layouts/Constants'
import { ContractContext } from '../../../../Contexts'
import { ConfirmMenuItem } from '../../../../../../SharedComponents/FormComponents'
import { UserAvatarByID, UserFullName } from '../../../../../../Organisations/UserRoleDisplay/UserAvatarList'

import signedIcon from '../../../../../../../img/t-logo green.svg'
import { PartyInfo } from '../Context/InstanceUsersContext'
import SwitchSigning from './SwitchSigning'


interface UserCardProps {
  partyInfo: PartyInfo
  titleTag?: React.ReactNode
  actionLoading?: boolean
  cardActions?: any[]
  deleteUser?: () => any
}

export const SignedTLogo: React.FC = () => (
  <div className='doneSigned'>
    <img src={signedIcon} />
    <span><Trans>Contract signed</Trans></span>
  </div>
)
const UserCard: React.FC<UserCardProps> = ({
  partyInfo,
  titleTag,
  actionLoading,
  cardActions,
  deleteUser,
  children
}) => {
  const { contract } = useContext<any>(ContractContext)
  const isContractSigned = contract && (contract.contractStatus === 'signed' || contract.signaturesHolder?.pdfHash)
  const isTemplateView = contract.contractID === '__preview__'

  const { company: { organisationID, teams } } = useContext(UserAndCompanyDataContext)
  const allTeams = useMemo(() => ({
    ...teams,
    __Admin: { userID: '__Admin', name: t`Managers` },
    [organisationID]: { userID: organisationID, name: t`Other company members` }
  }), [organisationID, teams])

  const isSigningFlow = !!contract.signaturesHolder || contract.contractStatus === 'signed' || contract.contractStatus === 'frozen'
  let signingSwitch: React.ReactNode = null

  //  Build signing switch
  if (!isContractSigned && !partyInfo.signedDate && !isTemplateView && !allTeams[partyInfo.partyData.partyID] && contract.contractStatus !== 'imported') {
    signingSwitch = <SwitchSigning partyInfo={partyInfo} />

    if (isSigningFlow) {
      signingSwitch = (
        <Tooltip title={<Trans>Signing rights cannot be changed once signing process has started</Trans>}>
          <span>{signingSwitch}</span>
        </Tooltip>
      )
    } else {
      signingSwitch = (
        <Tooltip title={<Trans>Tap to give permission to sign</Trans>}>
          <span>{signingSwitch}</span>
        </Tooltip>
      )
    }

    signingSwitch = (
      <div className='signerSwitch'>
        <span><Trans>Signing Contract</Trans></span>
        {signingSwitch}
      </div>
    )
  }

  return (
    <Card
      actions={cardActions}
      className='betterCard'
      extra={deleteUser ? (
        <Dropdown
          overlay={(
            <Menu key='menu'>
              <ConfirmMenuItem onClick={deleteUser}>
                <DeleteOutlined />
                <span><Trans>Delete</Trans></span>
              </ConfirmMenuItem>
            </Menu>
          )}
          placement='bottomRight'
          trigger={['click']}
        >
          <Button
            className='noBorder moreButton'
            ghost
            icon={<MoreOutlined />}
            loading={actionLoading}
            type='default'
          />
        </Dropdown>
      ) : undefined}
      size='small'
      title={(
        <>
          <UserAvatarByID autoSide size='1.8rem' userID={partyInfo.partyData.partyID} />
          <div className='userData'>
            <span className='userName'>
              <UserFullName userID={partyInfo.partyData.partyID} />
            </span>
            {titleTag || null}
          </div>
        </>
      )}
    >
      {signingSwitch}
      {partyInfo.signedDate ? <SignedTLogo /> : children}
    </Card>
  )
}

export default UserCard
