import React, { useContext } from 'react'
import { useParams } from 'react-router'
import { Trans } from '@lingui/macro'
import { SettingOutlined, TagOutlined } from '@ant-design/icons'
import { Tag, Tooltip } from 'antd'

import { TemplateContext } from '../../Contexts'
import TemplateTagging from '../../../../Template/TemplateTagging'
import { dateTypes, timeTypes } from '../../../../Template/TemplateHelperFunctions'
import { displayDate, getElem } from '../../../../Template/_TemplateHelperFunctions'

import './SectionMetadataDisplayStyles.scss'
import { SectionProps } from './Types'

/**
 * Displaying dynamic section tooltip
 */
const errorStyle = {
  color: 'red', margin: 0, fontWeight: 700, textDecoration: 'underline', letterSpacing: 0.5
}
const DynamicSectionDisplay: React.FC<SectionProps> = ({ section }) => {
  const { lang } = useParams()
  const { template } = useContext<any>(TemplateContext)
  const field = section.field && getElem(section.field, template.fields)
  const fieldValues = field && field.values && (field.values[template.lang || 'en'] || field.values)

  const error = msg => (
    <div className='cardContentWrapper conditionalDisplay'>
      <h3 style={errorStyle}>{msg}</h3>
    </div>
  )

  const conditionalSentenceWrapper = (children, addendum: any = '') => (
    <div className='cardContentWrapper conditionalDisplay'>
      <span>
        <Trans>This section will be displayed in the contract only if the field</Trans>
        {' '}
        <Tag>{field.name}</Tag>
        {' '}
      </span>
      {children}
      {addendum}
    </div>
  )

  if (!field) {
    return error(<Trans>Invalid condition missing field!</Trans>)
  }
  if (field.type === 'list') {
    if (Array.isArray(section.activeValues)) {
      return conditionalSentenceWrapper(
        <>
          <span>
            <Trans>is set to one of these values</Trans>
            {' '}
          </span>
          {
            section.activeValues.map(
              keyActiveValue => <Tag key={keyActiveValue}>{fieldValues[keyActiveValue]}</Tag>
            )
          }
        </>
      )
    }
    return error(<Trans>Invalid condition missing active values!</Trans>)
  }
  if (field.type === 'yesNo') {
    /**
     * yes or no conditions
     */
    if (section.active) {
      return (
        <>
          {conditionalSentenceWrapper(
            <span>
              <Trans>is set to</Trans>
              {' '}
              <Tag key={`${section.sectionID}-value`}>
                {{
                  yes: <Trans>Yes</Trans>,
                  no: <Trans>No</Trans>
                }[section.active]}
              </Tag>
            </span>,
            lang === 'de' && <span>gesetzt wird.</span>
          )}
        </>
      )
    }
    return error(<Trans>Invalid condition missing active values!</Trans>)
  }
  if (/(date|time|number|amount|listOfFormattedText)/.test(field.type)) {
    const renderSectionValueCondition = (value, valueType, isRelativeToContractFillingDate) => {
      const newValue = value
      if (field.type !== 'number') {
        const type = (field.type === 'date' ? dateTypes : timeTypes)[valueType]
        if (field.type === 'date') {
          if (isRelativeToContractFillingDate) {
            if (value < 0) {
              return <>{value} past {type}</>
            }
            if (value === 0) {
              return <Trans>today</Trans>
            }
            return <>{value} next {type}</>
          }
          return displayDate(value)
        }
        return <>{value} {type}</>
      }
      return newValue
    }

    if (section.inferiorBorder != null && section.superiorBorder != null) {
      const lower = renderSectionValueCondition(section.inferiorBorder, section.inferiorBorderType, section.isRelativeToContractFillingDate)
      const upper = renderSectionValueCondition(section.superiorBorder, section.superiorBorderType, section.isRelativeToContractFillingDate)
      return conditionalSentenceWrapper(<Trans>is between <Tag>{lower}</Tag> and <Tag>{upper}</Tag></Trans>)
    }

    if (section.inferiorBorder != null) {
      const lower = renderSectionValueCondition(section.inferiorBorder, section.inferiorBorderType, section.isRelativeToContractFillingDate)
      return conditionalSentenceWrapper(<Trans>is greater than <Tag>{lower}</Tag></Trans>)
    }

    if (section.superiorBorder != null) {
      const upper = renderSectionValueCondition(section.superiorBorder, section.superiorBorderType, section.isRelativeToContractFillingDate)
      return conditionalSentenceWrapper(<Trans>is lower than <Tag>{upper}</Tag></Trans>)
    }
    return error(<Trans>Invalid condition we are missing acceptance borders!</Trans>)
  }
  return error(<Trans>Invalid condition the type of selected field is invalid!</Trans>)
}


// ==============================================================================================


// ==============================================================================================


/**
 * Displaying metadata tooltips (only if it's not header or footer)
 */
const SectionMetadataDisplay: React.FC<SectionProps> = ({ section, updateSection }) => (
  <>
    <div className='sectionMetadataDisplay'>
      {
        ((Array.isArray(section.tags) && section.tags.length > 0) || (section.weight && section.weight !== 'empty')) && (
          <Tooltip
            overlayClassName='sectionMetadataTooltip'
            placement='rightTop'
            title={(
              <>
                <h1><Trans>Section tagging</Trans></h1>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <TemplateTagging noEdit value={{ tags: section.tags || [], weight: section.weight || 'empty' }} />
              </>
            )}
          >
            <TagOutlined />
          </Tooltip>
        )
      }
      {
        section.isDynamicSection && section.field && (
          <Tooltip
            overlayClassName='sectionMetadataTooltip'
            placement='rightTop'
            title={(
              <>
                <h1><Trans>Dynamic section</Trans></h1>
                <DynamicSectionDisplay section={section} updateSection={updateSection} />
              </>
            )}
          >
            <SettingOutlined />
          </Tooltip>
        )
      }
    </div>
  </>
)

export default SectionMetadataDisplay
