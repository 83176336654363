import React, { useEffect, useMemo, useState } from 'react'
import { Trans } from '@lingui/macro'
import { WechatOutlined } from '@ant-design/icons'
import { withUserData } from '../Layouts/AuthenticatedPage'
import MainContainer from '../Layouts/MainLayout/MainContainer'
import RestService from '../../RestService'

import './FeedbackStyles.scss'

const Feedback = () => {
  const [productBoardUrl, setProductBoardUrl] = useState()

  useEffect(() => {
    (async () => {
      setProductBoardUrl(await RestService('POST', '/utils/generate-productboard-token'))
    })()
  }, [])

  return (
    <iframe
      frameBorder='0'
      height='100%'
      src={productBoardUrl}
      width='100%'
    />
  )
}

Feedback.FullPage = props => {
  const Component = useMemo(() => withUserData(() => (
    <MainContainer
      mainContentClass='feedBackPage'
      topbarContent={(
        <div className='topbarMainContent'>
          <WechatOutlined className='headerIcon' twoToneColor='#3DBD7D' />
          <h1 className='title'><Trans>top.legal feedback</Trans></h1>
        </div>
      )}
    >
      <Feedback />
    </MainContainer>
  )), [])
  return <Component {...props} />
}

export default Feedback
