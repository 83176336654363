/* eslint-disable import/no-unresolved */
import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Select, Tag, Tooltip } from 'antd'
import { Trans } from '@lingui/macro'
// components
import { Select as CustomSelect } from '../SharedComponents/FormComponents/index'
import ComponentWrapperFactory from '../SharedComponents/ComponentWrapperFactory'
// styles
import './styles/TemplateTagging.scss'

const { Option } = Select

export const tagStyle = {
  width: 'auto',
  textAlign: 'center'
}

export const weights = {
  empty: {
    text: <Trans>No assessment</Trans>,
    color: '#CACACA'
  },
  ppForSender: {
    text: <Trans>++ Sender</Trans>,
    color: '#3dbd7d'
  },
  pForSender: {
    text: <Trans>+ Sender</Trans>,
    color: '#8BD7B1'
  },
  neutral: {
    text: <Trans>Neutral</Trans>,
    color: 'grey'
  },
  pForReceiver: {
    text: <Trans>+ Receiver</Trans>,
    color: '#FFB115'
  },
  ppForReceiver: {
    text: <Trans>++ Receiver</Trans>,
    color: '#FF6868'
  }
}

// ==================================================================================================
// ==================================================================================================

const TemplateTaggingComponent = props => {
  const tags = Array.isArray(props.value.tags) ? props.value.tags : []
  if (props.noEdit) {
    if (tags.length > 0 || (props.value.weight && props.value.weight !== 'empty')) {
      return (
        <div className='tagsContainer noEdit'>
          {
            /**
             * returning the tags that a user has entered, if the
             * section is not in the edit mode
             */
            tags.map(tag => {
              const isLongTag = tag.length > 20
              const tagElem = (
                <Tag
                  key={tag} className='tags' closable={false} color='#5678a4'
                  style={{ marginTop: 2 }}
                >
                  {tag}
                </Tag>
              )
              return isLongTag ? (
                <Tooltip key={tag} title={tag}>
                  {tagElem}
                </Tooltip>
              ) : tagElem
            })
          }
          {props.value.weight && props.value.weight !== 'empty' && (

            <div style={{ width: 'auto', marginTop: '5px' }}>
              <Tag color={weights[props.value.weight].color}>{weights[props.value.weight].text}</Tag>
            </div>
          )}
        </div>
      )
    }
    return null
  }

  /**
   * displaying the select tag container if the user is editing this section.
   * plus the selector to assess and interpret the favourability of the
   */
  return (
    <div className='tagsContainer'>
      <div className='labelSelector'>
        <span className='selectorLabel'><Trans>Add tags:</Trans></span>
        <Select
          className='simple-line'
          mode='tags'
          onChange={newTags => props.onChange({ tags: newTags, weight: props.value.weight })}
          placeholder={<Trans>Add tags</Trans>}
          value={tags}
        >
          {props.organisationTags.map(tag => <Option key={tag}>{tag}</Option>)}
        </Select>
        <Tooltip title={<Trans>Use tags to search for paragraphs with matching tags throughout your organisation.</Trans>}>
          <InfoCircleOutlined />
        </Tooltip>
      </div>
      <div className='weightSelector'>
        <span className='selectorLabel'><Trans>In favour of:</Trans></span>
        <CustomSelect
          className='simple-line weightSelect'
          dropdownClassName='weightSelectorMenu'
          items={weights}
          label={item => (item.text ? (
            <>
              <Tag color={item.color} style={tagStyle}>{item.text}</Tag>
            </>
          ) : null)}
          noSearch
          onChange={value => props.onChange({ tags: props.value.tags, weight: value })}
          value={props.value.weight}
        />
        <Tooltip
          title={(
            <Trans>
              Use the assessment labels to indicate to other users who would benefiting from the inclusion of the consecutive paragraph.
            </Trans>
          )}
        >
          <InfoCircleOutlined />
        </Tooltip>
      </div>
    </div>
  )
}

// ==================================================================================================
const mapStateToProps = state => ({
  organisationTags: state.template.organisationTags
})

export default ComponentWrapperFactory(TemplateTaggingComponent)
  .withRedux(mapStateToProps)
  .build()
