import React, { useMemo } from 'react'
import { Trans } from '@lingui/macro'
import CardSelector, { CardSelectorProps } from '../../../SharedComponents/CardSelector'

import './SlateInlineBlockSelectorStyles.scss'

const BlockDisplay: React.FC<{ type: string }> = ({ type }) => (
  <div className='slateInlineBlockSelector'>
    <div className='block'><p><span /></p></div>
    <div className={`block ${type}`}>
      <ul>
        <li><p><span /></p>
          <ul className='block'>
            <li><p><span /></p></li>
          </ul>
        </li>
      </ul>
    </div>
    <div className='block'><p><span /></p></div>
  </div>
)

const InlineDisplay: React.FC<{ type: string }> = ({ type }) => (
  <div className='slateInlineBlockSelector'>
    <div className='block'><p><span /></p></div>
    <div className='block'><p><span /></p></div>
    <div className='block'><p><span /><span className={type} /><span /></p></div>
    <div className='block'><p><span /></p></div>
  </div>
)

const SlateInlineBlockSelector: React.FC<Omit<CardSelectorProps, 'cards'> & { type: 'inputField' | 'conditionalText' }> = ({
  type,
  ...props
}) => {
  const cards = useMemo(() => [{
    value: 'block',
    title: <Trans>Block element</Trans>,
    cover: <BlockDisplay type={type} />,
    description: (
      <p><Trans>Usefull for <b>complex or multiline</b> dynamic element</Trans></p>
    )
  }, {
    value: 'inline',
    title: <Trans>Inline text</Trans>,
    cover: <InlineDisplay type={type} />,
    description: (
      <p><Trans>Usefull for dynamic <b>text</b> that should be <b>injected into an existing paragraph</b></Trans></p>
    )
  }], [type])

  return <CardSelector {...props} cards={cards} />
}

export default SlateInlineBlockSelector
