import React, { useCallback, useEffect, useState } from 'react'
import Storage from '@aws-amplify/storage'
import { CloudUploadOutlined, SyncOutlined } from '@ant-design/icons'
import { notification as antNotif, Upload } from 'antd'
import { Trans } from '@lingui/macro'
import Auth from '@aws-amplify/auth'
import ImgCrop from 'antd-img-crop'
import './ImageUploader.scss'


/** *******************************************************************
 *                    New Image uploader
 ******************************************************************* */
const ImageUploader = ({ value, onChange, accept = '.jpg,.jpeg,.png', filePrefix, placeholder, storageLevel = 'private', crop, ...props }) => {
  const [loadedFile, setLoadedFile] = useState()

  /** *******************************************************************
   *                      Load a file
   ******************************************************************* */
  const loadFile = useCallback(async fileName => {
    const extraOptions = {}
    if (storageLevel !== 'public') {
      const split = fileName.split('/')
      split.shift()
      extraOptions.identityId = split.shift()
      fileName = split.join('/')
    }

    const url = await Storage.get(fileName, {
      level: storageLevel,
      ...extraOptions
    })

    setLoadedFile({
      url,
      name: fileName,
      extraOptions,
      status: 'done',
      uid: fileName
    })
  }, [storageLevel])
  useEffect(() => { value ? loadFile(value) : setLoadedFile(null) }, [value, loadFile])

  /** *******************************************************************
   *                      Remove handler
   ******************************************************************* */
  const onRemove = async () => {
    // removing the file from the bucket
    try {
      await Storage.remove(loadedFile.name, {
        level: storageLevel,
        ...loadedFile.extraOptions
      })
    } catch (err) {
      console.error('delete error', err)
    }

    // sending a notification message
    window.notification.success({
      message: <Trans>Delete successful</Trans>,
      description: <Trans>Your document has been removed from memory.</Trans>
    })

    onChange(null)
  }

  /** *******************************************************************
   *                      Upload handler
   ******************************************************************* */
  const uploadFile = async file => {
    if (!accept.match(new RegExp(`.${file.name.split('.').pop(-1)}`))) {
      window.notification.error({
        message: <Trans>Invalid file type</Trans>,
        description: <Trans>We accept only "{accept}"</Trans>
      })
      setTimeout(() => antNotif.close('UploadingNotification'), 100) // Because the return is to speed and notification not closed
    } else {
      try {
        // uploading the document to the s3 bucket
        const split = file.name.split('.')
        const ext = split.pop()
        const { key } = await Storage.put(`${filePrefix || split.join('.')}.${ext}`, file, {
          level: storageLevel,
          contentType: file.type
        })
        if (storageLevel !== 'public') {
          const { identityId } = await Auth.currentCredentials()
          onChange(`${storageLevel}/${identityId}/${key}`)
        } else {
          onChange(key)
        }

        antNotif.close('UploadingNotification')
        window.notification.success({
          message: <Trans>Upload successful</Trans>,
          description: <Trans>Your document has been securely uploaded.</Trans>
        })
      } catch (err) {
        console.error('Upload error', err)
      }
    }
  }

  /** *******************************************************************
   *                      Rendering
   ******************************************************************* */
  const uploader = (
    <Upload
      {...props}
      accept={accept}
      className={`imageUploader ${loadedFile ? 'hasFile ' : ''}${props.className || ''}`}
      customRequest={
        ({ file }) => {
          uploadFile(file)
          return { abort: () => null }
        }
      }
      fileList={loadedFile ? [{ ...loadedFile }] : []}
      listType='picture-card'
      onChange={({ file: { status } }) => {
        if (status === 'uploading') {
          window.notification.open({
            key: 'UploadingNotification',
            icon: <SyncOutlined spin />,
            message: <Trans>Uploading documents</Trans>,
            description: <Trans>Just a moment, your documents will be securely uploaded.</Trans>,
            duration: 0,
            className: 'uploadingNotificationSpinner'
          })
        }
      }}
      onRemove={onRemove}
    >
      <div className='ant-upload ant-upload-drag' style={{ border: 'none', padding: 0 }}>
        <p className='ant-upload-drag-icon'>
          <CloudUploadOutlined />
        </p>
        <p
          className='ant-upload-text'
        >
          {placeholder || <Trans>Please upload your image</Trans>}
        </p>
        <p className='ant-upload-hint'>{accept}</p>
      </div>
    </Upload>
  )

  if (Object(crop) === crop) {
    return (
      <ImgCrop modalTitle={<Trans>Adjust Image</Trans>} {...crop}>
        {uploader}
      </ImgCrop>
    )
  }
  return uploader
}
export default ImageUploader
