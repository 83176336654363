import React from 'react'
import { Trans } from '@lingui/macro'
import { Button, Card } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import useBrowserStorage from '../../hooks/useBrowserStorage'

import './DiscoverBoxStyles.scss'
import { ConfirmButton } from '../SharedComponents/FormComponents/index'
import ButtonLink from '../SharedComponents/ButtonLink'

const buttonProps = {
  ghost: true, size: 'small', type: 'primary', className: 'noBorder'
}
const DiscoverBox = () => {
  const [showDiscover, setShowDiscover] = useBrowserStorage('discover', true, window.localStorage)

  const legal = useBrowserStorage('legal', true, window.localStorage)
  const sales = useBrowserStorage('sales', true, window.localStorage)
  const sign = useBrowserStorage('sign', true, window.localStorage)
  const style = useBrowserStorage('style', true, window.localStorage)
  const team = useBrowserStorage('team', true, window.localStorage)
  const automate = useBrowserStorage('automate', true, window.localStorage)

  const all = [legal, sales, sign, style, team, automate]
  window.resetDiscoverBox = () => {
    setShowDiscover(true)
    all.forEach(([, set]) => set(true))
  }

  if (!showDiscover) {
    return null
  }

  const openModal = (text, videoLink, set) => {
    set(false)

    const modal = window.modal.info({
      className: 'mediumModal discoverTopLegalModal',
      content: (
        <div className='vimeoVideo'>
          <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              allow='autoplay; fullscreen' allowFullScreen frameBorder='0' src={videoLink}
              style={{
                position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'
              }}
            />
          </div>
        </div>
      ),
      onCancel: () => modal.destroy(),
      maskClosable: true
    })
  }

  const genButton = (text, videoLink, state) => {
    if (state[0]) {
      return (
        <Button {...buttonProps} onClick={() => openModal(text, videoLink, state[1])}>
          {text}
        </Button>
      )
    }
    return null
  }

  return (
    <Card
      className='discoverCard'
      extra={(
        <ConfirmButton
          {...buttonProps}
          confirmMessage={<Trans>Are you sure you don’t want to look at this box anymore?</Trans>}
          onClick={() => setShowDiscover(false)}
          placement='left'
        >
          <Trans>Close</Trans>
        </ConfirmButton>
      )}
      title={(
        <div className='titleWrapper'>
          {
            //  TODO: Move to whitelabel version instead of HardCoded one for transferinitiative
            window.IS_TRANSFERINITIATIVE ? (
              <h2><Trans>Intro-Videos to top.legal</Trans></h2>
            ) : (
              <h2><Trans>Discover top.legal</Trans></h2>
            )
          }
          <p><Trans>Your first steps to better contracts</Trans></p>
        </div>
      )}
    >
      <div className='actionsList'>
        {genButton(
          <Trans>How playbooks empower your legal team</Trans>,
          'https://player.vimeo.com/video/480976620',
          legal
        )}
        {genButton(
          <Trans>How playbooks accelerate your sales team</Trans>,
          'https://player.vimeo.com/video/480983378',
          sales
        )}
        {genButton(
          <Trans>Test-Sign your first contract</Trans>,
          'https://player.vimeo.com/video/481342011',
          sign
        )}
        {genButton(
          <Trans>Style your playbooks in one place</Trans>,
          'https://player.vimeo.com/video/480876569',
          style
        )}
        {genButton(
          <Trans>Invite your team members</Trans>,
          'https://player.vimeo.com/video/480973949',
          team
        )}
        {genButton(
          <Trans>Get help to automate your contracts</Trans>,
          'https://player.vimeo.com/video/481441558',
          automate
        )}
        {!all.find(([val]) => val) && (
          <div className='wellDone'>
            <CheckOutlined />
            <p><Trans>Well done! Contact now Customer Success in order to implement your use-cases.</Trans></p>
            <ButtonLink
              ghost
              href='https://meetings.hubspot.com/bernhard-stippig'
              noRouter
              rel='noreferrer'
              size='small'
              target='_blank'
              type='default'
            >
              <Trans>Contact Customer Success</Trans>
            </ButtonLink>
          </div>
        )}
      </div>
    </Card>
  )
}

export default DiscoverBox
