import React, { useCallback, useContext, useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { Alert } from 'antd'
import Icon, { AuditOutlined } from '@ant-design/icons'
import RestService from '../../RestService'

//  Contexts
import { defaultUser, UserAndCompanyDataContext } from '../Layouts/Constants'
import { ExternalContext, NO_TOKEN_DATA } from './ExternalContext'

//  Components
import MainContainer, { MainContainerProps } from '../Layouts/MainLayout/MainContainer'
import { LinksGenerator, logoutLinkFactory } from '../Layouts/MainLayout/NavSidebar/NavSidebar'
import { LangSwitchButtons } from '../LoginRegistration/LoginRegistrationPage'

//  Styles
import './ExternalMainContainerStyles.scss'
import TLogo from '../../img/top-legal-t.svg'


/** ******************************************************************
 *              Guest Contract Portal Links
 ****************************************************************** */
const ROOT = 'contract-portal/'
const PUBLIC_APP_LINKS: LinksGenerator = () => [
]

const PUBLIC_USER_LINKS: LinksGenerator = () => [
  /* {
    title: {
      de: 'Anmelden / Registrieren',
      en: 'Login / Register'
    },
    path: `${ROOT}login`,
    icon: <LoginOutlined />
  } */
]


/** ******************************************************************
 *              Guest Contract Portal Links
 ****************************************************************** */
const TIconComponent = () => <img alt='T' className='topLegalAboutUs' src={TLogo} />
const TIcon = props => <Icon {...props} component={TIconComponent} />
const PORTAL_APP_LINKS: LinksGenerator = () => [
  {
    title: {
      de: 'Meine Verträge',
      en: 'My contracts'
    },
    path: `${ROOT}contracts`,
    icon: <AuditOutlined />
  }
  // {
  //   title: {
  //     de: 'Über top.legal',
  //     en: 'About top.legal'
  //   },
  //   path: `${ROOT}top.legal`,
  //   icon: <TIcon />
  // }
]

const PORTAL_USER_LINKS: LinksGenerator = props => [
  logoutLinkFactory(props)
]


export const ExternalLangSwitchButtons = () => {
  const { tokenData } = useContext(ExternalContext)

  const onClick = useCallback(async lang => {
    await RestService('PUT', `/token/${tokenData?.token}/updateData`, { lang }, false)
  }, [tokenData?.token])

  return <LangSwitchButtons onClick={onClick as any} />
}


const ExternalMainContainer: React.FC<MainContainerProps> = ({ children, ...props }) => {
  const { user } = useContext(UserAndCompanyDataContext)
  const { tokenData } = useContext(ExternalContext)

  const links = useMemo(() => {
    if (user.userID === defaultUser.userID) {
      return { appLinks: PUBLIC_APP_LINKS, userLinks: PUBLIC_USER_LINKS }
    }
    return { appLinks: PORTAL_APP_LINKS, userLinks: PORTAL_USER_LINKS }
  }, [user.userID])

  /** ******************************************************************
   *              Rendering with all the contexts
   ****************************************************************** */
  return (
    <div className='externalMainContainer'>
      <MainContainer {...props} links={links} publicMode>
        {
          tokenData === NO_TOKEN_DATA ? (
            <Alert
              description={(
                <Trans>
                  Sorry the invite token is either invalid or expired.
                  Please contact the person that invited you or the top.legal support at <a
                    href='mailto:suppor@top.legal'
                    // eslint-disable-next-line
                  >suppor@top.legal</a>
                </Trans>
              )}
              message={<Trans>No invite data found!</Trans>}
              showIcon
              style={{ margin: '3rem' }}
              type='error'
            />
          ) : children
        }
      </MainContainer>
    </div>
  )
}

export default ExternalMainContainer
