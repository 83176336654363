import React, { useContext, useEffect, useLayoutEffect, useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { Alert } from 'antd'

//  Context
import { StringParam, useQueryParam } from 'use-query-params'
import { ContractContext, TemplateContext } from './ContractEditor/Contexts'
import { SidebarToolboxes } from './ContractEditor/Sidebar/Sidebar'

//  Sidebar toolboxes
import assistantToolbox from './ContractEditor/Sidebar/SidebarToolboxes/QuestionsWizard/AssistantToolbox'
import CommentsToolbox from './ContractEditor/Sidebar/SidebarToolboxes/Commenting/CommentsToolbox'
import UserManagementToolbox from './ContractEditor/Sidebar/SidebarToolboxes/UserManagement/ContractUsersManagementToolbox'
import useActivityToolbox from './ContractEditor/Sidebar/SidebarToolboxes/ActivityToolbox'
import ChatToolbox from './ContractEditor/Sidebar/SidebarToolboxes/ChatToolbox'
import ApprovalToolbox from './ContractEditor/Sidebar/SidebarToolboxes/ApprovalBeforeSigning'
import ParametrisationToolbox from './ContractEditor/Sidebar/SidebarToolboxes/ParametrisationToolbox'

//  Custom components
import ProvideContractSuggestionSaving
  from './ContractEditor/editorRefactoredPart/sectionComponents/ProvideContractSuggestionSaving'
import ContractEditorComponent from './ContractEditor/ContractEditorComponent'
import TableOfContent from './ContractEditor/TableOfContent/TableOfContent'
import ContractLayout from './ContractDiplay/ContractLayout'
import ContractSummary from './ContractEditor/ContractSummary'
import ContractView from './ContractView'
import { PdfContract } from './ContractEditor/ContractAdditionalDocuments'

//  Helpers
import useContractFillingHelpers from './ContractFillingHelpers'
import useContractProgressHelper from './ContractProgressHelper'


const SignedPdfContract: React.FC = () => {
  const { contract } = useContext<any>(ContractContext)

  useLayoutEffect(() => {
    document.body.classList.add('noBottomMarginContract')
    return () => document.body.classList.remove('noBottomMarginContract')
  }, [])

  return (
    <div className='contractEditorComponent contractDocument'>
      <div className='sectionText' style={{ background: 'transparent', position: 'relative' }}>
        <ContractSummary />
        <PdfContract url={contract.signaturesHolder.pdf} />
      </div>
    </div>
  )
}


const ContractPageInner: React.FC = () => {
  const { setReadOnly } = useContext<any>(TemplateContext)
  const { contract, previewMode } = useContext<any>(ContractContext)
  const { contractID, contractName, contractStatus, sentOverDate, signaturesHolder, fileUrl } = contract

  //  Approval status
  const { approvals, neededApprovals = 0, pdf } = signaturesHolder || {}
  const needApproval = Object(approvals) === approvals && Object.keys(approvals).length < neededApprovals

  //  Take care of setting up contexes
  useLayoutEffect(() => setReadOnly(true), [setReadOnly])

  const [caOnly] = useQueryParam('caOnly', StringParam)
  const [keyTermsAndCommentsOnly] = useQueryParam('termsOnly', StringParam)

  //  Create out toolboxes
  const commentsToolbox = useMemo(() => CommentsToolbox('contract', contractID), [contractID])
  if (pdf) { commentsToolbox.fullSize = false } //  On pdf we do not have section commenting but document
  const userManagementToolbox = useMemo(() => UserManagementToolbox(contractID), [contractID])
  const chatToolbox = useMemo(() => ChatToolbox(contractID), [contractID])
  const activityToolbox = useActivityToolbox('contract', contractID, contractName)

  //  Combine toolboxes
  const toolboxes = useMemo(() => {
    const boxes: any[] = []
    if (fileUrl) {
      boxes.push(ParametrisationToolbox)
      if (keyTermsAndCommentsOnly) {
        boxes.push(commentsToolbox)
        return boxes
      }
    } else if (contractStatus === 'filling') {
      boxes.push(assistantToolbox)
    }
    if (!previewMode) {
      if (contractID) {
        if (needApproval) {
          boxes.push(ApprovalToolbox)
        }

        //  Regular toolboxes
        boxes.push(userManagementToolbox, commentsToolbox, activityToolbox)

        if (sentOverDate) {
          boxes.push(chatToolbox)
        }
      }
    }
    return boxes
  }, [
    contractID, previewMode, sentOverDate, contractStatus, needApproval, fileUrl,
    activityToolbox, chatToolbox, commentsToolbox, userManagementToolbox,
    keyTermsAndCommentsOnly
  ])


  //  Contract filling helpers
  useContractFillingHelpers()
  const progress = useContractProgressHelper()

  //  Deleted contract
  if (contract.deleted) {
    return (
      <Alert
        description={<Trans>Sorry you can't work with this deleted contract</Trans>}
        message={<Trans>Deleted contract</Trans>}
        showIcon
        style={{ margin: '2rem' }}
        type='error'
      />
    )
  }

  //  Get the right content
  let content = (
    <ProvideContractSuggestionSaving>
      <ContractLayout LeftSidebar={TableOfContent}>
        <ContractEditorComponent />
      </ContractLayout>
    </ProvideContractSuggestionSaving>
  )

  //  Signed contract
  if (pdf) {
    content = <ContractLayout><SignedPdfContract /></ContractLayout>
  } else if (fileUrl) {
    content = <ContractLayout><ContractView /></ContractLayout>
  }

  return (
    <SidebarToolboxes.Provider
      value={{
        defaultKey: (toolboxes[0] || {}).key,
        defaultOpened: true,
        toolboxes,
        ...progress
      }}
    >
      {content}
      {/* Remove overhead for ca only mode when after drafting */}
      {contract?.contractID && caOnly && contract.draftedDate && (
        <style>
          {`
            .toolboxSidebar, .contractTopbarContainer, .editorToolbar, .editorSearchBar {
              display: none !important;
            }
            * {
              pointer-events: none !important;
              user-select: none !important;
            }
          `}
        </style>
      )}
    </SidebarToolboxes.Provider>
  )
}

export default ContractPageInner
