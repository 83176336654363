import React from 'react'
import { Trans } from '@lingui/macro'
import { PhoneOutlined } from '@ant-design/icons'
import loadingModalFeedback from '../Alert/LoadingModalFeedback'
import { freezeContract, saveContract, signContractExtension } from './redux/ContractActions'
import ButtonLink from '../SharedComponents/ButtonLink'
import {noop} from "../Defaults";

const ContractPageContext = React.createContext({ setShowSettings: noop })
export default ContractPageContext


export const noAccountModal = () => {
  const modal = window.modal.info({
    className: 'smallModal noTokenRegistrationModal',
    title: <h1><Trans>Want to do more?</Trans></h1>,
    content: (
      <div className='noTokenRegistrationModalContent'>
        <p><Trans>It looks like you are interresting to do more with top.legal.</Trans></p>
        <p><Trans>Book a free call with our sales team and discover how to unlock the value of your contracts.</Trans></p>
        <div className='buttonsWrapper'>
          <ButtonLink
            href='https://meetings.hubspot.com/alexander-baron/15-minuten-vorstellung'
            icon={<PhoneOutlined />}
            noRouter
            onClick={() => modal.destroy()}
            rel='noreferrer'
            target='_blank'
            type='primary'
          >
            <Trans>Book Demo</Trans>
          </ButtonLink>
        </div>
      </div>
    )
  })
}


export const freezeContractAction = (sectionsCollection, promise = undefined) => (dispatch, getState) => loadingModalFeedback({
  loadingTitle: <Trans>Freezing your contract</Trans>,
  loadingDescription: <Trans>One moment please. We are freezing your contract, so it cannot be modified.</Trans>,

  successTitle: <Trans>Contract frozen</Trans>,
  successDescription: <Trans>The contract is ready to sign</Trans>,

  errorTitle: <Trans>Contract freezing error</Trans>,
  errorDescription: <Trans>An unexpected error occurred while we were trying to seal the contract. Please try again later.</Trans>,

  autoSuccessClose: 2000
})(async () => {
  promise && await promise
  !getState().contract.contractEditing.contractID && await dispatch(saveContract({}, true))
  await dispatch(freezeContract(sectionsCollection))
})


export const unFreezeContractAction = (sectionsCollection, promise = undefined) => dispatch => loadingModalFeedback({
  loadingTitle: <Trans>UnFreezing your contract</Trans>,
  loadingDescription: <Trans>One moment please. We are reverting your contract to editing stage.</Trans>,

  successTitle: <Trans>Contract unfrozen</Trans>,
  successDescription: <Trans>You can now edit and negotiate your contract.</Trans>,

  errorTitle: <Trans>Contract unfreezing error</Trans>,
  errorDescription: <Trans>An unexpected error occurred while we were trying to unfreeze the contract. Please try again later.</Trans>,

  autoSuccessClose: 2000
})(async () => {
  promise && await promise
  await dispatch(freezeContract(sectionsCollection, true))
})


export const prepareContractLoadingModal = promise => {
  if (promise) {
    return loadingModalFeedback({
      loadingTitle: <Trans>Preparing your contract</Trans>,
      loadingDescription: <Trans>One moment please. We are preparing your contract for future negotiation.</Trans>,

      successTitle: <Trans>Contract prepared</Trans>,
      successDescription: <Trans>You can now continue and start negotiating your contract.</Trans>,

      errorTitle: <Trans>Contract preparation error</Trans>,
      errorDescription: <Trans>An unexpected error occurred while preparing the contract for negotiation. Please try again later.</Trans>,

      autoSuccessClose: 2000
    })(() => promise)
  }
  return Promise.resolve()
}

export const signContractExtensionAction = extensionID => (dispatch, getState) => loadingModalFeedback({
  loadingTitle: <Trans>Preparing your contract</Trans>,
  loadingDescription: <Trans>One moment please. We are preparing your contract.</Trans>,

  successTitle: <Trans>Check your email please</Trans>,
  successDescription: <Trans>You will receive an email for signature in 30 seconds, check your email please</Trans>,

  errorTitle: <Trans>Contract preparation error</Trans>,
  errorDescription: <Trans>An unexpected error occurred while preparing the contract for signature. Please try again later.</Trans>,

  autoSuccessClose: 10000
})(async () => {
  !getState().contract.contractEditing.contractID && await dispatch(saveContract({}, true))
  await dispatch(signContractExtension(extensionID))
})
