import React, { useContext, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { Button, Dropdown, Menu } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'

import { UserAndCompanyDataContext } from '../../../../../Layouts/Constants'
import { TemplateContext } from '../../../Contexts'

import { CommentDisplayProps, CommentsToolboxConfigContext, FeedbackComment } from './Types'
import { PartCommentContext } from './PartComment'
import { CommentTitleDisplay, FormatText, useAutoScrollToComment } from './CommentDisplay'
import Thread from './Thread'
import DisplayField from '../TemplateEntitiesToolbox/DisplayField'
import { saveComment } from '../../../../../Organisations/redux/OrganisationsActions'
import { ConfirmMenuItem } from '../../../../../SharedComponents/FormComponents'

const config = { readOnly: true }

const FeedbackDisplay: React.FC<CommentDisplayProps & { withNewTabLink?: boolean }> = ({
  node,
  noThread,
  onClick
}) => {
  const { entityID } = useParams()
  const { template: { templateID, fields } } = useContext<any>(TemplateContext)
  const { user: { userID } } = useContext(UserAndCompanyDataContext)

  const feedback = node.comment as FeedbackComment
  const { comments } = node
  const nbComments = comments ? Object.keys(comments).length : 0
  const [threadOpen, setThreadOpen] = useState(false)

  const { scrollElem, flashElem, getSectionElem } = useContext(PartCommentContext)
  const elemRef = useRef<HTMLDivElement>(null)

  const reelClick = useMemo(() => onClick || (() => {
    let section = getSectionElem()
    section = (section && section.querySelector('.sectionText')) || section
    if (noThread) {
      scrollElem(section)
    }
    scrollElem(elemRef.current)
    flashElem(elemRef.current)
    flashElem(section)
  }), [onClick, getSectionElem, noThread, scrollElem, flashElem])


  const { part } = useContext(PartCommentContext)
  useAutoScrollToComment(part.sectionID, feedback, reelClick, setThreadOpen)


  const classes = ['comment', 'feedback']
  if (feedback.userID === userID) {
    classes.push('myComment')
  }
  if (feedback.done) {
    classes.push('done')
  }

  const dispatch = useDispatch()
  const [buttonLoading, setButtonLoading] = useState(false)

  return (
    <CommentsToolboxConfigContext.Provider value={config}>
      <div ref={elemRef} className={classes.join(' ')} onClick={reelClick}>
        {(!noThread || nbComments > 0) && feedback.fieldID && (
          <div className='feedbackHeader' onClick={evt => evt.stopPropagation()}>
            <DisplayField field={fields[feedback.fieldID]} fieldKey={feedback.fieldID} noActions={entityID !== templateID} />
          </div>
        )}
        <CommentTitleDisplay comment={feedback}>
          {!(feedback as any).inHistory && (
            <div className='actions' onClick={evt => evt.stopPropagation()}>
              <Dropdown
                disabled={buttonLoading}
                overlay={(
                  <Menu>
                    <ConfirmMenuItem
                      confirmMessage={<Trans>Are you sure you want to archive this feedback?</Trans>}
                      danger
                      onClick={async () => {
                        setButtonLoading(true)
                        try {
                          feedback.done = true
                          await dispatch(saveComment(feedback))
                        } catch (err) {
                          console.error('Failed to delete feedback', feedback, err)
                        }
                        setButtonLoading(false)
                      }}
                    >
                      <span><Trans>Archive</Trans></span>
                    </ConfirmMenuItem>
                  </Menu>
                )}
                placement='bottomRight'
                trigger={['click']}
              >
                <Button
                  className='noBorder moreButton'
                  ghost
                  icon={<MoreOutlined />}
                  loading={buttonLoading}
                  size='small'
                />
              </Dropdown>
            </div>
          )}
        </CommentTitleDisplay>

        <p><FormatText text={feedback.text} /></p>

        {!noThread && nbComments > 0 && (
          <>
            <Button
              //  eslint-disable-next-line no-script-url
              href='javascript:void(0)'
              onClick={evt => { evt.stopPropagation(); setThreadOpen(true) }}
              size='small'
              type='link'
            >
              <Trans>{nbComments + 1} feedbacks</Trans>
            </Button>
            <Thread
              className='feedbackThread'
              close={() => {
                setThreadOpen(false)
                flashElem(elemRef.current)
              }}
              node={node}
              open={threadOpen}
            />
          </>
        )}
      </div>
    </CommentsToolboxConfigContext.Provider>
  )
}

export default FeedbackDisplay
