import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Alert, Button, Drawer, Dropdown, Menu, Steps } from 'antd'
import { Trans } from '@lingui/macro'
import {
  CheckOutlined,
  CommentOutlined,
  EditOutlined,
  FilePdfOutlined,
  LeftOutlined,
  MenuOutlined,
  MessageOutlined,
  MoreOutlined,
  StrikethroughOutlined
} from '@ant-design/icons'
import moment from 'moment'

import { Delta } from '@top-legal/editor'

//  Contexts
import { ContractContext, TemplateContext } from '../ContractEditor/Contexts'
import { PhoneSidebarContext } from '../ContractEditor/Sidebar/PhoneSidebar'
import { PhoneCommentsDrawer } from '../ContractEditor/Sidebar/SidebarToolboxes/Commenting/PhoneCommentsToolbox'

import ContractEditorSectionContext, { ContractEditorSectionContextProps } from '../ContractEditor/editorRefactoredPart/sectionComponents/ContractEditorSectionContext'

//  Components
import MainContainer from '../../Layouts/MainLayout/MainContainer'
import { GlobalLoadingAlert } from '../../Alert/LoadingAlert'
import ContractLayout from '../ContractDiplay/ContractLayout'
import { SidebarToolboxes } from '../ContractEditor/Sidebar/Sidebar'
import { DummySidebar } from '../../ExternalViews/ExternalPageSigning'
import ContractEditorComponent from '../ContractEditor/ContractEditorComponent'
import ButtonLink from '../../SharedComponents/ButtonLink'
import PartComment from '../ContractEditor/Sidebar/SidebarToolboxes/Commenting/PartComment'

import {
  CommentsToolboxConfig,
  CommentsToolboxConfigContext,
  CommentsToolboxProps,
  CommentTree,
  CommentTreePartNode,
  StoreComment
} from '../ContractEditor/Sidebar/SidebarToolboxes/Commenting/Types'
import {
  CommentsPadderWrapper,
  useConstructCommentTrees,
  useGetComments
} from '../ContractEditor/Sidebar/SidebarToolboxes/Commenting/CommentsToolbox'

import { parrallelToSequentialFactory } from '../../Template/_TemplateHelperFunctions'
import { publishContract } from '../redux/ContractActions'
import loadingModalFeedback from '../../Alert/LoadingModalFeedback'

//  Styles
import './ContractPublishPageStyles.scss'
import { DeviceContext } from '../../../GlobalContext'
import { emptyObj, noop } from '../../Defaults'
import { useComputeSectionMapping } from '../Utils'


interface PublishModalContextProps {
  partIDsOrder: string[]
  reelComments: StoreComment[]
  activePartsComments: CommentTree
  historyPartsComments: CommentTree
  editing?: boolean // For external views used only (at least for now)
  now: string
  close: () => void
  step: number
  setStep: (step: number | ((num: number) => number)) => void
}

export const PublishModalContextDefaultValue = {}
export const PublishModalContext = React.createContext<PublishModalContextProps>({
  partIDsOrder: [],
  reelComments: [],
  activePartsComments: PublishModalContextDefaultValue,
  historyPartsComments: PublishModalContextDefaultValue,
  close: noop,
  setStep: noop,
  step: 1,
  now: ''
})

const PublishButton = () => {
  const { contract } = useContext<any>(ContractContext)
  const { now, close } = useContext(PublishModalContext)

  const dispatch = useDispatch()
  const publish = useCallback(
    () => loadingModalFeedback({
      loadingTitle: <Trans>Publishing your new version</Trans>,

      successTitle: <Trans>Version published</Trans>,
      successDescription: <Trans>The version {moment(now).calendar()} is now available for your counterparty.</Trans>,

      errorTitle: <Trans>Error during the publishment of your version</Trans>,
      errorDescription: <Trans>We got an unexpected error while publishing this version. Please try again later.</Trans>,

      autoSuccessClose: 2000,
      onSuccessClose: () => {
        if (!contract.sentOverDate) {
          setTimeout(
            () => (window as any).notification.info({
              message: <Trans>Invite counterparty</Trans>
            }),
            1000
          )
        }
        close()
      }
    })(() => dispatch(publishContract(now))),
    [now, contract.sentOverDate, close, dispatch]
  )

  return (
    <Button block className='ant-btn-external' onClick={publish}>
      <Trans>Send to external</Trans>
    </Button>
  )
}

const usePublishSetting = (): [React.ReactNode, React.ReactNode] => {
  const { setStep } = useContext(PublishModalContext)
  const { contract, updateContract } = useContext<any>(ContractContext)
  const [negotiationSetting, setNegotiationSetting] = useState(contract.publicAccessType || 'signing')

  const disablePdfMode = !!contract.firstRedlineDate
  return [
    <div key='publishSettingsChoices' className='accessTypeList'>
      <div
        className={`accessTypeOption ${negotiationSetting === 'signing' ? 'active' : ''} ${disablePdfMode ? 'disabled' : ''}`}
        onClick={disablePdfMode ? undefined : () => setNegotiationSetting('signing')}
        role='button'
      >
        <h2>
          <FilePdfOutlined /> PDF{' '}
          {disablePdfMode && (
            <span className='disabledText'>
              <Trans>Disabled due to redlining</Trans>
            </span>
          )}
        </h2>
        <p>
          <Trans>External users see the contract as an uneditable .pdf.</Trans>
        </p>
        <p className='activated'>
          <Trans>Activated</Trans> <CheckOutlined />
        </p>
      </div>
      <div
        className={`accessTypeOption ${negotiationSetting === 'commenting' ? 'active' : ''}`}
        onClick={() => setNegotiationSetting('commenting')}
        role='button'
      >
        <h2>
          <CommentOutlined /> <Trans>Commenting</Trans>
        </h2>
        <p>
          <Trans>External users see the editor and can comment on each section.</Trans>
        </p>
        <p className='activated'>
          <Trans>Activated</Trans> <CheckOutlined />
        </p>
      </div>
      <div
        className={`accessTypeOption ${negotiationSetting === 'redlining' ? 'active' : ''}`}
        onClick={() => setNegotiationSetting('redlining')}
        role='button'
      >
        <h2>
          <StrikethroughOutlined /> <Trans>Redlining</Trans>
        </h2>
        <p>
          <Trans>External users see the editor, can comment and make suggestions.</Trans>
        </p>
        <p className='activated'>
          <Trans>Activated</Trans> <CheckOutlined />
        </p>
      </div>
    </div>,
    <Button
      key='publishSettingsButton'
      block
      onClick={() => {
        setStep(old => old + 1)
        if (negotiationSetting !== contract.publicAccessType) {
          updateContract({ publicAccessType: negotiationSetting })
        }
      }}
      type='primary'
    >
      <Trans>Confirm settings</Trans>
    </Button>
  ]
}

/** **************************************************************
 *                Custom comments toolbox
 ************************************************************** */
const empty = { current: 'comments' }
const CommentsToolbox: React.FC<CommentsToolboxProps> = ({ instanceType, instanceID }) => {
  const { reelComments, partIDsOrder, activePartsComments, step, setStep, close } = useContext(PublishModalContext)
  const { contract } = useContext<any>(ContractContext)
  const [publishSetting, savePublishButton] = usePublishSetting()

  const [pageSize] = useQueryParam('pageSize', withDefault(NumberParam, 0))
  const [activePage] = useQueryParam('activePage', withDefault(NumberParam, 1))
  const chunk = useMemo(() => partIDsOrder.slice(pageSize * (activePage - 1), pageSize * activePage), [activePage, pageSize, partIDsOrder])

  return (
    <div>
      <div className='publishSteps'>
        <Steps current={step} direction='vertical' onChange={setStep}>
          <Steps.Step
            description={<Trans>Review changes before sending.</Trans>}
            title={
              <h1>
                <Trans>Verify changes</Trans>
              </h1>
            }
          />
          <Steps.Step
            description={<Trans>Chose external access rights.</Trans>}
            title={
              <h1>
                <Trans>Set negotiation settings</Trans>
              </h1>
            }
          />
          <Steps.Step
            description={<Trans>Changes visible in external view.</Trans>}
            title={
              <h1>
                <Trans>
                  Send to <span className='textExternal'>External Party</span>
                </Trans>
              </h1>
            }
          />
        </Steps>

        {step === 0 && (
          <>
            {reelComments.length === 0 && (
              <Alert
                className='noChangesInfo'
                description={<Trans>We found no changes for your external party since the last publish ({moment(contract.lastPublishDate).calendar()}).</Trans>}
                message={<Trans>No changes detected</Trans>}
                showIcon
                type='info'
              />
            )}
            <Button block onClick={() => setStep(old => old + 1)} type='primary'>
              <Trans>Verify and continue</Trans>
            </Button>
            <Button block ghost onClick={() => close()} type='primary'>
              <Trans>Back to editing</Trans>
            </Button>
          </>
        )}

        {step === 1 && (
          <>
            {publishSetting}
            {savePublishButton}
            <Button block ghost onClick={() => setStep(old => old - 1)} type='primary'>
              <Trans>Back to verify</Trans>
            </Button>
          </>
        )}

        {step === 2 && (
          <>
            <PublishButton />
            <Button block className='ant-btn-external' ghost onClick={() => setStep(old => old - 1)}>
              <Trans>Back to settings</Trans>
            </Button>
          </>
        )}
      </div>
      {step === 0 && (
        <CommentsPadderWrapper>
          {chunk.map(
            sectionID => activePartsComments[sectionID] && (
              <PartComment
                key={sectionID}
                currentBoxRef={empty}
                historyNode={empty as unknown as CommentTreePartNode}
                instanceID={instanceID}
                instanceType={instanceType}
                node={activePartsComments[sectionID]}
                side='external'
                switchToolbox={noop}
              />
            )
          )}
        </CommentsPadderWrapper>
      )}
    </div>
  )
}

const PhoneToolbox: React.FC<CommentsToolboxProps> = props => {
  const { reelComments, step, setStep, close } = useContext(PublishModalContext)
  const { contract } = useContext<any>(ContractContext)
  const [publishSetting, savePublishButton] = usePublishSetting()

  const drawerProps: any = {
    className: 'phoneToolboxDrawer phoneCommentToolboxDrawer',
    placement: 'bottom',
    zIndex: 1000
  }
  if (props.currentBoxRef.current === 'comments') {
    drawerProps.visible = true
  } else {
    drawerProps.style = {
      opacity: 0,
      pointerEvents: 'none'
    }
  }

  if (step === 0) {
    const buttons = (
      <Button.Group className='autoFit'>
        <Button ghost onClick={() => close()} type='primary'>
          <Trans>Back to editing</Trans>
        </Button>
        <Button onClick={() => setStep(old => old + 1)} type='primary'>
          <Trans>Verify and continue</Trans>
        </Button>
      </Button.Group>
    )

    if (reelComments.length === 0 && contract.sentOverDate) {
      return (
        <Drawer
          {...drawerProps}
          onClose={() => props.switchToolbox('none')}
          title={
            <h1>
              <Trans>Verify changes</Trans>
            </h1>
          }
        >
          <div>
            <Alert
              className='noChangesInfo'
              description={<Trans>We found no changes for your external party since the last publish ({moment(contract.lastPublishDate).calendar()}).</Trans>}
              message={<Trans>No changes detected</Trans>}
              showIcon
              type='info'
            />
            {buttons}
          </div>
        </Drawer>
      )
    }

    return (
      <PhoneCommentsDrawer {...props} defaultComment={0} drawerProps={drawerProps}>
        {buttons}
      </PhoneCommentsDrawer>
    )
  }

  if (step === 1) {
    return (
      <Drawer
        {...drawerProps}
        onClose={() => props.switchToolbox('none')}
        title={
          <h1>
            <Trans>Set negotiation settings</Trans>
          </h1>
        }
      >
        <div>
          {publishSetting}
          <Button.Group className='autoFit'>
            <Button ghost onClick={() => setStep(old => old - 1)} type='primary'>
              <Trans>Back to verify</Trans>
            </Button>
            {savePublishButton}
          </Button.Group>
        </div>
      </Drawer>
    )
  }

  if (step === 2) {
    return (
      <Drawer
        {...drawerProps}
        onClose={() => props.switchToolbox('none')}
        title={
          <h1>
            <Trans>
              Send to <span className='textExternal'>External Party</span>
            </Trans>
          </h1>
        }
      >
        <div>
          <Alert
            className='noChangesInfo'
            description={<Trans>Ready to publish the version ({moment(contract.lastPublishDate).calendar()}) to your counterparty?</Trans>}
            message={<Trans>New version</Trans>}
            showIcon
            type='info'
          />
          <Button.Group className='autoFit'>
            <Button block className='ant-btn-external' ghost onClick={() => setStep(old => old - 1)}>
              <Trans>Back to settings</Trans>
            </Button>
            <PublishButton />
          </Button.Group>
        </div>
      </Drawer>
    )
  }

  return null
}

const commentsToolboxInstance = {
  key: 'publishBox',
  className: 'commentsToolbox',
  text: <Trans>Publish to external</Trans>,
  icon: <MessageOutlined />,
  Component: CommentsToolbox,
  PhoneComponent: PhoneToolbox
}

const commentToolboxConfig: CommentsToolboxConfig = {
  readOnly: true,
  noLink: true
}


const getRedlineContext: ContractEditorSectionContextProps = {
  useContractEditorActions: (_, __, section, ___, setInitialDeltas) => {
    //  Initial deltas part
    const { sectionDeltasMapping } = useContext<any>(TemplateContext)
    const currentDeltas = sectionDeltasMapping[section.sectionID]
    const prevDeltas = useRef<Delta>()
    const changeTimeoutRef = useRef<NodeJS.Timeout>()


    //  Change the current deltas of the editor if we need it
    useMemo(() => {
      const changeDeltas = () => {
        changeTimeoutRef.current = undefined

        if (currentDeltas) {
          const { delta } = currentDeltas
          if (prevDeltas.current?.patch !== delta.patch) {
            prevDeltas.current = delta
            setInitialDeltas(delta)
          }
        } else if (prevDeltas.current) {
          prevDeltas.current = undefined
          setInitialDeltas(undefined)
        }
      }

      //  Debounce the set of new deltas since a re-rendering can throw multiple set deltas
      if (changeTimeoutRef.current) {
        clearTimeout(changeTimeoutRef.current)
      }
      changeTimeoutRef.current = setTimeout(changeDeltas, 1000)
    }, [currentDeltas, setInitialDeltas])

    return emptyObj as any
  }
}


const ContractPublishPage: React.FC = () => {
  const { url } = useRouteMatch()
  const history = useHistory()
  const isPhone = useContext(DeviceContext) === 'phone'
  const close = useCallback(() => history.push(`${url.replace(/\/publish$/, '')}${window.location.search}`), [history, url])

  const templateContext = useContext<any>(TemplateContext)
  const { contract } = useContext<any>(ContractContext)
  const now = useMemo<string>(() => new Date().toISOString(), [])
  const [step, setStep] = useState(0)

  useComputeSectionMapping()

  //  Transform the setSectionDeltasMapping into sequential (thread proof)
  const [sectionDeltasMapping, setSectionDeltasMapping] = useState({})
  const parrallelToSequential = useMemo(parrallelToSequentialFactory, [])
  const newSetSectionDeltasMapping = useCallback(
    data => {
      parrallelToSequential(() => {
        setSectionDeltasMapping(old => {
          if (typeof data === 'function') {
            return data(old)
          }
          return data
        })
      })
    },
    [parrallelToSequential]
  )

  const newTemplateContext = useMemo(
    () => ({
      ...templateContext,
      sectionDeltasMapping,
      setSectionDeltasMapping: newSetSectionDeltasMapping,
      readOnly: true
    }),
    [newSetSectionDeltasMapping, sectionDeltasMapping, templateContext]
  )

  /** **************************************************************
   *                Compute comments & redlines
   ************************************************************** */
  //  Get the comments
  const [storeComms, loading, error] = useGetComments(contract.contractID, 'contract')

  const { lastPublishDate } = contract
  const reelComments = useMemo<StoreComment[]>(() => {
    if (Object(storeComms) === storeComms) {
      const filtered: StoreComment[] = []

      Object.values<StoreComment>(storeComms).forEach(comm => {
        if (comm.childID) {
          if (comm.organisationID === '__EVERYONE__' && comm.lastUpdated > lastPublishDate && !(comm as any).fromExternal) {
            filtered.push(comm)
          }
        }
      })

      return filtered
    }
    return []
  }, [lastPublishDate, storeComms])

  const [activePartsComments, historyPartsComments, , partIDsOrder] = useConstructCommentTrees(reelComments, false)
  const toolboxes = useMemo(() => [commentsToolboxInstance], [])
  const openSidebarRef = useRef<any>()

  /**
   * Return loading and error views
   */
  if (error) {
    return <Alert message={<Trans>Failed to load your comments</Trans>} showIcon type='error' />
  }

  const PhoneButtons = () => (
    <ButtonLink href={url.replace(/\/publish$/, '')} icon={<EditOutlined />}>
      <span>
        <Trans>Back to contract</Trans>
      </span>
    </ButtonLink>
  )

  return (
    <PhoneSidebarContext.Provider value={{ ActionsButtons: PhoneButtons, openSidebarRef }}>
      <GlobalLoadingAlert description={<Trans>Loading your comments</Trans>} loading={loading}>
        <MainContainer
          mainContentClass='contractPageWrapper contractPublishPage'
          topbarContent={
            <>
              <div className='topbarMainContent'>
                <ButtonLink ghost href={url.replace(/\/publish$/, '')} icon={<LeftOutlined />} shape='circle' />
                <h1 className='title'>{isPhone ? <Trans>Publish</Trans> : contract.contractName}</h1>
              </div>
              <div className='topbarActions'>
                {isPhone ? (
                  <Button className='noBorder phoneSidebarDrawerButton' ghost icon={<MenuOutlined />} onClick={() => openSidebarRef.current('comments')} />
                ) : (
                  <Dropdown
                    overlay={
                      <Menu key='menu'>
                        <Menu.Item>
                          <Link to={url.replace(/\/publish$/, '')}>
                            <EditOutlined />
                            <span>
                              <Trans>Back to contract</Trans>
                            </span>
                          </Link>
                        </Menu.Item>
                      </Menu>
                    }
                    placement='bottomRight'
                    trigger={['click']}
                  >
                    <Button
                      className='noBorder moreButton'
                      ghost
                      // eslint-disable-next-line no-script-url
                      href='javascript:void(0)'
                      icon={<MoreOutlined />}
                    />
                  </Dropdown>
                )}
              </div>
            </>
          }
        >
          <ContractEditorSectionContext.Provider value={getRedlineContext}>
            <TemplateContext.Provider value={newTemplateContext}>
              <CommentsToolboxConfigContext.Provider value={commentToolboxConfig}>
                <SidebarToolboxes.Provider
                  value={{
                    defaultKey: commentsToolboxInstance.key,
                    defaultOpened: true,
                    toolboxes
                  }}
                >
                  <PublishModalContext.Provider
                    value={{
                      partIDsOrder,
                      reelComments,
                      activePartsComments,
                      historyPartsComments,
                      close,
                      step,
                      setStep,
                      now
                    }}
                  >
                    <div className={`publishContractWrapper ${step === 0 ? 'withGrayDifferences' : ''}`}>
                      <ContractLayout LeftSidebar={DummySidebar} customIdPrefix='deltas-'>
                        <ContractEditorComponent />
                      </ContractLayout>
                    </div>
                  </PublishModalContext.Provider>
                </SidebarToolboxes.Provider>
              </CommentsToolboxConfigContext.Provider>
            </TemplateContext.Provider>
          </ContractEditorSectionContext.Provider>
        </MainContainer>
      </GlobalLoadingAlert>
    </PhoneSidebarContext.Provider>
  )
}

export default ContractPublishPage
