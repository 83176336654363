import React, { useContext, useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { Badge, Tag } from 'antd'
import { getdistribution } from '../statisticsUtils'
import './ContractDistributionStyles.scss'
import { contractStatusOptions } from '../../Contract/Utils'
import { DeviceContext } from '../../../GlobalContext'

const ContractDistribution = ({ data, templates }) => {
  const formattedData = useMemo(() => getdistribution(data), [data])
  const isPhone = useContext(DeviceContext) === 'phone'
  const contractStatusIdx = {
    0: 'filling', 1: 'editing', 2: 'frozen', 3: 'signed'
  }
  return (
    <div className='chart contractDistribution'>
      <div className='templateContractCounter'>
        <div className='templateCount'>
          <h1><Trans>Templates</Trans></h1>
          <h2>{templates.length}</h2>
        </div>
        <div className='contractCount'>
          <h1><Trans>Contracts</Trans></h1>
          <h2>{data.length}</h2>
        </div>
      </div>

      <div className='contractStatusDistribution'>
        {isPhone ? (
          <table className='tableDistribution'>
            <tr className='elemetTableDistribution'>
              {formattedData.data.map((element, index) => (
                <div key={index} className='itemDistribution'>
                  {contractStatusOptions[contractStatusIdx[index]] && (
                    <>
                      <td><h1>{element}</h1></td>
                      <td>
                        <Badge
                          className='statusContracDistribution'
                          color={(contractStatusOptions[contractStatusIdx[index]].color)}
                          text={contractStatusOptions[contractStatusIdx[index]].text}
                        />
                      </td>
                    </>
                  )}
                </div>
              ))}
            </tr>
          </table>
        )
          : (
            <>
              {formattedData.data.map((element, index) => (
                <>
                  {contractStatusOptions[contractStatusIdx[index]] && (
                    <div key={index} className='itemDistribution'>
                      <>
                        <h1>{element}</h1>
                        <Tag color={contractStatusOptions[contractStatusIdx[index]].color} icon={contractStatusOptions[contractStatusIdx[index]].icon}>
                          {contractStatusOptions[contractStatusIdx[index]].text}
                        </Tag>
                      </>

                    </div>
                  )}
                </>
              ))}
            </>
          )}
      </div>
    </div>
  )
}

export default {
  key: 'contractDistribution',
  title: <Trans>Contract distribution</Trans>,
  Component: ContractDistribution,
  width: 8,
  height: 2,
  mobileHeight: 1
}
