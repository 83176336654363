import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Layout } from 'antd'
import { RedirectComp } from '../../Routes'
import ExternalPageListing from './ExternalPageListing'
import AboutTopLegal from './AboutTopLegal'
import PortalLoginRegister from './PortalLoginRegister'
import EmptyHeader from '../Layouts/EmptyHeader'
import ErrorPage from '../Error/ErrorPage'
import { AuthenticatedPage } from '../Layouts/AuthenticatedPage'
import ExternalPageSwitch from './ExternalPageSwitch'

const RedirectToContracts = ({ match }) => (
  <RedirectComp to={match.url.replace(`${match.params.access}-contract`, 'contracts')} />
)

const ContractPortalRoutes: React.FC<any> = ({ match }) => (
  <AuthenticatedPage checkOnly>
    <Switch>
      {/* Contracts */}
      <Route component={ExternalPageListing} exact path={`${match.path}/contracts`} />
      <Route component={ExternalPageSwitch} exact path={`${match.path}/contracts/:tokenOrContractID`} />
      <Route component={RedirectToContracts} exact path={`${match.path}/:access(view|edit|sign)-contract/:token`} />

      {/* About top.legal */}
      <Route component={AboutTopLegal} exact path={`${match.path}/top.legal`} />

      {/* Login & Registration */}
      <Route component={PortalLoginRegister} exact path={`${match.path}/login`} />

      {/* Error page */}
      <Route
        component={() => (
          <Layout>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <EmptyHeader />
            <ErrorPage />
          </Layout>
        )}
      />
    </Switch>
  </AuthenticatedPage>
)

export default ContractPortalRoutes
