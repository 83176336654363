/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Alert, Button, Drawer, Empty } from 'antd'
import { Trans } from '@lingui/macro'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
//  Contexts
import { defaultUser, UserAndCompanyDataContext } from '../../../../../Layouts/Constants'
import { ContractContext, TemplateContext } from '../../../Contexts'
import { IdPrefixContext } from '../../../../ContractDiplay/ContractLayout'
import { PublishModalContext, PublishModalContextDefaultValue } from '../../../../ContractPublish/ContractPublishPage'
//  Components
import { RenderComments, useConstructCommentTrees, useGetComments } from './CommentsToolbox'
import { CommentsToolboxProps, CommentTreePartNode, StoreComment } from './Types'
import NewComment from './NewComment'
import { LocalLoadingAlert } from '../../../../../Alert/LoadingAlert'
import { CommentButton, PartCommentContext, PartCommentContextContent, usePartCommentElements } from './PartComment'
import PartHistory from './PartHistory'
import { noop } from '../../../../../Defaults'

const props: Partial<PartCommentContextContent> = {
  side: 'internal',
  flashElem: noop,
  getSectionElem: noop,
  scrollElem: noop,
  switchToolbox: noop
}

const PhoneCommentButton: React.FC<{ node: CommentTreePartNode, onClick: () => void }> = ({ node, onClick }) => {
  const [,, domElement] = usePartCommentElements(node)

  return domElement ? ReactDOM.createPortal(<CommentButton node={node} onClick={onClick} />, domElement) : null
}

export const PhoneCommentsDrawer: React.FC<CommentsToolboxProps & { drawerProps: any, onSectionClickRef?: React.MutableRefObject<any>, defaultComment?: number }> = ({
  currentBoxRef, switchToolbox,
  instanceID, instanceType,
  drawerProps, onSectionClickRef,
  defaultComment,
  children
}) => {
  const idPrefix = useContext(IdPrefixContext)
  const { template, sectionMapping } = useContext<any>(TemplateContext)
  const { activePartsComments, historyPartsComments } = useContext(PublishModalContext)

  const { user } = useContext<any>(UserAndCompanyDataContext)
  const isExternal = useMemo(() => user.userID === defaultUser.userID, [user.userID])

  const numbersMapping = useMemo(() => {
    const mapping: any = {}

    let num = 0
    Object.entries(activePartsComments).forEach(([sectionID, node]) => {
      mapping[sectionID] = num

      if (node.comments) {
        const sectionNum = Object.keys(node.comments).length

        if (sectionNum > 0) {
          mapping[num] = sectionID
          num += sectionNum
          mapping[num - 1] = sectionID
        }
      }
    })
    mapping.total = num

    return mapping
  }, [activePartsComments])

  const [currentSection, setCurrentSection] = useState<string | undefined>(numbersMapping[defaultComment as any])
  const [currentComment, setCurrentComment] = useState<number | undefined>(defaultComment)

  useMemo(() => {
    if (onSectionClickRef) {
      onSectionClickRef.current = sectionID => {
        setCurrentSection(sectionID)
        setCurrentComment(undefined)
        if (currentBoxRef.current !== 'comments') {
          switchToolbox('comments')
        }
      }
    }
  }, [currentBoxRef, onSectionClickRef, switchToolbox])

  useEffect(() => {
    let sectionText

    setTimeout(() => {
      const section = document.getElementById(`${idPrefix}${template.templateID}-${currentSection}`)
      if (section) {
        const title = section.querySelector('.sectionTitle')
        sectionText = section.querySelector('.sectionText')
        if (sectionText) {
          (title || sectionText).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })
          sectionText.classList.add('activeSection')
        }
      }
    }, 500)

    return () => sectionText && sectionText.classList.remove('activeSection')
  }, [currentSection, idPrefix, template.templateID])

  if (currentSection) {
    const node = activePartsComments[currentSection]
    const commentIndex = currentComment == null ? numbersMapping[currentSection] : currentComment

    const drawerTitle = (
      <>
        <Button
          className='noBorder allComments' ghost
          onClick={() => {
            setCurrentSection(undefined)
            setCurrentComment(undefined)
          }}
          type='primary'
        >
          <Trans>View all</Trans>
        </Button>
        <div className='phoneCommentsSwitcher'>
          <Button
            className='noBorder' disabled={commentIndex === 0} ghost
            icon={<LeftOutlined />}
            onClick={() => {
              const newIndex = commentIndex - 1
              if (newIndex >= 0) {
                if (numbersMapping[newIndex]) {
                  setCurrentSection(numbersMapping[newIndex])
                }
                setCurrentComment(newIndex)
              }
            }}
            type='default'
          />
          <h1><Trans>{Math.min(commentIndex + 1, numbersMapping.total)} of {numbersMapping.total}</Trans></h1>
          <Button
            className='noBorder' disabled={commentIndex === numbersMapping.total - 1} ghost
            icon={<RightOutlined />}
            onClick={() => {
              const newIndex = commentIndex + 1
              if (newIndex < numbersMapping.total) {
                if (numbersMapping[newIndex]) {
                  setCurrentSection(numbersMapping[newIndex])
                }
                setCurrentComment(newIndex)
              }
            }}
            type='default'
          />
        </div>
      </>
    )

    if (currentComment != null) {
      const key = Object.keys(node.comments || {})[currentComment - numbersMapping[currentSection]]

      return (
        <Drawer
          {...drawerProps}
          onClose={() => {
            switchToolbox('none')
            setCurrentComment(undefined)
            setCurrentSection(undefined)
          }}
          title={drawerTitle}
        >
          <div className='phonePartComments'>
            <div className='comments'>
              <PartCommentContext.Provider
                key={currentSection}
                value={{
                  ...props, instanceID, instanceType, part: node.part, currentBoxRef
                } as PartCommentContextContent}
              >
                <RenderComments node={{ comments: { [key]: (node.comments || {})[key] } }} />
              </PartCommentContext.Provider>
            </div>
          </div>
          {children}
        </Drawer>
      )
    }

    const newNumber = numbersMapping[currentSection] + Object.keys(node.comments || {}).length
    return (
      <Drawer
        {...drawerProps}
        onClose={() => {
          switchToolbox('none')
          setCurrentComment(undefined)
          setCurrentSection(undefined)
        }}
        title={drawerTitle}
      >
        <div className='phonePartComments'>
          <div className='comments'>
            <PartCommentContext.Provider
              key={currentSection}
              value={{
                ...props, instanceID, instanceType, part: node.part, currentBoxRef
              } as PartCommentContextContent}
            >
              <NewComment
                childID={node.part.sectionID}
                close={data => {
                  if (data) {
                    setCurrentComment(newNumber)
                    setCurrentSection(currentSection)
                  } else {
                    switchToolbox('none')
                    setCurrentComment(undefined)
                    setCurrentSection(undefined)
                  }
                }}
                focus
                noTask={isExternal}
              />
            </PartCommentContext.Provider>
          </div>
        </div>
        {children}
      </Drawer>
    )
  }

  //  Render all comments / all sections
  const allComments = Object.entries(activePartsComments).map(([sectionID, node]) => {
    if (!node.comments || Object.keys(node.comments).length === 0) {
      return null
    }

    const partName = sectionMapping[`${template.templateID}-${sectionID}`] || node.part.name

    const Title = () => {
      const [domElement, setDomElement] = useState<HTMLDivElement | null>(null)
      const ref = useRef<HTMLDivElement>(null)

      useLayoutEffect(() => {
        setDomElement(ref.current)
        return () => setDomElement(null)
      })

      const title = useMemo(() => (
        <div ref={ref} className='partTitle'>
          <h5>{partName}</h5>
        </div>
      ), [])

      return (
        <>
          {title}
          <PartHistory domElement={domElement} node={historyPartsComments[sectionID]} />
        </>
      )
    }

    return (
      <PartCommentContext.Provider
        key={sectionID}
        value={{
          ...props, instanceID, instanceType, part: node.part, currentBoxRef
        } as PartCommentContextContent}
      >
        <div className='phonePartComments'>
          <Title />
          <div className='comments'>
            <RenderComments
              node={node}
              onClick={(_, index) => {
                setCurrentSection(sectionID)
                setCurrentComment(numbersMapping[sectionID] + index)
              }}
            />
          </div>
        </div>
      </PartCommentContext.Provider>
    )
  }).filter(elm => elm)

  return (
    <Drawer
      {...drawerProps}
      onClose={() => switchToolbox('none')}
      title={<h1><Trans>All comments</Trans></h1>}
    >
      <div style={{ minHeight: '40vh' }}>
        <div className='phoneComments'>
          {allComments.length === 0 ? <Empty description={<Trans>No comment yet for this document.</Trans>} /> : allComments}
        </div>
      </div>
      {children}
    </Drawer>
  )
}

const PhoneCommentsToolbox: React.FC<CommentsToolboxProps> = ({
  instanceType,
  instanceID,
  switchToolbox,
  currentBoxRef
}) => {
  const { contract } = useContext<any>(ContractContext)
  const onSectionClickRef = useRef<any>()

  // eslint-disable-next-line
  let reelComments: StoreComment[] | undefined = undefined,  loading = false, error = false, isGlobalDocumment = false
  let { activePartsComments, historyPartsComments, ...publishContextRest } = useContext(PublishModalContext)

  if (activePartsComments === PublishModalContextDefaultValue) {
    [reelComments, loading, error] = useGetComments(instanceID, instanceType)
    isGlobalDocumment = (contract && (contract.pdf || contract.fileKey))
    ;[activePartsComments, historyPartsComments] = useConstructCommentTrees(reelComments, isGlobalDocumment)
  }


  const drawerProps: any = {
    className: 'phoneToolboxDrawer phoneCommentToolboxDrawer',
    placement: 'bottom',
    zIndex: 1000
  }
  if (currentBoxRef.current === 'comments') {
    drawerProps.visible = true
  } else {
    drawerProps.style = {
      opacity: 0,
      pointerEvents: 'none'
    }
  }


  let content: any = null
  if (loading || error) {
    content = (
      <Drawer
        {...drawerProps}
        onClose={() => switchToolbox('none')}
        title={<h1>Comments</h1>}
      >
        <LocalLoadingAlert lightTheme loading={loading} message={<Trans>Loading comments</Trans>}>
          <Alert
            description={<Trans>Sorry we cannot load your comments. Please try again later.</Trans>}
            message={<Trans>Cannot load comments</Trans>}
            showIcon
            type='error'
          />
        </LocalLoadingAlert>
      </Drawer>
    )
  } else {
    content = (
      <PhoneCommentsDrawer
        currentBoxRef={currentBoxRef}
        drawerProps={drawerProps}
        instanceID={instanceID}
        instanceType={instanceType}
        onSectionClickRef={onSectionClickRef}
        switchToolbox={switchToolbox}
      />
    )
  }

  return (
    <PublishModalContext.Provider
      value={{
        ...publishContextRest, activePartsComments, historyPartsComments, reelComments: reelComments || publishContextRest.reelComments
      }}
    >
      {Object.entries(activePartsComments).map(([sectionID, node]) => (
        <PhoneCommentButton
          key={sectionID}
          node={node}
          onClick={() => onSectionClickRef.current(sectionID)}
        />
      ))}
      {content}
    </PublishModalContext.Provider>
  )
}
export default PhoneCommentsToolbox
