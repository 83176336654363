import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Col, Form, Input, Row } from 'antd'
import { t, Trans } from '@lingui/macro'

import { ConsoleLogger as Logger } from '@aws-amplify/core'
import { requiredRules } from '../CustomFormWrapper'

const logger = new Logger('GoogleAutoCompleteAddress')

// ==================================================================================================
// ==================================================================================================
const GoogleAutoCompleteAddress = ({ prefix, form, initialValue, required }) => {
  // ==================================================================================================
  const searchInput = useRef(null)
  const autocomplete = useRef()

  // ==================================================================================================

  const handleMapChange = useCallback(() => {
    try {
      // Get the place details from the autocomplete object.
      const place = autocomplete.current.getPlace()
      logger.debug('place: ', place)

      if (place && place.name && place.address_components) {
        const tmp = {}
        place.address_components.forEach(obj => {
          obj.types.forEach(type => {
            tmp[type] = obj.long_name
          })
        })

        const address = {
          country: tmp.country,
          city: tmp.locality,
          postcode: tmp.postal_code + (tmp.postal_code_suffix ? (`-${tmp.postal_code_suffix}`) : ''),
          address: [tmp.route, tmp.street_number].filter(elm => elm).join(' ')
        }
        if (address.postcode === 'undefined' || '') { address.postcode = 'Please insert the zip code' }         
        form.setFieldsValue(prefix ? { [prefix]: address } : address)
      }
    } catch (error) {
      logger.error('handleMapChange', error)
    }
  }, [form, prefix])

  // ==================================================================================================

  useEffect(() => {
    if (!autocomplete.current && searchInput.current && window.google) {
      // https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      autocomplete.current = new window.google.maps.places.Autocomplete(searchInput.current, {
        types: ['address']
      })

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      autocomplete.current.addListener('place_changed', handleMapChange)
    }
  }, [handleMapChange])

  // ==================================================================================================

  const name = useMemo(() => {
    if (prefix) {
      return fieldName => [prefix, fieldName]
    }
    return fieldName => fieldName
  }, [prefix])

  const value = useMemo(() => {
    if (prefix) {
      return fieldName => (initialValue[prefix] || {})[fieldName]
    }
    return fieldName => initialValue[fieldName]
  }, [prefix, initialValue])

  const req = required ? '*' : ''

  return (
    <>
      <Form.Item className='googleSearchPlace' label={<Trans>Automatic address entry</Trans>}>
        <input
          ref={searchInput}
          className='ant-input simple-line'
          onKeyPress={event => {
            event.key === 'Enter' && event.preventDefault()
          }}
          placeholder={t`Enter your address`}
        />
      </Form.Item>
      <Form.Item
        initialValue={value('address')}
        name={name('address')}
        rules={required ? requiredRules : null}
      >
        <Input className='simple-line' placeholder={t`Street` + req} />
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            initialValue={value('postcode')}
            name={name('postcode')}
            rules={required ? requiredRules : null}
          >
            <Input className='simple-line' placeholder={t`Postcode` + req} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            initialValue={value('city')}
            name={name('city')}
            rules={required ? requiredRules : null}
          >
            <Input className='simple-line' placeholder={t`Town` + req} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        initialValue={value('country')}
        name={name('country')}
        rules={required ? requiredRules : null}
      >
        <Input className='simple-line' placeholder={t`Country` + req} />
      </Form.Item>
    </>
  )
}

export default GoogleAutoCompleteAddress
