/* eslint-disable react/display-name */
import React, { useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { Trans } from '@lingui/macro'
import { ForkOutlined } from '@ant-design/icons'

import { UserAndCompanyDataContext } from '../../../../../Layouts/Constants'
import { TemplateContext } from '../../../Contexts'
import { fillTemplateEntities } from '../../../../../Template/_TemplateHelperFunctions'
import useTemplateEntities from './useTemplateEntities'
import useTemplateEntitiesSearch from './useTemplateEntitiesSearch'
import TemplateEntitiesCategoriesDisplay from './TemplateEntitiesCategoriesDisplay'
import TemplateEntitiesGlobalSearch from './TemplateEntitiesGlobalSearch'

import './TemplateEntitiesToolbox.scss'


/**
 * This has the role of managing the entities display in the template editor
 * Can search globally or navigate through the different categories
 */
const TemplateEntitiesToolbox: React.FC<{ cssSelector?: string, inlinesOnly?: boolean }> = ({ cssSelector = 'body', inlinesOnly = false }) => {
  const { lang: pageLang } = useParams()
  const { user, company } = useContext(UserAndCompanyDataContext)
  const { template: { lang }, inputFields, conditionalTexts } = useContext<any>(TemplateContext)

  /** **************************************************************
   *                     Trees of entities
   ************************************************************** */
  const [allFields, allConds] = useMemo(() => {
    const consC = {}
    Object.entries<any>(conditionalTexts).forEach(([key, versions]) => {
      const last = Object.keys(versions).sort().pop() as string
      consC[key] = versions[last]
    })

    const fieldsC = {}
    Object.entries<any>(inputFields).forEach(([key, versions]) => {
      const last = Object.keys(versions).sort().pop() as string
      fieldsC[key] = versions[last]
    })

    const fakeTemplate = { fields: fieldsC, conditionalTexts: consC }
    fillTemplateEntities(
      fakeTemplate,
      `${user.firstName} ${user.lastName}`,
      company.name,
      undefined,
      undefined,
      pageLang
    )

    return [fakeTemplate.fields, fakeTemplate.conditionalTexts]
  }, [conditionalTexts, inputFields, user.firstName, user.lastName, company.name, pageLang])

  const [fieldCategories, condCategories] = useTemplateEntities(allFields, allConds, inlinesOnly, lang)

  /** **************************************************************
   *                     Search of entities
   ************************************************************** */
  const [openedCategory, setOpenedCategory] = useState<string>()
  const currentCategory = openedCategory ? (
    fieldCategories.find(({ type }) => type === openedCategory) || condCategories.find(({ type }) => type === openedCategory)
  ) : undefined

  const { searchBar, search, Highlight } = useTemplateEntitiesSearch(!!currentCategory)


  /** **************************************************************
   *                     Rendering
   ************************************************************** */
  return (
    <>
      {searchBar}
      <div className='templateEntitiesToolboxMainContent'>
        {search ? (
          <TemplateEntitiesGlobalSearch
            Highlight={Highlight}
            condCategories={condCategories}
            fieldCategories={fieldCategories}
            search={search}
          />
        ) : (
          <TemplateEntitiesCategoriesDisplay
            condCategories={condCategories}
            cssSelector={cssSelector}
            currentCategory={currentCategory}
            fieldCategories={fieldCategories}
            setOpenedCategory={setOpenedCategory}
          />
        )}
      </div>
    </>
  )
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  key: 'inputField',
  className: 'inputFieldToolbar',
  text: <Trans>Playbook logic</Trans>,
  icon: <ForkOutlined />,
  Component: TemplateEntitiesToolbox
}
