/* eslint-disable @typescript-eslint/no-empty-function,class-methods-use-this,no-await-in-loop */

import { ConditionalText, InputField, Section, Template } from '@top-legal/datastore'
import { EditorContent, slateToYjsDoc, computeSnapshotPatch, iterateTemplateEntities } from '@top-legal/editor'
import TemplateContentVisitor, { EntityType } from './TemplateContentVisitor'
import TemplateCloneRenameVisitor from './TemplateCloneRenameVisitor'
import templateFiller, { Replacer } from './templateFiller'
import { isSectionActive } from '../index'


class TemplateCloneFillVisitor extends TemplateCloneRenameVisitor {
  responses: any = {}

  replacement = false

  sectionContentMap: Record<string, EditorContent> = {}

  filler: Replacer = undefined as any


  renameFieldsResponses (organisationID: string, responses: any) {
    const newResponses: any = {}

    Object.entries(responses).forEach(([key, val]) => {
      if (key.startsWith('_')) {
        newResponses[key] = val
      } else {
        const newKey = `${key}-${organisationID}`
        newResponses[newKey] = val

        //  This special field has some responses with field keys
        const field = this.fieldsMap.get(newKey)
        if (field?.type === 'listOfFormattedText') {
          const response = responses[key]

          if (Array.isArray(response)) {
            newResponses[newKey] = response.map(
              item => this.renameFieldsResponses(organisationID, item)
            )
          }
        }
      }
    })

    return newResponses
  }


  //


  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async visitTemplate (
    template: Template,
    responses: Record<string, any>,
    templateID,
    organisationID
  ): Promise<void | Template> {
    this.replacement = false
    this.sectionContentMap = {}


    //  Else we need to clone first and fill after
    const newTemplate = await super.visitTemplate(template, templateID, organisationID) as any


    //  Rebind data for replacement part
    if (template.organisationID !== organisationID) {
      this.fieldsMap = { get: key => this.newData.fields[key] } as any
      this.condsMap = { get: key => this.newData.conds[key] } as any
    }
    this.sectionsMap = { get: key => this.newData.sections[key] } as any


    //  Bind responses
    if (template.organisationID === organisationID) {
      this.responses = responses
    } else {
      this.responses = this.renameFieldsResponses(organisationID, responses)
    }


    //  Fetch missing products from the response
    const response = this.responses.__products
    if (Array.isArray(response)) {
      const products = await this.getters.getFields(response.map(({ fieldKey }) => fieldKey))
      products.forEach((field, fieldID) => {
        if (template.organisationID === this.organisationID) {
          this.fieldsMap.set(fieldID, field)
        } else {
          this.newData.fields[fieldID] = field
        }
      })
    }


    //  Then replacement phase
    const { replacer, obfuscationMapping } = templateFiller({
      fieldsMap: this.fieldsMap,
      condsMap: this.condsMap,
      lang: template.lang,
      responses: this.responses
    })
    this.filler = replacer

    this.replacement = true
    this.processSections(newTemplate)


    //  Add Filling & Obfuscation
    if (newTemplate) {
      newTemplate.obfuscationMapping = obfuscationMapping
    }

    return newTemplate
  }

  //  Replace part
  visitSection (section: Section): void | Section | null {
    if (this.replacement) {
      if (!isSectionActive(section, this.fieldsMap, this.responses)) {
        delete this.newData.sections[section.sectionID]
        return null
      }

      const newSection: any = {
        sectionID: section.sectionID,
        organisationID: (section as any).organisationID,
        templateID: (section as any).templateID,
        version: 1,
        dateUpdated: section.dateUpdated
      }

      //  Add optional params if not null
      ;[
        'name', 'clauseID',
        'explanationText', 'explanationVideo',
        'explanationTextExternal', 'explanationVideoExternal',
        'yDoc'
      ].forEach(attr => {
        if (section[attr] != null) {
          newSection[attr] = section[attr]
        }
      })

      this.visitSectionYDoc(newSection, this.sectionYDocToContent(newSection))

      this.newData.sections[section.sectionID] = newSection

      return newSection
    }

    return super.visitSection(section)
  }

  //  We need to cache the content in fist phase to speed up replacement phase
  sectionYDocToContent (section: Section): EditorContent {
    const key = section.sectionID.replace(this.oldTemplateID, this.newTemplateID)
    if (!this.sectionContentMap[key]) {
      this.sectionContentMap[key] = super.sectionYDocToContent(section)
    }
    return this.sectionContentMap[key]
  }

  visitSectionYDoc (section: Section, content: EditorContent) {
    if (this.replacement) {
      iterateTemplateEntities(content, (type, key) => this.filler(type, key))

      section.yDoc = computeSnapshotPatch(slateToYjsDoc(content))
    } else {
      super.visitSectionYDoc(section, content)
    }
  }
}

export default TemplateCloneFillVisitor
