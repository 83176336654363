import { Button, Form } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { toJson, useContractingPartiesCollection, useDoc } from '@top-legal/datastore'
import { CustomForm, requiredRules } from '@top-legal/form-components'
import { GoogleFontSelector } from '@top-legal/react-helpers'

import { StickyMainContent } from '../../../Layouts/MainLayout/MainContainer'
import { LayoutSidebarWidthContext } from '../../../Layouts/LayoutSidebar'
import loadingModalFeedback from '../../../Alert/LoadingModalFeedback'
import { pinLessSigning } from '../../redux/ContractActions'
import { ContractContext } from '../Contexts'
import { UserAndCompanyDataContext } from '../../../Layouts/Constants'
import Portal from '../../../SharedComponents/Portal'
import { Signature } from './Utils/Types'
import { userFullName } from '../../../Organisations/UserRoleDisplay/UserAvatarList'
import { ScrollbarWidthContext } from '../../../../GlobalContext'
import CurrentUserCard from '../Sidebar/SidebarToolboxes/UserManagement/UserCards/CurrentUserCard'

interface SigningDrawerProps {
  signature: Signature
  visible: boolean
  close: () => void
  onFinishSigning?: () => void
}

const capitalize = (str, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase())


const SigningPinLessDrawer: React.FC<SigningDrawerProps> = ({ signature, visible, close, onFinishSigning }) => {
  const { user: internalUser } = useContext(UserAndCompanyDataContext)
  const partiesCollection = useContractingPartiesCollection()
  const [party] = useDoc(
    useMemo(() => partiesCollection.findOne(signature.partyID), [partiesCollection, signature.partyID]),
    toJson
  )
  const user = party || internalUser
  const { contract } = useContext<any>(ContractContext)

  //  Handwriting signature part
  const username = capitalize(userFullName(user), true)
  const needHandwriting = contract.signaturesHolder?.withHandwritingSignature


  //  Sign the contract pinless
  const dispatch = useDispatch()
  const sign = useCallback(({ selectedHandwriting }) => loadingModalFeedback({
    loadingTitle: <Trans>Accept and sign contract</Trans>,
    successTitle: <Trans>Contract signed</Trans>,
    errorTitle: <Trans>Cannot sign the contract</Trans>,
    autoSuccessClose: 2000
  })(async () => {
    await dispatch(pinLessSigning(signature.signatureID, selectedHandwriting))
    onFinishSigning?.()
  }), [dispatch, onFinishSigning, signature.signatureID])


  const width = useContext(LayoutSidebarWidthContext)
  const right = `${useContext(ScrollbarWidthContext)}px`

  return (
    <Portal cssSelector='body'>
      <StickyMainContent
        className={`fixedContent commentThread ${visible ? 'open' : 'close'} signingToolbox`}
        onClick={evt => evt.stopPropagation()}
        style={{ width: `calc(${width} + ${right})`, maxWidth: '28rem' }}
      >
        <div className='threadTitle'>
          <Button
            className='noBorder' ghost icon={<ArrowLeftOutlined />} onClick={close}
            shape='circle' type='default'
          />
          <h2>
            <Trans>Signing</Trans>
          </h2>
        </div>
        <div className='signaturePhoneContainer'>
          <CustomForm onSubmit={sign}>
            <CurrentUserCard user={user} />
            {needHandwriting && (
              <div className='handwritingSignature'>
                <h4><Trans>Please select your signature</Trans></h4>
                <Form.Item name='selectedHandwriting' rules={requiredRules}>
                  <GoogleFontSelector
                    className='simple-line'
                    dropdownClassName='handwritingSelectDropdown'
                    placeholder={username}
                    previewText={username}
                  />
                </Form.Item>
              </div>
            )}
            <Button
              block
              htmlType='submit'
              type='primary'
            >
              <Trans>Sign now</Trans>
            </Button>
          </CustomForm>
        </div>
      </StickyMainContent>
    </Portal>
  )
}

export default SigningPinLessDrawer
