import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { Trans } from '@lingui/macro'
import { Button, Modal } from 'antd'
import CustomFormWrapper from '../SharedComponents/CustomFormWrapper'
import loadingModalFeedback from '../Alert/LoadingModalFeedback'
import RestService from '../../RestService'
import MetadataSettings from './TemplateTabs/MetadataSettings'
import { updateTemplate } from './redux/TemplateActions'

import './styles/CreateNewPlaybookModalStyles.scss'
import { useNewSection } from '../Contract/ContractEditor/editorRefactoredPart/sectionComponents/Types'

interface CreateNewPlaybookModalProps {
  visible: boolean
  close: () => void
}

const CreateNewPlaybookModal: React.FC<CreateNewPlaybookModalProps> = ({ visible, close }) => {
  const { lang } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const templateIDRef = useRef('')

  const newSection = useNewSection()

  return (
    <Modal
      className='smallModal createNewPlaybookModal'
      footer={null}
      onCancel={close}
      title={<h1><Trans>Create a new playbook from scratch</Trans></h1>}
      visible={visible}
    >
      <CustomFormWrapper
        onSubmit={values => loadingModalFeedback({
          loadingTitle: <Trans>Creating a new playbook</Trans>,
          successTitle: <Trans>Playbook created</Trans>,
          errorTitle: <Trans>Failed to create your new playbook</Trans>,
          errorDescription: <Trans>An error occurred while creating your new playbook. Please try again later.</Trans>,
          autoSuccessClose: 1000,
          onSuccessClose: () => history.push(`/${lang}/templates/${templateIDRef.current}`)
        })(async () => {
          const template = await RestService('POST', '/template', values)
          template.role = 'Owner'
          templateIDRef.current = template.templateID
          await dispatch(updateTemplate(template))
          const saveSectionBE = sectionID => RestService('PUT', `/template/${template.templateID}/${sectionID}`, {})
          await Promise.allSettled([
            newSection('header', template.templateID).then(saveSectionBE),
            newSection('footer', template.templateID).then(saveSectionBE)
          ])
        })}
      >
        <MetadataSettings template={{}} />
        <div className='savingButtonWrapper'>
          <Button htmlType='submit' type='primary'>
            <Trans>Create new playbook</Trans>
          </Button>
        </div>
      </CustomFormWrapper>
    </Modal>
  )
}

export default CreateNewPlaybookModal
