import React from 'react'
import { Trans } from '@lingui/macro'
import { Form, Radio } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import { TemplateSettingsChildProps } from '../TemplatePage'
import { requiredRules } from '../../SharedComponents/CustomFormWrapper'

const FeedbackSettings: React.FC<TemplateSettingsChildProps> = ({ template }) => (
  <fieldset>
    <label><h3><Trans>Collect feedback while contract is drafted</Trans></h3></label>
    <Form.Item
      initialValue={template.allowUserFeedback || false}
      name='allowUserFeedback'
      rules={requiredRules}
    >
      <Radio.Group className='radioCardChoice'>
        <Radio value={false}>
          <h4><Trans>No Feedback</Trans></h4>
          <span className='activateChoice'><Trans>Activated</Trans> <CheckCircleFilled /></span>
        </Radio>
        <Radio value={true}>
          <h4><Trans>Collect Feedback</Trans></h4>
          <p><Trans>Allow users of this playbook to give feedback while using the contract assistant.</Trans></p>
          <span className='activateChoice'><Trans>Activated</Trans> <CheckCircleFilled /></span>
        </Radio>
      </Radio.Group>
    </Form.Item>
  </fieldset>
)

export default FeedbackSettings
