import React from 'react'
import { EnabledPlugins } from '../../core/PlatePlugins'
import Group from './formats/GroupFormat'
import * as formats from './formats'


// eslint-disable-next-line import/prefer-default-export
export const getToolbarButtons = (enabledPlugins: EnabledPlugins): [React.ReactNode[], React.ReactNode[]] => {
  const toolbarButtons = [formats.textStyle, formats.textAlign]
  const balloonToolbarButtons = [formats.textStyle]

  if (enabledPlugins.list) {
    toolbarButtons.push(formats.list)
  }
  if (enabledPlugins.link) {
    const groupedLink = <Group key='groupedLink'>{formats.link}</Group>
    toolbarButtons.push(groupedLink)
    balloonToolbarButtons.push(groupedLink)
  }
  if (enabledPlugins.image) {
    toolbarButtons.push(formats.image)
  }
  if (enabledPlugins.table) {
    toolbarButtons.push(formats.table)
  }
  if (enabledPlugins.history) {
    toolbarButtons.push(formats.history)
  }

  return [toolbarButtons, balloonToolbarButtons]
}
