import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { NumberParam, StringParam, useQueryParam } from 'use-query-params'
import { DeferRenderProvider, withDeferRender } from 'react-defer-renderer'
import { Trans } from '@lingui/macro'
import { Button, Skeleton } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

//  Editor library
import { EditorWithContractData } from '@top-legal/editor'

import { ContractContext } from '../Contexts'

import useContractEditorContent from './useContractEditorContent'
import { ExternalContext } from '../../../ExternalViews/ExternalContext'


const options = { fallback: <Skeleton active /> }
const emptyObject = {}
const ContractEditorContent_: React.FC<{ chunk: any[], previous?: React.ReactNode, next?: React.ReactNode }> = ({
  chunk, previous, next
}) => {
  const { contract, previewMode } = useContext<any>(ContractContext)
  const { tokenData } = useContext(ExternalContext)

  const readOnly = tokenData ? (
    tokenData.externalRole !== 'redlining' || tokenData.requestSigning
  ) : (contract.contractID !== '__preview__' && (
    previewMode || !contract.draftedDate || contract.signaturesHolder != null
    || window.location.href.includes('/publish')
  ))

  return (
    <DeferRenderProvider mode='sequential'>
      <EditorWithContractData borderless readOnly={readOnly}>
        <div className='contractRenderingWrapper'>
          {previous}
          {chunk.map(({ key, itemProps = emptyObject, RenderItem }) => {
            if (!(RenderItem as any).Wrapped) {
              (RenderItem as any).Wrapped = withDeferRender(RenderItem, options)
            }
            const Component = (RenderItem as any).Wrapped

            return <Component {...itemProps} key={key} />
          // return <RenderItem {...itemProps} key={key} />
          })}
          {next}
        </div>
      </EditorWithContractData>
    </DeferRenderProvider>
  )
}
const ContractEditorContent = React.memo(ContractEditorContent_)
if (process.env.NODE_ENV !== 'production') {
  ContractEditorContent.displayName = 'ContractEditorContent'
}


const PaginationWrapper_: React.FC = ({ children }) => (
  <div className='paginationWrapper'>
    <Skeleton />
    {children}
  </div>
)
const PaginationWrapper = withDeferRender(PaginationWrapper_, options)
if (process.env.NODE_ENV !== 'production') {
  PaginationWrapper.displayName = 'PaginationWrapper'
}


const defaultPageSize = 10
const PaginatedContractEditorContent: React.FC<{ before?: React.ReactNode, after?: React.ReactNode }> = ({
  before, after
}) => {
  const [pageSize, setPageSize] = useQueryParam('pageSize', NumberParam)
  const [activePage, setActivePage] = useQueryParam('activePage', NumberParam)
  const [activeSection] = useQueryParam('activeSection', StringParam)

  //  On paginated sections we do not want to have a big space at the bottom
  useLayoutEffect(() => {
    document.body.classList.add('noBottomMarginContract')
    return () => document.body.classList.remove('noBottomMarginContract')
  }, [])

  //  The content could be a provided to a virtual list lately if needed
  const currentPageSize = (pageSize || defaultPageSize)
  const editorContent = useContractEditorContent()
  const maxPages = Math.ceil(editorContent.length / currentPageSize)
  const currentPage = Math.max(1, Math.min(maxPages, activePage || 1))

  //  Change the page to the active section
  useEffect(() => {
    if (activeSection) {
      const sectionIndex = editorContent.findIndex(({ key }) => key === activeSection)
      if (sectionIndex >= 0) {
        setActivePage(Math.ceil((sectionIndex + 1) / currentPageSize))
      }
    }
    //  We don't want to rerender when currentPageSize or editorContent change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection, setActivePage])

  //  Get a subset of pages
  const chunk = useMemo(
    () => editorContent.slice(currentPageSize * (currentPage - 1), currentPageSize * currentPage),
    [currentPage, editorContent, currentPageSize]
  )

  const previous = useMemo(() => currentPage > 1 && (
    <PaginationWrapper>
      <Button
        icon={<UpOutlined />}
        onClick={() => setActivePage(currentPage - 1)}
        type='primary'
      >
        <Trans>Load more</Trans>
      </Button>
    </PaginationWrapper>
  ), [currentPage, setActivePage])
  const next = useMemo(() => currentPage < maxPages && (
    <PaginationWrapper>
      <Button
        icon={<DownOutlined />}
        onClick={() => setActivePage(currentPage + 1)}
        type='primary'
      >
        <Trans>Load more</Trans>
      </Button>
    </PaginationWrapper>
  ), [currentPage, maxPages, setActivePage])


  //  Guard to be sure that we have the pagination in url
  const checkedRef = useRef(false) // For doing it only first time
  if (!checkedRef.current) {
    checkedRef.current = true

    if (pageSize == null || pageSize <= 0 || activePage !== currentPage) {
      checkedRef.current = true
      setActivePage(currentPage)
      setPageSize(currentPageSize)
      return null
    }
  }

  return (
    <>
      {currentPage === 1 && before}
      <ContractEditorContent
        chunk={chunk}
        next={next}
        previous={previous}
      />
      {currentPage === maxPages && after}
    </>
  )
}

export default PaginatedContractEditorContent
