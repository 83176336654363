/* eslint-disable id-length,no-restricted-syntax,no-use-before-define */
import { Node, Path } from 'slate'


interface DeltaText {
  type: 'added' | 'removed'
  client: number
  offset: number
  text: string
}

interface DeltaProperty {
  newValue: any
  oldValue: any
}

interface AttributeDelta {
  [attr: string]: DeltaProperty
}

interface DeltaNode {
  attributesDelta?: AttributeDelta
  textDelta?: DeltaText[]
  children?: DeltaTree
  preDeletions?: Node[]
  postDeletions?: Node[]
  insertedNode?: Node
}

interface DeltaTree {
  [index: string]: DeltaNode
}

type EntityProcessCallback = (entityType: string, key: string, defaultRender: string) => string

interface NodeProps {
  node: Node,
  children: string,
  callback: EntityProcessCallback,
  [key: string]: any
}
type NodeRenderer = (props: NodeProps) => string

type IsInline = (node: any) => boolean

export const SLATE_NODES_MAPPING: { [key: string]: NodeRenderer } = {
  d: ({ children }) => `<div class="blockElement">${children}</div>`,
  p: ({ node: { align = 'left', style = '' }, children }) => `<p style="text-align: ${align};${style}">${children}</p>`,
  ol: ({ children }) => `<ol>${children}</ol>`,
  ul: ({ children }) => `<ul>${children}</ul>`,
  li: ({ children }) => `<li>${children}</li>`,
  a: ({ node, children }) => `<a href="${node.url}">${children}</a>`,
  img: ({ node }) => `<img src="${node.url}" style="max-width: 100%; width: auto; height: auto;" />`,
  table: ({ children, node: { withHeader, withFooter } }) => {
    const rows = [...(children as any).rawChildren]
    let header = ''
    let footer = ''

    if (withHeader) {
      header = `<thead>${rows.shift()}</thead>`
    }
    if (withFooter) {
      footer = `<tfoot>${rows.pop()}</tfoot>`
    }
    return `<table class="slate-table">${header}${rows.join('')}${footer}</table>`
  },
  tr: ({ children }) => `<tr>${children}</tr>`,
  th: ({ children }) => `<th>${children}</th>`,
  td: ({ children }) => `<td>${children}</td>`,

  //  Callback is async and make the whole renderSlateNodes async too
  TemplateEntity: ({ node: { entityType, key }, callback }) => callback(
    entityType as string,
    key as string,
    `<span class="${entityType}" data-key="${key}">${key}</span>`
  ),
  //  Section reference in case of obfuscated contract for signing
  r: ({ node: { key }, callback }) => callback(
    'sectionReference',
    key as string,
    `<span class="sectionReference" data-key="${key}">${key}</span>`
  )
}


export const SLATE_MARKS_MAPPING: { [key: string]: NodeRenderer } = {
  bold: ({ children }) => `<b>${children}</b>`,
  italic: ({ children }) => `<em>${children}</em>`,
  underline: ({ children }) => `<u>${children}</u>`
}

const Nil: NodeRenderer = ({ children }) => children

const getDelta = ([head, ...tail]: Path, deltaNode: DeltaNode): DeltaNode | undefined => {
  if (head != null) {
    if (deltaNode.children && deltaNode.children[head]) {
      return getDelta(tail, deltaNode.children[head])
    }
    return undefined
  }
  return deltaNode
}

const renderSlateNode = (isInlineNode: IsInline, node: Node, callback: EntityProcessCallback, deltaTree?: DeltaTree, path: Path = []) => {
  const { preDeletions, insertedNode, attributesDelta, textDelta, postDeletions } = (deltaTree && getDelta(path, { children: deltaTree })) || {}

  let children = ''
  if (Array.isArray(node.children)) {
    children = renderSlateNodes(isInlineNode, node.children, callback, deltaTree, path)
  } else if (typeof node.text === 'string') {
    children = node.text

    //  Add redlining first
    if (textDelta) {
      let offset = 0
      for (const delta of textDelta) {
        let text = ''

        if (delta.type === 'added') {
          const content = children.slice(delta.offset + offset, delta.offset + offset + delta.text.length)
          if (content === delta.text) {
            children = [children.slice(0, delta.offset + offset), children.slice(delta.offset + offset + delta.text.length)].join('')
            text = `<span class="redLiningInsert">${delta.text}</span>`
          } else {
            console.error('Invalid offset', children, delta, delta)
          }
        } else if (delta.type === 'removed') {
          text = `<span class="redLiningRemove">${delta.text}</span>`
        }

        if (text) {
          children = [
            children.slice(0, delta.offset + offset),
            text,
            children.slice(delta.offset + offset)
          ].join('')

          offset += text.length
          if (delta.type === 'added') {
            offset -= delta.text.length
          }
        }
      }
    }

    // Line break in slate are new lines but in html br
    children = children.replace(/\n/g, '<br/>')

    //  Then render marks
    for (const [mark, renderer] of Object.entries(SLATE_MARKS_MAPPING)) {
      if (node[mark]) {
        children = renderer({ node, children, callback }) // eslint-disable-line no-await-in-loop
      }
    }
  }

  //  Wrap the node with insertion, deletions & rendering of the slate node type
  const isInline = isInlineNode(node)
  let insertDone = false

  //  For li we need to wrap the content before applying li node or it will break the list design
  if ((insertedNode || attributesDelta) && node.type === 'li') {
    insertDone = true
    children = `<div class="redLiningInsertNode">${children}</div>`
  }

  if (typeof node.type === 'string') {
    const renderer = SLATE_NODES_MAPPING[node.type] || Nil
    children = renderer({ node, children, callback })
  }

  if ((insertedNode || attributesDelta) && !insertDone) {
    if (isInline) {
      children = `<span class="redLiningInsertNode inline">${children}</span>`
    } else {
      children = `<div class="redLiningInsertNode">${children}</div>`
    }
  }

  //  Wrap the deletions
  if (preDeletions) {
    if (isInline) {
      children = `<span class="redLiningRemoveNode inline">${renderSlateNodes(isInlineNode, preDeletions, callback)}</span>${children}`
    } else {
      children = `<div class="redLiningRemoveNode">${renderSlateNodes(isInlineNode, preDeletions, callback)}</div>${children}`
    }
  }
  if (postDeletions) {
    if (isInline) {
      children += `<span class="redLiningRemoveNode inline">${renderSlateNodes(isInlineNode, postDeletions, callback)}</span>`
    } else {
      children += `<div class="redLiningRemoveNode">${renderSlateNodes(isInlineNode, postDeletions, callback)}</div>`
    }
  }

  return children
}

const renderSlateNodes = (isInlineNode: IsInline, array: Node[], callback: EntityProcessCallback, deltaTree?: DeltaTree, path: Path = []): string => {
  const nodes = array.map(
    (node, index) => renderSlateNode(isInlineNode, node, callback, deltaTree, [...path, index])
  )

  // Need to be new String() to be an object where we can put the rawChildren prop
  // eslint-disable-next-line no-new-wrappers
  const str = new String(nodes.join(''))
  ;(str as any).rawChildren = nodes
  return str as string
}

export default renderSlateNodes
