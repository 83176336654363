/* eslint-disable no-use-before-define,no-restricted-syntax */
import * as Y from 'yjs'
import { Editor, Node, Path, Range } from 'slate'
import { PEditor } from '@udecode/plate-core'
import { toBase64 } from '../../core/yjsUtils/SlateToYjsDoc'

export interface DeltaText {
  type: 'added' | 'removed'
  client: number
  offset: number
  text: string
}

export interface DeltaProperty {
  newValue: any
  oldValue: any
}

export interface AttributeDelta {
  [attr: string]: DeltaProperty
}

export interface DeltaNode {
  attributesDelta?: AttributeDelta
  textDelta?: DeltaText[]
  children?: DeltaTree
  preDeletions?: Node[]
  postDeletions?: Node[]
  insertedNode?: Node
}

export interface DeltaTree {
  [index: string]: DeltaNode
}

export interface Delta {
  delta: DeltaTree
  patch: string
}

const getContent = (item: Y.Item): any => {
  const contents = item.content.getContent()
  return contents[contents.length - 1]
}

export const computeSnapshotDelta = (doc: Y.Doc, sharedType: Y.Array<any>, prevSnapshot: Y.Snapshot, initSnapshot = prevSnapshot): DeltaTree | undefined => {
  const nextSnapshot = Y.snapshot(doc)

  if (Y.equalSnapshots(initSnapshot, nextSnapshot)) {
    return undefined
  }

  const prevClock = (node: Y.Item) => prevSnapshot.sv.get(node.id.client) || 0
  const nextClock = (node: Y.Item) => nextSnapshot.sv.get(node.id.client) || 0

  const formatValue = (item: any): any => {
    if (item == null) {
      return undefined
    }
    if (item instanceof Y.Text) {
      let str = ''
      let node = item._start
      while (node !== null) {
        if (node.countable && node.id.clock < prevClock(node) && node.content.constructor === Y.ContentString) {
          str += (node.content as Y.ContentString).str
        }
        node = node.right
      }
      return str
    }
    if (item instanceof Y.Map) {
      const obj: any = {}
      for (const [key, attr] of item._map.entries()) {
        if (!Y.isDeleted(prevSnapshot.ds, attr.id)) {
          let val: Y.Item | null = attr
          while (val && val.id.clock >= prevClock(val)) { val = val.left }
          if (val) {
            obj[key] = formatValue(getContent(val))
          }
        }
      }
      return obj
    }
    if (item instanceof Y.Array) {
      const array: any[] = []
      let node = item._start
      while (node !== null) {
        if (node.countable) {
          if (!Y.isDeleted(prevSnapshot.ds, node.id)) {
            if (node.id.clock < prevClock(node)) {
              array.push(formatValue(getContent(node)))
            }
          }
        }
        node = node.right
      }
      return array
    }
    if (typeof item.toJSON === 'function') {
      return item.toJSON()
    }
    if (item instanceof Uint8Array) {
      return toBase64(item)
    }
    return item
  }

  const computeTextChanges = (text: Y.Text): DeltaText[] => {
    const deltas: DeltaText[] = []

    const tmpDeltas: { add?: any, del?: any } = {}
    const commitDelta = (key: 'add' | 'del') => {
      if (tmpDeltas[key]) {
        deltas.push(tmpDeltas[key])
        delete tmpDeltas[key]
      }
    }

    let offset = 0
    text.toDelta(
      nextSnapshot,
      prevSnapshot,
      (type, { client }) => ({ type, client })
    ).forEach(delta => {
      if (delta.attributes && delta.attributes.ychange) {
        const { type, client } = delta.attributes.ychange

        if (type === 'added') {
          if (tmpDeltas.add && tmpDeltas.add.offset + tmpDeltas.add.text.length === offset) {
            tmpDeltas.add.text += delta.insert
            offset += delta.insert.length
            return
          }

          commitDelta('add')
          tmpDeltas.add = {
            type, client, offset, text: delta.insert
          }
          offset += delta.insert.length
        } else {
          if (tmpDeltas.del && tmpDeltas.del.offset === offset) {
            tmpDeltas.del.text += delta.insert
            return
          }

          if (tmpDeltas.add && tmpDeltas.add.offset + tmpDeltas.add.text.length === offset) {
            if (!tmpDeltas.del || tmpDeltas.del.offset !== tmpDeltas.add.offset) {
              commitDelta('del')
              tmpDeltas.del = {
                type, client, offset: tmpDeltas.add.offset, text: ''
              }
            }
            tmpDeltas.del.text += delta.insert
            return
          }

          commitDelta('del')
          tmpDeltas.del = {
            type, client, offset, text: delta.insert
          }
        }
      } else {
        commitDelta('del')
        commitDelta('add')
        offset += delta.insert.length
      }
    })

    //  Don't forget to commit at the end
    commitDelta('del')
    commitDelta('add')

    return deltas
  }

  const computeNodeChanges = (node: Y.Map<any>): DeltaNode => {
    const obj: DeltaNode = {}
    const attributesDelta: AttributeDelta = {}

    for (const [key, item] of node._map.entries()) {
      if (!Y.isDeleted(prevSnapshot.ds, item.id)) {
        const pClock = prevClock(item)
        if (Y.isDeleted(nextSnapshot.ds, item.id)) {
          if (item.id.clock < pClock) {
            attributesDelta[key] = { oldValue: formatValue(getContent(item)), newValue: undefined }
          }
        } else if (item.id.clock >= pClock) {
          attributesDelta[key] = { oldValue: undefined, newValue: formatValue(getContent(item)) }
        } else if (item.id.clock < pClock) {
          let nextItem: Y.Item | null = item
          while (nextItem && nextItem.id.clock > nextClock(nextItem)) {
            nextItem = nextItem.left
          }

          let prevItem: Y.Item | null = nextItem
          while (prevItem && prevItem.id.clock > prevClock(prevItem)) {
            prevItem = prevItem.left
          }

          const oldValue = prevItem && getContent(prevItem)
          const newValue = nextItem && getContent(nextItem)

          // eslint-disable-next-line eqeqeq
          if (oldValue != newValue) {
            attributesDelta[key] = { oldValue, newValue }
          }
        }
      }
    }

    if (Object.keys(attributesDelta).length > 0) {
      obj.attributesDelta = attributesDelta
    }

    if (node.has('children')) {
      if (obj.attributesDelta) { delete obj.attributesDelta.children }
      const children = node.get('children')
      if (children && children.constructor === Y.Array) {
        const computedChildren = computeArrayChanges(children)
        if (Object.keys(computedChildren).length > 0) {
          obj.children = computedChildren
        }
      }
    }

    if (node.has('text')) {
      if (obj.attributesDelta) { delete obj.attributesDelta.text }
      const text = node.get('text')
      if (text && text.constructor === Y.Text) {
        const delta = computeTextChanges(text)
        if (delta.length > 0) {
          obj.textDelta = delta
        }
      }
    }

    return obj
  }

  const computeArrayChanges = (array: Y.Array<any>): DeltaTree => {
    const sparseTree: DeltaTree = {}
    let currentNode: DeltaNode = {}
    let index = 0
    let deletions: any[] = []

    const addElement = () => {
      if (deletions.length > 0) {
        currentNode.preDeletions = deletions
      }
      if (Object.keys(currentNode).length > 0) {
        sparseTree[index] = currentNode
      }
      index += 1
      currentNode = {}
      deletions = []
    }

    const childNodeChanges = (node: Y.Item) => {
      const tmp = computeNodeChanges(getContent(node))
      if (Object.keys(tmp).length > 0) {
        currentNode = { ...currentNode, ...tmp }
      }
      addElement()
    }

    let node = array._start
    while (node !== null) {
      if (node.countable && !node.redone) {
        if (!Y.isDeleted(prevSnapshot.ds, node.id)) {
          const pClock = prevClock(node)
          if (Y.isDeleted(nextSnapshot.ds, node.id)) {
            if (node.id.clock < pClock) {
              deletions.push(formatValue(getContent(node)))
            }
          } else if (node.id.clock >= pClock) {
            if (node.right && node.right.redone === node.id) {
              childNodeChanges(node)
              node = node.right
            } else {
              currentNode.insertedNode = getContent(node).toJSON()
              addElement()
            }
          } else {
            childNodeChanges(node)
          }
        }
      }
      node = node.right
    }

    if (deletions.length > 0) {
      const prevIndex = Math.max(0, index - 1)
      if (!sparseTree[prevIndex]) {
        sparseTree[prevIndex] = {}
      }
      sparseTree[prevIndex].postDeletions = deletions
    }

    return sparseTree
  }
  const delta = computeArrayChanges(sharedType)
  return Object.keys(delta).length ? delta : undefined
}

export const DELTA_MARK = '__DELTA_MARK__' as const
export interface DeltaLeaf {
  [DELTA_MARK]: true
  client: number
  path: Path
  insertNode?: boolean
  preDeletions?: Node[]
  postDeletions?: Node[]
  insertText?: { text: string, offset: number }
  removeText?: { text: string, offset: number }
}

export type DeltaRange = Range & DeltaLeaf

export interface DeltaNodeElement extends Pick<DeltaNode, 'preDeletions' | 'postDeletions'> {
  path: Path
  newNode: boolean
}

export type DeltaNodeMap = WeakMap<Node, DeltaNodeElement>
export const emptyDeltaRanges: [DeltaNodeMap, DeltaRange[]] = [new WeakMap(), []]

/**
 * Compute the deltas range
 * prevMap is for optimization purpose to not rerender what has not changed
 */
export const deltaToRanges = (delta: DeltaTree | undefined | null, editor: PEditor, prevMap?: DeltaNodeMap): [DeltaNodeMap, DeltaRange[]] => {
  const rangesMapping: { [key: string]: DeltaRange } = {}
  const nodeMap = new WeakMap<Node, DeltaNodeElement>()

  const addRange = (range: DeltaRange) => {
    const anchor = [...range.anchor.path, range.anchor.offset].join(',')
    if (rangesMapping[anchor]) {
      range = { ...rangesMapping[anchor], ...range }
    }

    const focus = [...range.focus.path, range.focus.offset].join(',')
    if (rangesMapping[focus]) {
      range = { ...rangesMapping[focus], ...range }
      delete rangesMapping[focus]
    }

    rangesMapping[anchor] = { ...range }
  }

  if (delta && editor.children.length > 0) {
    const processDeltaTree = (tree: DeltaTree, parentPath: Path = []) => {
      Object.entries(tree).forEach(([index, node]) => {
        const path: Path = [...parentPath, parseInt(index, 10)]

        if (node.preDeletions || node.postDeletions || node.insertedNode || node.attributesDelta) {
          const [editorNode] = Editor.node(editor, path)
          if (typeof editorNode.text === 'string') {
            if (node.preDeletions) {
              addRange({
                [DELTA_MARK]: true,
                client: 0,
                path,
                preDeletions: node.preDeletions,
                anchor: { path, offset: 0 },
                focus: { path, offset: 0 }
              })
            }
            if (node.postDeletions) {
              addRange({
                [DELTA_MARK]: true,
                client: 0,
                path,
                postDeletions: node.postDeletions,
                anchor: { path, offset: editorNode.text.length },
                focus: { path, offset: editorNode.text.length }
              })
            }
          } else {
            const nextNode: DeltaNodeElement = {
              preDeletions: node.preDeletions,
              postDeletions: node.postDeletions,
              newNode: !!node.insertedNode || !!node.attributesDelta,
              path
            }

            //  Memo optimization purpose
            const prevNode = prevMap?.get(editorNode)
            if (prevNode) {
              if (nextNode.preDeletions && prevNode.preDeletions && nextNode.preDeletions.length === prevNode.preDeletions.length) {
                nextNode.preDeletions = prevNode.preDeletions
              }
              if (nextNode.postDeletions && prevNode.postDeletions && nextNode.postDeletions.length === prevNode.postDeletions.length) {
                nextNode.postDeletions = prevNode.postDeletions
              }
              if (nextNode.path.length === prevNode.path.length && !nextNode.path.some((num, idx) => num !== prevNode.path[idx])) {
                nextNode.path = prevNode.path
              }
            }

            nodeMap.set(editorNode, nextNode)
          }
        }

        if (node.textDelta) {
          node.textDelta.forEach(textDelta => {
            if (textDelta.type === 'added') {
              addRange({
                [DELTA_MARK]: true,
                client: textDelta.client,
                path,
                insertText: { text: textDelta.text, offset: textDelta.offset },
                anchor: { path, offset: textDelta.offset },
                focus: { path, offset: textDelta.offset + textDelta.text.length }
              })
            } else if (textDelta.type === 'removed') {
              addRange({
                [DELTA_MARK]: true,
                client: textDelta.client,
                path,
                removeText: { text: textDelta.text, offset: textDelta.offset },
                anchor: { path, offset: textDelta.offset },
                focus: { path, offset: textDelta.offset }
              })
            }
          })
        }

        if (node.children) {
          processDeltaTree(node.children, path)
        }
      })
    }
    processDeltaTree(delta)
  }

  return [nodeMap, Object.values(rangesMapping)]
}
