import React, { Dispatch, SetStateAction, useCallback, useMemo, useRef, useState } from 'react'
import { Button, Modal } from 'antd'
import { Trans } from '@lingui/macro'

import { Contract, useContractsListBaseQuery, useList } from '@top-legal/datastore'

import ContractUploader from '../../Upload/ContractUploader'
import CreateNewPlaybookModal from '../../Template/CreateNewPlaybookModal'
import { MediaCard } from '../../Media/MediaLibrary'

import { emptyObject, getContractStatus } from '../SearchHelpers'


import './TemplateItemStyles.scss'

interface Stats {
  drafting: number
  negotiate: number
  approval: number
  signing: number
  signed: number
  // rejected: number
}

interface TemplateListingContextProps {
  templateStatistics: { [templateID: string]: Stats }
  setOpenedVideo: Dispatch<SetStateAction<string | undefined>>
  setShowUploadModal: Dispatch<SetStateAction<boolean>>
  setShowCreateNew: Dispatch<SetStateAction<boolean>>
}


export const TemplateListingContext = React.createContext<TemplateListingContextProps>({} as any)


const TemplateContainerWrapper: React.FC = ({ children }) => {
  //  Template stats
  const getContracts = useContractsListBaseQuery()
  const [templateStatistics = emptyObject] = useList<Contract, any, TemplateListingContextProps['templateStatistics']>(
    getContracts,
    useCallback(contracts => {
      const statistics: any = {}
      contracts.forEach(contract => {
        const { editingOrgininalTemplateID: source, templateID: current } = contract
        const templateID = source || current

        if (!statistics[templateID]) {
          statistics[templateID] = {}
        }

        const status = getContractStatus(contract)
        statistics[templateID][status] = (statistics[templateID][status] || 0) + 1
      })
      return statistics
    }, [])
  )

  const [openedVideo, setOpenedVideo] = useState<string>()
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [showCreateNew, setShowCreateNew] = useState(false)


  const timeoutRef = useRef()


  return (
    <TemplateListingContext.Provider
      value={useMemo(() => ({
        templateStatistics,
        setOpenedVideo,
        setShowUploadModal,
        setShowCreateNew
      }), [templateStatistics])}
    >
      {children}
      {/** ***********************************************************************
       *                    Modals
       ************************************************************************ */}
      <ContractUploader
        close={() => setShowUploadModal(false)} isTemplateUploader timeoutRef={timeoutRef}
        visible={showUploadModal}
      />
      <CreateNewPlaybookModal close={() => setShowCreateNew(false)} visible={showCreateNew} />

      <Modal
        className='smallModal'
        destroyOnClose
        footer={null}
        onCancel={() => setOpenedVideo(undefined)}
        visible={!!openedVideo}
      >
        {openedVideo && <MediaCard media={openedVideo} viewerOnly />}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem'
          }}
        >
          <Button onClick={() => setOpenedVideo(undefined)} type='primary'>
            <Trans>Close</Trans>
          </Button>
        </div>
      </Modal>
    </TemplateListingContext.Provider>
  )
}

export default TemplateContainerWrapper
