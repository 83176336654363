import React, { useCallback, useState } from 'react'
import { Trans } from '@lingui/macro'
import { Button, Progress } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import Auth from '@aws-amplify/auth'
import Storage from '@aws-amplify/storage'

export const getFileUrl = async (key: string): Promise<string> => {
  if (key) {
    const [level, identityId, ...rest] = key.split('/') as any
    return Storage.get(rest.join('/'), {
      level, identityId
    })
  }
  return ''
}

const useStorageUploadFeedback = (): [React.ReactElement | undefined, (filePath: string, file: File) => Promise<string>] => {
  const [uploadStatus, setUploadStatus] = useState<'active' | 'success' | 'exception'>()
  const [uploadProgress, setUploadProgress] = useState(0)

  const upload = useCallback(async (filePath: string, file: File) => {
    setUploadStatus('active')
    setUploadProgress(0)
    try {
      // uploading the document to the s3 bucket
      const user = await Auth.currentCredentials()
      const { key } = (await Storage.put(filePath, file, {
        level: 'protected',
        // identityId: user.identityId,
        cacheControl: 'must-revalidate',
        contentType: file.type,
        progressCallback: ({ loaded, total }) => {
          setUploadProgress(Math.round((loaded * 100) / total))
        }
      }) as any)

      setUploadStatus('success')
      setTimeout(() => setUploadStatus(undefined), 1000)

      return `protected/${user.identityId}/${key}`
    } catch (err) {
      console.error('Failed to upload file', err)
      setUploadStatus('exception')
      throw err
    }
  }, [])

  const feedback = uploadStatus && (
    <div className='uploadInProgress'>
      <Progress percent={uploadProgress} status={uploadStatus} strokeColor='#3dbd7d' type='circle' />
      {uploadStatus === 'active' && <h2 className='progress'><Trans>Upload in progress</Trans></h2>}
      {uploadStatus === 'success' && <h2 className='done'><Trans>Upload done</Trans></h2>}
      {uploadStatus === 'exception' && <h2 className='failed'><Trans>Upload failed</Trans></h2>}
      <p><Trans>Please wait during the uploading of your content.</Trans></p>
      <div className='mediaUploadActions'>
        {uploadStatus === 'exception' && (
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() => setUploadStatus(undefined)}
            type='default'
          >
            <Trans>Back</Trans>
          </Button>
        )}
      </div>
    </div>
  )

  return [feedback, upload]
}

export default useStorageUploadFeedback
