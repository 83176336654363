/* eslint-disable no-console */
import React, { useMemo } from 'react'
import { useParams } from 'react-router'

import {
  saveInStore,
  toJson,
  useDoc,
  useTemplateFlaternisationsCollection,
  Template, useTemplatesCollection
} from '@top-legal/datastore'

import TemplateDependenciesVisitor from './utils/templateVisitors/TemplateDependenciesVisitor'
import { useGetGetters } from './useGetGetters'


const Worker: React.FC = () => {
  const { templateID = '__NO_ID__' } = useParams()

  const templatesCollection = useTemplatesCollection()
  const flaternisationsCollection = useTemplateFlaternisationsCollection()
  const getGetters = useGetGetters()

  const [template] = useDoc<Template, {}, Template | undefined>(
    useMemo(() => templatesCollection.findOne(templateID), [templatesCollection, templateID]),
    toJson
  )

  //  Ensure we have a up to date flaternisation index of the template
  useMemo(() => {
    if (template) {
      const flaternisationProcess = async (force = false) => {
        const existingFlaternisation = await flaternisationsCollection.findOne(`flat-${templateID}`).exec()
        const isUpToDateFlaternisation = existingFlaternisation?.dateUpdated === template.dateUpdated

        if (process.env.NODE_ENV === 'development') {
          console.info('[Contract Assistant Worker] Found existing flaternisation?', existingFlaternisation)
          console.info('[Contract Assistant Worker] isUpToDateFlaternisation =', isUpToDateFlaternisation)
        }

        //  Check if we need to (re)build a flaternisation object
        if (!isUpToDateFlaternisation || (existingFlaternisation as any)?.toJSON?.()?.forced) {
          const beginAt = Date.now()
          const dependenciesVisitor = new TemplateDependenciesVisitor(getGetters(template), force)
          await dependenciesVisitor.visitTemplate(template, existingFlaternisation || undefined)
          const flaternisation = dependenciesVisitor.dependencies
          ;(flaternisation as any).forced = force

          const save = async () => saveInStore(flaternisationsCollection, flaternisation)

          if (process.env.NODE_ENV === 'development') {
            console.info('[Contract Assistant Worker] Job took', Date.now() - beginAt, 'ms')
            console.info('[Contract Assistant Worker] The flaternisation is', flaternisation)
          }

          try {
            await save()
          } catch (err) {
            console.error('[Contract Assistant Worker] Failed to save the flaternisation of the template, retry in 200ms', err)
            await new Promise(resolve => { setTimeout(() => resolve(save()), 200) })
          }
        }
      }

      //  Retry on error
      ;(async () => {
        for (let _ = 0; _ < 10; _ += 1) {
          try {
            // eslint-disable-next-line no-await-in-loop
            await flaternisationProcess(_ === 9)
            return
          } catch (err) {
            console.error('Failed to process the flaternisation of the template, retry in 200ms', err)
            // eslint-disable-next-line no-await-in-loop
            await new Promise(resolve => { setTimeout(resolve, 200) })
          }
        }
      })()
    }
  }, [template, flaternisationsCollection, templateID, getGetters])

  return null
}

export default Worker
