import React, { useContext, useMemo } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { Trans } from '@lingui/macro'
import { AuditOutlined, CheckOutlined, LinkOutlined, MailOutlined, SendOutlined } from '@ant-design/icons'
import { Button, Card, Tooltip } from 'antd'

//  Contexts
import moment from 'moment'
import { ConfirmButton } from '../../../../SharedComponents/FormComponents'
import RestService from '../../../../../RestService'
import { UserAndCompanyDataContext } from '../../../../Layouts/Constants'
import { ContractContext } from '../../Contexts'

//  Components
import { UserAvatar, userFullName } from '../../../../Organisations/UserRoleDisplay/UserAvatarList'
import { copyLink } from '../../InviteContactToContract/InviteFormDrawer'
import loadingModalFeedback from '../../../../Alert/LoadingModalFeedback'
import { saveContract } from '../../../redux/ContractActions'
import { resendEmailAction } from './UserManagement/UserCards/useCardActions'

//  Styles
import './ApprovalBeforeSigningStyles.scss'


const ApprovalCard: React.FC<{ userID: string, date: string, approvedDate?: string }> = ({ userID, date, approvedDate }) => {
  const { lang } = useParams()
  const { contract: { contractID } } = useContext<any>(ContractContext)
  const { company: { members }, user: currentUser } = useContext<any>(UserAndCompanyDataContext)
  const dispatch = useDispatch()

  const user = members[userID]
  const actions = useMemo(() => ((approvedDate && user) ? undefined : [
    <Tooltip key={`${userID}-resend`} placement='bottom' title={<Trans>Resend invite</Trans>}>
      <ConfirmButton
        className='noBorder'
        confirmMessage={<Trans>Are you sure you want to resend an invite email?</Trans>}
        ghost
        icon={<SendOutlined />}
        onClick={() => resendEmailAction(() => RestService('POST', `/comments/${contractID}/${date}/remindTask`))}
        placement='bottom'
        size='small'
        type='default'
      />
    </Tooltip>,
    <Tooltip key={`${userID}-email`} placement='bottom' title={<Trans>Write an email</Trans>}>
      <Button
        className='noBorder'
        ghost
        href={`mailto:${user.firstName} ${user.lastName} <${user.email}>`}
        icon={<MailOutlined />}
        size='small'
        type='default'
      />
    </Tooltip>,
    <Tooltip key={`${userID}-link`} placement='bottom' title={<Trans>Copy link</Trans>}>
      <Button
        className='noBorder'
        ghost
        icon={<LinkOutlined />}
        onClick={() => copyLink(window.location.href.replace(
          `/${lang}/`,
          `/${{ de: 'de' }[user.preferredLanguage] || 'en'}/`
        ))}
        size='small'
        type='default'
      />
    </Tooltip>
  ]), [approvedDate, contractID, date, lang, user.email, user.firstName, user.lastName, user.preferredLanguage, userID])

  return (user && (
    <Card
      actions={actions}
      className='betterCard approvalCard'
      title={(
        <>
          <UserAvatar size='1.8rem' user={user} />
          <div className='userData'>
            <span className='userName textBold'>{userFullName(user)}</span>
            {approvedDate ? (
              <span className='userStatus'><Trans>Approved {moment(approvedDate).calendar()}</Trans></span>
            ) : (
              <span className='userStatus'><Trans>Approval request sent</Trans></span>
            )}
          </div>
        </>
      )}
    >
      {approvedDate ? (
        <span className='textPrimary textBolder'><CheckOutlined /> <span><Trans>Approved</Trans></span></span>
      ) : (
        <Button
          disabled={currentUser.userID !== userID}
          icon={<CheckOutlined />}
          onClick={() => loadingModalFeedback({
            loadingTitle: <Trans>Giving approval</Trans>,
            successTitle: <Trans>Contract approved</Trans>,
            errorTitle: <Trans>Failed to give approval</Trans>,
            errorDescription: <Trans>An error occurred while giving your approval to the contract. Please try again later.</Trans>,
            autoSuccessClose: 1000
          })(async () => {
            const newSignaturesHolder = await RestService('POST', `/contract/${contractID}/approve`)
            await dispatch(saveContract({ signaturesHolder: newSignaturesHolder }, false))
          })}
          type='primary'
        >
          <Trans>Approve contract</Trans>
        </Button>
      )}
    </Card>
  )
  )
}


const ApprovalBeforeSigning: React.FC = () => {
  const { contract: { signaturesHolder: { approvals, approvers, createdAt } } } = useContext<any>(ContractContext)
  const date = (new Date(createdAt)).valueOf() + 1
  const { company: { members } } = useContext<any>(UserAndCompanyDataContext)

  return (
    <div className='signingContent'>
      <div className='approvalsList'>
        {approvers.map((userID, index) => {
          const user = members[userID]
          return (user && (
            <ApprovalCard
              key={userID}
              approvedDate={approvals[userID]}
              date={(new Date(date + index)).toISOString()}
              userID={userID}
            />
          ))
        })}
      </div>
    </div>
  )
}

export default {
  key: 'approval',
  className: 'signingToolbox',
  text: <Trans>Approval</Trans>,
  helpText: <Trans>The current contract requires an approval before signing</Trans>,
  icon: <AuditOutlined />,
  // eslint-disable-next-line react/display-name
  Component: ApprovalBeforeSigning
}
