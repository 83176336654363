const productTableTrans = {
  en: {
    name: 'Product & Services',
    question: 'What are you selling?',

    productName: 'Sample product',
    productDescription: 'The products will be selected during the contract configuration by the sales rep.',
    unitLabel: 'License'
  },
  de: {
    name: 'Produkte & Services',
    question: 'Was verkaufen Sie?',

    productName: 'Musterprodukt',
    productDescription: 'Die Produkte werden bei der Vertragskonfiguration durch den Vertriebsmitarbeiter ausgewählt.',
    unitLabel: 'Lizenz'
  }
}

const genProductTableField = (lang: string) => ({
  inputFieldID: '__products',
  type: 'productTable',
  pricingModel: 'volume',
  currency: 'eur',

  ...(productTableTrans[lang] || productTableTrans.en),

  pricingTiers: [{
    max: 5, unit: 0, flat: 0
  }, {
    max: 100, unit: 25, flat: 1000
  }, {
    max: 500, unit: 20, flat: 700
  }, {
    unit: 18, flat: 500
  }],

  subscriptionType: 'recurring',
  billingPeriod: 'monthly'
})

export default genProductTableField
