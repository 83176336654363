import React, { useContext } from 'react'
import { Trans } from '@lingui/macro'

import { TemplateContext } from '../Contexts'
import ContractEditorSection from './sectionComponents/ContractEditorSection'
import { ContractEditorSectionProps, useNewSection } from './sectionComponents/Types'

const HeaderFooter: React.FC<{ kind: 'header' | 'footer' }> = ({ kind }) => {
  const { template, readOnly, updateTemplate } = useContext<any>(TemplateContext)
  const newSection = useNewSection()

  const props: ContractEditorSectionProps = { path: [kind], sectionID: template[kind] }

  if (kind === 'header') {
    props.addBellowTitle = <Trans>Add section bellow</Trans>
    props.addBellow = async () => {
      if (!Array.isArray(template.sections)) {
        template.sections = []
      }
      const secID = await newSection()
      template.sections.splice(0, 0, {
        sectionID: secID,
        subSections: []
      })
      await updateTemplate({ sections: [...template.sections] })
    }
    props.title = ' '
  }

  if (kind === 'footer') {
    props.addAboveTitle = <Trans>Add section above</Trans>
    props.addAbove = async () => {
      if (!Array.isArray(template.sections)) {
        template.sections = []
      }
      const secID = await newSection()
      template.sections.splice(template.sections.length, 0, {
        sectionID: secID,
        subSections: []
      })
      await updateTemplate({ sections: [...template.sections] })
    }

    props.addBellowTitle = <Trans>Add section bellow</Trans>
    props.addBellow = async () => {
      if (!Array.isArray(template.annexes)) {
        template.annexes = []
      }
      const secID = await newSection()
      template.annexes.splice(0, 0, {
        sectionID: secID,
        subSections: []
      })
      await updateTemplate({ annexes: [...template.annexes] })
    }
    props.title = ' '
  }

  return <ContractEditorSection {...props} />
}

export default HeaderFooter
