import React, { useContext, useMemo } from 'react'
import { Button } from 'antd'
import {
  CalendarOutlined,
  CheckSquareOutlined,
  ClockCircleOutlined,
  ContainerOutlined,
  DatabaseOutlined,
  EditOutlined,
  EuroOutlined,
  MinusSquareOutlined,
  NumberOutlined,
  OrderedListOutlined,
  PhoneOutlined,
  ProfileOutlined,
  UserAddOutlined
} from '@ant-design/icons'
import { Trans } from '@lingui/macro'

import { ConditionalText, InputField } from '@top-legal/datastore'
import { isInlineConditional, isInlineField } from '@top-legal/editor'

import { isPartiesEntity } from '../../../../../Template/_TemplateHelperFunctions'
import { ModalRefContext } from '../../../TemplateEntities/TemplateEntitiesForms/TemplateEntitiesFormsModal'
import { Category, Search } from './Types'
import DisplayField from './DisplayField'
import DisplayConditional from './DisplayConditional'


export const DisableEntitiesActions = React.createContext(false)


/** **************************************************************
 *                Build the categories
 ************************************************************** */
const getFieldsCategories = (): Category[] => [{
  type: 'parties',
  icon: <UserAddOutlined />,
  name: <Trans>Contract parties</Trans>,
  link: 'https://help.top.legal/en/knowledge/input',
  children: []
}, {
  type: 'phone',
  icon: <PhoneOutlined />,
  name: <Trans>Phone Number</Trans>,
  link: 'https://help.top.legal/en/knowledge/input-field-phone-number',
  children: []
}, {
  type: 'amount',
  icon: <EuroOutlined />,
  name: <Trans>Amount</Trans>,
  link: 'https://help.top.legal/en/knowledge/input-field-amount',
  children: []
}, {
  type: 'number',
  icon: <NumberOutlined />,
  name: <Trans>Number</Trans>,
  link: 'https://help.top.legal/en/knowledge/category-input-field-number',
  children: []
}, {
  type: 'oneLineText',
  icon: <MinusSquareOutlined />,
  name: <Trans>Single Line Text</Trans>,
  link: 'https://help.top.legal/en/knowledge/input-field-single-line-text',
  children: []
}, {
  type: 'multipleLineText',
  icon: <ProfileOutlined />,
  name: <Trans>Multi Line Text</Trans>,
  link: 'https://help.top.legal/en/knowledge/input-field-multi-line-text',
  children: []
}, {
  type: 'date',
  icon: <CalendarOutlined />,
  name: <Trans>Date</Trans>,
  link: 'https://help.top.legal/en/knowledge/input-field-date',
  children: []
}, {
  type: 'time',
  icon: <ClockCircleOutlined />,
  name: <Trans>Time</Trans>,
  link: 'https://help.top.legal/en/knowledge/input-field-time',
  children: []
}, {
  type: 'product',
  icon: <ContainerOutlined />,
  name: <Trans>Product & Services</Trans>,
  link: 'https://help.top.legal/en/knowledge/input-field-product-and-services',
  children: []
}, {
  type: 'listOfFormattedText',
  icon: <DatabaseOutlined />,
  name: <Trans>Combined List</Trans>,
  link: 'https://help.top.legal/en/knowledge/input-field-1',
  children: []
}]

const getCondsCategories = (): Category[] => [{
  type: 'yesNo',
  icon: <CheckSquareOutlined />,
  name: <Trans>Yes / No</Trans>,
  link: 'https://help.top.legal/en/knowledge/decision-yes/-no',
  children: []
}, {
  type: 'list',
  icon: <OrderedListOutlined />,
  name: <Trans>Multiple choice</Trans>,
  link: 'https://help.top.legal/en/knowledge/input-field-multiple-choice-question-mcq',
  children: []
}]


// ===================================================================


// ===================================================================


const RenderProductLine: React.FC<{ product: InputField } & Search> = ({ product }) => {
  const { modalRef } = useContext<any>(ModalRefContext)

  return (
    <div className='productLine'>
      <span>{product.productName}</span>
      <Button
        className='noBorder'
        ghost
        icon={<EditOutlined />}
        onClick={() => modalRef.current.editInputField((product as any).key, undefined, product)}
        size='small'
      />
    </div>
  )
}


// ===================================================================


// ===================================================================


/** **************************************************************
 *                Entities tree builder
 ************************************************************** */
const useTemplateEntities = (
  fields: { [fieldID: string]: InputField },
  conditionalTexts: { [condID: string]: ConditionalText },
  inlinesOnly: boolean,
  lang: string
): [Category[], Category[]] => useMemo<[Category[], Category[]]>(() => {
  const fieldsCategories = getFieldsCategories()
  const fieldsCategoriesMap: Record<string, Category> = {}
  fieldsCategories.forEach(category => { fieldsCategoriesMap[category.type] = category })

  const condsCategories = getCondsCategories()
  const condsCategoriesMap: Record<string, Category> = {}
  condsCategories.forEach(category => { condsCategoriesMap[category.type] = category })


  //  Process the fields
  const fieldsMapping = {}
  const processFields = (entities: any, path: any[] = [], isEntity = false) => Object.keys(entities).map(key => {
    const newPath: any[] = [...path, key]
    const entity: any = {
      ...entities[key],
      key: newPath.join('.')
    }
    const isEntityField = isEntity || key.startsWith('_') || isPartiesEntity(entity)
    if (entity.items) {
      entity.children = processFields(entity.items, newPath, isEntityField)
    }

    //  Setting the displayName
    let DisplayName
    if (entity.type === 'product') {
      DisplayName = ({ Highlight, search }) => <RenderProductLine Highlight={Highlight} product={entity} search={search} />
    } else {
      DisplayName = ({ Highlight, search }) => (
        <DisplayField
          Highlight={Highlight}
          field={entity}
          fieldKey={entity.key}
          inlinesOnly={inlinesOnly}
          isEntityField={isEntityField}
          noActions={useContext(DisableEntitiesActions)}
          search={search}
        />
      )
    }

    //  Create a field with the DisplayName
    const field = { ...entity, DisplayName }
    fieldsMapping[entity.key] = field


    //  Add to the tree
    if (path.length === 0) {
      if (['person', 'company'].includes(field.type)) {
        fieldsCategoriesMap.parties.children.push(field)
      } else if (inlinesOnly && !isInlineField(field)) {
        return undefined
      }
      const type = fieldsCategoriesMap[field.type] || condsCategoriesMap[field.type]
      if (type) {
        type.children.push(field)
      }
    }

    return field
  })
  processFields(fields)


  //  Special field
  if (fields.__products) {
    const { children } = fieldsCategoriesMap.product
    fieldsCategoriesMap.product.children = [{
      key: '__products',
      DisplayName: ({ Highlight, search }) => (
        <DisplayField
          Highlight={Highlight}
          field={fields.__products}
          fieldKey='__products'
          noActions
          search={search}
        >
          <div className='products'>
            {children.map(({ key, DisplayName }) => <div key={key}><DisplayName Highlight={Highlight} search={search} /></div>)}
          </div>
        </DisplayField>
      )
    }]
  }


  //  Process conditionals
  Object.keys(conditionalTexts).forEach(condKey => {
    const entity: any = { ...conditionalTexts[condKey], key: condKey }
    const field = fieldsMapping[entity.field]

    if (inlinesOnly && !isInlineConditional(entity)) {
      return
    }

    if (field) {
      const DisplayName = ({ Highlight, search }) => (
        <DisplayConditional
          Highlight={Highlight}
          cond={entity}
          field={field}
          inlinesOnly={inlinesOnly}
          lang={lang}
          noActions={useContext(DisableEntitiesActions)}
          search={search}
        />
      )
      field.items = [...(field.items || []), { ...entity, DisplayName }]
    }
  })

  return [fieldsCategories, condsCategories]
}, [fields, conditionalTexts, inlinesOnly, lang])

export default useTemplateEntities
