import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
// reducers
import UserDataReducer from './reducer_user'
import OrganisationsReducer from '../components/Organisations/redux/OrganisationsReducer'
import TemplateEditorReducer from '../components/Template/redux/TemplateReducer'
import ContractReducer from '../components/Contract/redux/ContractReducer'
import LoginReducer from '../components/LoginRegistration/redux/loginReducer'

import { DataTableReduxReducer } from '../components/SharedComponents/DataTable'
import RestService from '../RestService'

let appReducer = null
export default (state, action) => {
  //  Best way to clean the redux state is wrap all reducers in a root reducer with a cleanup
  //  From: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store#35641992
  if (action.type === 'USER_LOGOUT') {
    //  Prevent RestService errors
    RestService.cancelNetwork()

    state = undefined

    //  Delete all cookies
    const cookies = document.cookie.split(';')

    for (let index = 0; index < cookies.length; index += 1) {
      const cookie = cookies[index]
      const eqPos = cookie.indexOf('=')
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
    }

    //  Delete all storages
    if (window.localStorage) { window.localStorage.clear() }
    if (window.sessionStorage) { window.sessionStorage.clear() }


    //  Segment cleanup
    if (window.analytics) {
      window.analytics.reset()
    }
  }

  if (!appReducer) {
    appReducer = combineReducers({
      contract: ContractReducer,
      dataTable: DataTableReduxReducer,
      login: LoginReducer,
      organisation: OrganisationsReducer,
      template: TemplateEditorReducer,
      user: UserDataReducer
    })
  }
  return appReducer(state, action)
}
