import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { Trans } from '@lingui/macro'

import { AuditOutlined, EditOutlined, FileOutlined, StopOutlined } from '@ant-design/icons'
import { saveInStore, useContractsCollection, useTemplatesCollection } from '@top-legal/datastore'
import RestService from '../../RestService'
import { loadContractFieldResponses } from '../Organisations/redux/OrganisationsActions'
import loadingModalFeedback from '../Alert/LoadingModalFeedback'
import { duplicateTemplate } from '../Template/redux/TemplateActions'
import { ContractContext, TemplateContext } from './ContractEditor/Contexts'
import { SectionsContext } from './ContractEditor/editorRefactoredPart/sectionComponents/ContractEditorSection'
import { checkSection } from '../Template/_TemplateHelperFunctions'
import { useClausesStore } from './ContractEditor/editorRefactoredPart/sectionComponents/Clause'


// ==================================================================================================


// ==================================================================================================


export const contractStatusOptions = {
  imported: {
    text: <Trans>Imported</Trans>,
    color: 'gold',
    icon: <FileOutlined />
  },
  filling: {
    text: <Trans>Filling</Trans>,
    color: 'blue',
    icon: <EditOutlined />
  },
  editing: {
    text: <Trans>Editing</Trans>,
    color: 'geekblue',
    icon: <FileOutlined />
  },
  frozen: {
    text: <Trans>Signature pending</Trans>,
    color: 'orange',
    icon: <AuditOutlined />
  },
  signed: {
    text: <Trans>Signed</Trans>,
    color: 'green',
    icon: <AuditOutlined />
  },
  rejected: {
    text: <Trans>Rejected</Trans>,
    color: 'red',
    icon: <StopOutlined />
  }
}

// ==================================================================================================


// ==================================================================================================


export const UNKNOWN_STATUS = '__UNKNOWN_STATUS__'
Object.defineProperty(contractStatusOptions, UNKNOWN_STATUS, {
  enumerable: false,
  configurable: true,
  value: {
    text: <Trans>Unknown</Trans>,
    color: 'grey',
    icon: 'question'
  }
})


// ==================================================================================================


// ==================================================================================================


export const ensureTemplateSections = (template, sectionsCollection) => async (dispatch, getState) => {
  const { templateID } = template
  const { contractID, templateID: contractTemplateID } = getState().contract.contractEditing

  //  Save network request for that special template
  if (templateID === 'template-blank-pdf-signing') {
    return
  }

  const sections = await RestService(
    'GET',
    `/template/${templateID}/sections?contractID=${templateID === contractTemplateID ? contractID || '' : ''}`
  )
  //  Save in store
  await saveInStore(sectionsCollection, sections)

  //  This is the best place to load any clause attached to the sections (we background load them, the UI is skeleton
  ;(async () => {
    const clauses = {}
    sections.forEach(section => { const clauseID = section?.clauseID; if (clauseID) { clauses[clauseID] = true } })
    const clauseIDs = Object.keys(clauses)
    if (clauseIDs.length > 0) {
      // eslint-disable-next-line no-console
      console.info('Going to load clauses', clauseIDs)

      const fetchedClauses = {}
      const loadClauses = async (lastKey?: string) => {
        const res = await sectionsCollection.database.__replication__.query(`
          query loadClauses($clauseIDs: [String!]!, $lastItem: String) {
            Get {
              searchClauses(clauseIDs: $clauseIDs, lastKey: $lastItem) {
                items {
                  clauseID
                  title
                  content
                }
                lastKey
              }
            }
          }
        `, { clauseIDs, lastKey })

        const { items, lastKey: nextKey } = res?.data?.Get?.searchClauses ?? {}

        if (!Array.isArray(items)) {
          console.error('Could not load clauses, BE response:', res)
        }

        items.forEach(clause => { fetchedClauses[clause.clauseID] = clause })

        return nextKey ? loadClauses(nextKey) : undefined
      }
      await loadClauses()
      useClausesStore.setState(old => ({ ...old, clauses: { ...old.clauses, ...fetchedClauses } }))
    }
  })().finally(() => {
    useClausesStore.setState({ loading: false })
  })

  //  Delay a bit the release
  await new Promise(resolve => setTimeout(resolve, 500))
}


// ==================================================================================================


// ==================================================================================================


export const useDeletePlaybook = (): (templateID: string) => Promise<void> => {
  const { lang } = useParams()
  const history = useHistory()
  const templatesCollection = useTemplatesCollection()

  return useCallback((templateID: string) => loadingModalFeedback({
    loadingTitle: <Trans>Deleting your playbook</Trans>,
    successTitle: <Trans>Playbook deleted</Trans>,
    errorTitle: <Trans>Failed to delete your playbook</Trans>,
    errorDescription: <Trans>An error occurred while deleting your playbook. Please try again later.</Trans>,
    autoSuccessClose: 1000,
    onSuccessClose: () => history.push(`/${lang}/listing/templates`)
  })(async () => {
    await RestService('DELETE', `/template/${templateID}`)
    let oldTemplate = await templatesCollection.findOne(templateID).exec() as any
    if (oldTemplate) {
      oldTemplate = oldTemplate.toJSON()
      oldTemplate.deleted = true
      await saveInStore(templatesCollection, oldTemplate)
    }
  }), [history, lang, templatesCollection])
}


// ==================================================================================================


// ==================================================================================================


export const useDuplicatePlaybook = (): (templateID: string) => Promise<void> => {
  const { lang } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const templatesCollection = useTemplatesCollection()

  return useCallback((templateID: string) => loadingModalFeedback({
    loadingTitle: <Trans>Duplicating your playbook</Trans>,
    successTitle: <Trans>Playbook duplicated</Trans>,
    errorTitle: <Trans>Failed to duplicate your playbook</Trans>,
    errorDescription: <Trans>An error occurred while duplicating your playbook. Please try again later.</Trans>,
    autoSuccessClose: 1000
  })(async () => {
    const template = await dispatch(duplicateTemplate(templateID))
    template.role = 'Owner'
    await saveInStore(templatesCollection, template)

    //  Hack to force refetch fields & conds after duplicates playbook
    const replication = (templatesCollection.database as any).__replication__
    if (replication) {
      await Promise.allSettled([
        replication.queryFields('', '', true),
        replication.queryConditionals('', '', true)
      ])
    }

    //  Then redirect to playbook
    history.push(`/${lang}/templates/${template.templateID}`)
  }), [dispatch, history, lang, templatesCollection])
}


// ==================================================================================================


// ==================================================================================================


export const fullLoadContract = contract => async dispatch => {
  await dispatch(loadContractFieldResponses(contract.contractID))
  return contract
}


// ==================================================================================================


// ==================================================================================================

/**
 * provides the name of the section to the template state for the template editor and contract
 * assistant. Contract/template data is currently coming from Redux. Basically this function does
 * the numbering of each section to make sure the numbering is correct, if sections are not
 * displayed (cause they are dynamic).
 *
 * 1. Preamble (static)
 * 2. Whatever (static)
 * 3. Dynamic (dynamic) => Disable
 *   Something (static)
 */
export const useComputeSectionMapping = () => {
  const { contract } = useContext<any>(ContractContext)
  const { template, setSectionMapping, readOnly: isContractAssistant } = useContext<any>(TemplateContext)
  const fieldsRef = useRef()
  fieldsRef.current = template.fields
  const sections = useContext(SectionsContext)
  const { clauses } = useClausesStore()

  //  Computing the section display path using short sectionID (sectionID = template-xxxx-yyyy shortID = yyyy)
  useEffect(() => {
    //  In case of uploaded contract template has no header/footer so then fallback
    const sectionMapping = {
      [(template.header || '__HEADER__').split('-').pop()]: 'Header',
      [(template.footer || '__FOOTER__').split('-').pop()]: 'Footer'
    }

    const processSections = (array, parentPath: number[] = [], prefix = '') => {
      if (Array.isArray(array)) {
        let index = 1
        array.forEach(({ sectionID, subSections }) => {
          const section = sections.get(sectionID)

          //  Here the section is shown when it is template editor OR contract flow and dynamic condition is respected
          if (section && (!isContractAssistant || checkSection(section, { fields: fieldsRef.current }, contract))) {
            const clauseTitle = clauses[(section as any).clauseID]?.title
            const newPath = [...parentPath, index]
            index += 1
            sectionMapping[sectionID.split('-').pop()] = `${prefix}${newPath.join('.')}. ${clauseTitle || section.name || ''}`

            if (Array.isArray(subSections)) {
              processSections(subSections, newPath)
            }
          }
        })
      }
    }

    processSections(template.sections, [], (template.sectionPrefix && `${template.sectionPrefix} `) || '')
    processSections(template.annexes, [], (template.annexePrefix && `${template.annexePrefix} `) || '')

    setSectionMapping(sectionMapping)
  }, [
    isContractAssistant, setSectionMapping, sections, contract, clauses,
    template.sections, template.annexes, template.sectionPrefix, template.annexePrefix, template.header, template.footer
  ])
}


// ==================================================================================================


// ==================================================================================================


export const useDuplicateContract = (): (contractID: string) => Promise<void> => {
  const { lang } = useParams()
  const history = useHistory()
  const contractsCollection = useContractsCollection()

  return useCallback((contractID: string) => loadingModalFeedback({
    loadingTitle: <Trans>Creating a new contract</Trans>,
    successTitle: <Trans>Contract Created</Trans>,
    errorTitle: <Trans>Failed to create a new contract</Trans>,
    autoSuccessClose: 1000
  })(async () => {
    const contract = await RestService('POST', `/contract/${contractID}/duplicate`)
    contract.role = 'Owner'
    await saveInStore(contractsCollection, contract)

    //  Then redirect to new contract
    setTimeout(() => history.push(`/${lang}/contracts/${contract.contractID}`), 300)
  }), [history, lang, contractsCollection])
}
