import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { Editor as SlateEditor, Node, Transforms } from 'slate'
import { PlateEditor, PlatePlugin, TEditor } from '@udecode/plate-core'
import * as Y from 'yjs'
import { slateYjsOriginSymbol as slateYjsSymbol } from 'slate-yjs'
import uniqid from 'uniqid'

import EditorContext, { Decorate } from '../EditorContext'
import Editor, { EditorProps } from '../Editor'
import useSlateYjsPlugin, { SlateYjsPluginReturn } from './SlateYjsPlugin'
import EditorContractContext from './EditorContractContext'
import { Delta, DELTA_MARK, deltaToRanges, DeltaTree } from './YjsDelta'
import { DeltaNode } from '../customPluginsComponents/DeltaComponent'
import { editorAddDefaultContent, isEmpty } from '../../core'

type SaveSectionUpdate = (
  update: string,
  delta: DeltaTree | undefined,
  //  The parent is deciding when to go next
  //  Then it can do some preview and keep the old one or apply the next one
  //  In case of failure it can also reset to the old one
  setSnapshot: (prev?: boolean) => void,
  dateUpdated: string
) => void

export interface SectionEditorProps extends Omit<EditorProps, 'editorID' | 'value' | 'onChange' | 'forceFullEditor'> {
  sectionID: string
  yDoc: string | null | undefined
  dateUpdated: string
  saveUpdate?: SaveSectionUpdate
  initialDeltas?: Delta
  resetRef?: number
  setIsEmptyContent?: (bool: boolean) => void
}


interface SectionEditorInnerProps extends Omit<EditorProps, 'editorID' | 'value' | 'onChange'> {
  editorID: string
  sectionPlugin: PlatePlugin
  getDelta: SlateYjsPluginReturn['getDelta']
  editorRef: React.MutableRefObject<PlateEditor>
  init: Node[]
}


const SectionEditorInner: React.FC<SectionEditorInnerProps> = ({
  editorID,
  sectionPlugin,
  getDelta,
  editorRef,
  init,
  ...props
}) => {
  const { redliningEnabled } = useContext(EditorContractContext)
  const [nodes, setNodes] = useState<Node[]>(init)


  /** ******************************************************************
   *       Custom plugin with saving handler & redlining
   ****************************************************************** */
  const deltas = useMemo(() => {
    if (redliningEnabled) {
      return deltaToRanges(getDelta(), { children: nodes } as any)
    }
    return undefined
  }, [getDelta, nodes, redliningEnabled])
  const deltasRef = useRef(deltas)
  deltasRef.current = deltas

  const suggestionPluginPart: Partial<PlatePlugin> = useMemo(() => {
    if (redliningEnabled) {
      return {
        //  Inject node deletion by wrapping the children
        inject: {
          props: {},
          // eslint-disable-next-line react/display-name
          aboveComponent: () => ({ element, children }) => {
            const deltaNode = deltasRef.current?.[0].get(element)

            if (deltaNode) {
              return <DeltaNode {...deltaNode} editorRef={editorRef}>{children}</DeltaNode>
            }

            return children
          }
        },
        plugins: [{
          key: DELTA_MARK,
          isLeaf: true,
          isInline: true
        }]
      } as Partial<PlatePlugin>
    }
    return {}
  }, [editorRef, redliningEnabled])


  /** ******************************************************************
   *       Decorate plugin listening for changes & deltas
   ****************************************************************** */
  const decorate: Decorate | undefined = useMemo(() => {
    if (deltas) {
      return ([, path]) => (path.length === 0 ? deltas[1] : [])
    }
    return undefined
  }, [deltas]) // Need to listen nodes change


  /** ******************************************************************
   *              Finally build the final plugin
   ****************************************************************** */
  const plugin = useMemo<PlatePlugin>(
    () => ({ ...sectionPlugin, ...suggestionPluginPart }),
    [sectionPlugin, suggestionPluginPart]
  )


  /** ******************************************************************
   *              Bind the plugin to the context
   ****************************************************************** */
  const editorContext = useContext(EditorContext)
  const finalPlugins = useMemo(() => [...editorContext.plugins, plugin], [editorContext.plugins, plugin])
  const finalDecorate = useMemo(() => {
    if (decorate) {
      return [...(editorContext.decorates || []), decorate]
    }
    return editorContext.decorates
  }, [editorContext.decorates, decorate])
  const finalContext = useMemo<typeof editorContext>(() => ({
    ...editorContext,
    plugins: finalPlugins,
    decorates: finalDecorate
  }), [editorContext, finalPlugins, finalDecorate])

  return (
    <EditorContext.Provider value={finalContext}>
      <Editor
        {...props}
        editorID={editorID}
        noContentCheck
        onChange={setNodes}
        value={nodes}
      />
    </EditorContext.Provider>
  )
}


/** ******************************************************************
 *                 Section Editor
 ****************************************************************** */
const SectionEditor: React.FC<Omit<SectionEditorProps, 'dateUpdated'>> = ({
  sectionID,
  yDoc = null,
  saveUpdate,
  initialDeltas,
  resetRef,
  setIsEmptyContent,
  ...props
}) => {
  const editorIDPrefix = useMemo(() => `${uniqid()}--${sectionID}`, [sectionID])
  const { userID, redliningEnabled, debounceSavingDelay = 5000 } = useContext(EditorContractContext)

  //  Managing incoming yDoc and change internal state when needed
  const reloadRef = useRef(0)
  useMemo(() => {
    reloadRef.current += 1
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yDoc, initialDeltas && initialDeltas.patch, userID, redliningEnabled, resetRef])
  const editorID = `${editorIDPrefix}--${reloadRef.current}`

  const {
    slateYjsPlugin,
    setInitialSnapshot,
    getDelta,
    getSnapshot,
    applySnapshotPatch,
    setCurrentUser,
    undoManager,
    prevSnapshotRef,
    getContent
  } = useSlateYjsPlugin(editorID, undefined, [reloadRef.current])
  const initSnapshotRef = useRef<Y.Snapshot>(Y.emptySnapshot)

  const timeoutRef = useRef<NodeJS.Timeout | undefined>()
  const isSavingRef = useRef(false)
  const editorRef = useRef<PlateEditor>({} as any)


  /** ******************************************************************
   *       Apply the Yjs doc update & get the init nodes
   ****************************************************************** */
  const init = useMemo(() => {
    if (yDoc) {
      applySnapshotPatch(yDoc)
    }

    //  Define the snapshot and reset history
    setInitialSnapshot()
    undoManager.clear()

    //  If any initial deltas apply them
    if (redliningEnabled && initialDeltas) {
      applySnapshotPatch(initialDeltas.patch, slateYjsSymbol)
    }

    //  Then set the current user
    setCurrentUser(userID)

    //  Capture the point where real editing would start
    const tmp = prevSnapshotRef.current
    setInitialSnapshot()
    initSnapshotRef.current = prevSnapshotRef.current
    prevSnapshotRef.current = tmp

    const content = getContent()
    setIsEmptyContent?.(isEmpty(content))
    return content
  }, [
    applySnapshotPatch, getContent, setCurrentUser, setInitialSnapshot, setIsEmptyContent, undoManager,
    redliningEnabled, initialDeltas, prevSnapshotRef, userID, yDoc
  ])
  const prevSavedNodesRef = useRef<Node[]>(init)


  /** ******************************************************************
   *       Saving handler
   ****************************************************************** */
  //  A boolean to for saving in internal usage
  const forceSaveRef = useRef(false)
  const save = useCallback((editor: TEditor<any>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = undefined

    if (!isSavingRef.current && (editor.children !== prevSavedNodesRef.current || forceSaveRef.current)) {
      prevSavedNodesRef.current = editor.children
      isSavingRef.current = true
      forceSaveRef.current = false

      //  Hold current state in case of fail
      const prevSnapshot = prevSnapshotRef.current

      //  Try to save the update
      try {
        //  Get the simplified update buffer for remote
        const delta = getDelta(initSnapshotRef.current)
        const update = getSnapshot()

        //  Capture the snapshot to capture next update
        setInitialSnapshot()
        const nextSnapshot = prevSnapshotRef.current
        prevSnapshotRef.current = prevSnapshot

        if (update) {
          //  The reset of the snapshot is belong to the parent (see doc on type)
          const dateUpdated = (new Date()).toISOString()
          setIsEmptyContent?.(isEmpty(editor.children))
          saveUpdate?.(update, delta, prev => {
            if (prev) {
              prevSnapshotRef.current = prevSnapshot
            } else {
              prevSnapshotRef.current = nextSnapshot
            }
          }, dateUpdated)
        }
      } catch (err) {
        console.error('Got error while saving Yjs section state', err)
      }

      isSavingRef.current = false
    }
  }, [getDelta, getSnapshot, prevSnapshotRef, saveUpdate, setInitialSnapshot])


  /** ******************************************************************
   *     WithOverrides in charge of Yjs part & debounce saving
   ****************************************************************** */
  const withOverrides: PlatePlugin['withOverrides'] = useCallback(editor => {
    //  Bind the init state to the editor
    editor.children = init

    //  OnChange handler
    {
      const { onChange } = editor
      editor.onChange = () => {
        if (editor.ignoreContentUpdate) {
          prevSavedNodesRef.current = editor.children
        } else {
          //  Save timeout
          if (debounceSavingDelay > 0) {
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current)
              timeoutRef.current = undefined
            }

            if (!isSavingRef.current && (editor.children !== prevSavedNodesRef.current || forceSaveRef.current)) {
              timeoutRef.current = setTimeout(() => save(editor), debounceSavingDelay)
            }
          }
        }

        onChange?.()
      }
    }


    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const wrapped = slateYjsPlugin.withOverrides(editor)

    //  Load initial data into the editor
    prevSavedNodesRef.current = wrapped.children


    //  Reset of the value when the section if broken or we delete everything
    {
      const checkEditorContent = (forceSave = true) => {
        if (isEmpty(wrapped.children)) {
          setTimeout(() => {
            SlateEditor.withoutNormalizing(wrapped, () => {
              if (wrapped.children.length === 1) {
                Transforms.removeNodes(wrapped, { at: [0] })
              }
              if (wrapped.children.length === 0) {
                editorAddDefaultContent(wrapped)
              }
            })
            //  I need to save the ref to avoid recursively destruct and construct new children
            prevSavedNodesRef.current = wrapped.children
            //  I need to force save that new content
            forceSaveRef.current = forceSave
          })
        }
      }

      const { onChange } = wrapped
      wrapped.onChange = () => {
        if (!wrapped.ignoreContentUpdate && prevSavedNodesRef.current !== wrapped.children) {
          checkEditorContent()
        }
        onChange?.()
      }

      //  Check the editor content at first run
      checkEditorContent(false)
    }

    //  Save editor ref
    editorRef.current = wrapped

    return wrapped
  }, [init, slateYjsPlugin, debounceSavingDelay, save])


  const sectionPlugin: PlatePlugin = useMemo(() => ({
    ...slateYjsPlugin,
    withOverrides
  }), [slateYjsPlugin, withOverrides])

  return (
    <SectionEditorInner
      {...props}
      key={editorID} // Mount a fresh editor when we need to rebuild the plugins
      editorID={editorID}
      editorRef={editorRef}
      forceFullEditor={redliningEnabled && initialDeltas != null}
      getDelta={getDelta}
      init={init}
      sectionPlugin={sectionPlugin}
    />
  )
}

/**
 * Wrap the section editor with a YDoc update safety
 */
const SectionEditorYDocUpdateMemo: React.FC<SectionEditorProps> = ({ yDoc, dateUpdated, saveUpdate, ...props }) => {
  const yDocRef = useRef(yDoc)
  const dateRef = useRef([dateUpdated, dateUpdated, dateUpdated, dateUpdated, dateUpdated, dateUpdated, dateUpdated, dateUpdated, dateUpdated, dateUpdated])

  //  Update the yDoc only when the dateUpdated is not known
  useMemo(() => {
    if (!dateRef.current.includes(dateUpdated)) {
      yDocRef.current = yDoc
      dateRef.current.pop()
      dateRef.current.unshift(dateUpdated)
    }
  }, [dateUpdated, yDoc])

  //  Wrap saving to catch the new dateUpdated
  const saveUpdateWrapped = useCallback<NonNullable<SectionEditorProps['saveUpdate']>>((update, delta, setter, date) => {
    dateRef.current.pop()
    dateRef.current.unshift(date)
    saveUpdate?.(update, delta, setter, date)
  }, [saveUpdate])

  return <SectionEditor {...props} saveUpdate={saveUpdateWrapped} yDoc={yDocRef.current} />
}

export default SectionEditorYDocUpdateMemo
