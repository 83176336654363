import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Select, SelectProps } from 'antd'


type GoogleFonts = Record<string, string>

interface FontSelectorProps {
  previewText?: string
}

export interface GoogleFontSelectorProviderProps {
  fontList?: string[] //  Restrict to only these fonts
  fontCategories?: string[]
  variants?: string
  subsets?: string
  nunitoSansAsDefault?: boolean
}


const googleApiKey = 'AIzaSyCd5fu7-LFDgmyZI_n4zHeoTlGX70G5yUE'
const fontListUrl = `https://www.googleapis.com/webfonts/v1/webfonts?key=${googleApiKey}&sort=alpha`
const fontUrl = 'https://fonts.googleapis.com/css'


/** *************************************************************************
 *              Helper to download fonts from Google API
 ************************************************************************** */
const download = (url: string): Promise<any> => axios({
  url,
  method: 'GET',
  responseType: 'json'
}).then(res => {
  //  We get a response from backend if it's good return data if not reject it
  if (res.status === 200) {
    return res.data
  }
  return Promise.reject(res)
}).catch(async err => {
  console.error('RestService.download error -', err)
  throw err
})


//  Internal context
const GoogleFontsContext = React.createContext<GoogleFonts>(undefined as any)


/** *************************************************************************
 *                 Google font selector is a select
 ************************************************************************** */
const empty = {}
export const GoogleFontSelector: React.FC<SelectProps & FontSelectorProps> = ({ previewText, ...props }) => {
  const googleFonts = useContext(GoogleFontsContext) || empty

  if (process.env.NODE_ENV === 'development' && googleFonts === empty) {
    throw new Error('Missing GoogleFontSelectorProvider in one of the parent to operate!')
  }

  return (
    <Select {...props}>
      {Object.entries(googleFonts).map(([family, displayName]) => (
        <Select.Option key={family} value={family}><span style={{ fontFamily: `'${family}'` }}>{previewText || displayName}</span></Select.Option>
      ))}
    </Select>
  )
}


/** *************************************************************************
 *          Hook for gathering the fonts & provide selector
 ************************************************************************** */
export const GoogleFontSelectorProvider: React.FC<GoogleFontSelectorProviderProps> = ({
  fontList, fontCategories, variants, subsets,
  nunitoSansAsDefault, children
}) => {
  const [googleFonts, setGoogleFonts] = useState<GoogleFonts>({})

  //  Loading Google Fonts
  useEffect(() => {
    const googleFontsCssElem: HTMLLinkElement[] = []
    download(fontListUrl)
      .then(data => {
        if (Array.isArray(data.items)) {
          const fonts: GoogleFonts = nunitoSansAsDefault ? { 'Nunito Sans': 'Nunito Sans (default)' } : {}
          data.items.forEach(font => {
            if (
              (!fontCategories || fontCategories.includes(font.category)) //  Check font family if set
              && (!fontList || fontList.includes(font.family)) //             Check font is in the list if set
              && (!variants || font.variants.includes(variants)) //           Check font variant if set
              && (!subsets || font.subsets.includes(subsets)) //              Check font subsets if set
              && !fonts[font.family] //                                       Avoid duplicate entries
            ) {
              fonts[font.family] = font.family
              const css = document.createElement('link')
              googleFontsCssElem.push(css)
              css.setAttribute('rel', 'stylesheet')
              css.setAttribute('href', `${fontUrl}?family=${font.family.replace(/ /g, '+')}:400,500,600${subsets ? (`&subset=${subsets}`) : ''}&display=swap`)
              document.head.appendChild(css)
            }
          })
          setGoogleFonts(fonts)
        }
      })
    return () => {
      googleFontsCssElem.forEach(elm => elm.remove())
    }
  }, [nunitoSansAsDefault, fontCategories, subsets, variants, fontList])

  return <GoogleFontsContext.Provider value={googleFonts}>{children}</GoogleFontsContext.Provider>
}
