import React, { useContext, useMemo } from 'react'
import { useQueryParam, NumberParam, withDefault } from 'use-query-params'
import { MessageOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'

import { PublishModalContext } from '../../Contract/ContractPublish/ContractPublishPage'
import {
  CommentsToolboxConfig,
  CommentsToolboxConfigContext,
  CommentsToolboxProps
} from '../../Contract/ContractEditor/Sidebar/SidebarToolboxes/Commenting/Types'
import { CommentsPadderWrapper } from '../../Contract/ContractEditor/Sidebar/SidebarToolboxes/Commenting/CommentsToolbox'
import PartComment from '../../Contract/ContractEditor/Sidebar/SidebarToolboxes/Commenting/PartComment'
import PhoneCommentsToolbox from '../../Contract/ContractEditor/Sidebar/SidebarToolboxes/Commenting/PhoneCommentsToolbox'
import { ContractContext } from '../../Contract/ContractEditor/Contexts'

/** **************************************************************
 *                Custom comments toolbox
 ************************************************************** */
const config: CommentsToolboxConfig = { noTask: true }
const CustomCommentsToolbox: React.FC<CommentsToolboxProps> = ({ currentBoxRef, switchToolbox }) => {
  const { contract: { contractID } } = useContext<any>(ContractContext)
  const { partIDsOrder, activePartsComments, historyPartsComments } = useContext(PublishModalContext)

  const [pageSize] = useQueryParam('pageSize', withDefault(NumberParam, 0))
  const [activePage] = useQueryParam('activePage', withDefault(NumberParam, 1))
  const chunk = useMemo(
    () => partIDsOrder.slice(pageSize * (activePage - 1), pageSize * activePage),
    [activePage, pageSize, partIDsOrder]
  )

  return (
    <CommentsToolboxConfigContext.Provider value={config}>
      <CommentsPadderWrapper>
        {chunk.map(sectionID => activePartsComments[sectionID] && (
          <PartComment
            key={sectionID}
            currentBoxRef={currentBoxRef}
            historyNode={historyPartsComments[sectionID]}
            instanceID={contractID}
            instanceType='contract'
            node={activePartsComments[sectionID]}
            side='external'
            switchToolbox={switchToolbox}
          />
        ))}
      </CommentsPadderWrapper>
    </CommentsToolboxConfigContext.Provider>
  )
}

const commentsToolboxInstance = {
  key: 'comments',
  className: 'commentsToolbox',
  text: <Trans>Comments</Trans>,
  fullSize: true,
  icon: <MessageOutlined />,
  // eslint-disable-next-line react/display-name
  Component: props => <CustomCommentsToolbox {...props} />,
  // eslint-disable-next-line react/display-name
  PhoneComponent: ({ currentBoxRef, switchToolbox }) => {
    const { contract: { contractID } } = useContext<any>(ContractContext)

    return (
      <PhoneCommentsToolbox
        currentBoxRef={currentBoxRef}
        instanceID={contractID}
        instanceType='contract'
        switchToolbox={switchToolbox}
      />
    )
  }
}

export default commentsToolboxInstance
