import { applyMiddleware, compose, createStore } from 'redux'
import createRootReducer from './reducers/reducers'
import thunk from 'redux-thunk'

// only use the compose enhancer if available
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const composeEnhancer = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true }))
  || compose

export const store = createStore(
  createRootReducer,
  composeEnhancer(applyMiddleware(thunk))
)
;(window as any).__store__ = store
