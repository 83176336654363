import React, { useEffect, useMemo, useRef } from 'react'
import { t, Trans } from '@lingui/macro'
import { Form, FormInstance, Input } from 'antd'

import { ProductField, ProductFieldResponse } from '@top-legal/editor'

import { InputNumber } from '../../../../../Template/TemplateHelperFunctions'

interface ProductCompletionInputProps {
  fieldKey: string
  products: ProductField[]
  value?: ProductFieldResponse
  onChange?: (values: ProductFieldResponse) => void
}

const ProductsCompletionInput: React.FC<ProductCompletionInputProps> = ({
  fieldKey, products, value, onChange
}) => {
  const [isValid, fields] = useMemo(() => {
    let bool = true
    const arr: any[] = []

    products.forEach((product, index) => {
      if (!product.isMeteredUsage) {
        bool = bool && !!value && value[index] > 0
      }

      arr.push(
        <tr key={index}>
          <td><h4>{product.productName} {product.unitLabel || <Trans>units</Trans>}</h4></td>
          <td>
            {product.isMeteredUsage ? (
              <Input className='simple-line' disabled value={t`Usage based`} />
            ) : (
              <InputNumber
                className='simple-line'
                min={1}
                onChange={val => onChange?.({ ...value, [index]: val })}
                value={value?.[index]}
              />
            )}
          </td>
        </tr>
      )
    })

    return [bool, arr]
  }, [onChange, products, value])

  const formRef = useRef<FormInstance>(null) as React.MutableRefObject<FormInstance>
  useEffect(() => {
    if (formRef.current && fieldKey) {
      if (isValid || value) {
        formRef.current.setFields([{
          name: [fieldKey],
          value: value || {},
          touched: true,
          validating: false,
          errors: (isValid ? [] : [<Trans key='error'>Please complete all the needed values above.</Trans>]) as any
        }])
      }
    }
  }, [fieldKey, isValid, value])

  return (
    <Form.Item noStyle shouldUpdate>
      {form => {
        formRef.current = form as any
        return (
          <div className='productCompletionInput'>
            <table>{fields}</table>
          </div>
        )
      }}
    </Form.Item>
  )
}

export default ProductsCompletionInput
