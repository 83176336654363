import React, { useContext } from 'react'
import { Trans } from '@lingui/macro'
import { Divider } from 'antd'
import moment from 'moment'

import { ContractContext } from '../../Contexts'

const TransactionAudit = () => {
  const { contract } = useContext<any>(ContractContext)
  const signed = contract && contract.contractStatus === 'signed'
  const fileHash = contract && contract.signaturesHolder && contract.signaturesHolder.pdfHash
  const signedDate = contract && contract.signedDate
  const pdfPagesNumber = contract && contract.pdfPagesNumber

  if (contract && signed) {
    return (
      <div className='top-wrapper'>
        <div className='audit-top'>
          <span className='userStatus done'><Trans>Completed</Trans></span>
        </div>
        <Divider />
        <div className='audit-instanceName'>
          <div className='transaction-trail-title'>
            <Trans>File Name</Trans>
          </div>
          <div className='transaction-data'>{contract.contractName}</div>
        </div>
        <Divider />

        <div className='audit-fileHash'>
          <div className='transaction-trail-title'>
            <Trans>Envelope ID / file Hash</Trans>
          </div>
          <div className='transaction-data'>{fileHash}</div>
        </div>
        <Divider />

        <div className='audit-classification'>
          <div className='transaction-trail-title'>
            <Trans>Classification</Trans>
          </div>
          <div className='transaction-data'>Advanced Electronic Signature</div>
        </div>
        <Divider />

        <div className='audit-doneDate'>
          <div className='transaction-trail-title'>
            <Trans>Completed On</Trans>
          </div>
          <div className='transaction-data'>
            {moment(signedDate).format('ll')}
          </div>
        </div>
        {pdfPagesNumber
        && (
          <> <Divider />
            <div className='transaction-trail-title'><Trans>Document Page Number</Trans></div>
            <div className='transaction-data'>{pdfPagesNumber}</div>
          </>
        )}
        <Divider />
        <div className='transaction-trail-title'><Trans>Audit Trail</Trans></div>
      </div>
    )
  }
  return null
}

export default TransactionAudit
