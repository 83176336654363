/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import React, { useCallback, useMemo, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { t, Trans } from '@lingui/macro'
import moment from 'moment'
import { Button, Empty, Progress, Tabs } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

import './TimeToCloseStyles.scss'

const day = 1000 * 3600 * 24

const TimeToClose = ({ data }) => {
  const [history, setHistory] = useState(6)

  const labels = useMemo(() => {
    const tmp = []
    for (let counter = 0; counter < history; counter++) {
      tmp.push(moment().subtract(history - counter - 1, 'month').format('MMM-YY'))
    }
    return tmp
  }, [history])

  const getTimeToClose = useCallback((month, year) => {
    const fillingDurations = {}
    const pendingDurations = {}
    let avgfillingTime = 0
    let avgpendingTime = 0
    data.forEach(contract => {
      const signedDate = new Date(contract.signedDate)
      if (signedDate && signedDate.getMonth() === month && signedDate.getFullYear() === year) {
        if (contract.fillingDuration && contract.pendingDuration) {
          fillingDurations[contract.contractID] = contract.fillingDuration
          avgfillingTime += contract.fillingDuration / day
          pendingDurations[contract.contractID] = contract.pendingDuration
          avgpendingTime += contract.pendingDuration / day
        }
      }
    })
    if (avgfillingTime > 0 && avgpendingTime > 0) {
      avgfillingTime /= (Object.keys(fillingDurations).length)
      avgpendingTime /= (Object.keys(pendingDurations).length)
    }
    return {
      fillingDurations, pendingDurations, avgfillingTime, avgpendingTime
    }
  }, [data])

  const allChartsData = useMemo(() => {
    const pendingData = []
    const fillingData = []
    const globalTimeData = []
    for (let counter = 0; counter < history; counter++) {
      const date = moment().subtract(history - counter - 1, 'month')
      const datas = getTimeToClose(parseInt(date.format('M'), 10) - 1, parseInt(date.format('YYYY'), 10))
      pendingData.push(Math.round(datas.avgpendingTime))
      fillingData.push(Math.round(datas.avgfillingTime))
      globalTimeData.push(Math.round(datas.avgpendingTime + datas.avgfillingTime))
    }
    return { pendingData, fillingData, globalTimeData }
  }, [history, getTimeToClose])

  const currentDate = new Date()
  const currentObject = getTimeToClose(currentDate.getMonth(), currentDate.getFullYear())

  const chartData = {
    datasets: [{
      label: t`Negotiation Time`,
      data: allChartsData.fillingData,
      borderColor: '#EC932F',
      backgroundColor: '#EC932F',
      hoverBackgroundColor: '#EC932F',
      hoverBorderColor: '#EC932F',
      fill: false
    }, {
      label: t`Signature Time`,
      data: allChartsData.pendingData,
      borderColor: '#5678a4',
      backgroundColor: '#5678a4',
      hoverBackgroundColor: '#5678a4',
      hoverBorderColor: '#5678a4',
      fill: false
    }, {
      label: t`Time to Close`,
      data: allChartsData.globalTimeData,
      borderColor: ' #3dbd7d',
      backgroundColor: '#3dbd7d',
      hoverBackgroundColor: '#3dbd7d',
      hoverBorderColor: '#3dbd7d',
      fill: false
    }],
    labels
  }

  const option = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1000,
      easing: 'easeInCubic'
    },
    tooltips: {
      mode: 'index',
      intersect: false
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [{
        display: true
      }],
      yAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Duration in days'
        }
      }]
    }
  }

  return (
    <div className='timeToClose'>
      <Tabs defaultActiveKey='1'>
        <Tabs.TabPane key='current' style={{ height: 200 }} tab={<Trans>Current Month</Trans>}>
          {currentObject.avgfillingTime === 0 && <Empty className='noData' description={<Trans>No contract has been signed this month so far.</Trans>} />}
          {currentObject.avgfillingTime !== 0 && (
            <div className='globalTime'>
              <div className='globalTimeAvg'>
                <h2><Trans>{Math.round((currentObject.avgfillingTime + currentObject.avgpendingTime))} days</Trans></h2>
                <p className='description'><Trans>Average time spent to close contracts</Trans></p>
              </div>
              <div className='negotiationTime'>
                <Progress
                  percent={Math.floor(currentObject.avgfillingTime * 100 / (currentObject.avgfillingTime + currentObject.avgpendingTime))}
                  strokeColor={{
                    '0%': '#108ee9',
                    '100%': '#87d068'
                  }}
                  type='circle'
                  width={80}
                />
                <div className='timeCard'>
                  <h4><Trans>Negotiation Time</Trans></h4>
                  <p className='description'><Trans>Average time spent for negotiation: {Math.round((currentObject.avgfillingTime))} days</Trans></p>
                </div>
              </div>
              <div className='signatureTime'>
                <Progress
                  percent={Math.floor(currentObject.avgpendingTime * 100 / (currentObject.avgfillingTime + currentObject.avgpendingTime))}
                  strokeColor={{
                    '0%': '#108ee9',
                    '100%': '#87d068'
                  }}
                  type='circle'
                  width={80}
                />
                <div className='timeCard'>
                  <h4><Trans>Signature Time</Trans></h4>
                  <p className='description'><Trans>Average time spent waiting for signature: {Math.round((currentObject.avgpendingTime))} days</Trans></p>
                </div>
              </div>
            </div>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane key='history' tab='History'>
          <div className='historyChart'>
            {false && (
              <Button
                className='loadMore'
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  const nHistory = history
                  if (nHistory < 15) {
                    setHistory(nHistory + 3)
                  }
                }}
                shape='circle'
                size='small'
                type='ghost'
              />
            )}
            <Line
              data={chartData}
              options={option}
            />
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default {
  key: 'timeToClose',
  title: <Trans>Time to Close</Trans>,
  Component: TimeToClose,
  width: 4,
  height: 6
}
