import React, { useContext, useMemo, useState } from 'react'
import { Button, Tabs } from 'antd'
import { CloseOutlined, YoutubeOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'

import { isOrganisationFieldsContext } from '../SectionSearchToolbox'
import { ModalRefContext } from '../../../TemplateEntities/TemplateEntitiesForms/TemplateEntitiesFormsModal'
import { DisableEntitiesActions } from './useTemplateEntities'
import { LayoutSidebarWidthContext } from '../../../../../Layouts/LayoutSidebar'
import { ScrollbarWidthContext } from '../../../../../../GlobalContext'
import { TemplateContext } from '../../../Contexts'

import { StickyMainContent } from '../../../../../Layouts/MainLayout/MainContainer'
import Portal from '../../../../../SharedComponents/Portal'
import InfiniteScrolling from '../../../../../SharedComponents/ListingComponents/InfiniteScrolling'
import ButtonLink from '../../../../../SharedComponents/ButtonLink'

import { NoResultFound } from '../../../../../../AntDesignProvider'
import { cancelPropagation } from '../../../../../Defaults'
import { Category } from './Types'
import useTemplateEntitiesSearch, { processCategoryChildren, RenderItem, rowKey } from './useTemplateEntitiesSearch'


interface TemplateEntitiesDrawerProps {
  currentCategory: Category | undefined
  close: () => void
  cssSelector: string
}

/**
 * This has for goal to display the entities of a category, search from them and easily create a new one
 */
const TemplateEntitiesDrawer: React.FC<TemplateEntitiesDrawerProps> = ({
  currentCategory, close, cssSelector
}) => {
  const { modalRef } = useContext<any>(ModalRefContext)
  const width = useContext(LayoutSidebarWidthContext)
  const right = `${useContext(ScrollbarWidthContext)}px`
  const { template: { fields, conditionalTexts: conds } } = useContext<any>(TemplateContext)


  const [openedTab, setOpenedTab] = useState('playbook')
  const { search, searchBar, Highlight, setSearch } = useTemplateEntitiesSearch(!currentCategory, 600)


  const fromAll = useMemo(() => (currentCategory
    ? processCategoryChildren(currentCategory.children, search, Highlight)
    : undefined
  ), [currentCategory, search, Highlight])
  const fromPlaybook = useMemo(() => (fromAll
    ? fromAll.filter(({ key }) => {
      const [baseKey] = key.split('.')
      return fields[baseKey] || conds[baseKey]
    })
    : undefined
  ), [fromAll, fields, conds])

  return (
    <Portal cssSelector={cssSelector}>
      <StickyMainContent
        className={`fixedContent commentThread logicCategoryDrawer ${currentCategory ? 'open' : 'close'} ${currentCategory?.type || ''}`}
        onClick={cancelPropagation}
        style={{ width: `calc(${width} + ${right})` }}
      >
        <div className='logicCategoryDrawerHeader'>
          <div className='logicCategoryDrawerTitle'>
            <h2>{currentCategory?.icon || null} <Trans>{currentCategory?.name || ''} <Trans>Input Field</Trans></Trans></h2>
            <Button
              className='noBorder'
              ghost
              icon={<CloseOutlined />}
              onClick={() => {
                setOpenedTab('playbook')
                setSearch('')
                close()
              }}
            />
          </div>
          <p className='logicCategoryInstructions' id={`explanations-category-${currentCategory?.type}`}>
            <span><Trans>Instructions</Trans></span>
            <YoutubeOutlined />
          </p>
          <Button
            block
            onClick={evt => {
              evt.preventDefault()
              evt.stopPropagation()
              modalRef.current.createOrEditInputField(undefined, { type: currentCategory?.type })
            }}
            type='primary'
          >
            <Trans>Create {currentCategory?.name || ''}</Trans>
          </Button>
          {searchBar}
        </div>
        <isOrganisationFieldsContext.Provider value={true}>
          <Tabs activeKey={openedTab} onChange={setOpenedTab}>
            <Tabs.TabPane key='playbook' tab={<span><Trans>This Playbook</Trans> ({fromPlaybook?.length || 0})</span>}>
              {fromPlaybook && fromPlaybook.length > 0 && (
                <InfiniteScrolling
                  RenderItem={RenderItem}
                  items={fromPlaybook}
                  rowKey={rowKey}
                />
              )}
              {(!fromPlaybook || fromPlaybook.length === 0) && <NoResultFound />}
            </Tabs.TabPane>
            <Tabs.TabPane key='all' tab={<span><Trans>All Playbooks</Trans> ({fromAll?.length || 0})</span>}>
              {fromAll && fromAll.length > 0 && (
                <DisableEntitiesActions.Provider value={true}>
                  <InfiniteScrolling
                    RenderItem={RenderItem}
                    items={fromAll}
                    rowKey={rowKey}
                  />
                </DisableEntitiesActions.Provider>
              )}
              {(!fromAll || fromAll.length === 0) && <NoResultFound />}
            </Tabs.TabPane>
          </Tabs>
        </isOrganisationFieldsContext.Provider>
      </StickyMainContent>
    </Portal>
  )
}


export default TemplateEntitiesDrawer
