import React from 'react'
import { useParams } from 'react-router'
import { Trans } from '@lingui/macro'
import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import ExternalPageSwitch from '../ExternalViews/ExternalPageSwitch'
import { defaultCompany, defaultUser, UserAndCompanyDataContext } from '../Layouts/Constants'

const context = { user: defaultUser, company: defaultCompany }
const ContractExternalViewFromInside: React.FC = () => {
  const { tokenOrContractID } = useParams()

  return (
    <div className='externalViewFromInside'>
      <Button
        className='closePreviewButton'
        ghost
        icon={<CloseOutlined />}
        onClick={() => {
          localStorage.removeItem(`${tokenOrContractID}-data`)
          window.close()
        }}
        type='primary'
      >
        <Trans>Close preview</Trans>
      </Button>
      <UserAndCompanyDataContext.Provider value={context}>
        <ExternalPageSwitch />
      </UserAndCompanyDataContext.Provider>
    </div>
  )
}

export default ContractExternalViewFromInside
