import React, { useContext, useEffect, useState } from 'react'
import { Radio } from 'antd'
import { Trans } from '@lingui/macro'
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons'

//  Contexts
import { IdPrefixContext } from '../ContractDiplay/ContractLayout'
import { ContractContext } from './Contexts'
import { ExternalContext } from '../../ExternalViews/ExternalContext'

//  Components
import ButtonLink from '../../SharedComponents/ButtonLink'
import { ConfirmButton } from '../../SharedComponents/FormComponents/index'
import Pdf from '../../Media/Pdf'

//  Styles
import './ContractAdditionalDocumentsStyles.scss'
import { RemToPxContext } from '../../../GlobalContext'

const contractWrapperSizeInRem = 45 - 0.6 // Of margin

const ContractAdditionalDocuments: React.FC<{ propertyKey?: string }> = ({ propertyKey = 'additionalDocuments' }) => {
  const remToPx = useContext(RemToPxContext)
  const { tokenData } = useContext(ExternalContext)
  const { contract, updateContract } = useContext<any>(ContractContext)
  const idPrefix = useContext<string>(IdPrefixContext)
  const array = contract && contract[propertyKey]

  /**
   * Displaying pdf below
   */
  if (Array.isArray(array) && array.length > 0) {
    return array.map(({ key, url }, index) => (
      <div key={key} className='contractAdditionalDocument' id={`${idPrefix}${key}`}>
        <div className='actions'>
          {
            (!tokenData || !tokenData.token) && !['frozen', 'signed'].includes(contract.contractStatus) && (
              <ConfirmButton
                confirmMessage={<Trans>Are you sure you want to delete this additional pdf?</Trans>}
                danger
                ghost
                icon={<DeleteOutlined />}
                onClick={async () => {
                  const newArray = [...array]
                  newArray.splice(index, 1)
                  await updateContract({ [propertyKey]: newArray })
                }}
                placement='topRight'
              />
            )
          }
        </div>
        <Pdf url={url} width={contractWrapperSizeInRem * remToPx} />
      </div>
    )) as any
  }

  return null
}

export const PdfContract: React.FC<{ url: string, noDownload?: boolean }> = ({ url, noDownload }) => {
  const remToPx = useContext(RemToPxContext)

  return (
    <div className='contractAdditionalDocument'>
      {!noDownload && (
        <div className='actions'>
          <ButtonLink
            ghost href={url} icon={<DownloadOutlined />}
            noRouter target='_blank' type='primary'
          />
        </div>
      )}
      <Pdf url={url} width={contractWrapperSizeInRem * remToPx} />
    </div>
  )
}

//  Microsoft Office
const microsoftViewer = 'https://view.officeapps.live.com/op/embed.aspx?src='
//  Google doc
const googleViewer = 'https://docs.google.com/gview?embedded=true&url='

export const DocxContract: React.FC<{ url: string }> = ({ url }) => {
  const [visible, setVisible] = useState(false)
  const [viewer, setViewer] = useState(microsoftViewer)
  useEffect(() => {
    setTimeout(() => setVisible(true), 100)
  }, [])

  return (
    <div className='contractAdditionalDocument'>
      <div className='actions'>
        <ButtonLink
          ghost
          href={url}
          icon={<DownloadOutlined />}
          noRouter
          target='_blank'
          type='primary'
        />
      </div>
      <Radio.Group
        onChange={evt => {
          setVisible(false)
          setViewer(evt.target.value)
          setTimeout(() => setVisible(true), 100)
        }} value={viewer}
      >
        <Radio.Button value={microsoftViewer}><Trans>Microsoft viewer</Trans></Radio.Button>
        <Radio.Button value={googleViewer}><Trans>Google viewer</Trans></Radio.Button>
      </Radio.Group>
      {visible && (
        <iframe
          frameBorder='0'
          height='100%'
          src={`${viewer}${encodeURIComponent(url)}`}
          width='100%'
        />
      )}
    </div>
  )
}

export default ContractAdditionalDocuments
