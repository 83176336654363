import React, { useCallback, useContext, useRef, useState, useEffect } from 'react'
import { DeviceContext, RemToPxContext } from '../../GlobalContext'
import './LayoutSidebarStyles.scss'
import useBrowserStorage from '../../hooks/useBrowserStorage'
import { getCoords } from '../Contract/ContractEditor/Sidebar/SidebarToolboxes/Commenting/CommentsToolbox'

interface LayoutSidebarProps {
  sidebarName: string
  initialPercentage?: number
  minSize?: number
  position: 'left' | 'right'
  isOpen?: boolean
  className?: string
}

export const LayoutSidebarWidthContext = React.createContext('0')

const minRem = 18
const defaultRem = 22
const maxRem = 28

const LayoutSidebar: React.FC<LayoutSidebarProps> = ({
  sidebarName,
  className = '',
  position,
  isOpen,
  children
}) => {
  const isSmall = useContext(DeviceContext) === 'phone'
  const [stateClass, setStateClass] = useState('close')
  const [size, setSize] = useBrowserStorage(`LayoutSidebar-${sidebarName}`, defaultRem, window.localStorage)

  const remToPx = useContext(RemToPxContext)
  const remRatioRef = useRef(remToPx)
  remRatioRef.current = remToPx

  const prevRem = useRef(size)
  prevRem.current = size
  const divRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>
  const moveHandler = useCallback((evt: MouseEvent) => {
    let deltaMove = getCoords(divRef.current).left - evt.clientX
    if (position === 'left') {
      deltaMove = -deltaMove - divRef.current.offsetWidth
    }

    deltaMove /= remRatioRef.current

    const value = Math.max(minRem, Math.min(maxRem, prevRem.current + deltaMove))

    if (value !== prevRem.current) {
      setSize(value)
    }
  }, [position, setSize])

  /**
   * Giving the open/close function
   */
  useEffect(() => {
    setStateClass(old => {
      if (isOpen) { // Closed before now opened
        return 'open'
      }
      if (old === 'open') { // Opened before now closing
        setTimeout(() => setStateClass('close'), 300) // Wait for the animation before completely close it
        return 'semi-close'
      }
      return 'close'
    })
  }, [isOpen])

  const width = `${size}rem`

  return (
    <div
      ref={divRef}
      className={`layoutSidebar ${position} ${className} ${stateClass}`}
      style={{ width, '--sidebar-width': width } as any}
    >
      <LayoutSidebarWidthContext.Provider value={width}>{children}</LayoutSidebarWidthContext.Provider>
      {!isSmall && (
        <div
          className='divider'
          onMouseDown={() => {
            window.addEventListener('mousemove', moveHandler)
            divRef.current.style.transition = 'none'

            const stop = () => {
              divRef.current.style.transition = ''
              window.removeEventListener('mousemove', moveHandler)
              window.removeEventListener('mouseup', stop)
            }
            window.addEventListener('mouseup', stop)
          }}
        />
      )}
    </div>
  )
}

export default LayoutSidebar
