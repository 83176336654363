import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Tooltip } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'

import { Section } from '@top-legal/datastore'
import { Delta, DeltaTree, SectionEditorProps } from '@top-legal/editor'

import ContractEditorSectionContext, { ContractEditorSectionContextProps, UseContractEditorActions } from './ContractEditorSectionContext'
import { ConfirmButton } from '../../../../SharedComponents/FormComponents'
import { saveComment } from '../../../../Organisations/redux/OrganisationsActions'
import { UserAndCompanyDataContext } from '../../../../Layouts/Constants'
import { SidebarStateContext } from '../../../../../ContractTemplateRoutes'
import { ContractContext, TemplateContext } from '../../Contexts'

type SuggestionSaving = (section: Section, delta: Delta) => Promise<any>

interface CurrentState {
  update: string
  delta: DeltaTree | undefined
}

const getUseContractSavingActions = (saveSuggestionRemote: SuggestionSaving): UseContractEditorActions => (
  rootKey,
  path,
  section,
  setSaving,
  setInitialDeltas,
  setResetRef
) => {
  //  Initial deltas part
  const { sectionDeltasMapping } = useContext<any>(TemplateContext)
  const currentDeltas = sectionDeltasMapping[section.sectionID]
  const prevDeltas = useRef<Delta>()
  const changeTimeoutRef = useRef<NodeJS.Timeout>()

  //  Redlining saving part
  const currentStateRef = useRef<CurrentState>()
  const [floatingButtons, setFloatingButtons] = useState<React.ReactNode>()
  const sectionRef = useRef(section)
  sectionRef.current = section


  //  Change the current deltas of the editor if we need it
  useMemo(() => {
    const changeDeltas = () => {
      changeTimeoutRef.current = undefined

      if (currentDeltas) {
        const { delta } = currentDeltas
        if (prevDeltas.current?.patch !== delta.patch) {
          prevDeltas.current = delta
          setInitialDeltas(delta)
          currentStateRef.current = undefined
          setFloatingButtons(undefined)
        }
      } else if (prevDeltas.current) {
        prevDeltas.current = undefined
        setInitialDeltas(undefined)
        currentStateRef.current = undefined
        setFloatingButtons(undefined)
      }
    }

    //  Debounce the set of new deltas since a re-rendering can throw multiple set deltas
    if (changeTimeoutRef.current) {
      clearTimeout(changeTimeoutRef.current)
    }
    changeTimeoutRef.current = setTimeout(changeDeltas, 1000)
  }, [currentDeltas, setInitialDeltas])


  //  Save section in store
  const savingRef = useRef(false)
  const buildBntRef = useRef<(suggestLoading?: boolean) => React.ReactNode>()
  const saveSuggestion = useCallback(async (): Promise<any> => {
    if (!savingRef.current && currentStateRef.current) {
      const { update, delta } = currentStateRef.current

      if (update && delta) {
        savingRef.current = true
        setFloatingButtons(buildBntRef.current?.(true))

        try {
          await saveSuggestionRemote(sectionRef.current, { patch: update, delta })
          setFloatingButtons(undefined)
          currentStateRef.current = undefined
        } catch (err) {
          console.error('Failed to saved a suggestion', err)
          setFloatingButtons(buildBntRef.current?.(false))
        }

        savingRef.current = false
      }
    }
  }, [])


  //  Cancel the current editing
  const cancelSuggestion = useCallback(() => {
    //  Discard our internal state
    currentStateRef.current = undefined
    //  Force reset of the editor
    setResetRef(old => old + 1)
    //  Remove buttons
    setFloatingButtons(undefined)
  }, [setResetRef])


  //  Build the floating buttons
  buildBntRef.current = useCallback((suggestLoading?: boolean) => (
    <>
      <Tooltip placement='left' title={<Trans>Save</Trans>}>
        <Button
          ghost
          icon={<CheckOutlined />}
          loading={suggestLoading}
          onClick={saveSuggestion}
          type='primary'
        />
      </Tooltip>
      <Tooltip placement='left' title={<Trans>Cancel</Trans>}>
        <ConfirmButton
          confirmMessage={<Trans>Are you sure you want to cancel your suggestion?</Trans>}
          danger
          ghost
          icon={<CloseOutlined />}
          loading={suggestLoading}
          onClick={cancelSuggestion}
          type='primary'
        />
      </Tooltip>
    </>
  ), [cancelSuggestion, saveSuggestion])


  //  We just need to track the current editor state
  const updateContent = useCallback<NonNullable<SectionEditorProps['saveUpdate']>>(async (update, delta) => {
    if (delta) {
      //  Add the saving and discard buttons to the floating area
      if (!currentStateRef.current) {
        setFloatingButtons(buildBntRef.current?.())
      }

      //  Save the current state
      currentStateRef.current = { update, delta }
    }
  }, [])


  return {
    updateContent,
    //  Save the potential suggestion when leaving the editing area
    onSectionDismount: saveSuggestion,
    // onEditingBlur: saveSuggestion,
    //  Render some floating button for the redlining
    floatingButtons
  }
}


//  Provide old comment saving for now
const useOldSaving = (): SuggestionSaving => {
  const { company } = useContext<any>(UserAndCompanyDataContext)
  const sidebarStateContext = useContext(SidebarStateContext)
  const { template, sectionDeltasMapping } = useContext<any>(TemplateContext)
  const { contract } = useContext<any>(ContractContext)
  const dispatch = useDispatch()

  const ref = useRef(sectionDeltasMapping)
  return useCallback<SuggestionSaving>(async (section, delta) => {
    const contractID = contract.contractID && contract.contractID !== '__preview__' && contract.contractID

    const comment = {
      delta,
      instanceType: contractID ? 'contract' : 'template',
      instanceID: contractID || template.templateID,
      childID: (ref.current?.[section.sectionID] || {}).childID || section.sectionID,
      sectionVersion: section.version || 1,
      organisationID: company.organisationID
    }

    await dispatch(saveComment(comment))

    if (sidebarStateContext.current?.openedToolbox !== 'comments') {
      sidebarStateContext.current?.switchToolbox('comments')
    }
  }, [company.organisationID, contract.contractID, dispatch, sidebarStateContext, template.templateID])
}


const ProvideContractSuggestionSaving: React.FC<{ saveSuggestion?: SuggestionSaving }> = ({
  saveSuggestion,
  children
}) => {
  const oldSaving = useOldSaving()
  const contractSavingContext = useMemo<ContractEditorSectionContextProps>(() => ({
    useContractEditorActions: getUseContractSavingActions(saveSuggestion || oldSaving)
  }), [saveSuggestion, oldSaving])

  return (
    <ContractEditorSectionContext.Provider value={contractSavingContext}>
      {children}
    </ContractEditorSectionContext.Provider>
  )
}

export default ProvideContractSuggestionSaving
