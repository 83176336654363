import React, { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react'
import { PlateEditor, PlateRenderElementProps, usePlateEditorState } from '@udecode/plate-core'

const FocussedEditor = React.createContext<[string | undefined, Dispatch<SetStateAction<string | undefined>>]>(
  [undefined, () => {}]
)

export const useFocusedEditor = (): PlateEditor | undefined => {
  const editorID = useContext(FocussedEditor)[0] || '__NO_EDITOR__'
  const editor = usePlateEditorState(editorID)

  if (editorID && editor) {
    return editor
  }

  return undefined
}

export const useEditorEvents = (editorID: string): React.TextareaHTMLAttributes<HTMLDivElement> => {
  const setEditor = useContext(FocussedEditor)[1]

  return useMemo(() => ({
    onFocus: () => setEditor(editorID),
    onBlur: () => setEditor(undefined)
  }), [editorID, setEditor])
}

export const FocussedEditorProvider: React.FC = ({ children }) => {
  const state = useState<string | undefined>(undefined)

  return <FocussedEditor.Provider value={state}>{children}</FocussedEditor.Provider>
}

//  TODO: Remove quick fix of https://github.com/udecode/plate/issues/1309#issuecomment-1010090559
export const WithEditorFocus: React.FC = ({ children }) => {
  const editorID = useContext(FocussedEditor)[0] || '__NO_EDITOR__'
  return <React.Fragment key={editorID}>{children}</React.Fragment>
}


export const StyleInjector: React.FC<{ className: string }> = ({ className, children }) => React.Children.map(
  children,
  (child: any) => React.cloneElement(child, {
    className: `${child.props.className} ${className}`
  })
) as any

const emptyProps = {}
export const createElementRender = (Elem: React.ElementType, extraProps?: (element: PlateRenderElementProps['element']) => any): React.FC<PlateRenderElementProps> => {
  const Render: React.FC<PlateRenderElementProps> = ({ attributes, className, element, children }) => (
    <Elem {...attributes} className={className} {...(extraProps ? extraProps(element) : emptyProps)}>{children}</Elem>
  )
  return Render
}
