import React from 'react'

export interface Search {
  search?: string
  Highlight?: React.FC<{ text: string }>
}

export interface Category {
  type: string
  icon: React.ReactNode
  name: React.ReactNode
  link: string
  children: any[]
}

export const Identity: React.FC<{ text: string }> = ({ text }) => (text || '') as any
