import React, { useMemo } from 'react'
import { t, Trans } from '@lingui/macro'
import { Form, Input, Tag } from 'antd'
import { requiredRules } from '../../SharedComponents/CustomFormWrapper'
import { Select } from '../../SharedComponents/FormComponents'
import { templateCategories } from '../TemplateHelperFunctions'
import { LANGS_AVAILABLE } from '../../../Routes'
import { TemplateSettingsChildProps } from '../TemplatePage'

const MetadataSettings: React.FC<TemplateSettingsChildProps> = ({ template }) => {
  const langs = useMemo(() => {
    const mapping = {}
    LANGS_AVAILABLE.forEach(language => {
      mapping[language] = language.toUpperCase()
    })
    return mapping
  }, [])


  return (
    <>
      <fieldset>
        <label><h3><Trans>Basic information</Trans></h3></label>
        <Form.Item
          initialValue={template.name}
          label={<Trans>Playbook name</Trans>}
          labelCol={{ span: 6 }}
          name='name'
          rules={requiredRules}
        >
          <Input className='simple-line' placeholder={t`Playbook name`} />
        </Form.Item>
        <Form.Item
          initialValue={template.category || undefined}
          label={<Trans>Playbook category</Trans>}
          labelCol={{ span: 6 }}
          name='category'
          rules={requiredRules}
        >
          <Select
            className='simple-line'
            items={templateCategories}
            label={element => (element.text ? <Tag color={element.color}>{element.text}</Tag> : null)}
            noSearch
            placeholder={t`Playbook category`}
          />
        </Form.Item>
        <Form.Item
          initialValue={template.lang}
          label={<Trans>Playbook lang</Trans>}
          labelCol={{ span: 6 }}
          name='lang'
          rules={requiredRules}
        >
          <Select
            className='simple-line'
            items={langs}
            noSearch
            placeholder={t`Playbook lang`}
          />
        </Form.Item>
      </fieldset>
      <fieldset>
        <label><h3><Trans>Playbook description (optional)</Trans></h3></label>
        <Form.Item
          initialValue={template.description}
          labelCol={{ span: 24 }}
          name='description'
          wrapperCol={{ span: 24 }}
        >
          <Input.TextArea
            autoSize={{
              minRows: 3,
              maxRows: 6
            }}
            className='simple-line'
            placeholder={t`Please enter a description for your playbook`}
          />
        </Form.Item>
      </fieldset>
    </>
  )
}

export default MetadataSettings
