import React, { useContext, useMemo, useRef } from 'react'

import { EnabledPlugins, getPlugins } from '../core/PlatePlugins'

import EditorContext from './EditorContext'
import { useSearch } from './toolbar/SearchBar'
import BalloonToolbar from './toolbar/BalloonToolbar'
import { getToolbarButtons } from './toolbar'
import { FocussedEditorProvider, WithEditorFocus } from './Helpers'
import EditorContractContext from './yjsEditor/EditorContractContext'


/** ******************************************************************
 *            Props & Defaults definitions
 ****************************************************************** */
export interface EditorWrapperProps {
  features?: Partial<Omit<EnabledPlugins, 'templateEntities'>>
  readOnly?: boolean
  inlinesOnly?: boolean
  noSearch?: boolean
  borderless?: boolean
}

const defaultEnabledPlugins: EnabledPlugins = {
  list: true,
  link: true,
  image: true,
  table: true,
  history: true
}


/** ******************************************************************
 *            Editor wrapper & context declaration
 ****************************************************************** */
const fakeEditorContractContext = { useEntityGetters: () => ({}) }
const EditorWrapper: React.FC<EditorWrapperProps> = ({
  features,
  readOnly,
  inlinesOnly,
  noSearch,
  borderless,
  children
}) => {
  const { useEntityGetters, onEntityAdded, onEntityRemoved } = useContext(EditorContractContext) || fakeEditorContractContext
  const { getField, getConditionalText } = (useEntityGetters as any)()

  const hasTemplateEntities = !!getField && !!getConditionalText
  const templateEntitiesRef = useRef<any>()
  templateEntitiesRef.current = {
    getField, getConditionalText, onEntityAdded, onEntityRemoved
  }

  //  Auto bind templateEntities plugin when possible
  const enabledPlugins = useMemo(() => {
    const features_: EnabledPlugins = { ...defaultEnabledPlugins, ...(features || {}) }
    if (hasTemplateEntities) {
      //  It needs to be a reference to the section to use the latest getter and to avoid recreating plugins all the time
      //  Not having the last reference make DnD of inline elements to be considered blocks before the new plugin come to the editor
      //  Ref solve both issues
      features_.templateEntities = {
        getField: (...args) => templateEntitiesRef.current.getField?.(...args),
        getCond: (...args) => templateEntitiesRef.current.getConditionalText?.(...args),
        onEntityAdded: (...args) => templateEntitiesRef.current.onEntityAdded?.(...args),
        onEntityRemoved: (...args) => templateEntitiesRef.current.onEntityRemoved?.(...args)
      }
    } else {
      delete features_.templateEntities
    }
    return features_
  }, [features, hasTemplateEntities])

  const { searchPlugin, decorateSearch, searchToolbarButton, searchBalloonButton, searchBar, fireSearchRef } = useSearch()

  const plugins = useMemo(() => {
    const plugins_ = getPlugins(enabledPlugins)

    if (!noSearch) {
      plugins_.push(searchPlugin)
    }

    return plugins_
  }, [enabledPlugins, noSearch, searchPlugin])

  const [toolbarFormats, balloonFormats] = useMemo(() => {
    const [toolbarFormats_, balloonFormats_] = getToolbarButtons(enabledPlugins)

    if (!noSearch) {
      toolbarFormats_.push(searchToolbarButton)
      balloonFormats_.push(searchBalloonButton)
    }

    return [toolbarFormats_, balloonFormats_]
  }, [enabledPlugins, noSearch, searchToolbarButton, searchBalloonButton])

  const decorates = useMemo(() => {
    if (!noSearch) {
      return [decorateSearch]
    }
    return undefined
  }, [decorateSearch, noSearch])

  const context = useMemo(() => ({
    plugins, decorates, readOnly, inlinesOnly, withSearch: !noSearch, fireSearchRef
  }), [plugins, decorates, readOnly, inlinesOnly, noSearch, fireSearchRef])

  return (
    <EditorContext.Provider value={context}>
      <FocussedEditorProvider>
        <div className={`editorWrapper ${borderless ? 'borderless' : ''}`}>
          {!readOnly && Array.isArray(toolbarFormats) && toolbarFormats.length && (
            <WithEditorFocus><div className='editorToolbar'>{toolbarFormats}</div></WithEditorFocus>
          )}
          <div className='editorSearchWrapper'>
            {!readOnly && !noSearch && searchBar}
            <div className='editorContentWrapper'>
              {!readOnly && Array.isArray(balloonFormats) && balloonFormats.length && (
                <WithEditorFocus><BalloonToolbar>{balloonFormats}</BalloonToolbar></WithEditorFocus>
              )}
              {children}
            </div>
          </div>
        </div>
      </FocussedEditorProvider>
    </EditorContext.Provider>
  )
}

export default EditorWrapper
