import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DatePicker as AntDatePicker, TimePicker as AntTimePicker } from 'antd'
// eslint-disable-next-line no-restricted-imports
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker'
import { Moment } from 'moment'

import { CustomInput } from '../Types'
import { dateFormat, dateFormatDB, timeFormatDB, parseDate, parseTime } from '../utils/TimeUtils'


interface DatePickerProps extends CustomInput<string> {
  inputYearMonthDate?: boolean
  isoDate?: boolean
}


export const DateInput: React.FC<DatePickerProps> = ({
  onChange,
  value,
  inputYearMonthDate,
  isoDate,
  ...props
}) => {
  const [mode, setMode] = useState<PickerDateProps<Moment>['mode']>(undefined)

  const changeHandler = useCallback<(val: Moment | null) => void>(val => {
    onChange?.((val && (isoDate ? val.toISOString() : val.format(dateFormatDB))) || undefined)
  }, [onChange, isoDate])

  const otherProps = useMemo(() => (inputYearMonthDate ? ({
    onBlur: () => setMode('year'),
    onPanelChange: (val, newMode) => {
      changeHandler(val)
      setMode(newMode)
    }
  }) : ({})), [changeHandler, inputYearMonthDate])
  useEffect(() => setMode(inputYearMonthDate ? 'year' : undefined), [inputYearMonthDate])

  return (
    <AntDatePicker
      {...props}
      {...otherProps}
      format={dateFormat}
      mode={mode}
      onChange={changeHandler}
      value={parseDate(value)}
    />
  )
}

export const TimeInput: React.FC<CustomInput<string>> = ({ onChange, value, ...props }) => {
  const changeHandler = useCallback<(val: Moment | null) => void>(val => {
    onChange?.((val && val.format(timeFormatDB)) || undefined)
  }, [onChange])

  return <AntTimePicker{...props} onChange={changeHandler} value={parseTime(value)} />
}
