const region = 'eu-central-1'

module.exports = {
  aws_project_region: region,

  /* Hosting to S3 (deploy of the app) */
  aws_content_delivery_bucket: 'top-legal-20190215215524-hostingbucket-dev',
  aws_content_delivery_bucket_region: 'eu-central-1',
  aws_content_delivery_url: 'https://d2z5dkaaara5cu.cloudfront.net',

  /* Auth config with cognito & oauth for external providers */
  Auth: {
    region,
    identityPoolId: 'eu-central-1:3afaad00-fe67-4729-b825-6a538ef64b3f',
    userPoolId: 'eu-central-1_mDKAYOjHb',
    userPoolWebClientId: '1p28i4r0daocn6lbkjpj0vm81',
    oauth: {
      domain: 'top-legal-dev.auth.eu-central-1.amazoncognito.com',
      scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'http://localhost:3000/en/login/',
      redirectSignOut: 'http://localhost:3000/en/login/',
      responseType: 'code'
    }
  },

  /* User storage to S3 */
  Storage: {
    AWSS3: {
      region,
      bucket: 'top-legal-user-storage-dev'
    }
  }
}
