import React, { useCallback, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'antd'
import { Trans } from '@lingui/macro'

import SelectContact from '../../../../InviteContactToContract/SelectContact'
import { addPartySigning } from '../../../../../redux/ContractActions'
import InstanceUsersContext from '../Context/InstanceUsersContext'


const InviteExternalFromParties: React.FC = () => {
  const { actionsDisabled, setActionsDisabled } = useContext(InstanceUsersContext)
  const dispatch = useDispatch()

  const [showInvite, setShowInvite] = useState(false)
  const [inviteLoading, setInviteLoading] = useState(false)

  return (
    <>
      <div className='buttonWrapper'>
        <Button
          className='ant-btn-external'
          disabled={actionsDisabled}
          loading={inviteLoading}
          onClick={() => setShowInvite(true)}
        >
          <Trans>Add external party</Trans>
        </Button>
      </div>

      {/* Select a contact to invite */}
      <SelectContact
        onCancel={() => setShowInvite(false)}
        onFinish={useCallback(async ({ partyID }) => {
          setActionsDisabled(true)
          setShowInvite(false)
          setInviteLoading(true)
          try {
            await dispatch(addPartySigning(partyID, true))
          } catch {}
          setInviteLoading(false)
          setActionsDisabled(false)
        }, [dispatch, setActionsDisabled])}
        // parentFilter={useCallback(({ partyID }) => !externRoles[partyID], [externRoles])}
        visible={showInvite && !actionsDisabled}
      />
    </>
  )
}

export default InviteExternalFromParties
