import React from 'react'
import { Trans } from '@lingui/macro'
import { Form, Radio } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import { TemplateSettingsChildProps } from '../TemplatePage'
import { requiredRules } from '../../SharedComponents/CustomFormWrapper'

const SignatureSettings: React.FC<TemplateSettingsChildProps> = ({ template }) => (
  <fieldset>
    <label><h3><Trans>Signing Mode</Trans></h3></label>
    <Form.Item
      initialValue={template.signingFlow || 'pinLess'}
      name='signingFlow'
      rules={requiredRules}
    >
      <Radio.Group className='radioCardChoice'>
        <Radio value='pinLess'>
          <h4><Trans>One click signing</Trans></h4>
          <p><Trans>A standard advanced signature signing verification.</Trans></p>
          <span className='activateChoice'><Trans>Activated</Trans> <CheckCircleFilled /></span>
        </Radio>
        <Radio value='phone'>
          <h4><Trans>Mobile Pin</Trans></h4>
          <p><Trans>A premium and higher factor authentication by your signatories.</Trans></p>
          <span className='activateChoice'><Trans>Activated</Trans> <CheckCircleFilled /></span>
        </Radio>
      </Radio.Group>
    </Form.Item>
  </fieldset>
)

export default SignatureSettings
