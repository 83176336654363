import React from 'react'
import { BulbOutlined } from '@ant-design/icons'

import './InfoBoxStyles.scss'

const InfoBox: React.FC = ({ children }) => (
  <div className='infoBox'>
    <BulbOutlined />
    <div className='infoContent'>{children}</div>
  </div>
)

export default InfoBox
