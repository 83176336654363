import { Alert, Button, Modal, Progress } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Trans } from '@lingui/macro'
import { useDispatch } from 'react-redux'
import { ArrowLeftOutlined } from '@ant-design/icons'
import Auth from '@aws-amplify/auth'
import Storage from '@aws-amplify/storage'
import { createMedia } from '../Organisations/redux/OrganisationsActions'
import './UploadAudioVideoFileStyles.scss'

const audioCodecs = {
  'audio/opus': '.opus',
  'audio/ogg': '.oga',
  'audio/mpeg': '.mp3'
}

const videoCodecs = {
  'video/webm': '.webm',
  'video/mp4': '.mp4'
}

const accept = [...Object.values(audioCodecs), ...Object.values(videoCodecs)]

interface UploadAudioVideoFileProps {
  visible : boolean
  next?: (mediaID: string) => void
  filePrefix: string
}

interface UploadedFile {
  fileKey?: string
  file?: File
  type: string
  url: string
  isObjectUrl: boolean
}

const UploadAudioVideoFile: React.FC <UploadAudioVideoFileProps> = ({ visible, next, filePrefix }) => {
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [modalOpen, setModalOpen] = useState(visible)
  const [viewRecorder, setViewRecroder] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploadStatus, setUploadStatus] = useState<'active' | 'success' | 'exception'>()
  const [uploadedFile, setUploadedFile] = useState<UploadedFile>()

  const getFileName = () => {
    if (uploadedFile && uploadedFile.file && uploadedFile.file.name) {
      const splited = uploadedFile.file.name.split('.')
      const ext = splited.pop() as string
      if (filePrefix) {
        splited.splice(0, 0, filePrefix) // Add prefix at beginning if exist
      }
      splited.push(ext) // dont forget to put back extension

      return splited.join('.')
    }
    return ''
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (visible) {
      setModalOpen(true)
    }
  }, [visible])


  const content = (
    <Alert
      description={(
        <div className='audioVideoImportModalContent'>
          <p>
            <Trans>
              You can import a video or audio file from your computer, but for compatibility reasons we ask you to use one of the following formats:
            </Trans>
          </p>
          <h2><Trans>Audio formats</Trans></h2>
          <ul>
            <li><Trans>Opus (.opus), Vorbis (.oga) or Mp3 (.mp3)</Trans></li>
          </ul>
          <h2 className='videoFormat'><Trans>Video formats</Trans></h2>
          <ul>
            <li><Trans>Webm container (.webm with vp9 video codec and opus audio codec)</Trans></li>
            <li><Trans>Mp4 container (.mp4 with h264 video codec and mp3 or acc audio codec)</Trans></li>
          </ul>
          <p><Trans>For any question, please don‘t hesitate to contact the top.legal support.</Trans></p>
          <p><Trans>Thank you for your kind consideration.</Trans></p>
        </div>
      )}
      message={<h1><Trans>Information about video or audio import.</Trans></h1>}
      showIcon
      type='info'
    />
  )

  // ==================================================================================================

  const onFinished = async file => {
    setUploadStatus('active')
    setUploadProgress(0)

    try {
      // uploading the document to the s3 bucket
      const user = await Auth.currentCredentials()
      const { key } = (await Storage.put(getFileName(), file, {
        level: 'protected',
        cacheControl: 'must-revalidate',
        contentType: file.type,
        progressCallback: ({ loaded, total }) => {
          setUploadProgress(Math.round((loaded * 100) / total))
        }
      }) as any)
      const reelKey = `protected/${user.identityId}/${key}`

      // Create a new hidden media
      setUploadStatus('success')
      setUploadedFile(prevValue => ({
        ...(prevValue as UploadedFile), file: undefined, fileKey: reelKey
      }))

      const media_ = await dispatch(createMedia({ type: file.type, fileKey: reelKey, hidden: true }))

      next?.(media_.mediaID)
    } catch (err) {
      setUploadStatus('exception')
      throw err
    }
  }

  const uploadFile = async () => {
    if (inputFileRef.current && inputFileRef.current.files && inputFileRef.current.files.length === 1) {
      const file = inputFileRef.current.files[0]
      const isAVideo = file.type.includes('video/')
      const isAAudio = file.type.includes('audio/')

      if (!isAAudio && !isAVideo) {
        (window as any).notification.error({
          message: <Trans>Invalid file type</Trans>,
          description: <Trans>We accept only audio or video files</Trans>
        })
      } else {
        setViewRecroder(true)
        setUploadedFile(prevValue => {
          if (prevValue && prevValue.isObjectUrl) {
            window.URL.revokeObjectURL(prevValue.url)
          }
          return {
            url: window.URL.createObjectURL(file), type: file.type, isObjectUrl: true, file
          }
        })
        onFinished(file)
      }
    }
  }

  return (
    <Modal
      className='smallModal formatSupportInfoModal'
      destroyOnClose
      footer={null}
      onCancel={() => setModalOpen(false)}
      visible={modalOpen}
      zIndex={10400}
    >
      {!viewRecorder && (
        <>
          {content}
          <Button.Group className='autoFit'>
            <Button className='buttonSelector' onClick={() => inputFileRef.current?.click()} type='ghost'>
              <Trans>Perfect, import a file</Trans>
            </Button>
            <Button className='recordSelector' onClick={() => setModalOpen(false)} type='primary'>
              <Trans>I prefer to use the recorder</Trans>
            </Button>
          </Button.Group>
        </>
      )}

      <input
        ref={inputFileRef} accept={accept.join(',')}
        onChange={uploadFile}
        style={{ display: 'none' }} type='file'
      />

      {uploadStatus && viewRecorder && (
        <div className='uploadInProgress'>
          <Progress percent={uploadProgress} status={uploadStatus} strokeColor='#3dbd7d' type='circle' />
          {uploadStatus === 'active' && <h2><Trans>Upload in progress</Trans></h2>}
          {uploadStatus === 'success' && <h2 className='done'><Trans>Upload done</Trans></h2>}
          {uploadStatus === 'exception' && <h2 className='failed'><Trans>Upload failed</Trans></h2>}
          <p><Trans>Please wait during the uploading of your content.</Trans></p>
          <div className='mediaUploadActions'>
            {uploadStatus === 'exception' && (
              <Button
                icon={<ArrowLeftOutlined />}
                onClick={() => setUploadStatus(undefined)}
                type='default'
              >
                <Trans>Back</Trans>
              </Button>
            )}
          </div>
        </div>
      )}
    </Modal>
  )
}

export default UploadAudioVideoFile
