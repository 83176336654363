import React from 'react'

import { Collection, Model, TypeConfiguration } from '../Types'
import DatabaseContext from '../DatabaseContext'


export interface EntityRef {
  type: 'inputField' | 'conditionalText' | 'sectionReference'
  key: string
}

export interface SectionDependency {
  //  Add the dateUpdated to avoid recomputing same data
  dateUpdated: string

  //  A dynamic section has a required field that make the section active or not
  field?: string

  //  Then a section might have entities under the content
  children?: EntityRef[]
}

export interface InputDependency {
  //  Some inputs like Combined List have also a content that can have entities
  children?: EntityRef[]
}

export interface CondDependency {
  //  A conditional text has a required field that make the section active or not
  field: string

  //  And the options of a conditional can lead to entities
  values: Record<string, EntityRef[]>
}


export interface TemplateFlaternisation extends Model {
  //  We need to have a unique ID and the dateUpdated from the template (to avoid compute it when unchanged)
  flatID: string
  dateUpdated: string

  //  Then we have our dependencies mapping
  fieldsDependencies: Record<string, InputDependency>
  condsDependencies: Record<string, CondDependency>
  sectionDependencies: Record<string, SectionDependency>

  //  And finally we have the order of the sections we go through
  sectionIDs: string[]
}


export interface TemplateFlaternisationDataset { 'template-flaternisation': TemplateFlaternisation[] }


const config: TypeConfiguration<TemplateFlaternisation> = {
  key: 'template-flaternisation',
  schema: {
    version: 0,
    primaryKey: 'flatID',
    type: 'object',
    properties: {
      flatID: {
        type: 'string'
      },
      dateUpdated: {
        type: 'string'
      },
      fieldsDependencies: {},
      condsDependencies: {},
      sectionDependencies: {},
      sectionIDs: {
        items: {
          type: 'string'
        },
        type: 'array'
      }
    }
  }
}

export const useTemplateFlaternisationsCollection = (): Collection<TemplateFlaternisation> => React.useContext(DatabaseContext)[config.key]

export default config
