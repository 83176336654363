import React, { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { t } from '@lingui/macro'

//  Contexts
import { ExternalContext } from '../ExternalContext'
import { ContractContext } from '../../Contract/ContractEditor/Contexts'
import { PublishModalContext } from '../../Contract/ContractPublish/ContractPublishPage'

//  Components
import ContractLayout from '../../Contract/ContractDiplay/ContractLayout'
import ContractEditorComponent from '../../Contract/ContractEditor/ContractEditorComponent'
import TableOfContent from '../../Contract/ContractEditor/TableOfContent/TableOfContent'

//  Actions
import { useComputeSectionMapping } from '../../Contract/Utils'
import { CHILDID_CHAR_SEPARATOR, StoreComment } from '../../Contract/ContractEditor/Sidebar/SidebarToolboxes/Commenting/Types'
import { useConstructCommentTrees } from '../../Contract/ContractEditor/Sidebar/SidebarToolboxes/Commenting/CommentsToolbox'
import { SectionsContext } from '../../Contract/ContractEditor/editorRefactoredPart/sectionComponents/ContractEditorSection'
import { noop } from '../../Defaults'


const ExternalEditor: React.FC = () => {
  useComputeSectionMapping()

  return (
    <ContractLayout LeftSidebar={TableOfContent}>
      <ContractEditorComponent />
    </ContractLayout>
  )
}

const EditorWrapper: React.FC = () => {
  const { tokenData } = useContext(ExternalContext)
  const sectionsMap = useContext(SectionsContext)
  const { contract: { contractID } } = useContext<any>(ContractContext)

  const storeComms = useSelector(useCallback(state => state.organisation.instanceComments[contractID], [contractID]))
  const reelComments = useMemo<StoreComment[]>(() => {
    const comms: StoreComment[] = (Object(storeComms) === storeComms ? Object.values(storeComms) : [])

    comms.forEach((comm: any) => {
      delete comm.external
      if (comm.childID) {
        comm.sectionVersion = (sectionsMap.get(comm.childID.split(CHILDID_CHAR_SEPARATOR)[0]) || {}).version || 1
      }

      if (comm.rate) {
        comm.description = comm.rate === 'accepted' ? t`Accepted` : t`Rejected`
      }
    })

    return comms
  }, [storeComms, sectionsMap])

  const [activePartsComments, historyPartsComments, , partIDsOrder] = useConstructCommentTrees(reelComments, false, true)

  return (
    <PublishModalContext.Provider
      value={{
        partIDsOrder,
        reelComments,
        activePartsComments,
        historyPartsComments,
        editing: tokenData.externalRole === 'redlining',
        now: '',
        close: noop,
        step: 0,
        setStep: noop
      }}
    >
      <ExternalEditor />
    </PublishModalContext.Provider>
  )
}

export default EditorWrapper
