import React from 'react'

//  Editor library
import { Delta } from '@top-legal/editor'

/** **************************************************************
 *            Constants & Types & Interfaces
 ************************************************************** */
export const CHILDID_CHAR_SEPARATOR = '_'

export interface IComment {
  organisationID: string
  instanceID: string
  childID: string
  userID: string
  date: string
  lastUpdated: string
}

export interface TextComment extends IComment {
  text: string
  external?: boolean
}

export interface TaskComment extends IComment {
  text: string
  assignedTo: string
  dueDate?: string
  done?: boolean
}

export interface YjsDeltaComment extends IComment {
  rate?: 'favorable' | 'neutral' | 'fallback' | 'rejected' | 'accepted'
  description?: string
  managedBy?: string
  managedDate?: string

  external?: boolean
  fromExternal?: boolean
  externalRate?: 'rejected' | 'accepted'
  externalyManagedBy?: string
  externalyManagedDate?: string

  inHistory?: boolean // For not rendering redline that are part of history
  sectionVersion: number

  delta: Delta
}

export interface FeedbackComment extends IComment {
  isFeedback: true
  text: string
  contractID: string
  fieldID?: string
  done?: boolean
}

export type Comment = TextComment | TaskComment | YjsDeltaComment | FeedbackComment
export type StoreComment = Comment

export interface CommentTreeCommentNode {
  comment?: Comment
  comments?: {
    [key: string]: CommentTreeCommentNode
  }
}

export interface CommentTreePartNode {
  part: any
  comments?: {
    [key: string]: CommentTreeCommentNode
  }
}

export interface CommentTree {
  [sectionID: string]: CommentTreePartNode
}

export interface CommentsToolboxProps {
  instanceType: string
  instanceID: string
  switchToolbox: (key: string) => void
  currentBoxRef: React.MutableRefObject<string>
}

export interface CommentsToolboxConfig {
  readOnly?: boolean
  noLink?: boolean
  noTask?: boolean
}

export interface CommentDisplayProps {
  node: CommentTreeCommentNode
  noThread?: boolean
  onClick?: () => void
}

export const CommentsToolboxConfigContext = React.createContext<CommentsToolboxConfig>({})
