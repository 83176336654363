import React from 'react'
import { PlateRenderElementProps } from '@udecode/plate-core'

import SelectedEntity from './SelectedEntity'
import { createElementRender } from '../Helpers'


export const Table: React.FC<PlateRenderElementProps> = ({
  element, attributes, className, children
}) => {
  const rows = [...children as any]
  let thead: any = null
  let tfoot: any = null

  if (element.withHeader) {
    const row = rows.shift()
    thead = <thead>{row}</thead>
  }

  if (element.withFooter) {
    const row = rows.pop()
    tfoot = <thead>{row}</thead>
  }

  return (
    <table {...attributes} className={`slate-table ${className || ''}`}>
      {thead}
      <tbody>{rows}</tbody>
      {tfoot}
    </table>
  )
}

export const TableRow: React.FC<PlateRenderElementProps> = createElementRender('tr')

export const TableCell: React.FC<PlateRenderElementProps> = ({ element, attributes, className, children }) => {
  const { ref, ...props } = attributes
  return <SelectedEntity {...props} Elem={element.type as any /* Would be th or td */} className={className} elmRef={ref}>{children}</SelectedEntity>
}
