import React, { useMemo, useState } from 'react'
import { Button, Col, Form, Row } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import { t, Trans } from '@lingui/macro'
import { useHistory } from 'react-router'

import { useLocalDoc } from '@top-legal/datastore'

import MainContainer from '../Layouts/MainLayout/MainContainer'
import { withUserData } from '../Layouts/AuthenticatedPage'
import CustomFormWrapper, { requiredRules } from '../SharedComponents/CustomFormWrapper'
import { GoogleAutoCompleteAddress, Select } from '../SharedComponents/FormComponents'

import ImageUploader from '../SharedComponents/FormComponents/ImageUploader'
import RESTService from '../../RestService'

import './Settings.scss'

const ImageUploaderT = ImageUploader as any

const langs = {
  en: 'English',
  de: 'Deutsch'
}

/**
 * component returns an element to set the user setting, ie. address and language
 */
const UserSettings: React.FC = () => {
  const [user, saveUser] = useLocalDoc('user')
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  //  We need the data prefill the form, lets just wait for it
  if (!user) {
    return null
  }

  const handleSubmit = async values => {
    setLoading(true)
    try {
      const prevLang = user.preferredLanguage
      const profileData = { ...user, ...values }
      const res = await RESTService('PUT', '/user/profile', { profileUpdateData: profileData })
      await saveUser(() => ({ ...profileData, ...res }))

      //  Redirect the user if the lang change
      if (prevLang !== values.preferredLanguage) {
        history.push(`/${values.preferredLanguage}/settings`)
      }
      setLoading(false)
    } catch (err) {
      console.error('Error when updating profile', err)
      setLoading(false)
      throw err
    }
  }

  return (
    <MainContainer
      topbarContent={
        <div className='topbarMainContent'>
          <SettingOutlined className='headerIcon' twoToneColor='#3DBD7D' />
          <h1 className='title'>
            <Trans>User settings</Trans>
          </h1>
        </div>
      }
    >
      <div className='settingsPage'>
        <CustomFormWrapper className='settingsForm' onSubmit={handleSubmit}>
          {form => (
            <>
              <Row className='avatarRow'>
                <Col md={12} xs={24}>
                  <legend>
                    <Trans>Your picture</Trans>
                  </legend>
                  <Form.Item initialValue={user.avatar} name='avatar' rules={requiredRules}>
                    <ImageUploaderT
                      crop={{ minZoom: 0.5, cropperProps: { restrictPosition: false } } as any}
                      filePrefix='UserAvatar'
                      storageLevel='protected'
                    />
                  </Form.Item>
                </Col>
              </Row>

              <fieldset>
                <legend>
                  <Trans>Your address</Trans>
                </legend>
                <GoogleAutoCompleteAddress form={form} initialValue={user} prefix='' required />
              </fieldset>

              <fieldset>
                <legend>
                  <Trans>Other user preferences</Trans>
                </legend>
                <Form.Item initialValue={user.preferredLanguage} name='preferredLanguage' rules={requiredRules}>
                  <Select className='simple-line' items={langs} placeholder={t`Preferred language`} />
                </Form.Item>
              </fieldset>
              <Form.Item>
                <Button
                  className='lastButton' ghost htmlType='submit' loading={loading}
                  style={{ marginTop: 40 }} type='primary'
                >
                  <Trans>Save</Trans>
                </Button>
              </Form.Item>
            </>
          )}
        </CustomFormWrapper>
      </div>
    </MainContainer>
  )
}

const Wrapped = props => {
  const Component = useMemo(() => withUserData(UserSettings), [])
  return <Component {...props} />
}

export default Wrapped
