import React, { useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { useQueryParam, StringParam } from 'use-query-params'
import { useDispatch } from 'react-redux'
import { t, Trans } from '@lingui/macro'
import {
  FileOutlined, MenuOutlined, NotificationOutlined, PlusCircleOutlined, PlusOutlined, QuestionCircleOutlined, WechatOutlined
} from '@ant-design/icons'
import {
  Button, Card, Drawer, Form, Input, Modal, notification, Tooltip
} from 'antd'

import RestService from '../../RestService'
//  Components
import CustomFormWrapper from '../SharedComponents/CustomFormWrapper'
import MainContainer from '../Layouts/MainLayout/MainContainer'
import { withUserData } from '../Layouts/AuthenticatedPage'

import { createColumn, PaginatedDataTable, updateElementInDataTable } from '../SharedComponents/DataTable'
//  Styles
import './SupportStyles.scss'
import Feedback from './Feedback'
import { DeviceContext } from '../../GlobalContext'

const Support = () => {
  const { lang } = useParams()
  const [loading, setLoading] = useState(false)
  const [newTicket, setNewTicket] = useState(false)
  const [tab = 'feedback', setTab] = useQueryParam('tab', StringParam)
  const dispatch = useDispatch()
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const ticketDescriptions = {
    en: `Hello top.legal support,
  
  I was on the page (URL) ___ when I try to do ___ and I faced to the following problem:
  __________________

  My environment is:
   - AppVersion: ${process.env.APP_VERSION}
   - Browser: ${window.get_browser_info()}
   - System: ${window.get_os_info().trim()}`,
    de: `Lieber top.legal-Support,
  
  Ich war auf der Seite (URL): __________________ 
  Ich habe versucht, folgende Aktionen durchzuführen: __________________ 
  Leider ist dabe folgendes Problem aufgetreten: __________________
  
  Mein IT-Setup ist:
   - AppVersion: ${process.env.APP_VERSION}
   - Browser: ${window.get_browser_info()}
   - System: ${window.get_os_info().trim()}`
  }

  const isPhone = useContext(DeviceContext) === 'phone'

  return (
    <MainContainer
      mainContentClass='supportPageWrapper'
      topbarContent={(
        <>
          <div className='topbarMainContent'>
            {!isPhone && (
              <>
                <WechatOutlined className='headerIcon' twoToneColor='#3DBD7D' /><h1 className='title'><Trans>top.legal support</Trans></h1>
                <Tooltip
                  placement='bottom'
                  title={(
                    <Trans>Contact us. We are here to solve your issues, listen to your feedback and enable your success.</Trans>
                  )}
                >
                  <QuestionCircleOutlined style={{ marginLeft: '1rem', fontSize: '1.1rem', cursor: 'pointer' }} />
                </Tooltip>
              </>
            )}
          </div>
          {!isPhone ? (
            <div className='topbarActions'>
              <Button.Group>
                <Button
                  ghost={tab !== 'feedback'}
                  href='javascript:void(0)'
                  icon={<NotificationOutlined />}
                  onClick={() => setTab('feedback')}
                  type='primary'
                >
                  <Trans>Feedback</Trans>
                </Button>
                <Button
                  ghost={tab !== 'tickets'}
                  href='javascript:void(0)'
                  icon={<FileOutlined />}
                  onClick={() => setTab('tickets')}
                  type='primary'
                >
                  <Trans>My tickets</Trans>
                </Button>
              </Button.Group>
            </div>
          ) : (
            <Button
              className='noBorder phoneSidebarDrawerButton'
              ghost
              icon={<MenuOutlined />}
              onClick={() => setSidebarOpen(true)}
              type='default'
            />
          )}
        </>
      )}
    >
      {isPhone ? (
        <Drawer
          className='phoneSidebarDrawer'
          onClose={() => setSidebarOpen(false)}
          placement='right'
          title={<h1 className='title'><Trans>top.legal support</Trans></h1>}
          visible={sidebarOpen}
          width='60vw'
          zIndex={1000}
        >
          <div className='sidebarToolboxButtons'>
            <Button
              className='noBorder'
              ghost
              hover
              href='javascript:void(0)'
              icon={<NotificationOutlined />}
              onClick={() => {
                setTab('feedback')
                setSidebarOpen(false)
              }}

            >
              <Trans>Feedback</Trans>
            </Button>
            <Button
              className='noBorder'
              ghost
              href='javascript:void(0)'
              icon={<FileOutlined />}
              onClick={() => {
                setTab('tickets')
                setSidebarOpen(false)
              }}
            >
              <Trans>My tickets</Trans>
            </Button>
          </div>
        </Drawer>
      ) : (
        <span />
      )}
      <div className='supportPage'>
        {tab === 'feedback' && <Feedback />}
        {tab === 'tickets' && (
          <>
            <div className='supportCardsWrapper'>
              <Card
                bordered={false}
                title={(
                  <div className='cardTitle'>
                    <FileOutlined />
                    <h3><Trans>Open new ticket</Trans></h3>
                  </div>
                )}
              >
                <span><Trans>Open a ticket if you face a problem with the top.legal application.</Trans></span>
                <Button
                  icon={<PlusCircleOutlined />}
                  onClick={() => setNewTicket(true)}
                  type='primary'
                >
                  <Trans>Ticket</Trans>
                </Button>
              </Card>
            </div>
            <PaginatedDataTable
              defaultView='list'
              isolatedScope
              loadMore={lastElement => RestService('get', `/utils/tickets?lastElement=${lastElement || ''}`)}
              noViewSwitcher
              rowKey={item => item.ticketID}
              tableKey='all-tickets'
            >
              {[
                createColumn({
                  columnKey: 'title',
                  columnName: <Trans>Ticket title</Trans>,
                  withSort: true
                }),
                createColumn({
                  columnKey: 'createAt',
                  columnName: <Trans>Created at</Trans>,
                  withSort: true,
                  withDate: true
                }),
                createColumn({
                  columnKey: 'updateAt',
                  columnName: <Trans>Updated at</Trans>,
                  withSort: true,
                  withDate: true
                }),
                createColumn({
                  columnKey: 'closedAt',
                  columnName: <Trans>Closed at</Trans>,
                  withSort: true,
                  withDate: true
                }),
                createColumn({
                  columnKey: 'hs_pipeline_stage',
                  columnName: <Trans>Status</Trans>,
                  withSort: true,
                  withTags: {
                    1: {
                      text: <Trans>Posted</Trans>,
                      color: 'geekblue'
                    },
                    2: {
                      text: <Trans>Replied</Trans>,
                      color: 'gold'
                    },
                    3: {
                      text: <Trans>In progress</Trans>,
                      color: 'blue'
                    },
                    4: {
                      text: <Trans>Solved</Trans>,
                      color: 'green'
                    },
                    archived: {
                      text: <Trans>Archived</Trans>,
                      color: '#b0b0ba'
                    }
                  }
                })
              ]}
            </PaginatedDataTable>
          </>
        )}
      </div>
      <Modal
        className='smallModal'
        footer={null}
        onCancel={() => setNewTicket(false)}
        style={{ width: 'auto !important', minWidth: '60vw' }}
        title={<Trans>Open a new ticket</Trans>}
        visible={newTicket}
      >
        <CustomFormWrapper
          layout='vertical'
          onSubmit={async values => {
            setLoading(true)
            try {
              const ticket = await RestService('POST', '/utils/tickets', values)
              dispatch(updateElementInDataTable('all-tickets', ticket.ticketID, 'ticketID', ticket))
              setNewTicket(false)
              notification.success({
                message: t`Ticket successfully opened.`,
                description: t`We will come back to you soon as possible.`
              })
              setLoading(false)
            } catch (err) {
              console.error('Error when open ticket', err)
              setLoading(false)
              throw err
            }
          }}
        >
          <Form.Item label={<Trans>Title</Trans>} name='title' required>
            <Input className='simple-line' placeholder={t`Ticket title`} />
          </Form.Item>
          <Form.Item
            initialValue={ticketDescriptions[lang] || ticketDescriptions.en}
            label={<Trans>Description</Trans>}
            name='description'
            required
          >
            <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} className='simple-line' placeholder={t`Please describe your case here`} />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              htmlType='submit'
              icon={<PlusOutlined />}
              loading={loading}
              type='primary'
            >
              <Trans>Submit ticket</Trans>
            </Button>
          </div>
        </CustomFormWrapper>
      </Modal>
    </MainContainer>
  )
}

const Wrapped = props => {
  const Component = useMemo(() => withUserData(Support), [])
  return <Component {...props} />
}

export default Wrapped
