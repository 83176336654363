import React, { useLayoutEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, Input, Modal, Steps } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import CustomFormWrapper from '../SharedComponents/CustomFormWrapper'
import './CreateNewMediaModalStyles.scss'
import ImageUploader from '../SharedComponents/FormComponents/ImageUploader'
import { createMedia } from '../Organisations/redux/OrganisationsActions'
import { Media } from './MediaLibrary'

interface CreateNewMediaModalprops {
  visible: boolean,
  media: 'image' | 'audioVideo',
  onFinish: (media: Media) => void,
  onCancel: () => void
}

const CreateNewMediaModal: React.FC<CreateNewMediaModalprops> = ({ visible, media, onFinish, onCancel }) => {
  const [step, setStep] = useState(0)
  const [formValues, setFormValues] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    if (!visible) {
      setFormValues({})
      setStep(0)
    }
  }, [visible])

  return (
    <Modal
      className='bigModal createMediaModal'
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      visible={visible}
      zIndex={10300}
    >
      <Steps current={step}>
        <Steps.Step title={<Trans>Upload</Trans>} />
        <Steps.Step title={<Trans>Describe</Trans>} />
      </Steps>
      {step === 0 && media === 'image' && (
        <div className='mediaImageUploadForm'>
          <CustomFormWrapper
            onSubmit={async ({ fileKey }) => {
              setFormValues(old => ({ ...old, fileKey: `protected/${fileKey}`, type: 'image' }))
              setStep(1)
            }}
          >
            <Form.Item name='fileKey' rules={[{ required: true, message: <Trans>Please provide a image file</Trans> }]}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <ImageUploader filePrefix='' storageLevel='protected' />
            </Form.Item>
            <div className='mediaUploadActions'>
              <span>&nbsp;</span>
              <Button htmlType='submit' icon={<ArrowRightOutlined />} type='primary'>
                <Trans>Continue</Trans>
              </Button>
            </div>
          </CustomFormWrapper>
        </div>
      )}
      {step === 1 && (
        <div className='mediaDescriptionForm'>
          <CustomFormWrapper
            labelCol={{ xs: { span: 24 }, md: { span: 8 } }}
            onSubmit={async values => {
              setLoading(true)
              try {
                const newMedia = await dispatch(createMedia({ ...formValues, ...values }))
                ;(window as any).notification.success({
                  message: <Trans>Media saved</Trans>
                })
                onFinish(newMedia)
                setLoading(false)
              } catch (err) {
                console.error('Failed to create a new media', err)
                setLoading(false)
                throw err
              }
            }}
            wrapperCol={{ xs: { span: 24 }, md: { span: 16 } }}
          >
            <Form.Item
              label={<Trans>Title</Trans>}
              name='title'
              rules={[{ required: true, message: <Trans>Please provide a title</Trans> }]}
            >
              <Input className='simple-line' />
            </Form.Item>
            <Form.Item
              label={<Trans>Description</Trans>}
              name='description'
              rules={[{ required: true, message: <Trans>Please provide a description</Trans> }]}
            >
              <Input.TextArea className='simple-line' rows={6} />
            </Form.Item>
            <Form.Item wrapperCol={{ xs: { span: 24 }, md: { span: 16, offset: 8 } }}>
              <Button htmlType='submit' loading={loading} type='primary'>
                <Trans>Save</Trans>
              </Button>
            </Form.Item>
          </CustomFormWrapper>
        </div>
      )}
    </Modal>
  )
}

export default CreateNewMediaModal
