import React, { useCallback, useMemo } from 'react'
import { Collection, Model, TypeConfiguration } from '../Types'
import { useList } from '../Helpers'
import DatabaseContext from '../DatabaseContext'

const emptyArray = []

export interface InputField extends Model {
  inputFieldID: string
  name: string
  question: string
  type: string

  //  Explanations
  explanationText?: any
  explanationVideo?: string

  //  List
  values?: any
  isMultipleChoices: boolean

  //  Combined list
  formattedText?: any
  displayLabel?: string
  previousListField?: string

  //  Product
  pricingModel?: string
  productName?: string
  productDescription?: string
  currency?: string
  unitLabel?: string

  ///   Standard pricing
  fixedPrice?: number

  ///   Package pricing
  unitPrice?: number
  unitNumber?: number

  ///   Graduated & Volume pricing
  pricingTiers?: any

  ///   Billing
  subscriptionType?: string
  billingPeriod?: string
  isMeteredUsage?: boolean
  tax?: number
  taxMode?: string
}

export interface InputFieldDataset { 'input_fields': InputField[] }

//  TODO: Migration is failing cannot change the schema today
const config: TypeConfiguration<InputField & any> = {
  key: 'input_fields',
  schema: {
    version: 1,
    primaryKey: 'inputFieldID',
    type: 'object',
    properties: {
      billingPeriod: {
        type: 'string'
      },
      currency: {
        type: 'string'
      },
      displayLabel: {
        type: 'string'
      },
      explanationText: {},
      fixedPrice: {
        type: 'number'
      },
      formattedText: {},
      inputFieldID: {
        type: 'string'
      },
      isMeteredUsage: {
        type: 'boolean'
      },
      isMultipleChoices: {
        type: 'boolean'
      },
      isVideoExplanation: {
        type: 'boolean'
      },
      name: {
        type: 'string'
      },
      previousListField: {},
      pricingModel: {
        type: 'string'
      },
      pricingTiers: {},
      productDescription: {
        type: 'string'
      },
      productName: {
        type: 'string'
      },
      question: {
        type: 'string'
      },
      subscriptionType: {
        type: 'string'
      },
      type: {
        type: 'string'
      },
      tax: {
        type: 'number'
      },
      taxMode: {
        type: 'string'
      },
      unitLabel: {
        type: 'string'
      },
      unitNumber: {
        type: 'number'
      },
      unitPrice: {
        type: 'number'
      },
      values: {}
    },
    required: [
      'inputFieldID',
      'name',
      'type'
    ]
  },
  migrationStrategies: {
    1: doc => doc
  }
}

export const useFieldsCollection = (): Collection<InputField> => React.useContext(DatabaseContext)[config.key] as Collection<InputField>

export type FieldsMapping = { [fieldID: string]: { [version: number]: InputField } }
export const useFieldsMapping = (): [FieldsMapping | undefined, boolean] => {
  const fieldsCollection = useFieldsCollection()
  return useList<InputField, any, FieldsMapping>(
    useMemo(() => fieldsCollection.find(), [fieldsCollection]),
    useCallback(fields => {
      const map: any = {}
      fields.forEach(field => {
        if (!map[field.inputFieldID]) { map[field.inputFieldID] = {} }
        map[field.inputFieldID][field.version || 1] = field.toJSON()
      })
      return map
    }, [])
  ) || emptyArray
}

export const inputsGQLQuery = (lastSyncDate: string, nextToken: string): string => `{
  Get {
    inputFields(lastUpdatedAt: "${lastSyncDate}", nextToken: "${nextToken}") {
      items {
        inputFieldID
        name
        question
        type
      
        # Explanations
        explanationText
        isVideoExplanation
      
        # List
        values
        isMultipleChoices
      
        # Combined list
        formattedText
        displayLabel
        previousListField
      
        # Product
        pricingModel
        productName
        productDescription
        currency
        unitLabel
      
        ## Standard pricing
        fixedPrice
      
        ## Package pricing
        unitPrice
        unitNumber
      
        ## Graduated & Volume pricing
        pricingTiers {
          max
          flat
          unit
        }
      
        ## Billing
        subscriptionType
        billingPeriod
        isMeteredUsage
        tax
        taxMode
      }
      nextToken
    }
  }
}`

export default config
