import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { Button, Drawer } from 'antd'

import { SidebarToolboxes } from './Sidebar'
import { ContractContext } from '../Contexts'
import { ExternalContext } from '../../../ExternalViews/ExternalContext'
import { noop } from '../../../Defaults'


interface PhoneSidebarContextProps {
  openSidebarRef: React.MutableRefObject<(open?: string | boolean) => void>
  ActionsButtons: React.ElementType<{ setCurrentToolbox: (key: string) => void }>
}

export const PhoneSidebarContext = React.createContext<PhoneSidebarContextProps>({
  openSidebarRef: { current: noop },
  ActionsButtons: noop
})

const DefaultPhoneComponent = ({
  currentBoxRef,
  openSidebarRef,
  switchToolbox,
  toolboxKey,
  className,
  Text,
  text,
  Component,
  CustomButton,
  actionText,
  phoneDrawerPlacement,
  doNotReturnMenu,
  ...props
}) => {
  const onClickRef = useRef<any>(null)
  let ActionButton: React.FC<any> = () => null
  if (CustomButton) {
    ActionButton = CustomButton
  } else if (actionText) {
    // eslint-disable-next-line react/display-name,no-unused-vars
    ActionButton = ({ onClickRef: _, ...buttonProps }) => <Button {...buttonProps}>{actionText}</Button>
  }

  return (
    <Drawer
      className={`phoneToolboxDrawer ${className}Drawer`}
      forceRender
      onClose={() => {
        switchToolbox(undefined)
        !doNotReturnMenu && openSidebarRef.current(true)
      }}
      placement={phoneDrawerPlacement || 'right'}
      title={<h1>{Text ? <Text /> : text}</h1>}
      visible={currentBoxRef.current === toolboxKey}
      zIndex={1000}
    >
      <ActionButton className='createNewButton' onClick={(...args) => (onClickRef.current || noop)(...args)} onClickRef={onClickRef} type='default' />
      <div className={className}>
        <Component
          {...props}
          key={toolboxKey}
          className={className}
          currentBoxRef={currentBoxRef}
          onClickRef={onClickRef}
          openSidebarRef={openSidebarRef}
          switchToolbox={switchToolbox}
          toolboxKey={toolboxKey}
        />
      </div>
    </Drawer>
  )
}

const PhoneSidebar: React.FC<any> = () => {
  const { tokenData } = useContext<any>(ExternalContext)
  const { openSidebarRef, ActionsButtons } = useContext<any>(PhoneSidebarContext)
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const { contract } = useContext<any>(ContractContext)
  const { toolboxes = [], defaultKey, defaultOpened } = useContext(SidebarToolboxes) || {}

  const [sidebar, setSidebar] = useQueryParam('sidebar', StringParam)

  const setCurrentToolbox = useCallback((open: string) => setSidebar(open), [setSidebar])

  const currentBoxRef = useRef<string | null | undefined>()
  currentBoxRef.current = sidebar

  useMemo(() => {
    openSidebarRef.current = (open = true) => {
      if (typeof open === 'string') {
        setSidebarOpen(false)
        setCurrentToolbox(open)
      } else {
        setSidebarOpen(open)
      }
    }
  }, [openSidebarRef, setCurrentToolbox])

  return (
    <>
      <Drawer
        className='phoneSidebarDrawer'
        forceRender
        onClose={() => setSidebarOpen(false)}
        placement='right'
        title={<h1 className='titleOfPhoneContract'>{tokenData?.instanceTitle || contract.contractName}</h1>}
        visible={sidebarOpen}
        width='60vw'
        zIndex={1000}
      >
        <div
          style={{
            paddingLeft: '0.5rem',
            width: '100%',
            color: 'grey',
            fontWeight: 'bold'
          }}
        >
          Access
        </div>
        <div style={{ padding: '1rem', width: '100%' }}>
          <div id='topbarExtraContent' />
        </div>
        <div className='sidebarMainActions'>
          <ActionsButtons
            setCurrentToolbox={key => {
              setSidebarOpen(false)
              setCurrentToolbox(key)
            }}
          />
        </div>
        <div className='sidebarToolboxButtons'>
          {toolboxes.map(({ key, Text, text, icon }) => (
            <Button
              key={key}
              className='noBorder'
              ghost
              icon={icon}
              onClick={() => {
                setSidebarOpen(false)
                setCurrentToolbox(key)
              }}
            >
              {Text ? <Text /> : text}
              {key === 'chat' && <div id='notificationDot' />}
            </Button>
          ))}
        </div>
      </Drawer>
      {toolboxes.map(config => {
        // if (config.key !== currentToolbox) {
        //   return null
        // }

        if (config.PhoneComponent) {
          const Component = config.PhoneComponent
          const { key, className, Text, text, ...props } = config
          return (
            <>
              <Component
                {...props}
                key={key}
                className={className}
                currentBoxRef={currentBoxRef}
                openSidebarRef={openSidebarRef}
                switchToolbox={setCurrentToolbox}
                text={<h1>{Text ? <Text /> : text}</h1>}
                toolboxKey={key}
              />
            </>
          )
        }

        return (
          <>
            <DefaultPhoneComponent
              {...config}
              key={config.key}
              currentBoxRef={currentBoxRef}
              forceRender
              openSidebarRef={openSidebarRef}
              switchToolbox={setCurrentToolbox}
              toolboxKey={config.key}
            />
          </>
        )
      })}
    </>
  )
}

export default PhoneSidebar
