import React, { useCallback } from 'react'
import { Trans } from '@lingui/macro'
import { Button } from 'antd'
import { YoutubeOutlined } from '@ant-design/icons'

import ButtonLink from '../../../../../SharedComponents/ButtonLink'
import TemplateEntitiesDrawer from './TemplateEntitiesDrawer'
import { Category } from './Types'


interface TemplateEntitiesCategoriesDisplayProps {
  fieldCategories: Category[]
  condCategories: Category[]
  currentCategory: Category | undefined
  setOpenedCategory: (cat: string | undefined) => void
  cssSelector: string
}

/**
 * This has for goal to display the categories of fields and decisions in a pretty way for the user
 * Then it can open a category and see the entities from it
 */
const TemplateEntitiesCategoriesDisplay: React.FC<TemplateEntitiesCategoriesDisplayProps> = ({
  fieldCategories, condCategories, currentCategory, setOpenedCategory, cssSelector
}) => (
  <>
    <div className='logicCategory'>
      <div className='logicCategoryTitle'>
        <h2><Trans>Input Fields</Trans></h2>
        <p className='logicCategoryInstructions' id='explanations-input-fields'>
          <span><Trans>Instructions</Trans></span>
          <YoutubeOutlined />
        </p>
      </div>
      <div className='logicCategoryItems'>
        {fieldCategories.map(category => (
          <Button
            key={category.type}
            block
            ghost
            icon={category.icon}
            onClick={() => setOpenedCategory(category.type)}
            type='primary'
          >
            <span className='categoryName'>{category.name}</span>
          </Button>
        ))}
      </div>
    </div>
    <div className='logicCategory'>
      <div className='logicCategoryTitle'>
        <h2><Trans>Decisions</Trans></h2>
        <p className='logicCategoryInstructions' id='explanations-decisions'>
          <span><Trans>Instructions</Trans></span>
          <YoutubeOutlined />
        </p>
      </div>
      <div className='logicCategoryItems'>
        {condCategories.map(category => (
          <Button
            key={category.type}
            block
            ghost
            icon={category.icon}
            onClick={() => setOpenedCategory(category.type)}
            type='primary'
          >
            <span className='categoryName'>{category.name}</span>
          </Button>
        ))}
      </div>
    </div>

    <TemplateEntitiesDrawer
      close={useCallback(() => {
        setOpenedCategory(undefined)
      }, [setOpenedCategory])}
      cssSelector={cssSelector}
      currentCategory={currentCategory}
    />
  </>
)

export default TemplateEntitiesCategoriesDisplay
