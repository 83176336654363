import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { t, Trans } from '@lingui/macro'
import Icon, { BankOutlined, CheckOutlined, MoreOutlined, PlusOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons'
import {
  Button, Dropdown, Empty, Input, List, Menu, Modal, Skeleton, Tooltip
} from 'antd'

import { ContractingParty, useContractingPartiesCollection, useList } from '@top-legal/datastore'

import { UserAvatar, userFullName } from '../../../Organisations/UserRoleDisplay/UserAvatarList'
import { addressToString } from '../../../ContactList/ContactList'
import { UserAndCompanyDataContext } from '../../../Layouts/Constants'
import CreateContactForm from './CreateContactForm'
import CreateCompanyForm from './CreateCompanyForm'

import InfiniteScrolling from '../../../SharedComponents/ListingComponents/InfiniteScrolling'
import { emptyArray, useHighlight } from '../../../Listing/SearchHelpers'

import './SelectContactStyles.scss'
import { DeviceContext } from '../../../../GlobalContext'


export interface SelectContactProps {
  initialValue?: any
  visible: boolean
  onCancel: () => void
  onFinish: (party: any) => void
  userAddressRequired?: boolean
  companyRequired?: boolean
  parentFilter?: (contact: any) => boolean // True = keep it
}


// ======================================================================================


const ZapierLogo = () => <img alt='zapier logo' src='https://cdn.zapier.com/zapier/images/logos/zapier-logomark.png' />
const ZapierIcon = () => <Icon component={ZapierLogo} />


// ======================================================================================


export const ContactTags = ({ contact }) => {
  const contactTags = useMemo(() => {
    const tags = []

    if (contact.source === 'Zapier') {
      tags.push(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Tooltip key='zapierSource' overlayClassName='registeredUserTooltip' title={<Trans>Contact added from zapier</Trans>}>
          <span className='userTagIcon'><ZapierIcon /></span>
        </Tooltip>
      )
    }

    if (contact.userID) {
      tags.push(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Tooltip key='userRegistered' overlayClassName='registeredUserTooltip' title={<Trans>This user has an contract portal account</Trans>}>
          <span className='userTagIcon registeredUser'><UserOutlined /></span>
        </Tooltip>
      )
    }

    return tags
  }, [contact.source, contact.userID])

  return <>{contactTags}</>
}


// ======================================================================================


const SelectContact: React.FC<SelectContactProps> = ({
  initialValue,
  visible,
  onCancel,
  onFinish,
  userAddressRequired,
  companyRequired,
  parentFilter
}) => {
  const { company } = useContext(UserAndCompanyDataContext)
  const isPhone = useContext(DeviceContext) === 'phone'


  /** ****************************************************************************************
   *                   Editing states
   **************************************************************************************** */
  const [showCreateContact, setShowCreateContact] = useState(false)
  const [showCreateCompany, setShowCreateCompany] = useState(false)
  const [currentEditingContact, setCurrentEditingContact] = useState<ContractingParty>()
  const [currentEditingCompany, setCurrentEditingCompany] = useState<ContractingParty>()


  /** ****************************************************************************************
   *             Get all our contacts and group the by the two categories
   **************************************************************************************** */
  const partiesCollection = useContractingPartiesCollection()
  const [contactsStore = emptyArray, loading] = useList<ContractingParty>(
    useMemo(() => partiesCollection.find().sort({ updatedAt: 'desc' }), [partiesCollection])
  )
  const [persons, companyMapping] = useMemo<[any[], { [companyPartyID: string]: ContractingParty }]>(() => {
    const array: any[] = []
    const mapping: { [companyPartyID: string]: ContractingParty } = {}

    if (Array.isArray(contactsStore)) {
      contactsStore.forEach(contact => {
        if (contact.type === 'company') {
          mapping[contact.partyID] = contact
        } else if (!contact.userID || !company.members[contact.userID]) {
          if (!parentFilter || parentFilter(contact)) {
            array.push(contact)
          }
        }
      })
    }

    return [array, mapping]
  }, [company.members, contactsStore, parentFilter])
  const companyName = useCallback(contact => companyMapping[contact.companyPartyID as string]?.name || '', [companyMapping])


  /** ****************************************************************************************
   *                        States & components for search
   **************************************************************************************** */
  const [search, setSearch] = useState<string[]>([])
  const Highlight = useHighlight(search)

  const filter = useCallback((searchArr: string[], contact: any): boolean => {
    const str = [
      contact.firstName, contact.lastName, contact.email,
      addressToString(contact.address || {}), contact.phoneNumber,
      companyName(contact)
    ].map(elm => elm || '').join(' ').toLowerCase()
    return !searchArr.some(txt => !str.includes(txt))
  }, [companyName])

  const filteredPersons = useMemo(() => (
    search.length ? persons.filter(contact => filter(search, contact)) : persons
  ), [search, persons, filter])


  /** ****************************************************************************************
   *                        Handling some props changes
   **************************************************************************************** */
  useEffect(() => {
    if (initialValue) {
      setCurrentEditingContact(initialValue)
    }
  }, [initialValue])
  //  Reset state when the modal is closed
  const [listVisible, setListVisible] = useState(false)
  useEffect(() => {
    if (!visible) {
      setSearch([])
      setShowCreateContact(false)
      setShowCreateCompany(false)
      setCurrentEditingContact(undefined)
      setListVisible(false)
    } else {
      setTimeout(() => setListVisible(true), 400)
    }
  }, [visible])

  // eslint-disable-next-line react/display-name
  const RenderItem = useMemo<React.FC<{ item: ContractingParty }>>(() => ({ item: contact }) => (
    <List.Item key={contact.partyID}>
      <List.Item.Meta
        avatar={<UserAvatar noTooltip size='2.5rem' user={contact} />}
        description={(
          <>
            <p className='contactEmail'>
              <Highlight text={contact.email} />
            </p>
            {contact.address && <p className='contactAddress'><Highlight text={addressToString(contact.address)} /></p>}
          </>
        )}
        title={
          <>
            <Highlight text={userFullName(contact)} />
            <div className='adjustCompany'><Highlight text={companyName(contact)} /></div>
          </>
        }
      />
      <div className='buttonsAndTags'>
        <ContactTags contact={contact} />
        {!contact.userID && (
          <Dropdown
            overlay={
              <Menu key='edit'>
                <Menu.Item
                  onClick={() => {
                    setCurrentEditingContact(contact)
                    setShowCreateContact(true)
                  }}
                >
                  <UserOutlined />
                  <span><Trans>Edit contact</Trans></span>
                </Menu.Item>
                {companyName(contact) && (
                  <Menu.Item
                    onClick={() => {
                      setCurrentEditingContact(contact)
                      setCurrentEditingCompany(companyMapping[contact.companyPartyID as string])
                      setShowCreateCompany(true)
                    }}
                  >
                    <BankOutlined />
                    <span><Trans>Edit company</Trans></span>
                  </Menu.Item>
                )}
              </Menu>
            }
            trigger={['click']}
          >
            <Button
              className='noBorder'
              ghost
              icon={<MoreOutlined />}
            />
          </Dropdown>

        )}
        <Button
          icon={<CheckOutlined />}
          onClick={() => onFinish(contact)}
          type='primary'
        >
          <Trans>Select</Trans>
        </Button>
      </div>
    </List.Item>
  ), [Highlight, companyMapping, companyName, onFinish])

  const rowKey = useCallback((item: any) => item.partyID, [])


  /** ****************************************************************************************
   *                        Rendering of the selector
   **************************************************************************************** */
  return (
    <>
      <Modal
        className='inviteContactModal smallModal'
        destroyOnClose
        footer={null}
        maskClosable={false}
        onCancel={onCancel}
        title={<Trans>Add a contact to the contract</Trans>}
        visible={visible}
        zIndex={10001}
      >
        <div className='contactsTopBar'>
          <Input
            className='contactsSearchInput simple-line'
            onChange={evt => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setSearch(
                evt.target.value
                  ? evt.target.value.toLowerCase().split(' ')
                  : []
              )
            }}
            placeholder={t`Search existing contacts`}
            prefix={<SearchOutlined />}
          />
          {!isPhone && (
            <Button
              ghost
              icon={<PlusOutlined />}
              onClick={() => setShowCreateContact(true)}
              size='large'
              type='primary'
            >
              <Trans>Create new</Trans>
            </Button>
          )}
        </div>
        {(() => {
          if (loading || !listVisible) {
            return (
              <div className='loadingContent'>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </div>
            )
          }

          if (filteredPersons.length > 0) {
            return (
              <div className='contactListWrapper'>
                <InfiniteScrolling
                  RenderItem={RenderItem}
                  items={filteredPersons}
                  rowKey={rowKey}
                />
              </div>
            )
          }

          return <Empty description={<Trans>No contact found</Trans>} />
        })()}
      </Modal>
      <CreateContactForm
        companyRequired={companyRequired}
        initialValue={currentEditingContact}
        onClose={() => setShowCreateContact(false)}
        onFinish={value => {
          setShowCreateContact(false)
          onFinish(value)
        }}
        userAddressRequired={userAddressRequired}
        visible={showCreateContact}
      />
      <CreateCompanyForm
        initialValue={currentEditingCompany}
        onClose={() => setShowCreateCompany(false)}
        onFinish={() => {
          setShowCreateCompany(false)
          onFinish(currentEditingContact)
        }}
        visible={showCreateCompany}
      />
    </>
  )
}

export default SelectContact
