/* eslint-disable react/display-name */
import React, { useContext, useLayoutEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { useQueryParam, StringParam } from 'use-query-params'
import { Button, Drawer, Dropdown, Menu } from 'antd'
import { CheckCircleOutlined, DeleteOutlined, EllipsisOutlined, HistoryOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'

import {
  Contract, ContractingParty, Template, useBatchGet, useContractingPartiesCollection, useDoc, useTemplatesCollection
} from '@top-legal/datastore'

import { UserAvatar, userFullName } from '../../Organisations/UserRoleDisplay/UserAvatarList'
import ButtonLink from '../../SharedComponents/ButtonLink'
import { ListItem } from '../Types'
import { CardCover, MainContentBox } from '../Template/TemplateRowComponents'
import { useContractSummaryTable } from '../../Contract/ContractEditor/ContractSummary'
import ListingContext from '../ListingContext'
import { ContractListingContext } from './ContractContainerWrapper'
import { useDeleteContract } from '../../Contract/ContractEditor/ContractContext'
import { ConfirmMenuItem } from '../../SharedComponents/FormComponents'

import './ContractItemStyles.scss'
import { DeviceContext } from '../../../GlobalContext'

type ContractWithDrawers = Contract & { openTermsDrawer?: () => void, openActivitiesDrawer?: () => void }

/**
 * Component is used on the contract list (overview) to display the contract summary. Each componnent is one contract
 * entry. It displays the contract, some tags and the contract terms.
 */
const domain = process.env.DEAL_DOMAIN
const frozenStatus = ['frozen', 'signed']
const fullAccessRoles = ['Owner', 'FullAccess']
const mainContent: ListItem<ContractWithDrawers> = {
  Element: React.memo(({ item }) => {
    const { lang } = useParams()
    const [templateID, setTemplateID] = useQueryParam('templateID', StringParam)
    const dealID = useMemo(() => (item as any).toJSON().dealID, [item])

    const contractTemplateID = (item.editingOrgininalTemplateID || item.templateID)
    const templatesCollection = useTemplatesCollection()
    const [template] = useDoc<Template>(useMemo(() => templatesCollection.findOne(contractTemplateID), [templatesCollection, contractTemplateID]))

    const { Highlight } = useContext(ListingContext)
    const isCurrentTemplate = templateID && template && template.templateID === templateID
    const templateName = template && (
      <span
        className={`templateName ${isCurrentTemplate ? 'searchActive' : ''}`}
        onClick={() => setTemplateID(isCurrentTemplate ? undefined as undefined : template.templateID)}
      >
        <Highlight text={template.name} />
      </span>
    )

    //  Little hack to wait about the ref of drawers
    const [showDropdown, setShowDropdown] = useState(false)
    useLayoutEffect(() => { setTimeout(() => setShowDropdown(true)) }, [])
    const canDelete = fullAccessRoles.includes(item.role) && !frozenStatus.includes(item.contractStatus)
    const deleteContract = useDeleteContract(item.contractID)

    return (
      <MainContentBox item={item as any} titleDesc={<p className='boxTitleDesc'><Trans>Drafted based on Playbook {templateName}</Trans></p>}>
        <ButtonLink
          href={dealID ? `${domain}/deals/${dealID}?contract=${item.contractID}` : `/${lang}/contracts/${item.contractID}`}
          noRouter={dealID}
          type='primary'
          target={dealID ? '__blank' : undefined}
        >
          {dealID ? 'Open Dealroom' : <Trans>Open Contract</Trans>}
        </ButtonLink>
        {!dealID && showDropdown && (item.openTermsDrawer || item.openActivitiesDrawer || canDelete) && (
          <Dropdown
            arrow
            overlay={(
              <Menu key='menu'>
                {/* <Menu.Item onClick={() => console.info('NotImplemented')}> */}
                {/*  <SisternodeOutlined /> */}
                {/*  <span><Trans>Show User Access</Trans></span> */}
                {/* </Menu.Item> */}
                {item.openTermsDrawer && (
                  <Menu.Item onClick={item.openTermsDrawer}>
                    <CheckCircleOutlined />
                    <span><Trans>Show Contract Terms</Trans></span>
                  </Menu.Item>
                )}
                {item.openActivitiesDrawer && (
                  <Menu.Item onClick={item.openActivitiesDrawer}>
                    <HistoryOutlined />
                    <span><Trans>View Activity</Trans></span>
                  </Menu.Item>
                )}
                {canDelete && (
                  <ConfirmMenuItem onClick={deleteContract}>
                    <DeleteOutlined />
                    <span><Trans>Delete contract</Trans></span>
                  </ConfirmMenuItem>
                )}
              </Menu>)}
            placement='bottomCenter'
            trigger={['click']}
          >
            <Button
              className='moreButton ant-btn-grey'
              ghost
              // eslint-disable-next-line no-script-url
              href='javascript:void(0)'
              icon={<EllipsisOutlined />}
              type='default'
            />
          </Dropdown>
        )}
      </MainContentBox>
    )
  }),
  width: 1.7,
  key: 'mainContent'
}

/// ////////////////////////////////////////////////////
//                Users
/// ////////////////////////////////////////////////////
const externals: ListItem<Contract> = {
  Element: React.memo(({ item }) => {
    const [party, setParty] = useQueryParam('party', StringParam)

    const allParties = useMemo(() => {
      const arr: string[] = []
      const add = (partyID: any) => {
        if (typeof partyID === 'string' && partyID && (partyID.startsWith('party-') || partyID.startsWith('contact-'))) {
          if (!arr.includes(partyID)) {
            if (partyID === party) {
              arr.unshift(partyID)
            } else {
              arr.push(partyID)
            }
          }
        }
      }

      if (item.parties) {
        Object.values<any>(item.parties).forEach(data => {
          add(data.partyID || data.contactID || data)

          if (Array.isArray(data.additionalUsers)) {
            data.additionalUsers.forEach(tmp => add(tmp.partyID || tmp.contactID))
          }
        })
      }

      return arr
    }, [item, party])

    //  Compute parties from the store
    const collection = useContractingPartiesCollection()
    const [contacts] = useBatchGet(collection, allParties)
    const [data, compIds] = useMemo(() => {
      const arr: ContractingParty[] = []
      const arr2: string[] = []

      if (contacts) {
        allParties.forEach(key => {
          const contact = contacts.get(key)?.toJSON()
          if (contact) {
            if (arr.length < 3) {
              arr.push(contact)
            }
            if (contact.companyPartyID) {
              arr2.push(contact.companyPartyID)
            }
          }
        })
      }

      return [arr, arr2]
    }, [allParties, contacts])
    const [comps] = useBatchGet(collection, compIds)

    return (
      <div className='listBox contractUsers'>
        <p className='boxTitleDesc'><Trans>Contract parties</Trans></p>
        <h3>{(comps && comps.values().next()?.value?.name) || <Trans>Parties</Trans>}</h3>
        <div className='usersProvidedFor'>
          {data.map(contact => (
            <div
              key={contact.partyID}
              className={`oneUser ${party === contact.partyID ? 'searchActive' : ''}`}
              onClick={() => setParty(party === contact.partyID ? undefined as any : contact.partyID)}
            >
              <UserAvatar className='extern' noTooltip size='2rem' user={contact} />
              <span className='userName'>{userFullName(contact)}</span>
            </div>
          ))}
        </div>
      </div>
    )
  }),
  width: 1.2,
  key: 'externals'
}

/// ////////////////////////////////////////////////////
//                Instructions
/// ////////////////////////////////////////////////////
const activitiesAreSame = (prevProps, nextProps) => prevProps.item === nextProps.item && prevProps.item.activities === nextProps.item.activities

const status: ListItem<ContractWithDrawers> = {
  Element: React.memo(({ item }) => {
    const [open, setOpen] = useState(false)
    const phases = (item as any).activities
    const device = useContext(DeviceContext)

    //  Little hack to share the openTerms to main dropdown
    useLayoutEffect(() => {
      if (phases.length > 1 || device === 'phone') {
        Object.defineProperty(item, 'openActivitiesDrawer', {
          enumerable: false,
          configurable: true,
          value: () => setOpen(true)
        })
        return () => { delete item.openActivitiesDrawer }
      }
      return undefined
    }, [item, phases, device])

    return (
      <div className='listBox contractStatus'>
        <p className='boxTitleDesc'><Trans>Contract Status</Trans></p>
        {phases[0]}
        <div className='contractPhaseBox' /* Needed for styling purpose */ />
        <Drawer
          className='contractListingTermsDrawer'
          destroyOnClose
          onClose={() => setOpen(false)}
          title={<h3><Trans>Activities</Trans></h3>}
          visible={open}
          width='22rem'
        >
          {phases}
        </Drawer>
        {phases.length > 1 && (
          <Button
            className='boxMoreButton'
            // eslint-disable-next-line no-script-url
            href='javascript:void(0)'
            onClick={() => setOpen(true)}
            size='small'
            type='link'
          >
            <Trans>+ see all</Trans>
          </Button>
        )}
      </div>
    )
  }, activitiesAreSame),
  width: 1.2,
  key: 'status'
}

/// ////////////////////////////////////////////////////
//                Terms
/// ////////////////////////////////////////////////////
const TermsDrawer: React.FC<{ item: ContractWithDrawers }> = ({ item }) => {
  const [open, setOpen] = useState(false)
  const { fieldsMap } = useContext(ContractListingContext)
  const termsTable = useContractSummaryTable(item, fieldsMap, item.fieldsResponse, { noParties: true })

  //  Little hack to share the openTerms to main dropdown
  useLayoutEffect(() => {
    if (termsTable) {
      Object.defineProperty(item, 'openTermsDrawer', {
        enumerable: false,
        configurable: true,
        value: () => setOpen(true)
      })
      return () => { delete item.openTermsDrawer }
    }
    return undefined
  }, [item, termsTable])

  if (!termsTable) {
    return null
  }

  return (
    <>
      <Drawer
        className='contractListingTermsDrawer'
        destroyOnClose
        onClose={() => setOpen(false)}
        title={<h1><Trans>Terms</Trans></h1>}
        visible={open}
        width='22rem'
      >
        {termsTable}
      </Drawer>
      <Button
        className='boxMoreButton'
        // eslint-disable-next-line no-script-url
        href='javascript:void(0)'
        onClick={() => setOpen(true)}
        size='small'
        type='link'
      >
        <Trans>+ see all</Trans>
      </Button>
    </>
  )
}

const terms: ListItem<Contract> = {
  Element: React.memo(({ item }) => {
    const { fieldsMap } = useContext(ContractListingContext)
    const termsTable = useContractSummaryTable(item, fieldsMap, item.fieldsResponse, {
      noParties: true,
      compact: true,
      maxItems: 4
    })

    return (
      <div className='listBox terms-columns'>
        <p className='boxTitleDesc'><Trans>Key Terms</Trans></p>
        <h3><Trans>Terms</Trans></h3>
        {termsTable}
        <TermsDrawer item={item} />
      </div>
    )
  }),
  width: 1.2,
  key: 'terms'
}


/// ////////////////////////////////////////////////////
//          Preview box for tablet & phone
/// ////////////////////////////////////////////////////
const previewBox: ListItem<Contract> = {
  Element: React.memo(({ item }) => (
    <div className='listBox previewBox'>
      <CardCover url={item.previewUrl} />
    </div>
  )),
  width: 0.8,
  key: 'preview'
}

const previewBoxWithData: ListItem<ContractWithDrawers> = {
  Element: React.memo(({ item }) => {
    const [open, setOpen] = useState(false)
    const phases = (item as any).activities

    //  Little hack to share the openTerms to main dropdown
    useLayoutEffect(() => {
      Object.defineProperty(item, 'openActivitiesDrawer', {
        enumerable: false,
        configurable: true,
        value: () => setOpen(true)
      })
      return () => { delete item.openActivitiesDrawer }
    }, [item])

    return (
      <div className='listBox previewBox withContractStatus'>
        {phases[0]}
        <CardCover url={item.previewUrl} />

        <TermsDrawer item={item} />
        <Drawer
          className='contractListingTermsDrawer'
          destroyOnClose
          onClose={() => setOpen(false)}
          title={<h3><Trans>Activities</Trans></h3>}
          visible={open}
          width='22rem'
        >
          {phases}
        </Drawer>
      </div>
    )
  }, activitiesAreSame),
  width: 0.8,
  key: 'preview'
}

export default {
  components: [previewBox, mainContent, externals, status, terms] as ListItem<Contract>[],
  tabletComponents: [{ ...previewBoxWithData, width: 1 }, mainContent, externals] as ListItem<Contract>[],
  phoneComponents: [previewBoxWithData, { ...mainContent, width: 1.2 }] as ListItem<Contract>[]
}

