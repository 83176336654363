import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { t, Trans } from '@lingui/macro'
import { Button, Tooltip } from 'antd'
import { AuditOutlined } from '@ant-design/icons'

import { useSectionsCollection } from '@top-legal/datastore'

import RestService from '../../../../../../../RestService'
import { UserAndCompanyDataContext } from '../../../../../../Layouts/Constants'
import { ContractContext } from '../../../../Contexts'
import { CustomTag } from '../../../../../../Organisations/UsersManagement'
import UserCard from './UserCard'
import SigningDrawer from '../../../../Signatures/SigningDrawer'

import { copyLinkProps } from '../../../../InviteContactToContract/InviteFormDrawer'
import { freezeContractAction } from '../../../../../ContractPageContext'
import useCardActions from './useCardActions'
import { displayDate } from '../../../../../../Template/_TemplateHelperFunctions'
import { switchSigning } from '../../../../../redux/ContractActions'
import { OpenManagementToolboxContext } from '../../../../../../../ContractTemplateRoutes'
import Portal from '../../../../../../SharedComponents/Portal'
import VerifyBeforeSigningDrawer from '../VerifyBeforeSigningDrawer'
import InstanceUsersContext, { PartyInfo } from '../Context/InstanceUsersContext'

interface InternalRoleProps {
  userRole: { userID: string, role: string }
  partyInfo?: PartyInfo
}

const InternalRoleCard: React.FC<InternalRoleProps> = ({ userRole: { userID, role }, partyInfo }) => {
  const { lang } = useParams()
  const {
    user: { userID: currentUserID },
    company: { organisationID, members, teams }
  } = useContext(UserAndCompanyDataContext)
  const deletable = userID !== currentUserID //    We cannot delete ourself
    && role !== 'Owner' //                         We cannot delete the owner of the contract
    && userID !== members[currentUserID]?.team //  We cannot delete our team

  const allTeams = useMemo(
    () => ({
      ...teams,
      __Admin: { userID: '__Admin', name: t`Managers` },
      [organisationID]: { userID: organisationID, name: t`Other company members` }
    }),
    [organisationID, teams]
  )

  const { contract } = useContext<any>(ContractContext)
  const { signaturesHolder } = contract
  const isTemplateView = contract.contractID === '__preview__'

  const [signingOpen, setSigningOpen] = useState(false)

  //  Get a general disabled state, it come with a check contract is fullySigned
  const {
    actionsDisabled, setActionsDisabled,
    deleteInternalRole
  } = useContext(InstanceUsersContext)
  const dispatch = useDispatch()

  //  Get the Context for opening the External tab after signing.
  const openManagementToolboxContext = useContext(OpenManagementToolboxContext)

  /** **********************************************************************
   *           Creates the function to open the External Tab
   ********************************************************************** */
  const openExternal = useCallback(() => {
    openManagementToolboxContext.current('external')
  }, [openManagementToolboxContext])

  /** **********************************************************************
   *                    Build card actions
   ********************************************************************** */
  const inviteEnabled = !isTemplateView && userID !== currentUserID && !allTeams[userID]
  const actions = useCardActions(
    useCallback(() => RestService('POST', '/user/notify', {
      contractID: contract.contractID,
      userID,
      signer: partyInfo?.signer
    }), [contract.contractID, partyInfo?.signer, userID]),
    members[userID]?.email,
    { ...copyLinkProps(lang, undefined, true, contract.contractID) },
    inviteEnabled,
    <Trans>Send invite</Trans>
  )

  /** **********************************************************************
   *           Build the delete user action
   ********************************************************************** */
  const deleteUser = useCallback(async () => {
    setActionsDisabled(true)
    try {
      if (partyInfo?.signer) {
        await dispatch(switchSigning(partyInfo?.partyKey, false))
      }
      await deleteInternalRole(userID)
    } catch (err) {
      console.error('Error when removing a user', err)
    }
    setActionsDisabled(false)
  }, [setActionsDisabled, partyInfo?.signer, partyInfo?.partyKey, deleteInternalRole, userID, dispatch])

  /** **********************************************************************
   *           Build the title tag
   ********************************************************************** */
  const titleTag = useMemo(() => {
    if (partyInfo?.signer && (contract.contractStatus === 'frozen' || contract.contractStatus === 'signed')) {
      if (partyInfo.signedDate) {
        return (
          <CustomTag
            color='done'
            status={
              <>
                <Trans>Signed</Trans> {displayDate(partyInfo.signedDate)}
              </>
            }
          />
        )
      }
      return <CustomTag color='warning' status={<Trans>Not Signed yet</Trans>} />
    }
    if (role === 'Owner') {
      return <CustomTag color='done' status={<Trans>Owner</Trans>} />
    }
    return <CustomTag color='done' status={<Trans>Access Granted</Trans>} />
  }, [contract.contractStatus, role, partyInfo?.signedDate, partyInfo?.signer])

  /** **********************************************************************
   *           Build the signing button & drawer
   ********************************************************************** */
  const internalSigner = !partyInfo?.signedDate && userID === currentUserID && partyInfo?.signer
  const signature = contract.signaturesHolder
    && Array.isArray(contract.signaturesHolder.signatures)
    && contract.signaturesHolder.signatures.find(({ partyID }) => partyID === userID)

  const sectionsCollection = useSectionsCollection()
  const { neededApprovals, hasAllApprovals } = signaturesHolder || {}
  const [moveToSignDrawerOpen, setMoveToSignDrawerOpen] = useState(false)
  const signingButton = useMemo(() => {
    if (
      internalSigner //  Need to sign
      && (!signaturesHolder || !neededApprovals || hasAllApprovals) // No approval needed
      && (!signature || !signature.signedDate) //  Not yet signing or not signed yet
    ) {
      const flowDisabled = !['editing', 'frozen', 'signed'].includes(contract.contractStatus)
      const confirmAndSign = () => dispatch(freezeContractAction(sectionsCollection)).then(() => {
        setMoveToSignDrawerOpen(false)
        setSigningOpen(true)
      })

      let button = (
        <Button
          block
          disabled={flowDisabled || actionsDisabled}
          onClick={() => {
            if (signaturesHolder) {
              setSigningOpen(true)
            } else {
              setMoveToSignDrawerOpen(true)
            }
          }}
          type='primary'
        >
          <Trans>Sign now</Trans>
        </Button>
      )
      let topButton = (
        <Button
          disabled={flowDisabled || actionsDisabled}
          icon={<AuditOutlined />}
          onClick={() => {
            if (signaturesHolder) {
              setSigningOpen(true)
            } else {
              setMoveToSignDrawerOpen(true)
            }
          }}
          type='primary'
        >
          <Trans>Sign now</Trans>
        </Button>
      )

      if (flowDisabled) {
        const disabledMessage = <Trans>You cannot invite users once the contract is fully signed</Trans>
        button = <Tooltip title={disabledMessage}>{button}</Tooltip>
        topButton = <Tooltip placement='bottomRight' title={disabledMessage}>{topButton}</Tooltip>
      }

      return (
        <>
          <Portal cssSelector='#topBarSignButton'>{topButton}</Portal>
          {button}
          {(signaturesHolder && signature) ? (
            <SigningDrawer close={() => setSigningOpen(false)} onFinishSigning={() => openExternal()} signature={signature} visible={signingOpen} />
          ) : (
            <VerifyBeforeSigningDrawer close={() => setMoveToSignDrawerOpen(false)} onConfirm={confirmAndSign} visible={moveToSignDrawerOpen} />
          )}
        </>
      )
    }
    return null
  }, [
    sectionsCollection,
    contract.contractStatus, actionsDisabled, dispatch, internalSigner,
    moveToSignDrawerOpen, openExternal, signature,
    signaturesHolder, neededApprovals, hasAllApprovals,
    signingOpen
  ])

  /** **********************************************************************
   *           Return a final user card
   ********************************************************************** */
  const userPartyInfo: any = useMemo(() => ({
    partyData: { partyID: userID }
  }), [userID])
  return (
    <UserCard
      cardActions={useMemo(() => {
        if (currentUserID === userID) {
          if (internalSigner && signingButton) {
            return [signingButton]
          }
          return undefined
        }
        return actions
      }, [actions, currentUserID, internalSigner, signingButton, userID])}
      deleteUser={deletable ? deleteUser : undefined}
      partyInfo={partyInfo || userPartyInfo}
      titleTag={titleTag}
    />
  )
}

export default InternalRoleCard
