import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { AppstoreOutlined, CheckOutlined, CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import { Button, Form, Skeleton } from 'antd'

import {
  saveInStore,
  Template, toJson,
  useContractsCollection, useDoc,
  useList,
  useSectionsCollection,
  useTemplateListBaseQuery,
  useTemplatesCollection
} from '@top-legal/datastore'

//  Editor library
import { RenderOnlyEditor } from '@top-legal/editor'
import { CustomForm } from '@top-legal/form-components'

import { ContractAssistant, ContractAssistantProps } from '@top-legal/contract-assistant'
import RestService from '../../../../../RestService'
import { ContractContext, TemplateContext } from '../../Contexts'

import CustomFormWrapper from '../../../../SharedComponents/CustomFormWrapper'
import { ConfirmButton, Select } from '../../../../SharedComponents/FormComponents'
import loadingModalFeedback from '../../../../Alert/LoadingModalFeedback'
import { getValue, isPartiesEntity } from '../../../../Template/_TemplateHelperFunctions'
import { buildContractInputQuestion } from '../../../../Template/TemplateHelperFunctions'
import { batchUpdateContractFields } from '../../../../Organisations/redux/OrganisationsActions'

import { UserAvatarByID, userFullName } from '../../../../Organisations/UserRoleDisplay/UserAvatarList'
import { InputParty, PartyTitle } from './QuestionsWizard/InputPartiesContacts'
import { updateTemplate } from '../../../../Template/redux/TemplateActions'

import { ensureTemplateSections } from '../../../Utils'
import { emptyObject } from '../../../../Listing/SearchHelpers'

import './ParametrisationToolboxStyles.scss'
import { UserAndCompanyDataContext } from '../../../../Layouts/Constants'
import { PartyFieldInput } from './QuestionsWizard/AssistantToolbox'


const PrametrisationCompletion: React.FC = () => {
  const { user, company } = useContext(UserAndCompanyDataContext)
  //  We have to use the template from the store rather than the one on redux to avoid desync
  const { template: { templateID = '__NO_TEMPLATE__' } } = useContext<any>(TemplateContext)
  const templatesColl = useTemplatesCollection()
  const [template] = useDoc<Template, {}, Template|undefined>(
    useMemo(() => templatesColl.findOne(templateID), [templateID, templatesColl]),
    toJson
  )
  const { contract } = useContext<any>(ContractContext)

  const dispatch = useDispatch()
  const contractsCollection = useContractsCollection()
  const updateFiledResponse = useCallback<ContractAssistantProps['updateFiledResponse']>(
    async update => {
      await dispatch(batchUpdateContractFields(update, contractsCollection))
    },
    [dispatch, contractsCollection]
  )

  const contractRef = useRef(contract)
  contractRef.current = contract
  const Wrapped = useMemo(() => {
    const Component: ContractAssistantProps['InputParty'] = ({ onChange, ...props }) => (
      <PartyFieldInput
        {...props}
        onChange={useCallback(party => {
          const { contractID, parties } = contractRef.current
          const newParties = { ...parties, [props.fieldKey]: { ...party } }
          RestService('PUT', `/contract/${contractID}`, { parties: newParties })
          saveInStore(contractsCollection, { ...contractRef.current, parties: newParties })
          return onChange(party)
        }, [onChange, props.fieldKey])}
      />
    )
    return Component
  }, [contractsCollection])

  if (template) {
    return (
      <ContractAssistant
        InputParty={Wrapped}
        contract={contract}
        currentCompanyName={company.name}
        currentPersonName={userFullName(user)}
        template={template}
        updateFiledResponse={updateFiledResponse}
      />
    )
  }

  return <Skeleton active />
}


const PrametrisationMatchToPlaybook: React.FC = () => {
  const { contract, updateContract } = useContext<any>(ContractContext)
  const dispatch = useDispatch()

  const templatesCollection = useTemplatesCollection()
  const sectionsCollection = useSectionsCollection()
  const getTemplates = useTemplateListBaseQuery()
  const [allTemplates = emptyObject] = useList(
    useMemo(() => getTemplates.sort({ dateUpdated: 'desc' }), [getTemplates]),
    useCallback(arr => {
      const map: Record<string, string> = {}
      arr.forEach(({ templateID, name }) => {
        if (templateID && name) {
          map[templateID] = name
        }
      })
      return map
    }, [])
  )


  return (
    <div className='contractParamsWrapper'>
      <div className='templateSelection'>
        <CustomForm
          onSubmit={({ templateID }) => loadingModalFeedback({
            loadingTitle: <Trans>Matching your contract to playbook</Trans>,
            successTitle: <Trans>Contract matched to playbook</Trans>,
            errorTitle: <Trans>Cannot match contract to playbook</Trans>,
            errorDescription: <Trans>An unexpected error occurred while trying to match the contract to the playbook. Please try again later.</Trans>,
            autoSuccessClose: 1000
          })(async () => {
            await RestService('POST', `/contract/${contract.contractID}/matchToPlaybook`, { templateID })
            const match = await templatesCollection.findOne(templateID).exec()
            if (match) {
              await dispatch(ensureTemplateSections(match.toJSON(), sectionsCollection))
              await updateContract({ templateID }, false)
              await dispatch(updateTemplate(match.toJSON()))
            }
          })}
        >
          <p className='matchDesc'><Trans>Match your current contract to a playbook</Trans></p>
          <Form.Item name='templateID' rules={[{ required: true, message: <Trans>Please select the playbook to match this contract.</Trans> }]}>
            <Select
              className='simple-line'
              items={allTemplates}
              placeholder={<Trans>Select the template to match to</Trans>}
            />
          </Form.Item>
          <Button
            block
            htmlType='submit'
            icon={<CheckOutlined />}
            style={{ marginBottom: '0.5rem' }}
            type='primary'
          >
            <Trans>Select</Trans>
          </Button>
        </CustomForm>
      </div>
    </div>
  )
}


const ParametrisationToolbox: React.FC = () => {
  const { contract: { templateID } } = useContext<any>(ContractContext)
  return templateID ? <PrametrisationCompletion /> : <PrametrisationMatchToPlaybook />
}

export default {
  key: 'contractParams',
  className: 'contractParamsToolbox',
  text: <Trans>Key Terms</Trans>,
  icon: <AppstoreOutlined />,
  Component: ParametrisationToolbox
}
