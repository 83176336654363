import React, { useLayoutEffect, useRef } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'

import './styles/LoadingFeedback.scss'

const LoadingFeedback: React.FC<{ loading: boolean }> = ({ loading, children }) => {
  const divRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (loading) {
      const elem = divRef.current as HTMLDivElement
      elem.className = 'loadingFeedback loading'

      return () => {
        elem.className = 'loadingFeedback done'
        setTimeout(() => {
          elem.className = 'loadingFeedback' // Remove the done class that do the cssAnimation
        }, 2000)
      }
    }
    return undefined
  }, [loading])

  return (
    <div ref={divRef} className='loadingFeedback'>
      <div className='loadingFeedbackDone'>
        <span className='loadingFeedbackMessage'>
          {children || <Trans>Saved</Trans>}
        </span>
        <span className='loadingFeedbackCheck'>
          <span ref={divRef} className='loadingFeedbackCheckInner' />
        </span>
      </div>
      <Spin indicator={<LoadingOutlined spin />} />
    </div>
  )
}

export default LoadingFeedback
