import React from 'react'

import { UserAvatarByID, UserFullName } from '../../../../../../Organisations/UserRoleDisplay/UserAvatarList'
import { PartyInfo } from '../Context/InstanceUsersContext'
import SwitchSigning from './SwitchSigning'

import './UserSigningSwitchStyles.scss'


const UserSigningSimpleSwitch: React.FC<{ partyInfo: PartyInfo }> = ({ partyInfo }) => (
  <div className='userSwitchSigning'>
    <UserAvatarByID autoSide noTooltip size='1.8rem' userID={partyInfo.partyData.partyID} />
    <div className='userName'><UserFullName userID={partyInfo.partyData.partyID} /></div>
    <SwitchSigning partyInfo={partyInfo} />
  </div>
)

export default UserSigningSimpleSwitch
