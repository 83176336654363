import React, { useContext, useState } from 'react'
import {
  Alert, Button, Col, Form, InputNumber, Modal, Radio, Row, Steps, Tabs, Tooltip
} from 'antd'
import { CheckOutlined, InfoCircleOutlined, LeftOutlined, PlusCircleOutlined, RightOutlined, SlidersOutlined } from '@ant-design/icons'
import { t, Trans } from '@lingui/macro'

import { TemplateContext } from '../../Contexts'
import { ModalRefContext } from '../../TemplateEntities/TemplateEntitiesForms/TemplateEntitiesFormsModal'
import CustomFormWrapper from '../../../../SharedComponents/CustomFormWrapper'
import { dateTypes, timeTypes } from '../../../../Template/TemplateHelperFunctions'
import { getElem } from '../../../../Template/_TemplateHelperFunctions'
import { InlineRadioGroupButtons, Select, SwitchInput } from '../../../../SharedComponents/FormComponents'
import { DatePicker } from '../../../../SharedComponents/DateTimePicker'
import TemplateTagging from '../../../../Template/TemplateTagging'
import { ExplanationsInput } from '../../TemplateEntities/TemplateEntitiesForms/CreateInputFieldForm'
import { SectionProps } from './Types'

// styles
import './SectionMetaDataModalStyles.scss'

const CustomDateInput = props => <DatePicker {...props} inputYearMonthDate noDateConversion />

const TemplateSectionMetadataModal: React.FC<SectionProps> = ({ section, updateSection }) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(0)
  const [values, setValues] = useState(section)
  const { template } = useContext<any>(TemplateContext)
  const { modalRef } = useContext<any>(ModalRefContext)

  /** *************************************************************************
   *                       Input list active values
   ************************************************************************** */
  const renderTaggingPart = () => (
    <Form.Item initialValue={{ tags: values.tags, weight: values.weight || 'empty' }} name='tagging'>
      <TemplateTagging />
    </Form.Item>
  )

  /** *************************************************************************
   *                       Input list active values
   ************************************************************************** */
  const inputListActiveValues = dynamicConditionalField => (
    <Row key='listField' align='top' className='sectionInputMetaDataValues' justify='start'>
      <Col span={24}>
        <div className='sentenceWrapper'>
          <span className='promptText'>
            <Trans>For which selected values do you want to show the section?</Trans>
          </span>
          <Form.Item
            className='inputModalFormItem'
            initialValue={values.activeValues}
            name='activeValues'
            rules={[{
              required: true,
              message: <Trans>You must select at least one value.</Trans>
            }]}
          >
            <Select
              className='inTextInput simple-line'
              items={dynamicConditionalField.values[template.lang] || dynamicConditionalField.values}
              multiple
              placeholder={t`- Select values -`}
            />
          </Form.Item>
        </div>
      </Col>
    </Row>
  )

  /** *************************************************************************
   *                       Input boolean active value
   ************************************************************************** */
  const inputBooleanActiveValue = () => (
    <Row key='booleanField' align='top' justify='start'>
      <Col span={24}>
        <div className='sentenceWrapper boolean'>
          <span className='promptText'>
            <Trans>For which selected values do you want to show the section?</Trans>
          </span>
          <Form.Item
            className='inputModalFormItem'
            initialValue={values.active}
            name='active'
            rules={[{
              required: true,
              message: <Trans>Please select either yes or no.</Trans>
            }]}
          >
            <InlineRadioGroupButtons
              className='inTextInput'
              items={{
                yes: <Trans>Yes</Trans>,
                no: <Trans>No</Trans>
              }}
            />
          </Form.Item>
        </div>
      </Col>
    </Row>
  )

  /** *************************************************************************
   *                  Input number/date/time active range
   ************************************************************************** */
  const inputNumberActiveRange = (dynamicConditionalField, getFieldValue, CustomInput?: any) => {
    const selectTypes = {
      date: dateTypes,
      time: timeTypes
    }[dynamicConditionalField && dynamicConditionalField.type]

    const FormInput = CustomInput || InputNumber

    return (
      <div key='numberField'>
        <Row align='top' justify='start'>
          <Col span={24}>
            <div className='sentenceWrapper'>
              <span className='promptText'>
                <Trans>This field needs a range condition. Let's set the minimum and maximum.</Trans>
              </span>
            </div>
          </Col>
        </Row>
        <Row align='top' justify='start'>
          <Col span={24}>
            <div className='sentenceWrapper rangeBorder'>
              <span className='promptText'>
                <Tooltip title={<Trans>If you set nothing the minimum border will be -Infinity</Trans>}>
                  <InfoCircleOutlined />
                </Tooltip>
                <Trans>Which minimum border do you want to set?</Trans>
              </span>
              <Form.Item
                className='inputModalFormItem'
                initialValue={values.inferiorBorder}
                name='inferiorBorder'
                rules={[{
                  required: getFieldValue('superiorBorder') == null,
                  message: <Trans>You must set one of these borders.</Trans>
                }]}
              >
                <FormInput className='inTextInput simple-line' placeholder='Min' />
              </Form.Item>
              {!CustomInput && selectTypes && (
                <Form.Item
                  className='inputModalFormItem'
                  initialValue={values.inferiorBorderType}
                  name='inferiorBorderType'
                  rules={[{
                    required: getFieldValue('inferiorBorder') != null,
                    message: <Trans>If you set this border please select a type.</Trans>
                  }]}
                >
                  <Select className='inTextInput simple-line' items={selectTypes} noSearch placeholder='Type' />
                </Form.Item>
              )}
            </div>
          </Col>
        </Row>

        <Row align='top' justify='start'>
          <Col span={24}>
            <div className='sentenceWrapper rangeBorder'>
              <span className='promptText'>
                <Tooltip title={<Trans>If you set nothing the maximum border will be +Infinity</Trans>}>
                  <InfoCircleOutlined />
                </Tooltip>
                <Trans>Which maximum border do you want to set?</Trans>
              </span>
              <Form.Item
                className='inputModalFormItem'
                initialValue={values.superiorBorder}
                name='superiorBorder'
                rules={[{
                  required: getFieldValue('inferiorBorder') == null,
                  message: <Trans>You must set one of these borders.</Trans>
                }]}
              >
                <FormInput className='inTextInput simple-line' placeholder='Max' />
              </Form.Item>
              {!CustomInput && selectTypes && (
                <Form.Item
                  className='inputModalFormItem'
                  initialValue={values.superiorBorderType}
                  name='superiorBorderType'
                  rules={[{
                    required: getFieldValue('superiorBorder') != null,
                    message: <Trans>If you set this border please select a type.</Trans>
                  }]}
                >
                  <Select className='inTextInput simple-line' items={selectTypes} noSearch placeholder='Type' />
                </Form.Item>
              )}
            </div>
          </Col>
        </Row>
      </div>
    )
  }


  /** *************************************************************************
   *                  Rendering the dynamic part form
   ************************************************************************** */
  const renderDynamicPart = ({ isFieldTouched, getFieldValue, setFieldsValue, getFieldError }) => {
    const isDynamic = isFieldTouched('isDynamicSection') ? getFieldValue('isDynamicSection') : values.isDynamicSection
    // eslint-disable-next-line no-nested-ternary
    const isRelativeToToday = isFieldTouched('isRelativeToContractFillingDate')
      ? getFieldValue('isRelativeToContractFillingDate')
      : (values.isRelativeToContractFillingDate != null ? values.isRelativeToContractFillingDate : true)

    const fieldPath = getFieldError('field').length > 0 ? null : (getFieldValue('field') || values.field)
    const dynamicConditionalField = fieldPath && getElem(fieldPath, template.fields)

    const fields = {
      ...template.fields,
      _createNew_: { type: 'global' }
    }

    const content = [
      /** *************************************************************************
       *                           Is dynamic ?
       ************************************************************************** */
      <Row key='dynamicQuestion' align='top' justify='start'>
        <Col span={24}>
          <div className='sentenceWrapper'>
            <span className='promptText'>
              <Trans>Would you like to make this section conditional to a user input?</Trans>
            </span>
            <Form.Item
              className='inputModalFormItem'
              initialValue={values.isDynamicSection}
              name='isDynamicSection'
            >
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <SwitchInput checkedChildren={<Trans>Yes</Trans>} unCheckedChildren={<Trans>No</Trans>} />
            </Form.Item>
          </div>
        </Col>
      </Row>
    ]

    if (isDynamic) {
      /** *************************************************************************
       *                           Which field ?
       ************************************************************************** */
      content.push(
        <Row key='whichField' align='top' justify='start'>
          <Col span={24}>
            <div className='sentenceWrapper'>
              <span className='promptText'>
                <Trans>On which field do you want to base your condition?</Trans>
              </span>
              <Form.Item
                className='inputModalFormItem'
                initialValue={values.field}
                name='field'
                rules={[{
                  required: true,
                  message: <Trans>Please select one field or disable the condition.</Trans>
                }]}
              >
                <Select
                  alwaysShown={fieldShown => fieldShown === fields._createNew_}
                  className='inTextInput simple-line'
                  collapsed
                  filter={element => /(list|date|time|number|amount|yesNo|global|company|person)/.test(element.type)}
                  items={fields}
                  label={(fieldObject, path) => {
                    if (fieldObject === fields._createNew_) {
                      return (
                        <Button
                          block
                          icon={<PlusCircleOutlined />}
                          onClick={() => {
                            modalRef.current.createOrEditInputField(keySelect => {
                              setTimeout(() => setFieldsValue({ field: keySelect }))
                            })
                          }}
                          size='small'
                          type='default'
                        >
                          <Trans>New input</Trans>
                        </Button>
                      )
                    }
                    if (path.length > 0) {
                      return fieldObject.name
                    }
                    return null
                  }}
                  placeholder={t`- Conditional field -`}
                  selectable={fieldSelect => /(list|date|time|number|amount|yesNo)/.test(fieldSelect.type)}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
      )

      if (dynamicConditionalField) {
        if (dynamicConditionalField.type === 'list') {
          content.push(inputListActiveValues(dynamicConditionalField))
        } else if (dynamicConditionalField.type === 'yesNo') {
          content.push(inputBooleanActiveValue())
        } else if (/(date|time|number|amount|listOfFormattedText)/.test(dynamicConditionalField.type)) {
          if (dynamicConditionalField.type === 'date') {
            content.push(
              <Row
                key='absoluteOrRelative'
                style={{
                  marginLeft: 'auto',
                  paddingRight: '1rem'
                }}

              >
                <Col span={24}>
                  <div className='sentenceWrapper'>
                    <span className='promptText'>
                      <Trans>Would you like to make the conditional text dependent on a duration (3 years) or an absolute date (27/05/2017)?</Trans>
                    </span>
                    <Form.Item
                      className='inputModalFormItem dateTypeSwitch'
                      initialValue={
                        typeof values.isRelativeToContractFillingDate === 'boolean'
                          ? values.isRelativeToContractFillingDate : true
                      }
                      name='isRelativeToContractFillingDate'
                    >
                      <Radio.Group buttonStyle='solid' size='small'>
                        <Radio.Button value={true}><Trans>Duration</Trans></Radio.Button>
                        <Radio.Button value={false}><Trans>Absolute Date</Trans></Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            )

            if (isRelativeToToday) {
              content.push(inputNumberActiveRange(dynamicConditionalField, getFieldValue))
            } else {
              content.push(inputNumberActiveRange(
                dynamicConditionalField,
                getFieldValue,
                CustomDateInput
              ))
            }
          } else {
            content.push(inputNumberActiveRange(dynamicConditionalField, getFieldValue))
          }
        } else {
          content.push(<Alert key='invalidField' message={<Trans>Invalid field selection!</Trans>} type='error' />)
        }
      }
    }

    return content
  }

  /**
   * Returning the button with modals
   */
  return (
    <>
      <Tooltip title={<Trans>Edit section metadata.</Trans>}>
        <Button
          className='editSectionMetadataButton noBorder'
          ghost
          icon={<SlidersOutlined />}
          onClick={() => {
            setValues({ ...section })
            setStep(0)
            setVisible(true)
          }}
          shape='circle'
          size='small'
          type='default'
        />
      </Tooltip>
      <Modal
        className='templateTextEditorModal sectionMetaDataEditModal mediumModal'
        destroyOnClose
        footer={null}
        onCancel={() => setVisible(false)}
        visible={visible}
      >
        <div className='createInputFieldForm'>
          <CustomFormWrapper
            onSubmit={async ({ tagging = {}, ...formValues }) => {
              const newValues = { ...values, ...formValues, ...tagging }

              //  Map field version if needed
              if (step === 2) {
                setLoading(true)
                if (newValues.field) {
                  const [base] = newValues.field.split('.')
                  newValues.fieldVersion = template.fields[base].version
                }
              }

              setValues(newValues)

              if (step === 2) {
                setLoading(true)
                try {
                  await updateSection?.(newValues)
                  setLoading(false)
                  setVisible(false)
                } catch (err) {
                  console.error('Failed to save section metadata', err)
                  setLoading(false)
                }
              } else {
                setStep(step + 1)
              }
            }}
          >
            <div className='logicColumn'>
              <div className='logigColumnInner paragraphWithInputs'>
                <Steps current={step} direction='horizontal'>
                  <Steps.Step title={<Trans>Tagging</Trans>} />
                  <Steps.Step title={<Trans>Dynamic section</Trans>} />
                  <Steps.Step title={<Trans>Provide explanations</Trans>} />
                </Steps>
                <div className='formContent'>
                  {step === 0 && renderTaggingPart()}
                  {step === 1 && <Form.Item shouldUpdate>{form => renderDynamicPart(form)}</Form.Item>}
                  {step === 2 && (
                    <Tabs centered className='sectionExplanationTabs' defaultActiveKey='internal'>
                      <Tabs.TabPane key='internal' className='internalSide' forceRender tab={<Trans>Internal</Trans>}>
                        <ExplanationsInput initialValue={{ ...values, key: values.sectionID }} />
                      </Tabs.TabPane>
                      <Tabs.TabPane key='external' className='externalSide' forceRender tab={<Trans>External</Trans>}>
                        <ExplanationsInput initialValue={{ ...values, key: values.sectionID }} inputSuffix='External' />
                      </Tabs.TabPane>
                    </Tabs>
                  )}
                </div>
                <div className='actionButtons'>
                  {step > 0 ? (
                    <Button className='previous' icon={<LeftOutlined />} onClick={() => setStep(step - 1)} type='default'>
                      <Trans>Previous</Trans>
                    </Button>
                  ) : <span>&nbsp;</span>}
                  {step < 2 && (
                    <Button className='next' htmlType='submit' icon={<RightOutlined />} type='default'>
                      <Trans>Next</Trans>
                    </Button>
                  )}
                  {step === 2 && (
                    <Button
                      className='next' htmlType='submit' icon={<CheckOutlined />}
                      loading={loading} type='primary'
                    >
                      <Trans>Done</Trans>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </CustomFormWrapper>
        </div>
      </Modal>
    </>
  )
}

export default TemplateSectionMetadataModal
