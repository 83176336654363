import React from 'react'

import { ContractingParty } from '@top-legal/datastore'

export interface InternalUserRole {
  userID: string
  role: string
}

export interface PartyInfo {
  partyKey: string
  signer?: boolean
  partyData: any

  signatureID?: string
  signedDate?: string

  tokenData?: any
}

export type PartiesInfo = Record<string, PartyInfo>


export interface InstanceUsersContextProps {
  //  The internal access management
  internRoles: InternalUserRole[]
  updateInternalRole: (userID: string, role: string) => Promise<void>
  deleteInternalRole: (userID: string) => Promise<void>

  //  The contract parties information
  partiesMap: Map<string, ContractingParty>
  partiesInfo: PartiesInfo
  partiesGroups: Record<string, string[]>

  actionsDisabled: boolean
  setActionsDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

const InstanceUsersContext = React.createContext<InstanceUsersContextProps>(undefined as any)

export default InstanceUsersContext
