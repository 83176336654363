import React, { useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { Menu } from 'antd'
import {
  AuditOutlined, BookOutlined,
  BulbOutlined,
  CarryOutOutlined,
  ContactsOutlined,
  CustomerServiceOutlined,
  ExportOutlined,
  FileDoneOutlined,
  FileOutlined,
  FundProjectionScreenOutlined,
  GlobalOutlined,
  HistoryOutlined,
  HomeOutlined,
  IdcardOutlined,
  LikeOutlined,
  LoginOutlined,
  PicLeftOutlined,
  SettingOutlined,
  TeamOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons'
import Auth from '@aws-amplify/auth'

import { UserAvatar } from '../../../Organisations/UserRoleDisplay/UserAvatarList'
import useBrowserStorage from '../../../../hooks/useBrowserStorage'
import { SearchModalContext } from '../../../Search/Types'

import './NavSidebarStyles.scss'

export interface LinkParams {
  lang: string
  history: any
  dispatch: any
  topLegalComunityPlaybook: boolean
  user: any
  organisation: any
  organisationRole: any
  setSearchOpen: (open: boolean) => void
}

export type LinksGenerator = (params: LinkParams) => any[]

export interface NavSidebarProps {
  publicMode?: boolean
  links?: { appLinks: LinksGenerator; userLinks: LinksGenerator }
}

/** ******************************************************************
 *              Public links / Demo playbooks
 ****************************************************************** */
const PUBLIC_APP_LINKS: LinksGenerator = () => [
  {
    title: {
      de: 'Startseite',
      en: 'Homepage'
    },
    externalLink: 'https//www.top.legal',
    icon: <HomeOutlined />
  },
  {
    title: {
      de: 'Vorlagen',
      en: 'Templates'
    },
    path: 'public-templates',
    icon: <FileOutlined />
  }
]

const PUBLIC_USER_LINKS: LinksGenerator = () => [
  {
    title: {
      de: 'Anmelden',
      en: 'Login'
    },
    path: 'login',
    icon: <LoginOutlined />
  }
]

/** ******************************************************************
 *              Authenticated application links
 ****************************************************************** */
const APP_LINKS: LinksGenerator = ({ topLegalComunityPlaybook, user, organisation, organisationRole }) => [
  // {
  //   title: {
  //     de: 'Search',
  //     en: 'Search'
  //   },
  //   onClick: () => setSearchOpen(true),
  //   icon: <SearchOutlined />
  // },
  {
    title: {
      de: 'Aktivitäts-Feed',
      en: 'Activity Feed'
    },
    path: 'dashboard',
    icon: <HistoryOutlined />
  },
  {
    title: {
      de: 'Analytics',
      en: 'Analytics'
    },
    path: 'analytics',
    icon: <FundProjectionScreenOutlined />
  },
  ...(!organisation.hideDealroom ? [{
    title: {
      de: 'Deals',
      en: 'Deals'
    },
    externalLink: `${process.env.DEAL_DOMAIN}/deals`,
    noNewTab: true,
    icon: <BookOutlined />
  }] : []),
  ...(topLegalComunityPlaybook || (window as any).IS_TRANSFERINITIATIVE
    ? [
      {
        title: {
          de: 'Globale Playbooks',
          en: 'Global Playbooks'
        },
        path: 'top-legal-templates',
        icon: <GlobalOutlined />
      }
    ]
    : []),
  {
    title: (window as any).IS_TRANSFERINITIATIVE
      ? {
        de: 'Meine Playbooks',
        en: 'My Playbooks'
      }
      : {
        de: 'Playbooks',
        en: 'Playbooks'
      },
    path: 'listing/templates',
    icon: <FileDoneOutlined />
  },
  {
    title: {
      de: 'Verträge',
      en: 'Contracts'
    },
    path: 'listing/contracts',
    icon: <AuditOutlined />
  },
  {
    title: {
      de: 'Erinnerungen',
      en: 'Reminders'
    },
    path: 'tasks?view=list&g_type=Mytasks',
    icon: <CarryOutOutlined />
  },
  {
    title: {
      de: 'Vertragsparteien',
      en: 'Contracting Parties'
    },
    path: 'contacts',
    icon: <ContactsOutlined />
  },
  //  Disable navigation to this page for now
  // {
  //   title: {
  //     de: 'Standards',
  //     en: 'Standards'
  //   },
  //   path: 'content-library',
  //   icon: <BookOutlined />
  // },
  {
    title: {
      de: 'Unternehmen',
      en: 'Organisation'
    },
    icon: <TeamOutlined />,
    isAllowed: () => organisationRole.team === '__Admin' && !user.tempEntityEndorsement,
    items: [
      {
        title: {
          de: 'Einstellungen',
          en: 'Settings'
        },
        path: 'organisation-settings',
        icon: <SettingOutlined />
      },
      {
        title: {
          de: 'Vertragsgestaltung',
          en: 'Contract styling'
        },
        path: 'contract-styling',
        icon: <PicLeftOutlined />
      },
      {
        title: {
          de: 'Nutzerverwaltung',
          en: 'User management'
        },
        path: 'organisation-user-management',
        icon: <UsergroupAddOutlined />
      }
      /* {
        title: {
          de: 'Integrationen',
          en: 'Extensions'
        },
        path: 'organisation-extensions',
        icon: <AppstoreOutlined />
      } */
      //  TODO: Reactivate later
      // {
      //   title: {
      //     de: 'Preis',
      //     en: 'Pricing'
      //   },
      //   path: 'pricing',
      //   icon: <EuroOutlined />
      // },
      // {
      //   title: {
      //     de: 'Rechnungen',
      //     en: 'Invoices'
      //   },
      //   path: 'invoices',
      //   icon: <AuditOutlined />
      // }
    ]
  }
]

export const logoutLinkFactory = ({ lang, history, dispatch }) => ({
  title: {
    de: 'Abmelden',
    en: 'Logout'
  },
  icon: <ExportOutlined />,
  onClick: async () => {
    await Auth.signOut()
    await dispatch({ type: 'USER_LOGOUT' })
    history.push(`/${lang}/login`)
  }
})

const USER_LINKS: LinksGenerator = props => [
  {
    title: {
      de: 'Einstellungen',
      en: 'Settings'
    },
    path: 'settings',
    icon: <IdcardOutlined />
  },
  {
    title: {
      de: 'Hilfe-Center',
      en: 'Help Center'
    },
    externalLink: 'https://help.top.legal/en',
    icon: <CustomerServiceOutlined />
  },
  /*
  {
    title: {
      de: 'Support',
      en: 'Support'
    },
    onClick: () => getIntercom()?.('show'),
    icon: <LikeOutlined />
  },
  */
  {
    title: {
      de: 'Feedback',
      en: 'Feedback'
    },
    path: 'support',
    icon: <BulbOutlined />
  },
  logoutLinkFactory(props)
]

/** ******************************************************************
 *              Nav sidebar component
 ****************************************************************** */
const NavSidebar: React.FC<NavSidebarProps> = ({ publicMode, links }) => {
  const { lang } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { setSearchOpen } = useContext(SearchModalContext)

  //  Which list in the nav bar are expanded
  const [openKeys, setOpenKeys] = useBrowserStorage<{ appLinks: string[]; userLinks: string[] }>(
    'nav-sidebar-open-keys',
    { appLinks: [], userLinks: [] },
    window.localStorage
  )

  const selectedOrganisation = useSelector(state => state.organisation.selectedOrganisation)
  const { topLegalComunityPlaybook = true } = selectedOrganisation || {}
  const user = useSelector(state => state.user.ownProfileData)
  const organisationRole = useMemo(() => {
    const orga = selectedOrganisation || {}
    const userData = (orga.members || {})[user.userID] || {}
    return userData.organisationRole || {}
  }, [selectedOrganisation, user])

  // ==================================================================================================

  // i think useMemo is the issue , ask fabien about it
  const linksParams = useMemo<LinkParams>(
    () => ({
      lang,
      history,
      dispatch,
      topLegalComunityPlaybook,
      user,
      organisation: selectedOrganisation,
      organisationRole,
      setSearchOpen
    }),
    [lang, history, dispatch, topLegalComunityPlaybook, user, selectedOrganisation, organisationRole, setSearchOpen]
  )

  const [appLinks, userLinks] = useMemo<[any[], any[]]>(() => {
    if (links) {
      return [links.appLinks(linksParams), links.userLinks(linksParams)]
    }
    if (publicMode) {
      return [PUBLIC_APP_LINKS(linksParams), PUBLIC_USER_LINKS(linksParams)]
    }
    // if (isPhone) {
    //   return [APP_LINKS(linksParams).slice(1), PUBLIC_USER_LINKS(linksParams)]
    // }
    return [APP_LINKS(linksParams), USER_LINKS(linksParams)]
  }, [links, linksParams, publicMode])

  // ==================================================================================================

  const buildLinks = (linkArray, prefix: any[] = []) => linkArray.map((link, index) => {
    const newPrefix = [...prefix, index]
    const key = newPrefix.join('.')

    /**
       * testing whether the link comes with a property and whether the
       * user is allowed to use this link (ie. the link is displayed)
       */
    if (typeof link.isAllowed === 'function') {
      if (!link.isAllowed()) {
        return null
      }
    }

    if (link.path) {
      return (
        <Menu.Item key={key} className={link.className}>
          {/* <NavLink to={`/${lang}/${link.path}`}> the correct version after refactoring of app side */}
          <NavLink to={`/${lang}/${link.path}`}>
            {link.icon}
            <span>{link.title[lang]}</span>
          </NavLink>
        </Menu.Item>
      )
    }
    if (link.onClick || link.externalLink) {
      return (
        <Menu.Item key={key} className={link.className}>
          {/* eslint-disable-next-line no-script-url */}
          <a
            href={link.externalLink || 'javascript:void(0)'}
            onClick={
              link.onClick
                && (evt => {
                  evt.preventDefault()
                  link.onClick(evt)
                })
            }
            target={link.externalLink && !link.noNewTab && '_blank'}
          >
            {link.icon}
            <span>{link.title[lang]}</span>
          </a>
        </Menu.Item>
      )
    }
    if (link.items) {
      return (
        <Menu.SubMenu
          key={key}
          className={link.className}
          title={
            <span>
              {link.icon}
              <span>{link.title[lang]}</span>
            </span>
          }
        >
          {buildLinks(link.items, newPrefix)}
        </Menu.SubMenu>
      )
    }
    return null
  })

  const menuProps: any = {
    mode: 'inline',
    theme: 'dark',
    selectable: false,
    selectedKeys: [] // Remove ant selection to avoid conflict with React router active class
  }

  // ==================================================================================================

  return (
    <div className='navSidebarContent' onClick={evt => evt.stopPropagation()}>
      <div className='navs'>
        <div className='appNav'>
          <Menu {...menuProps} onOpenChange={keys => setOpenKeys({ ...openKeys, appLinks: keys as string[] })} openKeys={openKeys.appLinks || []}>
            {buildLinks(appLinks)}
          </Menu>
        </div>
        <div className='userNav'>
          <Menu {...menuProps} onOpenChange={keys => setOpenKeys({ ...openKeys, userLinks: keys as string[] })} openKeys={openKeys.userLinks || []}>
            <Menu.SubMenu
              title={
                <span className='userDetails'>
                  <span className='userAvatarWrapper'>
                    <UserAvatar noModal noTooltip size='2.5rem' user={user} />
                  </span>
                  <span className='userNameDetails'>
                    <span className='userName'>
                      {user.firstName} {user.lastName}
                    </span>
                    {selectedOrganisation && <span className='userCompany'>{selectedOrganisation.name}</span>}
                  </span>
                </span>
              }
            >
              {buildLinks(userLinks)}
            </Menu.SubMenu>
          </Menu>
        </div>
      </div>
    </div>
  )
}

export default NavSidebar
