import React, { useContext, useLayoutEffect, useMemo, useState } from 'react'
import { Trans } from '@lingui/macro'
import { GlobalOutlined, QuestionOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Modal, Tag, Tooltip } from 'antd'
import Storage from '@aws-amplify/storage'
import { useDispatch } from 'react-redux'

import { useContractingPartiesCollection, useDoc } from '@top-legal/datastore'

import { allRoles } from '../UsersManagement'
import UserInformationDisplay from './UserInformationDisplay'
import { ExternalContext } from '../../ExternalViews/ExternalContext'
import { defaultCounterparty, defaultUser, UserAndCompanyDataContext } from '../../Layouts/Constants'
import { compareOrganisation } from './CompareUserRoles'

//  Styles
import './UserAvatarListStyles.scss'

// ==================================================================================================

export const userFullName = user => (Object(user) === user ? `${user.firstName || ''} ${user.lastName || ''}` : '')

export const useAvatarStyle = (size = '1rem') => useMemo(() => ({
  height: size,
  width: size,
  fontSize: (parseFloat(size) * 0.5) + size.replace(`${parseFloat(size)}`, ''),
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center'
}), [size])


// ==================================================================================================
// ==================================================================================================


const avatarStore = {}
const UserAvatar_ = ({ user = {}, noTooltip = false, size = '1rem', tooltipProps = null, noModal = false, autoSide = false, ...props }) => {
  const [avatarUrl, setAvatarUrl] = useState()
  const [modal, contextHolder] = Modal.useModal()
  useLayoutEffect(() => {
    if (user.avatar) {
      if (!avatarStore[user.avatar]) {
        const split = user.avatar.split('/')
        split.shift()
        const identityId = split.shift()
        const name = split.join('/')
        avatarStore[user.avatar] = Storage.get(name, {
          level: 'protected',
          identityId,
          expires: 60 * 60 // 1h
        })
      }
      avatarStore[user.avatar].then(setAvatarUrl)
    } else if (user.avatarUrl) {
      setTimeout(() => setAvatarUrl(user.avatarUrl), 500)
    } else {
      setAvatarUrl(null)
    }
  }, [user.avatar, user.avatarUrl])

  if (!noModal && ((user.userID && !user.userID.startsWith('team-') && !user.userID.startsWith('org-')) || user.partyID)) {
    props.onClick = () => {
      modal.info({
        key: user.userID || user.partyID,
        className: 'userInformationsModal mediumModal',
        title: <h1><Trans>User informations</Trans></h1>,
        content: <UserInformationDisplay user={user} />,
        getContainer: document.getElementById('topZIndexModalContainer'),
        maskClosable: true
      })
    }

    props.style = {
      ...(props.style || {}),
      cursor: 'pointer'
    }
  }


  props.style = {
    ...(props.style || {}),
    ...useAvatarStyle(size)
  }

  const uID = user.userID || user.partyID || user.contactID
  if (autoSide && uID) {
    if (!props.className) {
      props.className = ''
    }
    if (uID === defaultUser.userID || uID === defaultCounterparty.userID || /^(party|contact)-/.test(uID)) {
      props.className += ' extern'
    } else {
      props.className += ' intern'
    }
  }

  const defaultAvatar = <Avatar {...props} icon={user.icon || <UserOutlined />} />
  let avatar = null
  if (avatarUrl) {
    avatar = <Avatar {...props} alt={`${userFullName(user)} avatar`} src={avatarUrl} />
  } else if (user.firstName && user.lastName) {
    avatar = <Avatar {...props}>{`${user.firstName[0].toUpperCase()}${user.lastName[0].toUpperCase()}`}</Avatar>
  }

  if (noTooltip) {
    return <>{avatar || defaultAvatar}{contextHolder}</>
  }

  if (avatar || tooltipProps) {
    return <><Tooltip overlayClassName={`userAvatarTooltip ${props.className}`} title={userFullName(user)} {...tooltipProps}>{avatar || defaultAvatar}</Tooltip>{contextHolder}</>
  }

  return defaultAvatar
}
export const UserAvatar = React.memo(UserAvatar_)


// ==================================================================================================
// ==================================================================================================

const getUserIDStr = userID => {
  if (typeof userID === 'string') {
    return userID
  }
  if (Object(userID) === userID) {
    return userID.userID || userID.partyID || userID.contactID || ''
  }
  return ''
}

// ==================================================================================================
// ==================================================================================================

/**
 * getting the user data for a given userID.
 * - partyID/userID searches in the datastore for the counterpary
 * - if it is a member of our company, we return the user data
 * - teamID --> returns the name of the team.
 * ...
 *
 */
const useUser = userID => {
  const { tokenData } = useContext(ExternalContext)
  const { company } = useContext(UserAndCompanyDataContext)

  const partiesCollection = useContractingPartiesCollection()

  // search in the datastore for the contracting party.
  const [contact] = useDoc(useMemo(() => partiesCollection.findOne(getUserIDStr(userID)), [partiesCollection, userID]))

  const token = tokenData && tokenData.token
  return useMemo(() => {
    if (contact) {
      return contact
    }

    let data
    if (!token) {
      if (typeof userID === 'string' && userID) {
        if (userID === company.organisationID || userID === '__NoTeam') {
          return {
            userID,
            name: company.name,
            icon: <GlobalOutlined />
          }
        }
        if (userID === '__Admin') {
          return {
            userID,
            name: <Trans>Managers</Trans>,
            icon: <TeamOutlined />
          }
        }
        if (userID.startsWith('team-')) {
          return {
            userID,
            name: <Trans>Company wide</Trans>,
            ...(company.teams[userID] || {}),
            icon: <TeamOutlined />
          }
        }
        if (company.members) {
          data = company.members[userID]
        }
      }
    }

    if (!data && Object(userID) === userID) {
      data = userID
    }

    return data
  }, [company.members, company.name, company.organisationID, company.teams, contact, token, userID])
}


// ==================================================================================================
// ==================================================================================================

/**
 * Helper component that returns the user name that is wrapped with the highlight component. the
 * highlight component highlights the letters on search.
 */
export const UserFullName = ({ userID, Highlight = ({ text }) => text }) => {
  const userData = useUser(userID)
  if (userData && (userData.partyID || userData.userID)) {
    return <Highlight text={(userData.name || userFullName(userData))} />
  }
  if (userID === defaultUser.userID) {
    return <Trans>You</Trans>
  }
  if (userID === defaultCounterparty.userID) {
    return <Trans>Counterparty</Trans>
  }

  return <>-</>
}


// ==================================================================================================
// ==================================================================================================


const tooltipAvatarSize = '2rem'
const UserAvatarByID_ = ({ userID, role = null, size = '1rem', tooltip = {}, contentOnly = false, ...props }) => {
  const userData = useUser(userID)
  const userStyle = useAvatarStyle(tooltipAvatarSize)

  return useMemo(() => {
    if (!userData) {
      return <UserAvatar {...props} size={size} />
    }

    const tagRole = (!userData.organisationRole || userData.organisationRole.team !== '__Admin') && role && (allRoles[role.role] || {
      text: <Trans>Unknown</Trans>,
      color: 'grey',
      icon: <QuestionOutlined />
    })
    const content = (
      <div className='userAvatarDescription'>
        {
          userData.name
            ? <Avatar key={userID} icon={userData.icon} style={userStyle} />
            : <UserAvatar {...props} noTooltip size={tooltipAvatarSize} user={userData} />
        }
        <div className='userDescription'>
          <p>{userData.name || userFullName(userData)}</p>
          {userData.organisationRole && (
            <Tag color='blue'>
              {userData.organisationRole.team ? <UserFullName userID={userData.organisationRole.team} /> : <Trans>Company wide</Trans>}
            </Tag>
          )}
          {tagRole && <Tag color={tagRole.color}>{tagRole.icon} {tagRole.text}</Tag>}
        </div>
      </div>
    )

    if (contentOnly) {
      return content
    }

    return (
      <UserAvatar
        {...props}
        key={userID}
        size={size}
        tooltipProps={{ title: content, ...tooltip }}
        user={userData}
      />
    )
  }, [contentOnly, props, role, size, tooltip, userData, userID, userStyle])
}
export const UserAvatarByID = React.memo(UserAvatarByID_)


// ==================================================================================================
// ==================================================================================================


// ==================================================================================================


// ==================================================================================================
// ==================================================================================================


const maxNumber = 4
const UserAvatarList = ({ roles, size = '1rem', tooltip }) => {
  const userStyle = useAvatarStyle(size)

  if (Array.isArray(roles)) {
    const sortedRoles = roles.filter(({ userID, role }) => !userID.startsWith('org-') || role !== 'NoAccess').sort(compareOrganisation)
    let rolesToDisplay = sortedRoles

    //  Adjusting the number of roles to display
    if (sortedRoles.length > maxNumber) {
      rolesToDisplay = sortedRoles.slice(0, maxNumber - 1)
    }

    //  Render each roles
    rolesToDisplay = rolesToDisplay.map(({ userID, ...role }) => (
      <UserAvatarByID
        key={userID} autoSide role={role} size={size}
        tooltip={tooltip} userID={userID}
      />
    ))

    if (sortedRoles.length <= maxNumber) {
      return <>{rolesToDisplay}</>
    }

    const additionalRoles = sortedRoles.slice(maxNumber - 1)
    return (
      <>
        {[...rolesToDisplay, (
          <Tooltip
            key='additionalRoles'
            placement='leftBottom'
            title={(
              <div className='additionalRoles'>
                {additionalRoles.map(({ userID, ...role }) => (
                  <UserAvatarByID
                    key={userID} autoSide contentOnly role={role}
                    size={size} tooltip={tooltip}
                    userID={userID}
                  />
                ))}
              </div>
            )}
          >
            <Avatar style={{ ...userStyle, cursor: 'pointer' }}>+{additionalRoles.length}</Avatar>
          </Tooltip>
        )]}
      </>
    )
  }
  return null
}

export default UserAvatarList
