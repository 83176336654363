import React, { useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { Select } from 'antd'
import { UserAvatarByID, UserFullName } from '../../Organisations/UserRoleDisplay/UserAvatarList'

import './FilterStatisticsStyles.scss'

const FilterStatistics = ({ userCompanyData, filterTeams, filterEmployee, changeFilterEmployee, changeFilterTeams }) => {
  const currentUserID = userCompanyData.user.userID

  const getEmployeeList = useMemo(() => {
    if (filterTeams === 'all') { return Object.keys(userCompanyData.company.members) }
    return Object.keys(userCompanyData.company.members).filter(userID => filterTeams === userCompanyData.company.members[userID].organisationRole.team)
  }, [filterTeams, userCompanyData])


  const generateFilterOptions = () => {
    const teamID = userCompanyData.company.members[currentUserID].organisationRole.team
    if (teamID === '__Admin') {
      return (
        <div className='filterBox'>
          <div className='selectFilter'>
            <h4><Trans>Select Team</Trans></h4>
            <Select
              dropdownClassName='dropDown'
              onChange={value => {
                changeFilterTeams(value)
                changeFilterEmployee('all')
              }}
              size='small'
              value={filterTeams}
            >
              <Select.OptGroup label='Company'>
                <Select.Option value='all'> <Trans>Company wide statistics</Trans></Select.Option>
              </Select.OptGroup>
              <Select.OptGroup label='Teams'>
                {Object.keys(userCompanyData.company.teams).map(team => (
                  <Select.Option key={team} value={team}>
                    <UserAvatarByID autoSide size='1.5rem' userID={team} /><UserFullName userID={team} />
                  </Select.Option>
                ))}
              </Select.OptGroup>
            </Select>
          </div>
          <div className='selectFilter'>
            <h4><Trans>Select Employee</Trans></h4>
            <Select
              dropdownClassName='dropDown'
              onChange={value => changeFilterEmployee(value)}
              placeholder={<Trans>All Employees</Trans>}
              size='small'
              value={filterEmployee}
            >
              <Select.Option value='all'> <Trans>All Employees</Trans></Select.Option>
              {getEmployeeList.map(userID => (
                <Select.Option key={userID} value={userID}>
                  <UserAvatarByID autoSide size='1.5rem' userID={userID} /><UserFullName className='userName' userID={userID} />
                </Select.Option>
              ))}
            </Select>
          </div>

        </div>
      )
    }
    return (
      <div className='filterBox'>
        <div className='selectFilter'>
          <h4><Trans>Select Entity</Trans></h4>
          <Select
            defaultValue={userCompanyData.company.members[currentUserID].organisationRole.team}
            dropdownClassName='dropDown'
            onChange={value => {
              if (value.startsWith('team')) { changeFilterTeams(value) } else { changeFilterEmployee(value) }
            }}
            size='small'
          >
            <Select.OptGroup label={<Trans>Teams</Trans>}>
              <Select.Option value={userCompanyData.company.members[currentUserID].organisationRole.team}>
                <UserAvatarByID autoSide userID={userCompanyData.company.members[currentUserID].organisationRole.team} />
                <UserFullName className='userName' userID={userCompanyData.company.members[currentUserID].organisationRole.team} />
              </Select.Option>
            </Select.OptGroup>
            <Select.OptGroup label={<Trans>Employee</Trans>}>
              <Select.Option key={userCompanyData.user.userID} value={userCompanyData.user.userID}>
                <UserAvatarByID autoSide userID={userCompanyData.user.userID} />
                <UserFullName userID={userCompanyData.user.userID} />
              </Select.Option>
            </Select.OptGroup>
          </Select>
        </div>
      </div>
    )
  }
  return generateFilterOptions()
}
export default {
  key: 'filterStatistics',
  title: <Trans>Filter Statistics</Trans>,
  Component: FilterStatistics,
  width: 4,
  height: 2,
  mobileHeight: 1
}
