import React, { useMemo } from 'react'
import { ConfigProvider, Modal, notification, Empty } from 'antd'
import { Trans } from '@lingui/macro'

//  Translation catalogs
import DeAntLocale from 'antd/lib/locale-provider/de_DE' // eslint-disable-line
import EnAntLocale from 'antd/lib/locale-provider/en_US' // eslint-disable-line

const catalogs = {
  de: DeAntLocale,
  en: EnAntLocale
}

export const NoResultFound: React.FC = () => <Empty description={<Trans>No results found</Trans>} image={Empty.PRESENTED_IMAGE_SIMPLE} />

const AntDesignProvider: React.FC<{ lang: string }> = ({ lang, children }) => {
  const [notif, contextHolderNotif] = notification.useNotification()
  const [modal, contextHolderModal] = Modal.useModal()

  // defining the context for the notification
  useMemo(() => {
    (window as any).notification = { ...notification, ...notif }
    ;(window as any).modal = modal
  }, [modal, notif])

  return (
    <ConfigProvider locale={catalogs[lang]} renderEmpty={NoResultFound as any}>
      {contextHolderNotif}
      {contextHolderModal}
      {children}
    </ConfigProvider>
  )
}

export default AntDesignProvider
