import React, { ReactNode, useCallback, useRef } from 'react'
import anime from 'animejs'
import { Form, FormInstance, FormProps } from 'antd'
import { ConsoleLogger as Logger } from '@aws-amplify/core'
import { Trans } from '@lingui/macro'

import useEventSubscription from '../../hooks/useEventSubscription'
import {noop} from "../Defaults";

let FORM_ID_COUNTER = 0

type Handler = (handler: any) => void
export const CustomFormContext = React.createContext<[Handler, Handler]>([noop, noop])

interface CustomFormWrapperProps {
  onSubmit: (values: any, form: FormInstance) => Promise<any>
  onFail?: (errors: any) => void
  initialValues?: any
  noErrorNotif?: boolean
}

export const requiredRules = [{ required: true, message: <Trans>This field is required</Trans> }]

/**
 * On submit function is passed from to the custom element to handle the submit
 */
const xMax = 16
const logger = new Logger('custom form wrapper')


const CustomFormWrapper: React.FC<CustomFormWrapperProps & Omit<FormProps, 'onFinish' | 'onFinishFailed'> & { children?: ReactNode | ((form: FormInstance) => ReactNode)}> = ({
  onSubmit, onFail,
  initialValues = {},
  noErrorNotif,
  children,
  ...props
}) => {
  //  Handle submit action
  const [subscribeToSubmit, unSubscribeToSubmit, fireSubmitEvent] = useEventSubscription()
  const formID = useRef(FORM_ID_COUNTER++) // eslint-disable-line
  const [form] = Form.useForm()

  // ==================================================================================================
  /**
   * submit function runs the shake animation on error
   * @param {object} event
   */
  const handleForm = useCallback(async (values, errors) => {
    await fireSubmitEvent()

    const shake = anime({
      targets: `#animeForm${formID.current}`,
      easing: 'easeInOutSine',
      duration: 550,
      translateX: [{
        value: xMax * -1
      }, {
        value: xMax
      }, {
        value: xMax / -2
      }, {
        value: xMax / 2
      }, {
        value: 0
      }],
      autoplay: false,
      complete: () => {
        if (errors && typeof onFail === 'function') {
          onFail(errors)
        }
      }
    })

    if (errors) {
      shake.restart()
      logger.error(errors)
      if (!noErrorNotif) {
        (window as any).notification.warning({
          message: <Trans>Invalid form values</Trans>,
          description: <Trans>Please review your form values.</Trans>
        })
      }
    } else {
      try {
        await onSubmit(values, form)
      } catch (error) {
        logger.error('caught', error)
        shake.restart()
      }
    }
  }, [fireSubmitEvent, form, noErrorNotif, onFail, onSubmit])

  // ==================================================================================================

  return (
    <CustomFormContext.Provider value={[subscribeToSubmit, unSubscribeToSubmit]}>
      <Form
        {...props}
        form={form}
        id={`animeForm${formID.current}`}
        initialValues={initialValues}
        onFinish={handleForm as any}
        onFinishFailed={({ values, errorFields }) => handleForm(values, errorFields)}
      >
        {(typeof children === 'function' && !(children as any).$$typeof) ? (children as any)(form) : children}
      </Form>
    </CustomFormContext.Provider>
  )
}

export default CustomFormWrapper
