import React, { useMemo } from 'react'

import { isEmpty, RenderOnlyEditor } from '@top-legal/editor'
//  TODO: Replace with the new feature later
// import { MediaCard } from '@top-legal/legacy-app/src/components/Media/MediaLibrary'

import { AssistantInputProps } from '../Types'

const MediaCard: React.FC<any> = () => null

export const FieldExplanationBox: React.FC<{ className?: string }> = ({ className, children }) => (
  <div className={`inputFieldExplanation ${className}`}>
    {children}
  </div>
)

const FieldExplanation: React.FC<Pick<AssistantInputProps, 'field'>> = ({ field: { explanationText, explanationVideo } }) => {
  explanationVideo = '' // Disabled video feature for now // TODO: Use the next feature later

  const hasText = useMemo(() => {
    if (Array.isArray(explanationText)) {
      return !isEmpty(explanationText)
    }
    return false
  }, [explanationText])

  return ((hasText || explanationVideo) ? (
    <FieldExplanationBox className={explanationVideo ? 'withVideo' : ''}>
      {explanationVideo && (
        <div className='videoWrapper'><MediaCard media={explanationVideo} viewerOnly /></div>
      )}
      {hasText && (
        <div className='explanationWrapper'>
          <RenderOnlyEditor nodes={explanationText} />
        </div>
      )}
    </FieldExplanationBox>
  ) : null)
}

export default FieldExplanation
