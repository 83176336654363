import React, { useCallback, useContext, useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { Tooltip } from 'antd'

import { isInlineConditional } from '../../../core'
import { TemplateEntityProps } from '../../../core/customPlugins/TemplateEntities'

import EditorContractContext from '../../yjsEditor/EditorContractContext'
import RenderOnlyEditor from '../../RenderOnlyEditor'

import SelectedEntity from '../SelectedEntity'
import RecursiveTerminationContext from './RecursiveTerminationContext'


const ConditionalText: React.FC<TemplateEntityProps> = ({ dataKey, version, attributes, children }) => {
  const { useEntityGetters, useOnTemplateEntityClick, currentField } = useContext(EditorContractContext)
  const { getConditionalText, getConditionalTextValue, getField, getFieldValue } = useEntityGetters()
  const onTemplateEntityClick = useOnTemplateEntityClick()

  /**
   * Setup all variables use memo for avoiding too many updates
   */
  const conditionalText = useMemo(() => getConditionalText(dataKey, version), [getConditionalText, dataKey, version])
  const fieldKey = conditionalText && conditionalText.field
  const fieldVersion = conditionalText && conditionalText.fieldVersion
  const field = useMemo(() => fieldKey && getField(fieldKey, fieldVersion), [fieldKey, getField, fieldVersion])
  const fieldValue = useMemo(() => fieldKey && getFieldValue(fieldKey), [fieldKey, getFieldValue])
  const conditionalValue = useMemo(() => getConditionalTextValue(dataKey), [getConditionalTextValue, dataKey])

  const className = useMemo<string>(
    () => {
      const classes: string[] = [attributes.className || '']
      if (currentField && (currentField.key === fieldKey || currentField.condKey === dataKey)) {
        classes.push('blink')
      }
      if (isInlineConditional(conditionalText)) {
        classes.push('inlineElement')
      } else {
        classes.push('blockElement')
      }
      return classes.join(' ')
    },
    [attributes.className, conditionalText, currentField, dataKey, fieldKey]
  )

  /**
   * On click handler
   */
  const onClick = useCallback(() => onTemplateEntityClick('conditionalText', dataKey), [dataKey, onTemplateEntityClick])
  const Elem = isInlineConditional(conditionalText) ? 'span' : 'div'

  //  Recursion safety guard
  const recursion = useContext(RecursiveTerminationContext)
  const childRecursion = useMemo(() => [...recursion, dataKey], [recursion, dataKey])

  /**
   * Return a memoized content
   */
  const content = useMemo(() => {
    //  If we found it
    if (conditionalText) {
      if (field) {
        if (fieldValue != null) {
          const index = recursion.findIndex(str => str === dataKey)
          if (index >= 0) {
            return (
              <Tooltip title={<Trans>We have detected an if-then condition that refers to itself. Please change the logic (parent: {index + 1})</Trans>}>
                <span className='invalidTemplateEntity' contentEditable={false}>
                  /!\ <Trans>Self-referring logic</Trans> /!\
                </span>
              </Tooltip>
            )
          }

          return (
            <RecursiveTerminationContext.Provider value={childRecursion}>
              <RenderOnlyEditor nodes={conditionalValue} />
            </RecursiveTerminationContext.Provider>
          )
        }
      } else {
        return <span className='invalidTemplateEntity'>/!\ <Trans>Invalid conditional text!</Trans> /!\</span>
      }

      //  Else display the cond name
      return conditionalText.name
    }

    //  The ultimate case is a invalid field key
    return <span className='invalidTemplateEntity'>/!\ <Trans>Invalid conditional text!</Trans> /!\</span>
  }, [conditionalText, field, fieldValue, recursion, childRecursion, conditionalValue, dataKey])

  return (
    <SelectedEntity {...attributes} Elem={Elem} className={className} onClick={onClick}>
      {children}
      {content}
      {children}
    </SelectedEntity>
  )
}

export default ConditionalText
