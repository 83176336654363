import React from 'react'
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Tree } from 'antd'
import { Trans } from '@lingui/macro'
import { ConsoleLogger as Logger } from '@aws-amplify/core'

const logger = new Logger('tree edition')

// ==================================================================================================
// ==================================================================================================

const getElem = (path, parent) => {
  if (path.length && parent) {
    // removes the first element from the path array and returns it
    const index = path.shift()
    return getElem(path, (parent.items || parent)[index])
  }
  return parent
}
// ==================================================================================================
// ==================================================================================================
// Copy example from https://ant.design/components/tree/#components-tree-demo-draggable
// But the onDrop function is piece of shit so change it
class TreeEdition extends React.Component {
  // ==================================================================================================
  constructor (props) {
    super(props)

    this.innerForm = React.createRef()

    this.state = {
      promise: null
    }
  }

  // ==================================================================================================
  add = async path => {
    try {
      let elem = getElem(path, this.props.value)
      const tmp = {}
      Object.defineProperty(tmp, '_parent', {
        value: elem,
        configurable: true
      })

      const newEl = await this.onEdit(tmp, {
        title: <Trans>Create new item</Trans>,
        okText: <Trans>Create</Trans>
      })

      elem = getElem(path, this.props.value)
      if (Array.isArray(elem)) {
        elem.push(newEl)
      } else if (elem.items) {
        elem.items.push(newEl)
      } else {
        elem.items = [newEl]
      }

      setTimeout(() => this.props.onChange(this.props.value), 100)
    } catch (err) {
      logger.error('Add err:', err)
    }
  }

  // ==================================================================================================
  /**
   * @param {object} path is the index of the item that is being edited.
   */
  edit = async path => {
    try {
      // getting the correct item from the value properties
      let textElement = getElem([...path], this.props.value)


      const index = path.pop()

      //  Call the onEdit callback with a copy and get it's return
      const tmp = { ...textElement }

      Object.defineProperty(tmp, '_parent', {
        value: textElement._parent,
        configurable: true
      })

      const newValue = await this.onEdit(tmp, {
        title: <Trans>Edit selected item</Trans>,
        okText: <Trans>Validate</Trans>
      })

      //  If a new value was returned assign it and update
      if (newValue) {
        textElement = getElem([...path], this.props.value)
        textElement = textElement.items || textElement
        textElement && (textElement[index] = { ...tmp, ...newValue })
        setTimeout(() => this.props.onChange(this.props.value), 100)
      } //  In the other case it's edit cancellation so just skip it
    } catch (err) {
      logger.error('Edit err:', err)
    }
  }

  // ==================================================================================================
  onEdit = async (item, modal) => new Promise((resolve, reject) => {
    this.setState({
      promise: {
        resolve,
        reject,
        item,
        modal
      }
    })
  }).then(values => {
    this.setState({ promise: null })
    return values
  })

  // ==================================================================================================
  remove = path => {
    const index = path.pop()
    let elem = getElem(path, this.props.value)
    elem = elem.items || elem
    elem && elem.splice(index, 1)
    this.props.onChange(this.props.value)
  }

  // ==================================================================================================
  onDrop = async info => {
    const onMove = this.props.onMove || (() => Promise.resolve())

    if (!info || !info.node || !info.dragNode) { return } // Can arrive when we drop in other tree
    /**
     * Get keys and split with - for looking the good object
     */
    const dropKeyPath = info.node.props.eventKey.split('-')
    const dragKeyPath = info.dragNode.props.eventKey.split('-')

    /**
     * If drop is restricted by a depth check the depth and denied it if not good
     */
    if (this.props.maxDepth && dropKeyPath.length - (info.dropToGap ? 1 : 0) >= this.props.maxDepth) {
      window.notification.error({
        message: <Trans>Invalid drop location</Trans>,
        description: <Trans>You cannot drop this element here because of the depth restriction!</Trans>
      })
    } else {
      /**
       * Looking function to get the object with the given path
       */
      const getObject = path => {
        let current = { items: this.props.value }
        path.forEach(index => {
          if (current && current.items) {
            current = current.items[index]
          }
        })
        return current
      }

      /**
       * Get needed elements
       */
      const dragParent = getObject(dragKeyPath.slice(0, -1))
      // Currently if it's insert to gap we need to remove last one index -> Maybe it's ant issue in fact it must be the correct path
      const reelDstPath = info.dropToGap ? dropKeyPath.slice(0, -1) : dropKeyPath
      const dropElem = getObject([...reelDstPath])
      const dragChildKey = dragKeyPath.slice(-1)[0]

      let dstIndex = ''

      if (info.dropToGap) {
        dstIndex = Math.max(0, info.dropPosition)
      } else {
        dstIndex = (dropElem.items ? dropElem.items.length : 0)
      }

      try {
        await onMove(dragKeyPath, reelDstPath, dstIndex)

        /**
         * Remove the element from original parent
         */
        const dragChild = dragParent.items[dragChildKey] // To avoid mistakes if it's the same parent save child before deletion
        dragParent.items.splice(dragChildKey, 1) // And delete before addition

        /**
         * Move the object
         */
        if (dropElem.items) {
          dropElem.items.splice(dstIndex, 0, dragChild)
        } else {
          dropElem.items = [dragChild] // No items array -> create it
        }

        /**
         * And finally update the form value
         */
        this.props.onChange(this.props.value)
      } catch (err) {
        console.error('onDrop - onMove error:', err)
        throw err
      }
    }
  }

  onSubmit = () => {
    if (this.innerForm.current && this.innerForm.current.props && this.innerForm.current.props.form) {
      this.innerForm.current.props.form.validateFields(async (errors, values) => {
        if (errors) {
          window.notification.info({
            message: <Trans>Please review the form data!</Trans>
          })
        } else {
          this.state.promise.resolve(values)
        }
      })
    } else {
      console.error('From instance is undefined')
    }
  }

  // ==================================================================================================
  render () {
    const onRowClick = this.props.onRowClick || (() => null)

    const displayItem = typeof this.props.label === 'function'
      ? this.props.label : item => <span>{item[this.props.label]}</span>


    // --------------------------------------------------------------------------------------------------
    /**
     * function to create tree nodes for all items provided to the function
     * @param path
     */
    const createTreeNodeLoop = (items, parent, path = [], depth = 1) => items.map((item, index) => {
      Object.defineProperty(item, '_parent', {
        value: parent,
        configurable: true
      })

      const currentPath = [...path, index]
      const key = currentPath.join('-')

      const treeEditionRow = (
        <div className='treeEditionRow' onClick={element => onRowClick(element, item)} role='treeitem' tabIndex={index}>
          <div className='treeEditionTitle'>{displayItem(item)}</div>
          {
            /**
             * displaying/ hiding the action buttons for the item. Used to edit an item for conditional
             * text for example.
             */}
          {!this.props.hideButtons && (
            <Button.Group className='treeEditionActions' size='small' type='default'>
              {!this.props.disableNew && (!this.props.maxDepth || depth < this.props.maxDepth) && (
                <Button ghost icon={<PlusCircleOutlined />} onClick={() => this.add(currentPath)} type='primary' />
              )}
              <Button ghost icon={<EditOutlined />} onClick={() => this.edit(currentPath)} type='default' />
              {!this.props.disableDelete && (
                <Button ghost icon={<DeleteOutlined />} onClick={() => this.remove(currentPath)} type='danger' />
              )}
            </Button.Group>
          )}
        </div>
      )

      if (item.items && item.items.length) {
        return (
          <Tree.TreeNode key={key} selectable={false} title={treeEditionRow}>
            {createTreeNodeLoop(item.items, item, currentPath, depth + 1)}
          </Tree.TreeNode>
        )
      }
      return (<Tree.TreeNode key={key} selectable={false} title={treeEditionRow} />)
    })

    // --------------------------------------------------------------------------------------------------

    let { value } = this.props
    const { style, className, id } = this.props

    /**
     * if there are no values, assign an empty array or the values from the
     * properties.
     */
    if (!value) {
      value = this.props.values || []
    }

    const parent = typeof this.props.parent === 'function' ? this.props.parent() : this.props.parent

    Object.defineProperty(value, '_parent', {
      value: parent,
      configurable: true
    })

    return (
      <div className={className} id={id} style={style}>
        <div
          className={`treeContainer ${this.props.noDraggable ? '' : 'draggable'} ${this.props.treeContainerClass || ''}`}
          style={{
            maxHeight: '100%',
            overflowY: 'auto'
          }}
        >
          <Tree
            blockNode
            defaultExpandAll={!this.props.collapsed}
            draggable={!this.props.noDraggable}
            onDrop={this.onDrop}
          >
            {createTreeNodeLoop(value, value)}
          </Tree>
        </div>
        {
          !this.props.disableNew && !this.props.hideButtons
          && <Button className='treeEditionAddNew' icon={<PlusCircleOutlined />} onClick={() => this.add([])} type='primary'><Trans>Add new input</Trans></Button>
        }

        {/* Edition modal */}
        {this.state.promise
          ? (
            <Modal
              className={`treeModal mediumModal ${this.props.modalClassName || ''}`}
              footer={this.props.noModalFooter ? null : undefined}
              okText={this.state.promise.modal.okText}
              onCancel={() => {
                this.state.promise.reject('Canceled modifications!')
                this.setState({ promise: null })
              }}
              onOk={this.onSubmit}
              title={<h1>{this.state.promise.modal.title}</h1>}
              visible
              width={this.props.modalWidth || '60%'}
            >
              {React.cloneElement(this.props.children, {
                initialValue: this.state.promise.item,
                //  Basic form
                ref: this.innerForm,
                //  FormFactory
                hideSubmit: true,
                //  For other kind of complex component expose resolve and reject
                //  Resolve want values object as params
                resolve: this.state.promise.resolve,
                reject: this.state.promise.reject
              })}
            </Modal>
          )
          : null}
      </div>
    )
  }
}

export default TreeEdition
