import React, { useMemo, useState } from 'react'
import { Collapse } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'

import { UserFullName } from '../../../../../Organisations/UserRoleDisplay/UserAvatarList'


const expandIcon = ({ isActive }: any) => <CaretRightOutlined rotate={isActive ? 90 : 0} />

/**
 * This is the header for accordion (collapse item). For the signature drawer
 * the signature is the company name (e.g. top.legal GmbH). If the signee is a  person,
 * then the header is the name of the person.  
 */
const PartyCollapseHeader: React.FC<{ groupID: string }> = ({ groupID }) => (
  <h3><UserFullName userID={groupID} /></h3>
)


export interface PartiesCollapseProps {
  groups: {
    groupID: string,
    children: React.ReactElement
  }[]
}

/**
 * component is used to list the parties on a contract in the sidebar.
 *  
 */

const PartiesCollapse: React.FC<PartiesCollapseProps> = ({ groups }) => (
  <div className='userManagementParties'>
    <Collapse
      defaultActiveKey={useMemo(() => groups.map(({ groupID }) => groupID), [groups])}
      expandIcon={expandIcon}
      ghost
    >
      {groups.map(({ groupID, children }) => (
        <Collapse.Panel key={groupID} header={<PartyCollapseHeader groupID={groupID} />}>
          {children}
        </Collapse.Panel>
      ))}
    </Collapse>
  </div>
)

export default PartiesCollapse
