import React, { useLayoutEffect } from 'react'
import 'antd/es/popover/style' // eslint-disable-line
import { useFocusedEditor } from '../Helpers'


/** ******************************************************************
 *            Balloon toolbar (constructed as ant popover)
 ****************************************************************** */
const BalloonToolbar: React.FC = ({ children }) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const editor = useFocusedEditor()

  useLayoutEffect(() => {
    const elem = ref.current
    if (elem) {
      if (elem.parentElement) {
        const domSelection = window.getSelection()
        if (domSelection && domSelection.rangeCount > 0) {
          const domRange = domSelection.getRangeAt(0)
          if (domRange.startContainer !== domRange.endContainer || domRange.startOffset !== domRange.endOffset) {
            const rect = domRange.getBoundingClientRect()
            const elemRect = elem.getBoundingClientRect()
            const parentRect = elem.parentElement.getBoundingClientRect()

            elem.style.top = `${rect.top - parentRect.top - elemRect.height}px`
            elem.style.left = `${rect.left - parentRect.left + rect.width / 2 - elemRect.width / 2}px`

            elem.classList.remove('hidden')
            return
          }
        }
      }
      elem.classList.add('hidden')
    }
  }, [editor?.selection])

  return (
    <div ref={ref} className='editorBalloonBar ant-popover ant-popover-placement-top hidden'>
      <div className='ant-popover-content'>
        <div className='ant-popover-arrow' />
        <div className='ant-popover-inner' role='tooltip'>
          <div>
            <div className='ant-popover-inner-content'>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BalloonToolbar
