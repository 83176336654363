//  First what is a stamp? A displayName, a signingDate, maybe some extra access data?
//  How can we abstract the gathering of the stamps?
//  Where does the data come from?
//   - If we are in signing mode we do have a SignatureHolder that manage signatures attached to the contract
//   - If we have a contract but not in signing yet, that is the contract and the fields responses that hold the data
//   - And if we are in a template only the data come from the parties attached to the template
//  ==> Then we can create a getter function and provides that objects

import { ContractParty, SignaturesHolder } from './Types'

export interface SignatureStampInfo {
  displayName: string // Can contains \n
  personName?: string
  companyName?: string
  partyID?: string
  signatureID?: string
  signedDate?: string
  signingMethod?: 'phone' | 'email'
  userAccess?: string
  token?: any

  //  If the signing flow is configured with handwriting we can have the following configuration
  handwriting?: { text: string, font: string }
}

interface ContractBase {
  contractID: string
  parties?: Record<string, ContractParty>
  fieldsResponse?: Record<string, any>
}


//  Better to keep the same empty array ref for FE memoization
const noSignatures = []


// ==============================================================================================================================


// ==============================================================================================================================


/**
 * Get signature display name from signature
 */
const getSignatureDisplayName = (signature: any): Pick<SignatureStampInfo, 'displayName' | 'personName' | 'companyName'> => {
  if (signature) {
    const companyName = signature.companyName || signature.name || undefined
    const personName = (signature.firstName && signature.lastName && `${signature.firstName} ${signature.lastName}`) || undefined

    const result = (displayName: string) => ({ displayName, personName, companyName })

    if (personName) {
      if (signature.type === 'company' && companyName) {
        return result(`${personName}\n${companyName}`)
      }
      return result(personName)
    }
    if (signature.type === 'company' && companyName) {
      return result(companyName)
    }
    if (signature.entityName) {
      return result(signature.entityName)
    }
  }
  return { displayName: '-' }
}


/**
 * Get signature info from signature holder
 */
const capitalize = (str, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase())
const getSignatureHolderStamps = (signatureHolder: SignaturesHolder): SignatureStampInfo[] => {
  if (Object(signatureHolder) === signatureHolder) {
    const { signatures } = signatureHolder

    if (Array.isArray(signatures)) {
      return signatures.map(signature => {
        const stamp: SignatureStampInfo = {
          ...getSignatureDisplayName(signature),
          partyID: signature.partyID,
          signatureID: signature.signatureID,
          signedDate: signature.signedDate,
          signingMethod: signature.signingMethod,
          userAccess: signature.userAccess,
          token: (signature as any).token
        }

        //  Attach handwriting signature to stamp if configured for
        if (signatureHolder.withHandwritingSignature && signature.selectedHandwriting && signature.firstName && signature.lastName) {
          stamp.handwriting = {
            text: capitalize(`${signature.firstName} ${signature.lastName}`, true),
            font: signature.selectedHandwriting
          }
        }

        return stamp
      })
    }
  }
  return noSignatures
}


// ==============================================================================================================================


// ==============================================================================================================================


/**
 * Get signature info from contract
 */
const getContractStamps = (template: any, contract: ContractBase): SignatureStampInfo[] => {
  if (Object(contract) === contract && Object(template) === template) {
    const { fields, parties: templateParties = {} } = template
    const { parties, fieldsResponse } = contract

    if (Object(fields) === fields && parties && Object(parties) === parties && fieldsResponse && Object(fieldsResponse) === fieldsResponse) {
      const signatures: SignatureStampInfo[] = []

      //  Note: If partyValue contains signer: false it has been switched off, if true or not set it is considered as signer
      Object.entries(parties).forEach(([partyKey, partyValue]) => {
        const partyID = (partyValue && partyValue.partyID) || (typeof partyValue === 'string' && partyValue) || ''
        const field = fields[partyKey]
        let displayName: Pick<SignatureStampInfo, 'displayName' | 'personName' | 'companyName'>

        //  If a field exist & it is in bound templateParties
        if (field && templateParties[`${partyKey}.signature`]) {
          //  When response is from DB it is a object with primary keys & value the real content otherwise it is already the content
          const response = fieldsResponse[partyKey] && (fieldsResponse[partyKey].value || fieldsResponse[partyKey])
          if (response) {
            response.type = field.type // Bind party type to the response for the getter
          }
          displayName = getSignatureDisplayName(response)
        } else {
          //  Else the party might have been added
          //  I've seen that the additional parties the key is the legal entity name
          displayName = { displayName: partyValue.entityName || partyKey.replace(/_/g, ' ') }
        }

        //  Check if the party is signing
        if (Object(partyValue) !== partyValue || partyValue.signer !== false) {
          //  Add the signature
          signatures.push({
            ...displayName,
            partyID,
            userAccess: partyValue && (partyValue as any).userAccess,
            token: partyValue && partyValue.token
          })
        }

        //  Treat potential nested signers
        if (Object(partyValue) === partyValue && Array.isArray(partyValue.additionalUsers)) {
          partyValue.additionalUsers.forEach(data => {
            if (data && data.signer !== false) {
              //  Add the signature
              signatures.push({
                ...displayName,
                partyID: data.partyID,
                userAccess: partyValue && (partyValue as any).userAccess,
                token: partyValue && partyValue.token
              })
            }
          })
        }
      })

      if (signatures.length > 0) {
        return signatures
      }
    }
  }

  return noSignatures
}


// ==============================================================================================================================


// ==============================================================================================================================


/**
 * Get signature info from template
 */
const getTemplateStamps = (template: any): SignatureStampInfo[] => {
  if (Object(template) === template) {
    const { fields, parties } = template

    if (Object(fields) === fields && Object(parties) === parties) {
      const signatures: SignatureStampInfo[] = []

      Object.keys(parties).forEach(partyKey => {
        if (partyKey.includes('signature')) {
          const field = fields[partyKey.split('.')[0]]

          //  If a field exist & it is in bound templateParties
          if (field) {
            signatures.push({ displayName: field.name })
          }
        }
      })

      if (signatures.length > 0) {
        return signatures
      }
    }
  }

  return noSignatures
}


// ==============================================================================================================================


// ==============================================================================================================================


/**
 * Get the signature stamps info
 * @param template needs to have the fields filled in please have a look at fillTemplateEntities in TemplateHelperFunctions
 * @param contract needs to have the fieldResponses attached to it
 * @param signaturesHolder needs to have the signatures array filled with signatures object
 * @return {SignatureStampInfo[]}
 */
const getSignatureStampsInfo = (template: any, contract?: any, signaturesHolder?: SignaturesHolder): SignatureStampInfo[] => {
  if (signaturesHolder) {
    return getSignatureHolderStamps(signaturesHolder)
  }
  if (template) {
    if (contract && contract.contractID && contract.contractID !== '__preview__') { // Template editor has a fake contract that we need to ignore
      return getContractStamps(template, contract)
    }
    return getTemplateStamps(template)
  }
  return noSignatures
}

export default getSignatureStampsInfo
