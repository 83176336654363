/* eslint-disable id-length */

const isSupportedBrowser = async () => {
  // synchronously throws in IE/Edge
  const workerCode = `self.onmessage = ${(
    function () {
      function error () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        self.postMessage({ hasIndexedDB: false }) // eslint-disable-line
      }
      function success () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        self.postMessage({ hasIndexedDB: true }) // eslint-disable-line
      }

      // This works on all devices/browsers, and uses IndexedDBShim as a final fallback
      if (typeof indexedDB !== 'undefined') {
        // Open (or create) the database
        const open = indexedDB.open('__my_test_db__', 1)

        // Create the schema
        open.onupgradeneeded = function () {
          const db = open.result
          db.createObjectStore('__store__', { keyPath: 'id' })
          db.onerror = error
        }
        open.onsuccess = function () {
          // Start a new transaction
          const db = open.result
          db.onerror = error
          const tx = db.transaction('__store__', 'readwrite')
          const store = tx.objectStore('__store__')

          // Test read write
          store.put({ id: 4269, foo: 'bar' })
          store.get(4269)

          // Close the db when the transaction is done
          tx.oncomplete = function () {
            db.close()
            const del = indexedDB.deleteDatabase('__my_test_db__')
            del.onsuccess = success
            del.onerror = error
          }
          tx.onerror = error
        }

        open.onerror = error
      } else {
        error()
      }
    }
  ).toString()}`

  const worker = new Worker(URL.createObjectURL(new Blob([workerCode], { type: 'text/javascript' })))

  return new Promise((resolve, reject) => {
    function listener (e) {
      worker.terminate()
      if (e.data.hasIndexedDB) {
        resolve(true)
        return
      }
      reject()
    }

    function errorListener () {
      worker.terminate()
      reject()
    }

    worker.addEventListener('error', errorListener)
    worker.addEventListener('message', listener)
    worker.postMessage({})
  }).catch(err => {
    if (typeof console !== 'undefined' && typeof console.error === 'function') {
      console.error('This browser is not supported by WorkerPouch', err)
    }
    return false
  })
}

export default isSupportedBrowser
