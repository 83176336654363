import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react'
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2'

import { CustomInput } from '../Types'
import './PhoneInputStyles.scss'


const PhoneInput: React.FC<PhoneInputProps & CustomInput<string> & { displayOnly?: string }> = ({
  value, onChange,
  disabled, displayOnly, className = '',
  ...props
}) => {
  const divRef = useRef(null)
  const resizeObserver = useMemo(() => new ResizeObserver(([entry]) => {
    (entry.target as any).style.setProperty('--flag-scale', (parseFloat(window.getComputedStyle(document.body).fontSize) * 1.2) / 20)
  }), [])

  useLayoutEffect(() => {
    const elem = divRef.current
    if (elem) {
      resizeObserver.observe(elem)
      return () => resizeObserver.unobserve(elem)
    }
    return undefined
  }, [resizeObserver])

  return (
    <div ref={divRef}>
      <ReactPhoneInput
        {...props}
        containerClass={`phoneInput ${disabled ? 'disabled' : ''} ${displayOnly ? 'displayOnly' : ''}`}
        copyNumbersOnly={false}
        country='de'
        disabled={disabled}
        dropdownClass='phoneInputDropdown'
        enableSearch
        inputClass={`ant-input ${className}`}
        onChange={useCallback(newValue => onChange?.(`+${newValue}`), [onChange])}
        value={value}
      />
    </div>
  )
}

export default PhoneInput
