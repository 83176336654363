import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { Button, Drawer, Dropdown, Menu, Tag, Tooltip } from 'antd'
import {
  BellOutlined,
  CarryOutOutlined,
  CheckOutlined,
  DeleteOutlined,
  DeliveredProcedureOutlined,
  EditOutlined,
  FileOutlined,
  LoadingOutlined,
  MoreOutlined,
  RightOutlined,
  UserOutlined
} from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import moment from 'moment'


import {
  Template, useTemplateListBaseQuery,
  Contract, useContractsListBaseQuery,
  Comment, useContractCommentsCollection, useTemplateCommentsCollection, useSaveCommentInStore,
  useList, toJsonList
} from '@top-legal/datastore'

// components
import { DeviceContext } from '../../GlobalContext'
import { UserAndCompanyDataContext } from '../Layouts/Constants'
import { withUserData } from '../Layouts/AuthenticatedPage'
import { ConfirmMenuItem } from '../SharedComponents/FormComponents'
import NewComment from '../Contract/ContractEditor/Sidebar/SidebarToolboxes/Commenting/NewComment'
import { UserAvatarByID, UserFullName } from '../Organisations/UserRoleDisplay/UserAvatarList'
import { deleteComment, saveComment } from '../Organisations/redux/OrganisationsActions'
import { emptyArray, emptyObject } from '../Listing/SearchHelpers'

// services
import RestService from '../../RestService'
import MainContainer from '../Layouts/MainLayout/MainContainer'

// actions
import { createColumn, DataTable } from '../SharedComponents/DataTable'

// style
import './Task.scss'
import '../Contract/styles/ContractListing.scss'
import '../Contract/ContractEditor/Sidebar/SidebarToolboxes/Commenting/CommentStyles.scss'

/**
 * task element that display all the tasks
 * we can edit,delete and also filter the tasks
 */
export const taskStatusOptions = {
  AllTasks: {
    text: <Trans>All tasks</Trans>,
    color: 'gold',
    icon: <FileOutlined />
  },
  Mytasks: {
    text: <Trans>My tasks</Trans>,
    color: 'gold',
    icon: <BellOutlined />
  },
  Completed: {
    text: <Trans>Completed</Trans>,
    color: 'gold',
    icon: <CheckOutlined />
  }

}


const empty = {}
const dbTask = task => task?.toJSON?.() || task || empty
const systemTaskDisabledMessage = <Trans>Automatic tasks cannot be modified</Trans>

const useUpdateTask = (record: any, setButtonLoading: (bool: boolean) => void) => {
  const dispatch = useDispatch()
  const saveCommentInStore = useSaveCommentInStore()

  return useCallback(async (newValues: any, remote = true) => {
    setButtonLoading(true)
    try {
      const update: any = remote
        ? await dispatch(saveComment({ ...dbTask(record), ...newValues }))
        : { ...dbTask(record), ...newValues }

      await saveCommentInStore(update)

      ;(window as any).notification.success({ message: <Trans>The task has been successfully updated</Trans> })
      setButtonLoading(false)
    } catch (err) {
      console.error('Failed to update task', record, newValues, err)
      setButtonLoading(false)
      throw err
    }
  }, [record])
}

// =====================================================================================================================================================
/**
 * this function will be rendred for the action column
 * here we have two option either the task is done or not
 * if it is done so we can undo complete or delete the task
 * otherwise we have the access to edit the task
 */
export const ActionTask = ({ record }) => {
  const taskDate = record.realDate || record.date
  const [editing, setEditing] = useState<Comment>()
  const [buttonLoading, setButtonLoading] = useState(false)
  const dispatch = useDispatch()
  const { user: { userID: currentUser } } = useContext(UserAndCompanyDataContext)


  const updateTask = useUpdateTask(record, setButtonLoading)

  const deleteTask = useMemo(() => (
    <ConfirmMenuItem
      confirmMessage={<Trans>Are you sure you want to delete this task?</Trans>}
      danger
      // eslint-disable-next-line no-console
      onClick={async () => {
        setButtonLoading(true)
        try {
          await dispatch(deleteComment(record))
          await updateTask({ deleted: true }, false)
          ;(window as any).notification.success({ message: <Trans>The task has been deleted successfully</Trans> })
          setButtonLoading(false)
        } catch (err) {
          console.error('Failed to delete task', record, err)
          setButtonLoading(false)
          throw err
        }
      }}
    >
      <DeleteOutlined />
      <span><Trans>Delete</Trans></span>
    </ConfirmMenuItem>
  ), [dispatch, record, updateTask])


  return (
    <>
      {!buttonLoading ? (
        <Dropdown
          disabled={!!record.systemTask}
          overlay={(
            <Menu key='menu'>
              {record.done ? (
                <>
                  <Menu.Item onClick={() => updateTask({ done: false })}>
                    <CheckOutlined />
                    <span><Trans>Undo complete</Trans></span>
                  </Menu.Item>
                  {deleteTask}
                </>
              ) : (
                <>
                  <Menu.Item onClick={() => updateTask({ done: true })}>
                    <CheckOutlined />
                    <span><Trans>Mark complete</Trans></span>
                  </Menu.Item>
                  <Menu.Item onClick={() => { setEditing(dbTask(record)) }}>
                    <EditOutlined />
                    <span><Trans>Edit</Trans></span>
                  </Menu.Item>
                  <Menu.Item onClick={() => { setEditing(dbTask(record)) }}>
                    <UserOutlined />
                    <span><Trans>Reassign</Trans></span>
                  </Menu.Item>
                  {record.assignedTo !== currentUser && (
                    <Menu.Item
                      onClick={async () => {
                        setButtonLoading(true)
                        try {
                          await RestService('POST', `/comments/${record.instanceID}/${taskDate}/remindTask`)
                          ;(window as any).notification.success({
                            message: <Trans>An email will be sent to <UserFullName userID={record.assignedTo} /></Trans>
                          })
                          setButtonLoading(false)
                        } catch (err) {
                          console.error('Failed to send reminder', record, err)
                        }
                      }}
                    >
                      <DeliveredProcedureOutlined />
                      <span><Trans>Send reminder</Trans></span>
                    </Menu.Item>
                  )}
                  {deleteTask}
                </>
              )}
            </Menu>
          )}
          trigger = {['click']}
        >
          {(() => {
            let button = (
              <Button
                className='moreButton'
                disabled={!!record.systemTask}
                ghost
                icon={<MoreOutlined />}
                loading={buttonLoading}
                size='small'
              />
            )
            if (record.systemTask) {
              button = (
                <Tooltip title={systemTaskDisabledMessage} placement='topRight'>
                  {button}
                </Tooltip>
              )
            }
            return button
          })()}
        </Dropdown>
      ) : (
        <LoadingOutlined spin />
      )}
      <Drawer
        className='drawerStyle'
        destroyOnClose
        onClose={() => setEditing(undefined)}
        title={<><UserOutlined className='headerIcon' twoToneColor='#3DBD7D' /><h1><Trans>Edit task</Trans></h1></>}
        visible={!!editing}
      >
        {editing && (
          <NewComment
            className='comment task'
            close={comm => {
              setEditing(undefined)
              if (comm) {
                updateTask(comm, false)
              }
            }}
            initialData={editing as any}
          />
        )}
      </Drawer>
    </>
  )
}

export const StatusAction = ({ record }) => {
  const [buttonLoading, setButtonLoading] = useState(false)
  const isPhone = useContext(DeviceContext) === 'phone'

  const updateTask = useUpdateTask(record, setButtonLoading)

  return (
    <div className='centerItems'>
      <Tooltip
        title={record.systemTask ? systemTaskDisabledMessage : (
          record.done ? <Trans>Undo complete</Trans> : <Trans>Mark complete</Trans>
        )}
        placement='topLeft'
      >
        <Button
          disabled={record.systemTask}
          ghost={!record.done}
          icon={<CheckOutlined />}
          loading={buttonLoading}
          onClick={async () => updateTask({ done: !record.done })}
          shape='circle'
          size={isPhone ? 'small' : undefined}
          type='primary'
        />
      </Tooltip>
    </div>
  )
}

// TODO: Convert createColumn  and datatable to typescript
const createColumn_ = createColumn as any
const DataTable_ = DataTable as any


const NameMappingContext = React.createContext<any>({})

const ContractTitle: React.FC<{ record: any }> = ({ record, children }) => {
  const nameMapping = useContext(NameMappingContext)
  const { lang } = useParams()
  const { childID, date, instanceType, instanceID } = record
  if (childID && date) {
    return (
      <Link
        className='rendercontract'
        to={`/${lang}/${instanceType}s/${instanceID}?activeSection=${childID.split('_')[0]}&comment=${date}&sidebar=comments`}
      >
        <span>{children || nameMapping[instanceID]}</span>
      </Link>
    )
  }
  return (
    <Link
      className='rendercontract'
      to={ `/${lang}/${instanceType}s/${instanceID}`}
    >
      <span>{children || nameMapping[instanceID]}</span>
    </Link>
  )
}

export const commonColumns = [
  createColumn_({
    columnKey: 'userID',
    columnName: <Trans>From <span className='iconStyle'> <RightOutlined /> </span>To</Trans>,
    columnIcon: <UserOutlined />,
    // eslint-disable-next-line react/display-name
    render: (_, record: any) => (
      <div className='centerItems'>
        <UserAvatarByID
          autoSide
          size='2.2rem'
          userID={record.userID}
        />
        <span className='iconStyle'><RightOutlined /></span>
        <UserAvatarByID
          autoSide
          size='2.2rem'
          userID={record.assignedTo}
        />
      </div>
    )
  }),
  createColumn_({
    columnKey: 'dueDate',
    columnName: <Trans>Due Date</Trans>,
    withDate: true,
    withSort: true,
    render: (_, record) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { lang } = useParams()
      const date = new Date(record.dueDate)
      const timestamp = date.valueOf()
      if (!Number.isNaN(timestamp) && timestamp > 0) {
        return (
          <div className='dateDisplay'>
            <h5 className='absoluteDate'>
              {new Intl.DateTimeFormat(lang, {
                day: '2-digit',
                month: 'long',
                year: 'numeric'
              }).format(date)}
            </h5>
            <h5 className='relativeDate'>{moment(date).fromNow()}</h5>
          </div>
        )
      }
      return '-'
    }
  }),
  createColumn_({
    columnKey: 'priority',
    columnName: <Trans>Priority</Trans>,
    render: (_, record) => {
      let today: any = new Date()
      today.setHours(0, 0, 0, 0)
      today = today.valueOf()
      const date = new Date(record.dueDate).valueOf()
      if (!Number.isNaN(date) && date > 0 && date < today) {
        return (
          <div className='centerItems'>
            <Tag className='ant-tag-red'>
              <Trans>OVERDUE</Trans>
            </Tag>
          </div>
        )
      }
      return '-'
    }
  }),
  // here i had to get the contracts and templates to get their name and displayed it
  createColumn_({
    columnKey: 'instanceID',
    columnName: <Trans>Contract</Trans>,
    withSort: true,
    render: (_, record) => <ContractTitle record={record} />
  }),
  // here we display the actions
  createColumn_({
    columnKey: 'Action',
    columnName: <Trans>Actions</Trans>,
    render: (_, record) => (<ActionTask record={record} />)
  })
]

// ======================================================================================

export const taskListingColumnsPhone = [
  createColumn_({
    columnKey: 'text',
    columnName: <Trans>Task</Trans>,
    className: 'itemName',
    withSort: true,
    render: (_:any, record: any) => <div className = 'phoneStyle'><StatusAction record={record} /> {record.text} </div>
  }),
  ...commonColumns
]

// ======================================================================================

export const taskListingColumns = [
  createColumn_({
    columnKey: 'status',
    columnName: <Trans>Status</Trans>,
    // to render the column , record is the data in that line
    render: (_:any, record: any) => <StatusAction record={record} />
  }),
  createColumn_({
    columnKey: 'text',
    columnName: <Trans>Task</Trans>,
    className: 'itemName',
    withSort: true,
    render: (_, record) => <ContractTitle record={record}>{record.text}</ContractTitle>
  }),
  ...commonColumns
]


// ======================================================================================


const scroll = { x: true }
const TasksInner = () => {
  const isPhone = useContext(DeviceContext) === 'phone'
  const { user } = useContext(UserAndCompanyDataContext)

  //  Fetch contracts tasks
  const contractsCommentsCollection = useContractCommentsCollection()
  const [contractsComments = emptyArray as Comment[]] = useList(useMemo(
    () => contractsCommentsCollection.find().where('assignedTo').ne(null).sort({ lastUpdated: 'desc' }),
    [contractsCommentsCollection]
  ), toJsonList)

  //  Fetch templates tasks
  const templatesCommentsCollection = useTemplateCommentsCollection()
  const [templatesComments = emptyArray as Comment[]] = useList(useMemo(
    () => templatesCommentsCollection.find().where('assignedTo').ne(null).sort({ lastUpdated: 'desc' }),
    [templatesCommentsCollection]
  ), toJsonList)

  //  Merge the two arrays in one and keep the sorting order
  const array = useMemo(() => {
    const arr: Comment[] = []

    const cIT = contractsComments[Symbol.iterator]()
    const tIT = templatesComments[Symbol.iterator]()

    let cItem = cIT.next()
    let tItem = tIT.next()

    const addCItem = () => {
      arr.push(cItem.value)
      cItem = cIT.next()
    }
    const addTItem = () => {
      arr.push(tItem.value)
      tItem = tIT.next()
    }

    while (cItem.value || tItem.value) {
      if (cItem.value && tItem.value) {
        if (cItem.value.lastUpdated > tItem.value.lastUpdated) {
          addCItem()
        } else {
          addTItem()
        }
      } else if (cItem.value) {
        addCItem()
      } else if (tItem.value) {
        addTItem()
      }
    }

    return arr
  }, [contractsComments, templatesComments])

  return (
    <div className='paginatedDatatable'>
      <DataTable_
        data={array}
        defaultView='list'
        globalFilters={useMemo(() => [{
          columnKey: 'type',
          getData: item => {
            const tmp: any[] = []
            if ((item.assignedTo === user.userID || user.userID === item.userID) && !item.done) {
              tmp.push('Mytasks')
            }
            if (item.done) {
              tmp.push('Completed')
            }
            return tmp
          },
          options: ['Completed', 'Mytasks'].map(status => ({
            value: status,
            ...taskStatusOptions[status]
          }))
        }], [user.userID])}
        noViewSwitcher
        rowKey={item => item.instanceID}
        scroll={scroll}
      >
        {!isPhone ? taskListingColumns : taskListingColumnsPhone}
      </DataTable_>
    </div>
  )
}


// ======================================================================================


const Tasks: React.FC = () => {
  //  Templates name mapping
  const getTemplates = useTemplateListBaseQuery()
  const [templateNameMapping = emptyObject] = useList<Template, any, { [templateID: string]: string }>(
    getTemplates,
    useCallback(array => {
      const map: any = {}
      array.forEach(({ templateID, name }) => { if (name) { map[templateID] = name } })
      return map
    }, [])
  )


  //  Contracts name mapping
  const getContracts = useContractsListBaseQuery()
  const [contractsNameMapping = emptyObject] = useList<Contract, any, { [contractID: string]: string }>(
    getContracts,
    useCallback(array => {
      const map: any = {}
      array.forEach(({ contractID, contractName }) => { if (contractName) { map[contractID] = contractName } })
      return map
    }, [])
  )

  //  Instance name mapping
  const instancesNameMapping = useMemo(() => ({ ...templateNameMapping, ...contractsNameMapping }), [templateNameMapping, contractsNameMapping])

  return (
    <MainContainer
      mainContentClass='contractListing taskPage'
      topbarContent={(
        <>
          <div className='topbarMainContent'>
            <CarryOutOutlined className='headerIcon' twoToneColor='#3DBD7D' />
            <h1 className='title'><Trans>Tasks & Reminders</Trans></h1>
          </div>
        </>
      )}
    >
      <NameMappingContext.Provider value={instancesNameMapping}>
        <TasksInner />
      </NameMappingContext.Provider>
    </MainContainer>
  )
}

const Wrapped: React.FC = props => {
  const Component = useMemo(() => withUserData(Tasks), [])
  return <Component {...props} />
}

export default Wrapped
