import { useCallback, useMemo, useState } from 'react'
import {
  ConditionalText,
  EntityRef, InputField, Section,
  Template,
  TemplateFlaternisation, useConditionalsCollection,
  useDoc,
  useFieldsCollection, useSectionsCollection,
  useTemplateFlaternisationsCollection
} from '@top-legal/datastore'
import getConditionalValue from './utils/getConditionalValue'
import isSectionActive from './utils/isSectionActive'
import { useGetGetters } from './useGetGetters'
import { incrementWithGlobalFields } from './utils'
import getFieldValue from './utils/getFieldValue'

type Responses = Record<string, any>

export interface AssistantStep {
  sectionID: string
  fieldIDs: string[]
}


interface DataMaps {
  fieldsMap: Map<string, InputField>
  condsMap: Map<string, ConditionalText>
  sectionsMap: Map<string, Section>
}

const emptyMaps: DataMaps = { fieldsMap: new Map(), condsMap: new Map(), sectionsMap: new Map() }


interface FlatVisitors {
  visitField: (fieldID: string) => void
  visitCond: (condID: string) => void
  visitEntity: (entityRef: EntityRef) => void
  visitSection: (sectionID: string) => void

  //  Content
  steps: AssistantStep[]
  fieldIDs: string[]
}


const getFlatVisitors = (flaternisation: TemplateFlaternisation, maps: DataMaps, responses: Responses): FlatVisitors => {
  const visited: Record<string, boolean> = {}

  let fieldIDs: string[] = []
  const steps: AssistantStep[] = []

  const visitors: FlatVisitors = {
    steps,
    fieldIDs,

    visitField: (fieldID: string) => {
      if (!visited[fieldID]) {
        visited[fieldID] = true

        //  Handle party filing
        const [baseKey] = fieldID.split('.')
        if (baseKey !== fieldID) {
          visitors.visitField(baseKey)
        }

        fieldIDs.push(fieldID)
      }
    },

    visitCond: (condID: string) => {
      if (!visited[condID]) {
        visited[condID] = true

        const dep = flaternisation.condsDependencies[condID]
        const cond = maps.condsMap.get(condID)
        if (dep && cond) {
          visitors.visitField(dep.field)

          const [, condIndexes] = getConditionalValue(cond, maps.fieldsMap, responses) || []
          if (condIndexes) {
            const condIndexesArr = Array.isArray(condIndexes) ? condIndexes : [condIndexes]

            condIndexesArr.forEach(reply => {
              // eslint-disable-next-line no-use-before-define
              dep.values[reply]?.forEach(visitors.visitEntity)
            })
          }
        }
      }
    },

    visitEntity: (entityRef: EntityRef) => {
      if (entityRef.type === 'inputField') {
        return visitors.visitField(entityRef.key)
      }
      if (entityRef.type === 'conditionalText') {
        return visitors.visitCond(entityRef.key)
      }
      return undefined
    },

    visitSection: (sectionID: string) => {
      const dep = flaternisation.sectionDependencies[sectionID]
      const section = maps.sectionsMap.get(sectionID)

      if (dep && section) {
        let isActive = true
        if (dep.field) {
          visitors.visitField(dep.field)
          isActive = isSectionActive(section, maps.fieldsMap, responses)
        }
        if (isActive && dep.children) {
          dep.children.forEach(visitors.visitEntity)
        }

        //  Save a new assistant step
        if (fieldIDs.length > 0) {
          steps.push({sectionID, fieldIDs})
          fieldIDs = []
        }
      }
    }
  }

  return visitors
}


const getTemplateAssistantSteps = (flaternisation: TemplateFlaternisation, maps: DataMaps, responses: Responses): AssistantStep[] => {
  const beginAt = Date.now()
  const { visitSection, steps } = getFlatVisitors(flaternisation, maps, responses)

  //  Visit the template dependencies
  flaternisation.sectionIDs.forEach(visitSection)

  //  istanbul ignore next
  if (process.env.NODE_ENV === 'development') {
    console.info('[Contract Assistant Steps] Job took', Date.now() - beginAt, 'ms')
    console.info('[Contract Assistant Steps] The steps are', steps)
  }
  return steps
}


const defaultCombinedListStep: AssistantStep = { sectionID: '__combined_list_steps__', fieldIDs: [] }
const getCombinedListAssistantSteps = (flaternisation: TemplateFlaternisation, maps: DataMaps, responses: Responses, fieldID: string): AssistantStep => {
  const step: AssistantStep = { ...defaultCombinedListStep }

  //  Visit the combined list dependencies
  const deps = flaternisation.fieldsDependencies[fieldID]
  if (deps && Array.isArray(deps.children)) {
    const visitors = getFlatVisitors(flaternisation, maps, responses)

    //  Override visitField for skipping combined lists
    const { visitField } = visitors
    visitors.visitField = fieldID => {
      const field = maps.fieldsMap.get(fieldID)

      if (field?.type !== 'listOfFormattedText') {
        return visitField(fieldID)
      }
    }

    deps.children.forEach(visitors.visitEntity)

    step.fieldIDs = visitors.fieldIDs
  }

  return step
}


export interface UseGetAssistantStepsReturn {
  getTemplateSteps: (responses: Responses) => AssistantStep[]
  getCombinedListSteps: (fieldID: string, responses: Responses) => AssistantStep
  loading: boolean
  flaternisation?: TemplateFlaternisation
}

const emptyArray = []
const useGetAssistantSteps = (template: Template): UseGetAssistantStepsReturn => {
  const { templateID, dateUpdated } = template

  const flaternisationsCollection = useTemplateFlaternisationsCollection()
  const [flaternisation] = useDoc<TemplateFlaternisation>(
    useMemo(() => flaternisationsCollection.findOne(`flat-${templateID}`), [flaternisationsCollection, templateID]),
    useCallback(flat => {
      //  Ensure we get only the correct object
      if (flat?.dateUpdated === dateUpdated) {
        return flat.toJSON()
      }
      //  istanbul ignore next
      return undefined
    }, [dateUpdated])
  )

  //  Get all the required data out of the dependencies
  const getGetters = useGetGetters()
  const [maps, setMaps] = useState<DataMaps>(emptyMaps)
  useMemo(() => {
    if (flaternisation) {
      const { fieldsDependencies, condsDependencies, sectionIDs } = flaternisation
      const { getFields, getConds, getSections } = getGetters(template)
      Promise.all([
        getFields(Object.keys(fieldsDependencies)),
        getConds(Object.keys(condsDependencies)),
        getSections(sectionIDs)
      ]).then(([mapF, mapC, mapS]) => {
        incrementWithGlobalFields(mapF, template.lang)
        setMaps({ fieldsMap: mapF, condsMap: mapC, sectionsMap: mapS })
      })
    }
  }, [flaternisation, getGetters, template])


  const getTemplateSteps: UseGetAssistantStepsReturn['getTemplateSteps'] = useCallback((responses: Responses) => (
    flaternisation ? getTemplateAssistantSteps(flaternisation, maps, responses) : emptyArray
  ), [flaternisation, maps])


  const getCombinedListSteps: UseGetAssistantStepsReturn['getCombinedListSteps'] = useCallback((fieldID: string, responses: Responses) => (
    flaternisation ? getCombinedListAssistantSteps(flaternisation, maps, responses, fieldID) : defaultCombinedListStep
  ), [flaternisation, maps])


  return {
    getTemplateSteps, getCombinedListSteps, loading: !flaternisation, flaternisation
  }
}

export default useGetAssistantSteps
