import React, { useCallback, useMemo, useRef } from 'react'

import { saveInStore, Section, useSectionsCollection } from '@top-legal/datastore'
import { applySnapshotPatch, computeSnapshotPatch, newDoc, SectionEditorProps } from '@top-legal/editor'
import RestService from '../../../../../RestService'

import ContractEditorSectionContext, { ContractEditorSectionContextProps, UpdateSection, UseContractEditorActions } from './ContractEditorSectionContext'

const useTemplateEditorSavingActions: UseContractEditorActions = (
  rootKey,
  path,
  section,
  setSaving
) => {
  const sectionsCollection = useSectionsCollection()
  const { sectionID } = section
  const templateID = useMemo(() => {
    const tpl = sectionID.split('-')
    tpl.pop()
    return tpl.join('-')
  }, [sectionID])


  //  Save section in store
  const saveSectionInStore = useCallback(async (update: ((oldSection: Section) => Promise<Section>)): Promise<any> => {
    const obj = await sectionsCollection.findOne(sectionID).exec()
    const section = (obj?.toJSON() || { sectionID, templateID, name: '' }) as Section

    //  Save back in store
    return saveInStore<Section>(sectionsCollection, await update(section))
  }, [sectionID, sectionsCollection, templateID])


  //  On template editor the save of the section content would need to be done on a websocket layer to be more effective
  //  Note the current version save only remote, we need then to update the store but for efficiency only when the section got unmounted
  const savingRef = useRef(false)
  const updateContent = useCallback<NonNullable<SectionEditorProps['saveUpdate']>>(async (update, _, setSnapshot, dateUpdated) => {
    if (!savingRef.current) {
      savingRef.current = true
      setSaving(true)
      try {
        setSnapshot()
        await RestService('put', `/template/${templateID}/${sectionID}/yjsdoc`, { update, dateUpdated })

        //  Save section in store
        await saveSectionInStore(async oldSection => {
          //  Merge updates with doc
          const doc = newDoc()

          if (oldSection.yDoc) {
            applySnapshotPatch(doc, oldSection.yDoc)
          }
          applySnapshotPatch(doc, update)

          oldSection.yDoc = computeSnapshotPatch(doc)
          oldSection.dateUpdated = dateUpdated

          return oldSection
        })
      } catch (err) {
        //  Reset the prevSnapshot when failing
        setSnapshot(true)
        console.error('Got error while saving Yjs section state', err)
      }
      setSaving(false)
      savingRef.current = false
    }
  }, [saveSectionInStore, sectionID, setSaving, templateID])


  //  Update section with partial content
  const updateSection = useCallback<UpdateSection>(async values => {
    setSaving(true)
    try {
      await saveSectionInStore(async oldSection => {
        const update = {
          ...oldSection,
          dateUpdated: (new Date()).toISOString(),
          ...values
        }
        delete update.yDoc // Avoid sending the big yDoc to update section metadata

        const sectionTemplateID = values.sectionID && (() => {
          const tpl = values.sectionID.split('-')
          tpl.pop()
          return tpl.join('-')
        })()

        const newSection = await RestService('PUT', `/template/${sectionTemplateID || templateID}/${values.sectionID || sectionID}`, update)
        return { ...(oldSection || {}), ...newSection }
      })
    } catch (err) {
      console.error('Got error while saving section values', err, values)
    }
    setSaving(false)
  }, [saveSectionInStore, sectionID, setSaving, templateID])

  return { updateContent, updateSection }
}

const templateSavingContext: ContractEditorSectionContextProps = { useContractEditorActions: useTemplateEditorSavingActions }
const ProvideTemplateSectionSaving: React.FC = ({ children }) => (
  <ContractEditorSectionContext.Provider value={templateSavingContext}>
    {children}
  </ContractEditorSectionContext.Provider>
)

export default ProvideTemplateSectionSaving
