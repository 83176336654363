import { ConsoleLogger as Logger } from '@aws-amplify/core'
import RestService from './RestService'

const logger = new Logger('WebSocketService')

class WebSocketService {
  constructor () {
    this.ws = null
    this.retries = 0
    this.handlers = {}
    this.queue = []
    this.wsPromise = Promise.reject('Start') // eslint-disable-line

    // window.onbeforeunload = this.close.bind(this)
  }

  close () {
    //  Don't change in multiple if, I need this version for thread safe
    let previousWebSocket
      ; (previousWebSocket = this.wsPromise) && (this.wsPromise = new Promise(resolve => {
      previousWebSocket.finally(() => {
        if (this.ws) {
          this.ws.onclose = () => null // disable onclose handler first
          typeof this.ws.close === 'function' && this.ws.close()
          this.ws = null
        }
        this.handlers = {}
        this.queue = []
        resolve()
      })
    }))
  }

  initWebSocket () {
    //  Don't change in multiple if, I need this version for thread safe
    logger.info('WebSocketService - initWebSocket called')
    let previousWebSocket
    !this.ws && (previousWebSocket = this.wsPromise) && (this.wsPromise = new Promise((resolve, reject) => {
      previousWebSocket.then(() => resolve())
        .catch(() => {
          logger.info('WebSocketService - initWebSocket: Init the WebSocket')
          this.wsOppeningPromise = {
            resolve,
            reject
          }
          RestService.getCurrentCognitoSession(false)
            .then(currentUser => {
              //  Try open the WebSocket
              this.retries += 1

              this.ws = new WebSocket(`${process.env.WEB_SOCKET_URL}?token=${currentUser.signInUserSession.idToken.jwtToken}`)

              //  Map event handlers
              this.ws.onopen = this.onOpen.bind(this)
              this.ws.onmessage = this.onMessage.bind(this)
              this.ws.onerror = this.onError.bind(this)
              this.ws.onclose = this.onClose.bind(this)
            })
            .catch(err => {
              logger.error('WebSocketService - initWebSocket:', err)
              this.ws = null
              reject()
            })
        })
    }))
  }

  ping () {
    if (this.ws) {
      this.sendData('default', '')
      this.pingTimeout = setTimeout(this.ping.bind(this), 5 * 60 * 1000)
    }
  }

  onOpen () {
    logger.info('WebSocketService - onOpen')

    if (this.wsOppeningPromise) {
      this.retries = 0
      this.opened = true

      //  Sending queueing data if we have
      this.queue.forEach(message => this.ws.send(JSON.stringify(message)))
      this.queue = []

      this.wsOppeningPromise.resolve()
      this.wsOppeningPromise = null

      this.pingTimeout = setTimeout(this.ping.bind(this), 5 * 60 * 1000)
    }
  }

  onError (err) {
    logger.error('WebSocketService - onError:', err)
    if (this.pingTimeout) {
      clearTimeout(this.pingTimeout)
    }
    if (this.wsOppeningPromise) {
      this.wsOppeningPromise.reject()
    }
  }

  onClose () {
    logger.info('WebSocketService - onClose')

    this.ws = null

    if (this.pingTimeout) {
      clearTimeout(this.pingTimeout)
    }

    if (this.retries < 5) {
      logger.info(`WebSocketService - onClose retry - Try number ${this.retries}`)
      if (this.wsOppeningPromise) {
        this.wsOppeningPromise.reject(`Try number ${this.retries}`)
      } else {
        this.wsOppeningPromise = Promise.reject(`Try number ${this.retries}`)
      }
      this.initWebSocket()
    } else {
      this.wsOppeningPromise && this.wsOppeningPromise.reject('Too many tries!')
      logger.error('WebSocketService - onClose: Too many tries for opening the WebSocket!')
    }
  }

  onMessage (event) {
    if (event.data) {
      try {
        const incomingData = JSON.parse(event.data)
        const { action } = incomingData

        if (action) {
          try {
            const { data } = incomingData
            const handler = this.handlers[action]

            if (handler) {
              try {
                handler(data)
              } catch (err) {
                logger.error('WebSocketService - onMessage: Encountered a error in the handler of the incoming event', action, err)
              }
            } else {
              logger.warn('WebSocketService - onMessage: Incoming event with action =', action, 'is not handled!')
            }
          } catch (err) {
            logger.error('WebSocketService - onMessage: Incoming event data is mal formatted json!', err, 'Data:', event.data)
          }
        } else {
          logger.error('WebSocketService - onMessage: Incoming event have empty action!')
        }
      } catch (err) {
        logger.error('WebSocketService - onMessage: Can\'t parse incoming data!')
      }
    } else {
      logger.error('WebSocketService - onMessage: No incoming data!')
    }
  }

  /**
   * Hook a function
   * @param action the backend route key equivalent to the lambda to call
   * @param callback function
   */
  hook (action, callback) {
    if (typeof action !== 'string') {
      logger.error('WebSocketService - hook: Invalid action param need a string!', new Error('Stack trace'))
    } else if (typeof callback !== 'function') {
      logger.error('WebSocketService - hook: Invalid callback param need a function!', new Error('Stack trace'))
      console.trace()
    } else if (this.handlers[action]) {
      if (this.handlers[action] === callback) {
        logger.error('WebSocketService - hook: You already hook this action in this component, please don\'t hook again!',
          new Error('Stack trace'))
      } else {
        logger.error('WebSocketService - hook: This action is already hook in an other component, please unHook before hook again!',
          new Error('Stack trace'))
      }
    } else {
      this.handlers[action] = callback
      logger.info('WebSocketService - hook: Hook a new handler for', action)

      if (!this.ws) {
        this.initWebSocket()
      }
    }
  }

  /**
   * UnHook a function
   * @param action the backend route key equivalent to the lambda to call
   * @param callback function
   */
  unHook (action, callback) {
    if (typeof action !== 'string') {
      logger.error('WebSocketService - unHook: Invalid action param need a string!', new Error('Stack trace'))
    } else if (typeof callback !== 'function') {
      logger.error('WebSocketService - unHook: Invalid callback param need a function!', new Error('Stack trace'))
    } else if (!this.handlers[action]) {
      logger.error('WebSocketService - unHook: This action is not handled currently!', new Error('Stack trace'))
    } else if (this.handlers[action] !== callback) {
      logger.error(
        'WebSocketService - unHook: You can\'t unHook a function that is not in your component, please unHook in the unMount trigger!',
        new Error('Stack trace')
      )
    } else {
      delete this.handlers[action]
    }
  }

  /**
   * Sending data through the WebSocket
   * @param action the backend route key equivalent to the lambda to call
   * @param data
   */
  sendData (action, data) {
    if (typeof action !== 'string') {
      logger.error('WebSocketService - sendData: Invalid action param need a string!', new Error('Stack trace'))
    } else {
      try {
        const body = {
          action,
          data
        }

        //  Don't change in multiple if, I need this version for thread safe
        let previousWebSocket
          ; (previousWebSocket = this.wsPromise) && (this.wsPromise = new Promise((resolve, reject) => {
          previousWebSocket.then(() => {
            if (this.ws) {
              this.ws.send(JSON.stringify(body))
              resolve()
            } else {
              this.queue.push(body)
                reject('WebSocket not initialized yet!') // eslint-disable-line
              this.initWebSocket()
            }
          })
            .catch(err => {
              this.queue.push(body)
              reject(err)
              this.initWebSocket()
            })
        }))
      } catch (err) {
        logger.error('WebSocketService - sendData: Error when trying to stringify data', err, new Error('Stack trace'))
      }
    }
  }

  subscribe (action, callback) {
    if (this.hook(action, callback)) {
      this.sendData('subscribe', action)
    }
  }

  unsubscribe (action, callback) {
    if (this.unHook(action, callback)) {
      this.sendData('unsubscribe', action)
    }
  }
}

/**
 * The WebSocketService instance to manage sync aspect of the app
 * Public functions are hook, unHook, sendData
 * @type {WebSocketService}
 */
const instance = new WebSocketService()

export default instance
