import React, { useContext } from 'react'
import { Trans } from '@lingui/macro'
import { Button, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { InputField } from '@top-legal/datastore'
import { isInlineField } from '@top-legal/editor'

import { ModalRefContext } from '../../../TemplateEntities/TemplateEntitiesForms/TemplateEntitiesFormsModal'

import { Identity, Search } from './Types'
import EntityTitle from './EntityTitle'


const DisplayField: React.FC<{
  fieldKey: string, field: InputField, inlinesOnly?: boolean, noActions?: boolean, noDrag?: boolean, isEntityField?: boolean
} & Search> = ({
  fieldKey, field, inlinesOnly, noActions, noDrag, isEntityField,
  Highlight = Identity, children
}) => {
  const { modalRef } = useContext<any>(ModalRefContext)
  const disabled = inlinesOnly && !isInlineField(field)

  return (
    <EntityTitle
      className={`inputField${disabled ? ' disabled' : ''}`}
      entityKey={fieldKey}
      entityTitle={field.name}
      entityType='inputField'
      noDrag={noDrag || disabled}
      onClick={() => !noActions && !isEntityField && modalRef.current.editInputField(fieldKey, undefined, field)}
      version={(field as any).version}
    >
      <span className='inputFieldTitle'>[<Highlight text={field.name} />]</span>
      <span className='inputFieldQuestion'>{typeof field.question === 'string' ? <Highlight text={field.question} /> : field.question}</span>
      {
        !noActions && ['list', 'combinedList', 'number', 'date', 'time', 'yesNo'].includes(field.type) && (
          <Tooltip placement='topLeft' title={<Trans>Create a condition from this field</Trans>}>
            <Button
              ghost
              icon={<PlusOutlined />}
              onClick={evt => {
                evt.preventDefault()
                evt.stopPropagation()
                modalRef.current.createOrEditConditionalText(undefined, undefined, { field: (field as any).key }, 0)
              }}
              shape='round'
              size='small'
            />
          </Tooltip>
        )
      }
      {children}
    </EntityTitle>
  )
}

export default DisplayField
