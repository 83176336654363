import React from 'react'
import { Collection, Model, TypeConfiguration } from '../Types'
import DatabaseContext from '../DatabaseContext'

export interface Address {
  address: string
  city: string
  postcode: string
  country: string
}

export interface ContractingParty extends Model {
  partyID: string
  organisationID: string
  userID?: string
  type: 'person' | 'company'
  team?: string

  //  Person
  firstName?: string
  lastName?: string
  email?: string
  phoneNumber?: string
  companyPartyID?: string

  //  Company
  name?: string

  //  Both
  address?: Address

  //  Historical activity
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}

export interface ContractingPartyDataset { parties: ContractingParty[] }

const config: TypeConfiguration<ContractingParty> = {
  key: 'parties',
  schema: {
    version: 0,
    primaryKey: 'partyID',
    type: 'object',
    properties: {
      address: {},
      companyPartyID: {
        type: 'string'
      },
      createdAt: {
        type: 'string'
      },
      createdBy: {
        type: 'string'
      },
      email: {
        type: 'string'
      },
      firstName: {
        type: 'string'
      },
      lastName: {
        type: 'string'
      },
      name: {
        type: 'string'
      },
      organisationID: {
        type: 'string'
      },
      partyID: {
        type: 'string'
      },
      phoneNumber: {
        type: 'string'
      },
      team: {
        type: 'string'
      },
      type: {
        enum: [
          'person',
          'company'
        ],
        type: 'string'
      },
      updatedAt: {
        type: 'string'
      },
      updatedBy: {
        type: 'string'
      },
      userID: {}
    },
    required: [
      'partyID',
      'organisationID',
      'type',
      'updatedAt',
      'updatedBy'
    ],
    indexes: ['updatedAt']
  }
}

export const useContractingPartiesCollection = (): Collection<ContractingParty> => React.useContext(DatabaseContext)[config.key] as Collection<ContractingParty>

export const partiesGQLQuery = `
{
  partyID
  organisationID
  userID # When the user register
  type
  team

  # Person
  firstName
  lastName
  email
  phoneNumber
  companyPartyID

  # Company
  name

  # Both
  address {
    address
    city
    country
    postcode
  }

  # Historical activity
  createdAt
  createdBy
  updatedAt
  updatedBy
}`

export default config
