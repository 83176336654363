import React from 'react'
import { Button, Modal } from 'antd'
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'

import './styles/LoadingModalFeedback.scss'
import {noop} from "../Defaults";

interface LoadingModalFeedbackProps {
  loadingTitle: React.ReactNode
  loadingDescription?: React.ReactNode

  successTitle: React.ReactNode
  successDescription?: React.ReactNode

  errorTitle: React.ReactNode
  errorDescription?: React.ReactNode

  modalClassName?: string

  autoSuccessClose?: number
  autoErrorClose?: number

  onSuccessClose?: () => void
  onErrorClose?: () => void
}


export const LoadingModalContent: React.FC<LoadingModalFeedbackProps & { state: 'loading' | 'done' | 'error', close?: () => void }> = ({
  loadingTitle, loadingDescription,
  successTitle, successDescription,
  errorTitle, errorDescription,
  state, close = noop,
  autoSuccessClose, autoErrorClose,
  onSuccessClose = noop, onErrorClose = noop
}) => (
  <div className='loadingFeedbackModalContent' onClick={evt => evt.stopPropagation()}>
    <div className={`loadingState ${state === 'loading' ? 'active' : ''}`}>
      <div className='stateIcon'><LoadingOutlined spin /></div>
      <h1>{loadingTitle}</h1>
      {loadingDescription && <p>{loadingDescription}</p>}
    </div>
    <div className={`doneState ${state === 'done' ? 'active' : ''}`}>
      <div className='stateIcon'><CheckOutlined /></div>
      <h1>{successTitle}</h1>
      {successDescription && <p>{successDescription}</p>}
      {!autoSuccessClose && (
        <Button ghost onClick={() => { close(); onSuccessClose() }} size='small' type='default'>
          <Trans>Back</Trans>
        </Button>
      )}
    </div>
    <div className={`errorState ${state === 'error' ? 'active' : ''}`}>
      <h1>{errorTitle}</h1>
      {errorDescription && <p>{errorDescription}</p>}
      {!autoErrorClose && (
        <Button ghost onClick={() => { close(); onErrorClose() }} size='small' type='default'>
          <Trans>Back</Trans>
        </Button>
      )}
    </div>
  </div>
)


const loadingModalFeedback = ({ modalClassName = '', ...props }: LoadingModalFeedbackProps) => async <T, >(func: () => Promise<T>): Promise<T> => {
  const modal = ((window as any).modal.info as typeof Modal.info)({
    className: `smallModal loadingFeedbackModal ${modalClassName}`,
    closable: false,
    maskClosable: false,
    zIndex: 50000,
    icon: null,
    content: <LoadingModalContent {...props} state='loading' />
  })

  try {
    const res: T = await func()
    modal.update({ content: <LoadingModalContent {...props} close={modal.destroy} state='done' /> })
    if (props.autoSuccessClose) {
      setTimeout(() => {
        modal.destroy()
        if (props.onSuccessClose) { props.onSuccessClose() }
      }, props.autoSuccessClose)
    }
    return res
  } catch (err) {
    console.trace()
    console.error('Error when loading', err)
    modal.update({ content: <LoadingModalContent {...props} close={modal.destroy} state='error' /> })
    if (props.autoErrorClose) {
      setTimeout(() => {
        modal.destroy()
        if (props.onErrorClose) { props.onErrorClose() }
      }, props.autoErrorClose)
    }
    throw err
  }
}

export default loadingModalFeedback
