export const handleAWSJSONProps = <T> (obj: T, keys: string[]): T => {
  keys.forEach(key => {
    const value = obj[key]
    if (value && typeof value === 'string') {
      try {
        obj[key] = JSON.parse(value)
      } catch {
      }
    }
  })

  return obj
}

export const handleStrOrNumber = <T> (obj: T, keys: string[]): T => {
  keys.forEach(key => {
    const value = obj[key]
    if (value && typeof value === 'string' && /^[0-9]+$/.test(value)) {
      const num = parseFloat(value)
      if (!Number.isNaN(num)) {
        obj[key] = num
      }
    }
  })

  return obj
}

export const delay = (time: number) => new Promise(resolve => {
  setTimeout(resolve, time)
})

export type FetchFunc = (lastSyncDate: string, lastElement?: string, options?: any) => Promise<string>
