import type { ConditionalText, InputField } from '@top-legal/datastore'
import type { EditorContent } from '@top-legal/editor'

//  Prefer using direct source imports to allow backend compilation
import { isEmpty } from '@top-legal/editor'
import { parseDate, parseTime, timeMapping } from '@top-legal/form-components'

import getFieldValue from './getFieldValue'


type CondValue = [EditorContent, string | string[]]

const getConditionalValue = (
  { field: fieldKey, values, isRelativeToContractFillingDate }: ConditionalText,
  fields: Map<string, InputField>,
  responses: Record<string, any>
): CondValue | undefined => {
  if (Object(values) !== values) { return undefined }

  const [value, field] = getFieldValue(fields, fieldKey, responses, false) || []

  if (value == null || field == null) { return undefined }

  if (/^(list|yesNo)$/.test(field.type)) {
    if (field.type === 'list' && field.isMultipleChoices) {
      if (Array.isArray(value)) {
        const children: EditorContent = []

        value.forEach(val => {
          if (Array.isArray(values[val]) && !isEmpty(values[val])) {
            children.push({
              type: 'd',
              children: values[val]
            })
          }
        })

        if (!isEmpty(children)) {
          return [children, value]
        }
      }

      //  istanbul ignore next
      return undefined
    }

    if (values[value] != null && !isEmpty(values[value])) {
      return [values[value], value]
    }

    //  istanbul ignore next
    return undefined
  }


  if (/^(date|time|number|amount|listOfFormattedText)$/.test(field.type)) {
    let number: number = value
    let getValue = (obj: any): number | undefined => obj.value
    const timesToType = type => timeMapping[type] || NaN

    if (field.type === 'date') {
      const date = parseDate(value)
      //  istanbul ignore next
      if (date == null) { return undefined }
      number = date.valueOf()

      if (isRelativeToContractFillingDate) {
        number -= Date.now()
        getValue = obj => obj.value * timesToType(obj.type)
      } else {
        getValue = obj => parseDate(obj.value)?.valueOf()
      }
    } else if (field.type === 'time') {
      //  Always absolute
      const time = parseTime(value)
      //  istanbul ignore next
      if (time == null) { return undefined }
      number = time.valueOf()
      getValue = obj => parseTime(obj.value)?.valueOf()
    } else if (field.type === 'listOfFormattedText') {
      //  istanbul ignore next
      if (!Array.isArray(value)) { return undefined }
      number = value.length
    }

    const matchIndex = values.findIndex(obj => {
      const max = getValue(obj)
      return max != null && number <= max
    })

    if (matchIndex >= 0) {
      const { text } = values[matchIndex]
      if (text != null && !isEmpty(text)) {
        return [text, `${matchIndex}`]
      }
    }
  }

  return undefined
}

export default getConditionalValue
