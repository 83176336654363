import { ConsoleLogger as Logger } from '@aws-amplify/core'
import Storage from '@aws-amplify/storage'

import { saveInStore } from '@top-legal/datastore'

import {
  batchUpdateContractFields,
  FETCH_ORGANISATION_FIELDS_RESPONSE,
  generateInstanceRolesActions, loadComments,
  loadContractFieldResponses
} from '../../Organisations/redux/OrganisationsActions'
import RestService from '../../../RestService'
import { resetTemplate, updateTemplate } from '../../Template/redux/TemplateActions'
import * as types from '../../Template/redux/TemplateActionsType'
import { ensureTemplateSections } from '../Utils'
import { getHistory } from '../../../BrowserHistory'


export const SAVE_CONTRACT = 'SAVE_CONTRACT'
export const DELETE_CONTRACT_BY_CONTRACT_ID = 'DELETE_CONTRACT_BY_CONTRACT_ID'
export const GET_ALL_CONTRACTS = 'GET_ALL_CONTRACTS'
export const FETCHING_CONTRACT_DATA = 'FETCHING_CONTRACT_DATA'
export const EMPTY_CONTRACT_EDITING_STORE = 'EMPTY_CONTRACT_EDITING_STORE'
export const DISPLAY_SAVE_REGISTRATION_MODAL = 'DISPLAY_SAVE_REGISTRATION_MODAL'
export const FETCHING_SHARED_CONTRACTS = 'FETCHING_SHARED_CONTRACTS'
export const GET_SHARED_CONTRACTS = 'GET_SHARED_CONTRACTS'
export const ADD_COMMENT = 'ADD_COMMENT'
export const POSTING_COMMENT = 'POSTING_COMMENT'
export const ADD_COMMENT_FROM_WS = 'ADD_COMMENT_FROM_WS'
export const SHOW_REGISTRATION_MODAL = 'SHOW_REGISTRATION_MODAL'
export const UPDATE_CONTRACT_STATUS = 'UPDATE_CONTRACT_STATUS'
export const UPDATE_SELECTED_TAB = 'UPDATE_SELECTED_TAB'
export const GET_CONTRACTS_BY_STATUS = 'GET_CONTRACTS_BY_STATUS'
export const UPDATE_FIELD_RESPONSE = 'UPDATE_FIELD_RESPONSE'
// const
const logger = new Logger('ContractActions')

// ==================================================================================================
/**
 * fetches the data from the contract that is currently edited from the DB and saves it with the
 * new data to the DB, if the flag is set to true, else the contract data is only saved to the store
 * again
 * @param {object} values
 * @param {boolean} saveInDB
 * @param {boolean} publicMode
 * @param {boolean} isLastStep
 */
export const saveContract = (values, saveInDB = true) => async (dispatch, getState) => {
  /**
     * assigning the current values and the values from
     * the store to one object. the contract editing object contains all the answers
     * that a user has submitted while editing the contract.
     */
  let newContract = {
    ...getState().contract.contractEditing,
    ...values,
    dateUpdated: (new Date()).toISOString()
  }


  // ==================================================================================


  // ==================================================================================


  //  Format before network
  const formatFileInfo = data => {
    if (Object(data) === data && typeof data.key === 'string' && data.key) {
      const obj = {}
      if (data.key) { obj.key = data.key }
      if (data.name) { obj.name = data.name }
      return obj
    }
    if (typeof data === 'string') {
      return { key: data }
    }
    return null
  }

  let avoidPdfRenewals = data => data
  for (const arrayKey of ['covers', 'additionalDocuments']) {
    if (Array.isArray(values[arrayKey])) {
      const prevArr = values[arrayKey]
      values[arrayKey] = values[arrayKey].map(formatFileInfo)

      const prev = avoidPdfRenewals
      avoidPdfRenewals = data => {
        const newData = prev(data)
        if (Array.isArray(newData[arrayKey])) {
          newData[arrayKey].forEach((val, index) => {
            if (prevArr[index] && prevArr[index].key === val.key && prevArr[index].url) {
              val.url = prevArr[index].url
            }
          })
        } else {
          newData[arrayKey] = prevArr
        }
        return newData
      }
    }
  }

  if (Object(values.pdfSigningFile) === values.pdfSigningFile) {
    const prevVal = values.pdfSigningFile
    values.pdfSigningFile = formatFileInfo(values.pdfSigningFile)

    const prev = avoidPdfRenewals
    avoidPdfRenewals = data => {
      const newData = prev(data)
      if (Object(newData.pdfSigningFile) === newData.pdfSigningFile) {
        if (prevVal.key === newData.pdfSigningFile.key && prevVal.url) {
          newData.pdfSigningFile.url = prevVal.url
        }
      } else {
        newData.pdfSigningFile = prevVal
      }
      return newData
    }
  }


  // ==================================================================================


  // ==================================================================================


  const { templateID, name, lang } = getState().template.templateCreator
  if (!newContract.contractName) {
    newContract.contractName = new Intl.DateTimeFormat(lang, {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    }).format(new Date())
    if (name) {
      newContract.contractName = `${name} - ${newContract.contractName}`
    }
  }
  if (!newContract.createdBy) {
    newContract.createdBy = getState().user.ownProfileData.userID
  }

  /**
   * if the flag saveInDB is set to true the contract is saved to the DB,
   * else only saved to the redux sotre
   */
  if (saveInDB) {
    if (newContract.contractID) {
      // eslint-disable-next-line no-unused-vars
      const { fieldsResponse, template, ...otherValues } = values
      const res = await RestService('PUT', `/contract/${newContract.contractID}`, otherValues)
      newContract = avoidPdfRenewals({ ...newContract, ...res })
      await dispatch({
        type: SAVE_CONTRACT,
        payload: newContract
      })
      return newContract
    }
    /**
     * if the contract does not come with a contract id, it is contract that has
     * not been saved before, thus a new contract id is generated. The user
     * is then redirected to edit contract page, as the contract now exists.
     */
    // eslint-disable-next-line no-unused-vars
    const { fieldsResponse, template, ...contractData } = { ...newContract, ...values }
    if (!contractData.templateID) {
      contractData.templateID = templateID
    }

    const receivedContract = await RestService('POST', '/contract', contractData)
    newContract = avoidPdfRenewals({ ...newContract, ...receivedContract })
    newContract.role = 'Owner'

    await dispatch({
      type: SAVE_CONTRACT,
      payload: newContract
    })

    await dispatch(loadContractFieldResponses(newContract.contractID))
    getHistory().replace(getHistory().location.pathname.replace(/contracts\/.+?\/new$/, `contracts/${newContract.contractID}${getHistory().location.search}`))
    return newContract
  }
  // saving the contract to the redux store (props contractEditing)
  await dispatch({
    type: SAVE_CONTRACT,
    payload: newContract
  })
  return newContract
}

export const loadContract = (contractID, templatesCollection) => async (dispatch, getState) => {
  const contract = await RestService('GET', `/contract/${contractID}`)

  //  Redirect to dealroom if this is not drafting under the dealroom
  if (contract.dealID && !window.location.search?.includes('caOnly=')) {
    await saveInStore({ name: 'contracts', database: templatesCollection.database }, contract).catch(() => null)
    const domain = process.env.DEAL_DOMAIN
    getHistory().replace(`${getHistory().location.pathname.split('/')[1]}/listing/contracts`)
    window.open(`${domain}/deals/${contract.dealID}?contract=${contract.contractID}`, '__blank')
  }

  if (contract.template) {
    await dispatch(resetTemplate())
    contract.template = await dispatch(updateTemplate(contract.template))

    const prev = await templatesCollection.findOne(contract.template.templateID).exec()
    if (prev) {
      contract.template = { ...prev.toJSON(), ...contract.template }
    }

    await saveInStore(templatesCollection, contract.template)
  }

  dispatch({
    type: SAVE_CONTRACT,
    payload: contract
  })

  return contract
}

// ==================================================================================================
// ==================================================================================================
/**
 * function saves the contract information on the redux store to the local storage
 * or to a cookie depending on availability.
 */
export const saveContractToBrowserStorage = () => async (_, getState) => {
  logger.info('saveContractToBrowserStorage')
  /**
   * checking if the local storage is available for the browser.
   */
  if (typeof (Storage) !== 'undefined') {
    logger.info('saveContractToBrowserStorage - typeof (Storage) !== undefined')
    localStorage.setItem('contractConfiguration', JSON.stringify(getState().contract.contractEditing))
    logger.info('saveContractToBrowserStorage - localStorage', localStorage)
  } else {
    logger.info('saveContractToBrowserStorage - typeof (Storage) === undefined')
    /**
     * saving the information as a cookie, if the local storage is not available.
     */
    const date = new Date()
    date.setTime(date.getTime() + (24 * 60 * 60 * 1000))
    document.cookie = `contractConfiguration=${JSON.stringify(getState().contract.contractEditing)};expires=${date.toUTCString()};path=/`
  }
}
// ==================================================================================================
/**
 * function fetches the contract information from the local storage or the cookie and saves
 * it to the redux store
 */
export const loadContractFromBrowserStorage = () => dispatch => {
  let contractConfiguration

  if (typeof (Storage) !== 'undefined') {
    logger.info('loadContractFromBrowserStorage - typeof (Storage) !== undefined')
    contractConfiguration = localStorage.getItem('contractConfiguration')
    localStorage.removeItem('contractConfiguration')
  } else {
    logger.info('loadContractFromBrowserStorage - typeof (Storage) === undefined')
    contractConfiguration = decodeURIComponent(document.cookie)
      .match(new RegExp('contractConfiguration=(.*?)(;|$)'))[1]

    // resetting a cookie
    document.cookie = 'contractConfiguration=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/'
  }

  logger.info('contractConfiguration?', contractConfiguration)

  if (contractConfiguration) {
    dispatch({
      type: SAVE_CONTRACT,
      payload: JSON.parse(contractConfiguration)
    })

    return JSON.parse(contractConfiguration)
  }
  return null
}

// ==================================================================================================
/**
 * getting all contracts for a given organisation from the db.
 */
export function getAllContracts (lastElement, directInstance = false, reset = false) {
  return async (dispatch, getState) => {
    // getting the organisation id from the storage
    const { organisationID } = getState().organisation.selectedOrganisation

    dispatch({
      type: FETCHING_CONTRACT_DATA,
      payload: true
    })

    const path = `/contract?lastElement=${lastElement || ''}&organisationID=${organisationID}&directInstance=${directInstance}`
    const result = await RestService('GET', path)
    const allContracts = reset ? result : {
      lastElement: result.lastElement,
      Items: [...(getState().contract.allContracts.Items || []), ...(result.Items || [])]
    }

    if (allContracts.Items && allContracts.Items.length > 0) {
      dispatch({
        type: GET_ALL_CONTRACTS,
        payload: allContracts
      })
    } else {
      dispatch({
        type: GET_ALL_CONTRACTS,
        payload: []
      })
    }
    dispatch({
      type: FETCHING_CONTRACT_DATA,
      payload: false
    })
  }
}

// ==================================================================================================
/**
 * function empties the editing contract store
 */
export const emptyContractEditingStore = () => async dispatch => {
  dispatch({
    type: EMPTY_CONTRACT_EDITING_STORE,
    payload: null
  })
}
// ==================================================================================================
/**
 * deleting a contract specified by its contract id. Function deletes the contract from the store
 * after deleting it from the DB.
 * @param {string} contractID
 */
export const deleteContractByContractID = contractID => async dispatch => {
  await RestService('DELETE', `/contract/${contractID}`)
}

export const toggleRegistrationModal = value => ({
  type: SHOW_REGISTRATION_MODAL,
  payload: value
})

export const saveSignatures = data => (dispatch, getState) => {
  const newContract = {
    ...getState().contract.contractEditing,
    ...data,
    contractStatus: 'signed',
    dateUpdated: (new Date()).toISOString()
  }
  dispatch({
    type: SAVE_CONTRACT,
    payload: newContract
  })
}

export const resetContract = (data = {}) => (dispatch, getState) => {
  dispatch({
    type: SAVE_CONTRACT,
    payload: { fieldsResponse: {}, ...data }
  })
  if (data.contractID) {
    const responses = (getState().organisation.fieldResponses || {})
    dispatch({
      type: FETCH_ORGANISATION_FIELDS_RESPONSE,
      payload: {
        ...responses,
        [data.contractID]: {}
      }
    })
  }
}

export const askReviewForContract = () => async (dispatch, getState) => {
  const contract = getState().contract.contractEditing
  const response = await RestService('POST', `/contract/${contract.contractID}/askForReview`)
  dispatch({
    type: SAVE_CONTRACT,
    payload: { ...contract, ...response, dateUpdated: (new Date()).toISOString() }
  })
}

export const freezeContract = (sectionsCollection, unFreeze = false) => async (dispatch, getState) => {
  const contract = getState().contract.contractEditing
  const response = await RestService('POST', `/contract/${contract.contractID}/${unFreeze ? 'unFreeze' : 'freeze'}`)
  if (!response.signaturesHolder) { response.signaturesHolder = undefined }
  const newContract = { ...contract, ...response, dateUpdated: (new Date()).toISOString() }

  //  Then load and wait all the sections in background (refresh redlines)
  if ((newContract.signaturesHolder || {}).pendingRedlinesApprovedBy) {
    await dispatch(ensureTemplateSections(getState().template.templateCreator, sectionsCollection))
    await dispatch(loadComments(newContract.contractID, 'contract'))
  }

  dispatch({
    type: SAVE_CONTRACT,
    payload: newContract
  })
}

export const requestSigningPin = data => async (dispatch, getState) => {
  const { token } = (getState().user.ownProfileData || {}).tokenData || {}
  const contract = getState().contract.contractEditing
  return RestService(
    'POST',
    token
      ? `/token/${token}/signing`
      : `/contract/${contract.contractID}/signature/${encodeURIComponent(data.signatureID)}`,
    data,
    !token
  )
}

export const confirmSigning = (signatureID, data) => async (dispatch, getState) => {
  const { token } = (getState().user.ownProfileData || {}).tokenData || {}
  const contract = getState().contract.contractEditing
  const response = await RestService(
    'PUT',
    token
      ? `/token/${token}/signing`
      : `/contract/${contract.contractID}/signature/${encodeURIComponent(signatureID)}`,
    data,
    !token
  )

  const newContract = {
    ...contract,
    signaturesHolder: { ...contract.signaturesHolder, ...response },
    dateUpdated: (new Date()).toISOString()
  }

  //  Update the status of the contract
  if (response.signedDate) {
    newContract.contractStatus = 'signed'
    newContract.signedDate = response.signedDate
  }

  dispatch({
    type: SAVE_CONTRACT,
    payload: newContract
  })
}

export const pinLessSigning = (signatureID, selectedHandwriting) => async (dispatch, getState) => {
  const { token } = (getState().user.ownProfileData || {}).tokenData || {}
  const contract = getState().contract.contractEditing
  const response = await RestService(
    'POST',
    token
      ? `/token/${token}/signing`
      : `/contract/${contract.contractID}/signature/${encodeURIComponent(signatureID)}`,
    { selectedHandwriting },
    !token
  )

  const newContract = {
    ...contract,
    signaturesHolder: { ...contract.signaturesHolder, ...response },
    dateUpdated: (new Date()).toISOString()
  }

  //  Update the status of the contract
  if (response.signedDate) {
    newContract.contractStatus = 'signed'
    newContract.signedDate = response.signedDate
  }

  dispatch({
    type: SAVE_CONTRACT,
    payload: newContract
  })
}

export const forwardParty = (party, extra) => async (dispatch, getState) => {
  const contract = getState().contract.contractEditing

  const signatures = [...contract.signaturesHolder.signatures]
  const index = signatures.findIndex(({ signatureID }) => signatureID === party)
  if (index >= 0) {
    delete signatures[index].userAccess
    signatures[index] = {
      ...signatures[index],
      ...extra
    }
  }

  dispatch({
    type: SAVE_CONTRACT,
    payload: { ...contract, signaturesHolder: { ...contract.signaturesHolder, signatures } }
  })
}

export const publishContract = date => async (dispatch, getState) => {
  const contract = getState().contract.contractEditing
  const res = await RestService('POST', `/contract/${contract.contractID}/publish`, { date })

  dispatch({
    type: SAVE_CONTRACT,
    payload: { ...contract, ...res, __NEED_INVITE__: true },
    __NEED_INVITE__: true
  })
}

export const prepareContractAction = sectionsCollection => async (dispatch, getState) => {
  const contract = { ...getState().contract.contractEditing, draftedDate: (new Date()).toISOString() }
  const res = await RestService('PUT', `/contract/${contract.contractID}`, contract)

  if (res.__result__ && res.__template__) {
    //  Call update template with fake dispatch to avoid update of the state yet (and rerendering/reloading views)
    const template = await updateTemplate({
      ...res.__template__,
      dateUpdated: (new Date()).toISOString(),
      public: false,
      community: false,
      demo: false
    })(() => null, getState)

    //  Update our contract data
    const newContract = { ...contract, ...res.__result__ }
    newContract.template = template
    newContract.templateID = template.templateID

    //  Create dispatch & state mock
    const tmpState = { ...getState() }
    tmpState.template = { ...tmpState.template, templateCreator: template }
    tmpState.contract = { ...tmpState.contract, contractEditing: newContract }

    const tmpDispatch = data => {
      if (typeof data === 'function') {
        data(tmpDispatch, () => tmpState)
      } else {
        dispatch(data)
      }
    }

    //  Then load and wait all the sections in background
    await tmpDispatch(ensureTemplateSections(template, sectionsCollection))

    //  Update FieldsResponse Organisationwide
    if (Object(res.__input_values__) === res.__input_values__) {
      await batchUpdateContractFields(res.__input_values__, false)
    }
    //  Finally update the state
    await new Promise(resolve => {
      setTimeout(async () => {
        await Promise.all([
          dispatch({
            type: SAVE_CONTRACT,
            payload: { ...getState().contract.contractEditing, ...res.__result__, template }
          }),
          dispatch({
            type: types.UPDATE_TEMPLATE,
            payload: template
          })
        ])
        resolve()
      }, 500)
    })
  } else {
    await dispatch({
      type: SAVE_CONTRACT,
      payload: { ...contract, ...res }
    })
  }
}

export const signContractExtension = extensionID => async (dispatch, getState) => {
  const contract = getState().contract.contractEditing
  const response = await RestService('POST', `/contract/${contract.contractID}/extension/${extensionID}`)
  const newContract = { ...contract, ...response }
  dispatch({
    type: SAVE_CONTRACT,
    payload: newContract
  })
}


/** **********************************************************************
 *       Parties management actions (backend source of truth)
 ********************************************************************** */
const partyManagement = payload => async (dispatch, getState) => {
  const { token } = (getState().user.ownProfileData || {}).tokenData || {}
  const { contractID } = getState().contract.contractEditing
  let contractPartialUpdate

  if (token) {
    contractPartialUpdate = await RestService('put', `/token/${token}/manageParties`, payload, false)
  } else {
    contractPartialUpdate = await RestService('put', `/contract/${contractID}/parties`, payload)
  }

  //  Add the new invited guy to the store
  if (contractPartialUpdate.__NEW_INVITE__) {
    const invite = contractPartialUpdate.__NEW_INVITE__
    delete contractPartialUpdate.__NEW_INVITE__

    const { addRoleToStore } = generateInstanceRolesActions('contract', contractID)
    await dispatch(addRoleToStore(invite))
  }

  await dispatch({
    type: SAVE_CONTRACT,
    payload: {
      ...getState().contract.contractEditing,
      ...contractPartialUpdate
    }
  })
}

export const addPartySigning = (partyID, signing) => partyManagement({ addPartyID: partyID, signing })

export const switchSigning = (partyKey, signing) => partyManagement({ switchPartyKey: partyKey, signing })

export const inviteExternalParty = (invite, partyKey) => partyManagement({ invitePartyKey: partyKey, invite })

