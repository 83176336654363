import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'

import { StickyMainContent } from '../../../../../Layouts/MainLayout/MainContainer'
import { LayoutSidebarWidthContext } from '../../../../../Layouts/LayoutSidebar'
import { ScrollbarWidthContext } from '../../../../../../GlobalContext'
import { ContractContext } from '../../../Contexts'
import { UserAndCompanyDataContext } from '../../../../../Layouts/Constants'
import { ExternalContext } from '../../../../../ExternalViews/ExternalContext'
import { ConfirmButton } from '../../../../../SharedComponents/FormComponents'
import { YjsDeltaComment } from '../Commenting/Types'
import Portal from '../../../../../SharedComponents/Portal'
import InfoBox from '../../../../../Alert/InfoBox'
import PartiesCollapse from './PartiesCollapse'
import useGroups, { UseGroupsProps } from './Context/useGroups'
import UserSigningSimpleSwitch from './UserCards/UserSigningSimpleSwitch'

import './VerifyBeforeSigningDrawerStyles.scss'

interface DrawerProps {
  visible: boolean
  close: () => void
  onConfirm: () => Promise<void> | void
  title?: React.ReactElement
}

interface UnclosedRedlining {
  internal: number
  external: number
}

const useCountUnclosedRedlining = (): UnclosedRedlining => {
  const { contract: { contractID } } = useContext<any>(ContractContext)
  const storeComms: any[] = useSelector(useCallback(state => state.organisation.instanceComments[contractID], [contractID]))

  return useMemo(() => {
    let external = 0
    let internal = 0

    if (Array.isArray(storeComms)) {
      storeComms.forEach(comm => {
        if (Object(comm.delta) === comm.delta) {
          const delta = comm as YjsDeltaComment

          if (delta.fromExternal) {
            if (!delta.managedDate) { external += 1 }
          } else if (delta.rate !== 'rejected' && !delta.externalyManagedDate) { internal += 1 }
        }
      })
    }

    return { internal, external }
  }, [storeComms])
}


const signContract = <Trans>Sign contract</Trans>
const Drawer: React.FC<DrawerProps> = ({ visible, close, title, children }) => {
  const width = useContext(LayoutSidebarWidthContext)
  const right = `${useContext(ScrollbarWidthContext)}px`

  return (
    <Portal cssSelector='body'>
      <StickyMainContent
        className={`fixedContent commentThread ${visible ? 'open' : 'close'} verifyBeforeSigningDrawer`}
        onClick={evt => evt.stopPropagation()}
        style={{ width: `calc(${width} + ${right})`, maxWidth: '28rem' }}
      >
        <div className='threadTitle'>
          <Button
            className='noBorder' ghost icon={<ArrowLeftOutlined />} onClick={close}
            shape='circle' type='default'
          />
          <h2>{title || signContract}</h2>
        </div>
        <div className='content'>
          {children}
        </div>
      </StickyMainContent>
    </Portal>
  )
}


const useConfig = (): UseGroupsProps => {
  const { tokenData = {} } = useContext(ExternalContext)
  const filterInternFromExtern = !tokenData.token
  return useMemo(() => ({
    InternalComponent: UserSigningSimpleSwitch,
    ExternalComponent: UserSigningSimpleSwitch,
    noInvite: true,
    filterInternFromExtern
  }), [filterInternFromExtern])
}
const VerifyPartiesDrawer: React.FC<DrawerProps> = props => (
  <Drawer {...props}>
    <h4><Trans>Are all the signatories added to the contract?</Trans></h4>
    <PartiesCollapse groups={useGroups(useConfig())} />
    <ConfirmButton
      block
      confirmMessage={<Trans>Are all parties who sign the contract added at this point?</Trans>}
      onClick={props.onConfirm}
      type='primary'
    >
      <Trans>Start Signing</Trans>
    </ConfirmButton>
    <InfoBox>
      <Trans>All parties need to be added at this point. After this step, you can only give permission to view.</Trans>
    </InfoBox>
  </Drawer>
)

const VerifyRedlinesDrawer: React.FC<DrawerProps & UnclosedRedlining> = ({ internal, external, ...props }) => (
  <Drawer {...props}>
    <h4><Trans>There are unapproved redlines. They are all accepted if you continue to sign.</Trans></h4>
    {props.children}
    <ConfirmButton
      block
      confirmMessage={<Trans>Accept all pending redlines?</Trans>}
      onClick={props.onConfirm}
      type='primary'
    >
      <Trans>Continue</Trans>
    </ConfirmButton>
    <InfoBox>
      <Trans>Your contracting party will be informed that you accepted them</Trans>
    </InfoBox>
  </Drawer>
)


export const useHasChangesToVerify = (visible: boolean): [boolean, () => void] => {
  const { contract } = useContext<any>(ContractContext)
  const { user: { userID } } = useContext(UserAndCompanyDataContext)
  const { tokenData } = useContext(ExternalContext)
  const user = tokenData?.contact?.partyID || userID

  const [changesVerified, setChangesVerified] = useState(true)
  const needChangesCheck = (contract?.signaturesHolder?.pendingRedlinesApprovedBy || user) !== user
  useMemo(() => setChangesVerified(!needChangesCheck), [visible, needChangesCheck])

  return [changesVerified, useCallback(() => setChangesVerified(true), [])]
}

export const VerifyChangesDrawer: React.FC<DrawerProps & { Component?: React.FC<DrawerProps> }> = ({ Component = Drawer, ...props }) => (
  <Component {...props}>
    <h4><Trans>Your counterparty has accepted the pending redlines.</Trans></h4>
    <Button
      block
      onClick={props.onConfirm}
      type='primary'
    >
      <Trans>Continue</Trans>
    </Button>
    <InfoBox>
      <Trans>You can see the changes reflected in the contract</Trans>
    </InfoBox>
  </Component>
)


const VerifyBeforeSigningDrawer: React.FC<DrawerProps> = ({ visible, close, onConfirm }) => {
  //  Number of unclosed redlining
  const { internal, external } = useCountUnclosedRedlining()
  const [redlineVerified, setRedlineVerified] = useState(true)

  const needRedlineCheck = internal > 0 || external > 0
  useMemo(() => setRedlineVerified(!needRedlineCheck), [visible, needRedlineCheck])

  return (
    <>
      <VerifyRedlinesDrawer
        close={close}
        external={external}
        internal={internal}
        onConfirm={useCallback(() => setRedlineVerified(true), [])}
        visible={visible && !redlineVerified}
      />
      <VerifyPartiesDrawer
        close={close}
        onConfirm={onConfirm}
        visible={visible && redlineVerified}
      />
    </>
  )
}

export default VerifyBeforeSigningDrawer
