import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DatePicker as AntDatePicker, TimePicker as AntTimePicker } from 'antd'
// eslint-disable-next-line no-restricted-imports
import { PickerDateProps, PickerTimeProps } from 'antd/lib/date-picker/generatePicker'
import { Moment } from 'moment'
import { dateFormat, dateFormatDB, parseDate, parseTime, timeFormatDB } from '../Template/_TemplateHelperFunctions'


interface DatePickerProps extends Omit<PickerDateProps<Moment>, 'onChange' | 'value' | 'format'> {
  onChange?: (date: string | null | undefined) => void
  value?: string | null | undefined
  inputYearMonthDate?: boolean
  noDateConversion?: boolean
}

interface TimePickerProps extends Omit<PickerTimeProps<Moment>, 'onChange' | 'value' | 'format'> {
  onChange?: (date: string | null | undefined) => void
  value?: string | null | undefined
}


export const DatePicker: React.FC<DatePickerProps> = ({
  onChange,
  value,
  inputYearMonthDate,
  noDateConversion,
  ...props
}) => {
  const [mode, setMode] = useState<DatePickerProps['mode']>(undefined)

  const changeHandler = useCallback<(val: Moment | null) => void>(val => {
    onChange?.(val && (noDateConversion ? val.format(dateFormatDB) : val.toISOString()))
  }, [onChange, noDateConversion])

  const otherProps = useMemo(() => (inputYearMonthDate ? ({
    onBlur: () => setMode('year'),
    onPanelChange: (val, newMode) => {
      changeHandler(val)
      setMode(newMode)
    }
  }) : ({})), [changeHandler, inputYearMonthDate])
  useEffect(() => setMode(inputYearMonthDate ? 'year' : undefined), [inputYearMonthDate])

  return (
    <AntDatePicker
      {...props}
      {...otherProps}
      format={dateFormat}
      mode={mode}
      onChange={changeHandler}
      value={parseDate(value)}
    />
  )
}

export const TimePicker: React.FC<TimePickerProps> = ({ onChange, value, ...props }) => {
  const changeHandler = useCallback<(val: Moment | null) => void>(val => {
    onChange?.(val && val.format(timeFormatDB))
  }, [onChange])

  return <AntTimePicker{...props} onChange={changeHandler} value={parseTime(value)} />
}
