import { InputField, Section } from '@top-legal/datastore'
import { parseDate, parseTime, timeMapping } from '@top-legal/form-components'

import getFieldValue from './getFieldValue'


const isSectionActive = (
  {
    isDynamicSection, field: fieldKey, active, activeValues,
    isRelativeToContractFillingDate,
    inferiorBorder, superiorBorder, inferiorBorderType, superiorBorderType
  }: Section,
  fields: Map<string, InputField>,
  responses: Record<string, any>
): boolean => {
  if (isDynamicSection && fieldKey) {
    const [value, field] = getFieldValue(fields, fieldKey, responses, false) || []

    if (value == null || field == null) { return false }

    if (field.type === 'yesNo') {
      return value === active
    }

    if (field.type === 'list') {
      if (Array.isArray(activeValues)) {
        if (field.isMultipleChoices && Array.isArray(value)) {
          return activeValues.some(activeChoice => value.includes(activeChoice))
        }
        return activeValues.includes(value)
      }
      //  istanbul ignore next
      return false
    }

    if (/^(date|time|number|amount|listOfFormattedText)$/.test(field.type)) {
      let number: number = value

      const resetBorderTypes = () => {
        inferiorBorderType = undefined
        superiorBorderType = undefined
      }

      if (field.type === 'date') {
        const date = parseDate(value)
        //  istanbul ignore next
        if (date == null) { return false }
        number = date.valueOf()

        if (isRelativeToContractFillingDate) {
          number -= Date.now()
        } else { // The case of absolute date we need to parse the dates for comparison
          inferiorBorder = parseDate(inferiorBorder)?.valueOf()
          superiorBorder = parseDate(superiorBorder)?.valueOf()
          resetBorderTypes()
        }
      } else if (field.type === 'time') {
        const time = parseTime(value)
        //  istanbul ignore next
        if (time == null) { return false }
        number = time.valueOf()
        inferiorBorder = parseTime(inferiorBorder)?.valueOf()
        superiorBorder = parseTime(superiorBorder)?.valueOf()
        resetBorderTypes()
      } else if (field.type === 'listOfFormattedText') {
        //  istanbul ignore next
        if (!Array.isArray(value)) { return false }
        number = value.length
        resetBorderTypes()
      }

      const timesToType = type => timeMapping[type] || 1
      if (inferiorBorder != null) {
        if (number <= (inferiorBorder as number) * timesToType(inferiorBorderType)) {
          return false
        }
      }
      if (superiorBorder != null) {
        if (number > (superiorBorder as number) * timesToType(superiorBorderType)) {
          return false
        }
      }
    }
  }

  return true
}

export default isSectionActive
