import React from 'react'

import { Contract, InputField, Template } from '@top-legal/datastore'
import {UseGetAssistantStepsReturn} from "./useGetAssistantSteps";


export interface InputPartyProps {
  field: InputField
  fieldKey: string
  value: any
  onChange: (party: any) => void
  userAddressRequired?: boolean
  companyRequired?: boolean
}

type Resp = Record<string, any>
export interface ContractAssistantContextProps {
  contract: Contract
  template: Template
  fieldsMap: Map<string, InputField>
  getCombinedListSteps: UseGetAssistantStepsReturn['getCombinedListSteps']

  InputParty: React.FC<InputPartyProps>

  updateFiledResponse: (update: Resp) => Promise<any>

  onFieldOpened?: (fieldKey: string, activeSection: string) => void
  initialFieldID?: string

  currentCompanyName?: string
  currentPersonName?: string
}

const ContractAssistantContext = React.createContext<ContractAssistantContextProps>(undefined as any)

export default ContractAssistantContext
