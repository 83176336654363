import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { RxQuery } from 'rxdb/plugins/core'
import { RxReplicationStateBase } from 'rxdb/plugins/replication'

import { Collection, Doc, List, Model } from './Types'
import DatabaseContext from './DatabaseContext'

const identity = (data: any): any => data
const useSubscribedData = <T extends Model, QueryData, TransformData = QueryData>(
  query: RxQuery<T, QueryData>,
  dataTransform: ((data: QueryData) => TransformData) = identity
): [TransformData | undefined, boolean] => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<TransformData>()

  useEffect(() => {
    const subscription = query.$.subscribe({
      next: data_ => {
        const isBatchGet = (query as any).__BATCH_GET__

        if (process.env.NODE_ENV === 'development') {
          if (Array.isArray(data_)) {
            console.info('====> Got', data_.length, query.collection.name)
          } else if (Object(data_) === data_ && isBatchGet) {
            console.info('====> Got batch get', data_, query.collection.name)
          }
        }
        setData(dataTransform(data_))
        setLoading(false)
      },
      error: err => {
        console.error('Got error while observing', query.collection.name, err)
        setLoading(false)
      }
    })

    return () => subscription.unsubscribe()
  }, [query, dataTransform])

  return [data, loading]
}


// ==========================================================================================


export const useDoc = <T extends Model, M = {}, R = Doc<T, M>>(
  query: RxQuery<T, Doc<T, M> | null>,
  dataTransform?: (data: Doc<T, M> | null) => R
): [R | undefined, boolean] => useSubscribedData(query, dataTransform)

export const useList = <T extends Model, M = {}, R = List<T, M>>(
  query: RxQuery<T, List<T, M>>,
  dataTransform?: (data: List<T, M>) => R
): [R | undefined, boolean] => useSubscribedData(query, dataTransform)

export const useBatchGet = <T extends Model, M = {}, R = Map<string, Doc<T, M>>>(
  collection: Collection<T, M>,
  ids: string[],
  dataTransform?: (data: Map<string, Doc<T, M>>) => R
): [R | undefined, boolean] => {
  const fakeQuery = useMemo<any>(() => ({
    collection,
    $: collection.findByIds$(ids),
    __BATCH_GET__: true
  }), [collection, ids])
  return useSubscribedData(fakeQuery, dataTransform)
}


// ==========================================================================================


export const toJson = <T extends Model, M = {}>(doc: Doc<T, M> | null) => doc?.toJSON() as T | undefined

export const toJsonList = <T extends Model, M = {}>(list: List<T, M>): T[] => list.map(obj => obj.toJSON() as T)

export const toJsonMap = <T extends Model, M = {}>(map: Map<string, Doc<T, M>>): Map<string, T> => {
  const newMap = new Map<string, T>()
  map.forEach((val, key) => {
    newMap.set(key, val.toJSON() as T)
  })
  return newMap
}


// ==========================================================================================


//  Temp function
//  TODO: Remove use of that when RxDb & AppSync mutations are available
export const saveInStore = <T extends Model, M = {}>(
  collection: Collection<T, M>,
  docs: T | Doc<T, M> | T[] | Doc<T, M>[]
): Promise<void> => (collection.database as any).databaseWriters.write(collection.name, docs)

if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
  (window as any).saveInStore = saveInStore
  // eslint-disable-next-line no-console
  console.info('window.saveInStore available')
}


// ==========================================================================================


type Setter<T> = (func: (prev: undefined | T) => T) => Promise<T>
export const useLocalDoc = <T = Record<string, any>>(key: string): [T | undefined, Setter<T>] => {
  const database = useContext(DatabaseContext)
  const [doc, setDoc] = useState<T>()

  useEffect(() => {
    const subscription = database.getLocal$(key).subscribe(next => setDoc(next?.toJSON() || undefined))
    return () => subscription.unsubscribe()
  }, [database, key])

  const docRef = useRef(doc)
  docRef.current = doc
  const setter = useCallback<Setter<T>>(
    async func => {
      const res = await database.upsertLocal(key, func(docRef.current))
      setDoc(res.toJSON() as any)
      return res
    },
    [database, key]
  )

  return [doc, setter]
}


// ==========================================================================================


//  The browser storage is not infinite, modern browser allocate a % of remaining free space disk but safari only allow 1GB
//  More info at https://web.dev/storage-for-the-web
//  TODO: It is preferable for MacOS & iOS users to install as PWA app to escape the data eviction
export const safeStorageLimit = 1024 * 1024 * 1024 // 1GB
export const getStorageUsage = async (): Promise<StorageEstimate | undefined> => {
  if (navigator.storage && navigator.storage.estimate) {
    const quota = await navigator.storage.estimate()
    if (quota.usage != null && quota.quota != null) {
      console.info('[Datastore] Browser storage usage', ((quota.usage * 100) / quota.quota).toFixed(2), '%')
      console.info('[Datastore] Safe storage limit', (quota.usage / safeStorageLimit).toFixed(3), 'GB out of 1GB')
      return quota
    }
  }
  return undefined
}
