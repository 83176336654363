import * as Y from 'yjs'
import { Element, Node, Text } from 'slate'
import { SharedType } from 'slate-yjs'
import { TemplateEntityType } from '../Constants'

//  Base64 utility
const error = () => { throw new Error('Base64 operation not supported, neither window nor Buffer is available') }
export const toBase64 = (arr: Uint8Array): string => {
  if (typeof window !== 'undefined') {
    return window.btoa(String.fromCharCode.apply(null, arr as any))
  }
  if (typeof Buffer !== 'undefined') {
    return Buffer.from(arr).toString('base64')
  }
  return error()
}
export const fromBase64 = (str: string): Uint8Array => {
  if (typeof window !== 'undefined') {
    return new Uint8Array(Array.from(window.atob(str)).map(char => char.charCodeAt(0)))
  }
  if (typeof Buffer !== 'undefined') {
    return new Uint8Array(Buffer.from(str, 'base64'))
  }
  return error()
}

export const newDoc = (): Y.Doc => new Y.Doc({ gc: false })

export const applySnapshotPatch = (doc: Y.Doc, patch: string, origin: any = 'snapshot-patch'): void => {
  Y.applyUpdate(doc, fromBase64(patch), origin)
}

export const computeSnapshotPatch = (doc: Y.Doc, prevSnapshot?: Y.Snapshot): string => {
  const prevStateVector = prevSnapshot ? Y.encodeStateVector(prevSnapshot.sv) : undefined
  const update = Y.encodeStateAsUpdate(doc, prevStateVector)
  return toBase64(update)
}

export const getSlateContent = (patch: string): Node[] => {
  const doc = newDoc()
  applySnapshotPatch(doc, patch)
  return doc.getArray('doc').toJSON() as any
}

const toSyncElement = (node: Node) => {
  const element = new Y.Map()
  if (Element.isElement(node)) {
    const childElements = node.children.map(toSyncElement)
    const childContainer = new Y.Array()
    childContainer.insert(0, childElements)
    element.set('children', childContainer)
  }
  if (Text.isText(node)) {
    const textElement = new Y.Text(node.text)
    element.set('text', textElement)
  }
  Object.entries(node).forEach(([key, value]) => {
    if (key !== 'children' && key !== 'text') {
      element.set(key, value)
    }
  })
  return element
}

const toSharedType = (sharedType: SharedType, nodes: Node[]) => sharedType.insert(0, nodes.map(toSyncElement))

export const slateToYjsDoc = (nodes: Node[]): Y.Doc => {
  const doc = new Y.Doc({ gc: false })
  const userStore = new Y.PermanentUserData(doc)
  const sharedType: SharedType = doc.getArray('doc')

  userStore.setUserMapping(doc, doc.clientID, '__INITIAL_DATA__')
  doc.transact(() => toSharedType(sharedType, nodes), '__INITIAL_DATA__')

  return doc
}


//  Can be used for simple visitor or replacer (in place, note need normalisation after)
export const iterateTemplateEntities = (
  content: Node[],
  onEntityFound: (entityType: 'inputField' | 'conditionalText' | 'sectionReference', key: string, version?: string) => void | null | string | Node
) => {
  const nodesIterator = (nodes: Node[]) => nodes.forEach((node, index) => {
    let newNode = node
    if (node.type === TemplateEntityType) {
      const { entityType, key, version } = node as any
      const result = onEntityFound(entityType, key, version)

      //  Replace content if something is returned
      if (typeof result === 'string') {
        newNode = { type: TemplateEntityType, entityType, key: result, children: [{ text: '' }] } as any
        nodes[index] = newNode
      } else if (result || result === null) {
        newNode = result || { text: '' }
        nodes[index] = newNode
      }
    }
    if (Array.isArray(newNode.children)) {
      nodesIterator(newNode.children)
    }
  })

  nodesIterator(content)
}
