import React from 'react'

import { PlateRenderElementProps, PlateRenderLeafProps } from '@udecode/plate-core'

//  Import Plate element types
import { ELEMENT_IMAGE } from '@udecode/plate-image'
import { ELEMENT_LINK } from '@udecode/plate-link'
import { ELEMENT_LI, ELEMENT_OL, ELEMENT_UL } from '@udecode/plate-list'
import { ELEMENT_TABLE, ELEMENT_TD, ELEMENT_TH, ELEMENT_TR } from '@udecode/plate-table'
import { MARK_BOLD, MARK_ITALIC, MARK_UNDERLINE } from '@udecode/plate-basic-marks'
import { MARK_SEARCH_HIGHLIGHT } from '@udecode/plate-find-replace'

//  Import used Plate UI (complex components)
import { ImageElement } from '@udecode/plate-ui-image'

//  Custom components
import { Table, TableCell, TableRow } from './customPluginsComponents/TableComponents'

import { MarkerTypeBlock, MarkerTypeInline, ParagraphType, SectionRefType, TemplateEntityType } from '../core'
import TemplateEntity from './customPluginsComponents/TemplateEntitiesComponent'
import { createElementRender } from './Helpers'


/** ******************************************************************
 *            Define plate components mapping
 ****************************************************************** */
const plateComponents: Record<string, React.FC<PlateRenderElementProps> | React.FC<PlateRenderLeafProps>> = {
  [ELEMENT_IMAGE]: ImageElement as any,

  [ELEMENT_LINK]: createElementRender('a', element => ({
    href: (element as any).url,
    rel: 'noreferrer',
    target: '_blank'
  })),

  [ELEMENT_UL]: createElementRender('ul'),
  [ELEMENT_OL]: createElementRender('ol'),
  [ELEMENT_LI]: createElementRender('li'),

  [ParagraphType]: createElementRender('p', element => ({
    style: { textAlign: element.align || 'left ' }
  })),

  [ELEMENT_TABLE]: Table,
  [ELEMENT_TR]: TableRow,
  [ELEMENT_TD]: TableCell,
  [ELEMENT_TH]: TableCell,

  [MARK_BOLD]: createElementRender('b'),
  [MARK_ITALIC]: createElementRender('i'),
  [MARK_UNDERLINE]: createElementRender('u'),

  [MARK_SEARCH_HIGHLIGHT]: createElementRender('mark', () => ({
    style: { backgroundColor: '#FEF3B7' }
  })),

  //  Custom plugins
  [TemplateEntityType]: TemplateEntity,
  [MarkerTypeBlock]: TemplateEntity,
  [MarkerTypeInline]: TemplateEntity,
  [SectionRefType]: TemplateEntity

  //  Delta rendering
  //  Disabled due to empty leaves render skipping
  // [DELTA_MARK]: DeltaComponent
}

export default plateComponents
