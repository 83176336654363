import React from 'react'
import { Trans } from '@lingui/macro'
import { Radio } from 'antd'

import { AssistantInputProps } from './Types'
import InputQuestion from './inputElements/InputQuestion'
import FieldExplanation from './inputElements/FieldExplanation'
import useFieldSaving from './inputElements/useFieldSaving'

import './ListFieldInputStyles.scss'

const YesNoFieldInput: React.FC<AssistantInputProps> = props => (
  <>
    <InputQuestion field={props.field} />
    <Radio.Group className='contractAssistantListField' {...useFieldSaving(props, false, true)}>
      <Radio value='yes'><Trans>Yes</Trans></Radio>
      <Radio value='no'><Trans>No</Trans></Radio>
    </Radio.Group>
    <FieldExplanation field={props.field} />
  </>
)

export default YesNoFieldInput
