import React, { useCallback, useMemo, useRef } from 'react'

type InnerPropsType<DataType, Props> = Omit<Props, 'initialValue' | 'onChange' | 'children'> & { initialValue: DataType, onChange: (data: DataType) => void }
type OuterPropsType<DataType, Props> = Omit<Props, 'initialValue' | 'onChange' | 'children'> & { value: DataType, onChange: (data: DataType) => void }

/**
 * The goal of that component is to avoid rerendering when the value change due to update
 * Then it provides a initialValue that only change if we get something completely different
 */
const withValue2InitialValue = <DataType, Props>(
  Component: React.FC<InnerPropsType<DataType, Props>>
): React.FC<OuterPropsType<DataType, Props>> => {
  const InnerComponent = Component as any

  const Wrapped: React.FC<OuterPropsType<DataType, Props>> = ({ value, onChange, ...props }) => {
    const prevRef = useRef(value)
    const saveRef = useRef(value)

    useMemo(() => {
      if (value !== prevRef.current && value !== saveRef.current) {
        prevRef.current = value
        saveRef.current = value
      }
    }, [value])

    const newChange = useCallback((data: DataType) => {
      saveRef.current = data
      onChange?.(saveRef.current)
    }, [onChange])

    return <InnerComponent {...props} initialValue={prevRef.current} onChange={newChange} />
  }
  Wrapped.displayName = Component.displayName

  return Wrapped
}

export default withValue2InitialValue
