import React, { useContext, useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Button, Dropdown, Menu } from 'antd'
import { EditOutlined, LeftOutlined, MenuOutlined, MoreOutlined, RedoOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'


import MainContainer from '../Layouts/MainLayout/MainContainer'
import { ContractContext, TemplateContext } from '../Contract/ContractEditor/Contexts'
import { useComputeSectionMapping } from '../Contract/Utils'
import { resetContract } from '../Contract/redux/ContractActions'
import ButtonLink from '../SharedComponents/ButtonLink'
import ContractPageInner from '../Contract/ContractPageInner'


const TemplatePreview: React.FC = () => {
  const { url } = useRouteMatch()
  const { setWithWizard, setPreviewMode, previewMode } = useContext<any>(ContractContext)
  const { setReadOnly } = useContext<any>(TemplateContext)
  const dispatch = useDispatch()
  useComputeSectionMapping()

  //  Configure contexes
  useEffect(() => {
    setReadOnly(true)
    setWithWizard(true)
    setPreviewMode(true)
  }, [setReadOnly, setWithWizard, setPreviewMode])

  if (!previewMode) {
    return null
  }

  return (
    <MainContainer
      mainContentClass='templateSettingPage'
      topbarContent={(
        <>
          <div className='topbarMainContent templateSettingPageToolbar'>
            <ButtonLink
              ghost
              href={url.replace(/\/preview$/, '')}
              icon={<LeftOutlined />}
              shape='circle'
            />
            <h1 className='title'><Trans>Playbook preview</Trans></h1>
          </div>
          <div className='topbarActions'>
            <Dropdown
              overlay={(
                <Menu key='menu'>
                  <Menu.Item>
                    <Link to={url.replace(/\/preview$/, '')}>
                      <EditOutlined />
                      <span><Trans>Back to editing</Trans></span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      dispatch(resetContract({ contractID: '__preview__', contractStatus: 'filling' }))
                      setWithWizard(false)
                      setTimeout(() => setWithWizard(true))
                    }}
                  >
                    <RedoOutlined />
                    <span><Trans>Reset preview data</Trans></span>
                  </Menu.Item>
                </Menu>
              )}
              placement='bottomRight'
              trigger={['click']}
            >
              <Button
                className='noBorder moreButton'
                ghost
                // eslint-disable-next-line no-script-url
                href='javascript:void(0)'
                icon={(window as any).IS_TRANSFERINITIATIVE ? <MenuOutlined /> : <MoreOutlined />}
              />
            </Dropdown>
          </div>
        </>
      )}
    >
      <ContractPageInner />
    </MainContainer>
  )
}

export default TemplatePreview
