import React from 'react'
import { Collection, Model, TypeConfiguration } from '../Types'
import DatabaseContext from '../DatabaseContext'

export interface Event extends Model {
  id: string // RxDB id concatenation
  eventID: string
  objectID: string
  objectName: string
  organisationID: string
  date: string
  eventType: string
  userID?: string
  user?: any
  thirdParty?: any
}

const config: () => TypeConfiguration<Event> = () => JSON.parse(JSON.stringify({
  key: '',
  schema: {
    version: 0,
    primaryKey: 'id',
    type: 'object',
    properties: {
      id: {
        type: 'string'
      },
      date: {
        type: 'string'
      },
      eventID: {
        type: 'string'
      },
      eventType: {
        type: 'string'
      },
      objectID: {
        type: 'string'
      },
      objectName: {
        type: 'string'
      },
      organisationID: {
        type: 'string'
      },
      thirdParty: {},
      user: {},
      userID: {
        type: 'string'
      }
    },
    required: [
      'id',
      'eventID',
      'objectID',
      'objectName',
      'organisationID',
      'date',
      'eventType'
    ],
    indexes: ['date']
  }
} as TypeConfiguration<Event>))

export const otherEventsConfig = { ...config(), key: 'other-events' }
export const contractEventsConfig = { ...config(), key: 'contracts-events' }
export const templateEventsConfig = { ...config(), key: 'templates-events' }

export interface EventDataset {
  'other-events': Event[]
  'contracts-events': Event[]
  'templates-events': Event[]
}

export const useOtherEventsCollection = (): Collection<Event> => React.useContext(DatabaseContext)[otherEventsConfig.key]
export const useContractEventsCollection = (): Collection<Event> => React.useContext(DatabaseContext)[contractEventsConfig.key]
export const useTemplateEventsCollection = (): Collection<Event> => React.useContext(DatabaseContext)[templateEventsConfig.key]

export const eventsGQLQuery = `
{
  items {
    date
    eventID
    eventType
    objectID
    objectName
    organisationID
    thirdParty
    user
    userID
  }
  nextToken
}`
