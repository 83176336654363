import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Alert, Button, Input, Select, Skeleton, Tooltip } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'

import { InputField, useFieldsCollection } from '@top-legal/datastore'
import { productTranslations } from '@top-legal/editor'
import { AmountInput } from '@top-legal/form-components'

import { AssistantInputProps } from './Types'
import InputQuestion from './inputElements/InputQuestion'
import useFieldSaving from './inputElements/useFieldSaving'

import './ProductFieldInputStyles.scss'


interface ProductRow {
  fieldKey: string
  qty?: number
}


const pricingModels = {
  standard: <Trans>Standard pricing</Trans>,
  package: <Trans>Package pricing</Trans>,
  graduated: <Trans>Graduated pricing</Trans>,
  volume: <Trans>Volume pricing</Trans>
}

const currencies = {
  eur: <span className='currency'>EUR<span> - Euro</span></span>,
  usd: <span className='currency'>USD<span> - US Dollar</span></span>,
  gbp: <span className='currency'>GBP<span> - British Pound</span></span>
}


const ProductHelpTable: React.FC<{ field: InputField, trans: any }> = ({ field, trans }) => (
  <table className='productDescriptionTooltip'>
    <tbody>
      {field.currency && (
        <tr>
          <td><Trans>Currency</Trans></td>
          <td>{currencies[field.currency] || '-'}</td>
        </tr>
      )}
      {field.pricingModel && (
        <tr>
          <td><Trans>Pricing model</Trans></td>
          <td>{pricingModels[field.pricingModel] || '-'}</td>
        </tr>
      )}
      {field.subscriptionType && (
        <tr>
          <td><Trans>Type</Trans></td>
          <td>{field.subscriptionType === 'recurring' ? <Trans>Recurring</Trans> : <Trans>One time</Trans>}</td>
        </tr>
      )}
      {field.subscriptionType === 'recurring' && field.billingPeriod && (
        <tr>
          <td><Trans>Billing period</Trans></td>
          <td>{trans.billing[field.billingPeriod] || '-'}</td>
        </tr>
      )}
      {field.tax && (
        <tr>
          <td><Trans>Tax</Trans></td>
          <td>{field.tax}%</td>
        </tr>
      )}
      {field.taxMode && (
        <tr>
          <td><Trans>Tax mode</Trans></td>
          <td>{field.taxMode === 'inclusive' ? <Trans>Inclusive</Trans> : <Trans>Exclusive</Trans>}</td>
        </tr>
      )}
    </tbody>
  </table>
)


const lang = ''
const ProductInput: React.FC<AssistantInputProps & { products: Record<string, InputField> }> = ({ products, ...props }) => {
  const trans = productTranslations[lang] || productTranslations.en
  const [errors, setErrors] = useState<React.ReactNode[]>([])

  const { value, onChange } = useFieldSaving<ProductRow[]>(props)

  const internalValRef = useRef(value || [])
  useMemo(() => {
    internalValRef.current = value || []
  }, [value])


  const save = useCallback((newValue: ProductRow[]) => {
    internalValRef.current = newValue

    if (Array.isArray(newValue) && newValue.length > 0) {
      const errorMap: Record<string, React.ReactNode> = {}
      let sub, bill, taxN, taxM, cur // eslint-disable-line one-var, one-var-declaration-per-line

      newValue.forEach(({ fieldKey: pdtKey }) => {
        const pdt = products[pdtKey]
        if (pdt) {
          if (!sub) { sub = pdt.subscriptionType }
          if (sub !== pdt.subscriptionType) {
            errorMap.sub = <Trans>One time payment & recurring payment cannot be mixed</Trans>
          }

          if (!bill) { bill = pdt.billingPeriod }
          if (bill !== pdt.billingPeriod) {
            errorMap.bill = <Trans>Having the same billing period</Trans>
          }

          if (!taxN) { taxN = pdt.tax }
          if (taxN !== pdt.tax) {
            errorMap.taxN = <Trans>Having the same tax applied</Trans>
          }

          if (!taxM) { taxM = pdt.taxMode }
          if (taxM !== pdt.taxMode) {
            errorMap.taxM = <Trans>Having the same tax mode (inclusive or exclusive)</Trans>
          }

          if (!cur) { cur = pdt.currency }
          if (cur !== pdt.currency) {
            errorMap.cur = <Trans>Having the same currency</Trans>
          }
        }
      })
      const newErrors = Object.values(errorMap)
      setErrors(newErrors)

      //  Save if no error
      if (newErrors.length === 0) {
        onChange(internalValRef.current)
      }
    }
  }, [onChange, products])


  const onQuantityChange = useCallback((index: number, val: number | undefined) => {
    const arr = [...internalValRef.current]
    arr[index].qty = val
    save(arr)
  }, [save])


  return (
    <div className='productTableInput'>
      <Select
        className='simple-line'
        onSelect={key => save([...internalValRef.current, { fieldKey: key }])}
        placeholder={<Trans>Product & Services</Trans>}
        value={null as any}
      >
        {Object.entries(products).map(([key, { productName }]) => !internalValRef.current.find(({ fieldKey }) => fieldKey === key) && (
          <Select.Option key={key} value={key}>{productName}</Select.Option>
        ))}
      </Select>
      <table>
        <tbody>
          {internalValRef.current.map((row, index) => {
            const pdt = products[row.fieldKey]

            if (pdt) {
              return (
                <tr key={row.fieldKey}>
                  <Tooltip placement='left' title={<ProductHelpTable field={pdt} trans={trans} />}>
                    <td>{pdt.productName}</td>
                  </Tooltip>
                  <td>
                    {pdt.isMeteredUsage ? (
                      <Input className='simple-line' disabled value={trans.usage} />
                    ) : (
                      <AmountInput
                        className='simple-line'
                        onChange={num => onQuantityChange(index, num)}
                        placeholder={pdt.unitLabel || trans.units}
                        value={row.qty}
                      />
                    )}
                  </td>
                  <td>
                    <Button
                      className='noBorder'
                      ghost
                      icon={<CloseOutlined />}
                      onClick={() => {
                        const arr = [...internalValRef.current]
                        arr.splice(index)
                        save(arr)
                      }}
                      size='small'
                    />
                  </td>
                </tr>
              )
            }

            return null
          })}
        </tbody>
      </table>
      {errors.length > 0 && (
        <Alert
          description={(
            <ul>
              {errors.map((error, index) => <li key={index}>{error}</li>)}
            </ul>
          )}
          message={<Trans>Verify your data to validate following rules</Trans>}
          type='warning'
        />
      )}
    </div>
  )
}


const ProductFieldInput: React.FC<AssistantInputProps> = props => {
  const [availableProducts, setAvailableProducts] = useState<Record<string, InputField>>()

  const fieldsCollection = useFieldsCollection()
  useMemo(() => {
    fieldsCollection.find().where({ type: 'product' }).exec().then(list => {
      const map: Record<string, InputField> = {}

      list.forEach(pdt => {
        map[pdt.inputFieldID] = pdt.toJSON()
      })

      setAvailableProducts(map)
    })
  }, [fieldsCollection])

  if (availableProducts == null) {
    return <Skeleton active />
  }

  return (
    <>
      <InputQuestion field={props.field} />
      <ProductInput {...props} products={availableProducts} />
    </>
  )
}

export default ProductFieldInput
