/* eslint-disable max-lines */
import React, { useState } from 'react'
import {
  Alert, Button, Col, Form, Input, Radio, Row, Steps, Switch
} from 'antd'
import { CheckOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { t, Trans } from '@lingui/macro'
import { ConsoleLogger as Logger } from '@aws-amplify/core'

//  Editor library
import { FormEditor, TemplateEntityType } from '@top-legal/editor'

//  Components
import { ListEdition, Select, SwitchInput } from '../../../../SharedComponents/FormComponents'
import CustomFormWrapper, { requiredRules } from '../../../../SharedComponents/CustomFormWrapper'
// functions
import { getElem, parseTemplateEntitiesInHtml } from '../../../../Template/_TemplateHelperFunctions'
import { buildContractInputQuestion, templateInputsType } from '../../../../Template/TemplateHelperFunctions'
import { TemplateContext } from '../../Contexts'
import Sidebar, { SidebarToolboxes } from '../../Sidebar/Sidebar'
import TemplateEntitiesToolboxes from '../../Sidebar/SidebarToolboxes/TemplateEntitiesToolbox/TemplateEntitiesToolbox'
import { MediaLibrarySelector } from '../../../../Media/MediaLibrary'
import TableOfContent from '../../TableOfContent/TableOfContent'
import { DefaultParty } from '../../Sidebar/SidebarToolboxes/QuestionsWizard/InputPartiesContacts'
import { ProductFieldConfigurationCurrentStep } from './InputFieldConfigurations/ProductFieldConfiguration'
import { noop } from '../../../../Defaults'

import './CreateInputFieldsFormStyles.scss'

const logger = new Logger('CreateInputFieldForm')

export const ExplanationsInput = ({ initialValue, inputSuffix = '' }) => {
  const [showVideo] = useState(false)
  return (
    <div className='explanationContainer'>
      {/* Disabled video explanation for now */}
      {/* <div className='explanationSwitchWrapper'> */}
      {/*  <Form.Item> */}
      {/*    <Radio.Group buttonStyle='solid' onChange={evt => setShowVideo(evt.target.value === 'video')} value={showVideo ? 'video' : 'text'}> */}
      {/*      <Radio.Button value='text'><Trans>Text explanation</Trans></Radio.Button> */}
      {/*      <Radio.Button value='video'><Trans>Video explanation</Trans></Radio.Button> */}
      {/*    </Radio.Group> */}
      {/*  </Form.Item> */}
      {/* </div> */}
      <div className='explanationVideo' style={{ display: showVideo ? null : 'none' }}>
        <h3><Trans>Create a explanation video</Trans></h3>
        <Form.Item initialValue={initialValue[`explanationVideo${inputSuffix}`]} name={`explanationVideo${inputSuffix}`}>
          <MediaLibrarySelector media='audioVideo' />
        </Form.Item>
      </div>
      <div className='practicalUserTip' style={{ display: showVideo ? 'none' : null }}>
        <h3><Trans>Create a practical tip</Trans></h3>
        <Form.Item
          className='userTipInputField'
          initialValue={initialValue[`explanationText${inputSuffix}`] || ''}
          name={`explanationText${inputSuffix}`}
        >
          <FormEditor placeholder={t`Practical tip (optional)`} />
        </Form.Item>
      </div>
    </div>
  )
}


const partiesTypes = {
  person: templateInputsType.person,
  company: templateInputsType.company
}


// ==================================================================================================
// ==================================================================================================
/**
 * InputField creator that is opened in a modal
 */
class CreateInputFieldForm extends React.Component {
  // ==================================================================================================s
  constructor (props) {
    super(props)
    Object.defineProperty(this, 'context', {
      configurable: true,
      get: () => this.props,
      set: () => { }
    })

    // starting a new looger
    this.logger = new Logger('CreateInputFieldForm')
    const fieldsData = []
    Object.keys(this.context.inputFields).forEach(inputFieldID => {
      Object.keys(this.context.inputFields[inputFieldID]).forEach(version => {
        fieldsData.push(this.context.inputFields[inputFieldID][version])
      })
    })
    fieldsData.sort((a, b) => a.inputFieldID - b.inputFieldID)
    this.state = {
      step: 0,
      values: { ...this.props.initialValue },
      loading: false,
      fieldsData,
      formattedFields: fieldsData,
      initialValue: this.props.initialValue,
      createNewFields: Object.keys(this.props.initialValue).length > 0
    }

    this.isParty = ['parties', 'person', 'company'].includes(this.props.initialValue.type)
    if (this.props.initialValue.type === 'parties') {
      delete this.props.initialValue.type
      delete this.state.values.type
    }

    //  Products do not need the first step
    if (this.props.initialValue.type === 'product') {
      this.state.step = 1
      this.state.internalStep = 0
    }
  }

  previousFieldType = null

  isCombinedList = false

  // ==================================================================================================
  /**
   * preview returns the preview form building the questions or alternatively an alert
   * notifying the user that the type needs to be selected to preview the component.
   */
  createPreview = form => {
    const inputFieldProperties = {
      name: this.context.lang === 'en' ? 'Preview' : 'Vorschau',
      key: this.context.lang === 'en' ? 'Preview' : 'Vorschau',

      //  Case of list of choices
      isMultipleChoices: false,
      values: {
        val1: this.context.lang === 'en' ? 'Value 1' : 'Wert 1',
        val2: this.context.lang === 'en' ? 'Value 2' : 'Wert 2'
      },

      //  Common case
      question: <Trans>Please enter a input question</Trans>,
      type: null,
      explanationText: '',
      explanationVideo: '',

      //  Case of listOfFormattedText
      isBasedOnPreviousList: false,
      previousListField: null,
      formattedText: '',
      displayLabel: '',

      //  Product
      productName: this.context.lang === 'en' ? 'Placeholder' : 'Platzhalter',
      productDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed molestie neque magna, non tempor sem ultricies nec.',
      unitLabel: this.context.lang === 'en' ? 'for product & services' : 'für Produkte und Services',
      pricingModel: 'package',
      unitNumber: 10,
      unitPrice: 150,
      currency: 'eur',
      billingPeriod: 'monthly',

      //  Overrides
      ...this.props.initialValue,
      ...this.state.values,
      ...form.getFieldsValue()
    }

    if (['company', 'person'].includes(inputFieldProperties.type)) {
      return <DefaultParty type={inputFieldProperties.type} />
    }

    if (inputFieldProperties.type !== this.previousFieldType) {
      this.previousFieldType = inputFieldProperties.type
      form.setFieldsValue({ preview: null })
      return null
    }

    if (inputFieldProperties && inputFieldProperties.type) {
      const { values } = inputFieldProperties
      if (Object(values) === values && Object.keys(values).length === 0) {
        inputFieldProperties.values = {
          val1: 'Value 1',
          val2: 'Value 2'
        }
      }
      return buildContractInputQuestion(
        this.context.template,
        'preview',
        form,
        {},
        inputFieldProperties,
        this.context.lang,
        false
      )
    }
    return (
      <Alert
        message={<Trans>You need to input a field type before start preview things.</Trans>}
        showIcon
        type='info'
      />
    )
  }

  // ==================================================================================================

  renderFirstStep = () => {
    const initialValue = { ...(this.props.initialValue || {}), ...this.state.values }
    const { showGeneralize } = this.props
    logger.debug('items:', templateInputsType)
    return (
      <>
        {/** *************************************************************************
         *                           Input question
         ************************************************************************** */}
        {!this.isParty && (
          <Row align='top' justify='start' type='flex'>
            <Col span={24}>
              <div className='sentenceWrapper'>
                <span className='promptText'>
                  <Trans>I ask the user: </Trans>
                </span>
                <Form.Item
                  className='inputModalFormItem'
                  initialValue={initialValue.question}
                  name='question'
                  rules={requiredRules}
                >
                  <Input className='inTextInput simple-line' placeholder={t`Input question`} />
                </Form.Item>
              </div>
            </Col>
          </Row>
        )}
        {/** *************************************************************************
         *                               Input type
         ************************************************************************** */}
        {this.isParty && (
          <Row justify='start' style={{ marginTop: 5 }}>
            <Col span={24}>
              <div className='sentenceWrapper'>
                <span className='promptText'>
                  <Trans>My counterparty is a</Trans>
                </span>
                <Form.Item
                  className='inputModalFormItem'
                  initialValue={initialValue.type}
                  name='type'
                  rules={[{
                    required: true,
                    message: t`This is an required input`
                  }]}
                >
                  <Radio.Group disabled={!!initialValue.type}>
                    <Radio value='company'><Trans>Company</Trans></Radio>
                    <Radio value='person'><Trans>Person</Trans></Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </Col>
          </Row>
        )}
        {/** *************************************************************************
         *                               Input name
         ************************************************************************** */}
        <Row justify='start' style={{ marginTop: 5 }} type='flex'>
          <Col span={24}>
            <div className='sentenceWrapper'>
              <span className='promptText'>
                <Trans>Reference name:</Trans>
              </span>
              <Form.Item
                className='inputModalFormItem'
                initialValue={initialValue.name}
                name='name'
                rules={requiredRules}
              >
                <Input className='inTextInput simple-line' placeholder={t`Name of input field`} />
              </Form.Item>
            </div>
          </Col>
        </Row>
        {/** *************************************************************************
         *                               Search and replace
         ************************************************************************** */}
        {showGeneralize && (
          <Row justify='start' style={{ marginTop: 5 }} type='flex'>
            <Col span={24}>
              <div className='sentenceWrapper'>
                <span className='promptText'>
                  <Trans>Set as input field for all sections:</Trans>
                </span>
                <Form.Item
                  className='inputModalFormItem'
                  initialValue={initialValue.generalize || true}
                  name='generalize'
                >
                  <Switch className='simple-line' defaultChecked />
                </Form.Item>
              </div>
            </Col>
          </Row>
        )}
      </>
    )
  }

  // ==================================================================================================

  renderConfigurationStep = () => {
    const { template } = this.context
    const initialValue = { ...(this.props.initialValue || {}), ...this.state.values }

    if (initialValue.type === 'list') {
      return (
        <>
          {/** *************************************************************************
           *                       Is it a multiple choices input?
           ************************************************************************** */}
          <Row align='top' justify='start' type='flex'>
            <Col span={24}>
              <div className='sentenceWrapper'>
                <span className='promptText'>
                  <Trans>The user can select multiple values: </Trans>
                  {' '}
                </span>
                <Form.Item
                  className='inputModalFormItem'
                  initialValue={initialValue.isMultipleChoices}
                  name='isMultipleChoices'
                >
                  <SwitchInput className='inTextInput large' />
                </Form.Item>
              </div>
            </Col>
          </Row>
          {/** *************************************************************************
           *                           What are the choices?
           ************************************************************************** */}
          <Row justify='start' style={{ marginTop: 10 }} type='flex'>
            <Col span={23}>
              <span><Trans>I want to provide the following choices to the user:</Trans></span>
            </Col>
          </Row>
          <Row justify='start' style={{ marginTop: 10 }} type='flex'>
            <Col span={23}>
              <Form.Item
                initialValue={initialValue.values || ['Value 1', 'Value 2']}
                name='values'
                rules={[{
                  validator: (_, value, callback) => {
                    if (Object.keys(value).length < 2) {
                      callback('Please create at least two choices!')
                    } else {
                      callback()
                    }
                  }
                }]}
              >
                <ListEdition
                  InputComponent={({ index, ...props }) => (
                    <Input
                      className='listInput simple-line'
                      {...props}
                      placeholder={t`Value ${index + 1}`}
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )
    }
    if (initialValue.type === 'number') {
      return (
        <div className='unitSelection sentenceWrapper'>
          <span className='promptText'>
            <Trans>Choose the unit type of this field:</Trans>
          </span>
          <Form.Item
            className='unitInput inputModalFormItem'
            initialValue={initialValue.unit}
            name='unit'
          >
            <Input className='simple-line' placeholder={t`Unit (optional)`} />
          </Form.Item>
        </div>
      )
    }
    // if the component is a combined list
    if (initialValue.type === 'listOfFormattedText') {
      const uniqueID = `sidebar-${Date.now()}`

      if (this.state.internalStep === 0) {
        return (
          <>
            {/** *************************************************************************
             *                 Is this list is based on a previous one?
             ************************************************************************** */}
            <div className='sentenceWrapper'>
              <span className='promptText'>
                <Trans>Would you like to extend an existing “{templateInputsType.listOfFormattedText}”: </Trans>
                {' '}
              </span>
              <Form.Item
                className='inputModalFormItem'
                initialValue={initialValue.isBasedOnPreviousList}
                name='isBasedOnPreviousList'
              >
                <SwitchInput className='inTextInput large' />
              </Form.Item>
            </div>

            {/** if the user has chosen to extend a previous combined list */}
            <Form.Item shouldUpdate={true}>
              {form => {
                const displayCombinedList = form.isFieldTouched('isBasedOnPreviousList')
                  ? form.getFieldValue('isBasedOnPreviousList')
                  : initialValue.isBasedOnPreviousList

                if (displayCombinedList) {
                  return (
                    <div className='sentenceWrapper'>
                      <span className='promptText'>
                        <Trans>Please select a previous “{templateInputsType.listOfFormattedText}”: </Trans>
                      </span>
                      <Form.Item
                        className='inputModalFormItem'
                        initialValue={initialValue.previousListField}
                        name='previousListField'
                        rules={[{ required: true, message: <Trans>Please select a field</Trans> }]}
                      >
                        <Select
                          className='simple-line inTextInput'
                          collapsed
                          dropdownMatchSelectWidth={false}
                          filter={field => field && field.type === 'listOfFormattedText' && field.name !== initialValue.name && !field.isBasedOnPreviousList}
                          items={template.fields}
                          label={index => index.type && index.name}
                          noSearch
                          placeholder={t`- Select a field -`}
                        />
                      </Form.Item>
                    </div>
                  )
                }
                return null
              }}
            </Form.Item>
          </>
        )
      }
      if (this.state.internalStep === 1) {
        // if there is no existing list to extend, the combined list will be configured here
        return (
          <div className='listOfFormattedTextEditWrapper'>
            <div className='conditionalTextEditing'>
              <div className='conditionalTextEntriesEditing'>
                <div className='sentenceWrapper'>
                  <p className='promptText'>
                    <Trans>Provide the text that would be used for each item.</Trans>
                  </p>
                </div>
                <div className='conditionalTextEntriesListWrapper'>
                  <Form.Item
                    initialValue={initialValue.formattedText || ''}
                    name='formattedText'
                    rules={[{ required: true }, {
                      validator: (_, value) => {
                        if (value) {
                          const checkHasEntityNode = node => (
                            node.type === TemplateEntityType
                            || (Array.isArray(node.children) && node.children.some(checkHasEntityNode))
                          )

                          if (!value.some(checkHasEntityNode)) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise.reject(<Trans>Please enter at least one input field.</Trans>)
                          }
                        }
                        return Promise.resolve()
                      }
                    }]}
                  >
                    <FormEditor
                      placeholder={t`Please enter the text that will be displayed for each list item that has been added by the user. You may also drag and drop input fields.`}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className='templateEntitiesSidebar' id={uniqueID}>
                <TemplateEntitiesToolboxes.Component cssSelector={`#${uniqueID}`} />
              </div>
              <TableOfContent
                next={noop}
                previous={noop}
                readOnly
                suffix='-conditionalModal'
              />
            </div>
          </div>
        )
      }
      if (this.state.internalStep === 2) {
        const items = {}
        parseTemplateEntitiesInHtml(initialValue.formattedText, (type, key) => {
          if (type === 'inputField') {
            items[key] = getElem(key, template.fields).name
          }
        })

        return (
          <div className='paragraphWithInputs'>
            <span><Trans>Please select a placeholder for each list item:</Trans></span>
            <Form.Item
              initialValue={initialValue.displayLabel}
              name='displayLabel'
              rules={[{ required: true }]}
            >
              <Select
                className='simple-line inTextInput'
                items={items}
                placeholder={t`- Display label -`}
              />
            </Form.Item>
          </div>
        )
      }
    }
    //  Product & service field
    if (initialValue.type === 'product') {
      return <ProductFieldConfigurationCurrentStep configStep={this.state.internalStep} initialValues={initialValue} />
    }
    return null
  }

  // ==================================================================================================

  renderExplanationStep = () => {
    const { template } = this.context
    const initialValue = { ...(this.props.initialValue || {}), ...this.state.values }

    return <ExplanationsInput initialValue={initialValue} template={template} />
  }

  // ==================================================================================================

  renderCurrentStep = () => {
    if (this.state.step === 0) {
      return this.renderFirstStep()
    }
    if (this.state.step === 1) {
      return this.renderConfigurationStep()
    }
    if (this.state.step === 2) {
      return this.renderExplanationStep()
    }
    return null
  }

  // ==================================================================================================

  previous = () => {
    this.setState(({ step, internalStep, values }) => {
      if (step === 2) {
        if (['list', 'listOfFormattedText', 'number', 'product'].includes(values.type)) {
          return { step: 1, internalStep: 2 } // Need configuration step
        }
      }
      if (step === 1) {
        if (['listOfFormattedText', 'product'].includes(values.type)) {
          if (internalStep > 0) {
            return { internalStep: internalStep - 1 } // Need more configuration step
          }
        }
      }

      return { step: 0 }
    })
  }

  // ==================================================================================================

  next = newValues => {
    this.setState(({ step, internalStep, values }) => {
      const newStateValues = { ...values, ...newValues }
      //  Initial step (type, name, question) definition
      if (step === 0) {
        if (['list', 'listOfFormattedText', 'number', 'product'].includes(newStateValues.type)) {
          return { step: 1, internalStep: 0, values: newStateValues } // Need configuration step
        }
        return { step: 2, values: newStateValues } // Direct go to explanations
      }
      //  Configuration step for certain types
      if (step === 1) {
        if (['listOfFormattedText', 'product'].includes(values.type)) {
          if (internalStep < 2) {
            return { internalStep: internalStep + 1, values: newStateValues } // Need more configuration step
          }
        }
        return { step: 2, values: newStateValues }
      }
      //  Latest step just need to save data and mark as finished for exit the modal
      return { values: newStateValues, finished: true }
    }, async () => {
      if (this.state.finished) {
        this.setState({ loading: true })
        await this.props.onSubmit(this.state.values)
        this.setState({ loading: false })
      }
    })
  }

  renderSubSteps = () => {
    if (this.state.values.type === 'listOfFormattedText' && this.state.step === 1) {
      return (
        <Steps current={this.state.internalStep} direction='horizontal' size='small'>
          <Steps.Step />
          <Steps.Step />
          <Steps.Step />
        </Steps>
      )
    }
    return null
  }

  // ==================================================================================================

  render = () => {
    // if the combined list is present, and the user needs to configure the combined list
    // the columns to configure the list needs to spread over the entire screen.
    const renderConfigFullSize = this.state.values.type === 'listOfFormattedText' && this.state.internalStep === 1
    return (
      <div className='inputFieldEditor'>
        <CustomFormWrapper className='createInputFieldForm' layout='horizontal' onSubmit={this.next}>
          <Row
            className={`createInputFieldFormRow${(renderConfigFullSize) ? ' verticalMode listOfFormattedText' : ''}`}
            gutter={16}
            type='flex'
          >
            <Col className='logicColumn' span={renderConfigFullSize ? 24 : 14}>
              <div className='logigColumnInner paragraphWithInputs'>
                <Steps current={this.state.step} direction='horizontal'>
                  <Steps.Step title={<Trans>Create logic</Trans>} />
                  <Steps.Step title={<Trans>Configure</Trans>} />
                  <Steps.Step title={<Trans>Provide explanations</Trans>} />
                </Steps>
                <div className='formContent'>
                  {this.renderCurrentStep()}
                </div>
                <div className='actionButtons'>
                  {(this.state.step > 0 || (this.state.values.type === 'product' && this.state.step === 1)) ? (
                    <Button
                      className='previous' ghost icon={<LeftOutlined />} onClick={this.previous}
                      type='primary'
                    ><Trans>Previous</Trans>
                    </Button>
                  ) : <span>&nbsp;</span>}
                  {this.state.step < 2 && (
                    <Button className='next' htmlType='submit' icon={<RightOutlined />} type='primary'><Trans>Next</Trans></Button>
                  )}
                  {this.state.step === 2 && (
                    <Button
                      className='next' htmlType='submit' icon={<CheckOutlined />} loading={this.state.loading}
                      type='primary'
                    ><Trans>Done</Trans>
                    </Button>
                  )}
                </div>
              </div>
            </Col>
            {/**
             * preview for the item that has been created
             * only show a preview if it is not set to combined list
             */}
            {!renderConfigFullSize ? (
              <Col className='inputPreview' span={renderConfigFullSize ? 24 : 10}>
                <div className='inputPreviewInner'>
                  <h3><Trans>Preview for user:</Trans></h3>
                  <Form.Item shouldUpdate={true}>
                    {form_ => this.createPreview(form_)}
                  </Form.Item>
                </div>
              </Col>
            ) : null}
          </Row>
        </CustomFormWrapper>
      </div>
    )
  }
}

// eslint-disable-next-line react/display-name
export default React.forwardRef((props, ref) => (
  <TemplateContext.Consumer>
    {context => <CreateInputFieldForm ref={ref} {...props} {...context} />}
  </TemplateContext.Consumer>
))
