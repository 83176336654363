import React from 'react'
import { Node } from 'slate'

import { ConditionalText, InputField, Template } from '@top-legal/datastore'

export interface EntityGetters {
  getField: (fieldKey: string, version?: number) => InputField | undefined
  getFieldValue: (fieldKey: string) => any | undefined

  getConditionalText: (condKey: string, version?: number) => ConditionalText | undefined
  getConditionalTextValue: (condKey: string) => any | undefined

  getSectionRef: (sectionKey: string) => any | undefined
}

export type EntityType = 'inputField' | 'conditionalText' | 'sectionReference'

export interface ContractEditorContextProps {
  userID: string
  contractLang: string

  obfuscationMapping?: Template['obfuscationMapping']

  redliningEnabled?: boolean
  debounceSavingDelay?: number,

  //  We are using a hooks because getting entity & values can be scoped
  useEntityGetters: () => EntityGetters,
  //  This is needed for scoped fields response in case of the special field listOfFormattedText
  ProvideFieldsResponseScope: React.FC<{ scope: any }>

  //  Use a hook to be able to get a context for providing this handler
  useOnTemplateEntityClick: () => ((entityType: EntityType, key: string) => void)
  onEntityAdded?: (node: Node) => void
  onEntityRemoved?: (node: Node) => void

  currentField?: any
}

//  Make the default value undefined then it is easy to catch the issue in development if no context is provided
const ContractEditorContext = React.createContext<ContractEditorContextProps>(undefined as any)


export const ContractEditorContextProvider: React.FC<ContractEditorContextProps> = ({ children, ...context }) => (
  <ContractEditorContext.Provider value={context}>
    {children}
  </ContractEditorContext.Provider>
)

export default ContractEditorContext
