import * as Sentry from '@sentry/browser'
import React from 'react'
import { WarningTwoTone } from '@ant-design/icons'
import { Button, Col, Layout, Row } from 'antd'
import { Trans } from '@lingui/macro'
import ComponentWrapperFactory from '../SharedComponents/ComponentWrapperFactory'

// ==================================================================================================

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { eventId: null }
    //  Handle goBack to remove the eventID
    this.props.history.listen(() => this.setState({ eventId: null, error: null, errorInfo: null }))
  }

  // ==================================================================================================
  componentDidCatch (error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId, error, errorInfo: { ...errorInfo, componentStack: errorInfo.componentStack.split('\n') } })
    })
  }

  // ==================================================================================================

  render () {
    if (this.state.eventId) {
      const MakeGlobalStyle = this.props.globalStyle
      //  Render error UI
      return (
        <MakeGlobalStyle lang={this.props.match.params.lang}>
          <Layout.Content className='errorPage'>
            <Row align='top' justify='center' type='flex'>
              <Col span={23}>
                <div className='pageWrapper'>
                  <h1><Trans>Oops - we encountered a complex technical error</Trans></h1>
                  <h2>
                    <Trans>Unavailable for <s>legal</s> <u>technical</u> reasons</Trans>
                  </h2>
                  <p>
                    <Trans>
                      You have reached the end of the application. If you are not already feed up by the technology or
                      the application, we would appreciate your feedback.
                    </Trans>
                  </p>
                  <WarningTwoTone twoToneColor='#E94A4C' />
                  {
                    process.env.NODE_ENV === 'development' && (
                      <pre style={{ fontSize: '0.5rem' }}>
                        {JSON.stringify(this.state, null, 2)}
                      </pre>
                    )
                  }
                  <Row gutter={16} type='flex'>
                    <Col>
                      <Button
                        onClick={() => {
                          Sentry.showReportDialog({
                            eventId: this.state.eventId,
                            lang: this.props.match.params.lang || null
                          })
                        }}
                        size='large'
                        type='primary'
                      >
                        <Trans>Report feedback</Trans>
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() => {
                          //  Reset error and go back
                          this.setState({ eventId: null })
                          this.props.history.goBack()
                        }}
                        size='large'
                        type='default'
                      >
                        <Trans>Go back</Trans>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Layout.Content>
        </MakeGlobalStyle>
      )
    }
    //  No error render the children
    return this.props.children
  }
}

export default ComponentWrapperFactory(ErrorBoundary)
  .withRouter()
  .build()
