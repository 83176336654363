import React, { useContext } from 'react'
import { Trans } from '@lingui/macro'
import { TeamOutlined } from '@ant-design/icons'

import InstanceUsersContext from './Context/InstanceUsersContext'

//  Components
import InstanceUsersContextProvider from './Context/InstanceUsersContextProvider'
import RenderTemplateAvatars from './TobBarAvatars/RenderTemplateAvatars'
import InviteInternalMembers from './InviteActions/InviteInternalMembers'
import TemplateRoleCard from './UserCards/TemplateRoleCard'

//  Styles
import './UserManagementToolboxStyles.scss'


const UserManagementToolbox = () => {
  const { internRoles } = useContext(InstanceUsersContext)

  return (
    <>
      <RenderTemplateAvatars />
      <InviteInternalMembers />
      <div className='userCards'>
        {internRoles.map(userRole => (
          <TemplateRoleCard key={userRole.userID} userRole={userRole} />
        ))}
      </div>
    </>
  )
}


/** **********************************************************************
 *                  Export a component factory
 ********************************************************************** */
export default (instanceID: string) => ({
  key: 'users',
  className: 'userManagementToolbox',
  text: <Trans>User rights</Trans>,
  icon: <TeamOutlined />,
  // eslint-disable-next-line react/display-name
  Component: props => (
    <InstanceUsersContextProvider instanceID={instanceID} instanceType='template'>
      <UserManagementToolbox {...props} />
    </InstanceUsersContextProvider>
  )
})
