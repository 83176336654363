import React from 'react'
import * as Sentry from '@sentry/browser'
import { WarningTwoTone } from '@ant-design/icons'
import { Button, Col, Layout, Row } from 'antd'
// styles
import './Error.scss'
import { Trans } from '@lingui/macro'
import ComponentWrapperFactory from '../SharedComponents/ComponentWrapperFactory'

// ==================================================================================================

const ErrorPage = ({ description, children, noButton, match, history }) => (
  <Layout.Content className='errorPage'>
    <Row align='top' justify='center' type='flex'>
      <Col span={23}>
        <div className='pageWrapper'>
          <h1><Trans>Oops - the page does not exist</Trans></h1>
          <h2>
            <Trans>Unavailable for <s>legal</s> <u>technical</u> reasons</Trans>
          </h2>
          <p>
            {description || (
              <Trans>
                You have reached the end of the application. If you are not already feed up by the technology or
                the application, we would appreciate your feedback.
              </Trans>
            )}
          </p>
          <WarningTwoTone twoToneColor='#E94A4C' />
          {!noButton && (
            <Row gutter={16} type='flex'>
              <Col>
                <Button
                  onClick={() => {
                    Sentry.withScope(scope => {
                      scope.setTag('404')
                      scope.setLevel('warning')

                      Sentry.captureException(new Error('404 error'))

                      Sentry.showReportDialog({ lang: match.params.lang || null })
                    })
                  }}
                  size='large'
                  type='primary'
                >
                  <Trans>Report feedback</Trans>
                </Button>
              </Col>
              <Col>
                <Button onClick={() => history.goBack()} size='large' type='default'>
                  <Trans>Go back</Trans>
                </Button>
              </Col>
            </Row>
          )}
          {children}
        </div>
      </Col>
    </Row>
  </Layout.Content>
)

export const ErrorPageNoRouter = ErrorPage

export default ComponentWrapperFactory(ErrorPage)
  .withRouter()
  .build()
