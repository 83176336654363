import React, { useCallback, useContext, useMemo } from 'react'
import { Trans } from '@lingui/macro'

//  Context
import { StringParam, useQueryParam } from 'use-query-params'
import { ContractContext, TemplateContext } from './Contexts'
import { ExternalContext } from '../../ExternalViews/ExternalContext'

//  Components
import ContractAdditionalDocuments from './ContractAdditionalDocuments'
import LoadingFeedback from '../../Alert/LoadingFeedback'
import ContractSummary from './ContractSummary'
import MultiFileUploader from '../../SharedComponents/FormComponents/MultiFileUploader'
import ContractEditorContent from './editorRefactoredPart/ContractEditorContent'

//  Styles
import './ContractEditorComponentStyles.scss'
import { IdPrefixContext } from '../ContractDiplay/ContractLayout'


/** ************************************************************
 *                 The contract editor
 ************************************************************* */
const ContractEditorComponent: React.FC<{ noUpload?: boolean }> = ({ noUpload, children }) => {
  const idPrefix = useContext<string>(IdPrefixContext)
  const { tokenData } = useContext(ExternalContext)
  const { readOnly, editLoading: templateLoading, template } = useContext<any>(TemplateContext)
  const { editLoading: contractLoading, contract, updateContract } = useContext<any>(ContractContext)

  const [caOnly] = useQueryParam('caOnly', StringParam)
  const showAdditionalFiles = !caOnly && !noUpload && contract && (!contract.contractID || contract.contractID !== '__preview__')
  const showSummary = contract && contract.contractID && contract.contractStatus !== 'filling'
  const canUpload = showAdditionalFiles && (!tokenData || !tokenData.token) && !['frozen', 'signed'].includes(contract.contractStatus)
    && !window.location.href.includes('/publish')
  const saveFileKeys = useCallback((propertyKey: string, files: any[]) => {
    updateContract({ [propertyKey]: [...(contract[propertyKey] || []), ...files] }, !!contract.contractID)
  }, [contract, updateContract])

  const before = useMemo(() => (
    <>
      {/* Contract field responses summary */}
      {showSummary && <ContractSummary />}

      {/* Loading feedback when saving contract or template */}
      <div className='loadingFeedbackWrapper'>
        <LoadingFeedback loading={templateLoading || contractLoading} />
      </div>

      {/* Cover uploader & display */}
      {showAdditionalFiles && <ContractAdditionalDocuments propertyKey='covers' />}
      {canUpload && (
        <div id={`${idPrefix}covers`}>
          <MultiFileUploader
            accept='.pdf'
            multiple
            onFinished={keys => saveFileKeys('covers', keys)}
            title={<Trans>Add Cover</Trans>}
            useFeedbackModal
            withNames
            withUrls
          />
        </div>
      )}
    </>
  ), [canUpload, contractLoading, idPrefix, saveFileKeys, showAdditionalFiles, showSummary, templateLoading])

  const after = useMemo(() => (
    <>
      {/* Additional pdfs uploader & display */}
      {canUpload && (
        <div id={`${idPrefix}additionalDocuments`}>
          <MultiFileUploader
            accept='.pdf'
            multiple
            onFinished={keys => saveFileKeys('additionalDocuments', keys)}
            title={<Trans>Add Attachments</Trans>}
            useFeedbackModal
            withNames
            withUrls
          />
        </div>
      )}
      {showAdditionalFiles && <ContractAdditionalDocuments propertyKey='additionalDocuments' />}
      {/* In caOnly we want to remove the section actions that float on the right */}
      {caOnly && <style>.sectionFloatingButtons {'{'} display: none !important; {'}'}</style>}
    </>
  ), [caOnly, canUpload, idPrefix, saveFileKeys, showAdditionalFiles])

  return (
    //  Force rerender when templateID is changing
    <div key={template.templateID} className={`contractEditorComponent ${readOnly ? 'readOnly' : ''}`}>
      {children
        ? <>{before}{children}{after}</>
        : <ContractEditorContent after={after} before={before} />}
    </div>
  )
}

export default ContractEditorComponent
