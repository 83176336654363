import React, { FC } from 'react'
import { create } from 'zustand'
import { Skeleton } from 'antd'

import './ClausesStyles.scss'

export interface Clause {
  clauseID: string
  title: string
  content: string
}

export interface ClauseStore {
  clauses: Record<string, Clause | undefined>;
  loading: boolean;
}

export const initClauseStore = (): ClauseStore => ({ clauses: {}, loading: true })
export const useClausesStore = create<ClauseStore>(initClauseStore)

export const ClauseRendering: FC<{ clauseID: string; noOverlay?: boolean }> = ({ clauseID, noOverlay }) => {
  //  Our section might be using a clause
  const { clause, loading } = useClausesStore(state => ({ clause: state.clauses[clauseID], loading: state.loading }))

  if (loading && !clause) {
    return (
      <div className='renderOnlyEditor'>
        <Skeleton active paragraph={{ rows: 3 }} title={false} />
      </div>
    )
  }

  return (
    <div className='renderOnlyEditor clauseRendering'>
      {(clause?.content ?? '\n').split('\n').map((str, idx) => <p key={`__text__${idx}${str}`}>{str}</p>)}
      {!noOverlay && (
        <div className='clauseOverlay'>
          <h3>Clauses can only be edited in the clause library.</h3>
        </div>
      )}
    </div>
  )
}
