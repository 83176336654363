import { BaseEditor, BaseElement, BaseText, Editor, Node, Transforms } from 'slate'

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & { [key: string]: any }
    Element: BaseElement & { [key: string]: any }
    Text: BaseText & { [key: string]: any }
  }
}

export const ParagraphType = 'p'
export const LinkType = 'a'

export const TemplateEntityType = 'TemplateEntity'
export const MarkerTypeInline = 's'
export const MarkerTypeBlock = 'd'
export const SectionRefType = 'r'


const _getElem = (path: string[], parent: any) => {
  if (path.length && parent) {
    const index = path.shift() as string
    return _getElem(path, (
      parent.children
      || parent.subSections
      || parent.items
      || parent
    )[index])
  }

  return parent
}
export const getElem = (path: string | string[] | null | undefined, parent: any) => {
  if (typeof path === 'string') {
    return _getElem(path.split('.'), parent)
  }
  if (Array.isArray(path)) {
    return _getElem([...path], parent)
  }
  return null
}


const inlineFieldTypes = ['yesNo', 'oneLineText', 'number', 'amount', 'phone', 'date', 'time']

export const isInlineField = (field: any) => {
  if (field) {
    //  If it is a list only single choices could be inlines
    if (field.type === 'list') {
      return !field.isMultipleChoices
    }
    //  If the slateType has been defined return it
    if (typeof field.slateType === 'string') {
      return field.slateType === 'inline'
    }
    //  Last case is the types that are inlines (all the rest is blocks)
    return inlineFieldTypes.includes(field.type)
  }
  return false
}

export const isInlineConditional = (cond: any) => cond && cond.slateType === 'inline'

export const isSimpleInlineNode = (node: any) => node && (
  [LinkType, SectionRefType, MarkerTypeInline].includes(node.type)
  || typeof node.text === 'string'
)

export const isInlineNodeFactory = (getField: (key: string) => any, getCond: (key: string) => any) => (node: any): boolean => {
  if (node.type === TemplateEntityType) {
    if (node.entityType === 'sectionReference') { return true }
    if (node.entityType === 'inputField') {
      return isInlineField(getField(node.key))
    }
    if (node.entityType === 'conditionalText') {
      return isInlineConditional(getCond(node.key))
    }
    return false
  }
  return isSimpleInlineNode(node)
}

export const isVoidNode = (node: any): boolean => node && [TemplateEntityType, SectionRefType].includes(node.type)

//  Important to initialize with an empty paragraph
export const DefaultSlateContent: Node[] = [{
  type: ParagraphType,
  children: [{ text: '' }]
}]

export const isEmpty = (children: any): boolean => Array.isArray(children) && (
  children.length === 0 || (
    children.length === 1 && (
      children[0].text === '' || (children[0].type === ParagraphType && isEmpty(children[0].children))
    )
  )
)

export const editorAddDefaultContent = <T extends Editor>(editor: T) => {
  Transforms.insertNodes(editor, DefaultSlateContent)
  const point = { path: [0, 0], offset: 0 }
  Transforms.setSelection(editor, { anchor: point, focus: point })
}

export const createDnDSlateContent = (dataTransfer: Pick<DataTransfer, 'getData' | 'setData'>, nodes: Node[]): void => {
  const string = JSON.stringify(nodes)
  const encoded = window.btoa(encodeURIComponent(string))

  if (process.env.NODE_ENV === 'development') {
    console.info('[Editor] createDnDSlateContent - ', nodes, encoded)
  }

  dataTransfer.setData('application/x-slate-fragment', encoded)
}

export const createTemplateEntityDnD = (
  dataTransfer: Pick<DataTransfer, 'getData' | 'setData'>,
  entityType: 'inputField' | 'conditionalText' | 'sectionReference',
  key: string,
  version?: string | number
): void => createDnDSlateContent(dataTransfer, [{
  type: TemplateEntityType,
  entityType,
  key,
  version: version == null ? '1' : `${version}`,
  children: [{ text: '' }]
} as any])
