import React, { useCallback, useMemo } from 'react'
import { Collection, Model, TypeConfiguration } from '../Types'
import DatabaseContext from '../DatabaseContext'
import { useList } from '../Helpers'

const emptyArray = []

export interface ConditionalText extends Model {
  conditionalTextID: string
  name: string
  version?: number
  slateType: 'inline' | 'block'
  field: string
  fieldVersion?: number
  values: any

  //  Date field based cond
  isRelativeToContractFillingDate?: boolean
}

export interface ConditionalTextDataset { 'conditional_texts': ConditionalText[] }

const config: TypeConfiguration<ConditionalText> = {
  key: 'conditional_texts',
  schema: {
    version: 0,
    primaryKey: 'conditionalTextID',
    type: 'object',
    properties: {
      conditionalTextID: {
        type: 'string'
      },
      field: {
        type: 'string'
      },
      fieldVersion: {
        type: 'number'
      },
      isRelativeToContractFillingDate: {
        type: 'boolean'
      },
      name: {
        type: 'string'
      },
      slateType: {
        enum: [
          'inline',
          'block'
        ],
        type: 'string'
      },
      values: {},
      version: {
        type: 'number'
      }
    },
    required: [
      'conditionalTextID',
      'name',
      'slateType',
      'field',
      'values'
    ]
  }
}

export const useConditionalsCollection = (): Collection<ConditionalText> => React.useContext(DatabaseContext)[config.key] as Collection<ConditionalText>

export type CondsMapping = { [condID: string]: { [version: number]: ConditionalText } }
export const useCondsMapping = (): [CondsMapping | undefined, boolean] => {
  const condsCollection = useConditionalsCollection()
  return useList<ConditionalText, any, CondsMapping>(
    useMemo(() => condsCollection.find(), [condsCollection]),
    useCallback(conds => {
      const map: any = {}
      conds.forEach(cond => {
        if (!map[cond.conditionalTextID]) { map[cond.conditionalTextID] = {} }
        map[cond.conditionalTextID][cond.version || 1] = cond.toJSON()
      })
      return map
    }, [])
  ) || emptyArray
}

export const condsGQLQUery = (lastSyncDate: string, nextToken: string): string => `{
  Get {
    conditionalTexts(lastUpdatedAt: "${lastSyncDate}", nextToken: "${nextToken}") {
      items {
        conditionalTextID
        name
        version
        slateType
        field
        fieldVersion
        values
      
        # Date field based cond
        isRelativeToContractFillingDate
      }
      nextToken
    }
  }
}`

export default config
