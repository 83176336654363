import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CookieStorage
} from 'amazon-cognito-identity-js'

// https://github.com/aws-amplify/amplify-js/tree/main/packages/amazon-cognito-identity-js

export interface CognitoClient {
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  // register: (username: string, password: string, extraAttributes?: Record<string, string>) => Promise<void>;
  getAccessToken: () => Promise<string>;
}

export interface CognitoClientProps {
  userPoolID: string;
  clientID: string;
}

export class NotAuthenticatedError extends Error {
  constructor () {
    super('User not authenticated')
  }
}

let cookieStorage: CookieStorage
if (process.env.NODE_ENV === 'production') {
  cookieStorage = new CookieStorage({
    domain: process.env.REACT_APP_ROOT_DOMAIN || 'top.legal',
    sameSite: 'strict',
    secure: true
  })
} else {
  cookieStorage = new CookieStorage({
    domain: document.location.hostname,
    sameSite: 'lax',
    secure: false
  })
}

export const getStorage = () => cookieStorage

/** *************************************************************************
 *                 Cognito client
 ************************************************************************** */
export const getCognitoClient = ({ userPoolID, clientID }: CognitoClientProps): CognitoClient => {
  const userPool = new CognitoUserPool({
    UserPoolId: userPoolID,
    ClientId: clientID,
    Storage: cookieStorage
  })
  const cognitoUser = userPool.getCurrentUser()
    || new CognitoUser({
      Pool: userPool,
      Username: '',
      Storage: cookieStorage
    })
  return {
    login: (Username, Password) => new Promise((resolve, reject) => {
      (cognitoUser as any).username = Username
      cognitoUser.authenticateUser(
        new AuthenticationDetails({
          Username,
          Password
        }),
        {
          onSuccess: () => resolve(),
          onFailure: reject
        }
      )
    }),
    logout: () => new Promise(resolve => {
      cognitoUser.signOut(resolve)
    }),
    getAccessToken: () => new Promise((resolve, reject) => {
      cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
        if (err) {
          if (err.message.includes('authenticate')) {
            return reject(new NotAuthenticatedError())
          }
          return reject(err)
        }
        if (!session) {
          return reject(new NotAuthenticatedError())
        }
        return resolve(session.getAccessToken().getJwtToken())
      })
    })
  }
}
