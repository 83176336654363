import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Trans } from '@lingui/macro'
import { Button, Modal } from 'antd'

import { Contract } from '@top-legal/datastore'
import { CombinedListItem, RenderOnlyEditor } from '@top-legal/editor'
import { ConfirmButton } from '@top-legal/form-components'

import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import ContractAssistantContext, { ContractAssistantContextProps } from '../ContractAssistantContext'
import { ContractAssistantInternal } from '../ContractAssistant'

import { AssistantInputProps } from './Types'
import InputQuestion from './inputElements/InputQuestion'
import FieldExplanation from './inputElements/FieldExplanation'
import useFieldSaving from './inputElements/useFieldSaving'
import FieldError from './FieldError'
import getFieldValue from '../utils/getFieldValue'

import './CombinedListFieldInputStyles.scss'


interface CombinedListEntryFillingProps extends AssistantInputProps {
  initialValue: Record<string, any>
  save: (data: Record<string, any>) => void
}

const CombinedListEntryFilling: React.FC<CombinedListEntryFillingProps> = ({ field, initialValue, save }) => {
  const { template, fieldsMap, getCombinedListSteps, InputParty } = useContext(ContractAssistantContext)
  const [entryData, setEntryData] = useState<Record<string, any>>(initialValue)

  const updateFiledResponse: ContractAssistantContextProps['updateFiledResponse'] = useCallback(async update => {
    setEntryData(old => ({ ...old, ...update }))
  }, [])

  //  We create a new context for the embed assistant for the combined list
  const context = useMemo<ContractAssistantContextProps>(() => ({
    template,
    contract: { fieldsResponse: entryData } as Contract,
    fieldsMap,
    getCombinedListSteps,
    InputParty,
    updateFiledResponse
  }), [InputParty, entryData, fieldsMap, getCombinedListSteps, template, updateFiledResponse])

  const steps = useMemo(() => [
    getCombinedListSteps(field.inputFieldID, entryData)
  ], [entryData, field.inputFieldID, getCombinedListSteps])

  const [stats, setStats] = useState<any>()

  return (
    <ContractAssistantContext.Provider value={context}>
      <div className='combinedListFilling'>
        <div className='combinedListContent'>
          <CombinedListItem field={field} values={entryData} />
        </div>
        <div className='combinedListAssistant'>
          <ContractAssistantInternal {...context} onStats={setStats} steps={steps} />
          <Button
            block
            disabled={!stats || stats.done !== stats.total}
            onClick={() => save(entryData)}
            type='primary'
          >
            <Trans>Complete</Trans>
          </Button>
        </div>
      </div>
    </ContractAssistantContext.Provider>
  )
}


type Responses = Record<string, any>[]
const CombinedListEntriesInput: React.FC<AssistantInputProps> = props => {
  const { fieldsMap } = useContext(ContractAssistantContext)
  const { value, onChange } = useFieldSaving<Responses>(props)
  const [editIndex, setEditIndex] = useState(-1)

  //  React is complaining if no key is added to the child but I cannot have a unique key and index will cause issue
  const key = Date.now()

  return (
    <>
      <div className='combinedListFieldInput'>
        <div className='combinedListFieldItems'>
          {Array.isArray(value) && value.map((data, index) => {
            let [displayValue = '-'] = getFieldValue(fieldsMap, props.field.displayLabel as string, data, true) || []

            //  Safeguard if the value is an object it cannot be rendered as is
            if (Array.isArray(displayValue)) {
              displayValue = <RenderOnlyEditor nodes={displayValue} />
            } else if (Object(displayValue) === displayValue) {
              displayValue = '-'
            }

            return (
              <div key={key + index} className='combinedListFieldItem'>
                <p>{displayValue}</p>
                <Button.Group size='small'>
                  <Button
                    ghost
                    icon={<EditOutlined />}
                    onClick={() => setEditIndex(index)}
                    size='small'
                  />
                  {!props.field.previousListField && (
                    <ConfirmButton
                      danger
                      ghost
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        const arr = [...value]
                        arr.splice(index, 1)
                        onChange(arr)
                      }}
                      size='small'
                    />
                  )}
                </Button.Group>
              </div>
            )
          })}
        </div>
        {!props.field.previousListField && (
          <Button
            block
            ghost
            icon={<PlusOutlined />}
            onClick={() => setEditIndex(Array.isArray(value) ? value.length : 0)}
            size='small'
            type='primary'
          >
            <Trans>Add a new entry</Trans>
          </Button>
        )}
      </div>
      <Modal
        className='mediumModal combinedListFillingModal'
        destroyOnClose
        footer={null}
        onCancel={() => setEditIndex(-1)}
        title={<h3><Trans>Edit item for "{props.field.name}"</Trans></h3>}
        visible={editIndex >= 0}
      >
        <CombinedListEntryFilling
          {...props}
          initialValue={value?.[editIndex] || {}}
          save={data => {
            const arr = [...(value || [])]
            arr[editIndex] = data
            onChange(arr)
            setEditIndex(-1)
          }}
        />
      </Modal>
    </>
  )
}


const CombinedListFieldInput: React.FC<AssistantInputProps> = props => {
  const { formattedText, displayLabel } = props.field
  if (!Array.isArray(formattedText) || typeof displayLabel !== 'string' || !displayLabel) {
    if (process.env.NODE_ENV !== 'production') {
      console.error('Invalid Combined List', props.field)
      return <FieldError>Invalid Combined List [{props.fieldKey}]</FieldError>
    }

    return null
  }

  return (
    <>
      <InputQuestion field={props.field} />
      <CombinedListEntriesInput {...props} />
      <FieldExplanation field={props.field} />
    </>
  )
}

export default CombinedListFieldInput
