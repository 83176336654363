import React, { useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { Editor as SlateEditor, Node, Operation, Path, Transforms } from 'slate'
import { PlateEditor, TRenderLeafProps } from '@udecode/plate-core'
import { Button, Popover } from 'antd'
import { SyncOutlined } from '@ant-design/icons'

import { DeltaLeaf, DeltaNodeElement } from '../yjsEditor/YjsDelta'
import { StyleInjector } from '../Helpers'
import RenderOnlyEditor from '../RenderOnlyEditor'


/** ******************************************************************
 *            Revert a redline component
 ****************************************************************** */
const Revert: React.FC<{ operations: Operation[], editor: PlateEditor }> = ({
  editor,
  operations,
  children
}) => (
  <Popover
    content={(
      <Button
        contentEditable='false'
        ghost
        icon={<SyncOutlined />}
        onClick={evt => {
          evt.preventDefault()
          evt.stopPropagation()
          SlateEditor.withoutNormalizing(editor, () => {
            operations.forEach(ope => Transforms.transform(editor, ope))
          })
        }}
        size='small'
        type='default'
      >
        <Trans>Revert</Trans>
      </Button>
    )}
    placement='top'
    trigger={['click']}
  >
    {children}
  </Popover>
)


/** ******************************************************************
 *            Delta deletions
 ****************************************************************** */
const DeltaDeletions: React.FC<{
  nodes: Node[]
  className?: string
  editorRef: React.MutableRefObject<PlateEditor | undefined>
  path: Path
}> = ({ nodes, className = '', editorRef, path }) => {
  const isInline = useMemo(() => !nodes.some(node => !editorRef.current?.isInline(node as any)), [editorRef, nodes])

  // const operations: Operation[] = useMemo(() => nodes.map(node => ({ type: 'insert_node', path, node })), [nodes, path])

  return (
    // <Revert editor={editor} operations={operations}>
    <div className={`redLiningRemoveNode ${isInline ? 'inline' : ''} ${className}`} contentEditable='false'>
      <RenderOnlyEditor nodes={nodes} />
    </div>
    // </Revert>
  )
}


/** ******************************************************************
 *            Delta leaf component
 ****************************************************************** */
const DeltaLeafComponent: React.FC<{ delta: DeltaLeaf, editorRef: React.MutableRefObject<PlateEditor | undefined> }> = ({
  delta: {
    path,
    insertNode,
    preDeletions,
    postDeletions,
    insertText,
    removeText
  },
  editorRef,
  children
}) => {
  let done = false
  const child = () => {
    if (done) {
      return null
    }
    done = true
    return children
  }

  return (
    <>
      {/* Render nodes that has been delete before that leaf */}
      {useMemo(
        () => preDeletions && <DeltaDeletions editorRef={editorRef} nodes={preDeletions} path={path} />,
        [editorRef, path, preDeletions]
      )}

      {/* Render the insertion */}
      {insertNode && <span className='redLiningInsert'>{child()}</span>}

      {/* Render text added */}
      {insertText && (
        // <Revert editor={editor} operations={revertTextInsertionOperation}>
        <span className='redLiningInsert'>{child()}</span>
        // </Revert>
      )}

      {/* Render children if not done yet */}
      {child()}

      {/* Render text deletion */}
      {removeText && (
        // <Revert editor={editor} operations={revertTextDeletionOperation}>
        <span className='redLiningRemove' contentEditable='false'>
          <span>{removeText.text}</span>
        </span>
        // </Revert>
      )}

      {/* Render nodes that has been delete after that leaf */}
      {useMemo(
        () => postDeletions && <DeltaDeletions editorRef={editorRef} nodes={postDeletions} path={path} />,
        [editorRef, path, postDeletions]
      )}
    </>
  )
}


export const DeltaNode: React.FC<DeltaNodeElement & { editorRef: React.MutableRefObject<PlateEditor> }> = ({
  editorRef,
  path,
  preDeletions,
  postDeletions,
  newNode,
  children
}) => (
  <>
    {/* Render nodes that has been delete before that node */}
    {useMemo(
      () => preDeletions && <DeltaDeletions editorRef={editorRef} nodes={preDeletions} path={path} />,
      [editorRef, path, preDeletions]
    )}

    {newNode ? <StyleInjector className='redLiningInsertNode'>{children}</StyleInjector> : children}

    {/* Render nodes that has been delete after that node */}
    {useMemo(
      () => postDeletions && <DeltaDeletions editorRef={editorRef} nodes={postDeletions} path={path} />,
      [editorRef, path, postDeletions]
    )}
  </>
)


//  TODO: Get the real editor when implementing revert
const DeltaComponent: React.FC<Omit<TRenderLeafProps, 'children'> & { editorRef: React.MutableRefObject<PlateEditor | undefined> }> = ({
  attributes, leaf, editorRef, children
}) => {
  if (leaf.client != null) {
    return (
      <span {...attributes}>
        <DeltaLeafComponent delta={leaf as any} editorRef={editorRef}>
          {children}
        </DeltaLeafComponent>
      </span>
    )
  }

  return null
}

export default DeltaComponent
