import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Trans } from '@lingui/macro'
import { Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'

import { saveComment } from '../../../../../Organisations/redux/OrganisationsActions'

interface CommentPublishOverlayProps {
  comment: any
}

const stop = evt => evt.stopPropagation()

const CommentPublishOverlay: React.FC<CommentPublishOverlayProps> = ({ comment }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  if (comment.showOverlay) {
    return (
      <div className='commentPublishOverlay' onClick={stop}>
        <div className='buttonsWrapper'>
          <Button
            className='ant-btn-external'
            icon={<EditOutlined />}
            loading={loading}
            onClick={async () => {
              setLoading(true)
              try {
                await dispatch(saveComment({ ...comment, showOverlay: null, organisationID: '__EVERYONE__' }))
              } catch {}
              setLoading(false)
            }}
          >
            <Trans>Make externally visible</Trans>
          </Button>
          <Button
            className='ant-btn-hover-white'
            icon={<EditOutlined />}
            loading={loading}
            onClick={() => dispatch(saveComment({ ...comment, showOverlay: null }, false))}
          >
            <Trans>Keep internally visible</Trans>
          </Button>
        </div>
      </div>
    )
  }

  return null
}

export default CommentPublishOverlay
