import React, { useLayoutEffect } from 'react'
import { Trans } from '@lingui/macro'
import { ExportOutlined, InfoCircleOutlined } from '@ant-design/icons'

import Portal from '../SharedComponents/Portal'
import { useCreateAndShowExternalView } from '../ExternalViews/Editor/ExternalContractEditingContext'

import './ContractTopBarStyles.scss'

const ContractTopBar: React.FC = () => {
  const showExternalView = useCreateAndShowExternalView()

  useLayoutEffect(() => {
    document.body.classList.add('withContractSpaceBar')
    return () => document.body.classList.remove('withContractSpaceBar')
  }, [])

  return (
    <Portal cssSelector='.mainContainer' position='first'>
      <div className='contractTopbar'>
        <div className='contractTopbarContainer'>
          <div className='internalSpaceButton'>
            <InfoCircleOutlined className='internalSpaceIcon' />
            <span className='internalSpaceSpan'>
              <Trans>You are in Internal Space</Trans>
            </span>
          </div>
          <div
            className='noBorder dealRoomButton'
            // eslint-disable-next-line object-curly-newline
          >
            <ExportOutlined className='externalSpaceIcon' />
            <span className='switchToDealRoomCTA' onClick={showExternalView}>
              <Trans>Switch to Deal Room</Trans>
            </span>
          </div>
        </div>
      </div>
    </Portal>
  )
}

export default ContractTopBar
