import { useConditionalsCollection, useFieldsCollection, useSectionsCollection, toJsonMap } from '@top-legal/datastore'
import { useCallback, useMemo } from 'react'
import { Getters } from './utils/templateVisitors/TemplateContentVisitor'


export const buildMap = (obj: any, attr: string) => {
  const map = new Map()

  if (Object(obj) === obj) {
    Object.values<any>(obj).forEach(val => {
      if (Object(val) === val) {
        if (val[attr]) {
          map.set(val[attr], val)
        } else {
          Object.values<any>(val).some(elm => {
            if (elm?.[attr]) {
              map.set(elm[attr], elm)
              return true
            }
            return false
          })
        }
      }
    })
  }

  return map
}


//  We have to provide different getters for public playbooks since we do not own that template
export const useGetGetters = () => {
  const sectionsCollection = useSectionsCollection()
  const fieldsCollection = useFieldsCollection()
  const conditionalsCollection = useConditionalsCollection()

  const storeGetters = useMemo<Getters>(() => ({
    getSections: ids => sectionsCollection.findByIds(ids).then(toJsonMap as any),
    getFields: ids => fieldsCollection.findByIds(ids).then(toJsonMap as any),
    getConds: ids => conditionalsCollection.findByIds(ids).then(toJsonMap as any)
  }), [sectionsCollection, fieldsCollection, conditionalsCollection])

  return useCallback((template: any): Getters => {
    const { public: isPublic, community, fieldsData, condsData } = template

    if (isPublic || community) {
      const fieldsMap = buildMap(fieldsData, 'inputFieldID')
      const condsMap = buildMap(condsData, 'conditionalTextID')

      return {
        getFields: async () => fieldsMap,
        getConds: async () => condsMap,
        getSections: storeGetters.getSections
      }
    }
    return storeGetters
  }, [storeGetters])
}
