import React, { useCallback, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Tooltip } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'

import { ConfirmButton } from '../../SharedComponents/FormComponents'
import { YjsDeltaComment } from '../../Contract/ContractEditor/Sidebar/SidebarToolboxes/Commenting/Types'
import RestService from '../../../RestService'
import { STORE_INSTANCES_COMMENTS } from '../../Organisations/redux/OrganisationsActions'
import * as types from '../../Template/redux/TemplateActionsType'
import {defaultUser, UserAndCompanyDataContext} from '../../Layouts/Constants'
import { TemplateContext } from '../../Contract/ContractEditor/Contexts'
import { ExternalContext } from '../ExternalContext'

const acceptOrRejectSuggestion = (tokenData: any, suggestion: YjsDeltaComment, accept: boolean) => async (dispatch, getState) => {
  const rate = accept ? 'accepted' : 'rejected'
  if ((suggestion as any).realDate) { suggestion.date = (suggestion as any).realDate }
  const { date } = suggestion

  const section = await RestService('POST', `/token/${tokenData.token}/manageRedline`, { date, rate }, false)

  suggestion.externalRate = rate
  suggestion.externalyManagedBy = defaultUser.userID
  suggestion.externalyManagedDate = (new Date()).toISOString()

  const comments = [...getState().organisation.instanceComments[suggestion.instanceID]]
  const index = comments.findIndex(comm => comm.date === date)
  if (index >= 0) {
    comments[index] = suggestion
  }

  if (Object(section) === section && section.sectionID) {
    dispatch({
      type: types.SAVE_SECTION,
      payload: section
    })
  }

  dispatch({
    type: STORE_INSTANCES_COMMENTS,
    payload: { [suggestion.instanceID]: comments }
  })
}

const ExternalSuggestionDisplayActions: React.FC<{ suggestion: YjsDeltaComment, part: any }> = ({ part, suggestion }) => {
  const { tokenData } = useContext(ExternalContext)
  const { setSectionDeltasMapping } = useContext<any>(TemplateContext)
  const { user } = useContext<any>(UserAndCompanyDataContext)
  const dispatch = useDispatch()

  const [buttonLoading, setButtonLoading] = useState(false)
  const acceptOrReject = useCallback(async (accept: boolean) => {
    setButtonLoading(true)
    try {
      await dispatch(acceptOrRejectSuggestion(tokenData, suggestion, accept))
      setSectionDeltasMapping(old => ({ ...old, [part.sectionID]: undefined }))
    } catch (err) {
      console.error('External accept/reject redline', err)
    }
    setButtonLoading(false)
  }, [dispatch, part.sectionID, setSectionDeltasMapping, suggestion, tokenData])

  if (!tokenData || !tokenData.token || suggestion.rate || suggestion.userID === user.userID || suggestion.externalRate || suggestion.fromExternal) {
    return null
  }

  return (
    <>
      <Tooltip placement='topRight' title={<Trans>Accept deltas</Trans>}>
        <ConfirmButton
          className='noBorder'
          confirmMessage={<Trans>Are you sure you want to accept these changes?</Trans>}
          ghost
          icon={<CheckOutlined />}
          loading={buttonLoading}
          onClick={() => acceptOrReject(true)}
          size='small'
          type='primary'
        />
      </Tooltip>
      <Tooltip placement='topRight' title={<Trans>Reject deltas</Trans>}>
        <ConfirmButton
          className='noBorder'
          confirmMessage={<Trans>Are you sure you want to reject these changes?</Trans>}
          danger
          ghost
          icon={<CloseOutlined />}
          loading={buttonLoading}
          onClick={() => acceptOrReject(false)}
          size='small'
          type='primary'
        />
      </Tooltip>
    </>
  )
}

export default ExternalSuggestionDisplayActions
