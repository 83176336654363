import React, { useContext, useMemo } from 'react'

import { EnabledPlugins } from '../../core/PlatePlugins'
import EditorWrapper, { EditorWrapperProps } from '../EditorWrapper'
import EditorContractContext from './EditorContractContext'

export type EditorWithContractDataProps = Omit<EditorWrapperProps, 'features' | 'inlinesOnly' | 'noSearch'>

const EditorWithContractData: React.FC<EditorWithContractDataProps> = ({ children, ...props }) => {
  const featuresOverrides = useMemo<Partial<EnabledPlugins>>(() => ({
    history: false // History not supported with current Yjs implementation
  }), [])

  return (
    <EditorWrapper {...props} features={featuresOverrides}>
      {children}
    </EditorWrapper>
  )
}

export default EditorWithContractData
