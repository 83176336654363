import React, { useCallback, useEffect, useRef, useState } from 'react'
import { t, Trans } from '@lingui/macro'
import moment from 'moment'
import { Button, Form } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { InputMask, PhoneInput } from '../../../../SharedComponents/FormComponents'
import CustomFormWrapper from '../../../../SharedComponents/CustomFormWrapper'

//  TODO: Typescript these guys
const PhoneInput_ = PhoneInput as any
const InputMask_ = InputMask as any

interface PinCodeInputProps {
  initialSignatureType?: 'phone' | 'email'
  sendPinCode: (type: 'phone' | 'email') => Promise<any>
  validatePinCode: (code: string, token: string) => Promise<any>
  buttonText?: React.ReactNode
  phoneNumber?: string
  email?: string
  onPhoneChange?: (phoneNumber: string) => void
  onEmailChange?: (email: string) => void
}

const now = <Trans>now</Trans>
const PinCodeInput: React.FC<PinCodeInputProps> = ({
  initialSignatureType,
  sendPinCode,
  validatePinCode,
  buttonText = <Trans>Sign now</Trans>,
  onPhoneChange,
  phoneNumber = '',
  email = '',
  children
}) => {
  const signing = useRef<any>({})
  const [loading, setLoading] = useState(false)
  const [showInput, setShowInput] = useState(false)
  const [retry, setRetry] = useState(false)
  const [type, setType] = useState<'phone' | 'email'>(initialSignatureType || 'email')

  /**
   * Method for sending a pin code to a phone number
   */
  const _sendPinCode = useCallback(
    async (sendingType: 'phone' | 'email') => {
      if (signing.current.locked) {
        (window as any).notification.info({
          message: <Trans>SMS sending countdown</Trans>,
          description: <Trans>Request a new PIN code {signing.current.locked ? signing.current.countdown.fromNow() : now}</Trans>
        })
      } else {
        setLoading(true)
        setRetry(false)

        try {
          const token = await sendPinCode(sendingType)
          signing.current = {
            token,
            countdown: moment().add(20, 'seconds'),
            locked: true,
            timeout: setTimeout(() => {
              signing.current.locked = false
            }, 20 * 1000) // 20sec of countdown
          }
          ;(window as any).notification.success({
            message: <Trans>PIN sent</Trans>,
            description:
              sendingType === 'email' ? (
                <Trans>We have successfully sent the pin to your email inbox.</Trans>
              ) : (
                <Trans>We have successfully sent the pin to the phone number provided.</Trans>
              )
          })
        } catch (err) {
          console.error('Error when sending pin', err)
          throw err
        }
        setLoading(false)
      }
    },
    [sendPinCode]
  )

  /**
   * Code validation
   */
  const _validatePinCode = useCallback(
    async code => {
      setLoading(true)
      try {
        await validatePinCode(code, signing.current.token)
        setLoading(false)
      } catch (err) {
        setLoading(false)
        console.error('Error to send pin', err)
        throw err
      }
    },
    [validatePinCode]
  )

  //  Sending a PIN at mounting
  useEffect(() => {
    _sendPinCode(type)
  }, []) // eslint-disable-line

  const codeInput = (
    <CustomFormWrapper onSubmit={({ code }) => _validatePinCode(code)}>
      <Form.Item
        name='code'
        rules={[
          {
            required: true,
            validator: (_, value) => {
              if (!/^[0-9]{3}-[0-9]{3}$/.test(value)) {
                return Promise.reject(new Error(t`Please enter your PIN code`))
              }
              return Promise.resolve()
            }
          }
        ]}
      >
        <InputMask_ className='pinInput simple-line' mask='000-000' maskChar='_' placeholder='999-999' />
      </Form.Item>
      {children}
      <Button
        block data-testid='sign now' htmlType='submit' loading={loading}
        type='primary'
      >
        {buttonText}
      </Button>
    </CustomFormWrapper>
  )

  const editIcon = (
    <Button
      className='noBorder' ghost icon={<EditOutlined />} onClick={() => setShowInput(true)}
      type='primary'
    />
  )

  const phoneInput = (
    <CustomFormWrapper
      onSubmit={async ({ phoneNumber }) => {
        onPhoneChange?.(phoneNumber)
        setShowInput(false)
        signing.current = { token: signing.current.token } // Reset timeout setting
        _sendPinCode('phone')
      }}
    >
      <Form.Item initialValue={phoneNumber} name='phoneNumber'>
        <PhoneInput_ />
      </Form.Item>
      <Button block htmlType='submit' loading={loading} type='primary'>
        <Trans>Sign now</Trans>
      </Button>
    </CustomFormWrapper>
  )

  //  Email
  if (type === 'email') {
    return (
      <>
        <h4>
          <Trans>A pin was sent to email inbox: {email}</Trans>
        </h4>
        {codeInput}
        <Button
          block ghost loading={loading} onClick={() => _sendPinCode('email')}
          type='primary'
        >
          <Trans>Resend Pin</Trans>
        </Button>
      </>
    )
  }

  //  Phone number
  if (showInput) {
    return (
      <>
        <h4>
          <Trans>Please verify your mobile number</Trans>
        </h4>
        {phoneInput}
      </>
    )
  }

  if (retry) {
    return (
      <>
        <h4>
          <Trans>Signing did not work with mobile number {phoneNumber}? You can request your pin again after 20 seconds or sign via email.</Trans>
        </h4>
        <Button
          block data-testid='request pin' loading={loading} onClick={() => _sendPinCode('phone')}
          type='primary'
        >
          <Trans>Request Pin</Trans>
        </Button>
        <Button
          block
          ghost
          loading={loading}
          onClick={() => {
            setRetry(false)
            setType('email')
            signing.current = { token: signing.current.token } // Reset timeout setting
            _sendPinCode('email')
          }}
          type='primary'
        >
          <Trans>Sign via Email</Trans>
        </Button>
      </>
    )
  }

  return (
    <>
      <h4 data-testid='phone number header'>
        <Trans>A pin was sent to mobile number: {phoneNumber}</Trans> {editIcon}
      </h4>
      {codeInput}
      <Button
        block data-testid='resend pin' ghost loading={loading}
        onClick={() => setRetry(true)} type='primary'
      >
        <Trans>Resend Pin</Trans>
      </Button>
    </>
  )
}

export default PinCodeInput
