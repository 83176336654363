import React, { useContext } from 'react'
import { FileDoneOutlined, FileOutlined, FileWordOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu } from 'antd'
import { Trans } from '@lingui/macro'
import { NavLink } from 'react-router-dom'
import { useRouteMatch } from 'react-router'
import { TemplateListingContext } from './TemplateContainerWrapper'
import { DeviceContext } from '../../../GlobalContext'

const TemplateActions: React.FC = () => {
  const { url } = useRouteMatch()
  const isSmall = useContext(DeviceContext) !== 'desktop'
  const { setShowUploadModal, setShowCreateNew } = useContext(TemplateListingContext)

  /** *************************************************************************
   *                           Desktop View
   ************************************************************************** */
  return (
    <Dropdown
      overlay={(
        <Menu>
          <Menu.Item onClick={() => setShowCreateNew(true)}>
            <FileOutlined />
            <span><Trans>Start new</Trans></span>
          </Menu.Item>
          <Menu.Item onClick={() => setShowUploadModal(true)}>
            <FileWordOutlined />
            <span><Trans>Start from Word document</Trans></span>
          </Menu.Item>
          <Menu.Item>
            <NavLink to={url.replace('listing/templates', 'top-legal-templates')}>
              <FileDoneOutlined />
              <span><Trans>Start from top.legal playbook</Trans></span>
            </NavLink>
          </Menu.Item>
        </Menu>
      )}
      trigger={['click']}
    >
      {isSmall ? (
        <Button
          className='noBorder moreButton'
          ghost
          // eslint-disable-next-line no-script-url
          href='javascript:void(0)'
          icon={<MoreOutlined />}
        />
      ) : (
        <Button
          href='javascript:void(0)'
          icon={<PlusOutlined />}
          type='primary'
        >
          <Trans>Create new</Trans>
        </Button>
      )}
    </Dropdown>
  )
}

export default TemplateActions
