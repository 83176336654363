import React, { useContext } from 'react'
import { Button, Form, Input, Modal } from 'antd'
import { t, Trans } from '@lingui/macro'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { UserAndCompanyDataContext } from '../Layouts/Constants'

//  Components
import { Select } from '../SharedComponents/FormComponents/index'
import { employeesOptions, industryOptions } from '../Template/_TemplateHelperFunctions'
import CustomFormWrapper, { requiredRules } from '../SharedComponents/CustomFormWrapper'

// assets
import topLegalLogo from '../../img/t-logo.png'

//  Actions
import { saveOrganisation } from '../Organisations/redux/OrganisationsActions'
import { updateUserProfile } from '../../actions/action_user'

// styles
import './OrganisationCreateModal.scss'
import loadingModalFeedback from '../Alert/LoadingModalFeedback'


// export
export const clientRoleInCompany = {
  en: {
    ceo: 'CEO',
    inHouseCounsel: 'In-house Counsel',
    procurementManager: 'Procurement Manager',
    salesManager: 'Sales Manager',
    hrManager: 'HR Manager',
    other: 'Other'
  },
  de: {
    ceo: 'Geschäftsführer',
    inHouseCounsel: 'Unternehmensjurist',
    procurementManager: 'Einkäufer',
    salesManager: 'Vertriebsmanager',
    hrManager: 'Personalleiter',
    other: 'Weiteres'
  }
}


// ==================================================================================================
// ==================================================================================================

const OrganisationCreateModal = () => {
  const { user, company } = useContext(UserAndCompanyDataContext)
  const { lang } = useParams()
  const noOrganisation = !company || !company.organisationID || company.toComplete
  const inviteData = useSelector(state => state.organisation.inviteData)
  const dispatch = useDispatch()

  // ==================================================================================================

  const submit = async values => loadingModalFeedback({
    loadingTitle: <Trans>Saving your profile</Trans>,
    successTitle: <Trans>Profile saved</Trans>,
    errorTitle: <Trans>Failed to save your profile</Trans>,
    autoSuccessClose: 2000
  })(async () => {
    await dispatch(updateUserProfile({ roleInCompany: values.roleInCompany }))
    await dispatch(saveOrganisation({ ...(company || {}), ...values, toComplete: null }))
  })

  // ==================================================================================================

  const defaultText = window.IS_TRANSFERINITIATIVE ? <Trans>Save profile</Trans> : <Trans>Discover the top.legal software</Trans>
  const generateButtonText = () => {
    if (inviteData) {
      return {
        contract: <Trans>View your contract</Trans>,
        template: <Trans>View your playbook</Trans>
      }[inviteData.instanceType] || defaultText
    }
    return defaultText
  }

  // ==================================================================================================

  const inputs = [
    <Form.Item key='name' initialValue={company.name} name='name' rules={requiredRules}>
      <Input className='simple-line' placeholder={t`Company`} type='default' />
    </Form.Item>,
    <Form.Item key='employees' initialValue={company.employees} name='employees' rules={requiredRules}>
      <Select className='simple-line' items={employeesOptions[lang]} noSearch placeholder={t`Employees`} />
    </Form.Item>,
    <Form.Item key='industry' initialValue={company.industry} name='industry' rules={requiredRules}>
      <Select className='simple-line' items={industryOptions[lang] || industryOptions.en} noSearch placeholder={t`Industry`} />
    </Form.Item>,
    <Form.Item key='roleInCompany' name='roleInCompany' rules={requiredRules}>
      <Select className='simple-line' items={clientRoleInCompany[lang] || clientRoleInCompany.en} noSearch placeholder={t`Role`} />
    </Form.Item>
  ]


  return (
    <Modal
      className='IntroModal mediumModal'
      closable={false}
      footer={null}
      visible={noOrganisation}
    >
      <div className='IntroModalContainer'>
        <img alt='top.legal logo' className='topLegalLogo' src={topLegalLogo} />
        <h1 className='introModalHeader'>
          <Trans>Welcome {user.firstName} {user.lastName}</Trans>
        </h1>
        <p>
          <Trans>
            Please complete the following sentence so that we can customize your virtual legal department for you.
          </Trans>
        </p>
        <CustomFormWrapper className='IntroForm' layout='inline' onSubmit={submit}>
          {
            lang === 'en' ? (
              <>
                <span className='formSentence'>I work for</span>
                {inputs[0]}
                <span className='formSentence'>, a company with</span>
                {inputs[1]}
                <span className='formSentence'>employees</span>
                <br />
                <span className='formSentence'>in the</span>
                {inputs[2]}
                <span className='formSentence'>sector as</span>
                {inputs[3]}
                <span className='formSentence'>.</span>
                <br />
                <br />
              </>
            )
              : (
                <>
                  <span className='formSentence'>Ich arbeite für</span>
                  {inputs[0]}
                  <span className='formSentence'>, eine Gesellschaft mit</span>
                  {inputs[1]}
                  <span className='formSentence'>Angestellten</span>
                  <br />
                  <span className='formSentence'>in folgender Industrie</span>
                  {inputs[2]}
                  <span className='formSentence'>als</span>
                  {inputs[3]}
                  <span className='formSentence'>.</span>
                  <br />
                  <br />
                </>
              )
          }
          <div className='buttonRow'>
            <Form.Item>
              <Button className='introSubmitButton' htmlType='submit' type='default'>
                {generateButtonText()}
              </Button>
            </Form.Item>
          </div>
        </CustomFormWrapper>
      </div>
    </Modal>
  )
}

export default OrganisationCreateModal
