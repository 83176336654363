export const customClustering = contracts => {
  contracts.sort((elm1, elm2) => elm1.duration - elm2.duration)
  if (Array.isArray(contracts) && contracts.length === 0) {
    return { clusterCenteroids: [], clusterCounts: [], clusteredData: [] }
  }
  const threshold = 3 * 24 * 60 * 60 * 1000 // 3 Days Threshold
  let currentCluster = []
  const clusterCenteroids = []
  const clusterCounts = []
  const clusteredData = []
  let lastElement = contracts[0]
  let average = 0
  contracts.forEach((element, index) => {
    if (element.duration - lastElement.duration < threshold) {
      currentCluster.push(element)
      average += element.duration
      if (index === contracts.length - 1) {
        clusterCenteroids.push(average / currentCluster.length)
        clusterCounts.push(currentCluster.length)
        clusteredData.push(currentCluster)
      }
    } else {
      clusterCenteroids.push(average / currentCluster.length)
      clusterCounts.push(currentCluster.length)
      clusteredData.push(currentCluster)

      currentCluster = [element]
      average = element.duration
    }
    lastElement = element
  })
  return { clusterCenteroids, clusterCounts, clusteredData }
}

export const getdistribution = contracts => {
  const contractStatusIdx = {
    filling: 0, editing: 1, frozen: 2, signed: 3, imported: 4
  }
  const distribution = [0, 0, 0, 0, 0]
  contracts.forEach(element => {
    if (element.contractStatus) {
      const index = contractStatusIdx[element.contractStatus]
      distribution[index] += 1
    }
  })
  return { data: distribution, contractStatusIdx }
}

export const getCategories = templates => {
  const categoryDistribution = {}
  templates.forEach(element => {
    if (!categoryDistribution[element.category]) {
      categoryDistribution[element.category] = 1
    } else {
      categoryDistribution[element.category] += 1
    }
  })
  return categoryDistribution
}


export const getConversion = contracts => {
  const conversion = { signed: 0, frozen: 0 }
  contracts.forEach(element => {
    if (element.contractStatus === 'signed') {
      conversion.signed += 1
    } else if (element.contractStatus === 'frozen') {
      conversion.frozen += 1
    }
  })
  return conversion
}
