import React, { useEffect, useRef } from 'react'
import { ConsoleLogger as Logger } from '@aws-amplify/core'
//  Video lib
import VideoJs from 'video.js'
import 'webrtc-adapter/out/adapter'
import RecordRTC from 'recordrtc'
//  Register video record
import 'videojs-record'
import 'videojs-record/dist/plugins/videojs.record.ts-ebml'
//  Register wave preview for audio only
import WaveSurfer from 'wavesurfer.js'
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone'
import 'videojs-wavesurfer/dist/videojs.wavesurfer'

//  Register Webm support for safari
import './VideoJsPlugins/VideoJsOGV'
import 'videojs-record/dist/plugins/videojs.record.webm-wasm'
import 'web-streams-polyfill/dist/polyfill'
// eslint-disable-next-line
// @ts-ignore
import WebmWorkerUrl from 'file-loader!webm-wasm/dist/webm-worker.js' // eslint-disable-line
// eslint-disable-next-line
// @ts-ignore
import WebmWasmUrl from 'file-loader!webm-wasm/dist/webm-wasm.wasm' // eslint-disable-line

import './VideoJsPlugins/TsEbml'
//  Styles
import 'video.js/dist/video-js.min.css'
import 'videojs-record/dist/css/videojs.record.css'
import 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css'
import './AudioVideoPlayerRecorderStyles.scss'

//  Images
import BackgroundPoster from './img/video_poster.png'

//  Set constants
const logger = new Logger('VideoComponent')
const noop = () => null


/** ***********************************************************
 *            Browsers detection
 *********************************************************** */
// const isEdge = navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveBlob || !!navigator.msSaveOrOpenBlob)
// const isOpera = !!window.opera || navigator.userAgent.indexOf('OPR/') !== -1
// const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1 && ('netscape' in window) && / rv:/.test(navigator.userAgent)
// const isChrome = (!isOpera && !isEdge && !!navigator.webkitGetUserMedia) || isElectron() || navigator.userAgent.toLowerCase().indexOf('chrome/') !== -1
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

;(window as any).HELP_IMPROVE_VIDEOJS = false


const videoJsOptions: any = {
  techOrder: ['html5', 'VideoJsOGV'],
  controls: true,
  autoplay: false,
  fluid: true,
  loop: false,
  liveui: false,
  width: '100%',
  aspectRatio: '16:9'
}

// Wavesurfer section is only needed when recording audio-only
const wavesurfer: any = {
  backend: 'WebAudio',
  waveColor: '#ffffff',
  progressColor: '#3dbd7d',
  debug: process.env.NODE_ENV === 'development',
  cursorWidth: 1,
  msDisplayMax: 20,
  hideScrollbar: true,
  displayMilliseconds: false,
  plugins: [
    // enable microphone plugin
    MicrophonePlugin.create({
      bufferSize: 4096,
      numberOfInputChannels: 1,
      numberOfOutputChannels: 1,
      constraints: {
        video: false,
        audio: true
      }
    })
  ]
}

interface AudioVideoFile {
  url: string,
  type: string
}

interface AudioVideoPlayerRecorderProps {
  file?: AudioVideoFile,
  audioOnly?: boolean,
  recorder?: boolean,
  poster?: string,
  onPlayer?: (player: any) => void,
  onStart?: () => void,
  onFinished?: (data: File) => void
}

/** ***********************************************************
 *            Audio Video Player Recorder
 *********************************************************** */
const AudioVideoPlayerRecorder: React.FC<AudioVideoPlayerRecorderProps> = ({
  file,
  audioOnly,
  recorder,
  poster,
  onPlayer = noop,
  onStart = noop,
  onFinished = noop
}) => {
  const videoContainer = useRef<HTMLDivElement>(null)
  const preview = poster || BackgroundPoster

  //  Setup the player
  useEffect(() => {
    //  Setting content like this because player.dispose() remove also the content

    if (videoContainer.current) {
      videoContainer.current.innerHTML = `
        <div data-vjs-player>
          <${audioOnly ? 'audio' : 'video'} class="video-js top-legal-player ${recorder ? 'recorder' : 'player'} ${audioOnly ? 'audioOnly' : 'audioVideo'}" />
        </div>
      `.replace(/\n/, '').trim() // Replace unneeded text

      //  Setting logger level to all for dev
      if (process.env.NODE_ENV === 'development') {
        VideoJs.log.level('all')
      }

      //  Clone options
      const options = { ...videoJsOptions }

      //  If it is use as player and recorder is not set remove the option
      options.plugins = {}
      if (recorder) {
        if (audioOnly) {
          options.plugins.wavesurfer = { ...wavesurfer }
        }

        options.plugins.record = {
          audio: true,
          video: !audioOnly, //  Set video when it is not audio only
          debug: process.env.NODE_ENV === 'development', // Debug only for dev
          maxLength: 300,
          //  Video settings
          ...(audioOnly ? {} : {
            videoMimeType: 'video/webm;codecs=vp9',
            frameWidth: 1280,
            frameHeight: 720,
            displayMilliseconds: false,
            convertEngine: 'ts-ebml'
          }),
          //  Audio settings
          // ...((isChrome && audioOnly) ? { audioRecorderType: RecordRTC.StereoAudioRecorder } : {}),
          // audioRecorderType: RecordRTC.StereoAudioRecorder,
          audioMimeType: 'audio/ogg',
          audioChannels: 1,
          audioBufferSize: 16384,
          audioSampleRate: 44100
        }

        /** ***********************************************************
         *            Browsers workarounds
         *********************************************************** */
        // support recording in safari 11/12
        // see https://github.com/collab-project/videojs-record/issues/295
        if (isSafari) {
          if (audioOnly) {
            options.plugins.record.audioBufferSize = 4096
          } else {
            options.techOrder = ['html5'] //  Don't use ogv.js when recording
            options.plugins.record = {
              ...options.plugins.record,
              audio: false,
              convertEngine: undefined,
              videoEngine: 'webm-wasm',
              videoWorkerURL: WebmWorkerUrl,
              videoWebAssemblyURL: WebmWasmUrl,
              videoBitRate: 1200,
              videoFrameRate: 30
            }
          }
        }
      } else if (audioOnly) { // If player and only audio
        options.bigPlayButton = false
        options.plugins.wavesurfer = { ...wavesurfer, plugins: [] }
      }
      logger.debug('Options', JSON.parse(JSON.stringify(options)))

      //  Add poster
      options.poster = preview

      const player = VideoJs(videoContainer.current.querySelector(audioOnly ? 'audio' : 'video'), options, () => {
        logger.debug(`Version of video.js is ${VideoJs.VERSION}`)
        logger.debug(`Version of videojs-record is ${VideoJs.getPluginVersion('record')}`)
        logger.debug(`Version of recordrtc is ${RecordRTC.version}`)
        logger.debug(`Version of wavesurfer is ${WaveSurfer.VERSION}`)
        logger.debug(`Version of videojs-wavesurfer is ${VideoJs.getPluginVersion('wavesurfer')}`)

        player.disablePictureInPicture(true)

        //  If it is recorder list in logger all devices and if not just add some classes to have style coherence
        if (recorder) {
          player.record().enumerateDevices()
        } else if (videoContainer.current && videoContainer.current.firstElementChild) {
          videoContainer.current.firstElementChild.classList.add('vjs-record')
          const bigButton = videoContainer.current.querySelector('.vjs-big-play-button')
          bigButton && bigButton.classList.add('vjs-record', 'vjs-device-button', 'vjs-control')

          if (file && file.url && file.type) {
            player.src({ src: file.url, type: file.type })
          }
        }
      })

      //  Recorder handlers
      player.on('enumerateReady', () => {
        const { devices } = player.record()
        devices.forEach(device => {
          logger.debug(`Found device, type: ${device.kind} label: ${device.label || 'unknown'} id: ${device.deviceId}`)
        })
        player.record().getDevice()
      })
      player.on('enumerateError', () => {
        logger.debug('enumerate error:', player.enumerateErrorCode)
      })
      player.on('error', () => {
        logger.debug('player error:', player.enumerateErrorCode)
      })
      if (recorder) {
        if (audioOnly || isSafari) {
          player.on('finishRecord', () => onFinished(player.recordedData))
        } else {
          player.on('finishConvert', () => onFinished(player.convertedData))
        }
      }

      //  Bind onStart handler if provided
      if (typeof onStart === 'function') {
        player.on('startRecord', onStart)
      }

      //  Auto start record
      if (recorder) {
        player.on('deviceReady', () => player.record().start())
      }

      onPlayer(player)

      //  When destruct dispose the player
      return () => player.dispose()
    }
    return undefined
  }, [file, audioOnly, recorder, onPlayer, onStart, onFinished, preview])

  return <div ref={videoContainer} />
}

export default AudioVideoPlayerRecorder
