import React from 'react'
import { Trans } from '@lingui/macro'
import { Tag } from 'antd'
import { EnvironmentOutlined } from '@ant-design/icons'
import { UserAvatar, UserFullName } from './UserAvatarList'

import { AddressDisplay, addressToString } from '../../ContactList/ContactList'
import { UserAddedDetails } from '../UsersManagement'

import './UserInformationDisplayStyles.scss'

const UserInformationDisplay = ({ user }) => (
  <div className='userInformationDisplay'>
    <div className='avatarWrapper'>
      <UserAvatar autoSide noModal noTooltip size='10rem' user={user} />
    </div>
    <div className='informations'>
      <div className='informationGroup'>
        <h3><Trans>User details</Trans></h3>
        <table>
          <tr>
            <th><Trans>Name</Trans></th>
            <td>{user.firstName} {user.lastName}</td>
          </tr>
          <tr>
            <th><Trans>Email</Trans></th>
            <td><a href={`mailto:${user.firstName} ${user.lastName} <${user.email}>`}>{user.email}</a></td>
          </tr>
          {user.phoneNumber && (
            <tr>
              <th><Trans>Phone</Trans></th>
              <td><a href={`tel:${user.phoneNumber}`}>{user.phoneNumber}</a></td>
            </tr>
          )}
        </table>
      </div>

      {(Object(user.userAddress) === user.userAddress || user) && addressToString(user.userAddress || user) && (
        <div className='informationGroup'>
          <h3><Trans>Personal address</Trans></h3>
          <div className='addressWrapper'>
            <a href={`http://maps.google.com/maps?q=${addressToString(user.userAddress || user)}`} rel='noreferrer' target='_blank'>
              <EnvironmentOutlined />
            </a>
            <AddressDisplay address={user.userAddress || user} />
          </div>
        </div>
      )}

      {Object(user.organisationRole) === user.organisationRole && (
        <div className='informationGroup'>
          <h3><Trans>Member information</Trans></h3>
          <p className='teamDisplay'>
            <b><Trans>Team</Trans></b>
            <Tag color='blue'>
              {user.organisationRole.team ? <UserFullName userID={user.organisationRole.team} /> : <Trans>Company wide</Trans>}
            </Tag>
          </p>
          <UserAddedDetails details={user.organisationRole || user} />
        </div>
      )}

      {(user.companyName || Object(user.companyAddress) === user.companyAddress) && (
        <div className='informationGroup'>
          <h3><Trans>Company information</Trans></h3>
          <p className='teamDisplay'><b><Trans>Company name</Trans></b> <span>{user.companyName}</span></p>
          {user.companyAddress && addressToString(user.companyAddress) && (
            <div className='addressWrapper'>
              <a href={`http://maps.google.com/maps?q=${addressToString(user.companyAddress)}`} rel='noreferrer' target='_blank'>
                <EnvironmentOutlined />
              </a>
              <AddressDisplay address={user.companyAddress} />
            </div>
          )}
        </div>
      )}
    </div>
  </div>
)

export default UserInformationDisplay
