import React, {
  Dispatch, SetStateAction, useCallback, useLayoutEffect, useMemo, useRef, useState
} from 'react'
import { Button, Input } from 'antd'
import { CloseOutlined, SearchOutlined } from '@ant-design/icons'

import { Text } from 'slate'
import { getPreventDefaultHandler, getSelectionText, PlatePlugin } from '@udecode/plate-core'
import { createFindReplacePlugin, MARK_SEARCH_HIGHLIGHT } from '@udecode/plate-find-replace'
import { ToolbarButton } from '@udecode/plate-ui-toolbar'

import { useFocusedEditor } from '../Helpers'
import { Decorate } from '../EditorContext'


/** ******************************************************************
 *            Props & Defaults definitions
 ****************************************************************** */
type FireSearch = (str: string) => void
interface SearchPluginProps {
  fireSearchRef: React.MutableRefObject<FireSearch | undefined>
  search: string
}

interface SearchBarProps extends SearchPluginProps {
  setSearch: Dispatch<SetStateAction<string>>
  setShowSearchBar: Dispatch<SetStateAction<boolean>>
  showSearchBar: boolean
  search: string
}

interface UseSearchReturn {
  searchPlugin: PlatePlugin
  decorateSearch: Decorate
  searchBar: React.ReactNode
  searchToolbarButton: React.ReactNode
  searchBalloonButton: React.ReactNode
  fireSearchRef: React.RefObject<FireSearch | undefined>
}

/** ******************************************************************
 *            Get the search plugin memoized
 ****************************************************************** */
const useSearchPlugin = ({ fireSearchRef, search }: SearchPluginProps): Pick<UseSearchReturn, 'searchPlugin' | 'decorateSearch'> => {
  const searchPlugin = useMemo(() => ({
    ...createFindReplacePlugin(),
    handlers: {
      onKeyDown: editor => evt => {
        if (evt.ctrlKey && evt.key === 'f') {
          evt.preventDefault()
          evt.stopPropagation()

          const selectionText = getSelectionText(editor)
          fireSearchRef.current?.(selectionText)
        }
      }
    }
  }), [fireSearchRef])

  const decorateSearch = useCallback<UseSearchReturn['decorateSearch']>(([node, path]) => {
    const ranges: any[] = []

    if (search && Text.isText(node)) {
      const text = node.text.toLowerCase()
      const parts = text.split(search)
      let offset = 0
      parts.forEach((part, idx) => {
        if (idx !== 0) {
          ranges.push({
            anchor: { path, offset: offset - search.length },
            focus: { path, offset },
            [MARK_SEARCH_HIGHLIGHT]: true
          })
        }

        offset = offset + part.length + search.length
      })
    }

    return ranges
  }, [search])

  return { searchPlugin, decorateSearch }
}

/** ******************************************************************
 *            Search bar
 ****************************************************************** */
// eslint-disable-next-line react/display-name
const SearchBar: React.FC<SearchBarProps> = React.memo(({
  setSearch,
  showSearchBar,
  setShowSearchBar,
  search,
  fireSearchRef
}) => {
  const ref = useRef<any>(null)

  useLayoutEffect(() => {
    fireSearchRef.current = str => {
      setShowSearchBar(true)
      setSearch(str.toLowerCase())
      ref.current?.focus()
    }
  }, [fireSearchRef, setShowSearchBar, setSearch])

  const strRef = useRef('')
  useMemo(() => { strRef.current = search }, [search])
  const timeoutRef = useRef<NodeJS.Timeout>()
  const save = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = undefined
    }
    setSearch(strRef.current.toLowerCase())
  }, [setSearch])


  return (
    <div className={`editorSearchBar ${showSearchBar ? '' : 'hidden'}`}>
      <SearchOutlined />
      <Input
        key={search + showSearchBar}
        ref={ref}
        autoFocus={showSearchBar}
        defaultValue={search}
        onBlur={save}
        onChange={useCallback(evt => {
          strRef.current = evt.target.value
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
          }
          timeoutRef.current = setTimeout(save, 1000)
        }, [save])}
        onKeyDown={useCallback(evt => {
          if (evt.key === 'Escape') {
            evt.preventDefault()
            evt.stopPropagation()
            setSearch('')
            setShowSearchBar(false)
          }
          if (evt.key === 'Enter') {
            evt.preventDefault()
            evt.stopPropagation()
            save()
          }
        }, [save, setSearch, setShowSearchBar])}
        placeholder='input search text'
      />
      <Button
        ghost
        icon={<CloseOutlined />}
        onClick={useCallback(() => {
          setSearch('')
          setShowSearchBar(false)
        }, [setSearch, setShowSearchBar])}
        type='default'
      />
    </div>
  )
})

/** ******************************************************************
 *            Balloon button
 ****************************************************************** */
const BalloonSearchFormat: React.FC<Pick<SearchPluginProps, 'fireSearchRef'>> = ({ fireSearchRef }) => {
  const currentEditor = useFocusedEditor()

  return (
    <ToolbarButton
      key='toolbarFormat'
      icon={<SearchOutlined />}
      onMouseDown={currentEditor ? getPreventDefaultHandler(() => {
        fireSearchRef.current?.(getSelectionText(currentEditor))
      }) : undefined}
    />
  )
}


/** ******************************************************************
 *            Use search hook
 ****************************************************************** */
// eslint-disable-next-line import/prefer-default-export
export const useSearch = (): UseSearchReturn => {
  const fireSearchRef = useRef<FireSearch>()
  const [search, setSearch] = useState<string>('')
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false)

  const plugins = useSearchPlugin({ fireSearchRef, search })

  const searchBar = useMemo(() => (
    <SearchBar
      key='searchBar'
      fireSearchRef={fireSearchRef}
      search={search}
      setSearch={setSearch}
      setShowSearchBar={setShowSearchBar}
      showSearchBar={showSearchBar}
    />
  ), [search, showSearchBar])

  const searchToolbarButton = useMemo(() => (
    <ToolbarButton
      key='toolbarFormat'
      active={showSearchBar}
      icon={<SearchOutlined />}
      onMouseDown={() => {
        setShowSearchBar(oldValue => !oldValue)
        setSearch('')
      }}
    />
  ), [showSearchBar])

  const searchBalloonButton = useMemo(() => <BalloonSearchFormat key='balloonFormat' fireSearchRef={fireSearchRef} />, [])

  return {
    ...plugins,
    searchBar,
    searchToolbarButton,
    searchBalloonButton,
    fireSearchRef
  }
}
