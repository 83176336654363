import React, { useMemo } from 'react'
import { LinkOutlined, MailOutlined, SendOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import { Button, Tooltip } from 'antd'

import { ConfirmButton } from '../../../../../../SharedComponents/FormComponents'
import loadingModalFeedback from '../../../../../../Alert/LoadingModalFeedback'


export const resendEmailAction = loadingModalFeedback({
  loadingTitle: <Trans>Resending invite email</Trans>,
  successTitle: <Trans>Email sent</Trans>,
  errorTitle: <Trans>Failed to resend invite email</Trans>,
  autoSuccessClose: 3000
})

const useCardActions = (
  resendEvent: any,
  email: string | undefined,
  copyLinkProps: any,
  condition: boolean | undefined,
  title: React.ReactElement | undefined | null = null
) => useMemo(() => {
  const actions: any[] = []
  if (condition) {
    if (resendEvent) {
      actions.push(
        <Tooltip key='invite' placement='bottom' title={title || (<Trans>Resend invite</Trans>)}>
          <ConfirmButton
            className='noBorder'
            confirmMessage={<Trans>Are you sure you want to send an invite email?</Trans>}
            ghost
            icon={<SendOutlined />}
            onClick={resendEvent}
            placement='bottom'
            size='small'
            type='default'
          />
        </Tooltip>
      )
    }
    if (email) {
      actions.push(
        <Tooltip key='email' placement='bottom' title={<Trans>Write an email</Trans>}>
          <Button
            className='noBorder'
            ghost
            href={`mailto:${email}`}
            icon={<MailOutlined />}
            size='small'
            type='default'
          />
        </Tooltip>
      )
    }
    if (copyLinkProps) {
      actions.push(
        <Tooltip key='link' placement='bottom' title={<Trans>Copy link</Trans>}>
          <Button
            {...copyLinkProps}
            className='noBorder'
            ghost
            icon={<LinkOutlined />}
            size='small'
            type='default'
          />
        </Tooltip>
      )
    }
  }
  return actions
}, [condition, copyLinkProps, email, resendEvent, title])

export default useCardActions
