import React, { useContext, useMemo } from 'react'
import { useParams } from 'react-router'
import { useQueryParam, StringParam } from 'use-query-params'
import { Trans } from '@lingui/macro'

import { getFieldValue } from '@top-legal/contract-assistant'
import { Contract, InputField, useBatchGet, useFieldsCollection, toJsonMap } from '@top-legal/datastore'
import { RenderOnlyEditor, renderProductsSummary } from '@top-legal/editor'

import { ContractContext } from './Contexts'
import { FormatText } from './Sidebar/SidebarToolboxes/Commenting/CommentDisplay'
import { emptyArr, emptyMap, emptyObj } from '../../Defaults'

import './ContractSummaryStyles.scss'

interface Options {
  noParties?: boolean
  maxItems?: number
  compact?: boolean
}

const notAllowedFieldsTypeToDisplay = /^(listOfFormattedText|multipleLineText|company|person)$/
export const canRenderFieldType = (type: string): boolean => !notAllowedFieldsTypeToDisplay.test(type)

/**
 * Produces a summary on top of the contract but also produces a summary of terms on the contract overview list.
 * It produces a summary and then you can employ whereever.
 */
export const useContractSummaryTable = (
  contract: Contract,
  fieldsMap: Map<string, InputField>,
  fieldsResponse: Contract['fieldsResponse'],
  { noParties, maxItems = -1, compact }: Options = {}
): React.ReactNode | undefined => {
  const { lang, entityID } = useParams()
  const [term, setTerm] = useQueryParam('term', StringParam)

  return useMemo<React.ReactNode | undefined>(() => {
    if (
      !contract
      || !contract.contractID
      || !contract.contractID.startsWith('contract-')
      || Object(fieldsResponse) !== fieldsResponse
    ) {
      return undefined
    }

    let elements: any[] = []
    let searchElement
    const addFieldValue = (key, name, value) => {
      if (Array.isArray(value) || (typeof value === 'string' && value) || value?.$$typeof) {
        let displayValue = value
        if (Array.isArray(value)) {
          displayValue = <RenderOnlyEditor nodes={value} />
        } else if (typeof value === 'string') {
          displayValue = <FormatText text={value} />
        }

        const isSearchedTerm = term === key

        const elem = (
          <div
            key={key}
            className={`contractSummaryItem ${isSearchedTerm ? 'searchActive' : ''}`}
            onClick={entityID ? undefined : () => setTerm(isSearchedTerm ? undefined : key)}
          >
            <div className='title'>
              <h4>{name}</h4>
            </div>
            <div className='fieldValue'>{displayValue}</div>
          </div>
        )

        if (isSearchedTerm) {
          searchElement = elem
        } else if (typeof name === 'string') {
          let idx = 0
          for (idx; idx < elements.length; idx += 1) {
            const elm = (elements as any)[idx][1]
            if (typeof elm !== 'string' || name < elm) {
              break
            }
          }
          elements.splice(idx, 0, [elem, name])
        } else {
          elements.push([elem, name])
        }
      }
    }

    //  Add all the elements
    Object.keys(fieldsResponse).forEach(fieldKey => {
      let [response, field] = getFieldValue(fieldsMap, fieldKey, fieldsResponse, true, lang) || emptyArr

      if (response != null && field != null) {
        if (field.type === 'company' || field.type === 'person') {
          if (noParties) { return null }
          response = fieldsResponse[fieldKey]
          return addFieldValue(fieldKey, field.name, response.name || `${response.firstName} ${response.lastName}`)
        }
        if (field.type === 'product') {
          return addFieldValue(fieldKey, field.productName, renderProductsSummary([field as any], fieldsResponse[fieldKey], lang))
        }
        if (field.type === 'productTable') {
          const products: InputField[] = []
          const quantities = {}

          const value = fieldsResponse[fieldKey] && fieldsResponse[fieldKey].__products
          if (Array.isArray(value)) {
            value.forEach(({ fieldKey: pdtKey, qty }, index) => {
              const pdt = fieldsMap.get(pdtKey)
              if (pdt) {
                products.push(pdt)
                if (qty != null) {
                  quantities[index] = qty
                }
              }
            })
          }

          return addFieldValue(fieldKey, field.name, renderProductsSummary(products as any, quantities, lang))
        }
        if (field.type === 'yesNo') {
          return addFieldValue(fieldKey, field.name, response === 'yes' ? <Trans>Yes</Trans> : <Trans>No</Trans>)
        }
        if (field.type === 'listOfFormattedText' || field.type === 'multipleLineText') {
          return null
        }
        if (field.type === 'list' && field.isMultipleChoices && Array.isArray(response) && compact) {
          //                 ul   -->   li   -->   p   -->   { text }   -->   text
          const text = response[0]?.children?.[0]?.children?.[0]?.children?.[0]?.text

          if (!text) {
            // eslint-disable-next-line no-console
            console.warn('Incorrect term value', contract.contractID, field.inputFieldID, field.name, response)
            return null
          }

          const nbI = response[0].children.length - 1
          response = `• ${text}`
          if (nbI > 0) {
            response += ` (+${nbI})`
          }
        }
        return addFieldValue(fieldKey, field.name, response)
      }
      return null
    })

    //  Extract display content
    elements = elements.map(([elm]) => elm)

    //  Pre add the searched element
    if (searchElement) {
      elements.unshift(searchElement)
    }

    if (elements.length === 0) {
      return undefined
    }

    if (maxItems != null && maxItems > 0) {
      elements = elements.slice(0, maxItems)
    }

    return <div className='contractSummaryTable'>{elements}</div>
  }, [contract, fieldsMap, fieldsResponse, maxItems, term, setTerm, lang, compact, noParties, entityID])
}


const ContractSummary: React.FC = () => {
  const { contract } = useContext<any>(ContractContext)
  const fieldsResponse = (contract && contract.fieldsResponse) || emptyObj
  const fieldIDs = useMemo(() => {
    const ids: any = {}
    if (Object(fieldsResponse) === fieldsResponse) {
      Object.keys(fieldsResponse).forEach(key => {
        ids[key.split('.')[0]] = true
      })
    }
    return Object.keys(ids)
  }, [fieldsResponse])

  const fieldsCollection = useFieldsCollection()
  const [fieldsMap] = useBatchGet(fieldsCollection, fieldIDs, toJsonMap)

  const table = useContractSummaryTable(contract, fieldsMap || emptyMap, fieldsResponse)
  if (!table) {
    return null
  }

  return (
    <div className='summaryContainer'>
      <h2 className='summaryTitle'>
        <Trans>Contract Summary</Trans>
      </h2>
      {table}
    </div>
  )
}

export default ContractSummary
