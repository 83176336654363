import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from '@lingui/macro'
import { Alert, Button } from 'antd'
import { EditOutlined, FileOutlined, MenuOutlined } from '@ant-design/icons'

//  Contexts
import { ContractContext, TemplateContext } from '../../Contract/ContractEditor/Contexts'
import { SidebarToolboxes } from '../../Contract/ContractEditor/Sidebar/Sidebar'
import { PhoneSidebarContext } from '../../Contract/ContractEditor/Sidebar/PhoneSidebar'
import ExternalMainContainer, { ExternalLangSwitchButtons } from '../ExternalMainContainer'
import { ExternalContext } from '../ExternalContext'

//  Components
import { GlobalLoadingAlert } from '../../Alert/LoadingAlert'
import { ExternalVideoModal } from '../ExternalPageSigning'
import ProvideContractSuggestionSaving from '../../Contract/ContractEditor/editorRefactoredPart/sectionComponents/ProvideContractSuggestionSaving'
import { ProvideContractEditorContext, UseSectionsFromStoreReturn } from '../../Contract/ContractEditor/editorRefactoredPart/withContractEditorContext'
import ExternalEditor from './ExternalEditor'

//  Toolboxes
import { ExternalWelcomeToolboxEditing } from '../Toolboxes/ExternalWelcomeToolbox'
import ExternalUserManagementToolbox from '../Toolboxes/ExternalUserManagementToolbox'
import CheckEmailToolbox from '../Toolboxes/CheckEmailToolbox'
import ChatToolbox from '../../Contract/ContractEditor/Sidebar/SidebarToolboxes/ChatToolbox'
import commentsToolboxInstance from '../Toolboxes/ExternalCommentToolbox'

//  Actions
import { parrallelToSequentialFactory } from '../../Template/_TemplateHelperFunctions'
import { loadContractFromToken } from './ExternalContractEditingContext'
import { DeviceContext } from '../../../GlobalContext'
import { noop } from '../../Defaults'


const useSectionsFromStore = (): UseSectionsFromStoreReturn => {
  const sections = useSelector(state => state.template.sections)
  return useMemo<UseSectionsFromStoreReturn>(() => [new Map<string, any>(Object.entries(sections)), false], [sections])
}


/** **************************************************************
 *                External contract editing
 ************************************************************** */
const ExternalContractEditing: React.FC = () => {
  const { tokenData } = useContext(ExternalContext)

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const contract = useSelector(state => state.contract.contractEditing)
  const template = useSelector(state => state.template.templateCreator)

  //  Load the contract & template behind that token
  useEffect(() => {
    (async () => {
      setLoading(true)
      try {
        await dispatch(loadContractFromToken(tokenData.token, tokenData.parties, tokenData.internalData, tokenData.internalComments))
      } catch (err) {
        console.error('Error when loading', err)
        setError(true)
      }
      setLoading(false)
    })()
  }, [dispatch, tokenData.token, tokenData.parties, tokenData.internalData, tokenData.internalComments])


  const [sectionMapping, setSectionMapping] = useState<any>({})
  const [sectionDeltasMapping, setSectionDeltasMapping] = useState<any>({})

  //  Transform the setSectionDeltasMapping into sequential (thread proof)
  const parrallelToSequential = useMemo(parrallelToSequentialFactory, [])
  const newSetSectionDeltasMapping = useCallback(data => {
    parrallelToSequential(() => {
      setSectionDeltasMapping(old => {
        if (typeof data === 'function') {
          return data(old)
        }
        return data
      })
    })
  }, [parrallelToSequential])

  const sidebarToolbox = useMemo(() => (tokenData.requestSigning ? {
    defaultKey: CheckEmailToolbox.key,
    defaultOpened: true,
    toolboxes: [CheckEmailToolbox]
  } : {
    defaultKey: ExternalWelcomeToolboxEditing.key,
    defaultOpened: true,
    toolboxes: [
      ExternalWelcomeToolboxEditing,
      commentsToolboxInstance,
      ExternalUserManagementToolbox,
      ChatToolbox(tokenData.instanceID)
    ]
  }), [tokenData.instanceID, tokenData.requestSigning])

  return (
    <TemplateContext.Provider
      value={{
        template,
        lang: template.lang,
        readOnly: true,
        sectionMapping,
        setSectionMapping,
        sectionDeltasMapping,
        setSectionDeltasMapping: newSetSectionDeltasMapping
      }}
    >
      <ContractContext.Provider value={{ contract, updateContract: noop }}>
        <ProvideContractEditorContext useSectionsFromStore={useSectionsFromStore}>
          <ProvideContractSuggestionSaving>
            <GlobalLoadingAlert description={<Trans>Loading your contract</Trans>} loading={loading}>
              {error ? (
                <Alert
                  description={<Trans>We cannot load your contract, do you have the right access?</Trans>}
                  message={<Trans>Cannot load your contract</Trans>}
                  showIcon
                  type='error'
                />
              ) : (
                <SidebarToolboxes.Provider value={sidebarToolbox}>
                  <ExternalEditor />
                </SidebarToolboxes.Provider>
              )}
            </GlobalLoadingAlert>
          </ProvideContractSuggestionSaving>
        </ProvideContractEditorContext>
      </ContractContext.Provider>
    </TemplateContext.Provider>
  )
}

const ExternalContractEditingActions: React.FC = () => (
  <>
    <div id='topBarSignButton' />
    <ExternalLangSwitchButtons />
  </>
)


/** **************************************************************
 *            External contract editing header
 ************************************************************** */
const ExternalContractEditingHeader: React.FC = () => {
  const isPhone = useContext(DeviceContext) === 'phone'
  const { openSidebarRef } = useContext(PhoneSidebarContext)
  const { tokenData } = useContext(ExternalContext)
  const { access } = useParams()
  const Icon = access === 'view' ? FileOutlined : EditOutlined

  return (
    <>
      <div className='topbarMainContent'>
        <Icon className='headerIcon' twoToneColor='#3DBD7D' />
        {!isPhone && <h1 className='title'>{tokenData.instanceTitle}</h1>}
      </div>
      <div id='topbarExtraContent' />
      <div className='topbarActions'>
        {isPhone ? (
          <Button
            className='noBorder phoneSidebarDrawerButton'
            ghost
            icon={<MenuOutlined />}
            onClick={() => openSidebarRef.current()}
            type='default'
          />
        ) : (
          <ExternalContractEditingActions />
        )}
      </div>
    </>
  )
}


/** **************************************************************
 *            External contract editing page
 ************************************************************** */
const ExternalContractEditingPage: React.FC = () => {
  const openSidebarRef = useRef<() => void>(noop)

  return (
    <PhoneSidebarContext.Provider value={{ ActionsButtons: ExternalContractEditingActions, openSidebarRef }}>
      <ExternalMainContainer mainContentClass='externalContractEditing' topbarContent={<ExternalContractEditingHeader />}>
        <ExternalContractEditing />
        <ExternalVideoModal />
      </ExternalMainContainer>
    </PhoneSidebarContext.Provider>
  )
}

export default ExternalContractEditingPage
