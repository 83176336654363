import React from 'react'

import { computeSnapshotPatch, slateToYjsDoc } from '@top-legal/editor'

import * as types from './TemplateActionsType'


// ==================================================================================================
/**
 * inital value for the templates, value extraction functions are stored in the
 * object to be used for later stage.
 */

const initTemplate = () => ({
  name: '',
  category: '',
  description: '',
  sections: [],
  annexes: [],
  fields: {},
  conditionalTexts: {}
})

const initState = () => ({
  templateCreator: initTemplate(),
  allTemplates: [],
  companyTemplates: [],
  allPublicTemplates: [],
  fetchingTemplateData: false,
  updatingConfidentiality: false,
  topLegalTemplates: [],
  duplicatingTemplate: false,
  instance: null,
  feature: null,
  sharedTemplates: [],
  fetchingSharedTemplates: false,
  fetchingTagsOrganisation: false,
  sectionLoad: {},
  organisationTags: [],
  communityTemplates: undefined,
  organisationSections: [],
  fetchingOrganisationSections: true,

  stylingTemplate: {
    name: 'Contract Title',
    templateID: 'template-styling',
    header: 'template-styling-three-paragraphs:1',
    footer: 'template-styling-three-paragraphs:2',
    sections: [{
      sectionID: 'template-styling-one-small-paragraph:3',
      subSections: [{
        sectionID: 'template-styling-with-marks:4'
      }, {
        sectionID: 'template-styling-chars-sample:5',
        subSections: [{
          sectionID: 'template-styling-one-small-paragraph:6'
        }]
      }]
    }, {
      sectionID: 'template-styling-one-small-paragraph:7',
      subSections: [{
        sectionID: 'template-styling-one-small-paragraph:8'
      }, {
        sectionID: 'template-styling-one-small-paragraph:9',
        subSections: [{
          sectionID: 'template-styling-with-marks:10'
        }]
      }]
    }]
  },

  sections: {
    'template-styling-one-small-paragraph': {
      name: 'Section name',
      yDoc: computeSnapshotPatch(slateToYjsDoc([{
        type: 'p',
        align: 'justify',
        children: [{
          text: 'Phasellus fermentum elementum dui quis aliquet. Sed tempor est ac dolor ultrices imperdiet. Vestibulum lobortis ut orci eu lobortis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
        }]
      }]))
    },
    'template-styling-three-paragraphs': {
      name: 'Section name',
      yDoc: computeSnapshotPatch(slateToYjsDoc([{
        type: 'p',
        align: 'left',
        children: [{
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        }]
      }, {
        type: 'p',
        align: 'center',
        children: [{
          text: 'Aenean facilisis ante felis, a feugiat nunc ultrices in.'
        }]
      }, {
        type: 'p',
        align: 'right',
        children: [{
          text: 'Maecenas auctor consectetur elit, eget dignissim dui laoreet sed.'
        }]
      }]))
    },
    'template-styling-with-marks': {
      name: 'Section name',
      yDoc: computeSnapshotPatch(slateToYjsDoc([{
        type: 'p',
        align: 'left',
        children: [
          { text: 'Lorem ipsum dolor sit amet, ' },
          { text: '«consectetur adipiscing»', bold: true },
          { text: ' elit. Aenean facilisis ante felis, a feugiat nunc ultrices in.' }
        ]
      }, {
        type: 'p',
        align: 'left',
        children: [
          { text: 'Nullaultrices lectus id ' },
          { text: '«tellus sollicitudin»', italic: true },
          { text: ', quis vestibulum erat placerat. Suspendisse potenti.' }
        ]
      }, {
        type: 'p',
        align: 'left',
        children: [
          { text: 'Maecenas auctor consecteturelit, eget ' },
          { text: '«dignissim dui laoreet»', underline: true },
          { text: ' sed. Cras imperdiet sollicitudin ligula, ultricies efficitur metus pretium et. ' },
          { text: '«Loremipsum dolor sit amet»', bold: true, italic: true, underline: true }, // eslint-disable-line
          { text: ', consectetur adipiscing elit.' }
        ]
      }]))
    },
    'template-styling-chars-sample': {
      name: 'Section name',
      yDoc: computeSnapshotPatch(slateToYjsDoc([{
        type: 'p',
        align: 'left',
        children: [{
          text: 'Phasellus fermentum elementum dui quis aliquet. Sed tempor est ac dolor ultrices imperdiet.'
        }]
      }, {
        type: 'ul',
        children: [{
          type: 'li',
          children: [{ text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' }]
        }, {
          type: 'li',
          children: [{ text: 'A B C D E F G H I J K L M N O P Q R S T U V W X Y Z' }]
        }, {
          type: 'li',
          children: [{ text: 'a b c d e f g h i j k l m n o p q r s t u v w x y z' }]
        }, {
          type: 'li',
          children: [{ text: '0 1 2 3 4 5 6 7 8 9' }]
        }, {
          type: 'li',
          children: [{ text: '{\'! " # $ % & \' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _ ` { | } ~ × ÷\'}' }]
        }, {
          type: 'li',
          children: [{ text: 'À Á Â Ã Ä Å Æ Ç È É Ê Ë Ì Í Î Ï Ð Ñ Ò Ó Ô Õ Ö Ø Ù Ú Û Ü Ý' }]
        }, {
          type: 'li',
          children: [{ text: 'Þ ß à á â ã ä å æ ç è é ê ë ì í î ï ð ñ ò ó ô õ ö ø ù ú û ü ý þ ÿ' }]
        }]
      }]))
    }
  }
})

const REDUCER_ACTIONS = {
  [types.UPDATE_TEMPLATE]: (state, payload) => {
    state.templateCreator = payload
  },
  [types.RESET_TEMPLATE]: state => {
    state.templateCreator = initTemplate()
  },
  [types.GET_ALL_TEMPLATES]: (state, payload) => {
    state.allTemplates = payload
  },
  [types.GET_ALL_PUBLIC_TEMPLATES]: (state, payload) => {
    state.allPublicTemplates = payload
  },
  [types.FETCHING_TEMPLATE_DATA]: (state, payload) => {
    state.fetchingTemplateData = payload
  },
  [types.GET_TOP_LEGAL_TEMPLATES]: (state, payload) => {
    state.topLegalTemplates = payload
  },
  [types.SET_TEMPLATE_CONFIDENTIAL]: (state, { templateID, confidential }) => {
    state.updatingConfidentiality = false
    const index = state.allTemplates.Items.indexOf(state.allTemplates.Items.filter(template => template.templateID === templateID)[0])
    state.allTemplates.Items[index] = {
      ...state.allTemplates.Items[index],
      confidential: `${confidential}`
    }
  },
  [types.UPDATING_CONFIDENTIALITY]: (state, value) => {
    state.updatingConfidentiality = value
  },
  [types.GENERATING_DUPLICATE_TEMPLATE]: (state, value) => {
    state.duplicatingTemplate = value
  },
  [types.DUPLICATE_TEMPLATE]: (state, template) => {
    state.allTemplates.Items = [...state.allTemplates.Items || [], template]
  },
  [types.SET_INSTANCE]: (state, { instance, feature }) => {
    state.instance = instance
    state.feature = feature
  },
  [types.FETCHING_SHARED_TEMPLATE]: (state, value) => {
    state.fetchingSharedTemplates = value
  },
  [types.GET_SHARED_TEMPLATE]: (state, templates) => {
    state.sharedTemplates = templates
  },
  [types.FETCHING_TAGS_ORGANISATION]: (state, value) => {
    state.fetchingTagsOrganisation = value
  },
  [types.GET_TAGS_ORGANISATION]: (state, value) => {
    state.sectionTagsMapping = value
  },
  [types.GET_SECTION_ORGANISATION]: (state, sectionLoad) => {
    state.sectionLoad = { ...sectionLoad, ...state.sectionLoad }
  },
  [types.ORGANISATION_TAGS]: (state, organisationTags) => {
    state.organisationTags = organisationTags
  },
  [types.ORGANISATION_SECTIONS]: (state, payload) => {
    state.organisationSections = { ...payload, ...state.organisationSections }
  },
  [types.FETCHING_ORGANISATION_SECTIONS]: (state, payload) => {
    state.fetchingOrganisationSections = payload
  },
  [types.COMMUNITY_TEMPLATES]: (state, templates) => {
    state.communityTemplates = templates
  },
  [types.SAVE_SECTIONS]: (state, fetchedSections) => {
    state.sections = {
      ...state.sections,
      ...fetchedSections
    }
  },
  [types.SAVE_SECTION]: (state, section) => {
    state.sections = {
      ...state.sections,
      [section.sectionID]: {
        ...(state.sections[section.sectionID] || {}),
        ...section
      }
    }
  },
  [types.DELETE_SECTION_ORG]: (state, sectionID) => {
    const oldSections = state.organisationSections
    delete oldSections[sectionID]
    state.organisationSections = oldSections
  }
}

export default function (state = initState(), action) {
  state = { ...state }
  const handler = REDUCER_ACTIONS[action.type]
  if (handler) {
    state = handler(state, action.payload) || state
  }
  return state
}
