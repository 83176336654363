import React from 'react'
import { Trans } from '@lingui/macro'

import PartExplanation from '../../../../Template/PartExplanation'

import { SectionProps } from './Types'

const SectionExplanations: React.FC<Pick<SectionProps, 'section'>> = ({ section }) => (
  <div className='sectionExplanationWrapper'>
    <PartExplanation media={section.explanationVideo} text={section.explanationText}>
      <Trans>Internal explanations</Trans>
    </PartExplanation>
    <PartExplanation className='externalSide' media={section.explanationVideoExternal} text={section.explanationTextExternal}>
      <Trans>External explanations</Trans>
    </PartExplanation>
  </div>
)

export default SectionExplanations
