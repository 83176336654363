import React, { useCallback, useContext, useState } from 'react'
import { Input } from 'antd'

import { TemplateContext } from '../Contexts'
import { IdPrefixContext } from '../../ContractDiplay/ContractLayout'
import useDebounceSaving from '../../../../hooks/useDebounceSaving'


const TemplateAnnexesTitle: React.FC<{ readOnly: boolean }> = ({ readOnly }) => {
  const { template: { annexesTitle }, updateTemplate } = useContext<any>(TemplateContext)
  const [title, setTitle] = useState<string>(annexesTitle)
  const idPrefix = useContext<string>(IdPrefixContext)

  const { saveTimeout, forceSave } = useDebounceSaving<string>(
    1500,
    annexesTitle,
    title,
    setTitle,
    useCallback(newName => updateTemplate({ annexesTitle: newName }), [updateTemplate])
  )

  return (
    <div className='templateTitle' id={`${idPrefix}annexes`}>
      {readOnly ? (
        <div className='ant-input'>{annexesTitle}</div>
      ) : (
        <Input.TextArea
          autoSize={{
            minRows: 1,
            maxRows: 5
          }}
          onBlur={forceSave}
          onChange={evt => {
            setTitle(evt.target.value)
            saveTimeout()
          }}
          onKeyDown={evt => {
            if (evt.key === 'Enter') {
              evt.preventDefault()
              forceSave()
            }
          }}
          value={title}
        />
      )}
    </div>
  )
}

export default TemplateAnnexesTitle
