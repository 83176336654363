import React, { useCallback, useContext, useMemo } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { Avatar, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import InstanceUsersContext from '../Context/InstanceUsersContext'
import { useAvatarStyle, UserAvatarByID } from '../../../../../../Organisations/UserRoleDisplay/UserAvatarList'
import Portal from '../../../../../../SharedComponents/Portal'


/**
 * This component creates some avatars on the top of the page wih the avatars of the users that 
 * have been added to the contract (not necessarily invited). With a click on the avatar, the user
 * gets more information ie. phone number.
 */
const size = '1.3rem'
const RenderContractAvatars: React.FC = () => {
  const { internRoles, partiesInfo } = useContext(InstanceUsersContext)
  const userStyle = useAvatarStyle(size)

  /** **********************************************************************
   *           Get the users out of the maps
   ********************************************************************** */
  const externalUsers: any[] = useMemo(
    () => {
      const arr: string[] = []
      Object.keys(partiesInfo).forEach(partyID => {
        if (/^(party|contact)-/.test(partyID)) {
          arr.push(partyID)
        }
      })
      return arr
    },
    [partiesInfo]
  )


  /** **********************************************************************
   *           Create the button actions
   ********************************************************************** */
  const [sidebar, setSidebar] = useQueryParam('sidebar', StringParam)
  const switchToolBox = useCallback(() => {
    if (sidebar !== 'users') {
      setSidebar('users')
    }
  }, [sidebar, setSidebar])


  /** **********************************************************************
   *       Rendering a portal to put avatars on the ContractTopbar
   ********************************************************************** */
  return (
    <>
      {/* Render internal avatars */}
      {internRoles.length > 0 && (
        <Portal cssSelector='.internalSpaceButton'>
          <span>
            <div className='contractMembersWrapper'>
              {internRoles.slice(0, internRoles.length === 3 ? 3 : 2).map(({ userID }) => (
                <UserAvatarByID
                  key={userID}
                  className='intern userAvatar'
                  onClick={switchToolBox}
                  size={size}
                  userID={userID}
                />
              ))}
              {internRoles.length > 3 && (
                <Avatar className='extraMembers' style={userStyle}>
                  {`+${internRoles.length - 2}`}
                </Avatar>
              )}
              <Button
                className='addInternal'
                icon={<PlusOutlined />}
                onClick={switchToolBox}
                shape='circle'
                size='small'
              />
            </div>
          </span>
        </Portal>
      )}

      {/* Render external avatars */}
      {externalUsers.length > 0 && (
        <Portal cssSelector='.dealRoomButton'>
          <span>
            <div className='contractMembersWrapper externalContainer'>
              {externalUsers.slice(0, externalUsers.length === 3 ? 3 : 2).map(userID => (
                <UserAvatarByID
                  key={userID}
                  className='extern userAvatar'
                  onClick={switchToolBox}
                  size={size}
                  userID={userID}
                />
              ))}
              {externalUsers.length > 3 && (
                <Avatar className='extraMembers externalExtraMember' style={userStyle}>
                  {`+${externalUsers.length - 2}`}
                </Avatar>
              )}
              <Button
                className='addExternal'
                icon={<PlusOutlined />}
                onClick={switchToolBox}
                shape='circle'
                size='small'
              />
            </div>
          </span>
        </Portal>
      )}
    </>
  )
}

export default RenderContractAvatars
