import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Empty, Tabs } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'

import ActivityLogs from './ActivityLogs'
import RestService from '../../RestService'
import { updateEvents, updateInstanceEvents } from '../Organisations/redux/OrganisationsActions'
import { LocalLoadingAlert } from '../Alert/LoadingAlert'
import { promises } from '../../PreLoadData'
import { UserAndCompanyDataContext } from '../Layouts/Constants'
import useLoadData from '../../hooks/useLoadData'
import TaskDisplay from '../Contract/ContractEditor/Sidebar/SidebarToolboxes/Commenting/TaskDisplay'
import { CommentsToolboxConfigContext } from '../Contract/ContractEditor/Sidebar/SidebarToolboxes/Commenting/Types'
import { DeviceContext } from '../../GlobalContext'

/** ******************************************************************
 *                    Organisation logs handler
 ****************************************************************** */

// get events of the whole organisation filtred by access rights
export const loadOrganisationEvents = (resetCache = false) => async (dispatch, getState) => {
  let { lastElement } = getState().organisation.events || {}
  if (resetCache) { lastElement = '' }
  const path = `/event?lastElement=${lastElement || ''}`
  const results = await RestService('GET', path)
  if (results && Array.isArray(results.Items)) {
    dispatch(updateEvents({ ...results, resetCache }))
  } else {
    dispatch(updateEvents({ Items: [], lastElement: null }))
  }
}

const defaultValue = {}
const defaultArray = []

export const OrganisationLogs = () => {
  const { user, company: { members } } = useContext(UserAndCompanyDataContext)
  const dispatch = useDispatch()
  const isPhone = useContext(DeviceContext) === 'phone'


  const [loading, setLoading] = useState(false)
  const loadEvents = useCallback(
    async () => {
      try {
        if (promises.organisationEvents) {
          setLoading(!promises.organisationEvents.done)
          await promises.organisationEvents
        } else {
          setLoading(true)
          await dispatch(loadOrganisationEvents(true))
        }
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
    },
    [dispatch]
  )

  useEffect(() => {
    loadEvents()
  }, [loadEvents])

  const { Items = defaultArray, lastElement } = useSelector(state => state.organisation.events || defaultValue)
  //  Pre-filtering the events
  const data = useMemo(() => Items.filter(elem => elem && elem.eventType !== 'editSection'), [Items])

  const loadMore = useCallback(async () => dispatch(loadOrganisationEvents()), [dispatch])
  const props = { hasMore: !!lastElement, loadMore }

  const notMember = userID => userID && typeof userID === 'string' && !userID.startsWith('org-') && !userID.startsWith('team-') && !members[userID]

  return (
    <>
      {!isPhone ? (
        <LocalLoadingAlert description={<Trans>Loading</Trans>} lightTheme loading={loading}>
          <div className='dashboardLogs'>
            <Tabs centered defaultActiveKey='me' destroyInactiveTabPane>
              <Tabs.TabPane key='all' tab={<h2><Trans>All activities</Trans></h2>}>
                <ActivityLogs {...props} Items={data} />
              </Tabs.TabPane>
              {!user.tempEntityEndorsement && (
                <Tabs.TabPane key='me' tab={<h2><Trans>My activities</Trans></h2>}>
                  <ActivityLogs {...props} Items={data.filter(({ userID }) => userID === user.userID)} />
                </Tabs.TabPane>
              )}
              <Tabs.TabPane key='myTeam' tab={<h2><Trans>External parties</Trans></h2>}>
                <ActivityLogs
                  {...props}
                  Items={data.filter(
                    ({ userID, thirdParty }) => notMember(userID) || (Object(thirdParty) === thirdParty
                      ? (notMember(thirdParty.userID) || notMember(thirdParty.partyID))
                      : notMember(thirdParty))
                  )}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </LocalLoadingAlert>
      ) : (
        <LocalLoadingAlert description={<Trans>Loading</Trans>} lightTheme loading={loading}>
          <div className='dashboardLogs'>
            <Tabs centered defaultActiveKey='me' destroyInactiveTabPane>
              <Tabs.TabPane key='all' tab={<h2><Trans>All</Trans></h2>}>
                <ActivityLogs {...props} Items={data} />
              </Tabs.TabPane>
              <Tabs.TabPane key='me' tab={<h2><Trans>Mine</Trans></h2>}>
                <ActivityLogs {...props} Items={data.filter(({ userID }) => userID === user.userID)} />
              </Tabs.TabPane>
              <Tabs.TabPane key='myTeam' tab={<h2><Trans>External</Trans></h2>}>
                <ActivityLogs
                  {...props}
                  Items={data.filter(
                    ({ userID, thirdParty }) => notMember(userID) || (Object(thirdParty) === thirdParty ? notMember(thirdParty.userID) : notMember(thirdParty))
                  )}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </LocalLoadingAlert>


      )}
    </>
  )
}

/** ******************************************************************
 *                    Instance logs handler
 ****************************************************************** */
// fetching instance events (template, contract)
const loadInstanceEvents = (instanceType, instanceID, resetCache = false) => async (dispatch, getState) => {
  const { lastElement } = getState().organisation.instanceEvents || {}
  const path = `/event/instance/${instanceType}/${instanceID}?lastElement=${(!resetCache && lastElement) || ''}`
  const results = await RestService('GET', path)
  dispatch(updateInstanceEvents({ ...results, resetCache }))
}

export const InstanceLogs = ({ instanceType, instanceID, instanceName, onData = data => data }) => {
  const dispatch = useDispatch()
  const loadMore = useCallback(
    resetCache => dispatch(loadInstanceEvents(instanceType, instanceID, resetCache)),
    [dispatch, instanceID, instanceType]
  )
  useEffect(() => { loadMore(true) }, [loadMore])

  const { Items = [], lastElement } = useSelector(state => {
    const { instanceEvents } = state.organisation
    if (instanceEvents) {
      return instanceEvents
    }
    return {}
  })

  const data = useMemo(() => (onData ? onData(Items) : Items), [Items, onData])

  return (
    <ActivityLogs
      Items={data}
      className='instanceLogs'
      hasMore={!!lastElement}
      instanceName={instanceName}
      loadMore={loadMore}
    />
  )
}

/** ******************************************************************
 *                    Logs for upcoming reminder
 ****************************************************************** */
const config = {
  noLink: true,
  readOnly: true
}

export const RemindersLogs = () => {
  const [loading, , tasks] = useLoadData(
    () => RestService('GET', '/comments/myTasks'),
    []
  )

  const content = useMemo(() => {
    const array = []
    if (loading) {
      return <LoadingOutlined spin style={{ fontSize: '4rem' }} />
    }
    if (!Array.isArray(tasks) || tasks.length === 0) {
      return <Empty description={<Trans>Well done you have no task to do!</Trans>} />
    }

    let today = new Date()
    today.setHours(0, 0, 0, 0)
    today = today.toISOString()

    if (tasks[0].dueDate < today) {
      array.push(
        <div key='overDueTasks' className='taskSeparator'><span><Trans>Overdue tasks</Trans></span></div>
      )
    }

    let next = false
    tasks.forEach(task => {
      if (!next && task.dueDate >= today) {
        next = true
        array.push(
          <div key='nextTasks' className='taskSeparator'><span><Trans>Next tasks</Trans></span></div>
        )
      }

      array.push(
        <TaskDisplay
          key={task.date}
          noThread
          node={{ comment: { ...task, date: new Date(task.date) } }}
          withNewTabLink
        />
      )
    })

    return array
  }, [loading, tasks])

  return (
    <Card className='remindersCard betterCard fullHeight' title={<h2><Trans>My tasks</Trans></h2>}>
      <CommentsToolboxConfigContext.Provider value={config}>
        <div className='reminderCardTasks'>{content}</div>
      </CommentsToolboxConfigContext.Provider>
    </Card>
  )
}

