import { RxCollectionBase, RxDatabase } from 'rxdb/plugins/core'
import { Collections } from '../Types'

/**
 * Typescript to json schema
 ```bash
 # First comment everything except the type
 cd /tmp # Important since it cause issue with types rule of the project
 npx ts-json-schema-generator --path /absolute-path-to-project/src/DataStore/Schema/ContractingParties.ts --type ContractingParty
 ```
 */
import filesSchema from './Files'
import { contractEventsConfig, otherEventsConfig, templateEventsConfig } from './Events'
import { contractCommentsConfig, otherCommentsConfig, templateCommentsConfig } from './Comments'
import sectionSchema from './Sections'
import inputsSchema from './InputFields'
import condsSchema from './ConditionalTexts'
import templatesSchema from './Templates'
import templateFlaternisationsConfig from './TemplateFlaternisation'
import partiesSchema from './ContractingParties'
import contractsSchema from './Contracts'


const ALL_COLLECTIONS = [
  filesSchema,

  otherEventsConfig,
  templateEventsConfig,
  contractEventsConfig,

  otherCommentsConfig,
  templateCommentsConfig,
  templateFlaternisationsConfig,
  contractCommentsConfig,

  sectionSchema,

  inputsSchema,
  condsSchema,

  templatesSchema,

  partiesSchema,
  contractsSchema
]

const doMigration = (col: RxCollectionBase<any>) => new Promise(resolve => {
  let prevPercent = 0
  console.info('[Datastore] Migrating', col.name)
  const subscription = col.migrate(20).subscribe(state => {
    if (state.done) {
      console.info('[Datastore] Migration of', col.name, 'done', state)
      subscription.unsubscribe()
      resolve(null)
    } else if (state.percent - 5 >= prevPercent) {
      prevPercent = state.percent
      console.info('[Datastore] Migration of', col.name, state.percent, '%')
    }
  })
})

export const checkMigration = async (database: RxDatabase, cols: Collections) => {
  //  Only migrate in the leader
  await database.waitForLeadership().then(async () => {
    await Promise.all(Object.values(cols).map(async col => {
      if (await col.migrationNeeded()) {
        await doMigration(col)
      }
    }))
  })
}

const setupCollections = async (database: RxDatabase): Promise<Collections> => {
  const collections: any = {}

  ALL_COLLECTIONS.forEach(({ key, schema, migrationStrategies }) => {
    collections[key] = { schema, migrationStrategies, autoMigrate: false }
  })

  return database.addCollections(collections)
}

export default setupCollections
