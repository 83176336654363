import React from 'react'
import { Trans } from '@lingui/macro'
import Icon, { PhoneOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import ExternalMainContainer from './ExternalMainContainer'
import TLogo from '../../img/top-legal-t-blue.svg'

const TIconComponent = () => <img alt='T' className='topLegalAboutUs' src={TLogo} />
const TIcon = props => <Icon {...props} component={TIconComponent} />

const AboutTopLegal = () => {
  const contactSales = () => {}

  return (
    <ExternalMainContainer
      mainContentClass='aboutTopLegal'
      topbarContent={(
        <>
          <div className='topbarMainContent'>
            <TIcon className='headerIcon' twoToneColor='#3DBD7D' />
            <h1 className='title'><Trans>About top.legal</Trans></h1>
          </div>
          <div className='topbarActions'>
            <Button
              ghost
              href='javascript:void(0)'
              icon={<PhoneOutlined />}
              onClick={contactSales}
              type='primary'
            >
              <Trans>Contact sales</Trans>
            </Button>
          </div>
        </>
      )}
    />
  )
}


export default AboutTopLegal
