import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Alert, Button, Dropdown, Menu, Tooltip } from 'antd'
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
  MoreOutlined
} from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import moment from 'moment'

//  Editor library
import { DeltaNode, DeltaTree, RenderOnlyEditor } from '@top-legal/editor'

import { useSectionsCollection } from '@top-legal/datastore'
import { UserAndCompanyDataContext } from '../../../../../Layouts/Constants'
import {ContractContext, TemplateContext} from '../../../Contexts'
import { PartCommentContext } from './PartComment'
import { ExternalContext } from '../../../../../ExternalViews/ExternalContext'
import { UserAvatarByID, UserFullName } from '../../../../../Organisations/UserRoleDisplay/UserAvatarList'
import { ConfirmMenuItem } from '../../../../../SharedComponents/FormComponents'
import { deleteComment } from '../../../../../Organisations/redux/OrganisationsActions'
import { manageSuggestion } from '../../../../../Template/redux/TemplateActions'

import Thread from './Thread'
import { CommentTitleDisplay, ExternalSwitchMenuItem, FormatText, useAutoScrollToComment, useGenerateLink } from './CommentDisplay'
import ExternalSuggestionDisplayActions from '../../../../../ExternalViews/Editor/ExternalSuggestionDisplayActions'
import { CHILDID_CHAR_SEPARATOR, CommentDisplayProps, CommentsToolboxConfigContext, YjsDeltaComment } from './Types'
import CommentPublishOverlay from './CommentPublishOverlay'


const RenderDeltaNode: React.FC<{ node: DeltaNode }> = ({
  node: { preDeletions, insertedNode, textDelta, children, postDeletions }
}) => {
  let offset = 0
  return (
    <>
      {/* Render nodes that has been delete before that leaf */}
      {preDeletions && <div className='redLiningRemoveNode'><RenderOnlyEditor nodes={preDeletions} /></div>}

      {/* Render the insertion */}
      {insertedNode && <div className='redLiningInsertNode'><RenderOnlyEditor nodes={[insertedNode]} /></div>}

      {/* Render text delta */}
      {textDelta && (
        <p>
          {textDelta.map((delta, index) => {
            const space = (delta.offset === offset || offset === 0) ? '' : <span>&nbsp;&nbsp;&nbsp;</span>
            offset = delta.offset + (delta.type === 'added' ? delta.text.length : 0)
            return (
              <span key={index}>
                {space}
                <span key={index} className={delta.type === 'added' ? 'redLiningInsert' : 'redLiningRemove'}>
                  <span>{delta.text}</span>
                </span>
              </span>
            )
          })}
        </p>
      )}

      {/* Render children */}
      {children && <RenderDeltaTree tree={children} />}

      {/* Render nodes that has been delete after that leaf */}
      {postDeletions && <div className='redLiningRemoveNode'><RenderOnlyEditor nodes={postDeletions} /></div>}
    </>
  )
}

const RenderDeltaTree: React.FC<{ tree: DeltaTree }> = ({ tree }) => Object.entries(tree).map(
  ([key, node]) => <RenderDeltaNode key={key} node={node} />
) as any


export const AcceptRejectStatus: React.FC<Pick<YjsDeltaComment, 'rate' | 'description' | 'managedBy' | 'managedDate'>> = ({
  rate,
  description,
  managedBy,
  managedDate
}) => {
  if (rate && managedBy && managedDate) {
    return (
      <Alert
        className='suggestionAcceptRejectReason'
        description={(
          <div className='reasonWrapper'>
            <UserAvatarByID autoSide noTooltip size='1.1rem' userID={managedBy} /> <span />
            <div className='reason'>
              <p className='reasonUser'><UserFullName userID={managedBy} /></p>
              <p className='reasonDate'><span>{moment(managedDate).calendar()}</span></p>
            </div>
          </div>
        )}
        icon={rate === 'rejected' ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
        message={(description ? <FormatText text={description} /> : (
          <>
            {rate === 'favorable' && <Trans>Favorable</Trans>}
            {rate === 'neutral' && <Trans>Acceptable</Trans>}
            {rate === 'fallback' && <Trans>Fallback</Trans>}
            {rate === 'rejected' && <Trans>Rejected</Trans>}
            {rate === 'accepted' && <Trans>Accepted</Trans>}
          </>
        ))}
        showIcon
        type={(['accepted', 'favorable'].includes(rate) && 'success') || (rate === 'rejected' && 'error') || 'info'}
      />
    )
  }
  return null
}

const YjsDeltaDisplay: React.FC<CommentDisplayProps> = ({ node, noThread, onClick }) => {
  const { tokenData } = useContext(ExternalContext)
  const { noLink, readOnly } = useContext(CommentsToolboxConfigContext)
  const { sectionDeltasMapping, setSectionDeltasMapping } = useContext<any>(TemplateContext)
  const { contract: { contractStatus } } = useContext<any>(ContractContext)
  const { user, company } = useContext<any>(UserAndCompanyDataContext)
  const { part, scrollElem, flashElem, getSectionElem } = useContext(PartCommentContext)

  const deltaComment = useMemo<YjsDeltaComment>(() => {
    let tmp = node.comment as YjsDeltaComment

    //  If it is a suggestion try to find the latestOne to display
    if (!noThread && node.comments) {
      const entries = Object.entries(node.comments)
      if (entries.length > 0) {
        entries.forEach(([, comm_]) => {
          const comm = comm_.comment as YjsDeltaComment
          if (comm && comm.delta && (comm.date > tmp.date || comm.rate !== 'rejected')) {
            tmp = comm
          }
        })
      }
    }

    return tmp
  }, [noThread, node.comment, node.comments])
  const [generateLink] = useGenerateLink(deltaComment)

  const ref = useRef({ sectionDeltasMapping, setSectionDeltasMapping })
  ref.current = { sectionDeltasMapping, setSectionDeltasMapping }
  const showDelta = useCallback((suggest?: boolean, checkDate?: boolean) => {
    //  We can't display deltas if the section has been updated
    if (deltaComment.inHistory || deltaComment.rate === 'rejected' || deltaComment.externalRate) {
      return
    }

    const prev = ref.current.sectionDeltasMapping[part.sectionID]
    if (!checkDate || !prev || deltaComment.date > prev.date) {
      ref.current.setSectionDeltasMapping(old => ({
        ...old,
        [part.sectionID]: {
          delta: deltaComment.delta,
          childID: deltaComment.childID.includes(CHILDID_CHAR_SEPARATOR)
            ? deltaComment.childID
            : `${deltaComment.childID}${CHILDID_CHAR_SEPARATOR}${deltaComment.date}`,
          date: deltaComment.date,
          suggest
        }
      }))
    }
  }, [part.sectionID, deltaComment])

  //  Make the latest change visible in the contract
  useEffect(() => {
    if (!noThread) {
      setTimeout(() => showDelta(false, true))
    }
  }, [noThread, showDelta])

  const [buttonLoading, setButtonLoading] = useState(false)
  const dispatch = useDispatch()
  //  Cannot manage redline in signing mode
  const isActive = !deltaComment.rate && !['frozen', 'signed'].includes(contractStatus)

  const [threadOpen, setThreadOpen] = useState(false)
  const elemRef = useRef<HTMLDivElement>(null)

  const realClick = useMemo(() => onClick || (() => {
    showDelta()

    let section = getSectionElem()
    section = (section && section.querySelector('.sectionText')) || section
    if (noThread) {
      scrollElem(section)
    }
    scrollElem(elemRef.current)
    flashElem(elemRef.current)
    flashElem(section)
  }), [flashElem, getSectionElem, noThread, onClick, scrollElem, showDelta])


  useAutoScrollToComment(part.sectionID, deltaComment, realClick, setThreadOpen)


  const editable = !readOnly
    && (new Date()).valueOf() - (new Date(deltaComment.date)).valueOf() < 1000 * 60 * 60
    && company.members[deltaComment.userID]

  const classes = ['comment', 'delta']
  if (sectionDeltasMapping[part.sectionID] && sectionDeltasMapping[part.sectionID].delta === deltaComment.delta) {
    classes.push('active')
  }
  // Redline external bool is only set by the External views & PublishModal but is not a state in DB
  if ((deltaComment as any).external) {
    classes.push('external-shadow')
  }
  if (deltaComment.userID === user.userID) {
    classes.push('myComment')
  }

  // const [reasonModal, setReasonModal] = useState<'favorable' | 'rejected' | null>(null)
  const sectionsCollection = useSectionsCollection()
  const updateRedlineInternalStatus = useCallback(async ({ rate, description = '' }) => {
    setButtonLoading(true)
    try {
      await dispatch(manageSuggestion(sectionsCollection, deltaComment, { rate, description }))
      setSectionDeltasMapping(old => ({ ...old, [part.sectionID]: undefined }))
      // setReasonModal(null)
    } catch (err) {
      console.error('Suggestion error', deltaComment, rate, description, err)
      throw err
    }
    setButtonLoading(false)
  }, [dispatch, sectionsCollection, deltaComment, setSectionDeltasMapping, part.sectionID])

  return (
    <div ref={elemRef} className={classes.join(' ')} onClick={realClick}>
      <CommentPublishOverlay comment={deltaComment} />
      <CommentTitleDisplay comment={deltaComment}>
        <div className='actions' onClick={evt => evt.stopPropagation()}>
          {(!tokenData || !tokenData.token) && isActive && (
            <>
              <Tooltip placement='topRight' title={<Trans>Accept deltas</Trans>}>
                <Button
                  className='noBorder'
                  ghost
                  icon={<CheckOutlined />}
                  loading={buttonLoading}
                  onClick={() => updateRedlineInternalStatus({ rate: 'accepted' })}
                  size='small'
                  type='primary'
                />
              </Tooltip>
              <Tooltip placement='topRight' title={<Trans>Reject deltas</Trans>}>
                <Button
                  className='noBorder'
                  danger
                  ghost
                  icon={<CloseOutlined />}
                  loading={buttonLoading}
                  onClick={() => updateRedlineInternalStatus({ rate: 'rejected' })}
                  size='small'
                  type='primary'
                />
              </Tooltip>
            </>
          )}
          <ExternalSuggestionDisplayActions part={part} suggestion={deltaComment} />
          {(!noLink || (editable && !deltaComment.rate) || isActive) && (
            <Dropdown
              disabled={buttonLoading}
              overlay={(
                <Menu>
                  {!noLink && (
                    <Menu.Item onClick={generateLink}>
                      <LinkOutlined />
                      <span><Trans>Generate link</Trans></span>
                    </Menu.Item>
                  )}
                  {deltaComment.managedBy && <ExternalSwitchMenuItem comment={deltaComment} setButtonLoading={setButtonLoading} />}
                  {isActive && (
                    <Menu.Item onClick={() => showDelta(true)}>
                      <EditOutlined />
                      <span><Trans>Edit this suggestion as new</Trans></span>
                    </Menu.Item>
                  )}
                  {editable && !deltaComment.rate && (
                    <ConfirmMenuItem
                      confirmMessage={<Trans>Are you sure you want to delete your suggestion?</Trans>}
                      danger
                      onClick={async () => {
                        setButtonLoading(true)
                        try {
                          await dispatch(deleteComment(deltaComment))
                        } catch (err) {
                          console.error('Failed to delete comment', deltaComment, err)
                        }
                        setButtonLoading(false)
                        setTimeout(() => setSectionDeltasMapping(old => ({ ...old, [part.sectionID]: undefined })))
                      }}
                    >
                      <DeleteOutlined />
                      <span><Trans>Delete this suggestion</Trans></span>
                    </ConfirmMenuItem>
                  )}
                </Menu>
              )}
              placement='bottomRight'
              trigger={['click']}
            >
              <Button
                className='noBorder moreButton'
                ghost
                icon={<MoreOutlined />}
                loading={buttonLoading}
                size='small'
              />
            </Dropdown>
          )}
        </div>
      </CommentTitleDisplay>

      <div className='suggestion'>
        <div className='suggestionHeader'>
          <p><Trans>Suggested a text modification with:</Trans></p>
        </div>
        <div className='suggestionContent'>
          <div className='deltas'>
            <RenderDeltaTree tree={deltaComment.delta.delta} />
          </div>
        </div>
      </div>
      {!isActive && (() => {
        if (!deltaComment.inHistory && deltaComment.managedBy === user.userID) {
          return null
        }
        if (deltaComment.rate) {
          return <AcceptRejectStatus {...deltaComment} />
        }
        return (
          <Alert
            className='suggestionAcceptRejectReason'
            description={(
              <p><Trans>The section has been updated and so this suggestion rejected.</Trans></p>
            )}
            icon={<CloseCircleOutlined />}
            message={<Trans>This suggestion has been automatically rejected</Trans>}
            showIcon
            type='error'
          />
        )
      })()}
      <AcceptRejectStatus managedBy={deltaComment.externalyManagedBy} managedDate={deltaComment.externalyManagedDate} rate={deltaComment.externalRate} />

      {!noThread && (
        <Button
          //  eslint-disable-next-line no-script-url
          href='javascript:void(0)'
          onClick={evt => { evt.stopPropagation(); setThreadOpen(true) }}
          size='small'
          type='link'
        >
          {(node.comments && Object.keys(node.comments).length) ? <Trans>{Object.keys(node.comments).length} comments</Trans> : <Trans>Comment</Trans>}
        </Button>
      )}

      {!noThread && (
        <Thread
          close={() => {
            setThreadOpen(false)
            flashElem(elemRef.current)
          }}
          node={node}
          open={threadOpen}
        />
      )}
    </div>
  )
}

export default YjsDeltaDisplay
