import React from 'react'
import { t, Trans } from '@lingui/macro'
import { Form, Radio, Select } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import { TemplateSettingsChildProps } from '../TemplatePage'
import { requiredRules } from '../../SharedComponents/CustomFormWrapper'
import InputUsersAndTeams from '../../SharedComponents/FormComponents/InputUsersAndTeams'

const ApprovalSettings: React.FC<TemplateSettingsChildProps> = ({ template }) => {
  const handleClick = (evt, fieldName, form) => {
    evt.preventDefault()
    evt.stopPropagation()
    if (!form.getFieldValue(fieldName)) {
      form.setFieldsValue({ [fieldName]: true })
    }
  }

  return (
    <>
      <fieldset>
        <label><h3><Trans>Approval before signing</Trans></h3></label>
        <Form.Item
          initialValue={template.needSigningApproval || false}
          name='needSigningApproval'
          rules={requiredRules}
        >
          <Radio.Group className='radioCardChoice'>
            <Radio value={false}>
              <h4><Trans>Self-Approval</Trans></h4>
              <p><Trans>Users approve the contract without approval from decision-makers.</Trans></p>
              <span className='activateChoice'><Trans>Activated</Trans> <CheckCircleFilled /></span>
            </Radio>
            <Radio value={true}>
              <h4><Trans>Team Approval</Trans></h4>
              <Form.Item shouldUpdate={(prev, next) => prev.needSigningApproval !== next.needSigningApproval}>
                {form => (
                  <div onClick={evt => handleClick(evt, 'needSigningApproval', form)}>
                    <Form.Item
                      initialValue={template.signingApprovalUsersNumber}
                      name='signingApprovalUsersNumber'
                      rules={form.getFieldValue('needSigningApproval') ? requiredRules : undefined}
                    >
                      <Select className='simple-line' dropdownMatchSelectWidth={false} placeholder={t`# of people to approve`}>
                        <Select.Option value={1}><Trans>Approval by 1 person of the list</Trans></Select.Option>
                        <Select.Option value={2}><Trans>Approval by 2 persons (4 eyes principle)</Trans></Select.Option>
                        <Select.Option value={3}><Trans>Approval by 3 persons (6 eyes principle)</Trans></Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      initialValue={template.signingApprovalUsers}
                      name='signingApprovalUsers'
                      rules={form.getFieldValue('needSigningApproval') ? requiredRules : undefined}
                    >
                      <InputUsersAndTeams />
                    </Form.Item>
                  </div>
                )}
              </Form.Item>
              <span className='activateChoice'><Trans>Activated</Trans> <CheckCircleFilled /></span>
            </Radio>
          </Radio.Group>
        </Form.Item>
      </fieldset>
      {/* Disabled redlining approval settings for now TODO: Enable it later */}
      {false && (
        <fieldset>
          <label><h3><Trans>Approval of redline edits</Trans></h3></label>
          <Form.Item
            initialValue={template.needRedlineApproval || false}
            name='needRedlineApproval'
            rules={requiredRules}
          >
            <Radio.Group className='radioCardChoice'>
              <Radio value={false}>
                <h4><Trans>Self-Approval</Trans></h4>
                <p><Trans>Users can self-approval changes without approval from decision-makers.</Trans></p>
                <span className='activateChoice'><Trans>Activated</Trans> <CheckCircleFilled /></span>
              </Radio>
              <Radio value={true}>
                <h4><Trans>Team Approval</Trans></h4>
                <Form.Item shouldUpdate={(prev, next) => prev.needRedlineApproval !== next.needRedlineApproval}>
                  {form => (
                    <div onClick={evt => handleClick(evt, 'needRedlineApproval', form)}>
                      <Form.Item
                        initialValue={template.redlineApprovalUsers}
                        name='redlineApprovalUsers'
                        rules={form.getFieldValue('needRedlineApproval') ? requiredRules : undefined}
                      >
                        <InputUsersAndTeams />
                      </Form.Item>
                    </div>
                  )}
                </Form.Item>
                <span className='activateChoice'><Trans>Activated</Trans> <CheckCircleFilled /></span>
              </Radio>
            </Radio.Group>
          </Form.Item>
        </fieldset>
      )}
    </>
  )
}

export default ApprovalSettings
