import React, { useCallback, useState } from 'react'
import { Alert, Skeleton } from 'antd'
import { Trans } from '@lingui/macro'
import { Document, Page, pdfjs } from 'react-pdf'
import ButtonLink from '../SharedComponents/ButtonLink'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

export const PDFError: React.FC = ({ children }) => (
  <Alert
    description={children}
    message={<Trans>Failed to load your pdf</Trans>}
    showIcon
    style={{ margin: '1rem' }}
    type='error'
  />
)

export const PdfNorRendering: React.FC<{ url: string }> = ({ url, children }) => {
  const Btn = useCallback(({ children: innerChildren }) => (
    <ButtonLink
      href={url}
      noRouter
      target='_blank'
      type='link'
    >
      {innerChildren}
    </ButtonLink>
  ), [url])

  return (
    <Alert
      description={(
        <Trans>
          We cannot display this pdf page in the application.
          Please <Btn>download</Btn> the pdf and use a system reader.
          If you continue to have a problem contact our support.
        </Trans>
      )}
      message={children}
      showIcon
      type='info'
    />
  )
}


export const pdfDocumentOptions = {
  noData: <span />,
  error: <span />,
  loading: <span />,
  externalLinkTarget: '_blank',
  options: {
    cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
    cMapPacked: true
  }
}

const pdfPageOptions = {
  loading: <span />,
  renderAnnotationLayer: false,
  renderInteractiveForms: false,
  renderTextLayer: false
}

interface PdfPageProps {
  url: string
  pageIndex: number
  width: number
}

const PdfPage: React.FC<PdfPageProps> = ({ url, pageIndex, width }) => {
  //  Svg offer a better readability but can break for some pages, then fallback to canvas or show error
  const [renderMode, setRenderMode] = useState('svg')
  const [loading, setLoading] = useState(true)
  const [isErrored, setIsErrored] = useState(false)

  const onError = useCallback(() => {
    let render
    setRenderMode(old => {
      render = old
      return 'canvas'
    })
    if (render === 'canvas') {
      setIsErrored(true)
    }
  }, [])

  if (isErrored) {
    return (
      <div className='pdfPage paddedContent'>
        <PdfNorRendering url={url}>
          <Trans>Page {pageIndex + 1} cannot be displayed</Trans>
        </PdfNorRendering>
      </div>
    )
  }

  return (
    <>
      {loading && (
        <div className='pdfPage paddedContent'>
          <Skeleton active />
        </div>
      )}
      <Page
        {...pdfPageOptions}
        key={pageIndex}
        className='pdfPage'
        onLoadError={onError}
        onRenderError={onError}
        onRenderSuccess={() => setLoading(false)}
        pageIndex={pageIndex}
        renderMode={renderMode}
        width={width}
      />
    </>
  )
}

const Pdf: React.FC<{ url: string, description?: React.ReactNode, pages?: number[], noLoading?: boolean, width?: number }> = ({
  url,
  pages,
  width
}) => {
  const [nbPages, setNbPages] = useState(-1)
  const onDocRender = useCallback(({ numPages }) => setNbPages(numPages), [])

  const [isErrored, setIsErrored] = useState(false)

  if (isErrored) {
    return (
      <PdfNorRendering url={url}>
        <Trans>The pdf cannot be displayed</Trans>
      </PdfNorRendering>
    )
  }

  const pageWidth = Math.min(width || document.body.clientWidth, document.body.clientWidth)
  const displayPages = nbPages > 0 ? (pages || [...Array(nbPages).keys()]) : []
  return (
    <>
      {nbPages === -1 && (
        <div className='pdfPage paddedContent'>
          <Skeleton active />
        </div>
      )}
      <Document
        {...pdfDocumentOptions}
        file={url}
        onLoadError={() => setIsErrored(true)}
        onLoadSuccess={onDocRender}
      >
        {displayPages.map(pageIndex => pageIndex < nbPages && (
          <PdfPage
            key={pageIndex}
            pageIndex={pageIndex}
            url={url}
            width={pageWidth}
          />
        ))}
      </Document>
    </>
  )
}

export default Pdf
