import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { Button, Dropdown, Menu } from 'antd'
import { DeleteOutlined, EditOutlined, LinkOutlined, MoreOutlined, UserOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'

import {defaultUser, UserAndCompanyDataContext} from '../../../../../Layouts/Constants'
import { PartCommentContext } from './PartComment'

import { ConfirmMenuItem } from '../../../../../SharedComponents/FormComponents'
import { UserAvatarByID, UserFullName } from '../../../../../Organisations/UserRoleDisplay/UserAvatarList'
import { deleteComment, saveComment } from '../../../../../Organisations/redux/OrganisationsActions'
import { copyLink } from '../../../InviteContactToContract/InviteFormDrawer'

import Thread from './Thread'
import NewComment from './NewComment'
import {
  CHILDID_CHAR_SEPARATOR, Comment, CommentDisplayProps, CommentsToolboxConfigContext, TaskComment, TextComment, YjsDeltaComment
} from './Types'
import { ContractContext } from '../../../Contexts'
import CommentPublishOverlay from './CommentPublishOverlay'


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const IdentityText: React.FC<{ text: string }> = ({ text }) => text
export const FormatText: React.FC<{ text: string | null; Highlight?: React.FC<{ text: string }> }> = ({ text, Highlight = IdentityText }) => {
  if (text) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const split = text.split('\n').map<React.ReactNode>(str => <Highlight key={str} text={str} />)
    const end = split.length * 2

    for (let index = 1; index < end; index += 2) {
      split.splice(index, 0, <br key={index} />)
    }

    return split as any
  }
  return null
}

export const CommentTitleDisplay: React.FC<{ comment: Comment }> = ({ comment, children }) => {
  const { user } = useContext<any>(UserAndCompanyDataContext)
  const { contract } = useContext<any>(ContractContext)
  const date = contract && contract.lastPublishDate && contract.lastPublishDate

  const task = comment as TaskComment
  const redline = comment as YjsDeltaComment

  let redlineStatus
  if (redline.delta) {
    if (redline.fromExternal) {
      if (!redline.managedDate) {
        if (user.userID === defaultUser.userID) {
          redlineStatus = (
            <div className='externalVisibleStatus'>
              <Trans>Waiting for counterparty</Trans>
            </div>
          )
        } else {
          redlineStatus = (
            <div className='externalVisibleStatus'>
              <Trans>Waiting for approval</Trans>
            </div>
          )
        }
      }
    } else if (!redline.externalyManagedDate) {
      if (redline.external) {
        if (date > redline.date) {
          redlineStatus = (
            <div className='externalVisibleStatus'>
              <Trans>Waiting for counterparty</Trans>
            </div>
          )
        } else if (redline.managedDate) {
          redlineStatus = (
            <div className='externalVisibleStatus'>
              <Trans>Ready for publication</Trans>
            </div>
          )
        }
      } else if (!redline.managedDate) {
        redlineStatus = (
          <div className='externalVisibleStatus'>
            <Trans>Waiting for approval</Trans>
          </div>
        )
      }
    }
  }

  const userToDisplay = (task.assignedTo && task.assignedTo === user.userID && user.userID) || comment.userID

  return (
    <>
      <div className='commentStatus'>
        {redline.external && (
          <div className='externalVisibleStatus'>
            <Trans>Externally visible</Trans>
          </div>
        )}
        {!task.assignedTo && !redline.external && comment.organisationID !== '__EVERYONE__' && (
          <div className='myCommentStatus'>
            <Trans>Internally visible</Trans>
          </div>
        )}
        {redlineStatus}
      </div>
      {task.assignedTo && task.assignedTo === user.userID && task.userID !== user.userID && (
        <div className='assignedToUser'>
          <UserAvatarByID autoSide size='1.8rem' userID={task.userID} />
          <div className='text'>
            <h6>
              <Trans>Assigned by</Trans>
            </h6>
            <p>
              <UserFullName userID={task.userID} />
            </p>
          </div>
        </div>
      )}
      {task.assignedTo && task.assignedTo !== user.userID && (
        <div className='assignedToUser'>
          <UserAvatarByID autoSide size='1.8rem' userID={task.assignedTo} />
          <div className='text'>
            <h6>
              <Trans>Assigned to</Trans>
            </h6>
            <p>
              <UserFullName userID={task.assignedTo} />
            </p>
          </div>
        </div>
      )}
      <div className='commentTitle'>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <UserAvatarByID autoSide noTooltip size='1.8rem' userID={userToDisplay} />
        <div className='titleWrapper'>
          <span className='username'>
            <UserFullName userID={userToDisplay} />
          </span>
          <span className='date'>{moment((comment as any).realDate || comment.date).calendar()}</span>
        </div>
        {children}
      </div>
    </>
  )
}

export const ExternalSwitchMenuItem: React.FC<{ comment: TextComment | YjsDeltaComment; setButtonLoading: React.Dispatch<React.SetStateAction<boolean>> }> = ({
  comment,
  setButtonLoading,
  ...props
}) => {
  const {
    company: { organisationID }
  } = useContext(UserAndCompanyDataContext)
  const { contract } = useContext<any>(ContractContext)
  const dispatch = useDispatch()
  const date = contract && contract.lastPublishDate && new Date(contract.lastPublishDate)

  if (!date || (comment.organisationID === '__EVERYONE__' && new Date(comment.lastUpdated) < date)) {
    return null
  }

  return (
    <Menu.Item
      {...props}
      onClick={async () => {
        setButtonLoading(true)
        try {
          await dispatch(
            saveComment({
              ...comment,
              organisationID: comment.organisationID === organisationID ? '__EVERYONE__' : organisationID
            })
          )
        } catch (err) {
          console.error('Failed to update comment', comment, err)
        }
        setButtonLoading(false)
      }}
    >
      <UserOutlined />
      <span>{comment.external ? <Trans>Move back to internal</Trans> : <Trans>Make externally visible</Trans>}</span>
    </Menu.Item>
  )
}

export const useGenerateLink = (comment: Comment): [() => void, string] => {
  const { origin, pathname } = window.location
  const link = useMemo(() => {
    let str = `${origin}${pathname}`
    if (comment.childID) {
      const [sectionID, ...rest] = comment.childID.split(CHILDID_CHAR_SEPARATOR)
      rest.push(comment.date)
      str += `?sidebar=comments&activeSection=${sectionID}&comment=${rest.join(CHILDID_CHAR_SEPARATOR)}`
    }
    return str
  }, [comment.childID, comment.date, origin, pathname])

  return [useCallback(() => copyLink(link), [link]), link]
}

// =====================================================================================================================================================

/**
 * This hook is responsible to trigger the scrolling or opening of the thread of the provided comment if that one is active in the url params
 * Params like comment (commentID) and activeSection which is a section id is extracted from the URL.
 */
export const useAutoScrollToComment = (sectionID: string, comment: Comment, scrollToComment: () => void, setThreadOpen: (bool: boolean) => void) => {
  const [commentToScroll] = useQueryParam('comment', StringParam)
  const [activeSection] = useQueryParam('activeSection', StringParam)

  /**
   * a comment can have one ore moer responses. The respones can come as children to the original comment. This comment idlooks like 12345_abcd.
   * We need to remove the section id from the comment childID
   */
  const { date } = comment

  /**
   * The root comment consists of the template id and the section id combined. If we have several comments on the same section,  all of them
   * have the same id, but a different time stamp.
   *
   * template-edex9mal2lv27so-l2lv3kst_2022-04-30T14:22:13.186Z
   * Comment 1 on section  childID = `template-edex9mal2lv27so-l2lv3kst`                    date = 2022-02-22-10:15
   * Comment 2 on section  childID = `template-edex9mal2lv27so-l2lv3kst`                    date = 2022-02-22-11:15
   * Reply to comment 1    childID = `template-edex9mal2lv27so-l2lv3kst_2022-02-22-10:15`   date = 2022-02-23-14:20
   * Reply to comment 2    childID = `template-edex9mal2lv27so-l2lv3kst_2022-02-22-11:15`   date = 2022-02-23-15:20
   *
   */

  const [, ...rest] = comment.childID.split(CHILDID_CHAR_SEPARATOR)
  rest.push(date)
  const childIDforThreadComments = rest.join(CHILDID_CHAR_SEPARATOR) + CHILDID_CHAR_SEPARATOR

  useEffect(() => {
    if (sectionID === activeSection && commentToScroll) {
      setTimeout(() => {
        if (date === commentToScroll) {
          scrollToComment()
        } else if (commentToScroll.startsWith(childIDforThreadComments)) {
          setThreadOpen(true)
        }
      }, 10000)
    }
  }, [activeSection, date, childIDforThreadComments, commentToScroll, scrollToComment, sectionID, setThreadOpen])
}

const CommentDisplay: React.FC<CommentDisplayProps> = ({ node, noThread, onClick }) => {
  const { noLink, readOnly } = useContext(CommentsToolboxConfigContext)

  const comment = node.comment as TextComment
  const [generateLink] = useGenerateLink(comment)
  const [threadOpen, setThreadOpen] = useState(false)

  const { user, company } = useContext<any>(UserAndCompanyDataContext)
  const { scrollElem, flashElem, getSectionElem } = useContext(PartCommentContext)
  const elemRef = useRef<HTMLDivElement>(null)

   // =====================================================================================================================================================


  const highlightAndScroll = useMemo(
    () => onClick
      || (() => {
        let section = getSectionElem()
        section = (section && section.querySelector('.sectionText')) || section
        if (noThread) {
          scrollElem(section)
        }
        scrollElem(elemRef.current)
        flashElem(elemRef.current)
        flashElem(section)
      }),
    [onClick, getSectionElem, noThread, scrollElem, flashElem]
  )

  const { part } = useContext(PartCommentContext)
  useAutoScrollToComment(part.sectionID, comment, highlightAndScroll, setThreadOpen)

  const editable = !readOnly && new Date().valueOf() - new Date(comment.date).valueOf() < 1000 * 60 * 60 && company.members[comment.userID]

  const dispatch = useDispatch()
  const [buttonLoading, setButtonLoading] = useState(false)
  const [editing, setEditing] = useState(false)

  if (editing) {
    return (
      <div ref={elemRef}>
        <NewComment close={() => setEditing(false)} focus initialData={comment} />
      </div>
    )
  }

  const classes = ['comment']
  if (comment.external) {
    classes.push('external-shadow')
  }
  if (comment.userID === user.userID) {
    classes.push('myComment')
  }

  return (
    <div ref={elemRef} className={classes.join(' ')} onClick={highlightAndScroll}>
      <CommentPublishOverlay comment={comment} />
      <CommentTitleDisplay comment={comment}>
        {(editable || !noLink) && (
          <div className='actions' onClick={evt => evt.stopPropagation()}>
            <Dropdown
              disabled={buttonLoading}
              overlay={
                <Menu>
                  {!noLink && (
                    <Menu.Item onClick={generateLink}>
                      <LinkOutlined />
                      <span>
                        <Trans>Generate link</Trans>
                      </span>
                    </Menu.Item>
                  )}
                  {editable && (
                    <>
                      <ExternalSwitchMenuItem comment={comment} setButtonLoading={setButtonLoading} />
                      <Menu.Item onClick={() => setEditing(true)}>
                        <EditOutlined />
                        <span>
                          <Trans>Edit</Trans>
                        </span>
                      </Menu.Item>
                      <ConfirmMenuItem
                        confirmMessage={<Trans>Are you sure you want to delete your comment?</Trans>}
                        danger
                        onClick={async () => {
                          setButtonLoading(true)
                          try {
                            await dispatch(deleteComment(comment))
                          } catch (err) {
                            console.error('Failed to delete comment', comment, err)
                          }
                          setButtonLoading(false)
                        }}
                      >
                        <DeleteOutlined />
                        <span>
                          <Trans>Delete</Trans>
                        </span>
                      </ConfirmMenuItem>
                    </>
                  )}
                </Menu>
              }
              placement='bottomRight'
              trigger={['click']}
            >
              <Button
                className='noBorder moreButton' ghost icon={<MoreOutlined />} loading={buttonLoading}
                size='small'
              />
            </Dropdown>
          </div>
        )}
      </CommentTitleDisplay>

      <p>
        <FormatText text={comment.text} />
      </p>

      {!noThread && (
        <>
          <Button
            //  eslint-disable-next-line no-script-url
            href='javascript:void(0)'
            onClick={evt => {
              evt.stopPropagation()
              setThreadOpen(true)
            }}
            size='small'
            type='link'
          >
            {node.comments && Object.keys(node.comments).length ? <Trans>{Object.keys(node.comments).length} replies</Trans> : <Trans>Reply</Trans>}
          </Button>
          <Thread
            close={() => {
              setThreadOpen(false)
              flashElem(elemRef.current)
            }}
            node={node}
            open={threadOpen}
          />
        </>
      )}
    </div>
  )
}

export default CommentDisplay
