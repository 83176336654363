import React, { useContext, useEffect, useMemo, useRef } from 'react'

//  Contexts
import { ContractContext, TemplateContext } from '../Contexts'
import { UserAndCompanyDataContext } from '../../../Layouts/Constants'
import { IdPrefixContext } from '../../ContractDiplay/ContractLayout'

//  Components
import SignatureStamp from './SignatureStamp'
import getSignatureStampsInfo from './Utils/getSignatureStampsInfo'

//  Styles
import './SignatureStamp.scss'

/**
 * Signatures list
 */

const emptyObject = {}
const SignatureStampList: React.FC = () => {
  const { user } = useContext(UserAndCompanyDataContext)
  const { template } = useContext<any>(TemplateContext)
  const { contract = emptyObject } = useContext<any>(ContractContext)
  const idPrefix = useContext(IdPrefixContext)
  const ref = useRef<HTMLDivElement>(null)


  //  Auto scroll to signatures in signing phase
  useEffect(() => {
    if (['frozen', 'signed'].includes(contract.contractStatus) && ref.current) {
      setTimeout(() => {
        if (ref.current) {
          ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }
      }, 1000)
    }
  }, [contract.contractStatus])


  //  Find a potential current user signature to sign
  const userSignature = useMemo(
    () => contract.signaturesHolder && Array.isArray(contract.signaturesHolder.signatures)
      && contract.signaturesHolder.signatures.find(({ userAccess, signedDate }) => !signedDate && userAccess === user.userID),
    [contract.signaturesHolder, user.userID]
  )


  //  Get the signature stamps
  const signatures = useMemo(() => getSignatureStampsInfo(template, contract, (contract && contract.signaturesHolder)), [template, contract])
  if (signatures.length > 0) {
    return (
      <div ref={ref} className='signatureStampList' id={`${idPrefix}signatures`}>
        {signatures.map((signatureInfo, index) => (
          <SignatureStamp
            key={index}
            signatureInfo={signatureInfo}
            userSignature={userSignature}
          />
        ))}
      </div>
    )
  }

  return null
}

export default SignatureStampList
