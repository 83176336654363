import React, { useState, useMemo } from 'react'

import { useLocalDoc } from '@top-legal/datastore'

export const defaultUser = {
  userID: '__GUEST__'
}
export const defaultCounterparty = {
  userID: '__COUNTERPARTY__'
}
export const defaultCompany = {
  organisationID: '__GUEST_COMPANY__',
  members: { [defaultUser.userID]: { organisationRole: {} } },
  teams: {}
}

export const UserAndCompanyDataContext = React.createContext<{ user: any, company: any }>({
  user: defaultUser, company: defaultCompany
})

export const UserAndCompanyDataContextProvider = ({ children }) => {
  const [user = defaultUser, setUser] = useLocalDoc('user')
  const [company_ = defaultCompany, setCompany] = useLocalDoc('company')
  const [currentDate] = useState(new Date().toISOString())

  const company = useMemo(() => ({
    ...company_,
    members: (() => {
      if (company_.members) {
        Object.values(company_.members).forEach((item: any) => {
          delete company_.members[item.aliasFor]
        })
      }
      return company_.members
    })()
  }), [company_])

  return (
    <UserAndCompanyDataContext.Provider
      value={{
        user, company, setUser, setCompany, currentDate
      } as any}
    >
      {children}
    </UserAndCompanyDataContext.Provider>
  )
}

