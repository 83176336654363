import { DISPLAY_RESET_PASSWORD } from './loginActions'

const initialState = () => ({
  displayResetPassword: false
})

const REDUCER_ACTIONS = {
  [DISPLAY_RESET_PASSWORD]: (state, payload) => {
    state.displayResetPassword = payload
  }
}

export default function (state = initialState(), action) {
  state = { ...state }
  const handler = REDUCER_ACTIONS[action.type]
  if (handler) {
    state = handler(state, action.payload) || state
  }
  return state
}

