/* eslint-disable no-await-in-loop,no-console,no-restricted-syntax */
import { RxCollection, RxDatabase } from 'rxdb/plugins/core'
import GraphQLClient from 'graphql-client'
import Auth from '@aws-amplify/auth'

import { templatesGQLQuery, templatesSectionsGQLQuery } from './Schema/Templates'
import { contractsGQLQuery } from './Schema/Contracts'
import { partiesGQLQuery } from './Schema/ContractingParties'
import { inputsGQLQuery } from './Schema/InputFields'
import { condsGQLQUery } from './Schema/ConditionalTexts'
import { sectionDataGQLQuery } from './Schema/Sections'
import { delay, FetchFunc, handleAWSJSONProps, handleStrOrNumber } from './Utils'

import DatabaseWriters from './DatabaseWriters'


const defaultDateUpdated = new Date(0).toISOString()

const pullInterval = 30_000

class CustomReplication {
  //  Warning do not accept spaces nor caps /^[a-z0-9\-]+$/ are safe
  //  Increment the version to force pull everything again
  static readonly REPLICATION_KEY = 'tl-rep-v2'

  //  Batch writing
  private batchSize: { [key: string]: number } = {}

  //  Add events data
  private dispatchEvents (instanceType: string, { items, nextToken }: any = {}) {
    if (nextToken) {
      console.warn('Events has a nextToken that is not handled')
    }
    if (Array.isArray(items)) {
      items.forEach(item => {
        handleAWSJSONProps(item, ['user', 'thirdParty'])
        item.id = `${item.eventID}-${item.date}`
        this.database.databaseWriters.dispatchItem(`${instanceType}-events`, item)
      })
    }
  }

  //  Add comments data
  private dispatchComments (instanceType: string, { items, nextToken }: any = {}) {
    if (nextToken) {
      console.warn('Comments has a nextToken that is not handled')
    }
    if (Array.isArray(items)) {
      items.forEach(item => {
        handleAWSJSONProps(item, ['delta'])
        item.id = `${item.instanceID}-${item.date}`
        this.database.databaseWriters.dispatchItem(`${instanceType}-comments`, item)
      })
    }
  }


  constructor (
    public readonly database: RxDatabase & { databaseWriters: DatabaseWriters },
    public readonly collections: { [key: string]: RxCollection },
    public readonly gqlEndpointUrl: string
  ) {
    this.key = `${CustomReplication.REPLICATION_KEY}-${this.database.name}`
    Object.keys(this.collections).forEach(key => { this.batchSize[key] = 20 })
    this.batchSize.sectionSizeMax = 200
  }

  async query (query: string, variables?: any): Promise<any> {
    const currentSession = await Auth.currentAuthenticatedUser()
    return GraphQLClient({
      url: this.gqlEndpointUrl,
      headers: {
        Authorization: currentSession.signInUserSession.idToken.jwtToken
      }
    })
      .query(query, variables)
      .then(data => {
        if (Array.isArray(data?.errors)) {
          const { errors, highlightQuery: _, ...other } = data
          console.error('Failed to query GQL', { query, variables }, errors)

          const keys = Object.keys(other)
          if (keys.length === 0 || (keys.length === 1 && other.data == null)) {
            throw new Error(errors[0]?.message || 'GQL fetching issue')
          }
        }
        return data
      })
  }

  async queryRemote (lastSyncDate): Promise<boolean> {
    let fetchedFine = true

    this.batchSize.sectionSize = 0

    //  Wait all queries
    ;(
      await Promise.allSettled([
        this.queryParties(lastSyncDate),
        this.queryFields(lastSyncDate),
        this.queryConditionals(lastSyncDate),
        this.queryTemplates(lastSyncDate),
        this.queryTemplateContent(lastSyncDate),
        this.queryContracts(lastSyncDate)
      ])
    ).forEach(res => {
      if (res.status === 'rejected') {
        fetchedFine = false
      }
    })

    //  Write remaining
    ;(await Promise.allSettled(
      Object.keys(this.collections).map(key => this.database.databaseWriters.dispatchWrite(key))
    )).forEach(res => {
      if (res.status === 'rejected') {
        fetchedFine = false
      }
    })

    //  Set it is not the first replication anymore
    if (fetchedFine) {
      (window as any).isFirstReplication = false
    }

    return fetchedFine
  }

  queryTemplateContent = this.withLastElementHandler('template-content', async (lastSyncDate, lastElement ) => {
    const [headerLast, sectionsLast, footerLast, annexesLast] = lastElement as any || ['', '', '', '']
    const done = '__DONE__'
    const next = ['', '', '', '']

    let error = false
    await Promise.allSettled([
      (async () => {
        try {
          next[0] = (await this.queryHeader(lastSyncDate, headerLast)) || done
        } catch {
          next[0] = headerLast
          error = true
        }
        try {
          next[3] = (await this.queryAnnexes(lastSyncDate, annexesLast)) || done
        } catch {
          next[3] = annexesLast
          error = true
        }
      })(),
      (async () => {
        try {
          next[1] = (await this.querySections(lastSyncDate, sectionsLast)) || done
        } catch {
          next[1] = sectionsLast
          error = true
        }
        try {
          next[2] = (await this.queryFooter(lastSyncDate, footerLast)) || done
        } catch {
          next[2] = footerLast
          error = true
        }
      })(),
    ])

    if (error) {
      throw new Error('Cannot load template content')
    }

    if (next.some(elm => elm !== done)) {
      return next as any
    }
    return ''
  })

  queryHeader = async (lastSyncDate, lastElement = '') => this.queryTemplatesSectionsData(lastSyncDate, 'headerSection', sectionDataGQLQuery, lastElement)

  querySections = async (lastSyncDate, lastElement = '') => this.queryTemplatesSectionsData(lastSyncDate, 'sections', undefined, lastElement)

  queryFooter = async (lastSyncDate, lastElement = '') => this.queryTemplatesSectionsData(lastSyncDate, 'footerSection', sectionDataGQLQuery, lastElement)

  queryAnnexes = async (lastSyncDate, lastElement = '') => this.queryTemplatesSectionsData(lastSyncDate, 'annexes', undefined, lastElement)

  async awaitTemplatesWriting () {
    await Promise.allSettled([
      this.database.databaseWriters.dispatchWrite('templates'),
      this.database.databaseWriters.dispatchWrite('templates-events'),
      this.database.databaseWriters.dispatchWrite('templates-comments')
    ])
  }

  queryTemplates = this.withLastElementHandler('templates', async (lastSyncDate, lastElement = '') => {
    const result = await this.query(templatesGQLQuery(lastSyncDate, lastElement))

    //  Add contracts data
    if (result?.data?.Get?.templates) {
      const { items, nextToken } = result?.data?.Get?.templates || {}

      if (Array.isArray(items)) {
        let batchSize = 0
        for (const { events, comments, ...template } of items) {
          batchSize += 1

          //  Default dateUpdate if not set
          if (!template.dateUpdated) {
            template.dateUpdated = defaultDateUpdated
          }

          //  The template
          handleAWSJSONProps(template, ['roles', 'header', 'footer', 'parties', 'fields', 'conditionalTexts', 'obfuscationMapping'])
          this.database.databaseWriters.dispatchItem('templates', template)

          //  The events & comments
          this.dispatchEvents('templates', events)
          this.dispatchComments('templates', comments)


          //  Release the first batch
          if (batchSize === this.batchSize.templates) {
            batchSize = 0
            if (this.batchSize.templates === 20) {
              this.batchSize.templates = 100
            } else {
              this.batchSize.templates = 200
            }
            await this.awaitTemplatesWriting()
            await delay(1000)
          }
        }
      }

      await this.awaitTemplatesWriting()

      return nextToken
    }
    return ''
  })

  handleSections_ (array) {
    if (Array.isArray(array)) {
      array.forEach(sectionRef => {
        //  Guard for null or undefined
        if (sectionRef) {
          this.batchSize.sectionSize += 1
          if (sectionRef.data) {
            handleAWSJSONProps(sectionRef.data, ['explanationText', 'explanationTextExternal'])
            handleStrOrNumber(sectionRef.data, ['inferiorBorder', 'superiorBorder'])
            this.database.databaseWriters.dispatchItem('sections', sectionRef.data)
            delete sectionRef.data
          }
          this.handleSections_(sectionRef.subSections)
        }
      })
    }
  }

  sectionPromise: Promise<any> | undefined = undefined

  async handleSections (array) {
    //  Could be one section of array of
    if (!Array.isArray(array) && Object(array) === array) {
      array = [{ data: array }]
    }

    if (this.batchSize.sectionSize > this.batchSize.sectionSizeMax) {
      this.batchSize.sectionSizeMax = 1000
      //  Thread safe expression for setting only one promise and wait all the same
      await ((!this.sectionPromise && (this.sectionPromise = this.database.databaseWriters.dispatchWrite('sections'))) || this.sectionPromise)
      await delay(1000)

      this.batchSize.sectionSize = 0
      this.sectionPromise = undefined
    }
    this.handleSections_(array)
  }

  async queryTemplatesSectionsData (
    lastSyncDate,
    attributeName: Parameters<typeof templatesSectionsGQLQuery>[2],
    data?: Parameters<typeof templatesSectionsGQLQuery>[3],
    lastElement = ''
  ) {
    const result = await this.query(templatesSectionsGQLQuery(lastSyncDate, lastElement, attributeName, data))

    //  Add contracts data
    if (result?.data?.Get?.templates) {
      const { items, nextToken } = result?.data?.Get?.templates || {}

      if (Array.isArray(items)) {
        for (const template of items) {
          await this.handleSections(template[attributeName])
        }
      }

      return nextToken
    }
    return ''
  }

  async awaitContractsWriting () {
    await Promise.allSettled([
      this.database.databaseWriters.dispatchWrite('contracts'),
      this.database.databaseWriters.dispatchWrite('contracts-events'),
      this.database.databaseWriters.dispatchWrite('contracts-comments'),
      this.database.databaseWriters.dispatchWrite('parties')
    ])
  }

  queryContracts = this.withLastElementHandler('contracts', async (lastSyncDate, lastElement = '') => {
    const result = await this.query(contractsGQLQuery(lastSyncDate, lastElement))

    //  Add contracts data
    if (result?.data?.Get?.contracts) {
      const { items, nextToken } = result?.data?.Get?.contracts || {}

      if (Array.isArray(items)) {
        let batchSize = 0
        for (const { events, comments, fieldsResponse: { items: values } = {} as any, allParties, ...contract } of items) {
          batchSize += 1
          //  Default dateUpdate if not set
          if (!contract.dateUpdated) {
            contract.dateUpdated = defaultDateUpdated
          }

          //  Build fields response
          contract.fieldsResponse = {}
          if (Array.isArray(values)) {
            values.forEach(item => {
              handleAWSJSONProps(item, ['value'])
              contract.fieldsResponse[item.inputFieldID] = item.value
            })
          }

          // The contract
          handleAWSJSONProps(contract, ['roles', 'beforeFillingParties', 'parties', 'externalEntities', 'publishHistory', 'signaturesHolder'])
          this.database.databaseWriters.dispatchItem('contracts', contract)

          //  the parties
          if (Array.isArray(allParties)) {
            allParties.forEach(party => this.database.databaseWriters.dispatchItem('parties', party))
          }

          //  The events & comments
          this.dispatchEvents('contracts', events)
          this.dispatchComments('contracts', comments)

          //  Release the first batch
          if (batchSize === this.batchSize.contracts) {
            batchSize = 0
            if (this.batchSize.contracts === 20) {
              this.batchSize.contracts = 100
            } else {
              this.batchSize.contracts = 200
            }
            await this.awaitContractsWriting()
            await delay(1000)
          }
        }
      }

      await this.awaitContractsWriting()

      return nextToken
    }
    return ''
  })

  queryParties = this.withLastElementHandler('parties', async (lastSyncDate, lastElement = '') => {
    const result = await this.query(`{
        Get {
          parties(lastUpdatedAt: "${lastSyncDate}", nextToken: "${lastElement}") {
            items ${partiesGQLQuery}
            nextToken
          }
        }
      }`)

    //  Add contracts data
    if (result?.data?.Get?.parties) {
      const { items, nextToken } = result?.data?.Get?.parties || {}

      if (Array.isArray(items)) {
        items.forEach(item => this.database.databaseWriters.dispatchItem('parties', item))
      }

      await this.database.databaseWriters.dispatchWrite('parties')

      return nextToken
    }
    return ''
  })

  queryFields = this.withLastElementHandler('fields', async (lastSyncDate, lastElement = '', force = false) => {
    if ((this as any).gotFields && !force) {
      return ''
    }

    const result = await this.query(inputsGQLQuery(lastSyncDate, lastElement))
    ;(this as any).gotFields = true

    //  Add contracts data
    if (result?.data?.Get?.inputFields) {
      const { items, nextToken } = result?.data?.Get?.inputFields || {}

      if (Array.isArray(items)) {
        items.forEach(item => {
          handleAWSJSONProps(item, ['explanationText', 'values', 'formattedText', 'pricingTiers'])
          this.database.databaseWriters.dispatchItem('input_fields', item)
        })
      }

      await this.database.databaseWriters.dispatchWrite('input_fields')

      return nextToken
    }
    return ''
  }, true)

  queryConditionals = this.withLastElementHandler('conds', async (lastSyncDate, lastElement = '', force = false) => {
    if ((this as any).gotConds && !force) {
      return ''
    }

    const result = await this.query(condsGQLQUery(lastSyncDate, lastElement))
    ;(this as any).gotConds = true

    //  Add contracts data
    if (result?.data?.Get?.conditionalTexts) {
      const { items, nextToken } = result?.data?.Get?.conditionalTexts || {}

      if (Array.isArray(items)) {
        items.forEach(item => {
          handleAWSJSONProps(item, ['values'])
          this.database.databaseWriters.dispatchItem('conditional_texts', item)
        })
      }

      await this.database.databaseWriters.dispatchWrite('conditional_texts')

      return nextToken
    }
    return ''
  }, true)

  //  Copied from the fork, since the fork has bigger issues, just use the original with some copy pasta
  private nextSyncDate = ''
  private key = ''

  async readMeta (attrName: string) {
    try {
      let prev = (await this.database.getLocal(this.key))?.toJSON()
      if (!prev) {
        prev = {[attrName]: window.localStorage.getItem(`${this.key}${attrName}`)}
      }
      return prev?.[attrName] || ''
    } catch (err) {
      console.error('[Datastore] ReadMeta error', err)
      throw err
    }
  }

  async saveMeta (attrName: string, value: string) {
    try {
      const prev = (await this.database.getLocal(this.key))?.toJSON() || {}
      prev[attrName] = value
      await this.database.upsertLocal(this.key, prev)
    } catch (err) {
      console.error('[Datastore] SaveMeta error', err)
      throw err
    }
  }

  async lastSync (updateLastSync = false) {
    try {
      if (updateLastSync) {
        this.nextSyncDate = new Date(Date.now() - 5_000).toISOString()
        const prev = (await this.database.getLocal(this.key))?.toJSON() || {}
        prev.nextSync = this.nextSyncDate
        await this.database.upsertLocal(this.key, prev)
        return prev.sync || ''
      }

      return (await this.readMeta('sync')) || ''
    } catch (err) {
      console.error('[Datastore] LastSync error', err)
      throw err
    }
  }

  async saveSync () {
    try {
      const prev = (await this.database.getLocal(this.key))?.toJSON() || {}
      prev.sync = prev.nextSync || this.nextSyncDate
      await this.database.upsertLocal(this.key, prev)
    } catch (err) {
      console.error('[Datastore] SaveSync error', err)
      throw err
    }
  }

  withLastElementHandler (fID: string, func: FetchFunc, reset = false): FetchFunc {
    const recursiveFunc: FetchFunc = async (lastSyncDate: string, lastElement?: string, options?: any) => {
      const nextToken = await func(lastSyncDate, lastElement, options)

      //  If we are a update only
      if (nextToken) {
        await this.saveMeta(fID, nextToken)
        await delay(1000)
        return recursiveFunc(lastSyncDate, nextToken, options)
      }

      return ''
    }

    const finalFunc: FetchFunc = async (lastSyncDate: string, lastElement?: string, options?: any) => {
      //  Guard that we are syncing the same date
      const currSync = await this.readMeta(`${fID}-sync`)
      let currLastElement = ''

      if (currSync !== lastSyncDate || reset) {
        await this.saveMeta(fID, '') // Delete currLastElement because we are not syncing the same stuff
        await this.saveMeta(`${fID}-sync`, lastSyncDate)
      } else {
        currLastElement = await this.readMeta(fID)
      }

      //  Guard that we are not syncing the pages we already got
      if (currLastElement !== '__DONE__') {
        await recursiveFunc(lastSyncDate, lastElement || currLastElement, options)
      }

      //  Save that this is done then we don't do the same thing again
      await this.saveMeta(fID, '__DONE__')
      return ''
    }

    return finalFunc
  }

  async startReplication () {
    (window as any).isFirstReplication = !await this.lastSync()
    if (this.database.multiInstance) {
      await this.database.waitForLeadership()
    }
    console.info('[Datastore] Replication started')

    //  Run long pulling
    ;(async () => {
      let syncIsFine = true
      while (!this.database.destroyed) {
        await this.lastSync(syncIsFine)
          .then(lastSync => this.queryRemote(lastSync))
          .then(isFine => {
            syncIsFine = isFine
            if (isFine) {
              this.saveSync()
            }
          })
          .then(() => delay(pullInterval))
          .catch(err => {
            console.error('[Datastore] Replication error', err)
            syncIsFine = false
          })
      }
    })().finally(() => console.info('[Datastore] Replication stopped'))
  }
}

export default CustomReplication
