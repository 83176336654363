import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { Trans } from '@lingui/macro'
import { useSelector } from 'react-redux'
import { Button, notification } from 'antd'
import { AppstoreAddOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { InstanceRenderingProps, UseSearchReturn } from '../Types'
import { templateInputsType } from '../../Template/TemplateHelperFunctions'
import { TemplateContext } from '../../Contract/ContractEditor/Contexts'

import './InputFieldSearchInstanceStyles.scss'
import { hasKeyWordsFactory, slateContentHasKeywords } from '../../Listing/SearchHelpers'

const isTemplateMetaDataSearch = false
const InputFieldSearchInstance: React.FC<InstanceRenderingProps & { createField?: any }> = ({
  instance, createField, Highlight
}) => {
  const [loading, setLoading] = useState(false)
  const { template, updateFields, inputFields } = useContext<any>(TemplateContext)
  const isImportedInTemplate = template && template.fields && template.fields[instance.inputFieldID]

  return (
    <div className='instanceRendering fieldInstance'>
      <div className='instanceData'>
        <table>
          <tr>
            <td><h4><Trans>Field name: </Trans></h4></td>
            <td><h4><span className='inputField'><Highlight text={instance.name} /></span></h4></td>
          </tr>
          <tr>
            <td><Trans>Type: </Trans></td>
            <td>{templateInputsType[instance.type]}</td>
          </tr>
          <tr>
            <td><Trans>Question: </Trans></td>
            <td><Highlight text={instance.question} /></td>
          </tr>
          {instance.unit && (
            <tr>
              <td><Trans>Unit: </Trans></td>
              <td><Highlight text={instance.unit} /></td>
            </tr>
          )}
        </table>
      </div>
      {isTemplateMetaDataSearch && (
        <div className='instanceExtra'>
          <Button.Group className='actionButtons'>

            <Button
              className='importName'
              ghost={!!isImportedInTemplate}
              icon={isImportedInTemplate ? <CheckCircleOutlined /> : <AppstoreAddOutlined />}
              loading={loading}
              onClick={async () => {
                if (isImportedInTemplate) {
                  notification.info({
                    key: 'fieldImported',
                    message: <Trans>Field already exists</Trans>,
                    description: <Trans>The selected field is already imported in the template.</Trans>,
                    duration: 1
                  })
                } else {
                  setLoading(true)
                  await updateFields(instance.inputFieldID, instance.version)
                  setLoading(false)
                }
              }}
              type='primary'
            >
              {isImportedInTemplate && <Trans>Imported</Trans>}
              {!isImportedInTemplate && <Trans>Import</Trans>}

            </Button>
            <Button
              className='editField'
              icon={isImportedInTemplate ? <CheckCircleOutlined /> : <AppstoreAddOutlined />}
              loading={loading}
              onClick={async () => createField(inputFields[instance.inputFieldID][instance.version || 1])}
              type='default'
            >
              <Trans>Edit</Trans>
            </Button>
          </Button.Group>
          <h4><Trans>Version: {`${instance.version}.0`}</Trans></h4>
        </div>
      )}
    </div>

  )
}


const useInputFieldSearchInstance = (): UseSearchReturn => {
  const templateContext = useContext<any>(TemplateContext)
  const fieldsRedux = useSelector(state => state.organisation.inputFields)
  const conditionalTexts = useSelector(state => state.organisation.conditionalTexts)

  const fields = useMemo(
    () => Object.keys(fieldsRedux).map(key => {
      const lastVersion = Math.max(...Object.keys(fieldsRedux[key]).map(version => parseInt(version, 10)))
      return fieldsRedux[key] && fieldsRedux[key][lastVersion]
    }).filter(elm => elm),
    [fieldsRedux]
  )

  const ref = useRef<any>()
  ref.current = fields

  const filterInstances = useCallback((search: string) => {
    if (!Array.isArray(ref.current) || ref.current.length === 0) {
      return []
    }
    const split = search.toLowerCase().trim().split(' ')
    if (split.length === 1 && !split[0]) {
      return [...ref.current]
    }

    return ref.current.filter(field => {
      const hasKeyWords = hasKeyWordsFactory([...split])

      if (
        hasKeyWords(field.name) || hasKeyWords(field.question)
        || hasKeyWords(field.type) || hasKeyWords(field.unit)
      ) {
        return true
      }

      if (Array.isArray(field.formattedText)) {
        return slateContentHasKeywords(field.formattedText, hasKeyWords)
      }

      return false
    })
  }, [])

  return useMemo<UseSearchReturn>(() => ({
    category: 'inputFields',
    filterInstances,
    InstanceRendering: props => (
      <TemplateContext.Provider
        value={
          templateContext.template
            ? templateContext
            : { template: { fields: {}, conditionalTexts: {} }, inputFields: fieldsRedux, conditionalTexts }
        }
      >
        <InputFieldSearchInstance {...props} />
      </TemplateContext.Provider>
    ),
    getID: instance => `${instance.inputFieldID}_${instance.version}`
  }), [conditionalTexts, fieldsRedux, filterInstances, templateContext])
}

export default useInputFieldSearchInstance
