import React from 'react'
import { t, Trans } from '@lingui/macro'
import { Col, Form, Input, Row } from 'antd'
import { requiredRules } from '../../SharedComponents/CustomFormWrapper'
import { SwitchInput } from '../../SharedComponents/FormComponents'
import { TemplateSettingsChildProps } from '../TemplatePage'

const StructureSettings: React.FC<TemplateSettingsChildProps> = ({ template }) => (
  <Row className='subSectionHeaderWrapper' gutter={16}>
    <Col span={12}>
      <fieldset>
        <label><h3><Trans>Naming convention</Trans></h3></label>
        <Form.Item
          initialValue={template.annexesTitle || t`Appendix`}
          label={<Trans>Appendix title</Trans>}
          labelCol={{ span: 10 }}
          name='annexesTitle'
          rules={requiredRules}
        >
          <Input className='simple-line' placeholder={t`Appendix title`} />
        </Form.Item>
        <Form.Item
          initialValue={template.sectionPrefix}
          label={<Trans>Section prefix</Trans>}
          labelCol={{ span: 10 }}
          name='sectionPrefix'
        >
          <Input className='simple-line' placeholder={t`Section prefix`} />
        </Form.Item>
        <Form.Item
          initialValue={template.annexePrefix}
          label={<Trans>Annexe prefix</Trans>}
          labelCol={{ span: 10 }}
          name='annexePrefix'
        >
          <Input className='simple-line' placeholder={t`Annexe prefix`} />
        </Form.Item>
      </fieldset>
      <fieldset>
        <label><h3><Trans>Sub-section display</Trans></h3></label>
        <Form.Item
          className='showSubsectionHeaderSwitch'
          id='showSubsectionHeaderSwitch'
          initialValue={template.showSubsections != null ? template.showSubsections : true}
          name='showSubsections'
        >
          <SwitchInput placeholder={t`Display subsection headers? `} />
        </Form.Item>
      </fieldset>
    </Col>
    <Col className='subSectionPreviewBox' span={12}>
      <div>
        <Form.Item shouldUpdate={true}>
          {form => {
            const value = field => (form.getFieldValue(field) && `${form.getFieldValue(field)} `) || ''
            const langsTrans = {
              en: {
                docT: 'Document title',
                secT: 'Section title',
                secP: 'Section text',
                subT: 'Subsection title',
                subP: 'Subsection text',
                annT: 'Annexe title'
              },
              de: {
                docT: 'Titel des Dokuments',
                secT: 'Titel des Paragraphen',
                secP: 'Text des Paragraphen',
                subT: 'Titel des Unterparagraphen',
                subP: 'Text des Unterparagraphen',
                annT: 'Titel des Anhangs'
              }
            }
            const trans = key => (langsTrans[form.getFieldValue('lang')] || langsTrans.en)[key] || ''
            /**
               * depending on the choice of the user, display a subsection header, or start
               * directly with the paragraph.
               */
            if (form.getFieldValue('showSubsections')) {
              return (
                <div className='previewText'>
                  <h1>{trans('docT')}</h1>
                  <h3>{value('sectionPrefix')}1. {trans('secT')}</h3>
                  <p className='subSectionParagraph'>{trans('secP')}</p>
                  <div style={{ paddingLeft: '1rem' }}>
                    <h4>1.1. {trans('subT')}</h4>
                    <p className='subSectionParagraph'>{trans('subP')}</p>
                  </div>
                  <h1>&nbsp;{value('annexesTitle')}&nbsp;</h1>
                  <h3>{value('annexePrefix')}1. {trans('annT')}</h3>
                </div>
              )
            }
            return (
              <div className='previewText'>
                <h1>{trans('docT')}</h1>
                <h3>{value('sectionPrefix')}1. {trans('secT')}</h3>
                <p className='subSectionParagraph'>{trans('secP')}</p>
                <div style={{ paddingLeft: '1rem' }}>
                  <p className='subSectionParagraph'><b>1.1.</b> {trans('subP')}</p>
                </div>
                <h1>&nbsp;{value('annexesTitle')}&nbsp;</h1>
                <h3>{value('annexePrefix')}1. {trans('annT')}</h3>
              </div>
            )
          }}
        </Form.Item>
      </div>
    </Col>
  </Row>
)

export default StructureSettings
