import React from 'react'
import axios from 'axios'
import Auth from '@aws-amplify/auth'
import * as Sentry from '@sentry/browser'
import { message } from 'antd'

import { getHistory } from './BrowserHistory'

// ==================================================================================================

const CANCELED = 'ECONNABORTED'
export let cancelToken = axios.CancelToken.source()
let canceling = false

// ==================================================================================================

const RestService = (method, path, data, needToBeAuthenticated = true, hide404Message) => {
  //  Skip request when canceling it during the user login out
  if (canceling) {
    return Promise.resolve([])
  }

  // Transforming the method to uppercase to avoid errors
  method = method.toUpperCase()

  //  Create an error there to capture the right stack trace
  const restServiceError = new Error('FE RestService error')

  //  Capture error to sentry with more context
  const captureError = err => {
    if (process.env.NODE_ENV === 'development') {
      console.error('[RestService] Error', {
        method, path, data, needToBeAuthenticated, hide404Message
      }, err)
      console.error(restServiceError.stack)
      const key = (new Date()).toISOString()
      message.error({
        className: 'restServiceError',
        content: (
          <span>
            [DEV ONLY] RestService error - {(err && err.message) || 'Check the console'}
            {err && err.response && err.response.data && err.response.data.message && <><br />{err.response.data.message}</>}
          </span>
        ),
        duration: 0,
        key,
        onClick: () => message.destroy(key)
      })
    } else {
      //  Send the error to sentry
      Sentry.withScope(scope => {
        let dataStr = ''
        try {
          dataStr = JSON.stringify(data, null, 2)
        } catch {}

        //  Add some debugging data
        scope.setExtras({
          request: {
            method, path, data: dataStr, needToBeAuthenticated
          },
          response: err && err.response,
          responseData: err && err.response && err.response.data,
          error: err
        })

        //  Refine the error if possible
        if (err && err.response && err.response.data && err.response.data.message) {
          restServiceError.message = err.response.data.message
        } else if (err && err.message) {
          restServiceError.message = err.message
        }

        Sentry.captureException(restServiceError)
      })
    }
  }

  const assert = (condition, message) => {
    // checking if the condition is false
    if (!condition) {
      const err = new Error(message)
      captureError(err)
      throw err
    }
  }

  //  Checking if a valid attribute is used.
  assert(['GET', 'POST', 'PUT', 'DELETE'].includes(method), 'Invalid request method')

  // checking if the path contains a valid string and starts with a slash
  assert(typeof path === 'string' && path.startsWith('/'), 'Invalid path. Path needs to be a string like /app/something')

  return new Promise(resolve => {
    //  checking if the backend request requires an authentication
    if (needToBeAuthenticated) {
      // resolves for the authorisation token contained in an object, if the authorisation is required
      resolve(RestService.getCurrentCognitoSession()
        .then(currentSession => ({
          // returns an object with the authorisation token contain
          Authorization: currentSession.signInUserSession.idToken.jwtToken
        })))
    } else {
      //  If not needed just resolve a empty object
      resolve({})
    }
  }).then(userAuth => axios({ // once the user has been authorised, the Authorisation header containing the tokein is passed
    url: process.env.REST_API_URL + path,
    method,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      ...userAuth
    },
    cancelToken: cancelToken.token,
    ...(data ? { data } : {})
  })).then(res => {
    //  We get a response from backend if it's good return data if not reject it
    if (res.status === 200) {
      return res.data
    }
    return Promise.reject(res)
  }).catch(async err => {
    //  Skip aborted errors
    if (err.code === CANCELED || err.message === CANCELED) {
      return []
    }

    console.error('Request error -', err)
    captureError(err)

    // if an error is encountered
    if (err.response && err.response.status) {
      if (err.response.status === 404) {
        // if we don't skip not found error, display it to the user
        if (hide404Message !== undefined) {
          return Promise.resolve(hide404Message)
        }
      }
    }

    //  Don't forget to return a rejection or we fall in then() function
    throw err
  })
}

RestService.upload = (url, data) => axios({
  url,
  method: 'PUT',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json'
  },
  cancelToken: cancelToken.token,
  ...(data ? { data } : {})
})
  .then(res => {
    //  We get a response from backend if it's good return data if not reject it
    if (res.status === 200) {
      return res.data
    }
    return Promise.reject(res)
  })
  .catch(async err => {
    console.error('RestService.upload error -', err)
    throw err
  })

RestService.download = url => axios({
  url,
  method: 'GET',
  responseType: 'json',
  cancelToken: cancelToken.token
})
  .then(res => {
    //  We get a response from backend if it's good return data if not reject it
    if (res.status === 200) {
      return res.data
    }
    return Promise.reject(res)
  })
  .catch(async err => {
    console.error('RestService.download error -', err)
    throw err
  })

// ==================================================================================================

let prevPromise = Promise.reject('GetCurrentUser start') // eslint-disable-line
RestService.getCurrentCognitoSession = (redirect = true) => {
  if (canceling) {
    // eslint-disable-next-line no-throw-literal
    throw { code: CANCELED }
  }

  let waitingPromise
  // eslint-disable-next-line
  return (waitingPromise = prevPromise) // eslint-disable-next-line
    && (prevPromise = new Promise((resolve) => waitingPromise.finally(() => resolve(Auth.currentAuthenticatedUser())))
      .catch(authErr => {
        //  Or reject with the error
        if (redirect && !getHistory().location.search.includes('next=') && !getHistory().location.pathname.includes('/login')) {
          console.error('Redirect to login -', authErr)

          // user is redirected to the login page if the user cannot be authorised
          getHistory().push(`/${getHistory().location.pathname.split('/')[1]}/login?next=${btoa(getHistory().location.pathname + getHistory().location.search + getHistory().location.hash)
          }`)
        }
        throw authErr
      }))
}

// ==================================================================================================

RestService.cancelNetwork = () => {
  canceling = true
  cancelToken.cancel(CANCELED)

  //  Wait 1sec that everything is canceled and activate back
  setTimeout(() => {
    cancelToken = axios.CancelToken.source()
    canceling = false
  }, 1000)
}

export default RestService
