import React, { useCallback, useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { Button, Drawer, Form } from 'antd'
import CustomFormWrapper, { requiredRules } from '../SharedComponents/CustomFormWrapper'
import { entityEndorsementAction } from './TempEntityEndorsementBar'
import InputUsersAndTeams from '../SharedComponents/FormComponents/InputUsersAndTeams'

const WrappedInputUsersAndTeams: React.FC<any> = ({ value, onChange }) => (
  <InputUsersAndTeams
    autoFocus
    onChange={useCallback(([, itemA, itemB]) => onChange(itemA === value ? itemB : itemA), [onChange, value])}
    placeholder={<Trans>Select someone</Trans>}
    value={useMemo(() => {
      const arr = ['__Admin']
      if (value) {
        arr.push(value)
      }
      return arr
    }, [value])}
  />
)

const TempEntityEndorsementDrawer: React.FC<{ open: boolean, onClose: () => void }> = ({ open, onClose }) => (
  <Drawer
    className='entityEndorsementDrawer'
    onClose={onClose}
    placement='right'
    title={<Trans>Test your users rights</Trans>}
    visible={open}
  >
    <h2><Trans>Select a team or person whose user rights you want to simulate.</Trans></h2>
    <CustomFormWrapper onSubmit={({ entityID }) => entityEndorsementAction(entityID)}>
      <Form.Item
        name='entityID'
        rules={requiredRules}
      >
        <WrappedInputUsersAndTeams />
      </Form.Item>
      <Button block htmlType='submit' type='primary'>
        <Trans>Start</Trans>
      </Button>
    </CustomFormWrapper>
  </Drawer>
)

export default TempEntityEndorsementDrawer
