import React, { useMemo } from 'react'
import { t, Trans } from '@lingui/macro'
import { Card, Drawer, Form, Input } from 'antd'
import { BankOutlined } from '@ant-design/icons'
//  Components
import { GoogleAutoCompleteAddress } from '../../../SharedComponents/FormComponents'
import { requiredRules } from '../../../SharedComponents/CustomFormWrapper'
import { CreateFormProps, FormWrapper } from './CreateContactForm'
import { OwningTeamFormItem } from '../../../ContactList/ContactList'


// ==================================================================================================


const GoogleAutoCompleteAddressTSX = GoogleAutoCompleteAddress as any


// =================================================================================================


const CreateCompanyForm : React.FC <CreateFormProps> = ({
  initialValue: initialValue_,
  visible,
  onClose,
  onFinish
}) => {
  //  Safe guard of RxDoc
  const initialValue = useMemo(() => initialValue_ && ((initialValue_.toJSON && initialValue_.toJSON()) || initialValue_), [initialValue_])

  return (
    <Drawer
      className='drawerContact'
      destroyOnClose
      footer={null}
      maskClosable={false}
      onClose={onClose}
      // Create a new contact on top.legal.
      title={<><BankOutlined className='headerIcon' twoToneColor='#3DBD7D' />{initialValue ? (<Trans>Edit company</Trans>) : (<Trans>New company</Trans>)}</>}
      visible={visible}
      zIndex={10010}
    >
      <FormWrapper initialValue={initialValue} onFinish={onFinish}>
        {form => (
          <Card title={initialValue ? (<Trans>Edit company</Trans>) : (<Trans>New company</Trans>)}>
            <Form.Item hidden initialValue='company' name='type'>
              <Input type='hidden' />
            </Form.Item>
            <Form.Item name='name' rules={requiredRules}>
              <Input className='simple-line' placeholder={`${t`Company name`} *`} />
            </Form.Item>
            <Form.Item rules={requiredRules}>
              <GoogleAutoCompleteAddressTSX form={form} initialValue={initialValue?.address || {}} prefix='address' required />
            </Form.Item>
            <OwningTeamFormItem initialValue={initialValue} />
          </Card>
        )}
      </FormWrapper>
    </Drawer>
  )
}
export default CreateCompanyForm
