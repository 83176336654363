import React from 'react'
import { Trans } from '@lingui/macro'
import { AuditOutlined, CheckCircleFilled } from '@ant-design/icons'

const EmailSentToolboxComponent = () => (
  <div className='signingContent'>
    <div className='contractDraftedBox'>
      <h4 className='done'><CheckCircleFilled /> <Trans>Check your inbox</Trans></h4>
      <p><Trans>We have sent you an email with the document ready to sign.</Trans></p>
    </div>
  </div>
)

export default {
  key: 'emailSent',
  className: 'externalEmailSentToolbox',
  text: <Trans>Signing</Trans>,
  icon: <AuditOutlined />,
  Component: EmailSentToolboxComponent
}
