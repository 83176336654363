/* eslint-disable no-else-return */
import React, { useCallback, useContext, useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { Link, useParams } from 'react-router-dom'
import { Avatar, Empty, Spin, Timeline } from 'antd'
import { TeamOutlined } from '@ant-design/icons'
import moment from 'moment'
import { UserAndCompanyDataContext } from '../Layouts/Constants'
import { IdPrefixContext } from '../Contract/ContractDiplay/ContractLayout'
import { UserAvatarByID, UserFullName } from '../Organisations/UserRoleDisplay/UserAvatarList'
import InfiniteScrolling, { InfiniteScrollingSingleProps } from '../SharedComponents/ListingComponents/InfiniteScrolling'
import { CHILDID_CHAR_SEPARATOR } from '../Contract/ContractEditor/Sidebar/SidebarToolboxes/Commenting/Types'

import './ActivityLogsStyles.scss'

const avatarStyle = {
  height: '2.3rem',
  width: '2.3rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const roleObj = {
  Owner: <Trans>owner</Trans>,
  FullAccess: <Trans>full access</Trans>,
  Contributor: <Trans>contributor</Trans>,
  Reporter: <Trans>reporter</Trans>,
  Viewer: <Trans>viewer</Trans>
}

const genItem = ({
  eventText,
  event: {
    userID,
    date,
    key,
    style
  }
}) => (
  <Timeline.Item
    key={key}
    dot={userID ? (
      <UserAvatarByID
        autoSide
        size='2.3rem'
        userID={userID}
      />
    ) : (
      <Avatar
        className='intern'
        icon={<TeamOutlined />}
        style={avatarStyle}
      />
    )}
    style={style}
  >
    <div className='eventItem'>
      <div className='eventItemContent'>
        <div className='eventFirstRow'>{moment(new Date(date)).format('lll')}
        </div>
        <div className='eventSecondRow'>
          <p className='eventDescription'>{eventText}</p>
        </div>
      </div>
      <p className='eventTime'>{moment(new Date(date)).fromNow()}
      </p>
    </div>
  </Timeline.Item>
)

const withCommentLink = func => props => {
  const {
    childID,
    date
  } = props.event
  if (childID && date) {
    props.link = (
      <Link
        className='link'
        to={`${props.url}?sectionID=${childID.split(CHILDID_CHAR_SEPARATOR)[0]}&comment=${childID}${CHILDID_CHAR_SEPARATOR}${date}`}
      >
        {props.objectName}
      </Link>
    )
  }
  return func(props)
}


const Bold = ({ children }) => <span className='eventPrimaryText'>{children}</span>
const EVENTS_MAPPING = {
  /** ******************************************************************
   *              Contract signing events
   ****************************************************************** */
  requestSigning: ({
    event,
    userName,
    link
  }) => genItem({
    event,
    eventText: <Trans><Bold>{userName}</Bold> has <Bold>requested signing</Bold> {link}</Trans>
  }),
  contractInvite: ({
    event,
    userName,
    link,
    thirdParty
  }) => {
    const trans = {
      redlining: <Trans><Bold>{userName}</Bold> has <Bold>invited</Bold> <Bold>{thirdParty}</Bold> to {link} with <Bold>redlining</Bold> rights</Trans>,
      commenting: <Trans><Bold>{userName}</Bold> has <Bold>invited</Bold> <Bold>{thirdParty}</Bold> to {link} with <Bold>commenting</Bold> rights</Trans>,
      signing: <Trans><Bold>{userName}</Bold> has <Bold>invited</Bold> <Bold>{thirdParty}</Bold> to {link} with <Bold>signing</Bold> rights</Trans>
    }
    return genItem({
      event,
      eventText: trans[event.publicAccessType] || trans.signing
    })
  },
  externalInvited: ({
    event,
    userName,
    thirdParty,
    link
  }) => genItem({
    event,
    eventText: <Trans><Bold>{userName}</Bold> has invited <Bold>{thirdParty}</Bold> to <Bold>{link}</Bold></Trans>
  }),
  externalViewed: ({
    event,
    userName,
    link
  }) => genItem({
    event,
    eventText: <Trans><Bold>{userName}</Bold> opened <Bold>{link}</Bold></Trans>
  }),
  signatureInvite: ({
    event,
    userName,
    thirdParty,
    link
  }) => genItem({
    event,
    eventText: <Trans><Bold>{userName}</Bold> has <Bold>invited</Bold> <Bold>{thirdParty}</Bold> to sign {link}</Trans>
  }),
  signatureForward: ({
    event,
    userName,
    thirdParty,
    link
  }) => genItem({
    event,
    eventText: <Trans><Bold>{userName}</Bold> has <Bold>delegated</Bold> <Bold>{thirdParty}</Bold> to sign {link}</Trans>
  }),
  sign: ({
    event,
    userName,
    link
  }) => {
    const trans = {
      phone: <Trans><Bold>{userName}</Bold> has <Bold>signed</Bold> the contract {link} with mobile PIN via <Bold>{event.signature.phoneNumber}</Bold></Trans>,
      email: <Trans><Bold>{userName}</Bold> has <Bold>signed</Bold> the contract {link} with email PIN via <Bold>{event.signature.email}</Bold></Trans>,
      pinLess: <Trans><Bold>{userName}</Bold> has <Bold>signed</Bold> the contract {link}</Trans>
    }
    return genItem({
      event,
      eventText: trans[event.signingMethod || 'pinLess']
    })
  },
  signed: ({
    event,
    link
  }) => genItem({
    event,
    eventText: <Trans>Contract {link} has been <Bold>signed by all parties</Bold> via <Bold>{event.source || 'top.legal'}</Bold></Trans>
  }),
  contractFrozen: ({
    event,
    link
  }) => genItem({
    event,
    eventText: <Trans>Contract {link} has been <Bold>frozen</Bold> and move to <Bold>signing</Bold></Trans>
  }),
  contractUnfrozen: ({
    event,
    link
  }) => genItem({
    event,
    eventText: <Trans>Contract {link} has been <Bold>unfrozen</Bold> and moved to <Bold>editing</Bold></Trans>
  }),
  approve: ({
    event,
    link,
    userName
  }) => genItem({
    event,
    eventText: <Trans>Contract {link} has been <Bold>approved</Bold> by <Bold>{userName}</Bold></Trans>
  }),


  /** ******************************************************************
   *              User management events
   ****************************************************************** */
  // Dsitinguish between someone invited thirdparty and someine created an organisation => need to be updated backend.
  userAdded: ({
    event,
    userName,
    thirdParty,
    companyName,
    unknown
  }) => genItem({
    event,
    eventText: unknown
      ? <Trans><Bold>{userName}</Bold> has been <Bold>added</Bold> to {companyName}</Trans>
      : <Trans><Bold>{userName}</Bold> has <Bold>invited</Bold> <Bold>{thirdParty}</Bold> to {companyName}</Trans>
  }),
  userAddedToTeam: ({
    event,
    userName,
    thirdParty
  }) => genItem({
    event,
    eventText: (
      <Trans><Bold>{userName}</Bold> has <Bold>added</Bold> {thirdParty} to team {event.team}</Trans>
    )
  }),
  userAddedToInstance: ({
    event,
    userName,
    thirdParty,
    link
  }) => genItem({
    event,
    eventText: event.role === 'NoAccess'
      ? <Trans><Bold>{userName}</Bold> has <Bold>removed</Bold> <Bold>{thirdParty}</Bold> access rights from {link}</Trans>
      : <Trans><Bold>{userName}</Bold> has <Bold>added</Bold> <Bold>{thirdParty}</Bold> to {link} with <Trans>{roleObj[event.role]}</Trans> rights</Trans>
  }),


  /** ******************************************************************
   *              Instance listing events
   ****************************************************************** */
  upload: ({
    event,
    userName,
    link
  }) => genItem({
    event,
    eventText: <Trans><Bold>{userName}</Bold> has <Bold>uploaded</Bold> the contract {link}</Trans>
  }),
  duplicate: ({
    event,
    userName,
    link
  }) => genItem({
    event,
    eventText: <Trans><Bold>{userName}</Bold> has <Bold>duplicated</Bold> the template {link}</Trans>
  }),
  automate: ({
    event,
    userName,
    link
  }) => genItem({
    event,
    eventText: <Trans><Bold>{userName}</Bold> has <Bold>converted</Bold> a contract into the playbook {link}</Trans>
  }),


  /** ******************************************************************
   *              Instance editing events
   ****************************************************************** */
  create: ({
    event,
    isTpl,
    userName,
    link
  }) => genItem({
    event,
    eventText: isTpl
      ? <Trans><Bold>{userName}</Bold> has <Bold>created</Bold> the playbook {link}</Trans>
      : <Trans><Bold>{userName}</Bold> has <Bold>created</Bold> the contract {link}</Trans>
  }),
  update: ({
    event,
    isTpl,
    userName,
    link
  }) => genItem({
    event,
    eventText: isTpl
      ? <Trans><Bold>{userName}</Bold> has <Bold>updated</Bold> the playbook {link}</Trans>
      : <Trans><Bold>{userName}</Bold> has <Bold>updated</Bold> the contract {link}</Trans>
  }),
  delete: ({
    event,
    isTpl,
    userName
  }) => genItem({
    event,
    eventText: isTpl
      ? <Trans><Bold>{userName}</Bold> has <Bold>deleted</Bold> the playbook {event.name}</Trans>
      : <Trans><Bold>{userName}</Bold> has <Bold>deleted</Bold> the contract {event.name}</Trans>
  }),
  editSection: ({
    event,
    userName,
    prefix
  }) => genItem({
    event,
    eventText: (
      <Trans><Bold>{userName}</Bold> has <Bold>edited</Bold> section {(
        <Link
          onClick={() => {
            const sectionBox = document.getElementById(`${prefix}${event.childID || event.sectionID}`)
            if (sectionBox) {
              setTimeout(() => {
                sectionBox.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center'
                })
              }, 500)
            }
          }}
        >
          {event.sectionName}
        </Link>
      )}
      </Trans>
    )
  }),


  /** ******************************************************************
   *              Instance management events
   ****************************************************************** */
  comment: withCommentLink(({
    event,
    userName,
    link
  }) => genItem({
    event,
    eventText: <Trans><Bold>{userName}</Bold> has <Bold>commented</Bold> on {link}</Trans>
  })),
  feedback: withCommentLink(({
    event,
    userName,
    link
  }) => genItem({
    event,
    eventText: <Trans><Bold>{userName}</Bold> has given <Bold>feedback</Bold> on the playbook {link}</Trans>
  })),
  suggestion: withCommentLink(({
    event,
    userName,
    link
  }) => genItem({
    event,
    eventText: <Trans><Bold>{userName}</Bold> has <Bold>suggested</Bold> an edit in {link}</Trans>
  })),
  task: withCommentLink(({
    event,
    userName,
    thirdParty,
    link
  }) => genItem({
    event,
    eventText: (!event.thirdParty || event.userID === event.thirdParty)
      ? <Trans><Bold>{userName}</Bold> has created a <Bold>task</Bold> on {link}</Trans>
      : <Trans><Bold>{userName}</Bold> has assigned a <Bold>task</Bold> to <Bold>{thirdParty}</Bold> on {link}</Trans>
  })),
  reminder: ({
    event,
    userName,
    link
  }) => genItem({
    event,
    eventText: <Trans><Bold>{userName}</Bold> has <Bold>set</Bold> a reminder on {link}</Trans>
  }),
  extensionActivation: ({
    event,
    userName
  }) => genItem({
    event,
    eventText: <Trans><Bold>{userName}</Bold> has <Bold>activated</Bold> {event.extensionID}</Trans>
  }),
  extensionDeactivation: ({
    event,
    userName
  }) => genItem({
    event,
    eventText: <Trans><Bold>{userName}</Bold> has <Bold>deactivated</Bold> {event.extensionID}</Trans>
  }),


  /** ******************************************************************
   *              Fallback event
   ****************************************************************** */
  NOT_FOUND: () => null
}

const EventRenderingContext = React.createContext<any>('')
const EventRendering: InfiniteScrollingSingleProps<any>['RenderItem'] = ({ item: event }) => {
  const { company: { name: companyName } } = useContext(UserAndCompanyDataContext)
  const prefix = useContext(IdPrefixContext)
  const { lang } = useParams()
  const instanceName = useContext(EventRenderingContext)

  return useMemo(() => {
    if (event._render) {
      return event._render
    }

    //  Defining the link
    const isTpl = event.objectID && event.objectID.startsWith('template')
    const url = isTpl ? `/${lang}/templates/${event.objectID}` : `/${lang}/contracts/${event.objectID}`
    const objectName = (event.object && (event.object.name || event.object.contractName)) || instanceName
    const link = <Link className='link' to={url}>{objectName}</Link>

    //  Defining the user name
    let userName: any = <Trans>Unknown</Trans>
    if (event.userID) {
      userName = <UserFullName userID={event.userID} />
    } else if (event.user) {
      if (event.user.userID) {
        userName = <UserFullName userID={event.user.userID} />
      } else if (event.user.firstName && event.user.lastName) {
        userName = `${event.user.firstName} ${event.user.lastName}`
      }
    }

    //  Defining the third party name
    let thirdParty: any = <Trans>Unknown</Trans>
    let unknown = true
    if (event.thirdParty) {
      if (event.thirdParty.userID || typeof event.thirdParty === 'string') {
        thirdParty = <UserFullName userID={event.thirdParty.userID || event.thirdParty} />
        unknown = false
      } else if (event.thirdParty.firstName && event.thirdParty.lastName) {
        thirdParty = `${event.thirdParty.firstName} ${event.thirdParty.lastName}`
        unknown = false
      } else if (event.thirdParty.email) {
        thirdParty = event.thirdParty.email
        unknown = false
      }
    }

    //  Getting the right function for generating the item
    return (EVENTS_MAPPING[event.eventType] || EVENTS_MAPPING.NOT_FOUND)({
      event,
      userName,
      thirdParty,
      isTpl,
      link,
      companyName,
      objectName,
      prefix,
      url,
      unknown
    })
  }, [companyName, event, lang, prefix, instanceName])
}


const ActivityLogs = ({
  Items,
  className = '',
  instanceName = <Trans>this document</Trans>,
  hasMore,
  loadMore
}) => {
  const rowKey = useCallback(item => item.eventID, [])

  if (Items.length === 0) {
    if (hasMore) {
      setTimeout(loadMore)
    }
    return (
      <>
        <Empty
          description={<Trans>No activity found</Trans>}
          style={{ filter: hasMore ? 'blur(8px)' : '' }}
        />
        {hasMore && (
          <Spin
            size='large'
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 10
            }}
          />
        )}
      </>
    )
  }

  return (
    <EventRenderingContext.Provider value={instanceName}>
      <div className={`logsList ${className}`}>
        <InfiniteScrolling
          RenderItem={EventRendering}
          hasMore={hasMore}
          items={Items}
          loadMore={loadMore}
          rowKey={rowKey}
        />
      </div>
    </EventRenderingContext.Provider>
  )
}

export default ActivityLogs
