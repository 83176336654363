import React, { useMemo, useState } from 'react'
import { t, Trans } from '@lingui/macro'
import { Empty, Input, Tabs } from 'antd'
import { FileOutlined, SearchOutlined } from '@ant-design/icons'

import { withUserData } from '../Layouts/AuthenticatedPage'
import MainContainer from '../Layouts/MainLayout/MainContainer'
import TabsWithKeyInUrl from '../SharedComponents/TabsWithKeyInUrl'

import { LocalLoadingAlert } from '../Alert/LoadingAlert'
import { UseSearchReturn } from '../Search/Types'
import useMediaSearchInstance from '../Search/SearchInstances/MediaSearchInstance'
import useClauseSearchInstance from '../Search/SearchInstances/ClauseSearchInstance'
import useInputFieldSearchInstance from '../Search/SearchInstances/InputFieldSearchInstance'
import { useHighlight } from '../Listing/SearchHelpers'

import './ContentLibraryStyles.scss'

const MAX_ITEMS_PER_CATEGORY = 20

const empty = (
  <Empty
    description={<Trans>No content found</Trans>}
  />
)

const ContentLibrary: React.FC = () => {
  const [search, setSearch] = useState('')
  const searchClause = useClauseSearchInstance()
  const searchFields = useInputFieldSearchInstance()
  const searchMedia = useMediaSearchInstance()

  const Highlight = useHighlight(search)
  const renderSearch = ({ category, getID, filterInstances, InstanceRendering, loading, loadingText, AdditionalFilters }: UseSearchReturn) => {
    const content = (() => {
      if (loading) {
        return empty
      }

      const instances = filterInstances(search)

      if (instances.length === 0) {
        return empty
      }

      return (
        <div className='searchResults'>
          <div key={category} className='category'>
            <div className='categoryResults'>
              {instances.slice(0, MAX_ITEMS_PER_CATEGORY).map(instance => (
                <InstanceRendering key={getID(instance)} Highlight={Highlight} instance={instance} search={search} />
              ))}
            </div>
            {instances.length > MAX_ITEMS_PER_CATEGORY && (
              <div className='hasMore'><span><Trans>Has {instances.length - MAX_ITEMS_PER_CATEGORY} more</Trans></span></div>
            )}
          </div>
        </div>
      )
    })()


    return (
      <LocalLoadingAlert description={loadingText} lightTheme loading={loading}>
        {AdditionalFilters && <AdditionalFilters />}
        {content}
      </LocalLoadingAlert>
    )
  }

  return (
    <MainContainer
      mainContentClass='contentLibraryPage'
      topbarContent={(
        <div className='topbarMainContent'>
          <FileOutlined className='headerIcon' twoToneColor='#3DBD7D' />
          <h1 className='title'><Trans>Content library</Trans></h1>
          <Input
            addonBefore={<SearchOutlined />}
            className='simple-line'
            onChange={evt => setSearch(evt.target.value)}
            placeholder={t`Type to search...`}
            style={{ flex: 1, marginLeft: '1rem' }}
            value={search}
          />
        </div>
      )}
    >
      <TabsWithKeyInUrl className='autoHeight' defaultActiveKey='clauses' destroyInactiveTabPane>
        <Tabs.TabPane key='clauses' tab={<Trans>Clauses</Trans>}>
          {renderSearch(searchClause)}
        </Tabs.TabPane>
        <Tabs.TabPane key='parameters' tab={<Trans>Parameters</Trans>}>
          {renderSearch(searchFields)}
        </Tabs.TabPane>
        <Tabs.TabPane key='videos' tab={<Trans>Videos</Trans>}>
          {renderSearch(searchMedia)}
        </Tabs.TabPane>
        <Tabs.TabPane key='attachments' tab={<Trans>Attachments & Presentations</Trans>}>
          {empty}
        </Tabs.TabPane>
      </TabsWithKeyInUrl>
    </MainContainer>
  )
}

const Wrapped: React.FC<any> = props => {
  const Component = useMemo(() => withUserData(ContentLibrary), [])
  return <Component {...props} />
}

export default Wrapped
