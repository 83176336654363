import React, { useMemo } from 'react'
import { Collection, ListQuery, Model, TypeConfiguration } from '../Types'
import DatabaseContext from '../DatabaseContext'
import { eventsGQLQuery } from './Events'
import { commentsGQLQuery } from './Comments'
import { sectionsDataGQLQuery, sectionsTreeGQLQuery } from './Sections'

export interface Template extends Model {
  templateID: string
  editingOrgininalTemplateID?: string
  organisationID: string
  schemaVersion?: number
  name: string
  description?: string
  category?: string
  lang: string
  header?: any
  sections: any
  footer?: any
  annexes: any
  introVideo?: string
  tags?: string[]
  role: string
  accessType: string
  roles?: any[]

  sectionPrefix?: string
  annexesTitle?: string
  annexesPrefix?: string

  parties?: any
  fields?: any
  conditionalTexts?: any
  obfuscationMapping?: any

  //  Settings
  needSigningApproval?: boolean
  signingApprovalUsersNumber?: number
  signingApprovalUsers?: string[]

  //  Historical activity
  dateCreated: string
  createdBy: string
  dateUpdated: string
  updatedBy?: string
  deleted?: boolean
  previewUrl?: string
}

export interface TemplateDataset { templates: Template[] }

//  TODO: Migration is failing cannot change the schema today
const config: TypeConfiguration<Omit<Template, 'deleted'> & any> = {
  key: 'templates',
  schema: {
    version: 0,
    primaryKey: 'templateID',
    type: 'object',
    properties: {
      accessType: {
        type: 'string'
      },
      annexes: {},
      annexesTitle: {
        type: 'string'
      },
      category: {
        type: 'string'
      },
      conditionalTexts: {},
      createdBy: {
        type: 'string'
      },
      dateCreated: {
        type: 'string'
      },
      dateUpdated: {
        type: 'string'
      },
      description: {
        type: 'string'
      },
      editingOrgininalTemplateID: {
        type: 'string'
      },
      fields: {},
      footer: {},
      header: {},
      introVideo: {
        type: 'string'
      },
      lang: {
        type: 'string'
      },
      name: {
        type: 'string'
      },
      needSigningApproval: {
        type: 'boolean'
      },
      obfuscationMapping: {},
      organisationID: {
        type: 'string'
      },
      parties: {},
      previewUrl: {
        type: 'string'
      },
      role: {
        type: 'string'
      },
      roles: {
        items: {},
        type: 'array'
      },
      schemaVersion: {
        type: 'number'
      },
      sections: {},
      signingApprovalUsers: {
        items: {
          type: 'string'
        },
        type: 'array'
      },
      signingApprovalUsersNumber: {
        type: 'number'
      },
      tags: {
        items: {
          type: 'string'
        },
        type: 'array'
      },
      templateID: {
        type: 'string'
      },
      updatedBy: {
        type: 'string'
      }
    },
    required: [
      'templateID',
      'organisationID',
      'name',
      'lang',
      'sections',
      'annexes',
      'role',
      'accessType',
      'dateCreated',
      'createdBy',
      'dateUpdated'
    ],
    indexes: ['dateUpdated']
  }
}

export const useTemplatesCollection = (): Collection<Template> => React.useContext(DatabaseContext)[config.key]
export const useTemplateListBaseQuery = (): ListQuery<Template> => {
  const collection = useTemplatesCollection()
  return useMemo(
    () => collection.find().where({
      deleted: { $not: true },
      editingOrgininalTemplateID: { $or: [{ $eq: null }, { $exists: false }] }
    }),
    [collection]
  )
}


export const templatesGQLQuery = (lastSyncDate: string, nextToken: string): string => `
{
  Get {
    templates(lastUpdatedAt: "${lastSyncDate}", nextToken: "${nextToken}") {
      items {
        templateID
        editingOrgininalTemplateID
        organisationID
        schemaVersion
        name
        description
        category
        lang
        header
        sections ${sectionsTreeGQLQuery}
        footer
        annexesTitle
        annexes ${sectionsTreeGQLQuery}
        introVideo
        role
        accessType
      
        parties
        fields
        conditionalTexts
        obfuscationMapping
      
        # Settings
        needSigningApproval
        signingApprovalUsersNumber
        signingApprovalUsers
      
        # Historical activity
        dateCreated
        createdBy
        dateUpdated
        deleted
      
        # Nested resolvers
        previewUrl
        events ${eventsGQLQuery}
        comments ${commentsGQLQuery}
        roles {
          userID
          organisationID
          role
        }
      }
      nextToken
    }
  }
}`


export const templatesSectionsGQLQuery = (
  lastSyncDate: string,
  nextToken: string,
  attributeName: 'sections' | 'annexes' | 'headerSection' | 'footerSection',
  dataQuery = sectionsDataGQLQuery
): string => `
{
  Get {
    templates(lastUpdatedAt: "${lastSyncDate}", nextToken: "${nextToken}") {
      items {
        ${attributeName} ${dataQuery}
      }
      nextToken
    }
  }
}`

export default config
