import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import {
  CaretRightOutlined,
  CheckOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  FileWordOutlined, LeftOutlined,
  MenuOutlined,
  MoreOutlined,
  SettingOutlined
} from '@ant-design/icons'
import {
  Alert, Button, Dropdown, Form, Menu, Tabs, Tooltip
} from 'antd'

import { DeviceContext } from '../../GlobalContext'
import { ContractContext, TemplateContext } from '../Contract/ContractEditor/Contexts'
import RestService from '../../RestService'

import MainContainer from '../Layouts/MainLayout/MainContainer'
import loadingModalFeedback from '../Alert/LoadingModalFeedback'
import CustomFormWrapper from '../SharedComponents/CustomFormWrapper'
import ButtonLink from '../SharedComponents/ButtonLink'
import TabsWithKeyInUrl from '../SharedComponents/TabsWithKeyInUrl'
import { MediaLibrarySelector } from '../Media/MediaLibrary'
import { ConfirmMenuItem } from '../SharedComponents/FormComponents'
import { useComputeSectionMapping, useDeletePlaybook, useDuplicatePlaybook } from '../Contract/Utils'

import TemplateEditing from './TemplateTabs/TemplateEditing'
import MetadataSettings from './TemplateTabs/MetadataSettings'
import StructureSettings from './TemplateTabs/StructureSettings'
import ApprovalSettings from './TemplateTabs/ApprovalSettings'
import FeedbackSettings from './TemplateTabs/FeedbackSettings'
import SignatureSettings from './TemplateTabs/SignatureSettings'

import { TemplateEntitiesFormsModalProvider } from '../Contract/ContractEditor/TemplateEntities/TemplateEntitiesForms/TemplateEntitiesFormsModal'
import ProvideTemplateSectionSaving from '../Contract/ContractEditor/editorRefactoredPart/sectionComponents/ProvideTemplateSectionSaving'
import './TemplatePage.scss'
import { ContractDefaultIntroVideo } from '../Contract/ContractEditor/Sidebar/SidebarToolboxes/QuestionsWizard/ContractIntroVideo'

export interface TemplateSettingsChildProps {
  template: any
}

const TemplatePage: React.FC = () => {
  const isPhone = useContext(DeviceContext) === 'phone'
  const { url } = useRouteMatch()
  const { template, updateTemplate, setReadOnly } = useContext<any>(TemplateContext)
  const { setWithWizard, setPreviewMode } = useContext<any>(ContractContext)
  const [formTouched, setFormTouched] = useState(false)

  //  Configure contexes
  useEffect(() => {
    setReadOnly(false)
    setWithWizard(false)
    setPreviewMode(false)
  }, [setReadOnly, setWithWizard, setPreviewMode])

  useComputeSectionMapping()

  const deletePlaybook = useDeletePlaybook()
  const duplicatePlaybook = useDuplicatePlaybook()

  const download = useCallback(
    async (type: 'pdf' | 'docx') => {
      (window as any).notification.info({
        message: <Trans>Preparing download</Trans>,
        description: <Trans>We are currently preparing your document for download. We will send you a email with the generated link.</Trans>
      })

      await RestService('POST', '/utils/convert-document', { type, templateID: template.templateID })
    },
    [template.templateID]
  )

  const formProps = useMemo(
    () => ({
      onSubmit: async values => loadingModalFeedback({
        loadingTitle: <Trans>Saving your playbook configuration</Trans>,
        successTitle: <Trans>Playbook configuration saved</Trans>,
        errorTitle: <Trans>Failed to save your playbook configuration</Trans>,
        errorDescription: <Trans>An error occurred while saving your playbook configuration. Please try again later.</Trans>,
        autoSuccessClose: 1000
      })(() => updateTemplate(values).finally(() => setFormTouched(false))),
      onValuesChange: () => {
        setFormTouched(true)
      },
      noErrorNotif: true
    }),
    [updateTemplate]
  )

  const saveButton = useMemo(
    () => (
      <Button
        className='savingButton' disabled={!formTouched} htmlType='submit' icon={<CheckOutlined />}
        type='primary'
      >
        <Trans>Save</Trans>
      </Button>
    ),
    [formTouched]
  )

  if (template.deleted) {
    return (
      <MainContainer mainContentClass='templateSettingPage'>
        <Alert
          description={<Trans>Sorry, this playbook has been deleted.</Trans>}
          message={<Trans>Deleted Playbook</Trans>}
          showIcon
          style={{ margin: '2rem' }}
          type='error'
        />
      </MainContainer>
    )
  }

  const moreMenu = (
    <Menu key='menu'>
      {/* Only in automate not dealroom iframe */}
      {window.parent === window && (
        <Menu.Item>
          <Link to={`${url.replace('/templates/', '/contracts/')}/new`}>
            <EditOutlined />
            <span>
              <Trans>Draft a new contract</Trans>
            </span>
          </Link>
        </Menu.Item>
      )}
      <Menu.Item>
        <Link to={`${url}?tab=metadata`}>
          <SettingOutlined />
          <span>
            <Trans>Playbook settings</Trans>
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item onClick={() => download('docx')}>
        <FileWordOutlined />
        <span>
          <Trans>Download .docx</Trans>
        </span>
      </Menu.Item>
      <Menu.Item onClick={() => duplicatePlaybook(template.templateID)}>
        <CopyOutlined />
        <span>
          <Trans>Make a copy</Trans>
        </span>
      </Menu.Item>
      <ConfirmMenuItem onClick={() => deletePlaybook(template.templateID)}>
        <DeleteOutlined />
        <span>
          <Trans>Delete playbook</Trans>
        </span>
      </ConfirmMenuItem>
    </Menu>
  )

  return (
    <MainContainer
      mainContentClass='templatePage'
      topbarContent={
        <>
          <div className='topbarMainContent'>
            <FileOutlined className='headerIcon' twoToneColor='#3DBD7D' />
            <h1 className='title'>{template.name}</h1>
          </div>
          {!isPhone && <div id='topbarExtraContent' />}
          <div className='topbarActions'>
            <ButtonLink ghost href={`${url}/preview`} icon={<CaretRightOutlined />} type='default'>
              <span>
                <Trans>Preview</Trans>
              </span>
            </ButtonLink>
            <Dropdown overlay={moreMenu} placement='bottomRight' trigger={['click']}>
              <Button
                className='noBorder moreButton'
                ghost
                // eslint-disable-next-line no-script-url
                href='javascript:void(0)'
                icon={(window as any).IS_TRANSFERINITIATIVE ? <MenuOutlined /> : <MoreOutlined />}
              />
            </Dropdown>
          </div>
        </>
      }
    >
      <TabsWithKeyInUrl className='autoHeight' defaultActiveKey='automate' onChange={() => setFormTouched(false)}>
        <Tabs.TabPane key='automate' className='templateDocumentTab' forceRender tab={<Trans>Automation</Trans>}>
          <ProvideTemplateSectionSaving>
            <TemplateEntitiesFormsModalProvider>
              <TemplateEditing />
            </TemplateEntitiesFormsModalProvider>
          </ProvideTemplateSectionSaving>
        </Tabs.TabPane>
        <Tabs.TabPane key='metadata' tab={<Trans>General</Trans>}>
          <CustomFormWrapper {...formProps}>
            <h1>
              <Trans>General</Trans>
            </h1>
            <MetadataSettings template={template} />
            {saveButton}
          </CustomFormWrapper>
        </Tabs.TabPane>
        {window.parent === window && [
          //  Automate
          <Tabs.TabPane key='structure' tab={<Trans>Document Structure</Trans>}>
            <CustomFormWrapper {...formProps}>
              <h1>
                <Trans>Document Structure</Trans>
              </h1>
              <StructureSettings template={template} />
              {saveButton}
            </CustomFormWrapper>
          </Tabs.TabPane>,
          <Tabs.TabPane key='introVideo' tab={<Trans>Introduction Video</Trans>}>
            <CustomFormWrapper {...formProps}>
              <h1>
                <Trans>Introduction Video</Trans>
              </h1>
              <fieldset className='videoSetting'>
                <Form.Item initialValue={template.introVideo} name='introVideo'>
                  <MediaLibrarySelector defaultVideo={<ContractDefaultIntroVideo />} media='audioVideo' />
                </Form.Item>
              </fieldset>
              {saveButton}
            </CustomFormWrapper>
          </Tabs.TabPane>
        ]}
        <Tabs.TabPane key='approval' tab={<Trans>Approval</Trans>}>
          <CustomFormWrapper {...formProps}>
            <h1>
              <Trans>Approval</Trans>
            </h1>
            <ApprovalSettings template={template} />
            {saveButton}
          </CustomFormWrapper>
        </Tabs.TabPane>
        {window.parent === window && [
          //  Automate
          <Tabs.TabPane key='feedback' tab={<Trans>Feedback</Trans>}>
            <CustomFormWrapper {...formProps}>
              <h1>
                <Trans>Feedback</Trans>
              </h1>
              <FeedbackSettings template={template} />
              {saveButton}
            </CustomFormWrapper>
          </Tabs.TabPane>,
          <Tabs.TabPane key='signingFlow' tab={<Trans>Signature</Trans>}>
            <CustomFormWrapper {...formProps}>
              <h1>
                <Trans>Signature</Trans>
              </h1>
              <SignatureSettings template={template} />
              {saveButton}
            </CustomFormWrapper>
          </Tabs.TabPane>
        ]}
      </TabsWithKeyInUrl>
    </MainContainer>
  )
}

export default TemplatePage
