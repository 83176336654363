/* eslint-disable max-lines */
import React from 'react'
import {
  Alert, Button, Col, Form, Input, Radio, Row, Steps
} from 'antd'
import { CheckOutlined, EditOutlined, LeftOutlined, PlusCircleOutlined, RightOutlined } from '@ant-design/icons'
import { t, Trans } from '@lingui/macro'
import { ConsoleLogger as Logger } from '@aws-amplify/core'

//  Editor library
import { RenderOnlyEditor, isEmpty, isInlineConditional } from '@top-legal/editor'

//  Components
import { buildContractInputQuestion } from '../../../../Template/TemplateHelperFunctions'
import { Select } from '../../../../SharedComponents/FormComponents'
import CustomFormWrapper, { requiredRules } from '../../../../SharedComponents/CustomFormWrapper'
import { getConditionalTextValue, getElem, isPartiesEntity } from '../../../../Template/_TemplateHelperFunctions'
import { TemplateContext } from '../../Contexts'
import ConditionalTextEntriesEditing from './ConditionalTextEntriesEditing'
import Sidebar, { SidebarToolboxes } from '../../Sidebar/Sidebar'
import TableOfContent from '../../TableOfContent/TableOfContent'
import SlateInlineBlockSelector from '../SlateInlineBlockSelector'

import TemplateEntitiesToolboxes from '../../Sidebar/SidebarToolboxes/TemplateEntitiesToolbox/TemplateEntitiesToolbox'
import { noop } from '../../../../Defaults'
import './ConditionalTextFormStyles.scss'
// ==================================================================================================
// ==================================================================================================

/**
 * ConditionalText form creator & editor
 */
class ConditionalTextForm extends React.Component {
  // ==================================================================================================

  constructor (props) {
    super(props)
    Object.defineProperty(this, 'context', {
      configurable: true,
      get: () => this.props,
      // eslint-disable-next-line no-setter-return
      set: () => null
    })
    const conditionalTexts = []
    Object.keys(this.context.conditionalTexts).forEach(conditionalTextID => {
      Object.keys(this.context.conditionalTexts[conditionalTextID]).forEach(version => {
        conditionalTexts.push(this.context.conditionalTexts[conditionalTextID][version])
      })
    })
    conditionalTexts.sort((valA, valB) => valA.conditionalTextID - valB.conditionalTextID)
    this.state = {
      step: this.props.step == null ? -1 : this.props.step,
      values: { ...(this.props.initialValue || {}) },
      conditionalTexts,
      conditionalTextsFormatted: conditionalTexts.slice(0, 20),
      createNewConditional: Object.values(this.props.initialValue).length > 0
    }

    // starting a new looger
    this.logger = new Logger('conditional text modal')
  }

  // ==================================================================================================

  // ==================================================================================================

  createPreview = form => {
    const fieldKey = form.getFieldValue('field') || this.state.values.field

    if (fieldKey) {
      return (
        buildContractInputQuestion(this.context.template, '__TEST__', form, {}, getElem(fieldKey, this.context.template.fields), this.context.lang, false)
      )
    }

    return (
      <Alert
        message={<Trans>You need to input a field type before a preview can be displayed.</Trans>}
        showIcon
        type='info'
      />
    )
  }

  renderTryItStep = form => {
    const { field, name } = this.state.values
    const { template: { fields, lang } } = this.context
    const currentValue = form.getFieldValue('__TEST__')

    let text = null
    if (currentValue != null) {
      text = getConditionalTextValue(getElem(field, fields), { ...this.state.values }, currentValue, lang, '')

      const isInline = isInlineConditional(this.state.values)
      const Elem = isInline ? 'span' : 'div'
      if (text) {
        text = (
          <Elem className={`conditionalText ${isInline ? 'inlineElement' : 'blockElement'}`}>
            <RenderOnlyEditor nodes={text} />
          </Elem>
        )
      } else {
        text = ''
      }
    } else {
      text = <span className='conditionalText'>{name}</span>
    }

    const inlineText = t`The placeholder for the conditional text will be displayed in the contract/editor (until input from the user has been saved) as follows:`
    const html = <p className='conditionalTextExplanation'>{inlineText} {text}</p>

    return <div className='conditionalTextTryPreview'>{html}</div>
  }

  // ==================================================================================================
  /**
   * renders the input field configuration in the modal. This is the first step in
   * configuring the optional text element. the user selects the type of condition
   * that will be used to determine the conditional text.
   */

  renderInputSelectionStep = form => {
    const initialValue = { ...(this.props.initialValue || {}), ...this.state.values }
    const isExistingCond = initialValue.key || initialValue.conditionalTextID


    const orgFields = {}
    Object.keys(this.context.conditionalTexts).forEach(key => { orgFields[key] = this.context.conditionalTexts[key][1] })
    const fields = { ...this.context.template.fields, _createNew_: { type: 'global' }, ...orgFields } // Just add a mock field for a "Create new" button
    const currentInputFieldKey = form.getFieldValue('field') || initialValue.field
    return (
      <>
        {/** *************************************************************************
                        Which input field should be used?
        ************************************************************************** */}
        <p className='promptText' style={{ lineHeight: '1.4rem' }}>
          <Trans>This selection provides you with a list of existing input options:</Trans>
        </p>
        <div className='conditionalTextInput' style={{ marginBottom: '1rem' }}>
          <Form.Item
            className='inputModalFormItem primarySelector'
            initialValue={initialValue.field}
            name='field'
            rules={requiredRules}
          >
            <Select
              alwaysShown={field => field === fields._createNew_}
              className='inTextInput simple-line'
              collapsed
              disabled={isExistingCond}
              filter={element => /(list|date|time|number|yesNo|global|person|company)/.test(element.type)}
              items={fields}
              label={(field, path) => {
                if (field === fields._createNew_) {
                  return (
                    <Button
                      icon={<PlusCircleOutlined />}
                      onClick={() => this.props.createOrEditField({ ...initialValue, ...form.getFieldsValue() })}
                      type='primary'
                    >
                      <Trans>Create new input</Trans>
                    </Button>
                  )
                }
                if (path.length > 0) {
                  return field.name
                }
                return null
              }}
              placeholder={t`- Conditional field -`}
              selectable={field => /(list|date|time|number|yesNo)/.test(field.type)}
            />
          </Form.Item>
          {!isExistingCond && currentInputFieldKey && !isPartiesEntity(fields[currentInputFieldKey.split('.')[0]]) && (
            <Button
              className='inTextInput editButton'
              icon={<EditOutlined />}
              onClick={() => this.props.createOrEditField({ ...initialValue, ...form.getFieldsValue() }, currentInputFieldKey)}
              type='primary'
            />
          )}
        </div>
        {/** *************************************************************************
                                Some explanations
         ************************************************************************** */}
        <p className='promptText' style={{ lineHeight: '1.4rem' }}>
          <Trans>
            These input options may be linked to <b>specific text</b>.
            The text is only displayed in the document when a user has selected the <b>corresponding option</b>.
          </Trans>
        </p>
      </>
    )
  }

  renderTypeSelectionStep = () => {
    const initialValue = { ...(this.props.initialValue || {}), ...this.state.values }
    const field = getElem(initialValue.field, this.context.template.fields)
    const isMultiChoicesList = field && field.type === 'list' && field.isMultipleChoices

    return (
      <Form.Item
        initialValue={(isMultiChoicesList && 'block') || initialValue.slateType || 'block'}
        name='slateType'
        rules={[{ required: true }]}
      >
        <SlateInlineBlockSelector disabled={initialValue.key || initialValue.conditionalTextID || isMultiChoicesList} type='conditionalText' />
      </Form.Item>
    )
  }

  // ==================================================================================================
  /**
   * this is the second step in configuring the conditional text element. the user may enter
   * conditional text for each option (for a radio button)
   */
  renderConditionDefinitionStep = () => {
    const initialValue = { ...(this.props.initialValue || {}), ...this.state.values }
    const field = getElem(initialValue.field, this.context.template.fields)

    const initialCondName = `${field.name} - ${t`Conditional`}`
    //  These variables depend of the field type that is linked to the conditional text
    let initialConditionalValues = null

    if (field.type === 'yesNo') {
      /**
       * In case of a yes no the conditional text values is a object like this:
       * {
       *   yes: 'Yes text',
       *   no 'No text'
       * }
       */
      //  If it's a yes no field mock default value and merge with initial ones
      initialConditionalValues = {
        yes: { label: <Trans>Yes</Trans>, text: '' },
        no: { label: <Trans>No</Trans>, text: '' }
      }
      if (Object(initialValue.values) === initialValue.values) {
        if (initialValue.values.yes) {
          initialConditionalValues.yes.text = initialValue.values.yes
        }
        if (initialValue.values.no) {
          initialConditionalValues.no.text = initialValue.values.no
        }
      }
    } else if (field.type === 'list') {
      /**
       * All list created by the user is a array of labels the conditional values is so a array like object (object because it can be sparse):
       * {
       *   0: 'Text for option 0',
       *   5: 'Text for option 5',
       *   6: 'Text for option 6'
       * }
       * If the list is ours (Made in the code) we use trans like Company industries { biotechnology: <Trans>Biotechnology</Trans> } in this case the values is a normal object:
       * {
       *   biotechnology: 'Text for biotechnology',
       *   retail: 'Text for retail'
       * }
       *
       * For the edition we need to convert the input data to an object of label & text
       * {
       *   0: {
       *     label: 'Field option label', text: 'The conditional text value for this option'
       *   }
       * }
       *
       * At the end so in the method below we need to take care to transform back the values
       * @see ConditionalTextForm.next
       */
      const values = field.values[this.context.lang] || field.values
      const texts = (initialValue || {}).values || {}
      if (Array.isArray(values)) { // SO we need to check the type of the field list, is it a array or a object?
        // get value returns the value of an array define by the index
        initialConditionalValues = { ...values.map((label, index) => ({ label, text: texts[index] || '' })) }
      } else if (Object(values) === values) {
        initialConditionalValues = {}
        Object.keys(values).forEach(key => {
          initialConditionalValues[key] = { label: values[key], text: texts[key] || '' }
        })
      } else {
        return <Alert message={t`Invalid input field for this conditional text`} showIcon type='error' />
      }
    } else {
      /**
       * In the else case it should be Date/Time field and so the conditional values should be a array
       */
      //  Else just take initial values (Should be a array)
      // eslint-disable-next-line no-lonely-if
      if (Array.isArray(initialValue.values)) {
        initialConditionalValues = initialValue.values.map(elem => {
          if (Object(elem) === elem) {
            return elem
          }
          if (typeof elem === 'string') {
            return ({ text: elem })
          }
          return null
        }).filter(elem => elem)
      } else {
        initialConditionalValues = []
      }
    }

    const uniqueID = `sidebar-${Date.now()}`

    return (
      <div className='conditionalTextEditing'>
        <div className='conditionalTextEntriesEditing'>
          {/** *************************************************************************
                                    How should be named?
          ************************************************************************** */}
          <div className='sentenceWrapper' style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <span className='promptText'><Trans>This automation is referred to as </Trans></span>
            <Form.Item
              className='inputModalFormItem'
              initialValue={initialValue.name || initialCondName}
              name='name'
              rules={requiredRules}
            >
              <Input className='simple-line inTextInput simple-line' placeholder={t`Condition name`} style={{ maxWidth: 'unset' }} />
            </Form.Item>
          </div>
          {/** *************************************************************************
                   Should be relative to the date when the contract is filled?
           ************************************************************************** */}
          {field.type === 'date' && (
            <p className='sentenceWrapper' style={{ lineHeight: '1.4rem', display: 'unset' }}>
              <span className='promptText'>
                <Trans>Would you like to make the conditional text dependent on a duration (3 years) or an absolute date (27/05/2017)?</Trans>
              </span>
              <Form.Item
                className='inputModalFormItem dateTypeSwitch'
                initialValue={
                  typeof initialValue.isRelativeToContractFillingDate === 'boolean'
                    ? initialValue.isRelativeToContractFillingDate : true
                }
                name='isRelativeToContractFillingDate'
              >
                <Radio.Group buttonStyle='solid'>
                  <Radio.Button value={true}><Trans>Duration</Trans></Radio.Button>
                  <Radio.Button value={false}><Trans>Absolute Date</Trans></Radio.Button>
                </Radio.Group>
              </Form.Item>
            </p>
          )}
          {/** *************************************************************************
                                    Values editing
         ************************************************************************** */}
          <div className='conditionalTextEntriesListWrapper'>
            <Form.Item shouldUpdate={true}>
              {form => {
                const isRelativeToContractFillingDate = form.getFieldValue('isRelativeToContractFillingDate') || initialValue.isRelativeToContractFillingDate
                return (
                  <Form.Item
                    initialValue={initialConditionalValues}
                    name='values'
                    rules={[{ required: true }]}
                    type={Object}
                  >
                    <ConditionalTextEntriesEditing
                      conditional={initialValue}
                      isRelativeToContractFillingDate={isRelativeToContractFillingDate}
                      type={field.type}
                    />
                  </Form.Item>
                )
              }}
            </Form.Item>
          </div>
        </div>
        <div className='templateEntitiesSidebar' id={uniqueID}>
          <TemplateEntitiesToolboxes.Component cssSelector={`#${uniqueID}`} inlinesOnly={this.state.values.slateType === 'inline'} />
        </div>
        <TableOfContent
          next={noop}
          previous={noop}
          readOnly
          suffix='-conditionalModal'
        />
      </div>
    )
  }

  // ==================================================================================================

  renderCurrentStep = form => {
    if (this.state.step === -1) {
      return this.renderInputSelectionStep(form)
    }
    if (this.state.step === 0) {
      return this.renderTypeSelectionStep(form)
    }
    if (this.state.step === 1) {
      return this.renderConditionDefinitionStep(form)
    }
    return (
      <Form.Item shouldUpdate>
        {() => this.renderTryItStep(form)}
      </Form.Item>
    )
  }

  // ==================================================================================================
  next = async newValues => {
    if (this.state.step < 2) {
      this.setState(({ step, values }) => {
        const newStateValues = { ...values, ...newValues }

        //  Reformat back values
        if (newValues.values) {
          /**
           * Transform back the values look at the method below for more explanations about values
           * @see ConditionalTextForm.renderConditionDefinitionStep
           */
          const field = getElem(newStateValues.field, this.context.template.fields)
          newStateValues.fieldVersion = field.version
          if (field) {
            if (field.type === 'list') {
              const formattedValues = {} // We need a sparse array so use object
              Object.entries(newValues.values).forEach(([key, { text }]) => {
                if (Array.isArray(text) && !isEmpty(text)) {
                  formattedValues[key] = text
                }
              })
              newStateValues.values = formattedValues
            } else if (field.type === 'yesNo') {
              const formattedValues = {}
              if (Array.isArray(newValues.values.yes.text) && !isEmpty(newValues.values.yes.text)) {
                formattedValues.yes = newValues.values.yes.text
              }
              if (Array.isArray(newValues.values.no.text) && !isEmpty(newValues.values.no.text)) {
                formattedValues.no = newValues.values.no.text
              }
              newStateValues.values = formattedValues
            }
          } else {
            //  eslint-disable-next-line
            console.warn('Waring - ConditionalTextForm - Invalid field', newStateValues.field)
          }
        }
        return { step: step + 1, values: newStateValues }
      })
    } else {
      this.setState({ loading: true })
      await this.props.onSubmit(this.state.values)
      this.setState({ loading: false })
    }
  }

  // ==================================================================================================

  previous = () => this.setState(({ step }) => ({ step: Math.max(-1, step - 1) }))

  // ==================================================================================================

  render = () => (
    <CustomFormWrapper className='conditionalTextForm' onSubmit={this.next}>
      {
        form => (
          <Row gutter={16} type='flex'>
            <Col className='logicColumn' span={[0, 1].includes(this.state.step) ? 24 : 14}>
              <div className='logigColumnInner paragraphWithInputs'>
                <Steps current={Math.max(0, this.state.step)} direction='horizontal'>
                  <Steps.Step title={<Trans>Create logic</Trans>} />
                  <Steps.Step title={<Trans>Configure texts</Trans>} />
                  <Steps.Step title={<Trans>Preview</Trans>} />
                </Steps>
                <div className='formContent'>
                  {this.renderCurrentStep(form)}
                </div>
                <div className='actionButtons'>
                  {this.state.step >= 0 ? (
                    <Button
                      className='previous' ghost icon={<LeftOutlined />} onClick={this.previous}
                      type='primary'
                    ><Trans>Previous</Trans>
                    </Button>
                  ) : <span>&nbsp;</span>}
                  {this.state.step < 2 && (
                    <Button className='next' htmlType='submit' icon={<RightOutlined />} type='primary'><Trans>Next</Trans></Button>
                  )}
                  {this.state.step === 2 && (
                    <Button
                      className='next' htmlType='submit' icon={<CheckOutlined />} loading={this.state.loading}
                      type='primary'
                    ><Trans>Done</Trans>
                    </Button>
                  )}
                </div>
              </div>
            </Col>
            {![0, 1].includes(this.state.step) && (
              <Col className='inputPreview' span={10}>
                <div className='inputPreviewInner'>
                  <h3><Trans>Preview for user:</Trans></h3>
                  <Form.Item shouldUpdate>
                    {form_ => this.createPreview(form_)}
                  </Form.Item>
                </div>
              </Col>
            )}
          </Row>
        )
      }
    </CustomFormWrapper>
  )
}


// eslint-disable-next-line react/display-name
export default React.forwardRef((props, ref) => (
  <TemplateContext.Consumer>
    {context => <ConditionalTextForm ref={ref} {...props} {...context} />}
  </TemplateContext.Consumer>
))
