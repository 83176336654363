import React, { useContext, useRef } from 'react'
import { Modal } from 'antd'
import { Trans } from '@lingui/macro'
import { ConsoleLogger as Logger } from '@aws-amplify/core'

import { DatabaseContext, saveInStore } from '@top-legal/datastore'

//  Contexts
import { ContractContext, TemplateContext } from '../../Contexts'

//  Components
import ComponentWrapperFactory from '../../../../SharedComponents/ComponentWrapperFactory'
import CreateInputFieldForm from './CreateInputFieldForm'
import ConditionalTextForm from './ConditionalTextForm'
import { buildKey, getElem } from '../../../../Template/_TemplateHelperFunctions'
import { updateConditional, updateField } from '../../../../Organisations/redux/OrganisationsActions'
import { updateConditionalTextTemplate, updateFieldTemplate } from '../../../../Template/redux/TemplateActions'

//  Styles
import './TemplateEntitiesFormsModalStyles.scss'
import {noop} from "../../../../Defaults";

const MyModal = Modal

// ==================================================================================================
// ==================================================================================================
class TemplateEntitiesFormsModal extends React.Component {
  // ==================================================================================================

  constructor (props) {
    super(props)
    Object.defineProperty(this, 'context', {
      configurable: true,
      get: () => this.props,
      set: () => null
    })

    this.state = {
      showModal: false
    }

    this.formComponent = React.createRef()

    this.logger = new Logger('TemplateEntitiesFormsModal')
  }

  // ==================================================================================================
  editInputField = (key, changeCallback = noop, field = null) => {
    //  Cannot edit certain fields
    if (key && key.includes('.')) {
      const [baseKey] = key.split('.')
      field = getElem(baseKey, this.context.template.fields)
    }

    if (!field) {
      field = getElem(key, this.context.template.fields)
    }

    //  Cannot edit certain fields
    if (field && (field.type === 'company' || field.type === 'person' || field.type === 'productTable')) {
      return
    }

    this.createOrEditInputField(newKey => changeCallback(newKey), {
      ...field,
      key
    })
  }

  // ==================================================================================================
  /**
   * opens a modal that is used to edit an existing input field.
   * @param {function} callback takes in the param key
   * @param {object} initialValue
   * @param {boolean} showUpdateAllSection
   */
  createOrEditInputField = (callback = noop, initialValue = {}, showUpdateAllSection = false) => {
    this.logger.info('create or edit input field opened with initial value:', initialValue)
    this.setState({
      showModal: true,
      modalTitle: <Trans>Input Field Editor</Trans>,
      form: (
        <CreateInputFieldForm
          initialValue={initialValue}
          onCloseModal={() => this.setState({ showModal: false })}
          onSubmit={async ({ generalize, ...values }) => {
            this.logger.info('updating fields with values:', values)
            /**
           * merging all the existing template fields with the new incoming
           * fields where the type is used as a key, and saving it to the
           * redux store
           */
            const res = await this.props.dispatch(updateField(values))
            const field = { ...values, ...res }
            await saveInStore(this.props.database.input_fields, field)
            await this.props.dispatch(updateFieldTemplate(field.inputFieldID, field.version, values))
            // resetting the state
            this.setState({
              showModal: false,
              onSubmit: null,
              form: null,
              modalTitle: null
            })
            callback(field.inputFieldID, generalize, values)
          }}
          showGeneralize={showUpdateAllSection}
        />
      )
    })
  }

  // ==================================================================================================
  /**
   * Create a new ConditionalText
   * Called by the Embed component conditionalText for edit mode & by newConditionalText for creation
   * @param {function} callback takes in the param key
   * @param {string|null} key
   * @param {object} initialValue
   */
  createOrEditConditionalText = (callback = noop, key = null, initialValue = null, step = -1) => {
    //  Get the DefaultSlateContent
    if (!initialValue) {
      if (key) {
        initialValue = this.context.template.conditionalTexts[key]
      } else {
        initialValue = {}
      }
    }

    this.logger.info('conditional modal opened with key', key)

    this.setState({
      showModal: true,
      modalTitle: <Trans>Conditional text</Trans>,
      form: <ConditionalTextForm
        ref={this.formComponent}
        // Bring label from the select value
        createOrEditField={(values, fieldKey) => {
          const editCallback = newKey => {
            this.onCancel = null
            values.field = newKey
            this.createOrEditConditionalText(callback, key, values)
          }
          // reassigning the function to on cancel for the modal
          this.onCancel = editCallback
          this.editInputField(fieldKey, editCallback)
        }}
        initialValue={initialValue}
        onSubmit={async formValues => {
          // if there is no key, generate a new one
          if (!key) {
            key = buildKey(formValues.name)
          }

          const res = await this.props.dispatch(updateConditional(formValues))
          const cond = { ...formValues, ...res }
          await saveInStore(this.props.database.conditional_texts, cond)
          await this.props.dispatch(updateConditionalTextTemplate(cond.conditionalTextID, cond.version, formValues))

          // resetting the state
          this.setState({
            showModal: false,
            onSubmit: null,
            form: null,
            modalTitle: null
          })
          callback(key)
        }}
        step={step}
      />
    })
  }

  getForm = () => this.formComponent.current.props.form

  render () {
    return (
      <MyModal
        className='templateTextEditorModal bigModal'
        destroyOnClose
        onCancel={() => {
          if (typeof this.onCancel === 'function') {
            this.onCancel()
          } else {
            this.setState({
              showModal: false,
              onSubmit: null,
              form: null,
              modalTitle: null
            })
          }
        }}
        onOk={() => {
          // validating all the form items on submission
          this.getForm().validateFields((errors, values) => {
            this.logger.info('validating the form')

            if (!errors) {
              // taking the submit function of the state that has been left there earlier when opening the modal
              const { onSubmit } = this.state

              // passing the value to the onSubmit function
              setTimeout(() => onSubmit(values))

              // resetting the state
              this.setState({
                showModal: false,
                onSubmit: null,
                form: null,
                modalTitle: null
              })
            } else {
              this.logger.error('error validation the form:', errors)
            }
          })
        }}
        style={{ top: '2rem' }}
        title={<h1>{this.state.modalTitle}</h1>}
        visible={this.state.showModal}
        width='80%'
        {...(this.state.onSubmit ? {} : { footer: null })}
      >
        <TemplateEntitiesFormsModalProvider>
          {this.state.form}
        </TemplateEntitiesFormsModalProvider>
      </MyModal>
    )
  }
}

const Component = ComponentWrapperFactory(TemplateEntitiesFormsModal)
  .withRedux()
  .build()
const FinalComponent = React.forwardRef((props, ref) => (
  <TemplateContext.Consumer>
    {context => <Component ref={ref} {...props} {...context} />}
  </TemplateContext.Consumer>
))

export const ModalRefContext = React.createContext({})

export const TemplateEntitiesFormsModalProvider = ({ children }) => {
  const modalRef = useRef()
  const database = useContext(DatabaseContext)

  return (
    <>
      <ContractContext.Provider value={{}}>
        <FinalComponent ref={modalRef} database={database} />
      </ContractContext.Provider>
      <ModalRefContext.Provider value={{ modalRef }}>{children}</ModalRefContext.Provider>
    </>
  )
}

export default FinalComponent

