import React, { useContext, useMemo } from 'react'

import { defaultCounterparty, UserAndCompanyDataContext } from '../../../../../../Layouts/Constants'
import InstanceUsersContext, { PartyInfo, InternalUserRole } from './InstanceUsersContext'

import { PartiesCollapseProps } from '../PartiesCollapse'
import InviteInternalMembers from '../InviteActions/InviteInternalMembers'
import InternalRoleCard from '../UserCards/InternalRoleCard'
import InviteExternalFromParties from '../InviteActions/InviteExternalFromParties'
import ExternalRoleCard from '../UserCards/ExternalRoleCard'


const DEFAULT_EXTERN_PARTIES: PartiesCollapseProps['groups'] = [{
  groupID: defaultCounterparty.userID,
  children: <InviteExternalFromParties />
}]


export interface UseGroupsProps {
  InternalComponent?: React.FC<{ partyInfo: PartyInfo, userRole: InternalUserRole }>
  ExternalComponent?: React.FC<{ partyInfo: PartyInfo }>
  noInvite?: boolean
  filterInternFromExtern?: boolean
}


const useGroups = ({
  InternalComponent = InternalRoleCard as any,
  ExternalComponent = ExternalRoleCard,
  noInvite = false,
  filterInternFromExtern = false
}: UseGroupsProps = {} as any): PartiesCollapseProps['groups'] => {
  const { company: { organisationID } } = useContext(UserAndCompanyDataContext)
  const { internRoles, partiesInfo, partiesGroups } = useContext(InstanceUsersContext)

  const internGroup: PartiesCollapseProps['groups'] = useMemo(() => (internRoles.length === 0 ? [] : [{
    groupID: organisationID,
    children: (
      <>
        {!noInvite && <InviteInternalMembers />}
        {internRoles.map(userRole => (
          <InternalComponent
            key={userRole.userID}
            partyInfo={partiesInfo[userRole.userID] || { partyData: { partyID: userRole.userID } }}
            userRole={userRole}
          />
        ))}
      </>
    )
  }]), [InternalComponent, internRoles, noInvite, organisationID, partiesInfo])

  const externGroups: PartiesCollapseProps['groups'] = useMemo(
    () => {
      const arr: PartiesCollapseProps['groups'] = Object.keys(partiesGroups)
        .sort()
        .filter(groupID => !filterInternFromExtern || /^(contact|party)-/.test(groupID))
        .map(groupID => ({
          groupID,
          children: (
            <>
              {!noInvite && <InviteExternalFromParties />}
              {partiesGroups[groupID].map(partyID => (
                <ExternalComponent
                  key={partyID}
                  partyInfo={partiesInfo[partyID]}
                />
              ))}
            </>
          )
        }))

      if (arr.length === 0 && !noInvite) {
        return DEFAULT_EXTERN_PARTIES
      }
      return arr
    },
    [ExternalComponent, noInvite, partiesGroups, partiesInfo, filterInternFromExtern]
  )

  return useMemo(
    () => [...internGroup, ...externGroups],
    [internGroup, externGroups]
  )
}

export default useGroups
