import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Skeleton } from 'antd'

import { toJsonMap, useBatchGet, useContractingPartiesCollection } from '@top-legal/datastore'

import { ContractContext } from '../../../../Contexts'
import InstanceUsersContext, { InstanceUsersContextProps, PartiesInfo } from './InstanceUsersContext'

import { generateInstanceRolesActions } from '../../../../../../Organisations/redux/OrganisationsActions'
import { nestedPartyCharSeparator } from '../../../../Signatures/Utils/getSignatureHelpers'
import { emptyArr, emptyMap, emptyObj } from '../../../../../../Defaults'


/** **********************************************************************
 *           Compute contract contracting parties
 ********************************************************************** */
const useContractPartiesInfo = (invitedUsers: any[]): Pick<InstanceUsersContextProps, 'partiesInfo'> & { mustDisabled: boolean } => {
  const { contract: { parties, signaturesHolder: { signatures, signedDate } = emptyObj } } = useContext<any>(ContractContext)

  return useMemo(() => {
    const partiesInfo: PartiesInfo = {}

    if (Object(parties) === parties) {
      const processParty = (partyKey: string, partyData: any, parentKey: string[] = []) => {
        const partyID = partyData && (partyData.partyID || partyData.contactID || partyData)
        if (partyID && typeof partyID === 'string') {
          const key = [...parentKey, partyKey]

          partiesInfo[partyID] = {
            partyKey: key.join(nestedPartyCharSeparator),
            signer: partyData.signer,
            partyData,
            tokenData: partyData.tokenData
          }

          //  Loop over children
          if (Array.isArray(partyData.additionalUsers) && partyData.additionalUsers.length > 0) {
            Object.entries(partyData.additionalUsers).forEach(([index, data]) => processParty(index, data, key))
          }
        }
      }

      //  Loop over root parties
      Object.entries(parties).forEach(([index, data]) => processParty(index, data))
    }


    //  Iterate over the signatures to add the signedDate information
    if (Array.isArray(signatures)) {
      signatures.forEach(({ signatureID, partyID, signedDate: sigSignedDate }) => {
        if (partiesInfo[partyID]) {
          partiesInfo[partyID].signatureID = signatureID
          partiesInfo[partyID].signedDate = sigSignedDate
        }
      })
    }


    //  Iterate over the invitations token to add the info
    invitedUsers.forEach(tokenData => {
      const partyID = tokenData.partyID || tokenData.contactID
      if (partiesInfo[partyID]) {
        partiesInfo[partyID].tokenData = tokenData
      }
    })

    return { partiesInfo, mustDisabled: !!signedDate }
  }, [parties, signatures, invitedUsers, signedDate])
}


/** **********************************************************************
 *           Build the context provider
 ********************************************************************** */
interface InstanceUsersContextProviderProps {
  instanceType: 'contract' | 'template'
  instanceID: string
  internRoles?: InstanceUsersContextProps['internRoles']
}

const InstanceUsersContextProvider: React.FC<InstanceUsersContextProviderProps> = ({
  instanceType, instanceID, internRoles, children
}) => {
  //  Actions disabled
  const [actionsDisabled, setActionsDisabled] = useState(false)

  const dispatch = useDispatch()
  const { getInstanceRoles, updateInternalRole, deleteInternalRole } = useMemo(
    () => {
      const { getRoles, updateRole, deleteRole } = generateInstanceRolesActions(instanceType, instanceID)

      return {
        getInstanceRoles: getRoles,
        updateInternalRole: (userID, role) => dispatch(updateRole(userID, role)),
        deleteInternalRole: userID => dispatch(deleteRole(userID))
      }
    },
    [instanceType, instanceID, dispatch]
  )

  //  Load instance roles at mount
  const [loading, setLoading] = useState(!internRoles)
  useEffect(() => {
    !internRoles && (async () => {
      try {
        await dispatch(getInstanceRoles())
        setLoading(false)
      } catch (err) {
        setLoading(false)
        throw err
      }
    })()
  }, [internRoles, dispatch, getInstanceRoles])


  //  Get the roles
  const { instanceRoles = emptyArr, invitedUsers = emptyArr } = useSelector(state => state.organisation.instanceRoles[instanceID] || emptyObj)
  const { partiesInfo, mustDisabled } = useContractPartiesInfo(invitedUsers)
  const partiesCollection = useContractingPartiesCollection()
  const [partiesMap, partiesLoading] = useBatchGet(
    partiesCollection,
    useMemo(() => Object.keys(partiesInfo), [partiesInfo]),
    toJsonMap
  )

  //  Build groups
  const partiesGroups = useMemo(() => {
    const groups: InstanceUsersContextProps['partiesGroups'] = {}

    if (partiesMap) {
      Object.keys(partiesInfo).forEach(partyID => {
        const party = partiesMap.get(partyID)
        if (party) {
          const groupID = party.companyPartyID || party.partyID
          if (!groups[groupID]) {
            groups[groupID] = []
          }
          groups[groupID].push(partyID)
        }
      })
    }

    return groups
  }, [partiesInfo, partiesMap])


  //  Return while loading data
  if (loading || partiesLoading) {
    return (
      <>
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </>
    )
  }


  //  Return provider
  return (
    <InstanceUsersContext.Provider
      value={{
        internRoles: internRoles || instanceRoles,
        updateInternalRole,
        deleteInternalRole,

        partiesMap: partiesMap || emptyMap,
        partiesInfo,
        partiesGroups,

        actionsDisabled: mustDisabled || actionsDisabled,
        setActionsDisabled
      }}
    >
      {children}
    </InstanceUsersContext.Provider>
  )
}

export default InstanceUsersContextProvider
