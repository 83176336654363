import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t, Trans } from '@lingui/macro'
import { Button, Drawer, Form, Input, Steps } from 'antd'
import { RightOutlined } from '@ant-design/icons'

//  Contexts
import { UserAndCompanyDataContext } from '../../../Layouts/Constants'
import { ContractContext } from '../Contexts'
import { LayoutSidebarWidthContext } from '../../../Layouts/LayoutSidebar'

//  Custom components
import CustomFormWrapper, { requiredRules } from '../../../SharedComponents/CustomFormWrapper'
import { MediaCard, MediaLibrarySelector, saveRecordedMediaToLibrary } from '../../../Media/MediaLibrary'
import loadingModalFeedback from '../../../Alert/LoadingModalFeedback'

//  Styles
import './InviteFormDrawerStyles.scss'
import { DefaultSigningVideo } from '../../../ExternalViews/ExternalPageSigning'
import { inviteExternalParty } from '../../redux/ContractActions'

export const copyLink = link => {
  const elem = document.createElement('span')
  elem.innerText = link
  elem.style.position = 'fixed'
  elem.style.bottom = '0'
  elem.style.right = '0'
  elem.style.opacity = '0'
  document.body.appendChild(elem)

  const range = document.createRange()
  range.setStartBefore(elem)
  range.setEndAfter(elem)

  const selection = window.getSelection() as Selection
  selection.removeAllRanges()
  selection.addRange(range)

  document.execCommand('copy')
  selection.removeAllRanges()
  elem.remove()
  ;(window as any).notification.success({
    message: <Trans>Link copied!</Trans>
  })
}

export const copyLinkProps = (lang, token?: any, internal = false, contractID = undefined) => {
  const tokenStr = (token && token.token) || token
  let link = ''

  if (internal) {
    link = `${window.location.origin}/${lang}/contracts/${contractID}`
  } else if (tokenStr && typeof tokenStr === 'string') {
    link = `${window.location.origin}/${lang}/contract-portal/contracts/${tokenStr}`
  }

  return {
    'data-link': link,
    onClick: () => {
      copyLink(link)
    },
    style: { cursor: 'pointer' }
  }
}

export const getInviteEmailTexts = (userDisplayName, instanceName) => ({
  signing: t`Dear Sir/Madam,
I am writing to invite you to digitally sign the contract "${instanceName}" in a secure environment provided by top.legal.
Kind regards,
${userDisplayName}`,
  negotiation: t`Dear Sir/Madam,
I am writing to invite you to propose you the contract "${instanceName}" in a secure environment provided by top.legal where we can find a better suitable agreement for both of us.
Kind regards,
${userDisplayName}`,
  default: t`Dear Sir/Madam,
I am writing to invite you to ______
Kind regards,
${userDisplayName}`
})

interface InviteFormDrawerProps {
  visible?: boolean
  onComplete: () => void
  onCancel: () => void
  partyKey: string
  initialValue?: any
  inviteType?: 'default' | 'signing' | 'negotiation'
}

const InviteFormDrawer: React.FC<InviteFormDrawerProps> = ({
  visible,
  onComplete,
  onCancel,
  initialValue = {},
  partyKey,
  inviteType = 'default'
}) => {
  //  Some constants
  const { user } = useContext(UserAndCompanyDataContext)
  const { contract } = useContext<any>(ContractContext)

  const [startedRecord, setStartedRecord] = useState(false)
  const userDisplayName = user.firstName && user.lastName && `${user.firstName} ${user.lastName}`

  const mediaLibrary = useSelector(state => state.organisation.mediaLibrary)

  const emailTexts = useMemo(() => getInviteEmailTexts(userDisplayName, contract.contractName), [contract.contractName, userDisplayName])
  const emailTextPlaceholder = emailTexts[inviteType] || emailTexts.default

  const dispatch = useDispatch()

  // /////////////////////////////////////////////////////////////////
  //                       Some states
  // /////////////////////////////////////////////////////////////////
  const [currentStep, setCurrentStep] = useState(0)
  const [values, setValues] = useState<any>({})
  const prevValue = useRef(false)
  useEffect(() => {
    if (visible && !prevValue.current) {
      setCurrentStep(0)
      setValues({ ...initialValue })
    }
    prevValue.current = !!visible
  }, [visible, initialValue])

  // ==================================================================================================

  const sendInvite = newValues => loadingModalFeedback({
    loadingTitle: <Trans>Sending invite email</Trans>,

    successTitle: <Trans>Email sent</Trans>,
    successDescription: <Trans>We successfully sent the invite email.</Trans>,

    errorTitle: <Trans>Invitation failed</Trans>,
    errorDescription: <Trans>An error error occurred while creating the invitation to the contract. Please try again later.</Trans>,

    autoSuccessClose: 2000
  })(async () => {
    const data = {
      ...newValues,
      role: 'Viewer',
      instanceType: 'contract',
      instanceID: contract.contractID,
      party: partyKey,
      contactID: initialValue.partyID,
      securityLevel: 'open',
      inviteType: 'email'
    }
    await dispatch(inviteExternalParty(data, partyKey))
    await onComplete()
  })

  const onSubmit = async formValues => {
    const newValues = { ...values, ...formValues }
    setValues(newValues)

    if (values.inviteType === 'email' && currentStep === 2) {
      sendInvite(newValues)
    } else if (values.inviteType === 'link' && currentStep === 1) {
      sendInvite(newValues)
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  /** **********************************************************************
   *                  Rendering email settings
   ********************************************************************** */
  const renderFirstStep = () => (
    <div className='emailLinkInputStep'>
      <Form.Item
        initialValue={initialValue.inviteEmail || initialValue.email}
        label={<Trans>Invite email address</Trans>}
        name='inviteEmail'
        rules={requiredRules}
      >
        <Input className='simple-line' placeholder={t`Invite email address`} type='email' />
      </Form.Item>
      <Form.Item initialValue={initialValue.inviteText || emailTextPlaceholder} label={<Trans>Invite text</Trans>} name='inviteText' rules={requiredRules}>
        <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} className='simple-line' placeholder={t`Says hello`} size='small' />
      </Form.Item>
    </div>
  )

  /** **********************************************************************
   *                  Rendering video step
   ********************************************************************** */
  const renderVideoStep = () => (
    <div>
      <Form.Item shouldUpdate>
        {form => (
          <Form.Item initialValue={initialValue.introVideo} name='introVideo'>
            <MediaLibrarySelector
              defaultVideo={<DefaultSigningVideo />}
              media='audioVideo'
              onFinish={() => setTimeout(() => form.submit())}
              onStartRecord={() => setStartedRecord(true)}
            />
          </Form.Item>
        )}
      </Form.Item>
    </div>
  )

  /** **********************************************************************
   *                  Rendering confirmation
   ********************************************************************** */
  const renderConfirm = () => {
    const media = mediaLibrary[values.introVideo]

    return (
      <>
        {values.introVideo && media && (
          <div className='showMedia'>
            <MediaCard media={values.introVideo} viewerOnly />
            {media.hidden ? (
              <Button.Group className='autoFit'>
                <Button onClick={() => saveRecordedMediaToLibrary(media)} type='default'>
                  <Trans>Save to library</Trans>
                </Button>
                <Button
                  className='redoVideo'
                  onClick={() => {
                    setCurrentStep(currentStep - 1)
                    setStartedRecord(false)
                  }}
                  type='primary'
                >
                  <Trans>Change video</Trans>
                </Button>
              </Button.Group>
            ) : (
              <Button
                className='redoVideoOnly'
                onClick={() => {
                  setCurrentStep(currentStep - 1)
                  setStartedRecord(false)
                }}
                type='primary'
              >
                <Trans>Change video</Trans>
              </Button>
            )}
          </div>
        )}
        <div className='previewBox'>
          <h4>
            <Trans>Send an invite email to {values.inviteEmail}?</Trans>
          </h4>
          <Input.TextArea autoSize disabled value={values.inviteText} />
        </div>
      </>
    )
  }

  /** **********************************************************************
   *                  Rendering current step
   ********************************************************************** */
  const renderCurrentStep = () => {
    if (currentStep === 0) {
      return renderFirstStep()
    }
    if (currentStep === 1) {
      return renderVideoStep()
    }
    return renderConfirm()
  }

  /** **********************************************************************
   *                  Rendering root drawer
   ********************************************************************** */
  const previous = () => setCurrentStep(currentStep - 1)
  const width = useContext(LayoutSidebarWidthContext)

  const firstName = initialValue.firstName || ''
  const lastName = initialValue.lastName || ''

  return (
    <Drawer
      className='inviteUserDrawer'
      destroyOnClose
      onClose={onCancel}
      placement='right'
      style={{ '--sidebar-width': width } as any}
      title={
        <h2>
          <Trans>
            Invite {firstName} {lastName}
          </Trans>
        </h2>
      }
      visible={visible}
      zIndex={10020}
    >
      <Steps current={currentStep} direction='vertical'>
        <Steps.Step
          description={<Trans>Set the email content of your invite.</Trans>}
          title={
            <h1>
              <Trans>Invite</Trans>
            </h1>
          }
        />
        <Steps.Step
          description={<Trans>Add a personal video.</Trans>}
          title={
            <h1>
              <Trans>Personalise</Trans>
            </h1>
          }
        />
        <Steps.Step
          description={<Trans>Confirm and invite your contracting party.</Trans>}
          title={
            <h1>
              <Trans>Confirm & Send</Trans>
            </h1>
          }
        />
      </Steps>
      <CustomFormWrapper layout={'vertical'} onSubmit={onSubmit}>
        <div className='formContent'>{renderCurrentStep()}</div>
        <div className='actionButtons'>
          {(() => {
            if (currentStep === 2) {
              return (
                <>
                  <Button
                    className='backButton'
                    ghost
                    onClick={() => {
                      setStartedRecord(false)
                      previous()
                    }}
                  >
                    <Trans>Back</Trans>
                  </Button>
                  <Button className='skipButton' onClick={() => sendInvite(values)} type='primary'>
                    <Trans>Send</Trans>
                  </Button>
                </>
              )
            }
            if (currentStep === 1 && !startedRecord) {
              return (
                <>
                  <Button
                    className='backButton'
                    ghost
                    onClick={() => {
                      setStartedRecord(false)
                      previous()
                    }}
                  >
                    <Trans>Back</Trans>
                  </Button>
                  <Button className='skipButton' htmlType='submit' type='primary'>
                    <Trans>Skip</Trans>
                  </Button>
                </>
              )
            }
            if (currentStep === 0) {
              return (
                <>
                  <span />
                  <Button
                    className='nextButton' htmlType='submit' icon={<RightOutlined />} onClick={() => setStartedRecord(false)}
                    type='primary'
                  >
                    <Trans>Next</Trans>
                  </Button>
                </>
              )
            }
            return <span />
          })()}
        </div>
      </CustomFormWrapper>
    </Drawer>
  )
}

export default InviteFormDrawer
