import React, { useLayoutEffect, useState } from 'react'
import { CheckOutlined, DeleteOutlined, DeploymentUnitOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import { Button, Input, Select, Slider, Steps } from 'antd'
import MainContainer from '../Layouts/MainLayout/MainContainer'

import './NegotiationStrategizerStyles.scss'
import { LocalLoadingAlert } from '../Alert/LoadingAlert'
import { MediaLibrarySelector } from '../Media/MediaLibrary'

const CustomInput = ({ onChange, value: initialValue, ...props }) => {
  const [value, setValue] = useState()
  useLayoutEffect(() => { setValue(initialValue) }, [initialValue])
  return <Input {...props} onBlur={() => onChange(value)} onChange={evt => setValue(evt.target.value)} value={value} />
}

const AllFields = {
  billingPeriod: 'Billing installments',
  cPenalty: 'Cancellation penalty',
  emergency: 'Emergency maintenance',
  fixPeriod: 'Fix price period',
  ndPenalty: 'Non-delivery penalty',
  paymentDelay: 'Payment deadline',
  price: 'Price',
  remote: 'Remote maintenance'
}

const FieldSelector = props => (
  <Select {...props} className='simple-line'>
    {Object.entries(AllFields).map(([key, value]) => <Select.Option key={key} value={key}>{value}</Select.Option>)}
  </Select>
)

//  Key is used for accessing to the value in the object
const normalizationUpdate = (array, index, value, key = undefined) => {
  //  Return same array with no update when less or equal to 1
  if (!Array.isArray(array) || array.length <= 1) {
    return array
  }

  //  Getter and setter (need both because we don't have L-Value like in cpp)
  const getValue = key ? (ind => array[ind][key]) : (ind => array[ind])
  const setValue = key ? ((ind, val) => { array[ind][key] = val }) : ((ind, val) => { array[ind] = val })

  const total = (array.length * 50) - getValue(index)
  const newTotal = (array.length * 50) - value

  //  Update coef with the new total
  const excludeIndexes = [index]
  const redistributePercentage = (srcAmount, dstAmount) => {
    let newAmount = 0
    let delta = 0
    for (let ind = 0; ind < array.length; ind += 1) {
      if (!excludeIndexes.includes(ind)) {
        let newCoef = (getValue(ind) / srcAmount) * dstAmount
        if (newCoef > 100) {
          delta += newCoef - 100
          newCoef = 100
          excludeIndexes.push(ind)
        } else {
          newAmount += newCoef
        }
        setValue(ind, newCoef)
      }
    }

    if (delta > 0) {
      redistributePercentage(newAmount, newAmount + delta)
    }
  }
  redistributePercentage(total, newTotal)

  setValue(index, value)
  return array
}

const NegotiationStrategizer = () => {
  const [step, setStep] = useState(0)
  const [fields, setFields] = useState([{
    field: 'billingPeriod',
    values: ['1 week', '3 weeks', '6 weeks', '9 weeks', '12 weeks'],
    weights: [0, 25, 45, 80, 100],
    coef: 50
  }, {
    field: 'paymentDelay',
    values: ['5 days', '7 days', '14 days', '30 days', '60 days'],
    weights: [0, 40, 50, 60, 100],
    coef: 50
  }, {
    field: 'ndPenalty',
    values: ['30%', '40%', '50%', '70%', '100%'],
    weights: [0, 10, 50, 90, 100],
    coef: 50
  }, {
    field: 'remote',
    values: ['300€', '295€', '290€', '280€', '270€'],
    weights: [0, 25, 50, 75, 100],
    coef: 45
  }, {
    field: 'cPenalty',
    values: ['70%', '80%', '85%', '90%', '100%'],
    weights: [0, 25, 50, 75, 100],
    coef: 30
  }, {
    field: 'emergency',
    values: ['180€', '160€', '145€', '132€', '120€'],
    weights: [0, 25, 50, 75, 100],
    coef: 30
  }, {
    field: 'price',
    values: ['48€', '44€', '40€', '30€', '20€'],
    weights: [0, 25, 50, 75, 100],
    coef: 100
  }, {
    field: 'fixPeriod',
    values: ['1 year', '2 years', '3 years'],
    weights: [0, 50, 100],
    coef: 45
  }])

  const renderFields = () => (
    <>
      <div className='fieldsList'>
        {
          fields.map(({ field, values, weights }, index) => {
            const nbValues = Math.max(values.length, weights.length)
            const arr = [...new Array(nbValues)]
            return (
              <div key={index} className='field'>
                <div className='fieldSelector'>
                  <FieldSelector
                    onChange={value => setFields(oldArr => {
                      const newArr = [...oldArr]
                      newArr[index].field = value
                      return newArr
                    })}
                    value={field}
                  />
                </div>
                <div className='fieldValuation'>
                  <div className='fieldValues'>
                    <table>
                      <tr>
                        {arr.map((_, ind) => (
                          <td key={`${index}-${ind}`}>
                            <CustomInput
                              onChange={val => setFields(oldArr => {
                                const newArr = [...oldArr]
                                newArr[index].values[ind] = val
                                return newArr
                              })} value={values[ind] || ''}
                            />
                            <Button
                              danger ghost icon={<DeleteOutlined />} onClick={() => setFields(oldArr => {
                                const newArr = [...oldArr]
                                newArr[index].values.splice(ind, 1)
                                normalizationUpdate(newArr[index].weights, ind, 50)
                                newArr[index].weights.splice(ind, 1)
                                return newArr
                              })}
                              size='small'
                            />
                          </td>
                        ))}
                      </tr>
                      <tr>
                        {arr.map((_, ind) => (
                          <td key={`${index}-${ind}`}>
                            <div className='fieldWeightSlider'>
                              <Slider
                                onChange={num => setFields(oldArr => {
                                  const newArr = [...oldArr]
                                  normalizationUpdate(newArr[index].weights, ind, num)
                                  return newArr
                                })}
                                tooltipVisible={false} value={weights[ind]}
                              />
                              <span>{Math.round(weights[ind])}%</span>
                            </div>
                          </td>
                        ))}
                      </tr>
                    </table>
                    <Button
                      icon={<PlusOutlined />} onClick={() => setFields(oldArr => {
                        const newArr = [...oldArr]
                        newArr[index].values.push(newArr[index].values.slice(-1)[0])
                        newArr[index].weights.push(50)
                        return newArr
                      })} size='large' type='primary'
                    />
                  </div>
                  <Button
                    danger icon={<DeleteOutlined />} onClick={() => setFields(oldArr => {
                      const newArr = normalizationUpdate([...oldArr], index, 50, 'coef')
                      newArr.splice(index, 1)
                      return newArr
                    })} size='large'
                    type='primary'
                  />
                </div>
              </div>
            )
          })
        }
        <div className='field'>
          <div className='fieldValuation'>
            <div className='fieldValues' />
            <Button
              icon={<PlusOutlined />} onClick={() => setFields(oldArr => {
                const newArr = [...oldArr]
                newArr.push({
                  field: '', values: [''], weights: [0], coef: 50
                })
                return newArr
              })} size='large' type='primary'
            />
          </div>
        </div>
      </div>
      <div className='submitWrapper'>
        <Button icon={<CheckOutlined />} onClick={() => setStep(1)} type='primary'>Save and continue</Button>
      </div>
    </>
  )

  const [loading, setLoading] = useState(true)
  const updateCoefs = (index, value) => setFields(array => normalizationUpdate([...array], index, value, 'coef'))
  const renderSliders = () => (
    <>
      <div className='sliderList'>
        {
          fields.map(({ field, coef }, index) => (
            <div key={index} className='coefInput'>
              <div className='coefName'>{AllFields[field]}</div>
              <Slider onChange={val => updateCoefs(index, val)} tooltipVisible={false} value={coef} />
              <span>{Math.round(coef)}%</span>
            </div>
          ))
        }
      </div>
      <div className='submitWrapper'>
        <Button
          icon={<CheckOutlined />} onClick={() => {
            setStep(2)
            setTimeout(() => setLoading(false), 3000)
          }} type='primary'
        >Save and continue
        </Button>
      </div>
    </>
  )

  const [fineTune, setFineTune] = useState([50, 50, 50])
  const renderFineTuning = () => (
    <>
      {loading && <LocalLoadingAlert lightTheme loading />}
      <div className='fineTuningWrapper'>
        <div className='fineTuneOption'>
          <h2>Offer 1</h2>
          <ul>
            <li>{AllFields.price} of 34€</li>
            <li>{AllFields.paymentDelay} of 7 days</li>
            <li>{AllFields.billingPeriod} of 6 weeks</li>
            <li>{AllFields.ndPenalty} of 50%</li>
            <li>{AllFields.remote} of 285€</li>
            <li>{AllFields.fixPeriod} of 1 year</li>
            <li>{AllFields.emergency} of 155€</li>
            <li>{AllFields.cPenalty} of 80%</li>
          </ul>
          <div className='fieldWeightSlider'>
            <Slider
              onChange={num => setFineTune(oldArr => normalizationUpdate([...oldArr], 0, num))}
              tooltipVisible={false} value={fineTune[0]}
            />
            <span>{Math.round(fineTune[0])}%</span>
          </div>
        </div>
        <div className='fineTuneOption'>
          <h2>Offer 2</h2>
          <ul>
            <li>{AllFields.price} of 40€</li>
            <li>{AllFields.paymentDelay} of 14 days</li>
            <li>{AllFields.billingPeriod} of 6 weeks</li>
            <li>{AllFields.ndPenalty} of 50%</li>
            <li>{AllFields.remote} of 290€</li>
            <li>{AllFields.fixPeriod} of 3 year</li>
            <li>{AllFields.emergency} of 145€</li>
            <li>{AllFields.cPenalty} of 85%</li>
          </ul>
          <div className='fieldWeightSlider'>
            <Slider
              onChange={num => setFineTune(oldArr => normalizationUpdate([...oldArr], 1, num))}
              tooltipVisible={false} value={fineTune[1]}
            />
            <span>{Math.round(fineTune[1])}%</span>
          </div>
        </div>
        <div className='fineTuneOption'>
          <h2>Offer 3</h2>
          <ul>
            <li>{AllFields.price} of 45€</li>
            <li>{AllFields.paymentDelay} of 60 days</li>
            <li>{AllFields.billingPeriod} of 9 weeks</li>
            <li>{AllFields.ndPenalty} of 65%</li>
            <li>{AllFields.remote} of 280€</li>
            <li>{AllFields.fixPeriod} of 2 year</li>
            <li>{AllFields.emergency} of 132€</li>
            <li>{AllFields.cPenalty} of 90%</li>
          </ul>
          <div className='fieldWeightSlider'>
            <Slider
              onChange={num => setFineTune(oldArr => normalizationUpdate([...oldArr], 2, num))}
              tooltipVisible={false} value={fineTune[2]}
            />
            <span>{Math.round(fineTune[2])}%</span>
          </div>
        </div>
        <div className='nextBatch'>
          <Button icon={<RightOutlined />} onClick={() => null} type='default'>Next ( 1 / 5 )</Button>
        </div>
      </div>
      <div className='submitWrapper'>
        <Button icon={<CheckOutlined />} onClick={() => setStep(3)} type='primary'>Save and continue</Button>
      </div>
    </>
  )

  const [value, onChange] = useState('')
  const renderVideo = () => (
    <>
      <div className='negoVideoWrapper'>
        <MediaLibrarySelector media='audioVideo' onChange={onChange} value={value} />
      </div>
      <div className='submitWrapper'>
        <Button icon={<CheckOutlined />} onClick={() => setStep(1)} type='primary'>Save and finalize</Button>
      </div>
    </>
  )

  return (
    <MainContainer
      mainContentClass='strategizerWrapper'
      topbarContent={(
        <>
          <div className='topbarMainContent'>
            <DeploymentUnitOutlined className='headerIcon' twoToneColor='#3DBD7D' />
            <h1 className='title'><Trans>Negotiation Strategizer</Trans></h1>
          </div>
          <div className='topbarActions contractListingActions' />
        </>
      )}
    >
      <div className='strategizer'>
        <div className='stepsWrapper'>
          <Steps
            current={step} onChange={newStep => {
              if (newStep === 2) {
                setLoading(true)
                setStep(2)
                setTimeout(() => setLoading(false), 3000)
              } else {
                setStep(newStep)
              }
            }}
          >
            <Steps.Step title='Define interest' />
            <Steps.Step title='Weight interest' />
            <Steps.Step title='Model tuning' />
            <Steps.Step title='Personal video' />
          </Steps>
        </div>
        <div className='strategizerContent'>
          {(() => {
            if (step === 0) {
              return renderFields()
            }
            if (step === 1) {
              return renderSliders()
            }
            if (step === 2) {
              return renderFineTuning()
            }
            if (step === 3) {
              return renderVideo()
            }
            return null
          })()}
        </div>
      </div>
    </MainContainer>
  )
}

export default NegotiationStrategizer
