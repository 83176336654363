import React, { useContext } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useDispatch } from 'react-redux'
import { Trans } from '@lingui/macro'
import { EditOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import { useSectionsCollection } from '@top-legal/datastore'

import { ConfirmButton } from '../SharedComponents/FormComponents/index'
import { unFreezeContractAction } from './ContractPageContext'
import { ContractContext } from './ContractEditor/Contexts'

const ContractPageButtons = () => {
  const { url } = useRouteMatch()
  const history = useHistory()

  const { contract } = useContext<any>(ContractContext)
  const dispatch = useDispatch()
  const hasExternalParty: boolean = (Object(contract.parties) === contract.parties) && Object.keys(contract.parties).some(item => {
    const partyID = contract.parties[item].partyID || contract.parties[item].contactID || contract.parties[item]
    return (typeof partyID === 'string') && (partyID.startsWith('contact-') || partyID.startsWith('party-'))
  })

  const sectionsCollection = useSectionsCollection()

  if (contract.contractID && !contract.deleted && !['frozen', 'signed', 'imported'].includes(contract.contractStatus)) {
    const res: any[] = []
    if (!contract.pdfSigningMode) {
      //  Signing button would be portal in by InternalRoleCard
      res.push(<div key='topBarSignButton' id='topBarSignButton' />)
    }

    const publishButton = hasExternalParty && (
      <Button
        key='publishButton'
        onClick={() => history.push(`${url}/publish`)}
      >
        {contract.sentOverDate ? <span><span id='publishButtonCounter' /><Trans>Publish</Trans></span> : <Trans>Send to Counter-party</Trans>}
      </Button>
    )

    if (!contract.pdfSigningMode && contract.draftedDate) {
      res.splice(0, 0, publishButton)
    }

    return res
  }

  if (contract.contractStatus === 'frozen') {
    return (
      <ConfirmButton
        key='signingButton'
        confirmMessage={<Trans>Are you sure you want to move back to negotiation stage?</Trans>}
        // eslint-disable-next-line no-script-url
        ghost
        href='javascript:void(0)'
        icon={<EditOutlined />}
        onClick={() => dispatch(unFreezeContractAction(sectionsCollection))}
        placement='bottomRight'
        type='primary'
      >
        <Trans>Back to editing</Trans>
      </ConfirmButton>
    )
  }

  return null
}

export default ContractPageButtons
