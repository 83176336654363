import React from 'react'
import TreeEdition from './TreeEdition'

// ==================================================================================================
// ==================================================================================================
class TreeEditionObjectValueType extends React.Component {
  // ==================================================================================================

  render = () => {
    const {
      children, value, onChange, keyName = 'key', valueName, ...rest
    } = this.props

    return (
      <TreeEdition
        {...rest}
        onChange={newValue => onChange(newValue.reduce((acc, obj) => {
          const key = obj[keyName]
          delete obj[keyName]
          return {
            ...acc,
            [key]: (valueName ? obj[valueName] : obj)
          }
        }, {}))}
        value={Object.keys(value)
          .map(key => ({
            [keyName]: key,
            ...(valueName
              ? { [valueName]: value[key] }
              : value[key]
            )
          }))}
      >
        {children}
      </TreeEdition>
    )
  }
}

export default TreeEditionObjectValueType
