import { Element } from 'slate'
import { PlatePlugin } from '@udecode/plate-core'

import { isInlineNodeFactory, isVoidNode, MarkerTypeBlock, MarkerTypeInline, SectionRefType, TemplateEntityType as SlateTemplateEntityType } from '../Constants'
import { EnabledPlugins } from '../PlatePlugins'

export const templateEntities = ['inputField', 'conditionalText', 'sectionReference'] as const
export type TemplateEntityType = typeof templateEntities[number]

export interface TemplateEntityNode extends Element {
  entityType: TemplateEntityType,
  key: string,
  version: string
}

export interface TemplateEntityProps {
  attributes: any,
  dataKey: string,
  version: number
}

const makeTemplateEntitiesPlugin = ({
  getField, getCond, onEntityAdded, onEntityRemoved
}: NonNullable<EnabledPlugins['templateEntities']>): PlatePlugin => ({
  //  Main plugin key
  key: SlateTemplateEntityType,
  isElement: true,

  //  provide void & inline check
  withOverrides: editor => {
    const isInlineNode = isInlineNodeFactory(getField, getCond)
    const { isInline, isVoid, onChange } = editor

    editor.isInline = element => isInlineNode(element) || isInline(element)
    editor.isVoid = element => isVoidNode(element) || isVoid(element)

    //  Add entity handler
    if (onEntityAdded && onEntityRemoved) {
      editor.onChange = () => {
        editor.operations.forEach(ope => {
          if (ope.type === 'insert_node') {
            //  We need to inspect all the nodes to find entities
            onEntityAdded(ope.node)
          }
          if (ope.type === 'remove_node') {
            //  We need to inspect all the nodes to find entities
            onEntityRemoved(ope.node)
          }
        })

        onChange?.()
      }
    }

    return editor
  },

  //  Provide a parser of our types
  deserializeHtml: {
    getNode: elm => {
      const entityType = templateEntities.find(type => elm.classList.contains(type))
      const { key, version: verStr } = elm.dataset

      let version = parseInt(verStr as any, 10)
      if (Number.isNaN(version)) {
        version = 1
      }

      if (entityType && key) {
        return {
          type: SlateTemplateEntityType, entityType, key, version, children: [{ text: '' }]
        }
      }
      return undefined
    }
  },

  //  Define sub plugins
  plugins: [{
    key: MarkerTypeBlock,
    isElement: true
  }, {
    key: MarkerTypeInline,
    isElement: true,
    isInline: true
  }, {
    key: SectionRefType,
    isElement: true,
    isInline: true,
    isVoid: true
  }]
})

export default makeTemplateEntitiesPlugin
