import Auth from '@aws-amplify/auth'
import { ConsoleLogger as Logger } from '@aws-amplify/core'
import React from 'react'
import RESTService from '../RestService'

// const
const logger = new Logger('action user')

export const SAVE_DYNAMIC_CONFIG = 'SAVE_DYNAMIC_CONFIG'
export const SAVE_USER_PROFILE = 'SAVE_USER_PROFILE'

// ==================================================================================================
/**
 * registers a user against the cognito user pool and updates the endpoint. REgistration data
 * is saved to the store to be retrieved during the email confirmation step. Sets the state to
 * display the confirmation input field.
 * @param {object} values
 * @param {string} type
 */
export const registerUser = (values, type, inviteToken = null) => async dispatch => {
  logger.info('values: ', values)

  // preparing the user attributes
  const attributes = {
    email: values.email.toLowerCase(),
    family_name: values.lastName,
    given_name: values.firstName,
    gender: values.gender,
    phone_number: values.phoneNumber.replace(/[ _]/g, ''),
    ...(values.roleInCompany ? { 'custom:role': values.roleInCompany } : {}),
    'custom:preferredLanguage': values.preferredLanguage,
    'custom:type': type,
    //  TODO: Move to whitelabel version instead of HardCoded one for transferinitiative
    'custom:distributor':
        window.IS_TRANSFERINITIATIVE ? 'transferinitiative'
          : ((window.location.hostname.startsWith('ch-') && 'top.legal Zurich') || 'top.legal'),
    'custom:title': values.title || '-',
    ...(inviteToken ? { 'custom:registerToken': inviteToken } : {}),
    ...(values.companyName ? {
      'custom:companyName': values.companyName,
      'custom:companyType': values.companyType
    } : {})
  }

  const username = values.email.toLowerCase()
  const { password } = values

  logger.info('the user attributes: ', attributes)
  logger.info('the user name: ', username)

  // saving the user data to the AWS cognito pool
  const SignUpData = await Auth.signUp({
    username,
    password,
    attributes
  })

  /**
   * if the client registers from the client registration page without the post-a-project module
   * the company name and the company type are provided, which will be saved to the DB.
   */
  if (values.companyName) {
    attributes.companyName = values.companyName
    attributes.companyType = values.companyType
  }
}
// ==================================================================================================
/**
 * function retrieves the user profile data from the DB. the user is authenticated via the cognito
 * token obtained from the store
 */
export const getUserProfileData = () => async dispatch => {
  const userProfileData = await RESTService('GET', '/user/profile')

  dispatch({
    type: SAVE_USER_PROFILE,
    payload: userProfileData
  })

  return userProfileData
}

// ==================================================================================================

export const updateUserProfile = newData => async (dispatch, getState) => {
  const profileData = { ...getState().user.ownProfileData, ...newData }
  const res = await RESTService('PUT', '/user/profile', { profileUpdateData: profileData })

  // updating the store with the new profile data
  dispatch({
    type: SAVE_USER_PROFILE,
    payload: { ...profileData, ...res }
  })
}

// ==================================================================================================

export const loadDynamicConfig = configID => async dispatch => {
  const config = await RESTService('GET', `/dynamicConfigs/${configID}`, undefined, false)

  dispatch({
    type: SAVE_DYNAMIC_CONFIG,
    payload: config
  })
}
