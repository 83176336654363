import React, { useContext, useState } from 'react'
import { MoreOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Drawer, Dropdown, Menu } from 'antd'
import { Trans } from '@lingui/macro'
import { useRouteMatch } from 'react-router'
import ButtonLink from '../../SharedComponents/ButtonLink'
import { ContractListingContext } from './ContractContainerWrapper'

import './ContractActionsStyles.scss'
import { DeviceContext } from '../../../GlobalContext'
import CreateContractDrawer from '../../Contract/ContractEditor/CreateContractDrawer'

const ContractActions: React.FC = () => {
  const { url } = useRouteMatch()
  const isSmall = useContext(DeviceContext) !== 'desktop'
  const { setShowUploadModal } = useContext(ContractListingContext)
  const [showUploadDrawer, setShowUploadDrawer] = useState(false)
  const [visible, setVisible] = useState<boolean>(false)

  const drawer = (
    <Drawer
      className='contractUploadDrawer'
      onClose={() => setShowUploadDrawer(false)}
      placement='right'
      title={
        <h2>
          <Trans>Document Upload</Trans>
        </h2>
      }
      visible={showUploadDrawer}
    >
      <h1>
        <Trans>What would you like to do after uploading?</Trans>
      </h1>
      <div className='uploadChoicesWrapper'>
        <div className='uploadChoices'>
          <ButtonLink href={`${url.replace('/listing', '')}/pdfSigning/new`} size='large' type='primary'>
            <Trans>Sign Contract</Trans>
          </ButtonLink>
          <Button
            ghost
            onClick={() => {
              setShowUploadModal(true)
              setShowUploadDrawer(false)
            }}
            size='large'
            type='primary'
          >
            <Trans>Manage Contract</Trans>
          </Button>
        </div>
      </div>
    </Drawer>
  )

  if (isSmall) {
    return (
      <>
        <CreateContractDrawer visible={visible} />
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item>
                <PlusOutlined />
                <Button icon={<PlusOutlined />} onClick={() => setVisible(true)} type='primary'>
                  <Trans>Create new</Trans>
                </Button>
              </Menu.Item>
              <Menu.Item onClick={() => setShowUploadDrawer(true)}>
                <UploadOutlined />
                <span>
                  <Trans>Upload Contract</Trans>
                </span>
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <Button
            className='noBorder moreButton'
            ghost
            // eslint-disable-next-line no-script-url
            href='javascript:void(0)'
            icon={<MoreOutlined />}
          />
        </Dropdown>
        {drawer}
      </>
    )
  }
  const handleVisible = (open: boolean) => {
    setVisible(open)
  }

  return (
    <>
      <CreateContractDrawer handler={handleVisible} visible={visible} />
      <Button icon={<PlusOutlined />} onClick={() => handleVisible(true)} type='primary'>
        <Trans>Create new</Trans>
      </Button>
      <Button
        className='phoneHidden' ghost href='javascript:void(0)' icon={<UploadOutlined />}
        onClick={() => setShowUploadDrawer(true)} type='primary'
      >
        <Trans>Upload Contract</Trans>
      </Button>
      {drawer}
    </>
  )
}
export default ContractActions
