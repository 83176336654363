import React, { useEffect, useState } from 'react'
import { useQueryParams, StringParam } from 'use-query-params'
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons'
import {
  Alert, Button, Col, Form, Input, Row, Select
} from 'antd'
import { t, Trans } from '@lingui/macro'

import Auth from '@aws-amplify/auth'
import { ConsoleLogger as Logger } from '@aws-amplify/core'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'

//  Components
import * as Sentry from '@sentry/browser'
import { PhoneInput } from '../SharedComponents/FormComponents/index'
import AmplifyError from '../Error/AmplifyError'
import CustomFormWrapper from '../SharedComponents/CustomFormWrapper'

//  Actions
import { registerUser } from '../../actions/action_user'

//  Images
import { LANGS_AVAILABLE } from '../../Routes'
import GoogleOAuth from './OAuthProviders/GoogleOAuth'
import { noRegistrationTokenModal } from './LoginRegistrationPage'
import loadingModalFeedback from '../Alert/LoadingModalFeedback'

const { Option } = Select

const logger = new Logger('RegistrationForm')

const TermsAndConditions = () => {
  const { lang } = useParams()

  return (
    <p className='termsAndConditions'>
      <Trans>
        By registering, I agree to the <a
          href={
            lang === 'de'
              ? 'https://www.top.legal/de/agb'
              : 'https://www.top.legal/de/agb'
          }
          rel='noopener noreferrer'
          target='_blank'
        >
          general terms and conditions
        </a> and the <a
                                         href={
            lang === 'de'
              ? 'https://www.top.legal/de/datenschutz'
              : 'https://www.top.legal/de/datenschutz'
          }
                                         rel='noopener noreferrer'
                                         target='_blank'
                                                    >
          data protection
        </a> declaration of top.legal GmbH.
      </Trans>
    </p>
  )
}

// ==================================================================================================
// ==================================================================================================

const ClientRegistrationForm = ({ inviteData, userType: userTypeProps }) => {
  // ==================================================================================================
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [userType, setUserType] = useState(null)
  const [registrationStep, setRegistrationStep] = useState(1)
  const [formData, setFormData] = useState({ ...(inviteData || {}) })
  const { lang } = useParams()
  const [params] = useQueryParams({
    inviteToken: StringParam,
    email: StringParam,
    Email: StringParam,
    username: StringParam
  })
  const { inviteToken } = params
  const localUserType = params.userType || userTypeProps
  let email = params.email || params.Email || params.username || (inviteData && inviteData.email)
  const history = useHistory()

  const dispatch = useDispatch()

  if (email) {
    email = decodeURI(email)
  }


  useEffect(() => {
    onUserTypeChange(localUserType || 'owner')
  }, [localUserType])

  // ==================================================================================================

  const onUserTypeChange = type => {
    setUserType(type)
  }

  // ==================================================================================================
  const handleFormSubmit = async values => {
    setLoading(true)
    setError(null)

    // checking if this is the first step of the registration process
    if (registrationStep === 1) {
      logger.info('the user properties: ', values)
      values.email = values.email.toLowerCase()

      //  Setting the user for sentry
      Sentry.setUser({
        email: values.email,
        id: 'RegisterPhase'
      })

      if (window.analytics) {
        window.analytics.track('RegisterPhase', {
          email: values.email
        })
      }

      try {
        await Auth.signIn(values.email, values.password)

        // send the user to login if the user is known already
        history.push(`/${lang}/login`)
      } catch (errorSubmit) {
        logger.error('login error: ', errorSubmit)
        /**
         * if the user is not found, then continue to the second
         * step of the registration process, as the user is currently not in the
         * database.
         */
        if (errorSubmit.code === 'UserNotFoundException') {
          setLoading(false)
          setRegistrationStep(2)
          setFormData(prevFormData => (
            { ...prevFormData, ...values }
          ))
        } else {
          setError(errorSubmit.code)
        }
      }
    } else {
      let text = ''
      let setErrorDescription = str => { text = str }
      const ErrorDescription = () => {
        const [errorDescriptionText, setErrorDescriptionText] = useState(text)
        setErrorDescription = setErrorDescriptionText
        return errorDescriptionText
      }

      await loadingModalFeedback({
        loadingTitle: <Trans>Registration in progress</Trans>,
        successTitle: <Trans>Account created</Trans>,
        errorTitle: <Trans>Failed to create new account</Trans>,
        errorDescription: <ErrorDescription />,
        autoSuccessClose: 2000
      })(async () => {
        try {
          await dispatch(registerUser(
            {
              ...formData,
              ...values
            },
            userType,
            inviteToken
          ))
          setRegistrationStep(3)
          setLoading(false)
        } catch (err) {
          setError(err.code)
          logger.info('registration error:', err)

          if (err.code === 'InvalidParameterException') {
            setErrorDescription(err.message)
          } else if (err.code === 'UserLambdaValidationException') {
            if (err.message.includes('NO_REGISTER_TOKEN')) {
              noRegistrationTokenModal()
            }
          }
          setLoading(false)
          throw err
        }
      })
    }
  }

  // ==================================================================================================


  if (registrationStep === 3) {
    return (
      <>
        <div className='headerRow'>
          <h1 className='headerTitle'>
            <Trans>Registration</Trans>
          </h1>
          <h2 className='headerSubTitle'>
            <Trans>Your registration was successful.</Trans>
          </h2>
        </div>
        <Alert
          className='bigAlert'
          description={<Trans id='email.verification.sent' />}
          message={<Trans id='email.verification' />}
          showIcon
          type='success'
        />
      </>
    )
  }

  return (
    <>
      <div className='headerRow'>
        <h1 className='headerTitle'>
          <Trans>Registration</Trans>
        </h1>
      </div>
      <CustomFormWrapper className='registrationForm' onSubmit={handleFormSubmit}>
        {form => {
          if (email && !form.getFieldValue('email')) {
            setTimeout(() => form.setFieldsValue({ email }))
          }

          const buttonRow = (
            <Row className='submitButtonRow' justify='center' type='flex'>
              <Col span={24}>
                <Form.Item>
                  <Button
                    block
                    className='submitButton'
                    htmlType='submit'
                    loading={loading}
                    size='large'
                    type='primary'
                  >
                    <Trans id='Register' />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          )

          if (registrationStep === 1) {
            return (
              // ==================================================================================================
              // registration step 1
              <>
                <AmplifyError errorCode={error} />
                <Form.Item
                  initialValue={email || formData.email}
                  name='email'
                  rules={[{
                    required: true,
                    type: 'email',
                    message: t`Please enter a valid e-mail address`
                  }]}
                >
                  <Input
                    placeholder={t`Email`}
                    prefix={<MailOutlined style={{ fontSize: 13 }} />}
                    size='large'
                  />
                </Form.Item>
                <Form.Item
                  name='password' rules={[
                    () => ({
                      validator (rule, value) {
                        if (!value || value.length >= 6) {
                          return Promise.resolve()
                        }
                        return Promise.reject(new Error(t`The password needs to have at least 6 fields (lower case (a-z), capital case (A-Z) and digits (0-9))`))
                      }
                    }),
                    {
                      required: true,
                      type: 'string',
                      message: t`The password needs to have at least 6 fields (lower case (a-z), capital case (A-Z) and digits (0-9))`,
                      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,20}$/g,
                      min: 6
                    }
                  ]}
                >
                  <Input
                    placeholder={t`Password`}
                    prefix={<LockOutlined style={{ fontSize: 13 }} />}
                    size='large'
                    type='password'
                  />
                </Form.Item>
                <Form.Item
                  hasFeedback name='passwordConfirmation' rules={[
                    ({ getFieldValue }) => ({
                      validator (rule, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(new Error(t`The two passwords that you entered do not match`))
                      }
                    })
                  ]}
                >
                  <Input
                    placeholder={t`Password confirmation`}
                    prefix={<LockOutlined style={{ fontSize: 13 }} />}
                    size='large'
                    type='password'
                  />
                </Form.Item>
                {buttonRow}
                <div className='socialProviders'>
                  <span className='socialOrText'><Trans>OR</Trans></span>
                  <div className='socialProvidersButtons'>
                    <GoogleOAuth />
                    {/* <MicrosoftOAuth /> */}
                    {/* <SalesForceOAuth /> */}
                    {/* <LinkedinOAuth /> */}
                  </div>
                </div>
                <TermsAndConditions />
              </>
            )
          }

          // ==================================================================================================
          // registration step 2

          const gender = t`Gender`
          const title = t`Title`
          const optional = t`(optional)`
          const firstName = t`First name`
          const lastName = t`Last name`
          const phoneNumber = t`phone number`
          const language = t`Language`

          return (
            <>
              <Input.Group className='flexGroup'>
                <Col span={12}>
                  <Form.Item
                    name='gender'
                    rules={[{
                      required: true,
                      type: 'string',
                      message: t`Please select a ${gender || ''}`
                    }]}
                  >
                    <Select
                      multiple
                      placeholder={`- ${gender} -`}
                    >
                      <Select.Option value='m'>
                        <Trans>Mr.</Trans>
                      </Select.Option>
                      <Select.Option value='f'>
                        <Trans>Ms.</Trans>
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='title'
                    rules={[{
                      type: 'string',
                      message: t`Please select a ${title || ''}`
                    }]}
                  >
                    <Select allowClear placeholder={`- ${title} ${optional} -`}>
                      <Select.Option value='Prof.'>
                        <Trans>Prof.</Trans>
                      </Select.Option>
                      <Select.Option value='Dr.'>
                        <Trans>Dr.</Trans>
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Input.Group>
              <Form.Item
                name='firstName'
                rules={[{
                  required: true,
                  type: 'string',
                  message: t`Please provide your ${firstName || ''}`
                }]}
              >
                <Input
                  placeholder={firstName}
                  prefix={<UserOutlined style={{ fontSize: 13 }} />}
                  size='large'
                />
              </Form.Item>
              <Form.Item
                name='lastName'
                rules={[{
                  required: true,
                  type: 'string',
                  message: t`Please provide your ${lastName || ''}`
                }]}
              >
                <Input
                  placeholder={lastName}
                  prefix={<UserOutlined style={{ fontSize: 13 }} />}
                  size='large'
                />
              </Form.Item>

              <Form.Item
                name='phoneNumber'
                rules={[{
                  required: true,
                  type: 'string',
                  message: t`Please provide your ${phoneNumber || ''}`
                }]}
                style={{ marginTop: 25 }}
              >
                <PhoneInput />
              </Form.Item>
              <Row>
                <Col span={12}>
                  <Form.Item
                    initialValue={lang}
                    name='preferredLanguage'
                    rules={[{
                      required: true,
                      type: 'string',
                      message: t`Please provide a ${language || ''}`
                    }]}
                  >
                    <Select placeholder={language}>
                      {LANGS_AVAILABLE.map(availableLang => (
                        <Option key={availableLang} value={availableLang}>
                          {availableLang === 'en' ? 'English' : 'Deutsch'}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {buttonRow}
            </>
          )
        }}
      </CustomFormWrapper>
    </>
  )
}


export default ClientRegistrationForm
