/* eslint-disable no-plusplus */
import React, { useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { t, Trans } from '@lingui/macro'
import { Button, Empty, Progress, Select, Tabs } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import moment from 'moment'
import './DealsStyles.scss'

const Deals = ({ data, inputFields, fieldResponses }) => {
  const [history, setHistory] = useState(6)
  const [selectedFieldID, setSelectedFieldID] = useState(null)

  const labels = useMemo(() => {
    const tmp = []
    for (let counter = 0; counter < history; counter++) {
      tmp.push(moment().subtract(history - counter - 1, 'month').format('MMM-YY'))
    }
    return tmp
  }, [history])

  const contractsObject = useMemo(() => {
    const contracts = {}
    data.forEach(contract => {
      contracts[contract.contractID] = contract
    })
    return contracts
  }, [data])


  const signedValues = useMemo(() => {
    const values = {}
    const initialArray = new Array(history)
    initialArray.fill(0)
    Object.keys(inputFields).forEach(inputFieldID => [
      Object.keys(inputFields[inputFieldID]).forEach(version => {
        if (inputFields[inputFieldID][version].type === 'number') {
          values[inputFieldID] = { values: [...initialArray], ...inputFields[inputFieldID][version], nContracts: [...initialArray] }
        }
      })
    ])
    const currentDate = new Date()
    const currentMonth = currentDate.getMonth()
    const currentYear = currentDate.getFullYear()
    Object.keys(fieldResponses).forEach(contractID => {
      Object.keys(fieldResponses[contractID]).forEach(fieldID => {
        if (values[fieldID]) {
          if (contractsObject[contractID] && contractsObject[contractID].signedDate) {
            const month = (new Date(contractsObject[contractID].signedDate)).getMonth()
            const year = (new Date(contractsObject[contractID].signedDate)).getFullYear()
            const index = history - (currentMonth - month) - 1 - (currentYear - year) * 12
            if (index > 0) {
              values[fieldID].values[index] += parseInt(fieldResponses[contractID][fieldID].value, 10)
              values[fieldID].nContracts[index] += 1
            }
          }
        }
      })
    })
    return values
  }, [contractsObject, history, fieldResponses, inputFields])
  const fieldObject = useMemo(
    () => signedValues[selectedFieldID]
    , [signedValues, selectedFieldID]
  )


  const chartData = {
    datasets: [{
      label: selectedFieldID ? fieldObject.name : t`Deal Value`,
      data: selectedFieldID ? fieldObject.values : [],
      yAxisID: 'values',
      backgroundColor: '#5678a4',
      borderColor: '#5678a4',
      hoverBackgroundColor: '#5678a4',
      hoverBorderColor: '#5678a4',
      type: 'line',
      fill: false
    }, {
      label: t`Signed Contracts`,
      data: selectedFieldID ? fieldObject.nContracts : [],
      yAxisID: 'contracts',
      backgroundColor: '#3dbd7d',
      borderColor: '#3dbd7d',
      hoverBackgroundColor: '#3dbd7d',
      hoverBorderColor: '#3dbd7d'
    }],
    labels
  }
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    hoverMode: 'index',
    stacked: false,
    animation: {
      duration: 1000,
      easing: 'easeInCubic'
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false
          },
          labels
        }
      ],
      yAxes: [{
        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
        display: true,
        position: 'right',
        id: 'values'
        // grid line settings

      },
      {
        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
        display: true,
        position: 'left',
        id: 'contracts',
        // grid line settings
        gridLines: {
          drawOnChartArea: false // only want the grid lines for one axis to show up
        }
      }]
    }
  }

  return (
    <div className='dealsBox'>
      <div className='fieldSelect'>
        <h4><Trans>Select contract field : </Trans></h4>
        <Select className='simple-line' onChange={value => setSelectedFieldID(value)} placeholder={<Trans>Please select a field</Trans>} size='small'>
          {Object.keys(signedValues).map(fieldID => (
            <Select.Option key={fieldID} value={fieldID}>
              <div classNmae='fieldName'>
                <span className='inputField'>{signedValues[fieldID].name} {signedValues[fieldID].unit ? `(${signedValues[fieldID].unit})` : ''}</span>
                {signedValues[fieldID] && (
                  <div>
                    <Trans>{signedValues[fieldID].nContracts.reduce((a, b) => a + b, 0)} signed</Trans>
                  </div>
                )}
              </div>
            </Select.Option>
          ))}
        </Select>
      </div>
      <Tabs defaultActiveKey='current'>
        <Tabs.TabPane key='current' tab='Current Month'>
          {selectedFieldID && fieldObject.nContracts.slice(-1)[0] > 0 && (
            <div className='dealValue'>
              <div className='valuesSummary'>
                <div>
                  <h4 className='name'><Trans>Sum of signed values:</Trans></h4>
                  <h4 className='value'>{fieldObject.values.slice(-1)[0]}{signedValues[selectedFieldID].unit ? ` ${signedValues[selectedFieldID].unit}` : ''}</h4>
                </div>
                <div>
                  <h4 className='name'><Trans>Contracts signed:</Trans></h4>
                  <h4 className='value'>{fieldObject.nContracts.slice(-1)[0]}</h4>
                </div>
                <div>
                  <h4 className='name'><Trans>Average per contract:</Trans></h4>
                  <h4 className='value'>{Math.round(fieldObject.values.slice(-1)[0] / (fieldObject.nContracts.slice(-1)[0] === 0 ? 1 : fieldObject.nContracts.slice(-1)[0]))} {signedValues[selectedFieldID].unit ? `${signedValues[selectedFieldID].unit}` : ''}</h4>
                </div>
              </div>
              <div className='valuesChart'>
                {(() => {
                  // eslint-disable-next-line max-len
                  const stat = Math.trunc((fieldObject.values.slice(-1)[0] - fieldObject.values.slice(-2)[0]) * 100 / (fieldObject.values.slice(-2)[0] === 0 ? 1 : fieldObject.values.slice(-2)[0]))
                  return (
                    <>
                      <Progress
                        className={stat > 0 ? 'posProgress' : 'negProgress'}
                        format={percent => `${stat > 0 ? '+' : '-'} ${percent}%`}
                        gapDegree={30}
                        gapPosition='top'
                        percent={Math.abs(stat)}
                        strokeColor={stat > 0 ? {
                          '0%': '#108ee9',
                          '100%': '#87d068'
                        } : {
                          '0%': '#fa0',
                          '100%': '#e94a4c'
                        }}
                        type='dashboard'
                        width={80}
                      />
                      <p className='description'>
                        <Trans>Compared to last month</Trans>
                      </p>
                    </>
                  )
                })()}
              </div>
            </div>
          )}
          {selectedFieldID && fieldObject.nContracts.slice(-1)[0] === 0 && <Empty className='noData' description={<Trans>No contract with the selected field has been signed this month.</Trans>} />}
          {!selectedFieldID
            && <Empty className='noData' description={<Trans>Please select a field to display statistics.</Trans>} />}
        </Tabs.TabPane>
        <Tabs.TabPane key='history' tab='History'>
          {!selectedFieldID && (
            <Empty className='noData' description={<Trans>Please select a field to display statistics.</Trans>} />
          )}
          {selectedFieldID
            && (
              <div className='historyChart'>
                {false && (
                  <Button
                    className='loadMore'
                    icon={<ArrowLeftOutlined />}
                    onClick={() => {
                      const nHistory = history
                      if (nHistory < 15) {
                        setHistory(nHistory + 3)
                      }
                    }}
                    shape='circle'
                    size='small'
                    type='ghost'
                  />
                )}
                <Bar
                  data={chartData}
                  options={options}
                  type='vertical'
                />
              </div>
            )}
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default {
  key: 'deals',
  title: <Trans>Signed Values</Trans>,
  Component: Deals,
  width: 4,
  height: 6,
  mobileHeight: 1
}


/*
 <h2 className='sumValue'>
                  {fieldObject.values.slice(-1)[0]} {signedValues[selectedFieldID].unit ? `${signedValues[selectedFieldID].unit}` : ''}
                </h2>
                <h4><Trans>Total of {<span className='inputField'>{fieldObject.name}</span>} signed  this month.</Trans></h4>
              </div>
              <div className='nSigned'>
                <h4><Trans>{fieldObject.nContracts.slice(-1)[0]} Contracts Signed with <span className='inputField'>{fieldObject.name}</span></Trans></h4>
                <p className='description'><Trans>Average of {Math.round(fieldObject.values.slice(-1)[0] / (fieldObject.nContracts.slice(-1)[0] === 0 ? 1 : fieldObject.nContracts.slice(-1)[0]))} per contract</Trans></p>
              </div>
              <div className='comparison'>
                <Statistic
                  precision={2}
                  prefix={(fieldObject.values.slice(-1)[0] - fieldObject.values.slice(-2)[0]) < 0 ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                  suffix='%'
                  value={((fieldObject.values.slice(-1)[0] - fieldObject.values.slice(-2)[0]) * 100 / (fieldObject.values.slice(-1)[0]) === 0 ? 1 : fieldObject.values.slice(-1)[0]) || '--'}
                  valueStyle={{ color: (fieldObject.values.slice(-1)[0] - fieldObject.values.slice(-2)[0]) < 0 ? '#cf1322' : '#3f8600' }}
                />
                <h4><Trans>Compared to {labels.slice(-2)[0]} <br />with total <span className='inputField'>{fieldObject.name}</span> of {fieldObject.values.slice(-2)[0]}</Trans></h4>
              </div>
            </div>
*/
