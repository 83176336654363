import React, { useMemo, useState, useCallback } from 'react'
import { useRouteMatch } from 'react-router'
import { Card, Drawer, Select } from 'antd'
import { useList } from '@top-legal/datastore'
import { useTemplateListBaseQuery } from '@top-legal/datastore/src/Schema/Templates'
import { Trans, t } from '@lingui/macro'
import ButtonLink from '../../SharedComponents/ButtonLink'
import { emptyObj } from '../../Defaults'
import InfoBox from '../../Alert/InfoBox'

import './CreateContractDrawer.scss'

const CreateContractDrawer: React.FC<{ visible?: boolean; handler?: any }> = ({ visible, handler }) => {
  // Bring the templates list from the database
  const templateList = useTemplateListBaseQuery()
  const [templatesMap = emptyObj, loadingTemplates] = useList(
    useMemo(() => templateList.sort({ dateUpdated: 'desc' }), [templateList]),
    useCallback(list => {
      const map = {}
      list.forEach(({ templateID, name, description }) => {
        map[templateID] = { value: templateID, label: name, description }
      })
      return map
    }, [])
  )
  // Selected template in the Select
  const [selectedTemplate, setSelectedTemplate] = useState<string>('') // Template ID
  const { url } = useRouteMatch()
  return (
    <div>
      <Drawer
        autoFocus
        className='drawerContract'
        destroyOnClose
        footer={null}
        maskClosable={false}
        onClose={() => {
          handler(false)
          setSelectedTemplate('')
        }}
        title={<Trans>Create new contract</Trans>}
        visible={visible}
      >
        <Card title={<Trans>Select a playbook to start</Trans>}>
          <Select
            className='simple-line'
            labelInValue
            onChange={value => {
              setSelectedTemplate(value.value)
            }}
            optionFilterProp='label'
            options={Object.values(templatesMap)}
            placeholder={t`Search for a playbook`}
            showSearch
          />
          {selectedTemplate && templatesMap[selectedTemplate].description && (
            <Card className='cardDescription'>
              <h2 className='cardDescriptioTitle'>
                <Trans>Instructions</Trans>
              </h2>
              <p>{templatesMap[selectedTemplate].description}</p>
            </Card>
          )}
          <ButtonLink
            className='buttonDraftContract'
            disabled={!selectedTemplate}
            href={url.replace('/listing/contracts', `/contracts/${selectedTemplate}/new`)}
            size='large'
            type='primary'
          >
            <Trans>Draft contract</Trans>
          </ButtonLink>
        </Card>
        {selectedTemplate ? (
          <Card className='noTemplateHelper'>
            <InfoBox>
              <Trans>If you don’t find the playbook you need, please go to “playbooks” and create it</Trans>
            </InfoBox>
          </Card>
        ) : (
          <></>
        )}
      </Drawer>
    </div>
  )
}
export default CreateContractDrawer
