import { useCallback, useEffect, useMemo, useState } from 'react'

/**
 * Use media queries in components
 * @example ```
const columnCount = useMediaQueries(
  // Media queries
  ['(min-width: 1500px)', '(min-width: 1000px)', '(min-width: 600px)'],
  // Column counts (relates to above media queries by array index)
  [5, 4, 3],
  // Default column count
  2
)

// OR

const isDesktop = useMediaQueries('(max-width: 1200px)', false, true)
 ```
 */
const useMediaQueries = <T>(queries: string | string[], values: T | T[], defaultValue: T): T => {
  // Array containing a media query list for each query
  const reelQueries = useMemo<MediaQueryList[]>(
    () => (Array.isArray(queries) ? queries : [queries]).map(query => window.matchMedia(query)),
    [queries]
  )
  const reelValues = useMemo<T[]>(() => (Array.isArray(values) ? values : [values]), [values])

  // Function that gets value based on matching media query
  const getValue = useCallback<() => T>(() => {
    // Get index of first media query that matches
    const index = reelQueries.findIndex(mql => mql.matches)

    // Return related value or defaultValue if none
    return typeof reelValues[index] !== 'undefined' ? reelValues[index] : defaultValue
  }, [reelQueries, reelValues, defaultValue])

  // State and setter for matched value
  const [value, setValue] = useState<T>(getValue)

  useEffect(
    () => {
      // Event listener callback
      // Note: By defining getValue outside of useEffect we ensure that it has ...
      // ... current values of hook args (as this hook callback is created once on mount).
      const handler = () => setValue(getValue)

      // Set a listener for each media query with above handler as callback.
      reelQueries.forEach(mql => mql.addListener(handler))

      // Remove listeners on cleanup
      return () => reelQueries.forEach(mql => mql.removeListener(handler))
    },
    [reelQueries, getValue]
  )

  return value
}

export default useMediaQueries
