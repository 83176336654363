import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Input, InputProps } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'

import { hasKeyWordsFactory, useHighlight } from '../../../../../Listing/SearchHelpers'
import { cancelPropagation } from '../../../../../Defaults'
import { Category, Search } from './Types'


const InputDelayedFocus: React.FC<InputProps & { focusDelay: number }> = ({ autoFocus, focusDelay, ...props }) => {
  const ref = useRef<any>(null)

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => ref.current?.focus?.(), focusDelay)
    }
  }, [autoFocus, focusDelay])

  return <Input {...props} ref={ref} />
}


// ===================================================================


// ===================================================================


/** **************************************************************
 *          Entities tree rendering & search helpers
 ************************************************************** */
const processTree = (
  { key, name, DisplayName, items, children }: any,
  search?: string,
  Highlight?: Search['Highlight'],
  depth = 0
): any[] => {
  const arr: any[] = []

  //  Process children (fields)
  if (Array.isArray(children)) {
    children.forEach(item => {
      const subItems = processTree(item, search, Highlight, depth + 1)
      if (subItems.length > 0) {
        arr.push(...subItems)
      }
    })
  }

  //  Process children (conds)
  if (Array.isArray(items)) {
    items.forEach(item => {
      const subItems = processTree(item, search, Highlight, depth + 1)
      if (subItems.length > 0) {
        arr.push(...subItems)
      }
    })
  }

  if (arr.length > 0 || !search || hasKeyWordsFactory(search.split(' '))(name)) {
    arr.unshift({
      key,
      content: (
        <div className='templateEntityItem' style={{ paddingLeft: `${depth}rem`, paddingTop: depth === 0 ? '1.5rem' : '0.2rem' }}>
          <DisplayName Highlight={Highlight} search={search} />
        </div>
      )
    })
  }

  return arr
}

export const processCategoryChildren = (
  children: Category['children'],
  search?: string,
  Highlight?: Search['Highlight']
) => {
  const arr: any[] = []

  children.forEach(node => {
    const subItems = processTree(node, search, Highlight)
    if (subItems.length > 0) {
      arr.push(...subItems)
    }
  })

  return arr
}

export const rowKey = item => item.key
export const RenderItem: React.FC<{ item: any, index: number }> = ({ item: { content } }) => content || null


// ===================================================================


// ===================================================================


export interface UseTemplateEntitiesSearchReturn {
  search: string
  setSearch: (str: string) => void
  searchBar: React.ReactElement
  Highlight: React.FC<{ text: string }>
}


/**
 * This provides a helper for searching over the template entities
 */
const useTemplateEntitiesSearch = (disabled: boolean, focusDelay = 0): UseTemplateEntitiesSearchReturn => {
  const [search, setSearch] = useState<string>('')
  const Highlight = useHighlight(search, '#fff59d')

  //  Search setter
  const timeoutRef = useRef<NodeJS.Timeout>()
  const saveSearch = useCallback(evt => {
    const str = evt.target.value.toLowerCase().trim()
    setSearch(str || '')
  }, [])

  const searchBar = useMemo(() => (
    <div className='entitiesSearchBar' onClick={cancelPropagation}>
      <InputDelayedFocus
        key={`${search}-${disabled}`}
        autoFocus={!disabled} // Import because it rerender the input when the search change to it's default value
        defaultValue={search}
        disabled={disabled}
        focusDelay={focusDelay}
        onBlur={saveSearch}
        onKeyDown={evt => {
          if (evt.key === 'Enter') {
            evt.preventDefault()
            saveSearch(evt)
          } else {
            if (timeoutRef.current) { clearTimeout(timeoutRef.current) }
            timeoutRef.current = setTimeout(() => saveSearch(evt), 300)
          }
        }}
        placeholder={t`Type to search...`}
      />
      {search && (
        <Button
          className='noBorder clearSearchButton'
          ghost
          icon={<CloseOutlined />}
          onClick={() => setSearch('')}
        />
      )}
    </div>
  ), [saveSearch, search, disabled, focusDelay])

  return {
    search, setSearch, searchBar, Highlight
  }
}

export default useTemplateEntitiesSearch
