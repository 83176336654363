import React, { useMemo } from 'react'

import { NoResultFound } from '../../../../../../AntDesignProvider'
import InfiniteScrolling from '../../../../../SharedComponents/ListingComponents/InfiniteScrolling'
import { processCategoryChildren, RenderItem, rowKey, UseTemplateEntitiesSearchReturn } from './useTemplateEntitiesSearch'
import { Category } from './Types'


interface TemplateEntitiesGlobalSearchProps extends Pick<UseTemplateEntitiesSearchReturn, 'search' | 'Highlight'> {
  fieldCategories: Category[]
  condCategories: Category[]
}

/**
 * This has the role to be able to search in all the entities without opening a category
 */
const TemplateEntitiesGlobalSearch: React.FC<TemplateEntitiesGlobalSearchProps> = ({
  fieldCategories, condCategories,
  search, Highlight
}) => {
  const allElements = useMemo(() => {
    const arr: any[] = []

    fieldCategories.forEach(({ children }) => {
      arr.push(...children)
    })

    condCategories.forEach(({ children }) => {
      arr.push(...children)
    })

    return arr
  }, [fieldCategories, condCategories])

  const filtered = useMemo(() => processCategoryChildren(allElements, search, Highlight), [allElements, search, Highlight])

  if (filtered.length === 0) {
    return <NoResultFound />
  }

  return (
    <InfiniteScrolling
      RenderItem={RenderItem}
      items={filtered}
      rowKey={rowKey}
    />
  )
}

export default TemplateEntitiesGlobalSearch
