/* eslint-disable react/display-name,max-lines */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryParams, StringParam } from 'use-query-params'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button, Checkbox, Collapse, DatePicker, List, Radio, Table, Tag, Tooltip
} from 'antd'
import {
  BarsOutlined, CalendarFilled, FilterOutlined, ProfileOutlined, SearchOutlined, TableOutlined, TagFilled
} from '@ant-design/icons'
import { ConsoleLogger as Logger } from '@aws-amplify/core'
import { t, Trans } from '@lingui/macro'

import moment from 'moment'

import RestService from '../../RestService'
import { LocalLoadingAlert } from '../Alert/LoadingAlert'
import { TagListInput } from './FormComponents'
//  Styles
import './styles/DataTableStyles.scss'
import { DeviceContext } from '../../GlobalContext'

const logger = new Logger('DataTable')

// ==================================================================================================
// ==================================================================================================
//  Redux part
const STORE_DATATABLE_DATA = 'STORE_DATATABLE_DATA'
const DELETE_ELEMENT_IN_DATABALE = 'DELETE_ELEMENT_IN_DATABALE'
const UPDATE_ELEMENT_IN_DATABALE = 'UPDATE_ELEMENT_IN_DATABALE'
const RESET_TABLE = 'RESET_TABLE'

const initState = () => ({})
// ==================================================================================================
// ==================================================================================================

export const DataTableReduxReducer = (state = initState(), action) => {
  state = { ...state }
  if (action.type === STORE_DATATABLE_DATA) {
    // logger.debug('state:', state)
    const { tableKey, response, rowKey, append } = action.payload
    const { Items, lastElement } = response
    if (!state[tableKey]) {
      state[tableKey] = { data: [] }
    }

    const key = typeof rowKey === 'function' ? rowKey : (item => item[rowKey])
    const newDataKeys = {}
    Items.forEach(item => { newDataKeys[key(item)] = true })

    const currentData = []
    ;(state[tableKey].data || []).forEach(item => {
      if (!newDataKeys[key(item)]) {
        currentData.push(item)
      }
    })

    state[tableKey].data = append ? [...currentData, ...Items] : [...Items, ...currentData]
    state[tableKey].lastElement = lastElement

    // logger.debug(state)
  } else if (action.type === DELETE_ELEMENT_IN_DATABALE) {
    const { tableKey, elementID, idKey } = action.payload
    if (!state[tableKey]) { state[tableKey] = { data: [] } }
    const index = state[tableKey].data.findIndex(elem => elem[idKey] === elementID)
    logger.debug('index of deleted element:', index)
    if (index >= 0) {
      state[tableKey].data = [...state[tableKey].data]
      state[tableKey].data.splice(index, 1)
    } else {
      logger.error('unable to remove element')
    }
  } else if (action.type === UPDATE_ELEMENT_IN_DATABALE) {
    logger.debug('updating an element:', action.payload)
    const { tableKey, elementID, idKey, item } = action.payload
    if (!state[tableKey]) { state[tableKey] = { data: [] } }
    const index = state[tableKey].data.findIndex(elem => elem[idKey] === elementID)
    logger.debug('index of update element:', index)
    if (index >= 0) {
      logger.debug('the old element:', state[tableKey].data)
      state[tableKey].data = [...state[tableKey].data]
      const prev = state[tableKey].data[index]
      if (!item.role) { item.role = prev.role }
      if (!item.roles) { item.roles = prev.roles }
      if (!item.accessType) { item.accessType = prev.accessType }
      state[tableKey].data[index] = item
    } else {
      logger.debug('element not found in data table thus creating new entry')
      if (elementID) {
        state[tableKey].data = [item, ...state[tableKey].data]
      }
    }
  } else if (action.type === RESET_TABLE) {
    state[action.payload] = { data: [], lastElement: null }
  }
  return state
}

// ==================================================================================================
// ==================================================================================================

export const updateData = (tableKey, response, rowKey = '', append = true) => ({
  type: STORE_DATATABLE_DATA,
  payload: {
    tableKey, response, rowKey, append
  }
})


// ==================================================================================================
// ==================================================================================================

export const updateLabels = (tableKey, instanceType, instanceID, newTags, readOnly = false) => async (dispatch, getState) => {
  try {
    const { organisationID } = getState().organisation.selectedOrganisation
    const { data, lastElement } = getState().dataTable[tableKey] || { data: [] }
    !readOnly && await RestService('PUT', '/labels', {
      organisationID, instanceType, instanceID, tags: newTags
    })
    const index = data.findIndex(({ [`${instanceType}ID`]: elmID }) => elmID === instanceID)
    if (index >= 0) {
      data[index].tags = newTags
      dispatch(updateData(tableKey, { Items: data, lastElement }, `${instanceType}ID`, false))
    }

    window.notification.success({
      message: <Trans>Labels saved</Trans>
    })
  } catch (err) {
    console.error('Cannot change labels', err)
    throw err
  }
}

// ==================================================================================================
// ==================================================================================================
/**
 * Function deletes an element from the data table redux storage
 * @param {string} tableKey name of the table
 * @param {string} elementID the id of the element (ie. template-232424)
 * @param {string} idKey the property name of the id, used to search for the element id (ie. templateID)
 */
export const removeElementInDatatable = (tableKey, elementID, idKey) => ({
  type: DELETE_ELEMENT_IN_DATABALE,
  payload: { tableKey, elementID, idKey }
})
// ==================================================================================================
// ==================================================================================================
/**
 * Function deletes an element from the data table redux storage
 * @param {string} tableKey name of the table
 * @param {string} elementID the id of the element (ie. template-232424)
 * @param {string} idKey the property name of the id, used to search for the element id (ie. templateID)
 * @param {object} item the item data
 */
export const updateElementInDataTable = (tableKey, elementID, idKey, item) => ({
  type: UPDATE_ELEMENT_IN_DATABALE,
  payload: {
    tableKey, elementID, idKey, item
  }
})

// ==================================================================================================
// ==================================================================================================

export const resetTable = tableKey => ({ type: RESET_TABLE, payload: tableKey })


// ==================================================================================================
// ==================================================================================================


const ALL = {
  value: '__ALL__',
  text: <Trans>All</Trans>,
  icon: <ProfileOutlined />
}


// ==================================================================================================
// ==================================================================================================


/**
 * create the top level radio group tab for the data table
 * @param {object} filter
 *
 */
const DataTableTopFilters = ({ isSmall, params, setParams, filters }) => (
  <div className='dataTable-GlobalFilters'>
    {
      filters.map(({ columnKey, name, options, defaultValue, className }) => (
        <div key={columnKey} className={`dataTable-GlobalFilter ${className || ''}`}>
          {name && <h3 className='dataTable-GlobalFilter-Title'>{name}</h3>}
          <Radio.Group
            buttonStyle='solid'
            className='dataTable-GlobalFilter-Options'
            onChange={event => {
              setParams(old => {
                const newValue = event.target.value
                const newParams = { ...old }
                if (newValue === ALL.value) {
                  newParams[`g_${columnKey}`] = undefined
                } else {
                  newParams[`g_${columnKey}`] = newValue
                }
                return newParams
              })
            }}
            value={params[`g_${columnKey}`] || defaultValue || ALL.value}
          >
            { defaultValue && ([...options].map(({ icon, value, text }) => (
              <Radio.Button key={value} value={value}>
                {icon} {!isSmall && <span className='filterText'>{text}</span>}
              </Radio.Button>
            )))}
            {!defaultValue && ([ALL, ...options].map(({ icon, value, text }) => (
              <Radio.Button key={value} value={value}>
                {icon} {!isSmall && <span className='filterText'>{text}</span>}
              </Radio.Button>
            )))}

          </Radio.Group>
        </div>
      ))
    }
  </div>
)


// ==================================================================================================
// ==================================================================================================


const FilterCollapse = ({ children, params, setParams, isOpenRef, noViewSwitcher = false }) => {
  const [isFilterOpen, setFilterOpen] = useState(isOpenRef.current)
  return (
    <Collapse
      activeKey={isFilterOpen ? 'filters' : null}
      bordered={false}
      onChange={() => {
        isOpenRef.current = !isFilterOpen
        setFilterOpen(!isFilterOpen)
      }}
    >
      <Collapse.Panel
        key='filters'
        extra={(
          <>
            <FilterOutlined />
            {
              !noViewSwitcher && (
                <div onClick={evt => evt.stopPropagation()}>
                  <Radio.Group
                    buttonStyle='outline'
                    onChange={evt => setParams(old => ({
                      ...old,
                      view: evt.target.value
                    }))}
                    size='small'
                    style={{ marginLeft: '1rem' }}
                    value={params.view || 'list'}
                  >
                    <Radio.Button value='list'><BarsOutlined /></Radio.Button>
                    <Radio.Button value='cards'><TableOutlined /></Radio.Button>
                  </Radio.Group>
                </div>
              )
            }
          </>
        )}
        forceRender
        header={<Trans>Filters</Trans>}
      >
        <List bordered={false}>{children}</List>
      </Collapse.Panel>
    </Collapse>
  )
}


// ==================================================================================================
// ==================================================================================================

/**
 * main data table component that is called
 */
const noop = () => null
export const DataTable = ({
  CardComponent = noop, isolatedScope = false, scroll, rowSelection,
  data = [], loading = false, children: columns,
  globalFilters, actions, defaultView = 'list', noViewSwitcher = false,
  tableRef, extraActions,
  ...props
}) => {
  const isFilterOpenRef = useRef(false)
  const [params, setParams] = useQueryParams(useMemo(() => {
    const config = { view: StringParam, sort_column: StringParam, sort_order: StringParam }

    if (Array.isArray(globalFilters) && globalFilters.length > 0) {
      globalFilters.forEach(({ columnKey }) => {
        config[`g_${columnKey}`] = StringParam
      })
    }
    if (Array.isArray(columns) && columns.length > 0) {
      columns.forEach(({ columnKey, withDate }) => {
        if (withDate) {
          config[`${columnKey}_begin`] = StringParam
          config[`${columnKey}_end`] = StringParam
        } else {
          config[columnKey] = StringParam
        }
      })
    }

    console.info('config', config, columns)
    return config
  }, [globalFilters, columns]))

  const isSmall = useContext(DeviceContext) === 'phone'
  const dispatch = useDispatch()
  const reelColumns = useMemo(() => columns.map(createCol => createCol({
    params,
    setParams,
    tableKey: props.tableKey,
    rowKey: props.rowKey,
    dispatch
  })), [columns, dispatch, params, props.rowKey, props.tableKey, setParams])

  /**
   * Taking care for having the default view
   */
  let { view } = params
  useEffect(() => {
    if (!noViewSwitcher && !view) {
      setParams(old => ({
        ...old,
        view: defaultView
      }))
    }
  }, [defaultView, params, setParams, noViewSwitcher])
  if (noViewSwitcher) {
    view = defaultView
  }

  const antTableProps = useMemo(() => {
    const tableProps = {}

    /** ****************************************************************
   *                    item actions
   **************************************************************** */
    let RowWrapping = ({ children }) => children
    if (Array.isArray(actions) || typeof actions === 'function') {
      RowWrapping = ({ onlyButtons, ...rowProps }) => {
        const reelActions = typeof actions === 'function' ? actions(rowProps.record) : actions
        if (Array.isArray(reelActions)) {
          const { children, ...otherProps } = rowProps

          const buttons = (
            <div className={`actionsButtonsTooltip ${isSmall ? 'small' : ''}`}>
              {reelActions.map(({ key, Action }) => (
                <Action key={key} {...otherProps} />
              ))}
            </div>
          )

          if (onlyButtons) {
            return buttons
          }

          return <Tooltip overlayClassName='dataTable-actionsTooltip' title={buttons} trigger={['click', 'contextMenu']}>{children}</Tooltip>
        }
        return rowProps.children
      }
    }

    /** ****************************************************************
   *               Custom filters table elements
   **************************************************************** */
    const customFilters = {
      table: ({ children }) => <table className='dataTable-cardsView'>{children}</table>,
      header: {
        wrapper: ({ children }) => <thead className='dataTable-filters'><tr><th>{children}</th></tr></thead>,
        row: rowProps => (
          <FilterCollapse
            {...rowProps}
            isOpenRef={isFilterOpenRef}
            noViewSwitcher={noViewSwitcher}
            params={{ ...params, view }}
            setParams={setParams}
          />
        ),
        cell: ({ children, ...cellProps }) => {
        //  Filtering out column that are only string or only lingui trans
          const formattedChildren = children.filter(elem => elem && typeof elem !== 'string' && elem.props.children)
          if (formattedChildren.length > 0) {
            if (cellProps.onClick) {
              cellProps.style = { cursor: 'pointer' }
            }
            return <List.Item {...cellProps} className='dataTable-filter-item'>{formattedChildren}</List.Item>
          }
          return null
        }
      }
    }

    /** ****************************************************************
   *                    Cards view
   **************************************************************** */
    if (view === 'cards') {
      tableProps.components = {
        ...customFilters,
        body: {
          wrapper: ({ children }) => <tbody className='dataTable-cards-wrapper'><tr><td>{children}</td></tr></tbody>,
          row: ({ record, rowindex, children }) => (record ? (
            <RowWrapping key={rowindex} record={record} rowindex={rowindex}>
              <CardComponent record={record} rowindex={rowindex}>{children}</CardComponent>
            </RowWrapping>
          ) : null),
          cell: ({ children }) => children
        }
      }
    } else if (isSmall) {
      const mainColumn = reelColumns.find(({ isMainColumn }) => isMainColumn) || reelColumns[0]
      const otherColumns = reelColumns.filter(({ dataIndex }) => dataIndex !== mainColumn.dataIndex)
      tableProps.components = {
        ...customFilters,
        body: {
          wrapper: ({ children }) => (
            <div className='dataTable-list-small-wrapper'>
              <h1>{mainColumn.title}</h1>
              <Collapse accordion bordered={false}>
                {
                  (Array.isArray(children[1]) ? children[1] : []).map(({ props: { record, index, recordKey } }) => (record ? (
                    <Collapse.Panel key={recordKey} header={(mainColumn.render || (() => mainColumn.getData(record)))(mainColumn.getData(record), record)}>
                      <List bordered={false}>
                        {
                          otherColumns.map(
                            ({ dataIndex, title, getData, render = () => getData(record), filterIcon = () => null, columnIcon, className, style }) => (
                              <List.Item key={dataIndex} className={className} style={style}>
                                {title && <span className='title'><b>{filterIcon(false) || columnIcon} {title}</b></span>}
                                <span className='value'>{render(getData(record), record)}</span>
                              </List.Item>
                            )
                          )
                        }
                        {
                          (Array.isArray(actions) || typeof actions === 'function') && (
                            <List.Item>
                              <RowWrapping onlyButtons record={record} rowindex={index} />
                            </List.Item>
                          )
                        }
                      </List>
                    </Collapse.Panel>
                  ) : null))
                }
              </Collapse>
            </div>
          ),
          row: () => null,
          cell: () => null
        }
      }
    } else {
      tableProps.components = {
        table: tablePropsP => (
          <div className='dataTable-table-wrapper' onClick={evt => evt.stopPropagation()}>
            {
              !noViewSwitcher && (
                <Radio.Group
                  buttonStyle='outline'
                  className='viewSwitcher'
                  onChange={evt => setParams(old => ({
                    ...old,
                    view: evt.target.value
                  }))}
                  size='small'
                  style={{ marginLeft: '1rem' }}
                  value={view || 'list'}
                >
                  <Radio.Button value='list'><BarsOutlined /></Radio.Button>
                  <Radio.Button value='cards'><TableOutlined /></Radio.Button>
                </Radio.Group>
              )
            }
            <table {...tablePropsP} />
          </div>
        ),
        body: {
          row: ({ record, rowindex, ...rowProps }) => (record ? (
            <RowWrapping record={record} rowindex={rowindex}>
              <tr {...rowProps} />
            </RowWrapping>
          ) : null)
        }
      }
    }
    tableProps.onRow = (record, rowindex) => ({
      record,
      rowindex
    })

    return tableProps
  }, [CardComponent, actions, isSmall, noViewSwitcher, params, reelColumns, setParams])

  /** ****************************************************************
   *                    Loading element
   **************************************************************** */
  const loadingElem = useMemo(() => ({
    indicator: <LocalLoadingAlert lightTheme loading={loading} message={<Trans>Getting more data for you.</Trans>} />,
    spinning: loading
  }), [loading])

  /** ****************************************************************
   *                    Table change
   **************************************************************** */
  const onChange = useCallback((_, __, { field, order }) => {
    if (order) {
      setParams(old => ({
        ...old,
        sort_column: field,
        sort_order: order
      }))
    } else {
      setParams(old => {
        const newParams = { ...old }
        newParams.sort_column = undefined
        newParams.sort_order = undefined
        return newParams
      })
    }
  }, [setParams])

  /** ****************************************************************
   *                    GlobalFiltering
   **************************************************************** */
  const reelData = useMemo(() => {
    let array = [...data]
    if (Array.isArray(globalFilters) && globalFilters.length > 0) {
      globalFilters.forEach(({ columnKey, getData = record => record[columnKey] }) => {
        const selectedValue = params[`g_${columnKey}`]
        if (selectedValue != null && selectedValue !== ALL.value) {
          array = array.filter(record => (getData(record)).includes(selectedValue))
        }
      })
    }
    return array
  }, [data, globalFilters, params])

  /** ****************************************************************
   *                    Rendering table
   **************************************************************** */
  return (
    <div className={`dataTable2 ${(Array.isArray(globalFilters) && globalFilters.length > 0) ? 'withTopFilters' : ''}`}>
      {Array.isArray(globalFilters) && globalFilters.length > 0 && (
        <DataTableTopFilters
          filters={globalFilters}
          isSmall={isSmall}
          params={params}
          setParams={setParams}
        />
      )}
      {Array.isArray(extraActions) && <div className='extraActions'>{extraActions}</div>}
      <Table
        ref={tableRef}
        rowSelection={rowSelection}
        scroll = {scroll}
        {...props} {...antTableProps}
        columns={reelColumns}
        dataSource={reelData}
        loading={loadingElem}
        onChange={onChange}
      />
    </div>
  )
}
// ==================================================================================================
const defaultLoad = lastElement => ({
  Items: [],
  lastElement: null
})
// ==================================================================================================
/**
 * table to display card and a list of items in a data table
 */
export const PaginatedDataTable = ({ tableKey, loadMore = defaultLoad, pageSize = 20, ...props }) => {
  // getting the data from the store
  const dispatch = useDispatch()
  const dataTableStore = useSelector(state => state.dataTable)
  const { data: array = [], lastElement = null } = dataTableStore[tableKey] || {}
  const data = useMemo(() => array.filter(item => !item.deleted), [array])
  const [loading, setLoading] = useState(false)

  // ==================================================================================================

  const load = useCallback(async () => {
    setLoading(true)
    try {
      const response = await loadMore(lastElement)
      dispatch(updateData(tableKey, response, props.rowKey))
    } catch (err) {
      logger.error('PaginatedDataTable - Loading error', err)
    }
    setLoading(false)
  }, [dispatch, lastElement, loadMore, props.rowKey, tableKey])

  // ==================================================================================================

  const loadref = useRef()
  loadref.current = { load, data, lastElement }
  useEffect(() => {
    if (!loadref.current.lastElement && loadref.current.data.length === 0) {
      loadref.current.load()
    }
  }, [])

  // ==================================================================================================

  const LoadMoreButton = useMemo(() => () => (
    <Button
      className='loadMoreButton'
      ghost
      onClick={load}
      style={{ background: 'white', cursor: 'pointer' }}
      type='primary'
    >
      <Trans>Load more</Trans>
    </Button>
  ), [load])

  const pagination = useMemo(() => ({
    hideOnSinglePage: !lastElement,
    total: data.length,
    pageSize,
    showSizeChanger: false,
    position: ['bottomCenter'],
    itemRender: lastElement ? ((page, type, elem) => {
      if (type === 'next') {
        return <LoadMoreButton />
      }
      return elem
    }) : undefined
  }), [LoadMoreButton, data.length, lastElement, pageSize])

  return (
    <div className='paginatedDatatable'>
      <DataTable
        {...props}
        data={array}
        loading={loading}
        pagination={pagination}
        scroll={{ scrollToFirstRowOnChange: true }}
        tableKey={tableKey}
      />
    </div>
  )
}
//  WARNING: This is not a reactComponent because ant design use <Table.Column> just for there props and does not render it at all
export const createColumn = ({
  columnKey, columnName, isNumbers = false, getData = item => item[columnKey],
  withSort = false, withTags = null, withDate = false, withLabels = null, readOnly = false, render = null,
  ...other
}) => {
  const func = ({params, setParams, tableKey, dispatch}) => {
    const columnProps = {
      ...other,
      onCell: (record, rowindex) => ({record, rowindex})
    }

    /** **********************************************************************
     *                Date filter feature
     ********************************************************************** */
    const getDate = record => {
      const timestamp = new Date(getData(record)).valueOf()
      if (Number.isNaN(timestamp)) {
        return 0
      }
      return timestamp
    }
    if (withDate) {
      const handleSearch = ([[begin, end]]) => setParams(old => ({
        ...old,
        [`${columnKey}_begin`]: begin.clone().format('YYYY-MM-DD'),
        [`${columnKey}_end`]: end.clone().format('YYYY-MM-DD')
      }))

      const handleReset = () => {
        setParams(old => {
          const newParams = { ...old }
          newParams[`${columnKey}_begin`] = undefined
          newParams[`${columnKey}_end`] = undefined
          return newParams
        })
      }

      let filteredValue = null
      const beginStr = params[`${columnKey}_begin`]
      const endStr = params[`${columnKey}_end`]

      if (beginStr && endStr) {
        filteredValue = [[
          moment(beginStr, 'YYYY-MM-DD').startOf('day'),
          moment(endStr, 'YYYY-MM-DD').endOf('day')
        ]]
      }

      const today = moment()
      const yesterday = moment().subtract(1, 'day')
      const lastWeek = moment().subtract(1, 'week')
      const lastMonth = moment().subtract(1, 'month')

      columnProps.filterDropdown = ({setSelectedKeys, selectedKeys}) => (
        <div style={{padding: 8}}>
          <DatePicker.RangePicker
            dropdownClassName='dataTable-dateFilter'
            format='YYYY/MM/DD'
            onChange={dates => setSelectedKeys([dates])}
            ranges={{
              [t`Today`]: [today.clone().startOf('day'), today.clone().endOf('day')],
              [t`Yesterday`]: [yesterday.clone().startOf('day'), yesterday.clone().endOf('day')],
              [t`Last week`]: [lastWeek.clone().startOf('week'), lastWeek.clone().endOf('week')],
              [t`Last month`]: [lastMonth.clone().startOf('month'), lastMonth.clone().endOf('month')],
              [t`Past 24h`]: [yesterday, today],
              [t`Past 7days`]: [lastWeek, today],
              [t`Past 30days`]: [lastMonth, today]
            }}
            style={{
              width: 250,
              marginBottom: 8,
              display: 'block'
            }}
            value={Array.isArray(selectedKeys) ? selectedKeys[0] : null}
          />
          <Button.Group className='autoFit'>
            <Button
              icon={<SearchOutlined />}
              onClick={() => handleSearch(selectedKeys)}
              size='small'
              type='primary'
            >
              <Trans>Search</Trans>
            </Button>
            <Button onClick={() => handleReset()} size='small'>
              <Trans>Reset</Trans>
            </Button>
          </Button.Group>
        </div>
      )
      columnProps.filterIcon = filtered => <CalendarFilled style={{color: filtered ? '#3DBD7D' : '#1C3556'}}/>
      columnProps.filteredValue = filteredValue
      columnProps.onFilter = ([begin, end], record) => {
        const date = getDate(record)
        return date && !isNaN(date) && begin.valueOf() <= date && date <= end.valueOf()
      }
      columnProps.render = (_, record) => {
        const date = getDate(record)
        return date <= 0 ? '-' : moment(date).fromNow()
      }
    }


    /** **********************************************************************
     *                Tags filter feature
     ********************************************************************** */
    if (Object(withTags) === withTags) {
      const filters = Object.keys(withTags)
        .map(key => {
          const tag = withTags[key]
          if (tag && tag.text && tag.color) {
            return {
              text: <Tag color={tag.color}>{tag.text}</Tag>,
              value: key
            }
          }
          return null
        })
        .filter(elm => elm)
      columnProps.className = 'tagsColumn'
      columnProps.filterDropdown = ({setSelectedKeys, selectedKeys}) => (
        <div style={{padding: 8}}>
          <div className='tagFilterList'>
            <Checkbox.Group onChange={newKeys => setSelectedKeys(newKeys)} value={selectedKeys}>
              {filters.map(({text, value}) => <Checkbox key={value} value={value}>{text}</Checkbox>)}
            </Checkbox.Group>
          </div>
          <Button.Group className='autoFit'>
            <Button
              icon={<SearchOutlined/>}
              onClick={() => setParams(old => ({
                ...old,
                [columnKey]: selectedKeys
              }))}
              size='small'
              type='primary'
            >
              <Trans>Search</Trans>
            </Button>
            <Button
              onClick={() => {
                setParams(old => {
                  const newParams = {...old}
                  newParams[columnKey] = undefined
                  return newParams
                })
              }}
              size='small'
            >
              <Trans>Reset</Trans>
            </Button>
          </Button.Group>
        </div>
      )
      columnProps.render = (_, record) => {
        const data = getData(record)
        const tag = withTags[data]
        return tag ? <Tag color={tag.color}>{tag.text}</Tag> : data
      }
      columnProps.filterIcon = filtered => <TagFilled style={{color: filtered ? '#3DBD7D' : '#1C3556'}}/>
      columnProps.filteredValue = params[columnKey]
      columnProps.onFilter = (value, record) => getData(record).includes(value)
    }


    /** **********************************************************************
     *                Labels filter feature
     ********************************************************************** */
    if (typeof withLabels === 'string') {
      columnProps.className = 'labelsColumn'
      columnProps.filterDropdown = ({setSelectedKeys, selectedKeys}) => (
        <div style={{padding: 8}}>
          <h4><Trans>Labels search</Trans></h4>
          <div className='labelFilterList'>
            <TagListInput
              addText={<Trans>Add label</Trans>}
              max={5}
              onChange={newKeys => setSelectedKeys(newKeys)} value={selectedKeys}
            />
          </div>
          <Button.Group className='autoFit'>
            <Button
              icon={<SearchOutlined/>}
              onClick={() => setParams(old => ({
                ...old,
                [columnKey]: selectedKeys
              }))}
              size='small'
              type='primary'
            >
              <Trans>Search</Trans>
            </Button>
            <Button
              onClick={() => {
                setParams(old => {
                  const newParams = {...old}
                  newParams[columnKey] = undefined
                  return newParams
                })
              }}
              size='small'
            >
              <Trans>Reset</Trans>
            </Button>
          </Button.Group>
        </div>
      )
      columnProps.render = (_, record) => {
        const tags = getData(record)
        const {role} = record
        return (
          <TagListInput
            addText={<Trans>Tag</Trans>}
            max={3}
            onChange={newTags => dispatch(updateLabels(tableKey, withLabels, record[`${withLabels}ID`], newTags, readOnly))}
            readOnly={!['Owner', 'FullAccess', 'Contributor'].includes(role)}
            value={tags}
          />
        )
      }
      columnProps.filterIcon = filtered => <TagFilled style={{color: filtered ? '#3DBD7D' : '#1C3556'}}/>
      columnProps.filteredValue = params[columnKey]
      columnProps.onFilter = (value, record) => getData(record).map(str => str.toLowerCase()).includes(value.toLowerCase())
    }


    /** **********************************************************************
     *                Sorting feature
     ********************************************************************** */
    if (withSort) {
      const col = params.sort_column
      const order = params.sort_order
      if (withDate) {
        columnProps.sorter = (elemA, elemB) => {
          const elmA = getDate(elemA)
          const elmB = getDate(elemB)
          return elmA - elmB
        }
      } else {
        columnProps.sorter = (elemA, elemB) => {
          const elmA = getData(elemA)
          const elmB = getData(elemB)

          if (isNumbers) {
            return elmA - elmB
          }
          if (elmA === elmB) {
            return 0
          }
          if (elmA < elmB) {
            return -1
          }
          return 1
        }
      }
      columnProps.sortOrder = col === columnKey ? order : null
    }

    if (typeof render === 'function') {
      if (columnProps.render) {
        const filterRender = columnProps.render
        columnProps.render = (_, record) => render(
          filterRender(_, record),
          record
        )
      } else {
        columnProps.render = render
      }
    } else if (!columnProps.render) {
      columnProps.render = (_, item) => getData(item)
    }

    return {
      ...columnProps,
      dataIndex: columnKey,
      title: columnName,
      getData // Internal usage of our DataTable
    }
  }

  func.columnKey = columnKey
  func.withDate = withDate
  return func
}
