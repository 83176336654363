import React, { useContext, useLayoutEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Trans } from '@lingui/macro'
import { Button, Tooltip } from 'antd'
import { ArrowLeftOutlined, HistoryOutlined } from '@ant-design/icons'

import Portal from '../../../../../SharedComponents/Portal'
import { StickyMainContent } from '../../../../../Layouts/MainLayout/MainContainer'
import { LayoutSidebarWidthContext } from '../../../../../Layouts/LayoutSidebar'

import { RenderComments } from './CommentsToolbox'
import { PartCommentContext } from './PartComment'
import { CommentsToolboxConfig, CommentsToolboxConfigContext, CommentTreePartNode } from './Types'
import { ScrollbarWidthContext } from '../../../../../../GlobalContext'


const commentToolboxConfig: CommentsToolboxConfig = {
  readOnly: true,
  noLink: true
}


const PartHistory: React.FC<{ node: CommentTreePartNode, domElement?: HTMLElement | null }> = ({ node, domElement }) => {
  const width = useContext(LayoutSidebarWidthContext)
  const right = `${useContext(ScrollbarWidthContext)}px`
  const { getSectionElem, currentBoxRef, switchToolbox } = useContext(PartCommentContext)
  const [open, setOpen] = useState(false)

  useLayoutEffect(() => {
    let section = getSectionElem()
    section = section && section.querySelector('.sectionText')

    if (section && open) {
      section.classList.add('activeSection')
    }

    return () => {
      if (section) {
        section.classList.remove('activeSection')
      }
    }
  }, [getSectionElem, open])

  const ref = useRef<any>()
  useLayoutEffect(() => {
    if (!open) {
      return undefined
    }

    const close = () => {
      if (!ref.current) {
        setTimeout(() => setOpen(false))
      }
      ref.current = undefined
    }

    document.addEventListener('click', close)
    return () => document.removeEventListener('click', close)
  }, [open])

  if (!node.comments || Object.keys(node.comments).length === 0) {
    return null
  }

  return (
    <>
      {domElement && ReactDOM.createPortal(
        <div className='historySwitchButton'>
          <Tooltip placement='topRight' title={open ? <Trans>Hide history</Trans> : <Trans>Show history of all comments and suggestions</Trans>}>
            <Button
              className='noBorder'
              ghost={!open}
              icon={<HistoryOutlined />}
              onClick={() => {
                ref.current = true
                setOpen(!open)
                if (currentBoxRef.current !== 'comments') {
                  switchToolbox('comments')
                }
              }}
              shape='circle'
              size='small'
              type='default'
            />
          </Tooltip>
        </div>,
        domElement
      )}
      <Portal cssSelector='body'>
        <StickyMainContent
          className={`fixedContent commentThread ${open ? 'open' : 'close'}`}
          onClick={evt => evt.stopPropagation()}
          style={{ width: `calc(${width} + ${right})` }}
        >
          <div className='threadTitle'>
            <Button
              className='noBorder'
              ghost
              icon={<ArrowLeftOutlined />}
              onClick={() => setOpen(false)}
              shape='circle'
              type='default'
            />
            <h2><Trans>History</Trans></h2>
          </div>
          <div className='comments'>
            <div className='commentThreadList'>
              <CommentsToolboxConfigContext.Provider value={commentToolboxConfig}>
                <RenderComments node={node} />
              </CommentsToolboxConfigContext.Provider>
            </div>
          </div>
        </StickyMainContent>
      </Portal>
    </>
  )
}

export default PartHistory
