import React, { useMemo } from 'react'
import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import Highlighter from 'react-highlight-words'
import { Node } from 'slate'
import { TagOutlined } from '@ant-design/icons'

import { Contract } from '@top-legal/datastore'

import { Filter } from './Types'
import { templateCategories } from '../Template/TemplateHelperFunctions'

export const getHighlight = (search: string[], backgroundColor = '#C1EAD5'): React.FC<{ text: any }> => {
  const style = {
    backgroundColor,
    padding: 0,
    margin: 0
  }

  const Highlight: React.FC<{ text: any }> = (({ text }) => (typeof text === 'string' && text && (
    <Highlighter
      key={text}
      autoEscape
      highlightStyle={style}
      searchWords={search}
      textToHighlight={text}
    />
  )) || null)

  return Highlight
}

export const useHighlight = (search: string | string[], backgroundColor?: string): React.FC<{ text: any }> => useMemo(() => getHighlight(
  Array.isArray(search) ? search : search.trim().split(' '),
  backgroundColor
), [search, backgroundColor])

export const emptyObject = {}
export const emptyArray = []
export const ensureArray = (data: any) => (Array.isArray(data) ? data : emptyArray)

type HasKeyWords = (str: any) => boolean
export const hasKeyWordsFactory = (keywords: string[]): HasKeyWords => (str: any) => {
  if (typeof str !== 'string' || !str) {
    return false
  }
  if (keywords.length === 0) {
    return true
  }

  const lstr = str.toLowerCase()
  const remaining: string[] = []
  keywords.forEach(search => {
    if (!lstr.includes(search)) {
      remaining.push(search)
    }
  })

  keywords = remaining
  return keywords.length === 0
}

export const slateContentHasKeywords = (nodes: Node[], hasKeyWords: HasKeyWords): boolean => nodes.some(node => {
  if (typeof node.text === 'string' && node.text && hasKeyWords(node.text)) {
    return true
  }
  if (Array.isArray(node.children)) {
    return slateContentHasKeywords(node.children, hasKeyWords)
  }
  return false
})

export const getContractStatus = (contract: Contract): string => {
  const { contractStatus } = contract

  //  Return a more precise status according to other properties
  if (contractStatus === 'filling' || contractStatus === 'editing') {
    return contract.sentOverDate ? 'negotiate' : 'drafting'
  }
  if (contractStatus === 'frozen') {
    if (contract.signaturesHolder && contract.signaturesHolder.neededApprovals && !contract.signaturesHolder.hasAllApprovals) {
      return 'approval'
    }
    return 'signing'
  }

  return contractStatus
}

export const useGlobalTags = (): { [key: string]: string } => useMemo(() => {
  const map = {
    de: t`German`,
    en: t`English`
  }

  Object.entries(templateCategories).forEach(([key, value]) => {
    const id = value?.text?.props?.id //  It is a Trans element that has id props the key to translate
    if (id) {
      map[key] = i18n._(id)
    }
  })

  return map
}, [])

export const useTagsFilter = <T extends { tags?: string[], lang?: string, category?: string }>(arr: T[]): Filter<T> => {
  const globalTags = useGlobalTags()

  const items = useMemo(() => {
    const map: any = {}

    arr.forEach(({ tags }) => {
      if (Array.isArray(tags)) {
        tags.forEach(tag => { if (tag) { map[tag] = tag } })
      }
    })

    Object.values(globalTags).forEach(tag => { map[tag] = tag })

    return map
  }, [arr, globalTags])

  return useMemo(() => ({
    //  Tags
    key: 'tag',
    title: <Trans>Tags</Trans>,
    icon: <TagOutlined />,
    items,
    filter: (item, value) => (Array.isArray(item.tags) && item.tags.includes(value))
      || (!!item.lang && globalTags[item.lang] === value)
      || (!!item.category && globalTags[item.category] === value)
  }), [items, globalTags])
}
