import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

import InstanceUsersContext, { PartyInfo } from '../Context/InstanceUsersContext'
import { IdPrefixContext } from '../../../../../ContractDiplay/ContractLayout'
import { ContractContext } from '../../../../Contexts'
import { SwitchInput } from '../../../../../../SharedComponents/FormComponents'
import { addPartySigning, switchSigning } from '../../../../../redux/ContractActions'


const SwitchSigning: React.FC<{ partyInfo: PartyInfo }> = ({ partyInfo }) => {
  const idPrefix = useContext(IdPrefixContext)
  const { contract } = useContext<any>(ContractContext)
  const isSigningFlow = !!contract.signaturesHolder || contract.contractStatus === 'signed' || contract.contractStatus === 'frozen'

  //  Get a general disabled state, it come with a check contract is fullySigned
  const { actionsDisabled, setActionsDisabled } = useContext(InstanceUsersContext)
  const dispatch = useDispatch()

  return (
    <SwitchInput
      checkedChildren={<CheckOutlined />}
      disabled={actionsDisabled || isSigningFlow}
      onChange={async signer => {
        setActionsDisabled(true)
        try {
          if (partyInfo.partyKey) {
            await dispatch(switchSigning(partyInfo.partyKey, signer))
          } else {
            //  When we add an internal user it is not yet attached to parties unless we turn him to sign
            await dispatch(addPartySigning(partyInfo.partyData.partyID, signer))
          }

          //  Scroll to signatures
          {
            const elem = document.getElementById(`${idPrefix}signatures`)

            if (elem) {
              elem.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              })
            }
          }

          //  Blink signature
          if (signer) {
            const elem = document.getElementById(`${idPrefix}${partyInfo.partyData.partyID}-signing-card`)
            if (elem) {
              setTimeout(() => {
                elem.classList.add('blink-outline')
                setTimeout(() => elem.classList.remove('blink-outline'), 1000)
              }, 300)
            }
          }
          setActionsDisabled(false)
        } catch (err) {
          console.error('Error switchSigning', err)
          setActionsDisabled(false)
          throw err
        }
      }}
      placeholder=''
      unCheckedChildren={<CloseOutlined />}
      value={partyInfo.signer}
    />
  )
}

export default SwitchSigning
