import React, { useEffect, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { Route, Switch, useHistory } from 'react-router-dom'
import { Layout, Modal, notification } from 'antd'

import { Worker, ContractAssistant } from '@top-legal/contract-assistant'
import TranslationsProvider from './TranslationsProvider'
import AntDesignProvider from './AntDesignProvider'


//  OAuth config
//  Components
import { setOAuthRedirectUri } from './awsSetupConfig'
import EmptyHeader from './components/Layouts/EmptyHeader'

//  Error handler
import ErrorBoundary from './components/Error/ErrorBoundary'
import ErrorPage from './components/Error/ErrorPage'

//  Pages
import LoginRegistrationPage from './components/LoginRegistration/LoginRegistrationPage'
import RegistrationConfirmation from './components/LoginRegistration/RegistrationConfirmEmail'
import ForgotConfirmation from './components/LoginRegistration/ChangeForgottenPassword'

import PublicTemplatesListing from './components/Template/PublicTemplatesListing'
import ChatBot from './components/ChatBot/ChatBot'
import NegotiationStrategizer from './components/Template/NegotiationStrategizer'

import Dashboard from './components/Dashboard/Dashboard'
import Tasks from './components/Tasks/Tasks'
// import Tasks from './components/Tasks/TasksFromExecute'
import StatisticsDashboard from './components/Statistics/StatisticsDashboard'
import Support from './components/Support/Support'
import Feedback from './components/Support/Feedback'

import OrganisationSettings from './components/Settings/OrganisationSettings'
import UsersManagement from './components/Organisations/UsersManagement'
import ContractStyling from './components/Organisations/ContractStyling'
import ContactList from './components/ContactList/ContactList'
import MediaLibrary from './components/Media/MediaLibrary'

import UserSettings from './components/Settings/UserSettings'

import ContractPortalRoutes from './components/ExternalViews/ContractPortalRoutes'
import TeamsConfigPage from './components/Teams/TeamsConfigPage'
// import OrganisationExtensions from './components/Organisations/OrganisationExtensions'
// import OrganisationExtensionMorePage from './components/Organisations/OrganisationExtensionMorePage'
import ContentLibrary from './components/ContentLibrary/ContentLibrary'
import ContractTemplateRoutes from './ContractTemplateRoutes'
import Listing from './components/Listing/Listing'

//  Redirect component
export const RedirectComp = ({ to }) => {
  const history = useHistory()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => history.push(to), [])
  return null
}

const RedirectToDealroom = ({ pathname = '/auth' }) => {
  useMemo(() => {
    const url = new URL(process.env.DEAL_DOMAIN)
    url.pathname = pathname
    url.search = window.location.search
    window.location.href = url.href
  }, [pathname])
  return null
}


//  App routes
const AppRoutes = ({ match }) => {
  setOAuthRedirectUri(`${window.location.origin}/${match.params.lang}/login/`)
  document.documentElement.setAttribute('lang', match.params.lang)

  return (
    //  Note: The order is important AntDesignProvider needs to be lower as possible to inherits of previous contexts in modals
    <TranslationsProvider lang={match.params.lang}>
      <AntDesignProvider lang={match.params.lang}>
        <Switch>
          {/* <Route component={Test} exact path={`${match.path}/test`} /> */}

          {/* Auto negotiation with dummy ChatBot */}
          {/* <Route component={ChatBot} exact path={`${match.path}/auto-negotiation`} /> */}

          {/* Login & Registration */}
          <Route exact path={`${match.path}/:actionType(login|login_|register|register_)`}>
            <RedirectToDealroom pathname='/auth' />
          </Route>

          {/* Normally come from email */}
          <Route exact path={`${match.path}/password-reset`}>
            <RedirectToDealroom pathname='/auth/reset-password' />
          </Route>

          {/* Dashboard and setting */}
          <Route component={Dashboard} exact path={`${match.path}/dashboard`} />
          {/* Tasks and Reminders */}
          <Route component={Tasks} exact path={`${match.path}/tasks`} />

          {/* Organisation */}
          <Route component={UsersManagement} exact path={`${match.path}/organisation-user-management`} />
          <Route component={ContactList} exact path={`${match.path}/contacts`} />
          <Route component={ContractStyling} exact path={`${match.path}/contract-styling`} />
          <Route component={MediaLibrary} exact path={`${match.path}/media-library`} />
          { /* <Route component={OrganisationExtensions} exact path={`${match.path}/organisation-extensions`} />
             <Route component={OrganisationExtensionMorePage} exact path={`${match.path}/organisation-extensions/:extension`} />  */}
          <Route component={ContentLibrary} exact path={`${match.path}/content-library`} />

          {/* Contracts & Templates */}
          <Route component={Listing} path={`${match.path}/listing/:listType(all|contracts|templates)`} />
          <Route exact path={`${match.path}/contracts`}><RedirectComp to={`${match.url}/listing/contracts`} /></Route>
          <Route exact path={`${match.path}/templates`}><RedirectComp to={`${match.url}/listing/templates`} /></Route>
          <Route component={ContractTemplateRoutes} path={`${match.path}/:entity(contracts|templates)/:entityID`} />
          <Route component={Worker} exact path={`${match.path}/contract-assistant/:templateID/worker`} />
          <Route component={ContractAssistant} exact path={`${match.path}/contract-assistant/:templateID/:contractID`} />

          {/* Templates */}
          <Route component={PublicTemplatesListing.Auth} exact path={`${match.path}/top-legal-templates`} />
          <Route component={NegotiationStrategizer} exact path={`${match.path}/templates/strategizer`} />

          {/* Support Page */}
          <Route component={Support} exact path={`${match.path}/support`} />
          <Route component={Feedback} exact path={`${match.path}/support/feedback`} />

          {/* Statistics & Analytics Page */}
          <Route component={StatisticsDashboard} exact path={`${match.path}/analytics`} />

          {/* Settings */}
          <Route component={OrganisationSettings} exact path={`${match.path}/organisation-settings`} />
          <Route component={UserSettings} exact path={`${match.path}/settings`} />

          {/* Contract Portal / External views */}
          <Route component={ContractPortalRoutes} path={`${match.path}/contract-portal`} />

          {/* Contract Portal / External views */}
          <Route component={TeamsConfigPage} path={`${match.path}/extenssions/teams`} />

          {/* Default route redirects to login page */}
          <Route exact path={`${match.path}/`}>
            <RedirectToDealroom pathname='/auth' />
          </Route>

          <Route
            component={() => (
              <Layout>
                <EmptyHeader />
                <ErrorPage />
              </Layout>
            )}
          />
        </Switch>
        {ReactDOM.createPortal(<div id='topZIndexModalContainer' style={{ zIndex: 100000 }} />, document.body)}
      </AntDesignProvider>
    </TranslationsProvider>
  )
}

export const LANGS_AVAILABLE = ['en', 'de']
// ==================================================================================================
/**
 * produces /:lang(de/en/...) where :lang is the param suffixed with a regex expression of all the
 * permissible language abbreviation codes.
 */
const langRegex = `:lang(${LANGS_AVAILABLE.join('|')})`
// ==================================================================================================

//  Just a wrapper to add needed style to the children
const MakeGlobalStyle = props => (
  //  Note: The order is important AntDesignProvider needs to be lower as possible to inherits of previous contexts in modals
  <TranslationsProvider lang={props.lang || 'en'}>
    <AntDesignProvider lang={props.lang || 'en'}>
      <Layout>
        <EmptyHeader />
        {props.children}
      </Layout>
    </AntDesignProvider>
  </TranslationsProvider>
)

//  Default context outside of lang
window.notification = notification
window.modal = Modal

/**
 * redirects to de if only a slash is entered
 * shows an error page if the lang parameter is not recognised
 */
// eslint-disable-next-line
export default () => (
  <ErrorBoundary globalStyle={MakeGlobalStyle}>
    <Switch>
      <Route key='1' exact path='/'><RedirectComp to='/de/' /></Route>
      {' '}
      {/* Redirect to the default lang */}
      <Route key='2' component={AppRoutes} path={`/${langRegex}`} />
      <Route
        component={() => (
          <MakeGlobalStyle>
            <ErrorPage />
          </MakeGlobalStyle>
        )}
      />
    </Switch>
  </ErrorBoundary>
)
