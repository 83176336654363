import React from 'react'
import { AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined, MenuOutlined } from '@ant-design/icons'
import { Editor, Transforms } from 'slate'
import { ToolbarButton, ToolbarButtonProps } from '@udecode/plate-ui-toolbar'

import { useFocusedEditor } from '../../Helpers'

import Group from './GroupFormat'

const ToolbarAlign: React.FC<ToolbarButtonProps & { align: string }> = ({ align, ...props }) => {
  const currentEditor = useFocusedEditor()
  const parentParagraph = currentEditor && Editor.above(currentEditor, { match: node => node.type === 'p' })

  return (
    <ToolbarButton
      {...props}
      active={parentParagraph && ((parentParagraph[0] as any).align || 'left') === align}
      onMouseDown={(currentEditor && parentParagraph) ? (() => {
        Transforms.setNodes(currentEditor, { align }, { at: parentParagraph[1] })
      }) : undefined}
    />
  )
}

export default (
  <Group key='slateTextAlignFormat'>
    <ToolbarAlign align='left' icon={<AlignLeftOutlined />} />
    <ToolbarAlign align='center' icon={<AlignCenterOutlined />} />
    <ToolbarAlign align='right' icon={<AlignRightOutlined />} />
    <ToolbarAlign align='justify' icon={<MenuOutlined />} />
  </Group>
)
