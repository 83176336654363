import { InputField } from '@top-legal/datastore'

import genPersonField from './genPersonField'
import genCompanyField from './genCompanyField'
import genProductTableField from './genProductTableField'


const incrementWithGlobalFields = (
  fieldsMap: Map<string, InputField>,
  lang: string,
  currentCompanyName = 'My Company',
  currentPersonName = 'My User'
): Map<string, InputField> => {
  //  Iterate over existing and transform parties
  fieldsMap.forEach((field, key) => {
    if (field) {
      if (field.type === 'person') {
        fieldsMap.set(key, genPersonField(field.name, lang, field.inputFieldID) as any)
      } else if (field.type === 'company') {
        fieldsMap.set(key, genCompanyField(field.name, lang, field.inputFieldID) as any)
      }
    }
  })

  //  Add global parties
  fieldsMap.set('__my_user', genPersonField(currentPersonName, lang, '__my_user') as any)
  fieldsMap.set('__my_company', genCompanyField(currentCompanyName, lang, '__my_company') as any)

  //  Add product table field
  fieldsMap.set('__products', genProductTableField(lang))

  return fieldsMap
}

export default incrementWithGlobalFields
