import React from 'react'
import { Collection, Model, TypeConfiguration } from '../Types'
import DatabaseContext from '../DatabaseContext'

export interface Section extends Model {
  sectionID: string
  name: string
  weight?: string
  tags?: string[]
  yDoc?: string
  isDynamicSection?: boolean
  field?: string
  fieldVersion?: number

  //  Boolean field activation
  active?: string

  //  List field activation
  activeValues?: string[]

  //  Number field activation
  inferiorBorder?: number | string
  inferiorBorderType?: string
  superiorBorder?: number | string
  superiorBorderType?: string

  //  Date field activation
  isRelativeToContractFillingDate?: boolean

  //  Explanations
  explanationText?: string
  explanationVideo?: string
  explanationTextExternal?: string
  explanationVideoExternal?: string

  //  Versioning
  version?: number
  dateUpdated?: string
}

export interface SectionDataset { sections: Section[] }

const config: TypeConfiguration<Section> = {
  key: 'sections',
  schema: {
    version: 1,
    primaryKey: 'sectionID',
    type: 'object',
    properties: {
      active: {
        type: 'string'
      },
      activeValues: {
        items: {
          type: 'string'
        },
        type: 'array'
      },
      field: {
        type: 'string'
      },
      fieldVersion: {
        type: 'number'
      },
      isDynamicSection: {
        type: 'boolean'
      },
      name: {
        type: 'string'
      },
      sectionID: {
        type: 'string'
      },
      tags: {
        items: {
          type: 'string'
        },
        type: 'array'
      },
      weight: {
        type: 'string'
      },
      yDoc: {
        type: 'string'
      },
      inferiorBorder: {
      },
      inferiorBorderType: {
        type: 'string'
      },
      superiorBorder: {
      },
      superiorBorderType: {
        type: 'string'
      },
      isRelativeToContractFillingDate: {
        type: 'boolean'
      },
      explanationText: {
      },
      explanationVideo: {
        type: 'string'
      },
      explanationTextExternal: {
      },
      explanationVideoExternal: {
        type: 'string'
      },
      version: {
        type: 'number'
      },
      dateUpdated: {
        type: 'string'
      }
    },
    required: ['sectionID']
  },
  migrationStrategies: {
    1: doc => doc
  }
}


export const useSectionsCollection = (): Collection<Section> => React.useContext(DatabaseContext)[config.key] as Collection<Section>


export const sectionDataGQLQuery = `
{
  sectionID
  name
  weight
  tags
  yDoc(legacy: true)
  isDynamicSection
  field
  fieldVersion
  clauseID

  #  Boolean field activation
  active

  #  List field activation
  activeValues

  #  Number field activation
  inferiorBorder
  inferiorBorderType
  superiorBorder
  superiorBorderType

  #  Date field activation
  isRelativeToContractFillingDate

  #  Explanations
  explanationText
  explanationVideo
  explanationTextExternal
  explanationVideoExternal

  #  Versioning
  version
  dateUpdated
}
`

export const sectionsTreeGQLQuery = `
{
  sectionID
  subSections {
    sectionID
    subSections {
      sectionID
      subSections {
        sectionID
        subSections {
          sectionID
        }
      }
    }
  }
}`


export const sectionsDataGQLQuery = `
{
  data ${sectionDataGQLQuery}
  subSections {
    data ${sectionDataGQLQuery}
    subSections {
      data ${sectionDataGQLQuery}
      subSections {
        data ${sectionDataGQLQuery}
        subSections {
          data ${sectionDataGQLQuery}
        }
      }
    }
  }
}`


export default config
