/* eslint-disable max-lines */
import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router'
import { Button, Dropdown, Menu, Tooltip, Typography } from 'antd'
import {
  AuditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined, FileAddOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  MenuOutlined,
  MoreOutlined
} from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import { GoogleFontSelectorProvider } from '@top-legal/react-helpers'
import RestService from '../../RestService'

//  Context
import { ContractContext, TemplateContext } from './ContractEditor/Contexts'
import { PhoneSidebarContext } from './ContractEditor/Sidebar/PhoneSidebar'
import { noAccountModal } from './ContractPageContext'

//  Components
import MainContainer from '../Layouts/MainLayout/MainContainer'
import { ConfirmMenuItem, TagListInput } from '../SharedComponents/FormComponents'
import ContractPageButtons from './ContractPageButtons'

//  Contract components
import ContractPageInner from './ContractPageInner'
import ContractPdfPage from './ContractPdfPage'
import ContractTopBar from './ContractTopBar'

//  Actions
import { useComputeSectionMapping, useDuplicateContract } from './Utils'

//  Styles
import './styles/ContractPageWrapper.scss'
import { DeviceContext } from '../../GlobalContext'

const ContractPage = ({ publicMode }) => {
  const isPhone = useContext(DeviceContext) === 'phone'
  const { entityID: contractID } = useParams()
  const { contract, updateContract, deleteContract } = useContext(ContractContext)
  const { template } = useContext(TemplateContext)
  const { organisationID } = useSelector(state => state.organisation.selectedOrganisation)

  const { pathname } = useLocation()
  useComputeSectionMapping()

  const openSidebarRef = useRef()

  /** *******************************************************************
   *                    Download Transaction Audit
   ******************************************************************* */
  const downLoadTransaction = useCallback(async () => {
    window.notification.info({
      message: <Trans>Preparing download</Trans>,
      description: <Trans>We sent you an audit of the transaction as a .PDF to your email address</Trans>
    })
    try {
      await RestService('POST', '/utils/transaction-audit', {
        contractID,
        organisationID
      })
    } catch {}
  }, [contractID, organisationID])

  /** *******************************************************************
   *                    Download document
   ******************************************************************* */
  const download = useCallback(
    async type => {
      /**
       * checking first if the user is on the public contract creation page. Only if the user is a
       * registered user the contract may be downloaded.
       */
      if (contractID && !publicMode) {
        window.notification.info({
          message: <Trans>Preparing download</Trans>,
          description: <Trans>We are currently preparing your document for download. We will send you a email with the generated link.</Trans>
        })
        try {
          /**
           * creating a link to the produced word document on the s3 bucket
           */
          await RestService('POST', '/utils/convert-document', { type, contractID })
        } catch {}
      } else {
        /**
         * displays a warning message, asking the user to register before proceeding
         * with the download of the contract.
         */
        noAccountModal()
      }
    },
    [contractID, publicMode]
  )


  const canClone = contract && contract.contractID && !contract.pdfSigningMode
  const cloneContract = useDuplicateContract()
  const cloneContractMenuItem = useMemo(() => (canClone ? (
    <Tooltip placement='left' title={<Trans>Start drafting a new contract from the same playbook with the terms you have filled in.</Trans>}>
      <Menu.Item onClick={() => cloneContract(contractID)}>
        <FileAddOutlined />
        <span><Trans>Duplicate contract</Trans></span>
      </Menu.Item>
    </Tooltip>
  ) : null), [canClone, cloneContract, contractID])


  const moreMenu = useMemo(() => {
    if (contract.contractStatus === 'signed') {
      return (
        <Menu key='menu'>
          <Menu.Item onClick={() => downLoadTransaction()}>
            <DownloadOutlined />
            <span>
              <Trans>Transaction Audit</Trans>
            </span>
          </Menu.Item>
          {cloneContractMenuItem}
        </Menu>
      )
    }

    return (
      <Menu key='menu'>
        {!contract.pdfSigningMode && (
          <Menu.Item onClick={() => download('docx')}>
            <FileWordOutlined />
            <span>
              <Trans>Download .docx</Trans>
            </span>
          </Menu.Item>
        )}
        <Menu.Item onClick={() => download('pdf')}>
          <FilePdfOutlined />
          <span>
            <Trans>Download .pdf</Trans>
          </span>
        </Menu.Item>
        {cloneContractMenuItem}
        {contract.contractStatus !== 'frozen' && contract.contractID && (
          <ConfirmMenuItem onClick={deleteContract}>
            <DeleteOutlined />
            <span>
              <Trans>Delete contract</Trans>
            </span>
          </ConfirmMenuItem>
        )}
      </Menu>
    )
  }, [cloneContractMenuItem, contract.contractID, contract.contractStatus, contract.pdfSigningMode, deleteContract, downLoadTransaction, download])

  //  Avoid rendering while template preview being clean up
  if (contractID && contractID.startsWith('template-') && contract.contractID === '__preview__') {
    return null
  }

  const Icon = {
    frozen: AuditOutlined,
    signing: AuditOutlined,
    editing: EditOutlined
  }[contract.contractStatus] || FileOutlined

  //  Get the right content
  let content = <ContractPageInner />
  if (contract.pdfSigningMode || contractID === 'pdfSigning') {
    content = <ContractPdfPage />
  }

  return (
    <PhoneSidebarContext.Provider value={{ ActionsButtons: ContractPageButtons, openSidebarRef }}>
      <MainContainer
        mainContentClass='contractPageWrapper'
        publicMode={publicMode}
        topbarContent={
          <>
            <div className='topbarMainContent'>
              {!isPhone && (
                <>
                  <Icon className='headerIcon' twoToneColor='#3DBD7D' />
                  <div className='topbarMainContentInner'>
                    <div className='contractNameWithPlaybook'>
                      {!contract.deleted ? (
                        <Typography.Title
                          editable={{
                            onChange: contractName => updateContract({ contractName }, !pathname.includes('new'))
                          }}
                          style={{ width: '100%' }}
                        >
                          {contract.contractName}
                        </Typography.Title>
                      ) : (
                        <h1>{contract.contractName}</h1>
                      )}
                      {template.name && (
                        <span className='contractBasedOnPlaybook phoneHidden'>
                          └─ <Trans>Based on playbook «{template.name}»</Trans> ─┘
                        </span>
                      )}
                    </div>
                    {/* TODO: Find an other way to display labels */}
                    {false && !contract.deleted && !pathname.includes('new') && (
                      <div>
                        <TagListInput
                          addText={<Trans>Add label</Trans>}
                          max={3}
                          onChange={async newTags => {
                            await updateContract({ tags: newTags }, false)
                          }}
                          readOnly={publicMode}
                          value={contract.tags || []}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            {!isPhone && <div id='topbarExtraContent' />}
            <div className='topbarActions'>
              {isPhone ? (
                <Button
                  className='noBorder phoneSidebarDrawerButton'
                  ghost
                  icon={<MenuOutlined />}
                  onClick={() => openSidebarRef.current(true)}
                  type='default'
                />
              ) : (
                <ContractPageButtons />
              )}
              {!isPhone && contract.contractStatus && contract.contractStatus !== 'imported' && (
                <Dropdown overlay={moreMenu} placement='bottomRight' trigger={['click']}>
                  <Button
                    className='noBorder moreButton'
                    ghost
                    // eslint-disable-next-line no-script-url
                    href='javascript:void(0)'
                    icon={window.IS_TRANSFERINITIATIVE ? <MenuOutlined /> : <MoreOutlined />}
                  />
                </Dropdown>
              )}
            </div>
          </>
        }
      >
        {content}

        {!window.IS_TRANSFERINITIATIVE && !isPhone && contract.contractStatus && contract.contractStatus === 'editing' && <ContractTopBar />}
      </MainContainer>
    </PhoneSidebarContext.Provider>
  )
}

export default ContractPage
