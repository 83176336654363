import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { useHistory, useParams } from 'react-router'

import './DealroomModalStyles.scss'


export const openShowDealroomModal = (contractID: string, contractName: string) => {
  let modal
  const DealroomModal: React.FC = () => {
    const [openDrawer, setOpenDrawer] = useState(false)
    const domain = process.env.DEAL_DOMAIN
    const { lang } = useParams()
    const history = useHistory()

    useEffect(() => {
      window.addEventListener('message', event => {
        if (event.origin === domain) {
          if (event.data === '__close_modal__') {
            modal.destroy()
          } else if (event.data === '__finish__') {
            modal.destroy()
            history.replace(`/${lang}/listing/contracts`)
          }
        }
      }, false)
    }, [domain, history, lang])

    if (openDrawer) {
      return (
        <>
          <div style={{ minHeight: 0, flex: 1 }}>
            <iframe
              frameBorder='0'
              //  Super important to not restrict our iframe with sandbox property
              src={`${domain}/create-deal-embed?contractID=${contractID}&contractName=${contractName}`}
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </>
      )
    }

    return (
      <div>
        <div style={{ maxWidth: '24rem', margin: '0 auto' }}>
          <div
            style={{
              padding: '56.25% 0 0 0', position: 'relative', marginBottom: '1rem'
            }}
          >
            <iframe
              allowFullScreen
              frameBorder='0'
              src='https://www.loom.com/embed/d7dc432981ee44ebb1a10fefa023c6ba'
              style={{
                position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'
              }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            ghost
            onClick={() => modal.destroy()}
            style={{ marginRight: '1rem' }}
            type='primary'
          >
            Use existing signing process
          </Button>
          <Button
            onClick={() => {
              setOpenDrawer(true)
              modal.update({
                title: null,
                footer: null,
                closable: false,
                className: 'dealroomModalDrawer'
              })
            }}
            type='primary'
          >
            Try new Dealroom
          </Button>
        </div>
      </div>
    )
  }

  modal = (window as any).modal.info({
    className: 'mediumModal dealroomModal',
    title: <h2>Get to know the Dealroom!</h2>,
    content: <DealroomModal />,
    footer: null,
    icon: null,
    closable: true
  })
}

;(window as any).openShowDealroomModal = openShowDealroomModal
