/* eslint-disable no-console */
import { useCallback, useRef } from 'react'

const useEventSubscription = () => {
  const handlers = useRef()

  //  Subscribtion
  const subscribe = useCallback(handler => {
    if (!handlers.current) {
      handlers.current = []
    }

    const index = handlers.current.findIndex(hdl => hdl === handler)
    if (index >= 0) {
      console.warn('You already subscribe with this handler')
      console.trace()
    } else {
      handlers.current.push(handler)
    }
  }, [])

  //  Unsubscription
  const unSubscribe = useCallback(handler => {
    if (!handlers.current) {
      handlers.current = []
    }

    const index = handlers.current.findIndex(hdl => hdl === handler)
    if (index >= 0) {
      handlers.current.splice(index, 1)
    } else {
      console.warn('You didn\'t subscribe with this handler')
      console.trace()
    }
  }, [])

  //  Fire event through handlers
  const fireEvent = useCallback(async data => {
    if (!handlers.current) {
      handlers.current = []
    }

    const event = new CustomEvent('customSubscribtionEvent', data)

    for (let index = 0; index < handlers.current.length; index += 1) {
      await handlers.current[index](event) // eslint-disable-line
    }
  }, [])

  return [subscribe, unSubscribe, fireEvent]
}

export default useEventSubscription
