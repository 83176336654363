import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Alert } from 'antd'
import { useParams } from 'react-router'
import { Trans } from '@lingui/macro'

import { useSectionsCollection, useTemplatesCollection } from '@top-legal/datastore'

import { TemplateContext } from './Contexts'
import { deleteSection, loadTemplate, saveTemplate } from '../../Template/redux/TemplateActions'
import useLoadData from '../../../hooks/useLoadData'
import { GlobalLoadingAlert } from '../../Alert/LoadingAlert'
import { parrallelToSequentialFactory } from '../../Template/_TemplateHelperFunctions'
import { ensureTemplateSections } from '../Utils'
import { TemplateEntitiesContext } from './TemplateEntitiesContext'
import { useClausesStore } from './editorRefactoredPart/sectionComponents/Clause'


// ==================================================================================================


// ==================================================================================================

/**
 * This is the context wrapper for Redux and RXDB. It pulls all the data from the RXDB and the Redux
 * store. The wrapper makes the data accessible through the root template component.
 */

const TemplateContextProvider: React.FC = ({ children }) => {
  const { lang = 'en' } = useParams()
  const dispatch = useDispatch()

  //  Get the input fields, conditional text and the template from Redux
  const { template, inputFields, conditionalTexts, entityLoading } = useContext(TemplateEntitiesContext)

  //  Settings
  const [readOnly, setReadOnly] = useState(false)

  /**
   * Loading template data only if it's a templateID in the url
   */
  const { entityID } = useParams()
  const templateID = entityID && entityID.startsWith('template-') && entityID
  const isForContract = entityID && entityID.startsWith('contract-')

  const templatesCollection = useTemplatesCollection()
  const sectionsCollection = useSectionsCollection()
  const [loading, error] = useLoadData(
    async theTemplateID => {
      const _template = await dispatch(loadTemplate(theTemplateID, templatesCollection))

      //  Full load the template by ensuring sections in redux
      await dispatch(ensureTemplateSections(_template, sectionsCollection))
    },
    [templateID],
    !isForContract && !!templateID && (!template || template.templateID !== templateID)
  )


  //  Set clause library on loading
  useMemo(() => useClausesStore.setState({ loading: true }), [])


  /** ******************************************************************
   *                    Some state for editing
   ****************************************************************** */
  const [editLoading, setEditLoading] = useState(false)
  const [sectionMapping, setSectionMapping] = useState({})
  const [sectionDeltasMapping, setSectionDeltasMapping] = useState({})

  const templateIdRef = useRef(template.templateID) // Avoid recursive update
  templateIdRef.current = template.templateID

  /** ******************************************************************
   *                Template edit section function
   ****************************************************************** */
  const [deletePart, updateTemplate] = useMemo(() => {
    const success = value => {
      setEditLoading(false)
      return value
    }

    const fail = err => {
      setEditLoading(false)
      console.error('Contract template saving error', err)
      throw err
    }

    /**
     * Actions
     */
    const _deletePart = sectionID => {
      setEditLoading(true)
      return dispatch(deleteSection(sectionID)).then(success).catch(fail)
    }

    const _updateTemplate = values => {
      setEditLoading(true)
      return dispatch(saveTemplate(values)).then(success).catch(fail)
    }

    return [_deletePart, _updateTemplate]
  }, [dispatch])

  //  Transform the setSectionDeltasMapping into sequential (thread proof)
  const parrallelToSequential = useMemo(parrallelToSequentialFactory, [])
  const newSetSectionDeltasMapping = useCallback(data => {
    parrallelToSequential(() => {
      setSectionDeltasMapping(old => {
        if (typeof data === 'function') {
          return data(old)
        }
        return data
      })
    })
  }, [parrallelToSequential])

  /**
   * Return loading and error views
   */
  if (error) {
    return <Alert message={<Trans>Failed to load your contract template</Trans>} showIcon type='error' />
  }

  /**
   * Return the context provider with the data
   */
  return (
    <GlobalLoadingAlert description={<Trans>Loading your playbook</Trans>} loading={loading || entityLoading}>
      <TemplateContext.Provider
        value={{
          template: loading ? {} : template,
          lang,
          inputFields,
          conditionalTexts,
          editLoading,
          setEditLoading,
          readOnly,
          deletePart,
          updateTemplate,

          sectionMapping,
          setSectionMapping,
          sectionDeltasMapping,
          setSectionDeltasMapping: newSetSectionDeltasMapping,

          //  Config
          setReadOnly
        }}
      >
        {children}
      </TemplateContext.Provider>
    </GlobalLoadingAlert>
  )
}

//  Export withTemplateContext
// eslint-disable-next-line react/display-name
const withTemplateContext = <T, >(Component: React.FC<T>): React.FC<T> => props => (
  <TemplateContextProvider>
    <Component {...props} />
  </TemplateContextProvider>
)

export default withTemplateContext
