import React, { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { Trans } from '@lingui/macro'
import { LoginOutlined } from '@ant-design/icons'
import ExternalMainContainer from './ExternalMainContainer'
import { defaultUser, UserAndCompanyDataContext } from '../Layouts/Constants'

const PortalLoginregister = () => {
  const { lang } = useParams()
  const history = useHistory()
  const { user } = useContext(UserAndCompanyDataContext)

  useEffect(() => {
    if (user.userID !== defaultUser.userID) {
      history.replace(`/${lang}/contract-portal/contracts`)
    }
  }, [history, lang, user.userID])

  return null
}

const PortalLoginregisterPage = () => (
  <ExternalMainContainer
    mainContentClass='aboutTopLegal'
    topbarContent={(
      <div className='topbarMainContent'>
        <LoginOutlined className='headerIcon' twoToneColor='#3DBD7D' />
        <h1 className='title'><Trans>Login</Trans></h1>
      </div>
    )}
  >
    <PortalLoginregister />
  </ExternalMainContainer>
)

export default PortalLoginregisterPage
