/* eslint-disable max-len */
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Trans } from '@lingui/macro'
import { PieChartOutlined } from '@ant-design/icons'

import { useContractsListBaseQuery, useList, useTemplateListBaseQuery } from '@top-legal/datastore'

import MainContainer from '../Layouts/MainLayout/MainContainer'
import { withUserData } from '../Layouts/AuthenticatedPage'
import { UserAndCompanyDataContext } from '../Layouts/Constants'
import StatisticsBox from './StatisticsBox'
import TimeToClose from './StatisticsComponents/TimeToClose'
import ContractDistribution from './StatisticsComponents/ContractDistribution'
import Export from './StatisticsComponents/Export'
import Conversion from './StatisticsComponents/Conversion'
import Deals from './StatisticsComponents/Deals'
import FeedbackBox from './StatisticsComponents/FeedbackBox'
import FilterStatistics from './StatisticsComponents/FilterStatistics'
import ClosedContractRate from './StatisticsComponents/ClosedContractsRate'
import { emptyArray } from '../Listing/SearchHelpers'

import './StatisticsDashboardStyles.scss'

const StatisticsDashboard = ({ boxes = [FilterStatistics, ContractDistribution, TimeToClose, ClosedContractRate, Deals, Export, Conversion, FeedbackBox] }) => {
  const userCompanyData = useContext(UserAndCompanyDataContext)
  const [selectedRows, setSelectedRows] = useState([])
  const [filterTeams, setFilterTeams] = useState('all')
  const [filterEmployee, setFilterEmployee] = useState('all')

  //  Contracts data
  const getContracts = useContractsListBaseQuery()
  const [orgContracts = emptyArray] = useList(
    useMemo(() => getContracts.sort({ dateUpdated: 'desc' }), [getContracts]),
    useCallback(contracts => {
      contracts.forEach(contract => {
        if (contract.freezeDate && contract.dateCreated) {
          contract.fillingDuration = new Date(contract.freezeDate) - new Date(contract.dateCreated)
        }
        if (contract.signedDate && contract.freezeDate) {
          contract.pendingDuration = new Date(contract.signedDate) - new Date(contract.freezeDate)
        }
      })

      return contracts
    }, [])
  )

  //  Templates data
  const getTemplates = useTemplateListBaseQuery()
  const [allTemplates = emptyArray] = useList(useMemo(() => getTemplates.sort({ dateUpdated: 'desc' }), [getTemplates]))

  const { fieldResponses, inputFields } = useSelector(state => state.organisation)

  const changeFilterEmployee = value => setFilterEmployee(value)
  const changeFilterTeams = value => setFilterTeams(value)

  const templatesFormatted = useMemo(() => {
    if (selectedRows.length === allTemplates.length) {
      return allTemplates
    }
    return allTemplates.filter(elem => selectedRows.includes(elem.templateID))
  }, [allTemplates, selectedRows])

  // Contracts - Team - Employee - Filtering //
  const filterContract = useCallback(
    (tag, contract) => {
      const { createdBy } = contract
      if (tag === 'all') { return true }
      if (tag.startsWith('team')) {
        const owner = userCompanyData.company.members[createdBy]
        if (owner && owner.organisationRole && owner.organisationRole.team === tag) {
          return true
        }
        return false
      }
      return tag === createdBy
    },
    [userCompanyData]
  )

  const contractsFiltered = useMemo(() => orgContracts.filter(contract => {
    if (filterEmployee && filterEmployee !== 'all') {
      return filterContract(filterEmployee, contract)
    }
    return filterContract(filterTeams, contract)
  }), [filterTeams, filterEmployee, filterContract, orgContracts])


  return (
    <MainContainer
      topbarContent={(
        <div className='topbarMainContent statisticsBoardTile'>
          <PieChartOutlined className='headerIcon' twoToneColor='#3DBD7D' />
          <h1 className='title'><Trans>Analytics</Trans></h1>
        </div>
      )}
    >
      <div className='statisticsBoxes'>
        {boxes.map(stat => (
          <StatisticsBox
            {...stat}
            key={stat.key}
            boxKey={stat.key}
            changeFilterEmployee={changeFilterEmployee}
            changeFilterTeams={changeFilterTeams}
            contractsTable={orgContracts}
            data={contractsFiltered}
            fieldResponses={fieldResponses}
            filter={setSelectedRows}
            filterEmployee={filterEmployee}
            filterTeams={filterTeams}
            inputFields={inputFields}
            selectedRowKeys={selectedRows}
            templates={templatesFormatted.length === 0 ? allTemplates : templatesFormatted}
            templatesTable={allTemplates}
            userCompanyData={userCompanyData}
          />
        ))}
      </div>
    </MainContainer>
  )
}

const Wrapped = props => {
  const Component = useMemo(() => withUserData(StatisticsDashboard), [])
  return <Component {...props} />
}

export default Wrapped
