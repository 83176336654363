/* eslint-disable no-console,@typescript-eslint/no-var-requires,global-require,no-await-in-loop */
import React, { useEffect, useState } from 'react'
import { Hub } from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'

import { addRxPlugin, createRxDatabase, RxDatabase, RxStorage } from 'rxdb/plugins/core'
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election'
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder'
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration'
import { getRxStorageLoki } from 'rxdb/plugins/lokijs'
import { RxDBLocalDocumentsPlugin } from 'rxdb/plugins/local-documents'
import LokiIncrementalIndexedDBAdapter from 'lokijs/src/incremental-indexeddb-adapter'

import DatabaseContext from './DatabaseContext'
import DatabaseWriters from './DatabaseWriters'
import CustomReplication from './CustomReplication'
import setupCollections, { checkMigration } from './Schema/SetupCollections'
import isSupportedBrowser from './isSupportedBrowser'
import { getStorageUsage } from './Helpers'


addRxPlugin(RxDBQueryBuilderPlugin)
addRxPlugin(RxDBLeaderElectionPlugin)
addRxPlugin(RxDBMigrationPlugin)
addRxPlugin(RxDBLocalDocumentsPlugin)


const NO_INDEXED_DB = 'indexed_db_went_bad'

const STORAGE_NAME = 'lokijs'
const SELECTED_STORAGE = () => getRxStorageLoki({
  adapter: new LokiIncrementalIndexedDBAdapter()
})

const init = async (gqlEndpointUrl: string, dbName: string, online: boolean, getStorage: () => RxStorage<any, any> = SELECTED_STORAGE) => {
  const isSupported = await isSupportedBrowser()
  if (!isSupported) {
    // eslint-disable-next-line no-throw-literal
    throw { name: NO_INDEXED_DB }
  }

  let database
  const destroyDB = async () => {
    //  Removing last sync date
    localStorage.removeItem(`${CustomReplication.REPLICATION_KEY}-${process.env.REACT_APP_ENV}-${dbName}-${STORAGE_NAME}`)
    //  Destroying the DBs
    await database?.remove()
  }

  //  Setup the database
  database = await createRxDatabase({
    name: `${process.env.REACT_APP_ENV}-${dbName}-${STORAGE_NAME}`,
    storage: getStorage(),
    multiInstance: true,
    eventReduce: true,
    localDocuments: true
  } as any).catch(err => {
    console.error('Database error', err)
    throw err
  })

  //  Development purpose
  if (process.env.NODE_ENV === 'development') {
    console.log(`database.token: ${database.token}`)
    console.log(`database.storageToken: ${database.storageToken}`)
    console.log('database available in window')
    ;(window as any).database = database

    database.waitForLeadership().then(() => {
      if (document.title[0] !== '♛') { document.title = `♛ ${document.title}` }
    })
  }

  //  Setup the database collections
  let collections
  while (!collections) {
    try {
      collections = await setupCollections(database)
    } catch (err: any) {
      console.error('Cannot setup collections', err)
      if (err.message && err.message.includes('previousSchemaHash')) {
        console.error('Invalid schema, destroying DB')
        await destroyDB()
      } else {
        throw err
      }
    }
  }

  //  Bind writers
  ;(database as any).databaseWriters = new DatabaseWriters(database, collections as any)

  //  Check if we need to do a migration (in background)
  checkMigration(database, collections)

  //  Just print the estimated storage usage
  getStorageUsage()

  //  Build multi collections sync
  if (online && !process.env.REACT_APP_NO_REPLICATION) {
    const replication = new CustomReplication(database, collections, gqlEndpointUrl)
    replication.startReplication() // Do not wait the promise this is a background job
    ;(database as any).__replication__ = replication
  }

  return database
}

export interface PersistenceProviderProps {
  gqlEndpointUrl: string
  onReady?: () => void
  indexedDbNotSupported?: React.ReactElement
}

const PersistenceProvider: React.FC<PersistenceProviderProps> = ({
  gqlEndpointUrl,
  onReady,
  indexedDbNotSupported = null,
  children
}) => {
  const [database, setDb] = useState<any>()

  useEffect(() => {
    //  We need to have indexedDB to do anything
    if (window.indexedDB) {
      //  Take care of replacing the previous DB
      let prevDb: RxDatabase
      const setNewDb = async (_db?: any) => {
        setDb(_db)

        if (prevDb) {
          // Destroy not remove, just to close the sync but keep the data on disk
          await prevDb.destroy()
        }
        prevDb = _db
      }

      //  Setup the current userDB
      const setupDb = async () => {
        let isGuest = true
        let dbName = 'top-legal-guest'
        try {
          const currentSession = await Auth.currentAuthenticatedUser()
          dbName = currentSession.attributes.sub
          isGuest = false
        } catch {}

        try {
          const _db = await init(gqlEndpointUrl, dbName, !isGuest, isGuest ? getRxStorageLoki : undefined)
          await setNewDb(_db)
        } catch (err: any) {
          console.error('Cannot setup storage', err)
          if (err.name === NO_INDEXED_DB) {
            await setNewDb(NO_INDEXED_DB)
          }
        }
      }

      //  Listen to auth change and close database when log out
      Hub.listen('auth', ({ payload: { event } }) => {
        if (event === 'signIn' || event === 'signOut') {
          setupDb() //  Try to setup db when login in or log out
        }
      })

      //  try to setup the db
      setupDb().finally(() => onReady?.())
    }
  }, [gqlEndpointUrl, onReady])

  if (!window.indexedDB || database === NO_INDEXED_DB) {
    return indexedDbNotSupported
  }

  return database ? <DatabaseContext.Provider value={database}>{children}</DatabaseContext.Provider> : null
}


export default PersistenceProvider

