import { InputField } from '@top-legal/datastore'

const employeesOptions_ = {
  employees_1_10: '1 - 10',
  employees_11_25: '11 - 25',
  employees_26_50: '26 - 50',
  employees_51_200: '51 - 200',
  employees_201_500: '201 - 500',
  employees_500_more: '500+'
}

const employeesOptions = {
  en: {
    alone: 'I work alone',
    ...employeesOptions_
  },
  de: {
    alone: 'Ich arbeite allein',
    ...employeesOptions_
  }
}

const industryOptions = {
  de: {
    biotechnology: 'Biotechnologie',
    construction: 'Baugewerbe',
    retail: 'Einzelhandel & Großhandel',
    entertainment: 'Entertainment & Kunst',
    finance: 'Finanzen',
    health: 'Gesundheitswesen',
    internet: 'Internet und E-Commerce',
    legal: 'Rechtsberatung',
    manufacturing: 'Herstellendes Gewerbe',
    media: 'Medien & Verlagswesen',
    professionalServices: 'Professional Services',
    food: 'Lebensmittel',
    realestateResidential: 'Immobilien - Vermietung',
    software: 'Software',
    technology: 'Technologie',
    other: 'Andere Industrie'
  },
  en: {
    biotechnology: 'Biotechnology',
    construction: 'Construction',
    retail: 'Retail',
    entertainment: 'Entertainment',
    finance: 'Finance',
    health: 'Health care',
    internet: 'Internet and E-Commerce',
    legal: 'Legal advisory',
    manufacturing: 'Manufacturing',
    media: 'Media and Telecoms',
    professionalServices: 'Professional Services',
    food: 'Food',
    realestateResidential: 'Real Estate',
    software: 'Software',
    technology: 'Technologie',
    other: 'Other'
  }
}

interface CompanyField extends Omit<InputField, 'inputFieldID' | 'question' | 'isMultipleChoices'> {
  inputFieldID?: string
  value: (user: any, textFormatted: boolean) => any
  items: Record<string, CompanyField>
  slateType?: 'block' | 'inline'
  optional?: boolean
}


const genCompanyField = (fieldName: string, lang: string, inputFieldID: string): CompanyField => {
  const field: CompanyField = {
    inputFieldID,
    name: fieldName,
    type: 'company',
    slateType: 'block',
    value: (company, textFormatted) => {
      if (textFormatted) {
        const children = field.items

        const name = children.name.value(company, textFormatted)
        const address = children.full_address.value(company, textFormatted)
        const vatID = children.vatID.value(company, textFormatted)

        if (name && address) {
          return [
            { type: 'p', children: [{ text: name }] },
            address[0],
            ...(vatID ? [{ type: 'p', children: [{ text: `\nVatID: ${vatID}` }] }] : [])
          ]
        }
        return undefined
      }

      return company
    },
    items: {
      name: {
        name: `${fieldName} name`,
        type: 'oneLineText',
        value: company => company?.name,
        items: {}
      },
      industry: {
        name: `${fieldName} industry`,
        type: 'list',
        value: (company, textFormatted) => {
          if (textFormatted) {
            return (industryOptions[lang] || industryOptions.en)[company?.industry]
          }
          return company?.industry
        },
        values: industryOptions[lang] || industryOptions.en,
        optional: true,
        items: {}
      },
      employees: {
        name: `${fieldName} employees number`,
        type: 'list',
        value: (company, textFormatted) => {
          if (textFormatted) {
            return (employeesOptions[lang] || employeesOptions.en)[company?.employees]
          }
          return company?.employees
        },
        values: employeesOptions[lang] || employeesOptions.en,
        optional: true,
        items: {}
      },
      full_address: {
        name: `${fieldName} full address`,
        type: 'full_address',
        value: (company, textFormatted) => {
          if (textFormatted) {
            const children = field.items.full_address.items

            const country = children.country.value(company, textFormatted)
            const postcode = children.postcode.value(company, textFormatted)
            const city = children.city.value(company, textFormatted)
            const address = children.address.value(company, textFormatted)

            if (country && postcode && city && address) {
              return [{ type: 'p', children: [{ text: `${address}\n${postcode} ${city}\n${country}` }] }]
            }
            return undefined
          }

          return company
        },
        slateType: 'block',
        items: {
          country: {
            name: `${fieldName} country`,
            type: 'oneLineText',
            value: company => company?.country,
            items: {}
          },
          postcode: {
            name: `${fieldName} postcode`,
            type: 'oneLineText',
            value: company => company?.postcode,
            items: {}
          },
          city: {
            name: `${fieldName} city`,
            type: 'oneLineText',
            value: company => company?.city,
            items: {}
          },
          address: {
            name: `${fieldName} address`,
            type: 'oneLineText',
            value: company => company?.address,
            items: {}
          }
        }
      },
      vatID: {
        name: `${fieldName} vatID`,
        type: 'oneLineText',
        value: company => company?.vatID,
        optional: true,
        items: {}
      },
      commercialID: {
        name: `${fieldName} commercialID`,
        type: 'oneLineText',
        value: company => company?.commercialID,
        optional: true,
        items: {}
      }
    }
  }

  return field
}

export default genCompanyField
