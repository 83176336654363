/* eslint-disable no-await-in-loop,no-restricted-syntax */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Trans } from '@lingui/macro'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'

import Pdf from '../../Media/Pdf'
import useStorageUploadFeedback, { getFileUrl } from '../../../hooks/useStorageUploadFeedback'

import TemplatePlaceholder from '../../../img/templatePlaceholder.png'

import './MultiFileUploaderStyles.scss'
import { RemToPxContext } from '../../../GlobalContext'


const pages = [0]
const FilePreview: React.FC<{ file: File }> = ({ file }) => {
  const url = useRef('')
  const [openPreview, setOpenPreview] = useState(false)
  const remToPx = useContext(RemToPxContext)

  //  Get an object url from a user local file
  useMemo(() => {
    if (url.current) {
      URL.revokeObjectURL(url.current)
    }
    url.current = URL.createObjectURL(file)
  }, [file])

  //  Cleanup at unmount
  useEffect(() => () => {
    if (url.current) {
      URL.revokeObjectURL(url.current)
    }
  }, [])

  //  Try to render preview with known types
  if (url.current) {
    if (file.type === 'application/pdf') {
      return (
        <>
          <div className='pdfPreview' onClick={() => setOpenPreview(true)}>
            <Pdf
              noLoading
              pages={pages}
              url={url.current}
              width={remToPx * 10}
            />
          </div>
          <Modal
            className='mediumModal pdfPreviewModal'
            destroyOnClose
            footer={null}
            onCancel={() => setOpenPreview(false)}
            visible={openPreview}
          >
            <Pdf noLoading url={url.current} />
          </Modal>
        </>
      )
    }
    //  Cannot have localhost file in remote preview of .doc or .docx (it uses google or microsoft remote viewers)
  }

  //  Fallback preview
  return <img src={TemplatePlaceholder} />
}


interface MultiFileUploaderProps {
  accept: string
  multiple?: boolean
  useFeedbackModal?: boolean
  withFilePreview?: boolean
  onFinished?: (keys: string[]) => void
  title?: React.ReactElement
  description?: React.ReactElement

  //  Includes more info than the key like { key, name, url }
  withNames?: boolean
  withUrls?: boolean
}

const MultiFileUploader: React.FC<MultiFileUploaderProps> = ({
  accept, multiple,
  useFeedbackModal, withFilePreview, onFinished,
  title, description,
  withNames, withUrls
}) => {
  const [localFiles, setLocalFiles] = useState<File[]>([])
  const inputRef = useRef<HTMLInputElement>(null)

  const [uploadFeedback, upload] = useStorageUploadFeedback()
  const uploadFiles = useCallback(async (files: File[]) => {
    try {
      let keys: any[] = []

      for (const file of files) {
        const key = ((file as any).__key__) || await upload(`${(new Date()).toISOString()}.${file.name.split('.').pop()}`, file)
        ;(file as any).__key__ = key // Just save the uploaded files in case there is an error in one file
        keys.push(key)
      }

      //  Add more data if required
      if (withNames || withUrls) {
        keys = keys.map(key => ({ key }))
      }

      //  Add names required
      if (withNames) {
        files.forEach((file, index) => {
          const split = file.name.split('.')
          split.pop() //  Remove the extension from the name
          keys[index].name = split.join(' ')
        })
      }

      //  Add urls required
      if (withUrls) {
        for (const file of keys) {
          file.url = await getFileUrl(file.key)
        }
      }

      try {
        onFinished?.(keys)
      } catch {}
      setLocalFiles([])
      if (inputRef.current) { inputRef.current.value = '' }
    } catch (err) {
      console.error('Failed to upload files', err)
    }
  }, [onFinished, upload, withNames, withUrls])


  const handleNewFiles = useCallback((files: FileList) => {
    if (multiple) {
      if (withFilePreview) {
        setLocalFiles(old => [...old, ...files])
      } else {
        uploadFiles([...files])
      }
    } else {
      const arr: File[] = []
      if (files.length > 0) {
        arr.push(files.item(0) as File)

        if (files.length > 1) {
          (window as any).notification.info({
            message: <Trans>Only one file is supported</Trans>
          })
        }
      }
      if (withFilePreview) {
        setLocalFiles(arr)
      } else {
        uploadFiles(arr)
      }
    }
  }, [withFilePreview, multiple, uploadFiles])


  const preview = uploadFeedback || (
    <div className='previewContainer'>
      <div className='previewFileList'>
        {localFiles.map((file, index) => (
          <div key={index} className='previewItem'>
            <div className='previewBox'>
              <FilePreview file={file} />
              <Button
                danger
                ghost
                icon={<DeleteOutlined />}
                onClick={() => setLocalFiles(old => {
                  const arr = [...old]
                  arr.splice(index, 1)
                  return arr
                })}
                type='primary'
              />
            </div>
          </div>
        ))}
      </div>
      <div className='buttonWrapper'>
        <Button onClick={() => uploadFiles(localFiles)} type='primary'>
          <Trans>Validate & Upload</Trans>
        </Button>
      </div>
    </div>
  )

  return (
    <div className='multiFileUploader'>
      <input
        ref={inputRef}
        accept={accept}
        multiple={multiple}
        onChange={useCallback(evt => handleNewFiles(evt.target.files), [handleNewFiles])}
        type='file'
      />
      <div
        className='uploadArea'
        onClick={useCallback(() => inputRef.current?.click(), [])}
        onDragEnter={useCallback(evt => {
          evt.preventDefault()
          evt.target?.classList?.add('dragover')
        }, [])}
        onDragLeave={useCallback(evt => {
          evt.preventDefault()
          evt.target?.classList?.remove('dragover')
        }, [])}
        onDragOver={useCallback(evt => {
          evt.preventDefault()
        }, [])}
        onDrop={useCallback(evt => {
          evt.preventDefault()
          evt.target?.classList?.remove('dragover')
          handleNewFiles(evt.dataTransfer.files)
        }, [handleNewFiles])}
      >
        <h3><PlusOutlined /> <span>{title || <Trans>Add File</Trans>}</span></h3>
        <p>{description || <Trans>Click or drag your {accept} file to this area to upload</Trans>}</p>
      </div>
      {
        useFeedbackModal ? (
          <Modal
            className='mediumModal multiFileUploaderPreviewModal'
            destroyOnClose
            footer={null}
            onCancel={() => setLocalFiles([])}
            title={<h1><Trans>Upload new files</Trans></h1>}
            visible={localFiles.length > 0}
          >
            {preview}
          </Modal>
        ) : preview
      }
    </div>
  )
}

export default MultiFileUploader
