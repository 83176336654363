import { useHistory } from 'react-router'
import { createBrowserHistory } from 'history'

export const historyPrivate = createBrowserHistory()

let history_: typeof historyPrivate = null
export const getHistory = () => history_

export const GetHistory = () => {
  history_ = useHistory()
  return null
}
