import React, { useCallback, useMemo, useState } from 'react'
import { Input, Select } from 'antd'

import useDebounceSaving from '@top-legal/legacy-app/src/hooks/useDebounceSaving'

import { CustomInput } from '../Types'

const noop = () => undefined
const emptyStr = ''

const InputLink: React.FC<CustomInput<string>> = ({
  value = emptyStr,
  onChange = noop,
  className = '',
  ...props
}) => {
  const [protocol, setProtocol] = useState('https://')
  const [link, setLink] = useState('')

  const setData = useMemo(() => (newPropsLink: string, noDefault?: boolean) => {
    const split = newPropsLink.split('://')
    if (split.length >= 2) {
      setProtocol(`${split.shift()}://`)
      setLink(split.join('://'))
    } else {
      !noDefault && setProtocol('https://')
      setLink(newPropsLink)
    }
  }, [])

  const { saveTimeout, forceSave } = useDebounceSaving(
    500,
    value,
    `${protocol}${link}`,
    setData,
    onChange
  )

  const protocolSelect = (
    <Select
      {...props as any}
      className='protocolSelect'
      onBlur={forceSave}
      onSelect={useCallback(val => {
        setProtocol(val)
        saveTimeout()
      }, [saveTimeout])}
      value={protocol}
    >
      <Select.Option value='https://'>https://</Select.Option>
      <Select.Option value='http://'>http://</Select.Option>
    </Select>
  )

  return (
    <Input
      {...props}
      addonBefore={protocolSelect}
      className={`inputLink ${className}`}
      onBlur={forceSave}
      onChange={useCallback(evt => {
        setData(evt.target.value)
        saveTimeout()
      }, [saveTimeout, setData])}
      value={link}
    />
  )
}

export default InputLink
