import React, {
  useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState
} from 'react'
import { useQueryParam, NumberParam, withDefault } from 'use-query-params'
import { Trans } from '@lingui/macro'
import { useDispatch, useSelector } from 'react-redux'
import { MessageOutlined } from '@ant-design/icons'
import { Alert } from 'antd'

import { Section } from '@top-legal/datastore'

//  Contexts
import { ContractContext, TemplateContext } from '../../../Contexts'
import { SectionsContext } from '../../../editorRefactoredPart/sectionComponents/ContractEditorSection'
import { IdPrefixContext } from '../../../../ContractDiplay/ContractLayout'

//  Components
import { LocalLoadingAlert } from '../../../../../Alert/LoadingAlert'
import { loadComments } from '../../../../../Organisations/redux/OrganisationsActions'
import PhoneCommentsToolbox from './PhoneCommentsToolbox'
import Portal from '../../../../../SharedComponents/Portal'

import {
  CHILDID_CHAR_SEPARATOR,
  Comment,
  CommentsToolboxProps,
  CommentTree,
  CommentTreeCommentNode,
  FeedbackComment,
  StoreComment,
  TaskComment,
  TextComment,
  YjsDeltaComment
} from './Types'
import PartComment from './PartComment'
import CommentDisplay from './CommentDisplay'
import TaskDisplay from './TaskDisplay'
import YjsDeltaDisplay from './YjsDeltaDisplay'
import FeedbackDisplay from './FeedbackDisplay'

import './CommentStyles.scss'


/** **************************************************************
 *                     Util functions
 ************************************************************** */
export const RenderComments: React.FC<{ node: CommentTreeCommentNode, noThread?: boolean, onClick?: (comm: Comment, index: number) => void }> = ({
  node,
  noThread,
  onClick
}) => {
  if (node.comments) {
    return Object.entries(node.comments)
      .sort(([, valA], [, valB]) => {
        if (valA.comment && valB.comment) {
          if (valA.comment.date < valB.comment.date) {
            return -1
          }
          if (valA.comment.date > valB.comment.date) {
            return 1
          }
        }
        return 0
      })
      .map(([key, childNode], index) => {
        if (childNode && childNode.comment) {
          const reelClick = onClick ? () => onClick(childNode.comment as Comment, index) : undefined
          if ((childNode.comment as YjsDeltaComment).delta) {
            return <YjsDeltaDisplay key={key} noThread={noThread} node={childNode} onClick={reelClick} />
          }
          if ((childNode.comment as FeedbackComment).isFeedback) {
            return <FeedbackDisplay key={key} noThread={noThread} node={childNode} onClick={reelClick} />
          }
          if ((childNode.comment as TaskComment).assignedTo) {
            return <TaskDisplay key={key} noThread={noThread} node={childNode} onClick={reelClick} />
          }
          if ((childNode.comment as TextComment).text) {
            return <CommentDisplay key={key} noThread={noThread} node={childNode} onClick={reelClick} />
          }
        }
        return null
      }) as any
  }
  return null
}

export const getCoords = (elem: HTMLElement): { top: number, left: number } => {
  const box = elem.getBoundingClientRect()

  const { body } = document
  const docEl = document.documentElement

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  const clientTop = docEl.clientTop || body.clientTop || 0
  const clientLeft = docEl.clientLeft || body.clientLeft || 0

  const top = box.top + scrollTop - clientTop
  const left = box.left + scrollLeft - clientLeft

  return { top, left }
}

const getSectionCssHeight = (section?: HTMLElement | null): number => {
  if (section) {
    //  Now sections are individual (subsections separated) so then the height is the height of the content
    return section.clientHeight
  }
  return 0
}


/** **************************************************************
 *                Padder component
 ************************************************************** */
const usePadder = (padderRef: React.RefObject<HTMLDivElement>): () => void => {
  const idPrefix = useContext(IdPrefixContext)
  const { template } = useContext<any>(TemplateContext)

  return useMemo(() => {
    const handler = () => {
      if (padderRef.current) {
        const container = (padderRef.current.parentElement as any).parentElement as any
        const commentParts: HTMLDivElement[] = padderRef.current.querySelectorAll('.partComments') as any

        let offSet = getCoords(container).top
        commentParts.forEach(comment => {
          const id = (comment.getAttribute('id') || '').replace('-partComments', '')
          const section = document.getElementById(`${idPrefix}${template.templateID}-${id}`)

          if (section) {
            const height = getSectionCssHeight(section)
            const heightStr = `${height}px`

            comment.style.minHeight = heightStr
            comment.style.maxHeight = heightStr

            const sectionY = getCoords(section).top

            if (offSet < sectionY) {
              const margin = Math.round(sectionY - offSet - 5) // -5px since we put a padding top of 5px on the comment
              offSet += margin
              comment.style.marginTop = `${margin}px`
            } else {
              comment.style.marginTop = '0px'
            }
            offSet += height
          }
        })
      }
    }

    return handler
  }, [idPrefix, padderRef, template.templateID])
}


/** ******************************************************************
 *   Padder wrapper + IntersectionObserver for scrolling behavior
 ****************************************************************** */
export const IntersectionObserverContext = React.createContext<IntersectionObserver>(new IntersectionObserver(() => null))
export const CommentsPadderWrapper: React.FC = ({ children }) => {
  const padderRef = useRef<HTMLDivElement>(null)
  const padComments = usePadder(padderRef)
  const timeoutRef = useRef<NodeJS.Timeout>()
  const padTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(padComments, 1000)
  }, [padComments])


  const [observer, setObserver] = useState<IntersectionObserver>(new IntersectionObserver(() => null))

  useLayoutEffect(() => {
    const top = '10%'
    const bottom = '0%'
    setObserver(new IntersectionObserver(
      entries => entries.forEach(entry => {
        const elem = (entry.target as any).comment
        if (entry.isIntersecting) {
          elem && elem.classList.add('visible')
          entry.target.classList.add('visible')
        } else {
          elem && elem.classList.remove('visible')
          entry.target.classList.remove('visible')
        }
      }),
      {
        rootMargin: `-${top} 0px -${bottom} 0px`,
        threshold: 0
      }
    ))
  }, [])

  //  Triggering the padding of the comments
  useLayoutEffect(() => {
    const mutObserver = new MutationObserver(padTimeout)
    const szeObserver = new ResizeObserver(padTimeout)

    const target = document.querySelector('.contractRenderingWrapper')
    if (target) {
      mutObserver.observe(target, { childList: true })
    }
    const target2 = document.querySelector('.contractEditorComponent')
    if (target2) {
      mutObserver.observe(target2, { childList: true })
      szeObserver.observe(target2)
    }

    return () => {
      mutObserver.disconnect()
      szeObserver.disconnect()
    }
  }, [padTimeout])

  return (
    <div>
      <IntersectionObserverContext.Provider value={observer}>
        <div ref={padderRef} onClick={evt => evt.stopPropagation()}>
          {children}
        </div>
      </IntersectionObserverContext.Provider>
    </div>
  )
}

class CommentNode {
  part: any

  _comment: any = undefined

  _comments: any = undefined

  constructor (part?: any) {
    this.part = part
  }

  get comment () {
    if (this._comment || !this._comments) {
      return this._comment
    }
    const child = this._comments[Object.keys(this._comments).sort()[0]]
    if (child) {
      const comment = { ...child.comment }
      const childID = comment.childID.split(CHILDID_CHAR_SEPARATOR)
      const parentID = childID.pop()
      comment.childID = childID.join(CHILDID_CHAR_SEPARATOR)
      comment.realDate = comment.date
      comment.date = parentID
      return comment
    }
    return undefined
  }

  get comments () {
    if (this.part || this._comment || !this._comments) {
      return this._comments
    }
    const tmp = { ...this._comments }
    delete tmp[Object.keys(this._comments).sort()[0]]
    return tmp
  }

  addChild (dateStr: string, child?: any): CommentNode {
    if (!this._comments) {
      this._comments = {}
    }
    if (!this._comments[dateStr]) {
      this._comments[dateStr] = new CommentNode()
    }
    if (child) {
      this._comments[dateStr]._comment = child
    }
    return this._comments[dateStr]
  }
}


/** **************************************************************
 *           Hook to generate the comment tree
 ************************************************************** */
export const useConstructCommentTrees = (reelComments: StoreComment[], isGlobalDocument?: boolean, external?: boolean): [CommentTree, CommentTree, number, string[]] => {
  const { template: { header, sections, footer, annexes, ...template }, readOnly: isTemplateReadOnly } = useContext<any>(TemplateContext)
  const { contract } = useContext<any>(ContractContext)
  const sectionsData = useContext(SectionsContext)
  const fieldsResponse = contract && contract.fieldsResponse
  const fields = template && template.fields

  return useMemo(() => {
    let commentsArr = [...reelComments]
    let unPublishedChanges = 0
    commentsArr.sort((valA, valB) => (valA.date < valB.date ? -1 : 1))

    const activeCommentTree: CommentTree = {}
    const historyCommentTree: CommentTree = {}
    const partIDsOrder: string[] = []

    const processPart = part => {
      if (part.sectionID && typeof part.sectionID === 'string') {
        partIDsOrder.push(part.sectionID.replace(/template-.+?-/, ''))
      }

      const section: Section = part.sectionID === 'DOC'
        ? { sectionID: part.sectionID } as any
        : sectionsData.get(part.sectionID)

      if (section) {
        const activeCommentTreeNode = new CommentNode({
          ...section,
          ...part
        })
        const historyCommentTreeNode = new CommentNode({
          ...section,
          ...part
        })

        const currentSectionID = part.sectionID.replace(/template-.+?-/, '')
        activeCommentTree[currentSectionID] = activeCommentTreeNode
        historyCommentTree[currentSectionID] = historyCommentTreeNode

        const partDate = activeCommentTreeNode.part.dateUpdated && new Date(activeCommentTreeNode.part.dateUpdated)

        commentsArr = commentsArr.filter(comm_ => {
          //  Filter out comment not attached to a section
          if (!comm_.childID) {
            return false
          }

          const dateStr = comm_.date
          const comm: Comment = {
            ...comm_, external: !external && comm_.organisationID === '__EVERYONE__'
          }
          const childID = comm.childID.replace(/template-.+?-/, '')

          if (childID.startsWith(currentSectionID)) {
            const split = childID.split(CHILDID_CHAR_SEPARATOR)
            split.shift() //  Remove sectionID from the dates

            let parent = activeCommentTreeNode
            const task = comm as TaskComment
            if (task.assignedTo || (comm as FeedbackComment).isFeedback) {
              if (task.done) {
                parent = historyCommentTreeNode
              }
            } else if (partDate && comm.date < partDate) {
              parent = historyCommentTreeNode
              ;(comm as any).inHistory = true
            } else {
              const redline = (comm as YjsDeltaComment)
              if (redline.delta) {
                if (
                  redline.externalRate
                  || (redline.fromExternal && redline.rate)
                  || redline.rate === 'rejected'
                  || redline.sectionVersion !== (section.version || 1)
                ) {
                  parent = historyCommentTreeNode
                  redline.inHistory = true
                }
              }
            }

            //  Increment unPublishedChanges counter
            if (contract.lastPublishDate && comm.organisationID === '__EVERYONE__' && comm.lastUpdated > contract.lastPublishDate
              && !(comm as any).inHistory && !(comm as any).fromExternal
            ) {
              unPublishedChanges += 1
            }

            let tmp
            while (tmp = split.shift()) { // eslint-disable-line no-cond-assign
              parent = parent.addChild(tmp)
            }

            parent.addChild(dateStr, comm)
            return false
          }
          return true
        })
      }

      if (Array.isArray(part.subSections)) {
        part.subSections.forEach(processPart)
      }
    }

    //  Process template
    if (isGlobalDocument) {
      processPart({ sectionID: 'DOC', name: <Trans>Document</Trans> })
    } else {
      partIDsOrder.push('title')
      header && processPart({ sectionID: header, name: <Trans>First page</Trans> })
      Array.isArray(sections) && sections.forEach(processPart)
      footer && processPart({ sectionID: footer, name: <Trans>Last page</Trans> })
      partIDsOrder.push('signatures')
      partIDsOrder.push('annexeTitle')
      Array.isArray(annexes) && annexes.forEach(processPart)
    }

    return [activeCommentTree, historyCommentTree, unPublishedChanges, partIDsOrder]
  }, [reelComments, isGlobalDocument, contract, sectionsData, isTemplateReadOnly, fields, fieldsResponse, external, sections, header, footer, annexes])
}

const emptyArray = []
export const useGetComments = (instanceID: string, instanceType: string): [StoreComment[], boolean, boolean] => {
  /**
   * Load comments if user has the permission
   */
  const dispatch = useDispatch()
  const instanceComments = useSelector(state => state.organisation.instanceComments[instanceID] || emptyArray)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const prevID = useRef<string>()

  const fetchComments = useCallback(async (_instanceID, _instanceType) => {
    try {
      setError(false)
      setLoading(true)
      await dispatch(loadComments(_instanceID, _instanceType))
      setLoading(false)
    } catch (err) {
      console.error('Failed to load comments', err)
      setError(true)
      setLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    if (prevID.current !== instanceID) {
      prevID.current = instanceID
      fetchComments(instanceID, instanceType)
    }
  }, [fetchComments, instanceID, instanceType])

  return [instanceComments, loading, error]
}


/** **************************************************************
 *                Comment toolbox component
 ************************************************************** */

const CommentsToolbox: React.FC<CommentsToolboxProps> = ({
  instanceType,
  instanceID,
  switchToolbox,
  currentBoxRef
}) => {
  const [pageSize] = useQueryParam('pageSize', withDefault(NumberParam, 0))
  const [activePage] = useQueryParam('activePage', withDefault(NumberParam, 1))
  const { contract } = useContext<any>(ContractContext)
  /**
   * Formatting the data
   */
  const [reelComments, loading, error] = useGetComments(instanceID, instanceType)
  const isGlobalDocument = (
    contract && ((contract.signaturesHolder && contract.signaturesHolder.pdf) || contract.fileKey || contract.pdfSigningFile)
  )
  const [activePartsComments, historyPartsComments, unPublishedChanges, partIDsOrder] = useConstructCommentTrees(reelComments, isGlobalDocument)

  // const [side, setSide] = useState('internal')
  const side = 'internal'

  const chunk = useMemo(
    () => partIDsOrder.slice(pageSize * (activePage - 1), pageSize * activePage),
    [activePage, pageSize, partIDsOrder]
  )

  /**
   * Return loading and error views
   */
  if (error) {
    return <Alert message={<Trans>Failed to load your comments</Trans>} showIcon type='error' />
  }

  if (isGlobalDocument) {
    return (historyPartsComments.DOC && activePartsComments.DOC && (
      <PartComment
        canAlwaysComment
        currentBoxRef={currentBoxRef}
        historyNode={historyPartsComments.DOC}
        instanceID={instanceID}
        instanceType={instanceType}
        node={activePartsComments.DOC}
        side={side}
        switchToolbox={switchToolbox}
      />
    )) || null
  }

  /**
   * Rendering the view
   */
  return (
    <LocalLoadingAlert description={<Trans>Loading your comments</Trans>} lightTheme loading={loading}>
      {unPublishedChanges > 0 && <Portal cssSelector='#publishButtonCounter'><span>({unPublishedChanges}) </span></Portal>}
      <CommentsPadderWrapper>
        {chunk.map(sectionID => (activePartsComments[sectionID] && (
          <PartComment
            key={sectionID}
            // childID={sectionID}
            currentBoxRef={currentBoxRef}
            historyNode={historyPartsComments[sectionID]}
            instanceID={instanceID}
            instanceType={instanceType}
            node={activePartsComments[sectionID]}
            side={side}
            switchToolbox={switchToolbox}
          />
        )))}
      </CommentsPadderWrapper>
    </LocalLoadingAlert>
  )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (instanceType: string, instanceID: string): any => ({
  key: 'comments',
  className: 'commentsToolbox',
  text: <Trans>Comments</Trans>,
  fullSize: true,
  helpText: <Trans>Comment and collaborate with other members on the document.</Trans>,
  feature: 'comments',
  icon: <MessageOutlined />,
  // eslint-disable-next-line react/display-name
  Component: props => <CommentsToolbox {...props} instanceID={instanceID} instanceType={instanceType} />,
  // eslint-disable-next-line react/display-name
  PhoneComponent: props => <PhoneCommentsToolbox {...props} instanceID={instanceID} instanceType={instanceType} />
})
