import { useRef, useState } from 'react'

export const arraysAreSame = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) { return false }
  if (arr1.length !== arr2.length) { return false }

  return !arr1.some((val, index) => val !== arr2[index])
}
/**
 *
 * @param {function} loaderFunction
 * @param {array} args
 * @param {*} needToLoad
 */
const useLoadData = (loaderFunction, args, needToLoad) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [state, setState] = useState()

  // creating a reference
  const prevArgs = useRef()
  const prevNeedLoad = useRef()

  // executing the loader function with the arguments
  const loadData = async () => {
    try {
      const data = await loaderFunction(...args)
      setError(false)
      setState(data)
    } catch (err) {
      setError(err)
      setState(undefined)
      console.error('Got error while loading', err)
    }
    setLoading(false)
  }

  if (!error && !loading && (needToLoad == null ? !arraysAreSame(args, prevArgs.current) : (needToLoad && prevNeedLoad.current !== needToLoad))) {
    prevArgs.current = [...args]
    setLoading(true)
    loadData()
    return [true, null]
  }

  prevNeedLoad.current = needToLoad

  return [loading, error, state]
}

export default useLoadData
