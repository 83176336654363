import React from 'react'

import { FormEditor } from '@top-legal/editor'

import { AssistantInputProps } from './Types'
import InputQuestion from './inputElements/InputQuestion'
import FieldExplanation from './inputElements/FieldExplanation'
import useFieldSaving from './inputElements/useFieldSaving'

const MultiTextFieldInput: React.FC<AssistantInputProps> = props => (
  <>
    <InputQuestion field={props.field} />
    <FormEditor {...useFieldSaving(props)} />
    <FieldExplanation field={props.field} />
  </>
)

export default MultiTextFieldInput
