import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Button, Input, Tooltip } from 'antd'
import { DeleteOutlined, ReadOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'

import { useFlags } from 'launchdarkly-react-client-sdk'

import { TemplateContext } from '../../Contexts'
import { ContractEditorSectionProps, SectionProps } from './Types'
import SectionMetadataDisplay from './SectionMetadataDisplay'
import { ConfirmButton } from '../../../../SharedComponents/FormComponents'
import { useClausesStore } from './Clause'
import TemplateSectionMetadataModal from './SectionMetadataModal'


export interface SectionTitleProps extends SectionProps, Pick<ContractEditorSectionProps, 'deleteSection' | 'title' | 'titlePrefix'> {
  path: number[]
}

const SectionTitle: React.FC<SectionTitleProps & { isEmptyContent?: boolean }> = ({
  path,
  section,
  updateSection,
  titlePrefix,
  title,
  deleteSection,
  isEmptyContent,
  children
}) => {
  const { readOnly, sectionMapping, template } = useContext<any>(TemplateContext)
  const depth = Math.min(Math.max(1, path.length), 4)
  const [name, setName] = useState<string>('')
  useEffect(() => setName(section.name), [section.name])

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

  const featureEnabled = useFlags().clauseLibrary && window.parent !== window


  /** ******************************************************************
   *                         Saving Part
   ****************************************************************** */
  const displayPath = path.map(index => index + 1).join('.')
  const currNameRef = useRef(name)
  currNameRef.current = name
  const saveTitle = useCallback(() => updateSection?.({ name: currNameRef.current }), [updateSection])

  //  Our section might be using a clause
  const { clauseID } = (section as any).toJSON?.() ?? section as any
  const { clause, loading } = useClausesStore(state => ({ clause: state.clauses[clauseID], loading: state.loading }))

  return (
    <div className='titleWrapper'>
      {children}
      <div className='sectionTitle'>
        {(title && <span className={`h${depth}`}>{title}</span>) || (
          <>
            <span className={`h${depth}`}>
              {readOnly ? sectionMapping[section.sectionID.split('-').pop() as string] : `${titlePrefix}${displayPath}.`} {clause?.title || ''}
              {clauseID && loading && 'Loading clause...'}
            </span>
            {!(readOnly || clauseID) && (
              <Input.TextArea
                autoSize
                className={`titleInput h${depth}`}
                onBlur={saveTitle}
                onChange={evt => setName(evt.target.value)}
                onKeyDown={evt => {
                  if (evt.key === 'Enter') {
                    evt.preventDefault()
                    evt.stopPropagation()
                    saveTitle()
                  }
                }}
                value={name}
              />
            )}
          </>
        )}
      </div>
      {!readOnly && (
        <>
          <SectionMetadataDisplay section={section} updateSection={updateSection} />
          <div className='sectionTitleButtonsWrapper'>
            {featureEnabled && (
              <Tooltip title={isEmptyContent ? 'Add clause from library' : 'Clause can only be inserted in empty section'}>
                <span>
                  <Button
                    className='sectionSelectClause noBorder'
                    disabled={!isEmptyContent}
                    ghost
                    icon={<ReadOutlined />}
                    loading={deleteLoading}
                    onClick={() => {
                    // eslint-disable-next-line no-shadow
                      const { templateID, name, category, lang, description } = template
                      //  We call dealroom to select a clause, the response is managed in TemplateEditing
                      window.parent.postMessage({
                        action: 'selectClause',
                        payload: {
                          template: {
                            templateID, name, category, lang, description
                          },
                          sectionID: section.sectionID
                        }
                      }, process.env.DEAL_DOMAIN!)
                    }}
                    shape='circle'
                    size='small'
                  />
                </span>
              </Tooltip>
            )}
            <TemplateSectionMetadataModal section={section} updateSection={updateSection} />
            {deleteSection && (
              <Tooltip title={<Trans>Delete section</Trans>}>
                <ConfirmButton
                  className='sectionDeleteButton noBorder'
                  confirmMessage={<Trans>Are you sure you want to delete “{displayPath}”?</Trans>}
                  danger
                  ghost
                  icon={<DeleteOutlined />}
                  loading={deleteLoading}
                  onClick={async () => {
                    try {
                      setDeleteLoading(true)
                      deleteSection()
                    } catch { }
                    setDeleteLoading(false)
                  }}
                  placement='topRight'
                  shape='circle'
                  size='small'
                />
              </Tooltip>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default SectionTitle
