import React, { useContext, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { Button } from 'antd'

import { DeviceContext } from '../../../GlobalContext'
import NavSidebar, { NavSidebarProps } from './NavSidebar/NavSidebar'

import tlSvg from '../../../img/top-legal-t.svg'
import './MainContainerStyles.scss'
import { ExternalContext } from '../../ExternalViews/ExternalContext'

export interface MainContainerProps extends NavSidebarProps {
  topbarContent?: React.ReactElement
  mainContentClass?: string
}

/**
 * Main container element that take care of the main layouting of the app
 * A fixed topbar for the title and actions
 * A fixed sidebar for the navigation
 * A mainContent scrollable for the whole page content
 */
const MainContainer: React.FC<MainContainerProps> = ({ topbarContent = null, publicMode, mainContentClass = '', children, ...navProps }) => {
  const isPhone = useContext(DeviceContext) === 'phone'
  const container = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>
  const topbar = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>
  const content = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const location = useLocation()
  const isEmbed = window.parent !== window
  const hideTopbar = location.search && location.search.includes('hideTopbar=')
  const { organisationID, hideDealroom } = useSelector(state => state.organisation?.selectedOrganisation) || {}
  const { tokenData } = useContext(ExternalContext) || {}

  /**
   * Returning the main layout structure
   */
  return (
    <div ref={container} className={`mainContainer ${isEmbed ? 'embed' : ''}`} onClick={isPhone ? () => setSidebarOpen(false) : undefined}>
      <div ref={topbar} className='topbar' style={{ display: hideTopbar ? 'none' : undefined }}>
        {topbarContent}
      </div>
      {!isEmbed && (
        <div className={`navSidebar ${sidebarOpen ? 'open' : ''}`}>
          <Button
            className='phoneSidebarSwitcher'
            icon={<img alt='top.legal t' src={tlSvg} />}
            onClick={evt => {
              evt.stopPropagation()
              setSidebarOpen(!sidebarOpen)
            }}
          />
          <NavSidebar {...navProps} publicMode={publicMode} />
        </div>
      )}
      <div ref={content} className='mainContentWrapper'>
        <div className={`mainContent ${mainContentClass}`}>{children}</div>
      </div>
      {/* An organisation, that has not the hidden flag, that we are not in iframe and not external */}
      {organisationID && !hideDealroom && window.parent === window && !tokenData && (
        <div
          style={{
            boxShadow: '0 0.08rem 0.4rem rgba(0, 0, 0, 0.3)',
            zIndex: 20,
            background: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0.2rem'
          }}
        >
          <p
            style={{
              margin: 0, fontSize: '0.8rem', lineHeight: '0.9rem', fontWeight: 600
            }}
          >
            {!isPhone && 'Try top.legal 2.0 and discover the power of the new dealroom negotiation and signing.'}
          </p>
          <Button href={process.env.DEAL_DOMAIN} size='small' style={{ padding: '0.2rem 1rem !important', marginLeft: '0.5rem' }} type='primary'>Switch to top.legal 2.0</Button>
        </div>
      )}
      {hideTopbar && (
        <style>{`
          body {
            --main-container-top: 0 !important;
            --main-container-height: 100vh !important;
          }
        `}
        </style>
      )}
    </div>
  )
}

export const StickyMainContent: React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  className = '',
  children,
  ...props
}) => (
  <div {...props} className={`stickyMainContent ${className}`}>
    {children}
  </div>
)

export default MainContainer
