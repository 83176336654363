/* eslint-disable */
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withI18n } from '@lingui/react'

class ComponentWrapperFactory {
  constructor (component) {
    this.component = component
    this.wrappedContent = {}
  }

  build () {
    const Component = this.component
    let Wrapped = this.wrappedContent.forwardRef
      ? Component
      : ({ forwardRef, ...parentProps }) => <Component {...parentProps} ref={forwardRef} />

    //  Wrap it
    if (this.wrappedContent.i18n) {
      Wrapped = withI18n()(Wrapped)
    }
    if (this.wrappedContent.router) {
      Wrapped = withRouter(Wrapped)
    }

    //  Use forward ref
    return React.forwardRef((props, ref) => {
      return <Wrapped {...props} forwardRef={ref} />
    })
  }

  withRedux (mapStateToProps, mapDispatchToProps) {
    //  Debuging who call redux
    // this.component = connect(mapStateToProps, (...args) => {
    //   const res = mapDispatchToProps(...args)
    //   const newDispatch = {}
    //   Object.keys(res).forEach(key => {
    //     newDispatch[key] = (...args) => {
    //       console.info('Dispatch', key, 'queried')
    //       console.trace()
    //       return res[key](...args)
    //     }
    //   })
    //   return newDispatch
    // }, undefined, { forwardRef: true })(this.component)

    this.component = connect(mapStateToProps, mapDispatchToProps, undefined, { forwardRef: true })(this.component)
    return this
  }

  withRouter () {
    this.wrappedContent.router = true
    return this
  }

  withI18n () {
    this.wrappedContent.i18n = true
    return this
  }

  withForwardRef () {
    this.wrappedContent.forwardRef = true
    return this
  }
}

export default component => new ComponentWrapperFactory(component)
