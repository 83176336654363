/* eslint-disable global-require */
import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import Storage from '@aws-amplify/storage'
import {getStorage} from "./awsCognitoClient";

//  Webpack does not resolve dynamic import with string concatenation
// eslint-disable-next-line import/no-mutable-exports
let awsConfig: any
if (process.env.REACT_APP_ENV === 'dev') { awsConfig = require('./aws-config-dev') }
if (process.env.REACT_APP_ENV === 'beta') { awsConfig = require('./aws-config-beta') }
if (process.env.REACT_APP_ENV === 'prod') { awsConfig = require('./aws-config-prod') }
if (process.env.REACT_APP_ENV === 'zurich') { awsConfig = require('./aws-config-zurich') }

let redirectUri = `${window.location.origin}/${(/^\/(en|de)/.exec(window.location.pathname) || [])[1] || 'en'}/login/`
export const setOAuthRedirectUri = (uri: string): void => {
  redirectUri = uri
}
const oauth: any = { ...awsConfig.Auth.oauth }

Object.defineProperties(oauth, {
  redirectSignIn: {
    configurable: true,
    enumerable: true,
    get: () => redirectUri,
    set: () => undefined
  },
  redirectSignOut: {
    configurable: true,
    enumerable: true,
    get: () => redirectUri,
    set: () => undefined
  }
})

awsConfig.Auth.oauth = oauth
awsConfig.Auth.storage = getStorage() //  Bind cookie storage to amplify
Amplify.configure(awsConfig)
Auth.configure(awsConfig.Auth) // Stupid? yes but needed to work...
Storage.configure(awsConfig.Storage) // Stupid? yes but needed to work...

export default awsConfig
