import React from 'react'

import { createTemplateEntityDnD } from '@top-legal/editor'

export interface EntityTitleProps {
  entityKey: string
  entityType: 'inputField' | 'conditionalText' | 'sectionReference'
  version?: string | number

  entityTitle: string
  onClick?: () => void

  className?: string
  noDrag?: boolean
  extraContent?: React.ReactNode
}

const EntityTitle: React.FC<EntityTitleProps> = ({
  entityKey,
  entityType,
  version,
  entityTitle,
  onClick,
  className,
  noDrag = false,
  children,
  extraContent = null
}) => (
  <span
    className={`draggableInputField ${className || ''}`}
    data-key={entityKey}
    draggable={!noDrag}
    onClick={onClick}
    onDragStart={noDrag ? undefined : event => {
      event.dataTransfer.setData('text/plain', entityTitle)

      //  Build a slate fragment
      createTemplateEntityDnD(event.dataTransfer, entityType, entityKey, version)
    }}
    title={entityTitle}
  >
    {
      children || (
        <>
          <span className={entityType} data-key={entityKey} data-version={version}>{entityTitle}</span>
          {extraContent}
        </>
      )
    }
  </span>
)

export default EntityTitle
