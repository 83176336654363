import React, { useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { FileDoneOutlined } from '@ant-design/icons'

import { Template, useList, useTemplateListBaseQuery } from '@top-legal/datastore'

import { Filter, UseListing } from '../Types'
import ListRow from '../../SharedComponents/ListingComponents/ListRow'
import TemplateRowComponents from './TemplateRowComponents'
import TemplateActions from './TemplateActions'
import TemplateContainerWrapper from './TemplateContainerWrapper'
import { emptyArray, hasKeyWordsFactory, useTagsFilter } from '../SearchHelpers'

const useTemplateListing: UseListing<Template> = () => {
  //  Templates data
  const getTemplates = useTemplateListBaseQuery()
  const [templates = emptyArray, loadT] = useList(useMemo(() => getTemplates.sort({ dateUpdated: 'desc' }), [getTemplates]))


  /** *******************************************************************
   *                      Filters
   ******************************************************************* */
  const tagFilter = useTagsFilter<Template>(templates)
  const filters = useMemo<Filter<Template>[]>(() => [tagFilter], [tagFilter])


  /** *******************************************************************
   *                      Return config
   ******************************************************************* */
  return useMemo<ReturnType<UseListing<Template>>>(() => ({
    key: 'templates',
    title: <Trans>Playbooks</Trans>,
    icon: <FileDoneOutlined />,
    instances: templates,
    stringSearch: (template, split) => {
      const hasKeyWords = hasKeyWordsFactory([...split])
      return hasKeyWords(template.name) || hasKeyWords(template.description)
    },
    filters,
    rowKey: template => template.templateID,
    // eslint-disable-next-line react/display-name
    ListRow: props => <ListRow {...props} {...TemplateRowComponents} />,
    ContainerWrapper: TemplateContainerWrapper,
    Actions: TemplateActions,
    loading: loadT
  }), [templates, filters, loadT])
}

export default useTemplateListing

