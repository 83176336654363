import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { CloseOutlined, InfoOutlined } from '@ant-design/icons'
import { Button, Progress, Tooltip } from 'antd'

import { DeviceContext } from '../../../../GlobalContext'

//  Components
import { StickyMainContent } from '../../../Layouts/MainLayout/MainContainer'
import LayoutSidebar from '../../../Layouts/LayoutSidebar'
import PhoneSidebar from './PhoneSidebar'
import { noop } from '../../../Defaults'

//  Styles
import './Sidebar.scss'


export const SidebarToolboxes = React.createContext()
/**
 * Sidebar toolbox content
 */
const SidebarToolboxContent = ({ Component, text, helpText, actionText, CustomButton, ...props }) => {
  const onClickRef = useRef()
  let ActionButton = () => null
  if (CustomButton) {
    ActionButton = CustomButton
  } else if (actionText) {
    // eslint-disable-next-line react/display-name,no-unused-vars
    ActionButton = ({ onClickRef: _, ...buttonProps }) => <Button {...buttonProps}>{actionText}</Button>
  }

  // returns the sidebar box that is displayed on the playbook editor
  return (
    <>
      <div className='toolboxTile'>
        <div className='titleBar'>
          <h1 className='sidebarHeader'>{text}</h1>
          {props.toolboxKey === 'chat' && <div id='chatAvatars' style={{ flexGrow: 3 }} />}
          {helpText && (
            <Tooltip placement='topLeft' title={<p className='toolBox-explanation'>{helpText}</p>}>
              <Button ghost icon={<InfoOutlined />} size='small' />
            </Tooltip>
          )}
        </div>
        <ActionButton className='createNewButton' onClick={(...args) => (onClickRef.current || noop)(...args)} onClickRef={onClickRef} type='default' />
      </div>
      <div className='toolboxContent'>
        <Component {...props} onClickRef={onClickRef} />
      </div>
    </>
  )
}
/**
 * Sidebar toolbox
 */
const SidebarToolbox = ({ className, ...props }) => (
  <div className={className}>
    <SidebarToolboxContent {...props} />
  </div>
)
/**
 * Sidebar
 */
const Sidebar = ({ stateRef, suffix = '' }) => {
  const { toolboxes = [], defaultKey, defaultOpened, progressPercent, progressText } = useContext(SidebarToolboxes) || {}
  const isPhone = useContext(DeviceContext) === 'phone'
  const [sidebar = 'none', setSidebar] = useQueryParam('sidebar', StringParam)
  const [hideSwitches] = useQueryParam('caOnly', StringParam)
  const currentBoxRef = useRef(sidebar)
  currentBoxRef.current = sidebar

  /**
   * Switch toolbox
   */
  const switchToolbox = useCallback(
    key => setSidebar(old => {
      //  Close the sidebar
      if (key === 'none' || key === old) {
        return undefined
      }
      return key
    }),
    [setSidebar]
  )

  /**
   * Take care of defaultKey & invalid cases
   */
  useEffect(() => {
    const currentSidebarExist = toolboxes.find(({ key }) => key === sidebar)
    if (defaultOpened && defaultKey && !currentSidebarExist && defaultKey !== sidebar) {
      switchToolbox((toolboxes.find(({ key }) => key === defaultKey) || toolboxes[0] || {}).key)
    }
  }, [defaultKey, defaultOpened, sidebar, switchToolbox, toolboxes])

  if (stateRef) {
    stateRef.current = { openedToolbox: sidebar, switchToolbox }
  }

  /**
   * Rendering
   */
  return (
    <LayoutSidebar className='toolboxSidebar' isOpen={!!sidebar} position='right' sidebarName={`multiBoxSidebar${suffix}`}>
      <div className={`toolboxs ${progressPercent != null ? 'withProgress' : ''}`}>
        {progressPercent != null && (
          <div className='progressStatusWrapper'>
            <div className='progressStatus'>
              <Progress percent={progressPercent} showInfo={false} size='small' />
              <p>{progressText || <span>{progressPercent}%</span>}</p>
            </div>
          </div>
        )}
        {toolboxes.map(({ key, className, fullSize, Text, text, ...props }) => (
          <SidebarToolbox
            {...props}
            key={key}
            className={`${key === sidebar ? 'toolBoxOpen' : 'toolBoxClose'} ${className} ${fullSize ? '' : 'stickyMainContent'}`}
            currentBoxRef={currentBoxRef}
            switchToolbox={switchToolbox}
            text={Text ? <Text /> : text}
            toolboxKey={key}
          />
        ))}
      </div>
      {!hideSwitches && (
        <div className='toolbox-icons-wrapper'>
          <StickyMainContent className='toolbox-icons'>
            <Button
              className='closeButton' ghost icon={<CloseOutlined />} onClick={() => switchToolbox(sidebar)}
              type='default'
            />
            {toolboxes.map(({ key, icon, Text, text }) => (
              <Button
                key={key}
                className={`sidebarToolboxSwitch${key} ${sidebar === key ? 'toolbox-active' : ''}`}
                ghost
                icon={icon}
                onClick={() => switchToolbox(key)}
                type='default'
              >
                <span>{Text ? <Text /> : text}</span>
                {!isPhone && key === 'chat' && <div id='notificationDot' />}
              </Button>
            ))}
          </StickyMainContent>
        </div>
      )}
    </LayoutSidebar>
  )
}
const SidebarWrapper = props => {
  const isPhone = useContext(DeviceContext) === 'phone'
  const Component = useMemo(() => (isPhone ? PhoneSidebar : Sidebar), [isPhone])
  return <Component {...props} />
}
export default SidebarWrapper
