import React, { useMemo } from 'react'

//  Editor library
import { RenderOnlyEditor, isEmpty } from '@top-legal/editor'

import { MediaCard } from '../Media/MediaLibrary'

interface PartExplanationProps {
  media?: any
  text?: any
  isInputField?: boolean
  className?: string
}

/**
 * component produces the explanation for each paragraph
 */
const PartExplanation: React.FC<PartExplanationProps> = ({
  media, text, isInputField, className = '', children
}) => {
  const hasText = useMemo(() => {
    if (Array.isArray(text)) {
      return !isEmpty(text)
    }
    return false
  }, [text])

  return ((hasText || media) ? (
    <div className={`${isInputField ? 'inputFieldExplanation' : 'partExplanation'} ${media ? 'withVideo' : ''} ${className}`}>
      {!isInputField && <div className='switcher' />}
      <div className='content'>
        {children && <h3 className='tipHeader'>{children}</h3>}
        {media && (
          <div className='videoWrapper'><MediaCard media={media} viewerOnly /></div>
        )}
        {hasText && (
          <div className='explanationWrapper'>
            <RenderOnlyEditor nodes={text} />
          </div>
        )}
      </div>
    </div>
  ) : null)
}

export default PartExplanation
