/* eslint-disable react/display-name,max-len */
import React, { useContext, useLayoutEffect, useMemo, useState } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router'
import { useQueryParam, StringParam } from 'use-query-params'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from '@lingui/macro'
import {
  ApartmentOutlined,
  CopyOutlined,
  DeploymentUnitOutlined,
  FileOutlined,
  LeftOutlined,
  MenuOutlined,
  PlusOutlined,
  UserOutlined
} from '@ant-design/icons'

import { Alert, Button, Drawer, Radio, Tag, Tooltip } from 'antd'
import moment from 'moment'
import { NavLink } from 'react-router-dom'

import TemplateCard from './TemplateCard'
import RestService from '../../RestService'
import MainContainer from '../Layouts/MainLayout/MainContainer'
import ButtonLink from '../SharedComponents/ButtonLink'
import { templateCategories } from './TemplateHelperFunctions'
import UserAvatarList from '../Organisations/UserRoleDisplay/UserAvatarList'
import { getCommunityTemplates } from './redux/TemplateActions'
import { createColumn, PaginatedDataTable } from '../SharedComponents/DataTable'
import { LocalLoadingAlert } from '../Alert/LoadingAlert'

import './styles/PublicTemplatesListingStyles.scss'
import { withUserData } from '../Layouts/AuthenticatedPage'
import { useDuplicatePlaybook } from '../Contract/Utils'
import { DeviceContext } from '../../GlobalContext'

const publicListingColumns = readOnly => [
  createColumn({
    columnKey: 'name',
    columnName: <Trans>Contract name</Trans>,
    className: 'itemName',
    withSort: true
  }),
  createColumn({
    columnKey: 'dateUpdated',
    columnName: <Trans>Last update</Trans>,
    withDate: true,
    withSort: true
  }),
  createColumn({
    columnKey: 'category',
    columnName: <Trans>Category</Trans>,
    withTags: templateCategories
  }),
  createColumn({
    columnKey: 'lang',
    columnName: <Trans>Lang</Trans>,
    withTags: {
      en: { color: 'geekblue', text: 'EN' },
      de: { color: 'geekblue', text: 'DE' }
    }
  }),
  createColumn({
    columnKey: 'tags',
    columnName: <Trans>Labels</Trans>,
    getData: record => record.tags || [],
    withLabels: readOnly ? 'template' : null,
    readOnly
  }),
  createColumn({
    columnKey: 'users',
    columnName: <Trans>Users</Trans>,
    columnIcon: <UserOutlined />,
    className: 'contract-users',
    render: (_, record) => <UserAvatarList size='1.5rem' users={((Array.isArray(record.users) && record.users) || [])} />
  })
]

const CommunityPlaybooks = ({ hidden }) => {
  const { url } = useRouteMatch()
  const dispatch = useDispatch()
  const communityPlaybooks = useSelector(state => state.template.communityTemplates || [])
  const [loading, setLoading] = useState(false)
  useLayoutEffect(() => {
    setLoading(true)
    dispatch(getCommunityTemplates()).finally(() => setLoading(false))
  }, [dispatch])

  let desc = <Trans>Loading community playbooks</Trans>
  //  TODO: Move to whitelabel version instead of HardCoded one for transferinitiative
  if (window.IS_TRANSFERINITIATIVE) {
    desc = <Trans>Loading Transferinitiative RLP playbooks</Trans>
  }

  return (
    <div className='communityList' style={hidden ? { display: 'none' } : undefined}>
      <LocalLoadingAlert description={desc} lightTheme loading={loading}>
        {
          communityPlaybooks.map(({ organisationID, name, templates, isActive }) => (
            <div key={organisationID} className='communityElements dataTable2'>
              <div className={`title ${isActive ? 'active' : ''}`}><h2>{name}</h2></div>
              <div className='playbooks dataTable-cards-wrapper'>
                {
                  templates.map(template => (
                    <NavLink key={template.templateID} to={url.replace('top-legal-templates', `contracts/${template.templateID}/new`)}>
                      <TemplateCard record={template}>
                        {[
                          template.name,
                          (() => {
                            const date = new Date(template.dateUpdated)
                            if (Number.isNaN(date.valueOf())) {
                              return '-'
                            }
                            return moment(date).fromNow()
                          })(),
                          (() => {
                            const tag = templateCategories[template.category]
                            return tag ? <Tag color={tag.color}>{tag.text}</Tag> : ''
                          })(),
                          typeof template.lang === 'string' ? <Tag color='#3dbd7d'>{template.lang.toUpperCase()}</Tag> : '',
                          [],
                          <UserAvatarList key='users' size={'small'} users={((Array.isArray(template.users) && template.users) || [])} />
                        ]}
                      </TemplateCard>
                    </NavLink>
                  ))
                }
              </div>
            </div>
          ))
        }
      </LocalLoadingAlert>
    </div>
  )
}

const PublicTemplatesListing = ({
  embed = false,
  firstPhrase = <Trans>top.legal contract playbooks can be used free of charge with the Free Account. You can sign up now.</Trans>,
  readOnly = true,
  publicMode = false
}) => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const { lang } = useParams()
  const [disclaimerClosed, setDisclaimerClosed] = useState(false)
  const [list = window.IS_TRANSFERINITIATIVE ? 'community' : 'top-legal', setList] = useQueryParam('list', StringParam)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const isPhone = useContext(DeviceContext) === 'phone'

  const duplicatePlaybook = useDuplicatePlaybook()

  const buttonRadio = (
    <Radio.Group onChange={evt => setList(evt.target.value)} value={list}>
      {window.IS_TRANSFERINITIATIVE ? (
        <>
          <Radio.Button type='primary' value='community'>
            <DeploymentUnitOutlined /> <span><Trans>Transferinitiative RLP playbooks</Trans></span>
          </Radio.Button>
          <Radio.Button value='top-legal'>
            <ApartmentOutlined /> <span><Trans>Partner playbooks</Trans></span>
          </Radio.Button>
        </>
      ) : (
        <>
          <Radio.Button value='top-legal'>
            <ApartmentOutlined /> <span><Trans>top.legal playbooks</Trans></span>
          </Radio.Button>
          <Radio.Button value='community'>
            <DeploymentUnitOutlined /> <span><Trans>Community playbooks</Trans></span>
          </Radio.Button>
        </>
      )}
    </Radio.Group>
  )

  const buttonParams = side => ({
    className: 'noBorder',
    ghost: list !== side,
    onClick: evt => {
      setSidebarOpen(false)
      setParams(old => ({ ...old, list: evt.target.value }))
    },
    value: side,
    type: 'primary'
  })

  const contentTopbar = (
    <>
      {!publicMode && (
        <div className='topbarActions'>
          {!isPhone ? buttonRadio : (
            <div className='sidebarToolboxButtons'>
              {window.IS_TRANSFERINITIATIVE ? (
                <>
                  <Button
                    {...buttonParams('community')}
                    icon={<DeploymentUnitOutlined />}
                  >
                    <span><Trans>Transferinitiative RLP playbooks</Trans></span>
                  </Button>
                  <Button
                    {...buttonParams('top-legal')}
                    icon={<ApartmentOutlined />}
                  >
                    <span><Trans>Partner playbooks</Trans></span>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    {...buttonParams('top-legal')}
                    icon={<ApartmentOutlined />}
                  >
                    <span><Trans>top.legal playbooks</Trans></span>
                  </Button>
                  <Button
                    {...buttonParams('community')}
                    icon={<DeploymentUnitOutlined />}
                  >
                    <span><Trans>Community playbooks</Trans></span>
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
      )}

    </>
  )

  const content = useMemo(() => (
    <div className={`public-templates ${embed ? 'embed' : ''}`}>
      {!disclaimerClosed && (
        <Alert
          className='legalDisclaimer'
          closable
          //  TODO: Move to a proper whitelabel
          description={window.IS_TRANSFERINITIATIVE ? (
            <>
              <p><Trans>The use of these Transferinitiative RLP playbooks is free of charge for customers of the Transferinitiative Rheinland-Pfalz in the free version.</Trans></p>
              <p><Trans>Optional additional functions are available for a fee; if required, these can be booked through our partner top.legal.</Trans></p>
              <p><Trans>The Transferinitiative Rheinland-Pfalz and top.legal are not legal service providers and assume no liability for the playbooks offered here.</Trans></p>
            </>
          ) : (
            <>
              <p>{firstPhrase}</p>
              <p><Trans>Company lawyers or top.legal network lawyers can adapt the playbooks to your individual case.</Trans></p>
              <p><Trans>top.legal is not a legal service provider and assumes no liability for the templates offered here.</Trans></p>
            </>
          )}
          message={<Trans>Legal notice</Trans>}
          onClose={() => setDisclaimerClosed(true)}
          showIcon
          style={{ marginBottom: '2rem' }}
          type='info'
        />
      )}
      {list === 'top-legal' && (
        <PaginatedDataTable
          CardComponent={TemplateCard}
          actions={readOnly ? [{
            key: 'lookPlaybook',
            Action: ({ record }) => (
              <ButtonLink
                href={`${window.location.origin}/${lang}/public-templates/${record.templateID}`}
                icon={<FileOutlined />}
                noRouter
                target='_blank'
                type='default'
              >
                <Trans>Discover this playbook</Trans>
              </ButtonLink>
            )
          }] : ({ templateID }) => [
            ...(window.parent === window ? [{
              key: 'createContract',
              Action: () => (
                <ButtonLink
                  href={url.replace('/top-legal-templates', `/contracts/${templateID}/new`)}
                  icon={<PlusOutlined />}
                  type='primary'
                >
                  <Trans>Create contract</Trans>
                </ButtonLink>
              )
            }] : []),
            {
              key: 'duplicate',
              Action: () => (
                <Button
                  className='ButtonCopy'
                  icon={<CopyOutlined id='iconONLY' />}
                  onClick={() => duplicatePlaybook(templateID)}
                  type='default'
                >
                  <Trans>Duplicate this playbook</Trans>
                </Button>
              )
            }
          ]}
          defaultView='cards'
          isolatedScope
          loadMore={lastElement => {
            const path = publicMode ? `/public/demo-template?lastElement=${lastElement || ''}` : `/public/template?lastElement=${lastElement || ''}`
            return RestService('GET', path, null, false)
          }}
          rowKey={item => item.templateID}
          tableKey='public-templates'
        >
          {publicListingColumns(readOnly)}
        </PaginatedDataTable>
      )}
      <CommunityPlaybooks hidden={list !== 'community'} />
    </div>
  ), [disclaimerClosed, duplicatePlaybook, embed, firstPhrase, lang, list, publicMode, readOnly, url])

  if (embed) {
    return content
  }

  return (
    <MainContainer
      mainContentClass='globalPlaybookListing'
      publicMode={readOnly}
      topbarContent={(
        <>
          {!isPhone ? (
            <>
              <div className='topbarMainContent'>
                {window.parent === window ? (
                //  Automate
                  <FileOutlined className='headerIcon' twoToneColor='#3DBD7D' />
                ) : (
                //  Dealroom
                  <Tooltip placement='bottomLeft' title='Go back to your playbook list'>
                    <ButtonLink
                      ghost
                      href={url.replace(/\/top-legal-templates$/, '/listing/templates')}
                      icon={<LeftOutlined />}
                      shape='circle'
                      style={{ marginRight: '0.5rem' }}
                    />
                  </Tooltip>
                )}
                {window.IS_TRANSFERINITIATIVE ? (
                  <h1 className='title'><Trans>Transferinitiative RLP playbooks</Trans></h1>
                ) : (
                  <h1 className='title'><Trans>top.legal playbooks</Trans></h1>
                )}
              </div>
              {contentTopbar}
            </>
          ) : (
            <>
              {window.parent !== window && (
                //  Dealroom
                <Tooltip placement='bottomLeft' title='Go back to your playbook list'>
                  <ButtonLink
                    ghost
                    href={url.replace(/\/top-legal-templates$/, '/listing/templates')}
                    icon={<LeftOutlined />}
                    shape='circle'
                    style={{ marginRight: 'auto', marginLeft: '0.3rem' }}
                  />
                </Tooltip>
              )}
              <div className='searchePhone'>
                <Button
                  className='noBorder phoneSidebarDrawerButton'
                  ghost
                  icon={<MenuOutlined />}
                  onClick={() => setSidebarOpen(true)}
                  type='default'
                />
              </div>
              <Drawer
                className='phoneSidebarDrawer'
                onClose={() => setSidebarOpen(false)}
                placement='right'
                title={
                  <div className='topbarMainContent'>
                    {window.IS_TRANSFERINITIATIVE ? (
                      <h1 className='title'><Trans>Transferinitiative RLP playbooks</Trans></h1>
                    ) : (
                      <h1 className='title'><Trans>top.legal playbooks</Trans></h1>
                    )}
                  </div>
                }
                visible={sidebarOpen}
                width='60vw'
                zIndex={1000}
              >
                {contentTopbar}
              </Drawer>
            </>
          )}
        </>
      )}
    >
      {content}
    </MainContainer>
  )
}

PublicTemplatesListing.Auth = props => {
  const Component = useMemo(() => withUserData(props => (
    <PublicTemplatesListing
      {...props}
      firstPhrase={<Trans>top.legal contract playbooks can be used free of charge.</Trans>}
      readOnly={null}
    />
  )), [])
  return <Component {...props} />
}

export default PublicTemplatesListing
