import React from 'react'
import { HistoryEditor } from 'slate-history'
import { ToolbarButton } from '@udecode/plate-ui-toolbar'
import { RedoOutlined, UndoOutlined } from '@ant-design/icons'

import { useFocusedEditor } from '../../Helpers'

import Group from './GroupFormat'

interface HistoryFormatProps {
  action: 'undo' | 'redo',
  icon: any
}

const HistoryFormat: React.FC<HistoryFormatProps> = ({ action, icon }) => {
  const currentEditor = useFocusedEditor()
  const editor = currentEditor as unknown as HistoryEditor

  return <ToolbarButton icon={icon} onMouseDown={editor ? (() => editor[action]()) : undefined} />
}

export default (
  <Group key='editorHistoryFormat'>
    <HistoryFormat action='undo' icon={<UndoOutlined />} />
    <HistoryFormat action='redo' icon={<RedoOutlined />} />
  </Group>
)
