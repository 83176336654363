export const DISPLAY_RESET_PASSWORD = 'DISPLAY_RESET_PASSWORD'


// ==================================================================================================
/**
 * function saves whether the lawyerOffer modal is showing or not.
 * @param {boolean} bool
 * @returns {object}
 */

export const displayResetPassword = bool => ({
  type: DISPLAY_RESET_PASSWORD,
  payload: bool
})
