import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { Trans } from '@lingui/macro'

import { TemplateContext } from '../Contexts'

import ContractEditorSection from './sectionComponents/ContractEditorSection'
import TemplateTitle from './TemplateTitle'
import TemplateAnnexesTitle from './TemplateAnnexesTitle'
import HeaderFooter from './HeaderFooter'

import SignatureStampList from '../Signatures/SignatureStampList'
import { ContractEditorSectionProps, useNewSection } from './sectionComponents/Types'
import ContractEditorSectionContext from './sectionComponents/ContractEditorSectionContext'
import { emptyArr, noop } from '../../../Defaults'

// eslint-disable-next-line @typescript-eslint/ban-types
interface ContractContent<T = {}> {
  key: string
  itemProps?: T
  RenderItem: React.FC<T>
}

const emptySection = { sectionID: '' } as any
type Generator = (titlePrefix: string, array: any[], path: any[]) => ContractContent<ContractEditorSectionProps>[]
const useTreeGenerator = (): Generator => {
  const { deletePart, updateTemplate, template } = useContext<any>(TemplateContext)
  const templateRef = useRef(template)
  templateRef.current = template
  const newSection = useNewSection()

  //  Get the section saving for when we add new section
  const { useContractEditorActions } = useContext(ContractEditorSectionContext)
  const { updateSection } = useContractEditorActions('', emptyArr, emptySection, noop, noop, noop)

  //  Cache rendering props
  const sectionCache = useMemo<Record<string, ContractContent<ContractEditorSectionProps>>>(() => ({}), [])

  const insertOrDelete = useCallback(async ([attrName, ...path]: any[], insert: boolean) => {
    const index = path.pop()
    let array = templateRef.current[attrName]
    let next

    // eslint-disable-next-line no-cond-assign
    while ((next = path.shift()) != null) {
      array = array?.[next]?.subSections
    }

    let secID
    if (Array.isArray(array)) {
      if (insert) {
        secID = await newSection()
        array.splice(index, 0, { sectionID: secID, subSections: [] })
      } else {
        array.splice(index, 1)
      }
      await updateTemplate({ [attrName]: [...templateRef.current[attrName]] })
      //  Force saving the section to avoid editing issues later on
      if (secID) {
        await updateSection?.({ sectionID: secID })
      }
    } else {
      console.error('Failed to find the right array for path', path, 'in', attrName)
    }

    return secID
  }, [newSection, updateTemplate, updateSection])

  return useMemo<Generator>(() => {
    const func = (titlePrefix, array, path) => {
      const arr: ContractContent<ContractEditorSectionProps>[] = []

      array.forEach(({ sectionID, subSections }, index) => {
        const sectionPath = [...path, index]
        const pathStr = sectionPath.join('.')
        const pathInner = [...path, index, Array.isArray(subSections) ? subSections.length : 0]
        const pathBelow = [...path, index + 1]

        //  Remove the attribute name
        const attrName = pathInner.shift()
        pathBelow.shift()

        //  Build a new cache item if needed
        if (sectionCache[pathStr]?.key !== sectionID) {
          //  Update by changing the array ref
          const props: ContractEditorSectionProps = {
            path: sectionPath,
            sectionID,

            addAboveTitle: <Trans>Add above</Trans>,
            addBellowTitle: <Trans>Add “{pathBelow.map(ind => ind + 1).join('.')}”</Trans>,
            addInsideTitle: <Trans>Add “{pathInner.map(ind => ind + 1).join('.')}”</Trans>,

            addAbove: async () => insertOrDelete([...sectionPath], true),
            addBellow: async () => insertOrDelete([attrName, ...pathBelow], true),
            addInside: async () => insertOrDelete([attrName, ...pathInner], true),
            deleteSection: async () => {
              await deletePart(sectionID)
              await insertOrDelete(sectionPath, false)
            }
          }

          sectionCache[pathStr] = {
            key: sectionID,
            itemProps: props,
            RenderItem: ContractEditorSection
          }
        }

        arr.push(sectionCache[pathStr])

        if (Array.isArray(subSections) && subSections.length > 0) {
          arr.push(...func(titlePrefix, subSections, sectionPath))
        }
      })

      return arr
    }

    return func
  }, [sectionCache, deletePart, insertOrDelete])
}

/**
 * Flattening our template / contract to a linear collection of element to render
 */
const useContractEditorContent = (): ContractContent<any>[] => {
  const { readOnly, template: { header, sections, sectionPrefix, footer, annexes, annexePrefix } } = useContext<any>(TemplateContext)
  const genSectionTree = useTreeGenerator()

  const titleItem = useMemo<ContractContent>(() => ({
    key: 'title',
    RenderItem: () => <TemplateTitle readOnly={readOnly} />
  }), [readOnly])

  const headerItem = useMemo<ContractContent>(() => ({
    key: header,
    RenderItem: () => <HeaderFooter kind='header' />
  }), [header])

  const sectionsItems = useMemo<ContractContent<ContractEditorSectionProps>[]>(() => {
    if (Array.isArray(sections) && sections.length > 0) {
      return genSectionTree(sectionPrefix || '', sections, ['sections'])
    }
    return emptyArr
  }, [genSectionTree, sectionPrefix, sections])

  const footerItem = useMemo<ContractContent>(() => ({
    key: footer,
    RenderItem: () => <HeaderFooter kind='footer' />
  }), [footer])

  const signaturesItem = useMemo<ContractContent>(() => ({
    key: 'signatures',
    RenderItem: () => (
      <div className='templateSignaturesWrapper'>
        <SignatureStampList />
      </div>
    )
  }), [])

  const annexesItems = useMemo<ContractContent<ContractEditorSectionProps>[]>(() => {
    if (Array.isArray(annexes) && annexes.length > 0) {
      return [{
        key: 'annexesTitle',
        RenderItem: () => <TemplateAnnexesTitle readOnly={readOnly} />
      }, ...genSectionTree(annexePrefix || '', annexes, ['annexes'])]
    }
    return emptyArr
  }, [annexePrefix, annexes, genSectionTree, readOnly])


  //  Return aggregation
  return useMemo<ContractContent<any>[]>(() => [
    titleItem, headerItem, ...sectionsItems, footerItem, signaturesItem, ...annexesItems
  ], [annexesItems, footerItem, headerItem, sectionsItems, signaturesItem, titleItem])
}

export default useContractEditorContent
