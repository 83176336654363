export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE'
export const RESET_TEMPLATE = 'RESET_TEMPLATE'
export const FETCHING_TEMPLATE_DATA = 'FETCHING_TEMPLATE_DATA'
export const GET_ALL_TEMPLATES = 'GET_ALL_TEMPLATES'
export const GET_ALL_PUBLIC_TEMPLATES = 'GET_ALL_PUBLIC_TEMPLATES'
export const SET_TEMPLATE_CONFIDENTIAL = 'SET_TEMPLATE_CONFIDENTIAL'
export const UPDATING_CONFIDENTIALITY = 'UPDATING_CONFIDENTIALITY'
export const GET_TOP_LEGAL_TEMPLATES = 'GET_TOP_LEGAL_TEMPLATES'
export const DUPLICATE_TEMPLATE = 'DUPLICATE_TEMPLATE'
export const GENERATING_DUPLICATE_TEMPLATE = 'GENERATING_DUPLICATE_TEMPLATE'
export const SET_INSTANCE = 'SET_INSTANCE'
export const FETCHING_SHARED_TEMPLATE = 'FETCHING_SHARED_TEMPLATE'
export const GET_SHARED_TEMPLATE = 'GET_SHARED_TEMPLATE'
export const FETCHING_TAGS_ORGANISATION = 'FETCHING_TAGS_ORGANISATION'
export const GET_TAGS_ORGANISATION = 'GET_TAGS_ORGANISATION'
export const GET_SECTION_ORGANISATION = 'GET_SECTION_ORGANISATION'
export const ORGANISATION_TAGS = 'ORGANISATION_TAGS'
export const ORGANISATION_SECTIONS = 'ORGANISATION_SECTIONS'
export const FETCHING_ORGANISATION_SECTIONS = 'FETCHING_ORGANISATION_SECTIONS'
export const COMMUNITY_TEMPLATES = 'COMMUNITY_TEMPLATES'
export const ORGANISATION_SECTIONS_FETCHED = 'ORGANISATION_SECTIONS_FETCHED'
export const SAVE_SECTIONS = 'SAVE_SECTIONS'
export const SAVE_SECTION = 'SAVE_SECTION'
export const DELETE_SECTION_ORG = 'DELETE_SECTION_ORG'
