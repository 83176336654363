import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { Trans } from '@lingui/macro'

import { ContractEditorContextProps, TemplateEntityType } from '@top-legal/editor'

import { TemplateContext } from '../Contexts'
import { isPartiesEntity } from '../../../Template/_TemplateHelperFunctions'

type EntityHandlers = Pick<ContractEditorContextProps, 'onEntityAdded' | 'onEntityRemoved'>

const addSignature = (entityName: any) => new Promise<boolean>(resolve => {
  const modal = (window as any).modal.confirm({
    title: <Trans>New party added</Trans>,
    content: (
      <Trans>
        Do you want to add an advanced electronic signature for <span className='inputField'>{entityName}</span>?
      </Trans>
    ),
    cancelText: <Trans>No thank you</Trans>,
    className: 'smallModal',
    onCancel: () => {
      modal.destroy()
      resolve(false)
    },
    okText: <Trans>Yes please</Trans>,
    onOk: () => {
      modal.destroy()
      resolve(true)
    }
  })
})

const emptyObject = {}
const useEntityHandlers = (): EntityHandlers => {
  const { template, readOnly, inputFields, conditionalTexts, updateTemplate } = useContext<any>(TemplateContext)

  //  Create entities handlers
  const templateRef = useRef(template)
  templateRef.current = template
  if (!template.parties) {
    template.parties = {}
  }
  if (!template.fields) {
    template.fields = {}
  }
  if (!template.conditionalTexts) {
    template.conditionalTexts = {}
  }

  const hasBeenUpdatedRef = useRef(false)
  const partiesToAskRef = useRef<any[]>([])

  /** ******************************************************************
   *              Take care of parties fields
   ****************************************************************** */
  const handleParty = useCallback((added: boolean, baseKey: string, key: string, field: any) => {
    if (templateRef.current && isPartiesEntity(field)) {
      if (added) {
        const alreadyAsked = Object.keys(templateRef.current.parties).some(innerKey => innerKey.includes(baseKey))

        //  Add the field to counter
        templateRef.current.parties[key] = (templateRef.current.parties[key] || 0) + 1

        //  If not asked for signature, will be treated after debounce
        if (!alreadyAsked) {
          partiesToAskRef.current.push([baseKey, field])
        }
      } else {
        templateRef.current.parties[key] = (templateRef.current.parties[key] || 0) - 1
        if (templateRef.current.parties[key] <= 0) {
          delete templateRef.current.parties[key]
        }
        const findOneOther = Object.keys(templateRef.current.parties).some(innerKey => innerKey.includes(baseKey) && !innerKey.includes('signature'))
        if (!findOneOther && templateRef.current.parties[`${baseKey}.signature`]) {
          delete templateRef.current.parties[`${baseKey}.signature`]
        }
      }

      hasBeenUpdatedRef.current = true
    }
  }, [])

  /** ******************************************************************
   *      Node inspector, loop over nodes and find entities
   ****************************************************************** */
  const inspectNode = useCallback(
    (added: boolean, node: any, hidden = false) => {
      if (node.type === TemplateEntityType && templateRef.current) {
        const { entityType, key: nodeKey, version: verStr } = node
        const [key] = nodeKey.split('.')

        let version = parseInt(verStr as any, 10)
        if (Number.isNaN(version)) {
          version = 1
        }

        if (entityType === 'inputField') {
          //  For special fields look first in template
          const field = (inputFields[key] || {})[version]

          if (field) {
            if (!hidden) {
              handleParty(added, key, nodeKey, field)
            }

            if (added) {
              //  Update template
              if (!templateRef.current.fields[key]) {
                templateRef.current.fields[key] = field
                hasBeenUpdatedRef.current = true
              }

              //  Recurse on special field
              const { formattedText } = field
              if (Array.isArray(formattedText)) {
                formattedText.forEach(child => inspectNode(added, child, true))
              }
            }
          } else if (templateRef.current.fields[key] && !hidden) {
            handleParty(added, key, nodeKey, templateRef.current.fields[key])
          }
        } else if (entityType === 'conditionalText' && added) {
          const cond = (conditionalTexts[key] || {})[version]

          if (cond) {
            //  Update template
            if (!templateRef.current.conditionalTexts[key]) {
              templateRef.current.conditionalTexts[key] = cond
              hasBeenUpdatedRef.current = true
            }

            //  Recurse on the nested field
            inspectNode(
              added,
              {
                type: TemplateEntityType,
                entityType: 'inputField',
                key: cond.field,
                version: cond.fieldVersion
              },
              true
            )

            //  Recurse on the cond values
            const { values } = cond
            if (Object(values) === values) {
              Object.values<any>(values).forEach(data => {
                const children = data.text || data
                if (Array.isArray(children)) {
                  children.forEach(child => inspectNode(added, child, true))
                }
              })
            }
          }
        }
      }
      if (Array.isArray(node.children)) {
        node.children.forEach(child => inspectNode(added, child))
      }
    },
    [conditionalTexts, handleParty, inputFields]
  )

  /** ******************************************************************
   *           Return the handlers with debounced saving
   ****************************************************************** */
  return useMemo<EntityHandlers>(() => {
    if (!readOnly) {
      let timeout: NodeJS.Timeout

      const save = async () => {
        if (hasBeenUpdatedRef.current) {
          const data = {
            parties: { ...templateRef.current.parties },
            fields: { ...templateRef.current.fields },
            conditionalTexts: { ...templateRef.current.conditionalTexts }
          }
          const needToAsk = partiesToAskRef.current

          //  Reset stuff
          hasBeenUpdatedRef.current = false
          partiesToAskRef.current = []

          //  Process them
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, field] of needToAsk) {
            // eslint-disable-next-line no-await-in-loop
            if (await addSignature(field.name)) {
              data.parties[`${key}.signature`] = 1
            }
          }

          //  Save template
          if (process.env.NODE_ENV === 'development') {
            console.info('[useEntityHandlers] - Update', data)
          }
          await updateTemplate(data)
        }
      }

      const addOrDelete = (added: boolean, node: any) => {
        if (timeout) {
          clearTimeout(timeout)
        }

        inspectNode(added, node)

        timeout = setTimeout(save, 200)
      }

      //  Return the actions
      return {
        onEntityAdded: node => addOrDelete(true, node),
        onEntityRemoved: node => addOrDelete(false, node)
      } as EntityHandlers
    }

    return emptyObject as EntityHandlers
  }, [readOnly, updateTemplate, inspectNode])
}

export default useEntityHandlers
