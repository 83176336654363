import React, { useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react'
import { useQueryParams, StringParam } from 'use-query-params'
import { useParams, useHistory } from 'react-router'
import { CloseOutlined, DownOutlined, GlobalOutlined, SearchOutlined } from '@ant-design/icons'
import { t, Trans } from '@lingui/macro'
import { Button, Dropdown, Empty, Input, Menu, Skeleton } from 'antd'

//  Services & Data & Contexts
import { UserAndCompanyDataContext } from '../Layouts/Constants'
import ListingContext from './ListingContext'
import { withUserData } from '../Layouts/AuthenticatedPage'

//  Components
import MainContainer from '../Layouts/MainLayout/MainContainer'
import InfiniteScrolling, { InfiniteScrollingGrouped, InfiniteScrollingGroupsProps } from '../SharedComponents/ListingComponents/InfiniteScrolling'
import { UseListing } from './Types'
import { getHighlight, useHighlight } from './SearchHelpers'

//  Listings
import useTemplateListing from './Template/useTemplateListing'
import useContractListing from './Contract/useContractListing'

//  Actions
import useDebounceSaving from '../../hooks/useDebounceSaving'

//  Styles
import './Listing.scss'
import { DeviceContext } from '../../GlobalContext'

const Identity: React.FC = ({ children }) => children as any

const NoResult: React.FC = () => (
  <Empty
    className='listingEmpty'
    description={<Trans>No data match the current criteria</Trans>}
  />
)

const getKeywords = (str: string): string[] | undefined => {
  const lower = str.trim().toLowerCase()
  return lower ? lower.split(' ') : undefined
}


const InstanceListing: React.FC<ReturnType<UseListing<any>> & { search: string, params: any }> = ({
  icon,
  title,
  instances,
  filters,
  stringSearch,
  rowKey,
  ContainerWrapper = Identity,
  Actions = Identity,
  ListRow,
  loading,

  search,
  params,
  children
}) => {
  const device = useContext(DeviceContext)

  const split = useMemo(() => getKeywords(search), [search])
  const filteredInstances = useMemo(() => {
    if (!split && !filters) {
      return instances
    }

    return instances.filter(instance => (
      (!split || stringSearch(instance, split))
      && (!filters || !filters.some(filter => params[filter.key] && !filter.filter(instance, params[filter.key])))
    ))
  }, [instances, split, params, filters, stringSearch])

  const content = useMemo(() => {
    if (loading || ((window as any).isFirstReplication && filteredInstances.length === 0)) {
      const loadingAnim = (
        <div className='newListRowWrapper'>
          <div className='newListRow'>
            <Skeleton active paragraph={{ rows: 4 }} />
          </div>
        </div>
      )
      return (
        <div className='loadingWrapper' style={{ overflow: 'hidden' }}>
          {loadingAnim}
          {loadingAnim}
          {loadingAnim}
          {loadingAnim}
          {loadingAnim}
          {loadingAnim}
        </div>
      )
    }
    if (filteredInstances.length === 0) {
      return <NoResult />
    }
    return <InfiniteScrolling RenderItem={ListRow} items={filteredInstances} rowKey={rowKey} />
  }, [ListRow, filteredInstances, loading, rowKey])

  return (
    <ContainerWrapper>
      <MainContainer
        mainContentClass='instancesListing'
        topbarContent={(
          <>
            <div className='topbarMainContent'>
              {device !== 'phone' && React.cloneElement(icon as any, {
                className: 'headerIcon',
                twoToneColor: '#3DBD7D'
              })}
              {/* {device === 'phone' ? (children as any)[1] : children} */}
              {device !== 'phone' && <h1 className='title'>{title}</h1>}
              {(children as any)[1]}
            </div>
            <div className='topbarActions'>
              <Actions />
            </div>
          </>
        )}
      >
        {content}
      </MainContainer>
    </ContainerWrapper>
  )
}


// ==================================================================================================


// ==================================================================================================


const AllListing: React.FC<{ instanceTypes: ReturnType<UseListing<any>>[], search: string }> = ({
  instanceTypes,
  search,
  children
}) => {
  const device = useContext(DeviceContext)

  return useMemo(() => {
    const groups: InfiniteScrollingGroupsProps<any>['groups'] = []

    //  Build groups property
    instanceTypes.forEach(({
      key,
      rowKey,
      instances,
      stringSearch,
      ListRow,
      title
    }) => {
      const split = getKeywords(search)
      const items = split ? instances.filter(instance => stringSearch(instance, split)) : instances
      const RenderGroup: React.FC = () => <div className='listingGroupTitle'><span>{title}</span></div>

      if (items.length === 0) {
        groups.push({
          items: [{ id: `${key}__empty__` }],
          rowKey: item => item.id,
          RenderItem: NoResult,
          RenderGroup
        })
      } else {
        groups.push({
          items,
          rowKey,
          RenderItem: ListRow,
          RenderGroup
        })
      }
    })

    //  Define list content
    let content = (
      <MainContainer
        mainContentClass='instancesListing'
        topbarContent={(
          <div className='topbarMainContent'>
            {device !== 'phone' && <GlobalOutlined className='headerIcon' twoToneColor='#3DBD7D' />}
            {device === 'phone' ? (children as any)[1] : children}
          </div>
        )}
      >
        <InfiniteScrollingGrouped groups={groups} />
      </MainContainer>
    )

    //  Wrap the main content with all the containers by order
    instanceTypes.forEach(({ ContainerWrapper }) => {
      if (ContainerWrapper) {
        const inner = content
        content = <ContainerWrapper>{inner}</ContainerWrapper>
      }
    })

    //  Return the loading with the wrapped content
    return content
  }, [children, instanceTypes, search, device])
}


// ==================================================================================================


// ==================================================================================================


const last = (nodes?: NodeListOf<HTMLElement>): HTMLElement | undefined => nodes?.[nodes?.length - 1]

const keyBoardSuggestionFocusManagement = {
  onKeyDown: (evt: React.KeyboardEvent<HTMLDivElement>) => {
    evt.stopPropagation()
    let { key } = evt
    const target = evt.target as HTMLSpanElement

    const getRoot = (className = 'searchBar') => {
      let root: HTMLElement | null = target
      while (root && !root.className.includes(className)) {
        root = root.parentElement
      }
      return root
    }

    const focusInput = () => {
      getRoot()?.querySelector<HTMLTextAreaElement>('.ant-input')?.focus()
    }

    //  In case this is the input
    if (target.className.includes('ant-input')) {
      const textArea = target as HTMLTextAreaElement
      if (key === 'ArrowUp') {
        evt.preventDefault()
        last(last(getRoot()?.querySelectorAll('.suggestionCategory'))?.querySelectorAll<HTMLSpanElement>('.filterTag'))?.focus()
        return
      }
      if (key === 'ArrowDown') {
        evt.preventDefault()
        getRoot()?.querySelector('.suggestionCategory')?.querySelector<HTMLSpanElement>('.filterTag')?.focus()
        return
      }
      if ((key === 'Backspace' || key === 'ArrowLeft') && textArea.selectionStart === 0 && textArea.selectionEnd === 0) {
        last(getRoot()?.querySelector('.activeFilters')?.querySelectorAll<HTMLSpanElement>('.filterTag'))?.focus()
        return
      }
    }

    //  In case this is a filterTag
    if (target.className.includes('filterTag')) {
      if (key === 'Enter' || key === 'Backspace') {
        evt.preventDefault()
        target.click()
        if (key === 'Backspace') {
          if (target.previousElementSibling) {
            (target.previousElementSibling as HTMLSpanElement).focus()
            return
          }
          if (target.nextElementSibling) {
            (target.nextElementSibling as HTMLSpanElement).focus()
            return
          }
        }
        focusInput()
        return
      }

      if (key === 'ArrowLeft') {
        evt.preventDefault()
        if (target.previousElementSibling) {
          (target.previousElementSibling as HTMLSpanElement).focus()
          return
        }
        key = 'ArrowUp'
      }

      if (key === 'ArrowRight') {
        evt.preventDefault()
        if (target.nextElementSibling) {
          (target.nextElementSibling as HTMLSpanElement).focus()
          return
        }
        key = 'ArrowDown'
      }

      const parent = getRoot('suggestionCategory') as null | HTMLDivElement
      if (key === 'ArrowUp') {
        evt.preventDefault()
        if (parent?.previousElementSibling) {
          last((parent.previousElementSibling as HTMLDivElement).querySelectorAll<HTMLSpanElement>('.filterTag'))?.focus()
        } else {
          focusInput()
        }
        return
      }

      if (key === 'ArrowDown') {
        evt.preventDefault()
        if (parent?.nextElementSibling) {
          (parent.nextElementSibling as HTMLDivElement).querySelector<HTMLSpanElement>('.filterTag')?.focus()
        } else {
          //  Go to last item
          if (target.nextElementSibling) {
            let elm = target.nextElementSibling
            while (elm.nextElementSibling) {
              elm = elm.nextElementSibling
            }
            (elm as HTMLSpanElement).focus()
            return
          }
          focusInput()
        }
      }
    }
  }
}


// ==================================================================================================


// ==================================================================================================


const Listing: React.FC = () => {
  const { user: { userID } } = useContext(UserAndCompanyDataContext)

  const templateListing = useTemplateListing()
  const contractListing = useContractListing()

  const listMapping = useMemo<Record<string, ReturnType<UseListing<any>>>>(() => {
    const all = [contractListing, templateListing]
    const mapping = {
      // all: {
      //   key: 'all', title: <Trans>All</Trans>, icon: <GlobalOutlined />, instanceTypes: all
      // } as any
    }

    all.forEach(list => {
      mapping[list.key] = list
    })

    return mapping
  }, [contractListing, templateListing])


  // ==================================================================================================

  const { listType, lang } = useParams()
  const history = useHistory()

  const { filters } = listMapping[listType]

  const [params, setParams] = useQueryParams<any>(useMemo(() => {
    const config: any = { s: StringParam }
    filters?.forEach(({ key }) => { config[key] = StringParam })
    return config
  }, [filters]))

  const setUrlType = useCallback(
    (newType: string) => history.push(`/${lang}/listing/${newType}${
      window.location.search ? `?${window.location.search}` : ''
    }`),
    [history, lang]
  )


  // ==================================================================================================


  const [searchInput, setSearchInput] = useState(params.s || '')
  const Highlight = useHighlight(params.s || '')
  const { forceSave } = useDebounceSaving<string>(
    1500,
    params.s || '',
    searchInput,
    setSearchInput,
    useCallback(data => setParams(old => ({ ...old, s: data || undefined as any })), [setParams])
  )

  const [suggestion, setSuggestion] = useState(false)
  useLayoutEffect(() => {
    const handler = () => {
      forceSave()
      setSuggestion(false)
    }
    document.body.addEventListener('click', handler)
    return () => document.body.removeEventListener('click', handler)
  }, [forceSave])

  const searchBar = (
    <div className='searchBar' onClick={evt => evt.stopPropagation()} {...keyBoardSuggestionFocusManagement}>
      <SearchOutlined />
      <div className='activeFilters'>
        {filters && filters.map(({
          key,
          title,
          icon,
          items
        }) => params[key] && items[params[key]] && (
          <span
            className='filterTag searchActive'
            onClick={() => setParams(old => ({ ...old, [key]: undefined }))}
            tabIndex={0}
          >
            <span className='title'>{icon} {title}</span>
            <span className='content'>{items[params[key]]}</span>
            <CloseOutlined />
          </span>
        ))}
      </div>
      <Input.TextArea
        autoSize={useMemo(() => ({
          minRows: 1,
          maxRows: 1
        }), [])}
        onChange={useCallback(evt => setSearchInput(evt.target.value), [])}
        onFocus={() => setSuggestion(true)}
        onKeyDown={useCallback(evt => {
          if (evt.key === 'Enter') {
            evt.preventDefault()
            setTimeout(() => forceSave())

            //  Some analytics tracking
            if ((window as any).analytics) {
              (window as any).analytics.track('searchInputEnter', {
                userID,
                listType,
                searchString: evt.target.value
              })
            }
          }
        }, [forceSave, userID, listType])}
        placeholder={t`Type to search...`}
        value={searchInput}
      />
      {Object.keys(params).length > 0 && (
        <Button
          className='noBorder ant-btn-grey clearSearch'
          ghost
          icon={<CloseOutlined />}
          onClick={() => setParams(() => ({}))}
          size='small'
        />
      )}
      {suggestion && searchInput && (() => {
        const keywords = getKeywords(searchInput)
        const HighlightTag = keywords && getHighlight(keywords) as any

        if (!keywords || !Highlight || !filters) {
          return null
        }

        const suggestions: React.ReactElement[] = []
        filters.forEach(({
          key,
          title,
          icon,
          items
        }) => {
          const children: React.ReactElement[] = []

          const onClick = value => {
            setParams(old => ({ ...old, [key]: value, s: undefined as any }))
            setSearchInput('')
          }

          Object.entries(items).forEach(([key2, value]) => {
            const lower = value.toLowerCase()
            if (params[key] !== key2 && !keywords.some(str => !lower.includes(str))) {
              children.push(
                <span key={key2} className='filterTag' onClick={() => onClick(key2)} tabIndex={0}>
                  <span className='content'><HighlightTag text={value} /></span>
                </span>
              )
            }
          })

          if (children.length > 0) {
            suggestions.push(
              <div key={key} className='suggestionCategory'>
                <h3>{icon} {title}</h3>
                <div className='results'>{children}</div>
              </div>
            )
          }
        })

        return suggestions && suggestions.length > 0 && <div className='suggestionContainer'>{suggestions}</div>
      })()}
    </div>
  )


  // ==================================================================================================


  const categoryDropdown = (
    <Dropdown
      overlay={(
        <Menu>
          {Object.values(listMapping).map(({ key, icon, title: navTitle }) => (
            <Menu.Item key={key} onClick={() => setUrlType(key)}>
              {icon} <span>{navTitle}</span>
            </Menu.Item>
          ))}
        </Menu>
      )}
      trigger={['click']}
    >
      <span className='ant-dropdown-link'>
        <h1 className='title'>{listMapping[listType].title} <DownOutlined /></h1>
      </span>
    </Dropdown>
  )


  // ==================================================================================================


  let content
  if (listType === 'all') {
    content = (
      <AllListing instanceTypes={(listMapping.all as any).instanceTypes} search={params.s || ''}>
        {categoryDropdown}
        {searchBar}
      </AllListing>
    )
  } else {
    content = (
      <InstanceListing {...listMapping[listType]} params={params} search={params.s || ''}>
        {categoryDropdown}
        {searchBar}
      </InstanceListing>
    )
  }


  // ==================================================================================================


  return (
    <ListingContext.Provider value={useMemo(() => ({ Highlight }), [Highlight])}>
      {content}
    </ListingContext.Provider>
  )
}

const Wrapped: React.FC = () => {
  const Component = useMemo(() => withUserData(Listing), [])
  return <Component />
}

export default Wrapped
