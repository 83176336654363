import { useMemo } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'

import { Template, FieldsMapping, InputField, ConditionalText, CondsMapping } from '@top-legal/datastore'

import { generateCompanyEntity, generateUserEntity } from '../../Template/TemplateHelperFunctions'
import { fillTemplateEntities } from '../../Template/_TemplateHelperFunctions'

export const formatTemplate = (
  template: Template,
  inputFields: { [fieldID: string]: { [version: number]: InputField } },
  conditionalTexts: { [condID: string]: { [version: number]: ConditionalText } },
  selectedOrganisation: any,
  user: any,
  userLang: string
): Template => {
  //  Clone the template
  const formattedTemplate = { ...template }

  //  Inject temp fields
  if (Object((formattedTemplate as any).fieldsData) === (formattedTemplate as any).fieldsData) {
    inputFields = { ...inputFields, ...(formattedTemplate as any).fieldsData }
  }
  //  Inject temp conds
  if (Object((formattedTemplate as any).condsData) === (formattedTemplate as any).condsData) {
    conditionalTexts = { ...conditionalTexts, ...(formattedTemplate as any).condsData }
  }


  //  Bind fields from the store to the template
  if (Object(template.fields) === template.fields) {
    const newFields = {}

    Object.entries<string>(template.fields).forEach(([inputFieldID, version]) => {
      const field = inputFields[inputFieldID]?.[version || 1]

      if (field) {
        newFields[inputFieldID] = field
      }
    })

    formattedTemplate.fields = newFields

    //  Then build the fields up
    fillTemplateEntities(
      formattedTemplate,
      `${user.firstName} ${user.lastName}`,
      selectedOrganisation?.name,
      generateUserEntity as any,
      generateCompanyEntity as any,
      userLang
    )
  }


  //  Bind conditionals from the store to the template
  if (Object(template.conditionalTexts) === template.conditionalTexts) {
    const newConditionals = {}

    Object.entries<string>(template.conditionalTexts).forEach(([conditionalTextID, version]) => {
      const cond = conditionalTexts[conditionalTextID]?.[version || 1]
      if (cond) {
        newConditionals[conditionalTextID] = cond
      }
    })

    formattedTemplate.conditionalTexts = newConditionals
  }

  return formattedTemplate
}

const useTemplateFromRedux = (inputFieldsMapping: FieldsMapping, condsMapping: CondsMapping): Template => {
  const { lang } = useParams()
  const template = useSelector(state => state.template.templateCreator)
  const { selectedOrganisation } = useSelector(state => state.organisation)
  const user = useSelector(state => state.user.ownProfileData)

  return useMemo(
    () => formatTemplate(template, inputFieldsMapping, condsMapping, selectedOrganisation, user, lang),
    [template, inputFieldsMapping, condsMapping, selectedOrganisation, user, lang]
  )
}

export default useTemplateFromRedux
