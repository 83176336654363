import React, { useContext, useMemo, useRef } from 'react'

//  Context
import { ContractContext, TemplateContext } from '../ContractEditor/Contexts'

//  Conmponents
import Sidebar from '../ContractEditor/Sidebar/Sidebar'
import SwipableContainer from '../../SharedComponents/SwipableContainer'
import { SidebarStateContext } from '../../../ContractTemplateRoutes'
import { DeviceContext } from '../../../GlobalContext'


export interface ContractLayoutProps {
  LeftSidebar?: React.ElementType,
  customIdPrefix?: string
  className?: string
  openedLeftSidebar?: boolean
}


export const IdPrefixContext = React.createContext<string>('')


const ContractLayout: React.FC<ContractLayoutProps> = ({
  LeftSidebar,
  customIdPrefix,
  className,
  openedLeftSidebar,
  children
}) => {
  const isSmall = useContext(DeviceContext) === 'phone'
  const swipableRef = useRef<any>()

  const { contract } = useContext<any>(ContractContext)
  const { readOnly, template } = useContext<any>(TemplateContext)
  const sidebarStateRef = useContext(SidebarStateContext)


  const idPrefix = useMemo(() => {
    if (customIdPrefix) {
      return customIdPrefix
    }
    if (contract && Object.keys(contract).length > 0) {
      return `${contract.contractID || 'create-contract'}-`
    }
    return `${template.templateID || 'create-template'}-`
  }, [contract, customIdPrefix, template.templateID])

  /**
   * Rendering the layout with flex for desktop & tablet and carousel for phone
   */
  const leftSidebar = useMemo(() => (LeftSidebar ? (
    <LeftSidebar
      idPrefix={idPrefix}
      isSmall={isSmall}
      next={callback => {
        if (swipableRef.current) {
          swipableRef.current.next(callback)
        }
      }}
      previous={callback => {
        if (swipableRef.current) {
          swipableRef.current.prev(callback)
        }
      }}
      readOnly={readOnly}
    />
  ) : null), [LeftSidebar, idPrefix, isSmall, readOnly])

  const sidebar = useMemo(() => (
    <Sidebar
      defaultOpened={openedLeftSidebar}
      forceRender
      stateRef={sidebarStateRef}
    />
  ), [openedLeftSidebar, sidebarStateRef])

  // for phone , 3ana el condition hethy ken small
  if (isSmall) {
    //  TODO: Convert SwipableContainer to typescript
    const SwipableContainer_ = SwipableContainer as React.ForwardRefExoticComponent<any>
    return (
      <IdPrefixContext.Provider value={idPrefix}>
        <div className={`contractEditorComponentWrapper withCarousel ${className || ''}`}>
          <SwipableContainer_ ref={swipableRef} defaultIndex={1}>
            {leftSidebar}
            {children}
            {sidebar}
          </SwipableContainer_>
        </div>
      </IdPrefixContext.Provider>
    )
  }

  return (
    <IdPrefixContext.Provider value={idPrefix}>
      <div className={`contractEditorComponentWrapper withFlex ${className || ''}`}>
        {leftSidebar}
        {children}
        {sidebar}
      </div>
    </IdPrefixContext.Provider>
  )
}

export default ContractLayout
