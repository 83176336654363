import React, { useContext, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Trans } from '@lingui/macro'
import { Button, Tooltip } from 'antd'
import { AuditOutlined } from '@ant-design/icons'
import moment from 'moment'

import RestService from '../../../../../../../RestService'
import { ContractContext } from '../../../../Contexts'
import { CustomTag } from '../../../../../../Organisations/UsersManagement'
import InviteFormDrawer, { copyLinkProps } from '../../../../InviteContactToContract/InviteFormDrawer'
import UserCard from './UserCard'

import useCardActions from './useCardActions'
import { ExternalContext } from '../../../../../../ExternalViews/ExternalContext'
import { displayDate } from '../../../../../../Template/_TemplateHelperFunctions'
import Portal from '../../../../../../SharedComponents/Portal'
import { useConfirmAndSignAction } from '../../../../../../ExternalViews/ExternalPageSigning'
import VerifyBeforeSigningDrawer from '../VerifyBeforeSigningDrawer'
import InstanceUsersContext, { PartyInfo } from '../Context/InstanceUsersContext'
import SigningDrawer from '../../../../Signatures/SigningDrawer'

interface ExternalRoleCardProps {
  partyInfo: PartyInfo
}

const ExternalRoleCard: React.FC<ExternalRoleCardProps> = ({ partyInfo }) => {
  const { tokenData: guestToken } = useContext(ExternalContext)
  const { lang } = useParams()
  const history = useHistory()

  const { contract, signature } = useContext<any>(ContractContext)
  const [showInvite, setShowInvite] = useState<boolean>(false)

  //  Get a general disabled state, it come with a check contract is fullySigned
  const { actionsDisabled, partiesMap } = useContext(InstanceUsersContext)
  const partyData = partiesMap.get(partyInfo.partyData.partyID)

  /** **********************************************************************
   *                    Build card actions
   ********************************************************************** */
  const token = partyInfo.tokenData?.token
  const resend = token && (() => RestService('POST', `/user/invite/${token}/resendInvite`))
  const actions: any[] = useCardActions(resend, partyInfo.tokenData?.email, { ...(token ? copyLinkProps(lang, token) : {}) }, !!partyInfo.tokenData)

  /** **********************************************************************
   *           Build the title tag
   ********************************************************************** */
  const titleTag = useMemo(() => {
    if (partyInfo.signedDate) {
      return (
        <CustomTag
          color='done'
          status={
            <>
              <Trans>Signed</Trans> {displayDate(partyInfo.signedDate)}
            </>
          }
        />
      )
    }
    if (partyInfo.tokenData) {
      if (partyInfo.tokenData.lastSeen) {
        return (
          <CustomTag
            color='done'
            status={
              <>
                <Trans>Last seen</Trans> {moment(partyInfo.tokenData.lastSeen).calendar()}
              </>
            }
          />
        )
      }
      let tag = <Trans>Invited</Trans>
      if (partyInfo.tokenData.deleteAfter) {
        tag = (
          <>
            {tag} <Trans>Until {displayDate(partyInfo.tokenData.deleteAfter * 1000)}</Trans>
          </>
        )
      }
      return <CustomTag color='done' status={tag} />
    }
    return <CustomTag color='warning' status={<Trans>Not Invited yet</Trans>} />
  }, [partyInfo.signedDate, partyInfo.tokenData])

  /** **********************************************************************
   *           Build the invite button
   ********************************************************************** */
  const inviteButtonDisabled = actionsDisabled || !['drafted', 'editing', 'frozen', 'signed'].includes(contract.contractStatus)
  const inviteButton = useMemo(() => {
    const button = (
      <Button
        key='inviteBtn' block className='ant-btn-external' disabled={inviteButtonDisabled}
        onClick={() => setShowInvite(true)}
      >
        <Trans>Invite user</Trans>
      </Button>
    )

    if (inviteButtonDisabled) {
      return (
        <Tooltip key='invite' title={<Trans>You cannot invite users once the contract is fully signed</Trans>}>
          {button}
        </Tooltip>
      )
    }

    return button
  }, [inviteButtonDisabled])

  const [signingOpen, setSigningOpen] = useState(signature && !signature.signedDate)
  const externalRequestSigning = useConfirmAndSignAction()
  const signingButton = useMemo(() => {
    if (!signature || (!signature.signedDate && !partyInfo.signedDate)) {
      const open = () => setSigningOpen(true)
      const close = () => setSigningOpen(false)
      const confirm = async () => {
        await externalRequestSigning()
        setSigningOpen(false)
      }

      return (
        <>
          {signature ? (
            <SigningDrawer close={close} onFinishSigning={close} signature={signature} visible={signingOpen} />
          ) : (
            <VerifyBeforeSigningDrawer close={close} onConfirm={confirm} visible={signingOpen} />
          )}

          <Portal cssSelector='#topBarSignButton'>
            <Button icon={<AuditOutlined />} onClick={open} type='primary'>
              <Trans>Sign now</Trans>
            </Button>
          </Portal>
          <Portal cssSelector='#externalWelcomeBoxSigning'>
            <Button className='noBorder' ghost icon={<AuditOutlined />} onClick={open}>
              <Trans>Sign now</Trans>
            </Button>
          </Portal>
          <Button block onClick={open} type='primary'>
            <Trans>Sign now</Trans>
          </Button>
        </>
      )
    }
    return null
  }, [externalRequestSigning, signingOpen, signature, partyInfo.signedDate])

  /** **********************************************************************
   *           Return a final user card
   ********************************************************************** */
  return (
    <>
      <UserCard
        cardActions={useMemo(() => {
          // No invite button for internal side
          if (!/^(contact|party)-/.test(partyInfo.partyData.partyID)) {
            return undefined
          }
          //  If this is the current guest user do not display anything
          if (partyInfo.partyData.partyID === guestToken?.contact?.partyID) {
            return signingButton ? [signingButton] : undefined
          }
          //  If we have internally invited that user display new actions
          if (partyInfo.tokenData) {
            return actions
          }
          //  Or display an invite button
          return [inviteButton]
        }, [actions, guestToken?.contact?.partyID, inviteButton, signingButton, partyInfo.tokenData, partyInfo.partyData])}
        partyInfo={partyInfo}
        titleTag={titleTag}
      />

      <InviteFormDrawer
        initialValue={partyData}
        inviteType={['frozen', 'signed'].includes(contract.contractStatus) ? 'signing' : 'negotiation'}
        onCancel={() => setShowInvite(false)}
        onComplete={async () => {
          //  Quick fix for opening the publish page once first external is invited but not in signing mode
          if (!contract.sentOverDate && !['frozen', 'signed'].includes(contract.contractStatus) && contract.publishHistory.length === 1) {
            setTimeout(() => {
              history.push(`/${lang}/contracts/${contract.contractID}/publish`)
            }, 1500)
          }
          setShowInvite(false)
        }}
        partyKey={partyInfo.partyKey}
        visible={showInvite}
      />
    </>
  )
}

export default ExternalRoleCard
