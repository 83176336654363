import React, { useCallback, useContext, useState } from 'react'
import { Input } from 'antd'

import { TemplateContext } from '../Contexts'

import useDebounceSaving from '../../../../hooks/useDebounceSaving'


const TemplateTitle: React.FC<{ readOnly: boolean }> = ({ readOnly }) => {
  const { template: { name }, updateTemplate } = useContext<any>(TemplateContext)
  const [title, setTitle] = useState<string>(name)

  const { saveTimeout, forceSave } = useDebounceSaving<string>(
    1500,
    name,
    title,
    setTitle,
    useCallback(newName => updateTemplate({ name: newName }), [updateTemplate])
  )

  return (
    <div className='templateTitle' contentEditable={false}>
      {
        readOnly ? (
          <div className='ant-input'>{name}</div>
        ) : (
          <Input.TextArea
            autoSize={{
              minRows: 1,
              maxRows: 5
            }}
            onBlur={forceSave}
            onChange={evt => {
              setTitle(evt.target.value)
              saveTimeout()
            }}
            onKeyDown={evt => {
              if (evt.key === 'Enter') {
                evt.preventDefault()
                forceSave()
              }
            }}
            value={title}
          />
        )
      }
    </div>
  )
}

export default TemplateTitle
