import React, { useEffect, useRef } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { Tabs } from 'antd'

/**
 * TabsWithKeyInUrl it's a simple wrapper of ant design Tabs but with the specificity to save the current tab opened in the url to help for navigation
 * You must provide a defaultActiveKey in props and correctly named your <Tabs.TabPane> key because they will figure in the url
 * You can provide a onChange handler in props to get the key for loading data for example
 *
 * @example ```jsx
  <TabsWithKeyInUrl
    className='templateTabs'
    defaultActiveKey='public-templates'
    onChange={activeKey => this.setState({ activeKey })}
  >
    <Tabs.TabPane key='public-templates' tab={<Trans>top.legal templates</Trans>}>
      {this.state.activeKey === 'public-templates' && this.renderTemplateTable()} // If you use DataTable add this check to avoid autoLoading
    </Tabs.TabPane>
    <Tabs.TabPane key='company-templates' tab={<Trans>{this.props.selectedOrganisation.name} templates</Trans>}>
      {this.state.activeKey === 'company-templates' && this.renderCompanyTemplateTable()}
    </Tabs.TabPane>
    <Tabs.TabPane key='shared-templates' tab={<Trans>Shared templates</Trans>}>
      {this.state.activeKey === 'shared-templates' && this.renderSharedTemplateTable()}
    </Tabs.TabPane>
  </TabsWithKeyInUrl>
```
 */

const TabsWithKeyInUrl = ({ onChange = _ => {}, defaultActiveKey, ...props }) => {
  const [tab, setTab] = useQueryParam('tab', StringParam)

  /**
   * Take care of the tab url param
   */
  const dataRef = useRef({ defaultActiveKey, onChange })
  dataRef.current = { defaultActiveKey, onChange }
  useEffect(() => {
    if (tab && typeof dataRef.current.onChange === 'function') {
      dataRef.current.onChange(tab)
    }
    setTab(tab)
  }, [tab, setTab])

  /**
   * Returning the tabs
   */
  return <Tabs {...props} activeKey={tab} onChange={setTab}/>
}

export default TabsWithKeyInUrl
