/**
 * SRC: https://github.com/collab-project/videojs-record/blob/master/src/js/plugins/ts-ebml-plugin.js
 * @file ts-ebml-plugin.js
 * @since 3.3.0
 */

import videojs from 'video.js'

// see https://github.com/legokichi/ts-ebml/issues/25
import { Buffer } from 'buffer'
import { Decoder, Reader, tools } from 'ts-ebml'

window.Buffer = Buffer

/**
 * Converter engine using the ts-ebml library.
 *
 * Used to inject metadata, like duration, into webm files.
 */

const ConvertEngine = videojs.getComponent('ConvertEngine')

class TsEBMLEngine extends ConvertEngine {
  /**
     * Inject metadata.
     *
     * @param {Blob} data - Recorded data that needs to be converted.
     */
  convert (data) {
    console.info('Using custom TsEBMLEngine')
    const decoder = new Decoder()
    const reader = new Reader()
    reader.logging = false
    reader.drop_default_duration = false

    // save timestamp
    const timestamp = new Date()
    timestamp.setTime(data.lastModified)

    // notify listeners
    this.player().trigger('startConvert')

    // load and convert blob
    this.loadBlob(data).then(buffer => {
      // decode
      let elms = decoder.decode(buffer)

      // see https://github.com/legokichi/ts-ebml/issues/33#issuecomment-888800828
      const validEmlType = ['m', 'u', 'i', 'f', 's', '8', 'b', 'd']
      elms = elms.filter(elm => validEmlType.includes(elm.type))

      elms.forEach(elm => {
        reader.read(elm)
      })
      reader.stop()

      // generate metadata
      const refinedMetadataBuf = tools.makeMetadataSeekable(
        reader.metadatas, reader.duration, reader.cues
      )
      const body = buffer.slice(reader.metadataSize)

      // create new blob
      const result = new Blob([refinedMetadataBuf, body],
        { type: data.type })

      // add existing file info
      this.addFileInfo(result, timestamp)

      // store result
      this.player().convertedData = result

      // notify listeners
      this.player().trigger('finishConvert')
    })
  }
}

TsEBMLEngine.__OVERWRITTEN__ = true

// expose plugin
videojs.TsEBMLEngine = TsEBMLEngine
window.TsEBMLEngine = TsEBMLEngine
