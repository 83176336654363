import React, { useCallback, useMemo, useRef } from 'react'
import { useHistory, useParams } from 'react-router'
import { useSelector } from 'react-redux'

import { InstanceRenderingProps, UseSearchReturn } from '../Types'
import { MediaCard } from '../../Media/MediaLibrary'

import './MediaSearchInstanceStyles.scss'
import { hasKeyWordsFactory } from '../../Listing/SearchHelpers'

const MediaInstance: React.FC<InstanceRenderingProps> = ({ instance, Highlight }) => {
  const history = useHistory()
  const { lang } = useParams()

  return (
    <div className='instanceRendering mediaInstanceRendering'>
      <MediaCard Highlight={Highlight} media={instance} />
    </div>
  )
}

const empty = []
const useMediaSearchInstance = (): UseSearchReturn => {
  const mediasStore = useSelector(state => state.organisation.mediaLibrary)
  const ref = useRef<any>()

  const medias = useMemo(() => {
    if (Object(mediasStore) !== mediasStore) {
      return empty
    }

    const array = Object.values<any>(mediasStore)
    if (array.length === 0) {
      return empty
    }

    return array
  }, [mediasStore])

  ref.current = medias

  const filterInstances = useCallback((search: string) => {
    if (ref.current.length === 0) {
      return []
    }

    const split = search.toLowerCase().trim().split(' ')
    if (split.length === 1 && !split[0]) {
      return [...ref.current]
    }

    return ref.current.filter(media => {
      const hasKeyWords = hasKeyWordsFactory([...split])

      return hasKeyWords(media.title)
    })
  }, [])

  return useMemo<UseSearchReturn>(() => ({
    category: 'media',
    filterInstances,
    InstanceRendering: MediaInstance,
    getID: instance => instance.mediaID
  }), [filterInstances])
}

export default useMediaSearchInstance
