/* eslint-disable max-lines */
import React from 'react'
import { ConsoleLogger as Logger } from '@aws-amplify/core'
import Storage from '@aws-amplify/storage'
import { SyncOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'

import { saveInStore, toJson } from '@top-legal/datastore'

import RestService from '../../../RestService'
import { compareRoles } from '../UserRoleDisplay/CompareUserRoles'

// STORE
export const STORE_ORGANISATIONS = 'STORE_ORGANISATIONS'
export const STORE_SELECTED_ORGA = 'STORE_SELECTED_ORGA'
export const STORE_SELECTED_CLIENT_ORGA = 'STORE_SELECTED_CLIENT_ORGA'
export const FETCHING_ORGANISATION_DATA = 'FETCHING_ORGANISATION_DATA'
export const SET_SELECTED_ORGANISATION_INDEX = 'SET_SELECTED_ORGANISATION_INDEX'
export const UPDATE_COMPANY_ROLES = 'UPDATE_COMPANY_ROLES'
export const UPDATE_INSTANCE_ROLES = 'UPDATE_INSTANCE_ROLES'
export const LOAD_ORGANISATION_FROM_LOCAL_STORAGE = 'LOAD_ORGANISATION_FROM_LOCAL_STORAGE'
export const UPDATE_LAWYER_ORGANISATION = 'UPDATE_LAWYER_ORGANISATION'
export const STORE_INVITE_DATA = 'STORE_INVITE_DATA'
export const UPDATE_ORGANISATION_EVENTS = 'UPDATE_ORGANISATION_EVENTS'
export const STORE_EXTERNAL_USER = 'STORE_EXTERNAL_USER'
export const UPDATE_INSTANCE_EVENTS = 'UPDATE_INSTANCE_EVENTS'
export const UPDATE_REMINDERS = 'UPDATE_REMINDERS'
export const STORE_INSTANCES_COMMENTS = 'STORE_INSTANCES_COMMENTS'
export const STORE_INSTANCES_EVENTS = 'STORE_INSTANCES_EVENTS'
export const UPDATE_ORGANISATION_FIELDS = 'UPDATE_ORGANISATION_FIELDS'
export const FETCH_ORGANISATION_FIELDS = 'FETCH_ORGANISATION_FIELDS'
export const FETCH_ORGANISATION_CONDITIONAL_TEXTS = 'FETCH_ORGANISATION_CONDITIONAL_TEXTS'
export const UPDATE_ORGANISATION_CONDITIONAL_TEXTS = 'UPDATE_ORGANISATION_CONDITIONAL_TEXTS'
export const FETCH_ORGANISATION_FIELDS_RESPONSE = 'FETCH_ORGANISATION_FIELDS_RESPONSE'
export const UPDATE_ORGANISATION_FIELDS_RESPONSE = 'UPDATE_ORGANISATION_FIELDS_RESPONSE'
export const SAVE_MEDIA_LIBRARY = 'SAVE_MEDIA_LIBRARY'

const logger = new Logger('organisation action')

// ==================================================================================================
/**
 * retrieves the organisations from the DB and saves them to the store. All the organisation are
 * retrieves where a role for a user can be found in the OrganisationsRoles table.
 */
export const loadMyOrganisations = () => async dispatch => {
  try {
    const organisations = await RestService('get', '/organisation', null, true, [])

    dispatch({
      type: STORE_ORGANISATIONS,
      payload: organisations
    })

    return organisations
  } catch (err) {
    dispatch({
      type: STORE_ORGANISATIONS,
      payload: []
    })
    throw err
  }
}

// ==================================================================================================
/**
 * getting the organisation of a client for a given project
 * @param {string} projectID
 */
export function getClientOrganisation (projectID) {
  return async dispatch => {
    try {
      const organisationData = await RestService('get', `/project/${projectID}/customerOrganisation`)
      dispatch({
        type: STORE_SELECTED_CLIENT_ORGA,
        payload: organisationData
      })
    } catch (err) {
      dispatch({
        type: STORE_SELECTED_CLIENT_ORGA,
        payload: {}
      })
      throw err
    }
  }
}

// ==================================================================================================
/**
 * function retrieves the selected organisation from the database, when a organisation id (string) is
 * provided. If an object is provided, the provided organisation data is saved to the store.
 * @param {object|string} data
 */
export const selectOrganisation = data => async dispatch => {
  if (data === Object(data) && data.organisationID) {
    // We selected a organisation that we already have in store
    dispatch({
      type: STORE_SELECTED_ORGA,
      payload: data
    })
  } else {
    // Else we provide it's ID and get in back
    try {
      const organisationData = await RestService('get', `/organisation/${data}`)
      dispatch({
        type: STORE_SELECTED_ORGA,
        payload: organisationData
      })
    } catch (err) {
      dispatch({
        type: STORE_SELECTED_ORGA,
        payload: {}
      })
      throw err
    }
  }
}

// ==================================================================================================
export const resetSelectedOrganisation = () => ({
  type: STORE_SELECTED_ORGA,
  payload: {}
})

// ==================================================================================================
/**
 * function saves the selected organisation index to the store
 * @param {number} index
 */
export const setSelectedOrganisationIndex = index => ({
  type: SET_SELECTED_ORGANISATION_INDEX,
  payload: index
})

// ==================================================================================================
/**
 * function updates the organisation with the id in the data
 * @param {object} data
 */
export const updateOrganisation = data => async (dispatch, getState) => {
  const savingModal = window.modal.info()

  try {
    savingModal.update({
      title: <Trans>Organizational data is updated</Trans>,
      className: 'savingModal',
      content: (
        <div>
          <p><Trans>Just a moment, your organizational data will be updated.</Trans></p>
          <SyncOutlined spin />
        </div>
      )
    })

    const organisationData = await RestService('put', `/organisation/${data.organisationID}`, data)

    // updating the store wit the new information
    dispatch({
      type: STORE_SELECTED_ORGA,
      payload: { ...(getState().organisation.selectedOrganisation || {}), ...organisationData }
    })

    // closing the saving modal
    savingModal.destroy()

    window.notification.success({
      message: <Trans>Organizational data stored</Trans>,
      description: <Trans>Your organizational data has been successfully saved.</Trans>
    })
  } catch (error) {
    logger.error('error updating the organisation: ', error)

    // closing the saving modal
    savingModal.destroy()
  }
}

// ==================================================================================================
export const saveOrganisation = data => async (dispatch, getState) => {
  let res
  const organisations = [...getState().organisation.myOrganisations]
  if (data.organisationID) {
    const prevData = (getState().organisation.selectedOrganisation || {})
    res = await RestService('put', `/organisation/${data.organisationID}`, { ...prevData, ...data })
    const index = organisations.findIndex(element => element.organisationID === data.organisationID)
    if (index >= 0) { organisations.splice(index, 1) }

    res = { ...prevData, ...data, ...res }
  } else {
    res = await RestService('post', '/organisation', data)
    const user = getState().user.ownProfileData

    res.members = {
      [user.userID]: { ...user, organisationRole: res.role }
    }
    res.teams = {}
  }

  // update store array
  organisations.push(res)
  dispatch({
    type: STORE_ORGANISATIONS,
    payload: organisations
  })

  // saves the new organisation to the selected store
  dispatch({
    type: STORE_SELECTED_ORGA,
    payload: res
  })

  return res
}


// ==================================================================================================
// ==================================================================================================


/** ***************************************************************************
 *                    User management section (company)
 *************************************************************************** */
const updateOrganisationRoles = (organisationRoles, invitedUsers) => async (dispatch, getState) => {
  //  Update users in orga
  await dispatch({
    type: STORE_SELECTED_ORGA,
    payload: {
      ...getState().organisation.selectedOrganisation,
      ...organisationRoles
    }
  })

  /** **********************************************************************
   *              Start by defining some needed teams
   ********************************************************************** */
  let noTeam = {
    userID: '__NoTeam',
    name: <Trans>Company wide</Trans>,
    description: (
      <Trans>These users see files shared company-wide. Optionally, users can be invited directly.</Trans>
    ),
    users: [],
    templateRole: 'NoAccess',
    contractRole: 'NoAccess'
  }

  const adminTeam = {
    userID: '__Admin',
    name: <Trans>Managers</Trans>,
    description: (
      <Trans>
        Managers have access to all playbooks and contracts in your company including all teams.
        They can also configure all company settings.
      </Trans>
    ),
    users: [],
    templateRole: 'FullAccess',
    contractRole: 'FullAccess',
    readOnly: true
  }

  const pendingInvites = {
    userID: '__PendingInvites',
    name: <Trans>Pending invited users</Trans>,
    description: (
      <Trans>
        Users that are not yet part of the organisation because they still not registered.
      </Trans>
    ),
    users: invitedUsers,
    templateRole: 'NoAccess',
    contractRole: 'NoAccess'
  }

  /** **********************************************************************
   *              Loop over users and sort by team
   ********************************************************************** */
  const teamMapping = {
    [adminTeam.userID]: adminTeam,
    [noTeam.userID]: noTeam
  }

  const { members, teams } = getState().organisation.selectedOrganisation
  const userTeams = []

  Object.entries(teams).forEach(([key, value]) => {
    if (key.startsWith('_')) {
      if (key === '__NoTeam') {
        noTeam = { ...noTeam, ...value }
      }
    } else {
      const team = { ...value, users: [] }
      teamMapping[key] = team
      userTeams.push(team)
    }
  })

  Object.values(members).forEach(user => {
    const team = teamMapping[user.organisationRole.team]
    if (team) {
      team.users.push(user)
    } else {
      noTeam.users.push({ ...user, organisationRole: { ...user.organisationRole, team: noTeam.userID } })
    }
  })

  /** **********************************************************************
   *                      Saving to the store
   ********************************************************************** */
  dispatch({
    type: UPDATE_COMPANY_ROLES,
    payload: {
      teams: userTeams,
      otherTeams: [noTeam, adminTeam, ...(pendingInvites.users.length > 0 ? [pendingInvites] : [])],
      teamMapping
    }
  })
}

export const retrieveOrganisationRoles = orgID => async (dispatch, getState) => {
  try {
    const { organisationID } = getState().organisation.selectedOrganisation
    if (orgID || organisationID) {
      //  Fetch
      const { organisationRoles, invitedUsers } = await RestService('GET', `/organisation/${orgID || organisationID}/roles`)

      if (organisationRoles && organisationRoles.members) {
        Object.values(organisationRoles.members).forEach(item => {
          delete organisationRoles.members[item.aliasFor]
        })
      }

      await dispatch(updateOrganisationRoles(organisationRoles, invitedUsers))
    }
  } catch (err) {
    //  Reset
    dispatch({
      type: UPDATE_COMPANY_ROLES,
      payload: { teams: [], otherTeams: [] }
    })
    throw err
  }
}

// /////////////////////////
//  For users
// /////////////////////////
export const addInvite = invite => (dispatch, getState) => {
  const companyRoles = { ...getState().organisation.companyRoles }
  const team = companyRoles.otherTeams[2]
  if (team && team.users) {
    team.users.push(invite)
    dispatch({
      type: UPDATE_COMPANY_ROLES,
      payload: companyRoles
    })
  }
}

export const updateOrganisationUserRole = role => async (dispatch, getState) => {
  const { organisationID, members, teams } = getState().organisation.selectedOrganisation
  const { templateRole, contractRole, team, isTeamLeader } = role
  const res = await RestService('PUT', `/organisation/${organisationID}/roles/${role.userID}`, {
    templateRole, contractRole, team, isTeamLeader
  })

  const invitedUsers = (getState().organisation.companyRoles.otherTeams[2] || {}).users || []
  await dispatch(updateOrganisationRoles(
    {
      members: {
        ...members,
        [role.userID]: {
          ...members[role.userID],
          organisationRole: { ...members[role.userID].organisationRole, ...res }
        }
      },
      teams
    },
    invitedUsers
  ))
}

export const deleteOrganisationUserRole = role => async (dispatch, getState) => {
  const { organisationID, members, teams } = getState().organisation.selectedOrganisation
  await RestService('DELETE', `/organisation/${organisationID}/roles/${role.userID}`)

  const invitedUsers = (getState().organisation.companyRoles.otherTeams[2] || {}).users || []
  delete members[role.userID]
  await dispatch(updateOrganisationRoles({ members, teams }, invitedUsers))
}

// /////////////////////////
//  For teams
// /////////////////////////
export const createOrganisationTeam = name => async (dispatch, getState) => {
  const { organisationID, members, teams } = getState().organisation.selectedOrganisation
  const team = {
    ...(await RestService('POST', `/organisation/${organisationID}/roles`, { name })),
    users: []
  }

  const invitedUsers = (getState().organisation.companyRoles.otherTeams[2] || {}).users || []
  await dispatch(updateOrganisationRoles(
    {
      members,
      teams: { ...teams, [team.userID]: team }
    },
    invitedUsers
  ))
}

export const updateOrganisationTeam = team => async (dispatch, getState) => {
  const { organisationID, members, teams } = getState().organisation.selectedOrganisation
  const { name, templateRole, contractRole } = team
  const res = await RestService('PUT', `/organisation/${organisationID}/roles/${team.userID}`, {
    name, templateRole, contractRole
  })

  const invitedUsers = (getState().organisation.companyRoles.otherTeams[2] || {}).users || []
  await dispatch(updateOrganisationRoles(
    {
      teams: {
        ...teams,
        [team.userID]: {
          ...teams[team.userID],
          ...res
        }
      },
      members
    },
    invitedUsers
  ))
}

export const deleteOrganisationTeam = team => async (dispatch, getState) => {
  const { organisationID, members, teams } = getState().organisation.selectedOrganisation
  await RestService('DELETE', `/organisation/${organisationID}/roles/${team.userID}`)

  const invitedUsers = (getState().organisation.companyRoles.otherTeams[2] || {}).users || []
  delete teams[team.userID]
  await dispatch(updateOrganisationRoles({ teams, members }, invitedUsers))
}


// ==================================================================================================
// ==================================================================================================


/** ***************************************************************************
 *                    User management section (instance)
 *************************************************************************** */
export const generateInstanceRolesActions = (instanceType, instanceID) => {
  const getPath = getState => {
    const { organisationID } = getState().organisation.selectedOrganisation

    return `/organisation/${organisationID}/instance-roles/${instanceType}/${instanceID}`
  }

  const getRoles = () => async (dispatch, getState) => {
    //  { instanceRoles: [], invitedUsers: [] }
    const roles = await RestService('get', getPath(getState))
    if (roles && Array.isArray(roles.instanceRoles)) {
      roles.instanceRoles = roles.instanceRoles.filter(({ role }) => role !== 'NoAccess')
      roles.instanceRoles.sort(compareRoles)
    }

    dispatch({
      type: UPDATE_INSTANCE_ROLES,
      payload: {
        ...getState().organisation.instanceRoles,
        [instanceID]: roles
      }
    })
  }

  const updateRole = (userID, role) => async (dispatch, getState) => {
    const updatedRole = await RestService('put', `${getPath(getState)}/${userID}`, { role })
    const roles = { ...getState().organisation.instanceRoles[instanceID] }
    roles.instanceRoles = [...roles.instanceRoles]

    const index = roles.instanceRoles.findIndex(innerRole => innerRole.userID === userID)
    if (index >= 0) {
      roles.instanceRoles[index] = {
        ...roles.instanceRoles[index], role, ...updatedRole
      }
    } else {
      roles.instanceRoles.push({
        userID, role, ...updatedRole
      })
    }

    dispatch({
      type: UPDATE_INSTANCE_ROLES,
      payload: {
        ...getState().organisation.instanceRoles,
        [instanceID]: roles
      }
    })
  }

  const deleteRole = userID => async (dispatch, getState) => {
    await RestService('delete', `${getPath(getState)}/${userID}`)
    const roles = { ...getState().organisation.instanceRoles[instanceID] }
    roles.instanceRoles = [...roles.instanceRoles]

    const index = roles.instanceRoles.findIndex(role => role.userID === userID)
    if (index >= 0) {
      roles.instanceRoles.splice(index, 1)

      dispatch({
        type: UPDATE_INSTANCE_ROLES,
        payload: {
          ...getState().organisation.instanceRoles,
          [instanceID]: roles
        }
      })
    }
  }

  const addRoleToStore = data => (dispatch, getState) => {
    const roles = { ...getState().organisation.instanceRoles[instanceID] }

    if (data.token) {
      roles.invitedUsers = [...roles.invitedUsers, data]
    } else {
      roles.instanceRoles = [...roles.instanceRoles, data]
    }

    dispatch({
      type: UPDATE_INSTANCE_ROLES,
      payload: {
        ...getState().organisation.instanceRoles,
        [instanceID]: roles
      }
    })
  }

  return {
    getRoles, updateRole, deleteRole, addRoleToStore
  }
}


// ==================================================================================================
// ==================================================================================================


/**
 * The function updates the selected organisation when the user chooses from the dropdown
 * The selected organisation is also saved in the local storage
 * @param organisationID
 * @returns {Function}
 */
export const updateSelectedOrganisation = organisationID => async (dispatch, getState) => {
  const { userID } = getState().user.ownProfileData
  const organisation = getState()
    .organisation
    .myOrganisations
    .filter(org => org.organisationID === organisationID)[0]
  dispatch({
    type: UPDATE_LAWYER_ORGANISATION,
    payload: organisation
  })
  if (typeof (Storage) !== 'undefined') {
    localStorage.setItem(`${userID}-selectedOrganisation`, JSON.stringify(getState().organisation.selectedOrganisation))
  } else {
    const date = new Date()
    date.setTime(date.getTime() + (24 * 60 * 60 * 1000))
    document.cookie = `${userID}-selectedOrganisation=${JSON.stringify(getState().organisation.selectedOrganisation)};expires=${date.toUTCString()};path=/`
  }
}


// ==================================================================================================
/**
 * The function fetches the user's organisation from local storage
 * if no organisation is saved, it is retrieved from the store
 * if the user is not subscribed to a product plan, the subscription is automated here
 * @param userID
 * @returns {Function}
 */
export const loadOrganisationFromLocalStorage = userID => async (dispatch, getState) => {
  let selectedOrganisation

  if (typeof (Storage) !== 'undefined') {
    const data = localStorage.getItem(`${userID}-selectedOrganisation`)
    selectedOrganisation = JSON.parse(data)
    logger.info('organisation data from local storage: ', selectedOrganisation)
  } else {
    selectedOrganisation = decodeURIComponent(document.cookie)
      .match(new RegExp(`${userID}-selectedOrganisation=(.*?)(;|$)`))[1]
    document.cookie = `${userID}-selectedOrganisation=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`
  }

  if (selectedOrganisation) {
    dispatch({
      type: LOAD_ORGANISATION_FROM_LOCAL_STORAGE,
      payload: selectedOrganisation
    })
    return selectedOrganisation
  }
  let myOwnOrganisation
  if (userID !== 'project-manager') {
    myOwnOrganisation = getState()
      .organisation
      .myOrganisations
      .filter(organisation => organisation.owner === userID)[0]
  } else {
    const organisationID = 'org-o2flc9u1jye7i536'
    myOwnOrganisation = await RestService('GET', `/organisation/${organisationID}`)
      .catch(() => [])
  }
  if (myOwnOrganisation && myOwnOrganisation.organisationID) {
    // Save the organisation to the store
    dispatch({
      type: UPDATE_LAWYER_ORGANISATION,
      payload: myOwnOrganisation
    })
    if (typeof (Storage) !== 'undefined') {
      localStorage.setItem(`${userID}-selectedOrganisation`, JSON.stringify(myOwnOrganisation))
    }
  }
  return myOwnOrganisation
}


// ==================================================================================================
// ==================================================================================================


/** ***************************************************************************
 *                    Invite requests
 *************************************************************************** */
export const sendInvitation = formData => RestService('POST', '/user/invite', formData)
export const invitedUserCompleted = token => RestService('POST', `/user/invite/${token}`)

export const getUserInvitedData = (token, skipWhenNoData = false) => async (dispatch, getState) => {
  let { inviteData } = getState().organisation

  if (!inviteData || inviteData.token !== token) {
    const inviteDataTmp = await RestService('GET', `/user/invite/${token}`, null, false, skipWhenNoData ? null : undefined)
    inviteData = {
      ...inviteDataTmp,
      token
    }
    dispatch({
      type: STORE_INVITE_DATA,
      payload: inviteData
    })
  }
  return inviteData
}

export const updateEvents = data => ({
  type: UPDATE_ORGANISATION_EVENTS,
  payload: data
})
export const updateReminders = data => ({
  type: UPDATE_REMINDERS,
  payload: data
})

export const updateInstanceEvents = data => ({
  type: UPDATE_INSTANCE_EVENTS,
  payload: data
})


// ================================================================================================


const userCache = {}
export const fetchExternalUser = userID => async dispatch => {
  const match = /contract-portal\/contracts\/(token-.+)/.exec(window.location.pathname)
  const token = match && match[1]

  if (token) {
    const userDataExtern = await RestService('GET', `/token/${token}/${userID}`, null, false)
    dispatch({
      type: STORE_EXTERNAL_USER,
      payload: userDataExtern
    })
  }
  // eslint-disable-next-line no-cond-assign
  else if (!userCache[userID] && (userCache[userID] = true)) {
    const userData = await RestService('GET', `/user/profile/${userID}`)
    dispatch({
      type: STORE_EXTERNAL_USER,
      payload: userData
    })
  }
}


// ================================================================================================


export const loadComments = (instanceID, instanceType) => async dispatch => {
  const comments = await RestService('GET', `/comments?instanceID=${instanceID}&instanceType=${instanceType}`)
  dispatch({
    type: STORE_INSTANCES_COMMENTS,
    payload: { [instanceID]: comments }
  })
}

export const saveComment = (comment, saveDB = true) => async (dispatch, getState) => {
  if (comment.realDate) { comment.date = comment.realDate }
  const { instanceID, date } = comment
  const { userID, token } = getState().user.ownProfileData

  let res
  if (saveDB) {
    if (!userID && token) {
      res = await RestService(date ? 'PUT' : 'POST', `/token/${token}/comment`, comment, false)
    } else if (date) {
      res = await RestService('PUT', `/comments/${instanceID}/${date}`, comment)
    } else {
      res = await RestService('POST', '/comments', comment)

      const contract = getState().contract.contractEditing
      if (
        res.text && !res.assignedTo
        && instanceID.startsWith('contract-') && contract
        && (!contract.signaturesHolder || !contract.signaturesHolder.pdf)
        && !contract.pdfSigningFile && !contract.fileUrl
      ) {
        res.showOverlay = true
      }
    }
  }

  const comments = [...(getState().organisation.instanceComments[instanceID] || [])]
  if (date) {
    const index = comments.findIndex(
      comm => comm.instanceID === instanceID && comm.date === date
    )
    comments[index] = res || comment
  } else {
    comments.push(res || comment)
  }

  dispatch({
    type: STORE_INSTANCES_COMMENTS,
    payload: { [instanceID]: comments }
  })

  return res || comment
}


export const deleteComment = comment => async (dispatch, getState) => {
  if (comment.realDate) { comment.date = comment.realDate }
  const { instanceID, date } = comment

  const { userID, token } = getState().user.ownProfileData

  if (!userID && token) {
    await RestService('DELETE', `/token/${token}/comment`, { ...comment, date }, false)
  } else {
    await RestService('DELETE', `/comments/${instanceID}/${date}`)
  }

  const comments = [...(getState().organisation.instanceComments[instanceID] || [])].filter(
    comm => comm.instanceID !== instanceID || comm.date !== date
  )

  dispatch({
    type: STORE_INSTANCES_COMMENTS,
    payload: { [instanceID]: comments }
  })
}


// ================================================================================================


export const updateField = (field, saveDB = true) => async dispatch => {
  if (saveDB) {
    const { inputFieldID, version } = await RestService('POST', '/inputfield', { inputField: field })
    dispatch({
      type: UPDATE_ORGANISATION_FIELDS,
      payload: { ...field, inputFieldID, version }
    })
    return { inputFieldID, version }
  }
  await dispatch({
    type: UPDATE_ORGANISATION_FIELDS,
    payload: field
  })
  return field
}

// get all Input Fields if the organisation
export const loadConditionalText = () => async dispatch => {
  const results = await RestService('GET', '/conditional')
  dispatch({
    type: FETCH_ORGANISATION_CONDITIONAL_TEXTS,
    payload: results
  })
}

export const updateConditional = (conditionalText, saveDB = true) => async dispatch => {
  if (saveDB) {
    const { conditionalTextID, version } = await RestService('POST', '/conditional', { conditionalText })
    dispatch({
      type: UPDATE_ORGANISATION_CONDITIONAL_TEXTS,
      payload: { ...conditionalText, conditionalTextID, version }
    })
    return { conditionalTextID, version }
  }
  await dispatch({
    type: UPDATE_ORGANISATION_CONDITIONAL_TEXTS,
    payload: conditionalText
  })
  return conditionalText
}

const checkEntities = (template, templateName, storeName, routeName, actionName, entityIdName) => async (dispatch, getState) => {
  const templateEntities = template[templateName]

  if (Object(templateEntities) === templateEntities) {
    let newEntities = []

    if (template.organisationID === getState().organisation.selectedOrganisation.organisationID) {
      const storeEntities = getState().organisation[storeName]

      await Object.entries(templateEntities).asyncForEach(async ([id, version]) => {
        if (!storeEntities[id] || !storeEntities[id][version]) {
          const data = await RestService('GET', `/${routeName}/${id}?version=${version}`)
          newEntities.push(data)
        }
      })
    } else {
      newEntities = await RestService('GET', `/public/template/${template.templateID}/${routeName}`)
    }

    if (newEntities.length > 0) {
      const storeEntities = { ...getState().organisation[storeName] }

      newEntities.forEach(entity => {
        const id = entity[entityIdName]
        if (!storeEntities[id]) {
          storeEntities[id] = {}
        }
        storeEntities[id][entity.version] = entity
      })

      await dispatch({
        type: actionName,
        payload: storeEntities
      })
    }
  }
}

export const checkConditionals = template => checkEntities(
  template,
  'conditionalTexts',
  'conditionalTexts',
  'conditional',
  'FETCH_ORGANISATION_CONDITIONAL_TEXTS',
  'conditionalTextID'
)

export const checkFields = template => checkEntities(
  template,
  'fields',
  'inputFields',
  'inputfield',
  'FETCH_ORGANISATION_FIELDS',
  'inputFieldID'
)

export const saveContractFieldsInStore = (contractID, fieldsResponse) => (dispatch, getState) => {
  const responses = (getState().organisation.fieldResponses || {})
  return dispatch({
    type: FETCH_ORGANISATION_FIELDS_RESPONSE,
    payload: {
      ...responses,
      [contractID]: {
        ...(responses[contractID] || {}),
        ...fieldsResponse
      }
    }
  })
}

export const loadContractFieldResponses = contractID => async (dispatch, getState) => {
  //  Save network request for that special template
  if (
    getState().contract.contractEditing.contractID === contractID
    && getState().contract.contractEditing.templateID === 'template-blank-pdf-signing'
  ) {
    return {}
  }

  const results = await RestService('GET', `/inputfield/value/${contractID}`)
  const responses = {}

  results.forEach(field => { responses[field.inputFieldID] = field })

  await dispatch(saveContractFieldsInStore(contractID, responses))

  return responses
}

export const batchUpdateContractFields = (fieldsResponse, contractsCollection, saveDB = true) => async (dispatch, getState) => {
  const contract = getState().contract.contractEditing
  const { contractID } = contract
  const newValues = {}

  if (saveDB) {
    await Object.entries(fieldsResponse).asyncForEach(async ([inputFieldID, value]) => {
      const newValue = await RestService('POST', `/inputfield/value/${contractID}`, { inputFieldID, value })
      newValues[inputFieldID] = newValue
    })
  } else {
    Object.entries(fieldsResponse).forEach(([inputFieldID, value]) => {
      newValues[inputFieldID] = { contractID, inputFieldID, value }
    })
  }

  //  Update the contract from the store with new fields
  const prevContract = (await contractsCollection.findOne(contractID).exec().then(toJson)) || contract
  const tmp = {}
  Object.entries(newValues).forEach(([key, val]) => {
    tmp[key] = val.value
  })
  prevContract.fieldsResponse = { ...(prevContract.fieldsResponse || {}), ...tmp }
  prevContract.dateUpdated = new Date().toISOString()
  await saveInStore(contractsCollection, prevContract)

  return dispatch(saveContractFieldsInStore(contractID, newValues))
}


// ================================================================================================


export const loadMediaLibrary = () => async dispatch => {
  const medias = await RestService('GET', '/medias')

  const formmatedmedias = {}
  medias.forEach(media => {
    formmatedmedias[media.mediaID] = media
  })

  dispatch({
    type: SAVE_MEDIA_LIBRARY,
    payload: formmatedmedias
  })
}


export const createMedia = media => async (dispatch, getState) => {
  const res = await RestService('POST', '/medias', media)

  dispatch({
    type: SAVE_MEDIA_LIBRARY,
    payload: { ...getState().organisation.mediaLibrary, [res.mediaID]: res }
  })

  return res
}
export const updateMedia = media => async (dispatch, getState) => {
  const res = await RestService('PUT', `/medias/${media.mediaID}`, media)
  const payload = { ...getState().organisation.mediaLibrary, [res.mediaID]: res }
  if (res.hidden) {
    delete payload[res.mediaID]
  }
  dispatch({
    type: SAVE_MEDIA_LIBRARY,
    payload
  })
  return res
}
export const addSignatureInvite = (contractID, values) => (dispatch, getState) => {
  const roles = getState().organisation.instanceRoles
  const invites = (roles[contractID] || {}).invitedUsers || []
  dispatch({
    type: UPDATE_INSTANCE_ROLES,
    payload: {
      ...roles,
      [contractID]: {
        ...(roles[contractID] || {}),
        invitedUsers: [...invites, values.tokenData || values]
      }
    }
  })
}
