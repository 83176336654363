import { useContext, useEffect } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'

import { ContractContext, TemplateContext } from './ContractEditor/Contexts'
import { DeviceContext } from '../../GlobalContext'


const useContractFillingInitialData = () => {
  const { contract: { contractID, contractName, draftedDate }, updateContract, setSuggestModeEnabled } = useContext<any>(ContractContext)
  const { template } = useContext<any>(TemplateContext)

  //  Create initial data
  const hasTemplate = !!template
  useEffect(() => {
    if (!contractName && hasTemplate) {
      updateContract({ parties: {} }, !window.location.href.includes('/preview'))
    }
    if (contractID && draftedDate) {
      setSuggestModeEnabled(true)
    } else {
      setSuggestModeEnabled(false)
    }
  }, [updateContract, contractName, hasTemplate, setSuggestModeEnabled, contractID, draftedDate])
}

const useContractFillingScroller = () => {
  const isPhone = useContext(DeviceContext) === 'phone'
  const { contract: { contractID } } = useContext<any>(ContractContext)
  const [activeField] = useQueryParam('activeField', StringParam)
  const [activeSection] = useQueryParam('activeSection', StringParam)


  //  Scrolling to the active element
  useEffect(() => {
    const section = document.getElementById(`${contractID}-${activeSection}`)

    if (section) {
      const sectionText = section.querySelector('.sectionText') || section
      const bounding = sectionText.getBoundingClientRect()
      const windowHeight = window.innerHeight || document.documentElement.clientHeight

      //  Scroll only if it is outside
      if (bounding.bottom < 0 || (isPhone ? bounding.top > windowHeight / 2 : bounding.top > windowHeight)) {
        setTimeout(() => sectionText.scrollIntoView({
          behavior: 'smooth',
          block: isPhone ? 'start' : 'center'
        }), 500)
      } else {
        const elms = [
          ...sectionText.querySelectorAll('.inputField.blink'),
          ...sectionText.querySelectorAll('.conditionalText.blink')
        ]
        if (elms.length > 0) {
          setTimeout(() => elms[0].scrollIntoView({
            behavior: 'smooth',
            block: isPhone ? 'start' : 'center'
          }), 500)
        }
      }
    }
  }, [contractID, activeSection, activeField, isPhone])
}

const useContractFillingHelpers = () => {
  useContractFillingInitialData()
  useContractFillingScroller()
}

export default useContractFillingHelpers
