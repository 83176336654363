import React, {Dispatch, SetStateAction, useCallback, useContext, useMemo, useState} from 'react'
import { useParams } from 'react-router'

import { incrementWithGlobalFields } from '@top-legal/contract-assistant'
import { InputField, useFieldsCollection, useList } from '@top-legal/datastore'

import { UserAndCompanyDataContext } from '../../Layouts/Constants'
import ContractUploader from '../../Upload/ContractUploader'
import { emptyMap } from '../../Defaults'
import { userFullName } from '../../Organisations/UserRoleDisplay/UserAvatarList'

interface ContractListingContextProps {
  setShowUploadModal: Dispatch<SetStateAction<boolean>>
  fieldsMap: Map<string, InputField>
}


export const ContractListingContext = React.createContext<ContractListingContextProps>({} as any)


const ContractContainerWrapper: React.FC = ({ children }) => {
  const { lang } = useParams()
  const { user, company: { name } } = useContext(UserAndCompanyDataContext)
  const [showUploadModal, setShowUploadModal] = useState(false)

  //  Compute fields
  const fieldsCollection = useFieldsCollection()
  const [fieldsMap] = useList<InputField, any, Map<string, InputField>>(
    useMemo(() => fieldsCollection.find(), [fieldsCollection]),
    useCallback(fields => {
      const map = new Map<string, InputField>()
      fields.forEach(elm => {
        const field = elm.toJSON()
        map.set(field.inputFieldID, field)
      })
      incrementWithGlobalFields(map, lang, name, userFullName(user))
      return map
    }, [])
  )

  return (
    <ContractListingContext.Provider value={useMemo(() => ({ setShowUploadModal, fieldsMap: fieldsMap || emptyMap }), [fieldsMap])}>
      {children}

      {/** ***********************************************************************
       *                    Upload modal
       ************************************************************************ */}
      <ContractUploader close={() => setShowUploadModal(false)} visible={showUploadModal} />
    </ContractListingContext.Provider>
  )
}

export default ContractContainerWrapper
