import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { useQueryParam } from 'use-query-params'
import { Alert } from 'antd'
import { Trans } from '@lingui/macro'

import {
  Contract,
  ContractingParty,
  saveInStore,
  useContractingPartiesCollection,
  useContractsCollection,
  useDoc,
  useList,
  useSectionsCollection, useTemplatesCollection
} from '@top-legal/datastore'

import { ContractContext } from './Contexts'
import useLoadData from '../../../hooks/useLoadData'
import { GlobalLoadingAlert } from '../../Alert/LoadingAlert'

//  Actions
import { deleteContractByContractID, loadContract, saveContract } from '../redux/ContractActions'
import loadingModalFeedback from '../../Alert/LoadingModalFeedback'
import { ensureTemplateSections, fullLoadContract } from '../Utils'
import { TemplateEntitiesContext } from './TemplateEntitiesContext'
import { emptyObject } from '../../Listing/SearchHelpers'
import { emptyObj, noop } from '../../Defaults'

export const useDeleteContract = (contractID: string) => {
  const { lang } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const contractCollection = useContractsCollection()
  const defaultContract = useMemo(() => ({ contractID } as Contract), [contractID])
  const [contract = defaultContract] = useDoc<Contract>(
    useMemo(() => contractCollection.findOne(contractID), [contractCollection, contractID]),
    useCallback(data => data?.toJSON(), [])
  )

  return useCallback(
    () => loadingModalFeedback({
      loadingTitle: <Trans>Deleting your contract</Trans>,
      successTitle: <Trans>Contract deleted</Trans>,
      errorTitle: <Trans>Failed to delete your contract</Trans>,
      errorDescription: <Trans>An error error occurred while deleting your contract. Please try again later.</Trans>,
      autoSuccessClose: 1000,
      onSuccessClose: () => history.push(`/${lang}/listing/contracts`)
    })(async () => {
      await dispatch(deleteContractByContractID(contract.contractID))
      await saveInStore(contractCollection, { ...contract, deleted: true })
    }),
    [contractCollection, contract, dispatch, history, lang]
  )
}


// ==================================================================================================


// ==================================================================================================


const ContractContextProvider: React.FC = ({ children }) => {
  const [, setInternalUpdate] = useState(0)
  const internalUpdate = useCallback(() => setInternalUpdate(old => old + 1), [])
  const contractStore = useSelector(state => state.contract.contractEditing)
  const initialValues = useSelector(state => state.organisation.fieldResponses[contractStore.contractID] || emptyObj)

  //  Parties mapping
  const partiesCollection = useContractingPartiesCollection()
  const [partiesMapping = emptyObject] = useList<ContractingParty, any, { [partyID: string]: ContractingParty }>(
    useMemo(() => partiesCollection.find().sort({ updatedAt: 'desc' }), [partiesCollection]),
    useCallback(array => {
      const map: any = {}
      array.forEach(party => {
        map[party.partyID] = party
      })
      return map
    }, [])
  )

  const contract = useMemo(() => {
    const fieldsResponse = { ...contractStore.fieldsResponse }

    if (initialValues) {
      Object.keys(initialValues).forEach(fieldID => {
        fieldsResponse[fieldID] = initialValues[fieldID].value
      })
    }

    return {
      ...contractStore,
      fieldsResponse
    }
  }, [contractStore, initialValues])

  const { template } = useContext(TemplateEntitiesContext)
  const dispatch = useDispatch()

  //  Settings
  const [withWizard, setWithWizard] = useState(false)
  const [previewMode, setPreviewMode] = useState(false)
  const [suggestModeEnabled, setSuggestModeEnabled] = useState(false)


  const { entityID } = useParams()
  const contractID = entityID && entityID.startsWith('contract-') && entityID

  const templatesCollection = useTemplatesCollection()
  const sectionsCollection = useSectionsCollection()
  const [loading, error] = useLoadData(
    async () => {
      const tmpContract = await dispatch(loadContract(contractID, templatesCollection))
      const loadedContract = await dispatch(fullLoadContract(tmpContract))
      if (loadedContract.template && loadedContract.contractStatus !== 'signed' && (
        loadedContract.contractStatus !== 'frozen' || !loadedContract.signaturesHolder || !loadedContract.signaturesHolder.pdf
      )) {
        await dispatch(ensureTemplateSections(loadedContract.template, sectionsCollection))
      }
    },
    [contractID],
    !!contractID && (!contract || contract.contractID !== contractID)
  )

  const [editLoading, setEditLoading] = useState(false)
  const updateContract = useCallback(async (values, saveInDb = true) => {
    setEditLoading(true)
    try {
      let res
      if (previewMode || !saveInDb) {
        res = await dispatch(saveContract(values, false))
      } else {
        res = await dispatch(saveContract(values))
      }
      setEditLoading(false)
      return res
    } catch (err) {
      setEditLoading(false)
      console.error('Contract saving error', err)
      throw err
    }
  }, [dispatch, previewMode])

  const deleteContract = useDeleteContract(contractID)
  const [fillingStats, setFillingStats] = useState<{ done: number, total: number }>()

  /**
   * Return loading and error views
   */
  if (error) {
    return <Alert message={<Trans>Failed to load your contract</Trans>} showIcon type='error' />
  }

  /**
   * Return the provider
   */
  return (
    <GlobalLoadingAlert description={<Trans>Loading your contract</Trans>} loading={loading}>
      <ContractContext.Provider
        value={{
          contract: loading ? {} : contract,
          allParties: partiesMapping,
          updateContract,
          deleteContract,
          setEditLoading,
          editLoading,

          //  Contract wizard status
          fillingStats,
          setFillingStats,

          //  Config
          previewMode,
          withWizard,
          setWithWizard,
          setPreviewMode,
          suggestModeEnabled,
          setSuggestModeEnabled,

          //  Hack to listen sectionMap changes that is made down in the components tree
          internalUpdate
        }}
      >
        {/* Add the contract assistant worker in the page when needed */}
        {template.templateID && (!contract.contractStatus || ['filling', 'imported'].includes(contract.contractStatus)) && (
          <iframe
            allow='self'
            className='hiddenWorkerIframe'
            height='0'
            loading='eager'
            referrerPolicy='same-origin'
            sandbox='allow-same-origin allow-scripts'
            src={`/en/contract-assistant/${template.templateID}/worker`}
            width='0'
          />
        )}
        {children}
      </ContractContext.Provider>
    </GlobalLoadingAlert>
  )
}

//  Export withContractContext
// eslint-disable-next-line react/display-name
const withContractContext = <T, >(Component: React.FC<T>): React.FC<T> => props => (
  <ContractContextProvider>
    <Component {...props} />
  </ContractContextProvider>
)

export default withContractContext
