import React, { useContext, useState } from 'react'
import { Button } from 'antd'
import { Trans } from '@lingui/macro'
import MainContainer from '../Layouts/MainLayout/MainContainer'

import mobileView from '../../img/mobileView.svg'
import { DeviceContext } from '../../GlobalContext'

// eslint-disable-next-line react/display-name
const withPhoneLayoutWarning = (Component): React.FC<any> => props => {
  const isPhone = useContext(DeviceContext) === 'phone'
  const [showAnyway, setShowAnyway] = useState(false)
  if (!isPhone || showAnyway) {
    return <Component {...props} />
  }

  return (
    <MainContainer>
      <div className='pageNotCompatible'>
        <img alt='mobileView' src={mobileView} />
        <p><Trans>Some functionalities of our software, like this one, are better used on desktop.</Trans></p>
        <Button
          className='phoneButton'
          onClick={() => setShowAnyway(true)}
          type='default'
        >
          <Trans>Continue anyway</Trans>
        </Button>
      </div>
    </MainContainer>
  )
}

export default withPhoneLayoutWarning
