import React, { useMemo } from 'react'
import { Collection, ListQuery, Model, TypeConfiguration } from '../Types'
import DatabaseContext from '../DatabaseContext'
import { eventsGQLQuery } from './Events'
import { partiesGQLQuery } from './ContractingParties'
import { commentsGQLQuery } from './Comments'

export interface FieldResponse {
  value: any
  updatedBy: string
  updatedAt: string

  //  Threshold approval
  approvedBy?: string
  approvedAt?: string
}

export type SigningMethod = 'sms' | 'email'

export interface ContractPartyInfo {
  isSigning: boolean

  //  When signing
  signatureID?: string
  signingDate?: string
  signingMethod?: SigningMethod
}

export interface Contract extends Model {
  contractID: string
  organisationID: string
  templateID: string
  editingOrgininalTemplateID?: string
  contractName: string
  contractStatus: string
  role: string
  accessType: string
  roles?: any[]

  //  Parties
  // parties: { [partyID: string]: ContractPartyInfo }
  beforeFillingParties?: any
  parties?: any
  externalEntities?: any

  tags?: string[]

  //  Contract process
  draftedDate?: string
  whatToDoNext?: string
  sentOverDate?: string
  lastPublishDate?: string
  publishHistory?: any
  freezeDate?: string
  signedDate?: string
  // filledData?: { [fieldID: string]: FieldResponse }
  fieldsResponse?: any
  signaturesHolder?: any

  //  Historical activity
  dateCreated: string
  createdBy: string
  dateUpdated: string
  updatedBy?: string
  deleted?: boolean
  previewUrl?: string
}

export interface ContractDataset { contracts: Contract[] }

const config: TypeConfiguration<Omit<Contract, 'deleted'>> = {
  key: 'contracts',
  schema: {
    version: 0,
    primaryKey: 'contractID',
    type: 'object',
    properties: {
      accessType: {
        type: 'string'
      },
      beforeFillingParties: {},
      contractID: {
        type: 'string'
      },
      contractName: {
        type: 'string'
      },
      contractStatus: {
        type: 'string'
      },
      createdBy: {
        type: 'string'
      },
      dateCreated: {
        type: 'string'
      },
      dateUpdated: {
        type: 'string'
      },
      draftedDate: {
        type: 'string'
      },
      editingOrgininalTemplateID: {
        type: 'string'
      },
      externalEntities: {},
      fieldsResponse: {},
      freezeDate: {
        type: 'string'
      },
      lastPublishDate: {
        type: 'string'
      },
      organisationID: {
        type: 'string'
      },
      parties: {},
      previewUrl: {
        type: 'string'
      },
      publishHistory: {},
      role: {
        type: 'string'
      },
      roles: {
        items: {},
        type: 'array'
      },
      sentOverDate: {
        type: 'string'
      },
      signaturesHolder: {},
      signedDate: {
        type: 'string'
      },
      tags: {
        items: {
          type: 'string'
        },
        type: 'array'
      },
      templateID: {
        type: 'string'
      },
      updatedBy: {
        type: 'string'
      },
      whatToDoNext: {
        type: 'string'
      }
    },
    required: [
      'contractID',
      'organisationID',
      'templateID',
      'contractName',
      'contractStatus',
      'role',
      'accessType',
      'dateCreated',
      'createdBy',
      'dateUpdated'
    ],
    indexes: ['dateUpdated']
  }
}

export const useContractsCollection = (): Collection<Contract> => React.useContext(DatabaseContext)[config.key]
export const useContractsListBaseQuery = (): ListQuery<Contract> => {
  const collection = useContractsCollection()
  return useMemo(() => collection.find().where('deleted').ne(true), [collection])
}

export const contractsGQLQuery = (lastSyncDate: string, nextToken: string): string => `
{
  Get {
    contracts(lastUpdatedAt: "${lastSyncDate}", nextToken: "${nextToken}") {
      items {
        contractID
        organisationID
        templateID
        dealID
        editingOrgininalTemplateID
        contractName
        contractStatus
        role
        accessType
        beforeFillingParties
        parties
        externalEntities
      
        # Contract process
        draftedDate
        whatToDoNext
        sentOverDate
        lastPublishDate
        publishHistory
        freezeDate
        signedDate
      
        # Historical activity
        dateCreated
        createdBy
        dateUpdated
        deleted
      
        # Nested resolvers
        fieldsResponse {
          items {
            inputFieldID
            value
          }
        }
        previewUrl
        events ${eventsGQLQuery}
        comments ${commentsGQLQuery}
        allParties ${partiesGQLQuery}
        roles {
          userID
          organisationID
          role
        }
      }
      nextToken
    }
  }
}`

export default config
