import React from 'react'
import { Trans } from '@lingui/macro'
import { TeamOutlined } from '@ant-design/icons'
import InstanceUsersContextProvider from './Context/InstanceUsersContextProvider'

import RenderContractAvatars from './TobBarAvatars/RenderContractAvatars'
import PartiesCollapse from './PartiesCollapse'
import useGroups, { UseGroupsProps } from './Context/useGroups'

/**
 *
 * This component renders the toolbox that contains the parties for a given contract and the funcionality
 * to add new parties to the contract. The RenderConractAvatars produces a portal to show avatars on the
 * top of the page, so that user can switch between the internal and external view.
 */
const config: UseGroupsProps = { filterInternFromExtern: true }
const UserManagementToolbox: React.FC<{ contractID: string }> = () => (
  <>
    <RenderContractAvatars />
    <PartiesCollapse groups={useGroups(config)} />
  </>
)

/** **********************************************************************
 *                  Export a component factory
 ********************************************************************** */
export default (contractID: string) => ({
  key: 'users',
  className: 'userManagementToolbox',
  text: <Trans>User rights</Trans>,
  icon: <TeamOutlined />,
  // eslint-disable-next-line react/display-name
  Component: props => (
    <InstanceUsersContextProvider instanceID={contractID} instanceType='contract'>
      <UserManagementToolbox {...props} />
    </InstanceUsersContextProvider>
  )
})
