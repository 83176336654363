import React, { useContext, useMemo, useState } from 'react'
import { t, Trans } from '@lingui/macro'
import { Alert, Button, Form, Input } from 'antd'
import { useDispatch } from 'react-redux'
import { InfoCircleOutlined } from '@ant-design/icons'

import { useParams } from 'react-router'
import withPhoneLayoutWarning from '../SharedComponents/withPhoneLayoutWarning'
import { withUserData } from '../Layouts/AuthenticatedPage'
import { UserAndCompanyDataContext } from '../Layouts/Constants'
import { employeesOptions, industryOptions } from '../Template/_TemplateHelperFunctions'

//  Components
import MainContainer from '../Layouts/MainLayout/MainContainer'
import { GoogleAutoCompleteAddress, Select, SwitchInput } from '../SharedComponents/FormComponents'
import CustomFormWrapper from '../SharedComponents/CustomFormWrapper'
import ImageUploader from '../SharedComponents/FormComponents/ImageUploader'

//  Actions
import { updateOrganisation } from '../Organisations/redux/OrganisationsActions'

//  Styles
import './Settings.scss'


// ==================================================================================================
// ==================================================================================================

const OrganisationSettings = () => {
  const { user, company } = useContext(UserAndCompanyDataContext)
  const dispatch = useDispatch()
  const { lang } = useParams()
  const [loading, setLoading] = useState(false)

  // ==================================================================================================

  const handleSubmit = async values => {
    setLoading(true)
    try {
      await dispatch(updateOrganisation({ ...company, ...values }))
      setLoading(false)
    } catch (err) {
      console.error(err)
      setLoading(false)
      throw err
    }
  }

  // ==================================================================================================

  return (
    <MainContainer
      topbarContent={(
        <div className='topbarMainContent'>
          <InfoCircleOutlined className='headerIcon' twoToneColor='#3DBD7D' />
          <h1 className='title'><Trans>Organisations settings</Trans></h1>
        </div>
      )}
    >
      {
        (company.members[user.userID] && company.members[user.userID].organisationRole.team === '__Admin') ? (
          <div className='settingsPage'>
            <CustomFormWrapper className='settingsForm' onSubmit={handleSubmit}>
              {form => (
                <>
                  <fieldset>
                    <legend><Trans>Invoice address</Trans></legend>
                    <Form.Item
                      initialValue={company.name}
                      name='name'
                      rules={[{
                        required: true,
                        type: 'string',
                        message: t`Please provide the company name`
                      }]}
                    >
                      <Input className='simple-line' placeholder={t`Company name`} />
                    </Form.Item>

                    <GoogleAutoCompleteAddress form={form} initialValue={company} required />
                  </fieldset>

                  <fieldset>
                    <legend><Trans>Other company details</Trans></legend>
                    <Form.Item
                      initialValue={company.vatID}
                      name='vatID'
                    >
                      <Input className='simple-line' placeholder={t`VAT-ID (optional)`} />
                    </Form.Item>
                    <Form.Item
                      initialValue={company.commercialID}
                      name='commercialID'
                    >
                      <Input className='simple-line' placeholder={t`Commercial register number (optional)`} />
                    </Form.Item>
                    <Form.Item
                      initialValue={company.employees}
                      name='employees'
                      rules={[{ required: true }]}
                    >
                      <Select className='simple-line' items={employeesOptions[lang]} placeholder={t`Number of employees`} />
                    </Form.Item>
                    <Form.Item
                      initialValue={company.industry}
                      name='industry'
                      rules={[{ required: true }]}
                    >
                      <Select className='simple-line' items={industryOptions[lang]} placeholder={t`Industry sector`} />
                    </Form.Item>
                  </fieldset>

                  {!window.IS_TRANSFERINITIATIVE && (
                    <fieldset>
                      <legend><Trans>Other company settings</Trans></legend>
                      <Form.Item
                        className='topLegalComunityPlaybookChoice'
                        initialValue={typeof company.topLegalComunityPlaybook === 'boolean' ? company.topLegalComunityPlaybook : true}
                        name='topLegalComunityPlaybook'
                      >
                        <SwitchInput
                          placeholder={t`Would you like to show "Global Playbooks" (top.legal and community playbooks) in the menu on the left side?`}
                        />
                      </Form.Item>
                    </fieldset>
                  )}

                  <fieldset>
                    <div className='styleCategory'>
                      <h2><Trans>Company logo (optional) </Trans></h2>
                      <Form.Item
                        initialValue = {company.companyLogo}
                        name='companyLogo'
                      >
                        <ImageUploader
                          accept='.svg, .png, .jpg'
                          className='logoUploader'
                          filePrefix='CompanyLogo'
                          placeholder={<Trans>Upload your company logo</Trans>}
                          storageLevel='protected'
                        />
                      </Form.Item>
                    </div>
                  </fieldset>
                  <fieldset>
                    <div className='styleCategory'>
                      <h2><Trans>Video overview (optional)</Trans></h2>
                      <Form.Item
                        initialValue={company.invitePosterKey}
                        name='invitePosterKey'
                      >
                        <ImageUploader
                          accept='.svg, .png, .jpg'
                          className='logoUploader'
                          filePrefix='PosterImage'
                          placeholder={<Trans>Upload your company logo</Trans>}
                          storageLevel='protected'
                        />
                      </Form.Item>
                    </div>
                  </fieldset>
                  <Form.Item>
                    <Button
                      className='lastButton'
                      ghost
                      htmlType='submit'
                      loading={loading}
                      style={{ marginTop: 40 }}
                      type='primary'
                    >
                      <Trans>Save</Trans>
                    </Button>
                  </Form.Item>
                </>
              )}
            </CustomFormWrapper>
          </div>
        ) : (
          <Alert
            message={<Trans>You are not allowed to manage this organisation.</Trans>}
            showIcon
            style={{ margin: '2rem' }}
            type='error'
          />
        )
      }
    </MainContainer>
  )
}

const Wrapped = props => {
  const Component = useMemo(() => withPhoneLayoutWarning(withUserData(OrganisationSettings)), [])
  return <Component {...props} />
}

export default Wrapped
