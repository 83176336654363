import React, { useMemo, useState } from 'react'

import { Template, FieldsMapping, useFieldsMapping, CondsMapping, useCondsMapping } from '@top-legal/datastore'

import useTemplateFromRedux from './useTemplateFromRedux'
import { emptyObject } from '../../Listing/SearchHelpers'

interface TemplateEntitiesContextProps {
  template: Template
  inputFields: FieldsMapping
  conditionalTexts: CondsMapping
  entityLoading: boolean
}

export const TemplateEntitiesContext = React.createContext<TemplateEntitiesContextProps>(undefined as any)

const TemplateEntitiesContextProvider: React.FC = ({ children }) => {
  //  Build fields, conds & template
  const [inputFields = emptyObject, inputLoading] = useFieldsMapping()
  const [conditionalTexts = emptyObject, condLoading] = useCondsMapping()
  const template = useTemplateFromRedux(inputFields, conditionalTexts)
  const entityLoading = inputLoading || condLoading

  const context = useMemo<TemplateEntitiesContextProps>(
    () => ({
      template, inputFields, conditionalTexts, entityLoading
    }),
    [template, inputFields, conditionalTexts, entityLoading]
  )

  return <TemplateEntitiesContext.Provider value={context}>{children}</TemplateEntitiesContext.Provider>
}

//  Export withTemplateEntitiesContext
// eslint-disable-next-line react/display-name
const withTemplateEntitiesContext = <T, >(Component: React.FC<T>): React.FC<T> => props => (
  <TemplateEntitiesContextProvider>
    <Component {...props} />
  </TemplateEntitiesContextProvider>
)

export default withTemplateEntitiesContext
