/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useCallback, useMemo, useState } from 'react'
import { t, Trans } from '@lingui/macro'
import { Button, Empty, Progress, Tabs } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Bar } from 'react-chartjs-2'
import moment from 'moment'
import './ClosedContractRateStyles.scss'

const ClosedContractRate = ({ data: contractsData }) => {
  const [history, setHistory] = useState(6)

  const labels = useMemo(() => {
    const tmp = []
    for (let counter = 0; counter < history; counter++) {
      tmp.push(moment().subtract(history - counter - 1, 'month').format('MMM-YY'))
    }
    return tmp
  }, [history])

  const getConversionRate = useCallback((month, year) => {
    let contractClosed = 0
    let contractDrafted = 0
    contractsData.forEach(contract => {
      const dateCreated = new Date(contract.dateCreated)
      const dateSigned = contract.signedDate ? new Date(contract.signedDate) : null
      if (dateCreated.getMonth() === month && dateCreated.getFullYear() === year) {
        contractDrafted += 1
      }
      if (dateSigned && dateSigned.getMonth() === month && dateSigned.getFullYear() === year) {
        contractClosed += 1
      }
    })
    return { contractClosed, contractDrafted }
  }, [contractsData])

  const chartDataset = useMemo(() => {
    const contractDrafted = []
    const contractClosed = []
    const conversionRate = []
    // eslint-disable-next-line no-plusplus
    for (let counter = 0; counter < history; counter++) {
      const date = moment().subtract(history - counter - 1, 'month')
      const datas = getConversionRate(parseInt(date.format('M'), 10) - 1, parseInt(date.format('YYYY'), 10))
      contractDrafted.push(datas.contractDrafted)
      contractClosed.push(datas.contractClosed)
      conversionRate.push(datas.contractDrafted === 0 ? 0 : datas.contractClosed / datas.contractDrafted)
    }
    return {
      contractDrafted, contractClosed, conversionRate
    }
  }, [getConversionRate, history])

  const date = new Date()
  const currentMonthStats = getConversionRate(date.getMonth(), date.getFullYear())

  const dataForChart = {
    labels,
    datasets: [{
      label: t`Conversion Rate`,
      type: 'line',
      data: chartDataset.conversionRate,
      fill: false,
      borderColor: '#EC932F',
      backgroundColor: '#EC932F',
      pointBorderColor: '#EC932F',
      pointBackgroundColor: '#EC932F',
      pointHoverBackgroundColor: '#EC932F',
      pointHoverBorderColor: '#EC932F',
      yAxisID: 'rate'
    },
    {
      type: 'bar',
      label: t`Drafted Contracts`,
      data: chartDataset.contractDrafted,
      fill: false,
      backgroundColor: '#5678a4',
      borderColor: '#5678a4',
      hoverBackgroundColor: '#5678a4',
      hoverBorderColor: '#5678a4',
      yAxisID: 'contracts'
    },
    {
      type: 'bar',
      label: t`Closed Contracts`,
      data: chartDataset.contractClosed,
      fill: false,
      backgroundColor: '#3dbd7d',
      borderColor: '#3dbd7d',
      hoverBackgroundColor: '#3dbd7d',
      hoverBorderColor: '#3dbd7d',
      yAxisID: 'contracts'
    }]
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'label'
    },
    elements: {
      line: {
        fill: false
      }
    },
    animation: {
      duration: 1000,
      easing: 'easeInCubic'
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false
          },
          labels
        }
      ],
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'contracts',
          gridLines: {
            display: false
          },
          labels: {
            show: true
          },
          scaleLabel: {
            display: true,
            labelString: '#Contracts'
          },
          ticks: {
            beginAtZero: true
          }
        },
        {
          type: 'linear',
          display: true,
          position: 'right',
          id: 'rate',
          labels: {
            show: true
          },
          scaleLabel: {
            display: true,
            labelString: 'rate in %'
          }
        }
      ]
    }
  }

  return (
    <div className='closedContractRate'>
      <Tabs defaultActiveKey='current'>
        <Tabs.TabPane key='current' style={{ height: 200 }} tab='Current Month'>
          {currentMonthStats.contractClosed === 0 && <Empty className='noData' description={<Trans>No contract has been signed this month so far.</Trans>} />}
          {currentMonthStats.contractClosed !== 0 && (
            <div className='currentClosingRate'>
              <div className='closingRateAvg'>
                <Progress
                  format={percent => `${percent}%`}
                  percent={Math.floor(currentMonthStats.contractClosed * 100 / currentMonthStats.contractDrafted)}
                  strokeColor={{
                    '0%': '#108ee9',
                    '100%': '#87d068'
                  }} type='circle'
                  width={80}
                />
                <h4><Trans>Closing Rate of Contracts</Trans></h4>
              </div>
              <div className='draftedContracts'>
                <h4><Trans>{currentMonthStats.contractDrafted} Contracts Drafted</Trans></h4>
                <p className='description'><Trans>Average of {(currentMonthStats.contractDrafted * 7 / date.getDate()).toFixed(2)} drafted per week</Trans></p>
              </div>
              <div className='closedContracts'>
                <h4><Trans>{currentMonthStats.contractClosed} Contracts Closed</Trans></h4>
                <p className='description'><Trans>Average of {(currentMonthStats.contractClosed * 7 / date.getDate()).toFixed(2)} closed per week</Trans></p>
              </div>
            </div>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane key='history' tab='History'>
          <div className='historyChart'>
            {/** currently we ignore the load more button */}
            {false && (
              <Button
                className='loadMore'
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  const nHistory = history
                  if (nHistory < 15) {
                    setHistory(nHistory + 3)
                  }
                }}
                shape='circle'
                size='small'
                type='ghost'
              />
            )}
            <Bar
              data={dataForChart}
              options={options}
            />
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default {
  key: 'closingRate',
  title: <Trans>Contract Closing Rate</Trans>,
  Component: ClosedContractRate,
  width: 4,
  height: 6
}
