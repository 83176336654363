import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { Button, ButtonProps } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { Section } from '@top-legal/datastore'
import { Delta, SectionEditor } from '@top-legal/editor'
import { TemplateContext } from '../../Contexts'
import { IdPrefixContext } from '../../../ContractDiplay/ContractLayout'
import { PublishModalContext } from '../../../ContractPublish/ContractPublishPage'
import { ContractEditorSectionProps } from './Types'
import LoadingFeedback from '../../../../Alert/LoadingFeedback'
import SectionTitle from './SectionTitle'
import ContractEditorSectionContext from './ContractEditorSectionContext'
import SectionExplanations from './SectionExplanations'
import { ClauseRendering } from './Clause'


export const SectionsContext = React.createContext<Map<string, Section>>(undefined as any)


const ButtonLoading: React.FC<ButtonProps> = ({ onClick, ...props }) => {
  const [loading, setLoading] = useState(false)

  const newClick = useCallback(async (...args) => {
    setLoading(true)
    try {
      await (onClick as any)?.(...args)
    } catch {}
    setLoading(false)
  }, [onClick])

  return <Button {...props} loading={loading} onClick={newClick} />
}


const ContractEditorSection: React.FC<ContractEditorSectionProps & { section: Section }> = ({
  sectionID, section,
  path: completePath, title, titlePrefix = '',
  addAboveTitle, addAbove,
  addBellow, addBellowTitle,
  addInside, addInsideTitle,
  deleteSection
}) => {
  const idPrefix = useContext<string>(IdPrefixContext)
  const { activePartsComments } = useContext(PublishModalContext)
  const { readOnly } = useContext<any>(TemplateContext)
  const [activeField] = useQueryParam('activeField', StringParam)


  //  Extract the root key before giving down the path
  const [rootKey, path] = useMemo(() => {
    const arr = [...completePath]
    return [arr.shift(), arr]
  }, [completePath])


  /** ******************************************************************
   *            Some section state
   ****************************************************************** */
  const [resetRef, setResetRef] = useState(0)
  const [saving, setSaving] = useState(false)
  const [initialDeltas, setInitialDeltas] = useState<Delta | undefined>(undefined)

  const { useContractEditorActions } = useContext(ContractEditorSectionContext)
  const {
    updateSection, updateContent, floatingButtons, onEditingBlur, onSectionDismount
  } = useContractEditorActions(rootKey, path, section, setSaving, setInitialDeltas, setResetRef)
  useEffect(() => onSectionDismount, [onSectionDismount])
  const [isEmptyContent, setIsEmptyContent] = useState(false)


  /** ******************************************************************
   *            Additional rendering vars
   ****************************************************************** */
  const shadow = 255 - Math.min(0.03 * (path.length + 2) * 255, 255)
  const shadowColor = `rgb(${shadow}, ${shadow}, ${shadow})`
  const background = 255 - Math.min(0.03 * (path.length) * 255, 255)
  const backgroundColor = `rgb(${background}, ${background}, ${background})`

  //  Compute class properties
  const isDynamicSection = section && section.isDynamicSection && section.field
  const isActive = section && isDynamicSection && activeField === section.field
  const className = useMemo(() => {
    const classes = ['slateSection']
    if (readOnly) { classes.push('readOnly') }
    if (isActive) { classes.push('blink-outline') }

    const redlineComments = activePartsComments[sectionID.replace(/template-.+?-/, '')]
    if (redlineComments && redlineComments.comments && Object.keys(redlineComments.comments).length > 0) {
      classes.push('hasDeltas')
    }

    return classes.join(' ')
  }, [activePartsComments, isActive, readOnly, sectionID])

  const { clauseID } = (section as any).toJSON?.() ?? section as any

  return (
    <div
      className={className}
      id={`${idPrefix}${section.sectionID}`}
      style={readOnly ? {
        paddingLeft: `${Math.max(0, path.length - 1)}rem`
      } : {
        boxShadow: `0 -4px 6px -3px ${shadowColor}, 0 4px 6px -3px ${shadowColor}`,
        backgroundColor
      }}
    >
      <LoadingFeedback loading={saving} />
      <SectionExplanations section={section} />

      {/* Section title */}
      <SectionTitle
        deleteSection={deleteSection}
        isEmptyContent={!!clauseID || isEmptyContent}
        path={path}
        section={section}
        title={title}
        titlePrefix={titlePrefix}
        updateSection={updateSection}
      >
        {/* Button to add above the section */}
        {!readOnly && addAbove && (
          <div className='titleButtonWrapper'>
            <ButtonLoading
              className='sectionAddAboveButton noBorder'
              ghost
              icon={<PlusOutlined />}
              onClick={addAbove}
              size='small'
              type='primary'
            >
              {addAboveTitle}
            </ButtonLoading>
          </div>
        )}
      </SectionTitle>

      {/* Section content */}
      <div
        className='sectionText'
        onBlur={onEditingBlur}
        style={readOnly ? undefined : { boxShadow: `0 0 6px ${backgroundColor} inset` }}
      >
        {clauseID ? (
          <ClauseRendering clauseID={clauseID} noOverlay={readOnly} />
        ) : (
          <SectionEditor
            dateUpdated={section.dateUpdated || ''}
            initialDeltas={initialDeltas}
            resetRef={resetRef}
            saveUpdate={updateContent}
            sectionID={section.sectionID}
            setIsEmptyContent={setIsEmptyContent}
            yDoc={section.yDoc as string}
          />
        )}
        <div className='sectionFloatingButtons'>
          {floatingButtons || null}
        </div>
      </div>

      {/* Buttons to add section bellow */}
      {!readOnly && (
        <div className='slateSectionBottomButtonsWrapper'>
          {addBellow && (
            <ButtonLoading
              className='sectionAddBelowButton noBorder'
              ghost
              icon={<PlusOutlined />}
              onClick={addBellow}
              size='small'
              type='primary'
            >
              {addBellowTitle}
            </ButtonLoading>
          )}
          {addInside && (
            <ButtonLoading
              className='sectionAddInsideButton noBorder'
              ghost
              icon={<PlusOutlined />}
              onClick={addInside}
              size='small'
              type='primary'
            >
              {addInsideTitle}
            </ButtonLoading>
          )}
        </div>
      )}
    </div>
  )
}

const ContractEditorSectionWrapper: React.FC<ContractEditorSectionProps> = props => {
  const idPrefix = useContext<string>(IdPrefixContext)
  const { readOnly, sectionMapping } = useContext<any>(TemplateContext)
  const [activeField] = useQueryParam('activeField', StringParam)

  const section = useContext(SectionsContext).get(props.sectionID)

  //  The section is disabled if it is not present into the mapping
  //  Note1: In template editor none are disabled and during contract flow this is managed by useComputeSectionMapping
  //  Note2: It uses the section shortID as key of the map
  const isDisabledSection = !sectionMapping[props.sectionID.split('-').pop() as string]

  /** ******************************************************************
   *        Returning an empty section in case of contract
   *                and not fullfiled condition
   ****************************************************************** */
  //  Safe guard
  if (!props.sectionID || !section) {
    return null
  }

  const isDynamicSection = section.isDynamicSection && section.field
  const isActive = isDynamicSection && activeField === section.field
  if (isDisabledSection) {
    return (
      <div
        className={`slateSection ${readOnly ? 'readOnly' : ''} ${isActive ? 'disabledSection blink' : ''}`}
        id={`${idPrefix}${section.sectionID}`}
      />
    )
  }

  return <ContractEditorSection {...props} section={section} />
}

export default React.memo(ContractEditorSectionWrapper)

