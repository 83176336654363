import React from 'react'
import { Card, InputProps } from 'antd'
import useMediaQueries from '../../hooks/useMediaQueries'
import SwipableContainer from './SwipableContainer'

import './styles/CardSelectorStyles.scss'

export interface CardValue {
  value: string,
  title: React.ReactNode,
  description: React.ReactNode,
  image?: string,
  cover?: React.ReactNode
}

export interface CardSelectorProps extends Omit<InputProps, 'onChange'> {
  cards: CardValue[],
  onChange?: (val: string) => void
}

const CardSelector: React.FC<CardSelectorProps> = ({
  cards = [],
  value,
  onChange,
  disabled,
  className = ''
}) => {
  const currentCardIndex = Math.max(cards.findIndex(card => card.value === value), 0)
  const currentCard = cards[currentCardIndex]
  const isSmall = useMediaQueries(['(max-width: 768px)'], [true], false)
  const Component = isSmall ? SwipableContainer : 'div'

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Component autoHeight className={`cardSelector ${disabled ? 'disabled' : ''} ${className}`} defaultIndex={currentCardIndex} disableSelection>
      {cards.map(card => (
        <Card
          key={card.value}
          bordered={false}
          className={`cardSelector-card ${card.value === currentCard.value ? 'selected' : ''}`}
          cover={(card.image && <img alt={card.value} src={card.image} />) || card.cover}
          hoverable={!disabled}
          onClick={(!disabled && onChange) ? () => onChange(card.value) : undefined}
          size='small'
          title={<span>{card.title}</span>}
        >
          {card.description}
        </Card>
      ))}
    </Component>
  )
}

export default CardSelector
