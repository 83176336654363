import React, { useCallback, useContext, useState } from 'react'
import { Button } from 'antd'
import { UserAddOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'

import { ExternalContext } from '../ExternalContext'
import { ContractContext } from '../../Contract/ContractEditor/Contexts'

import { UserAvatar, UserAvatarByID } from '../../Organisations/UserRoleDisplay/UserAvatarList'
import ExternalInviteForm from './ExternalInviteForm'
import InfoBox from '../../Alert/InfoBox'
import InstanceUsersContextProvider
  from '../../Contract/ContractEditor/Sidebar/SidebarToolboxes/UserManagement/Context/InstanceUsersContextProvider'
import PartiesCollapse from '../../Contract/ContractEditor/Sidebar/SidebarToolboxes/UserManagement/PartiesCollapse'
import useGroups
, { UseGroupsProps } from '../../Contract/ContractEditor/Sidebar/SidebarToolboxes/UserManagement/Context/useGroups'
import { emptyArr } from '../../Defaults'


const config: UseGroupsProps = { noInvite: true }
const ExternalUserManagementToolbox: React.FC = () => {
  const { tokenData } = useContext(ExternalContext)
  const { contract } = useContext<any>(ContractContext)
  const [showInviteForm, setShowInviteForm] = useState(false)
  const closeInvite = useCallback(() => setShowInviteForm(false), [])

  /* In case of just the external view preview for internal users, the avatar by ID calls the user/profile route for externals,
   which works fine but shows a data not found error for the user, Which is not Ideal */
  const MyAvatar = tokenData.fake ? UserAvatar : UserAvatarByID

  return (
    <>
      <div>
        {/* {tokenData.contact && ( */}
        {/*  <Portal cssSelector='#topbarExtraContent'> */}
        {/*    <div className='contractMembersWrapper'> */}
        {/*      <MyAvatar */}
        {/*        key={tokenData.contractOwner.userID} */}
        {/*        className='intern' */}
        {/*        noModal */}
        {/*        onClick={openUserManagement} */}
        {/*        size='2.3rem' */}
        {/*        userID={tokenData.contractOwner.userID} */}
        {/*      /> */}
        {/*      {externRoles.map(({ userID }) => ( */}
        {/*        <MyAvatar */}
        {/*          key={userID} */}
        {/*          className='extern' */}
        {/*          noModal */}
        {/*          onClick={openUserManagement} */}
        {/*          size='2.3rem' */}
        {/*          userID={userID} */}
        {/*        /> */}
        {/*      ))} */}
        {/*    </div> */}
        {/*  </Portal> */}
        {/* )} */}
        {contract.contractStatus !== 'signed' && !showInviteForm && (
          <div className='buttonWrapper'>
            <Button block onClick={evt => { evt.stopPropagation(); setShowInviteForm(true) }} type='primary'>
              <Trans>Invite user</Trans>
            </Button>
          </div>
        )}
        {showInviteForm && (
          <div onClick={evt => evt.stopPropagation()}>
            <ExternalInviteForm onCancel={closeInvite} onFinish={closeInvite} />
          </div>
        )}
        <PartiesCollapse
          groups={useGroups(config)}
        />
        {!contract.signaturesHolder && contract.contractStatus !== 'frozen' && (
          <InfoBox>
            <p><Trans>If another person is signing the contract deactivate your signature and invite a new user</Trans></p>
          </InfoBox>
        )}
      </div>
    </>
  )
}

export default {
  key: 'users',
  className: 'userManagementToolbox',
  text: <Trans>Share contract</Trans>,
  icon: <UserAddOutlined />,
  Component: props => (
    <InstanceUsersContextProvider
      instanceID={useContext<any>(ContractContext).contract.contractID}
      instanceType='contract'
      internRoles={emptyArr}
    >
      <ExternalUserManagementToolbox {...props} />
    </InstanceUsersContextProvider>
  )
}
