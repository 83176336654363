import React from 'react'
import { Editor, Range, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'
import { getAbove, someNode, unwrapNodes, wrapNodes } from '@udecode/plate-core'
import { ELEMENT_LINK } from '@udecode/plate-link'
import { ToolbarButton, ToolbarButtonProps } from '@udecode/plate-ui-toolbar'
import { CheckOutlined, CloseOutlined, DeleteOutlined, LinkOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import { Button, Form } from 'antd'

import { CustomForm, InputLink } from '@top-legal/form-components'

import { useFocusedEditor } from '../../Helpers'

interface LinkValues { url: string }

const noop = () => {}

const updateLinkAtSelection = (
  editor: Editor,
  selection: Range | null,
  value: LinkValues
) => {
  if (selection) {
    //  If our cursor is inside an existing link, but don't have the text selected, select it now
    if (Range.isCollapsed(selection)) {
      const linkLeaf = Editor.leaf(editor, selection)
      const [, path] = linkLeaf

      const linkNode = getAbove(editor, { match: { type: ELEMENT_LINK }, at: path })
      if (linkNode) {
        Transforms.setNodes(editor, { url: value.url }, { at: linkNode[1] })
        selection = { anchor: Editor.start(editor, linkNode[1]), focus: Editor.end(editor, linkNode[1]) }
        Transforms.setSelection(editor, selection)
      }
    } else {
      //  Or wrap in a new link node
      wrapNodes(editor, {
        type: ELEMENT_LINK,
        ...value,
        children: []
      }, {
        at: selection,
        split: true
      })

      const { path } = Range.end(selection)
      path[path.length - 1] += 1
      Transforms.setSelection(editor, { anchor: { path, offset: 0 } })
    }
  }
}

//  TODO: Convert to typescript
const InputLink_ = InputLink as React.ElementType<any>
const openLinkModal = (
  { url = '' }: LinkValues,
  onComplete: ((values: LinkValues) => void) = noop,
  onCancel: (() => void) = noop,
  canRemove: boolean,
  remove: ((modal: { destroy: () => void }) => void) = noop
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const modal = window.modal.info({
    className: 'smallModal slateLinkModal',
    title: <h1><Trans>Insert a link</Trans></h1>,
    content: (
      <CustomForm
        onSubmit={async values => {
          modal.destroy()
          onComplete(values as LinkValues)
        }}
      >
        <Form.Item
          initialValue={url}
          label={<Trans>Link url</Trans>}
          name='url'
          rules={[{
            required: true, message: <Trans>Please provide a valid link</Trans>
          }]}
        >
          <InputLink_ className='simple-line' />
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button.Group>
            {canRemove ? (
              <Button danger ghost icon={<DeleteOutlined />} onClick={() => remove(modal)}>
                <Trans>Remove</Trans>
              </Button>
            ) : (
              <Button
                danger ghost icon={<CloseOutlined />}
                onClick={() => {
                  modal.destroy()
                  onCancel()
                }}
              >
                <Trans>Cancel</Trans>
              </Button>
            )}
            <Button htmlType='submit' icon={<CheckOutlined />} type='primary'>
              <Trans>Save</Trans>
            </Button>
          </Button.Group>
        </div>
      </CustomForm>
    )
  })
}

const ToolbarLink: React.FC<ToolbarButtonProps> = props => {
  const currentEditor = useFocusedEditor()
  const isLink = currentEditor && someNode(currentEditor, { match: { type: ELEMENT_LINK } })

  return (
    <ToolbarButton
      {...props}
      active={isLink}
      onMouseDown={currentEditor ? async evt => {
        evt.preventDefault()

        const initialValues: LinkValues = { url: '' }

        const { selection } = currentEditor
        const linkNode = getAbove(currentEditor, { match: { type: ELEMENT_LINK } })
        if (linkNode) {
          initialValues.url = linkNode[0].url as string
        } else if (!selection || Range.isCollapsed(selection)) {
          (window as any).notification.info({
            message: <Trans>Select a text to insert a link</Trans>
          })
          return
        }

        ReactEditor.deselect(currentEditor)
        openLinkModal(initialValues, values => {
          updateLinkAtSelection(currentEditor, selection, values)
        }, noop, !!linkNode, modal => {
          if (selection) {
            unwrapNodes(currentEditor, { at: selection, match: { type: ELEMENT_LINK } })
            modal.destroy()
          }
        })
      } : undefined}
    />
  )
}

export default <ToolbarLink key='slateLinkFormat' icon={<LinkOutlined />} />
