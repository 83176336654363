import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { Trans } from '@lingui/macro'
import moment from 'moment'
import { Select, Tag } from 'antd'

//  Editor library
import { getSlateContent, RenderOnlyEditor } from '@top-legal/editor'

import { isOrganisationFieldsContext } from '../../Contract/ContractEditor/Sidebar/SidebarToolboxes/SectionSearchToolbox'
import { promises } from '../../../PreLoadData'
import { TemplateContext } from '../../Contract/ContractEditor/Contexts'
import TemplateTagging, { tagStyle, weights } from '../../Template/TemplateTagging'
import { InstanceRenderingProps, UseSearchReturn } from '../Types'
import { Select as CustomSelect } from '../../SharedComponents/FormComponents/index'
import { UserAvatarByID, UserFullName } from '../../Organisations/UserRoleDisplay/UserAvatarList'
import { generateCompanyEntity, generateUserEntity } from '../../Template/_TemplateHelperFunctions'

import { hasKeyWordsFactory, slateContentHasKeywords } from '../../Listing/SearchHelpers'

import './ClauseSearchInstanceStyles.scss'


const TemplateTagging_ = TemplateTagging as any
const isEmpty: any = () => false


const ClauseInstance: React.FC<InstanceRenderingProps> = ({ instance, Highlight, search }) => {
  const { lang } = useParams()

  //  Compute template link & name
  const templates = useSelector(state => (state.dataTable['all-templates'] || {}).data)
  const templateName = useMemo(() => {
    const template = Array.isArray(templates) && templates.find(({ templateID }) => templateID === instance.templateID)
    return template && <Link className='link' to={`/${lang}/templates/${template.templateID}`}><b>{template.name}</b></Link>
  }, [instance.templateID, lang, templates])

  //  Compute user that edited the section
  const user = useMemo(() => {
    if (instance.updatedBy) {
      return (
        <b>
          <UserAvatarByID autoSide noTooltip userID={instance.updatedBy} />
          <UserFullName userID={instance.updatedBy} />
        </b>
      )
    }
    return null
  }, [instance.updatedBy])

  return (
    <div className='instanceRendering cardLike clauseRendering'>
      <div>
        <div className='instanceTitle'>
          <h3><Highlight text={instance.name} /></h3>
        </div>
        <div className='instanceDescription'>
          <TemplateTagging_ noEdit value={{ tags: instance.tags || [], weight: instance.weight || 'empty' }} />
          {Array.isArray(instance.content) && (
            <RenderOnlyEditor
              // searchString={search}
              nodes={instance.content}
            />
          )}
          {instance.dateUpdated && (
            <p className='lastEdit'><Trans>{user} edited in {templateName} on {moment(instance.dateUpdated).calendar()}.</Trans></p>
          )}
        </div>
      </div>
    </div>
  )
}


//


//


const empty = []
const useClauseSearchInstance = (): UseSearchReturn => {
  /** *******************************************************************
   *                      Some redux state
   ******************************************************************* */
  const templateContext = useContext<any>(TemplateContext)
  const inputFields = useSelector(state => state.organisation.inputFields)
  const conditionalTexts = useSelector(state => state.organisation.conditionalTexts)
  const sectionsStore = useSelector(state => state.template.sections)


  /** *******************************************************************
   *                      Load guard
   ******************************************************************* */
  const [loading, setLoading] = useState(!promises['all-templates'] || !promises['all-templates'].done)
  useEffect(() => {
    (async () => {
      try {
        if (promises['all-templates']) {
          await promises['all-templates']
        }
      } catch (err) {
        console.error('Error when fetching sections', err)
      }
      setLoading(false)
    })()
  }, [])


  /** *******************************************************************
   *                 Compute sections arry + tags
   ******************************************************************* */
  const [sections, allTags] = useMemo(() => {
    if (loading) {
      return [empty, empty]
    }

    if (Object(sectionsStore) !== sectionsStore) {
      return [empty, empty]
    }

    const array: any[] = []
    const tags: any = {}

    //  Filter useless special sections
    Object.entries<any>(sectionsStore).forEach(([key, value]) => {
      value = { ...value }
      if (!key.startsWith('template-styling')) {
        if (value.yDoc) {
          value.content = getSlateContent(value.yDoc)
        }

        if (Array.isArray(value.content) && !isEmpty(value.content)) {
          array.push(value)

          if (Array.isArray(value.tags)) {
            value.tags.forEach(tag => {
              tags[tag] = (tags[tag] || 0) + 1
            })
          }
        }
      }
    })

    if (array.length === 0) {
      return [empty, empty]
    }
    return [array, Object.entries<number>(tags).sort((elmA, elmB) => elmA[1] - elmB[1]).map(elm => elm[0])]
  }, [loading, sectionsStore])


  /** *******************************************************************
   *                      Additional filters
   ******************************************************************* */
  const [tags, setTags] = useState<string[]>([])
  const tagFilter = (
    <div className='filter'>
      <h5><Trans>Tags:</Trans></h5>
      <Select
        className='tagSelect simple-line'
        mode='tags'
        onChange={val => setTags(val)}
        value={tags}
      >
        {allTags.map(tag => <Select.Option key={tag} value={tag}>{tag}</Select.Option>)}
      </Select>
    </div>
  )

  const [weight, setWeight] = useState('empty')
  const weightFilter = (
    <div className='filter'>
      <h5><Trans>Rate:</Trans></h5>
      <CustomSelect
        className='weightSelect simple-line'
        items={weights}
        label={item => (item.text ? (
          <Tag color={item.color} style={tagStyle as any}>{item.text}</Tag>
        ) : null)}
        noSearch
        onChange={setWeight}
        value={weight}
      />
    </div>
  )

  const filtersRef = useRef<any>()
  filtersRef.current = (
    <div className='additionalFilters sectionFilters'>
      {tagFilter}
      {weightFilter}
    </div>
  )


  /** *******************************************************************
   *                      Search function
   ******************************************************************* */
  const ref = useRef<any>()
  ref.current = { sections, tags, weight }
  const filterInstances = useCallback((search: string) => {
    if (ref.current.sections.length === 0) {
      return []
    }

    const split = search.toLowerCase().trim().split(' ')
    if (split.length === 1 && !split[0] && ref.current.weight === 'empty' && ref.current.tags.length === 0) {
      return [...ref.current.sections]
    }

    return ref.current.sections.filter(section => {
      if (ref.current.tags.length > 0 && (!Array.isArray(section.tags) || ref.current.tags.some(tag => !section.tags.includes(tag)))) {
        return false
      }

      if (ref.current.weight && ref.current.weight !== 'empty' && ref.current.weight !== section.weight) {
        return false
      }

      const hasKeyWords = hasKeyWordsFactory([...split])

      return hasKeyWords(section.name) || (Array.isArray(section.content) && slateContentHasKeywords(section.content, hasKeyWords))
    })
  }, [])


  /** *******************************************************************
   *                Template context and return
   ******************************************************************* */
  const contextRef = useRef<any>()
  const { selectedOrganisation } = useSelector(state => state.organisation)
  const user = useSelector(state => state.user.ownProfileData)
  const userName = useMemo<string>(() => `${user.firstName} ${user.lastName}`, [user.firstName, user.lastName])
  const companyName = useMemo<string>(() => selectedOrganisation && selectedOrganisation.name, [selectedOrganisation])
  const { lang } = useParams()
  contextRef.current = templateContext.template
    ? templateContext
    : {
      template: {
        fields: {
          ...generateCompanyEntity('__my_company', companyName),
          ...generateUserEntity('__my_user', userName, () => lang)
        },
        conditionalTexts: {}
      },
      inputFields,
      conditionalTexts
    }

  return useMemo<UseSearchReturn>(() => ({
    category: 'clauses',
    filterInstances,
    loading,
    loadingText: <Trans>Loading your clauses</Trans>,
    InstanceRendering: props => (
      <isOrganisationFieldsContext.Provider value={true}>
        <TemplateContext.Provider value={contextRef.current}>
          <ClauseInstance {...props} />
        </TemplateContext.Provider>
      </isOrganisationFieldsContext.Provider>
    ),
    getID: instance => instance.sectionID,
    AdditionalFilters: () => filtersRef.current
  }), [filterInstances, loading])
}

export default useClauseSearchInstance
