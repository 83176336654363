/**
 * @author Fabien HUDIER
 *
 * Infinite Scrolling is a component that take care of rendering a huge list of items via virtualize list
 * to avoid to slow down the browser. There is a special boolean attribute autoHeight that could be use to
 * have a list of unknown item height to the cost of computing it at the runtime so prefer used a fixed
 * height when you can have a uniform item height.
 *
 * The Infinite Scrolling come with the feature to dynamically call loadMore function if provided and when
 * the user reach the bottom of the list. The hasMore boolean can tell the component if there is data that
 * can be loaded.
 */

import React, { useCallback, useMemo, useState } from 'react'
import { Spin } from 'antd'
import { Virtuoso, GroupedVirtuoso, GroupedVirtuosoProps } from 'react-virtuoso'

export interface InfiniteScrollingBaseProps {
  hasMore?: boolean
  loadMore?: () => Promise<any>
}

export interface InfiniteScrollingSingleProps<T> extends InfiniteScrollingBaseProps {
  items: T[]
  rowKey: (item: T) => string
  RenderItem: React.FC<{ item: T, index: number }>
}

export interface InfiniteScrollingGroupsProps<T> extends InfiniteScrollingBaseProps {
  groups: {
    items: T[]
    rowKey: (item: T) => string
    RenderItem: React.FC<{ item: T, index: number }>
    RenderGroup: React.FC
  }[]
}

const style: any = {
  height: '100%',
  width: '100%',
  position: 'relative',
  overflow: 'hidden'
}


const InfiniteScrollingWrapper: React.FC<InfiniteScrollingBaseProps & { children: any }> = ({
  hasMore, loadMore, children
}) => {
  const [loading, setLoading] = useState(false)

  return (
    <div style={style}>
      <div
        style={useMemo(() => ({
          ...style,
          filter: (hasMore && loading) ? 'blur(8px)' : ''
        }), [hasMore, loading])}
      >
        {React.cloneElement(children, {
          ...children.props,
          endReached: useMemo(() => ((hasMore && loadMore) ? (() => {
            setLoading(true)
            loadMore().finally(() => setLoading(false))
          }) : undefined), [hasMore, loadMore])
        })}
      </div>
      {hasMore && loading && (
        <Spin
          size='large'
          style={{
            position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 10
          }}
        />
      )}
    </div>
  )
}


const InfiniteScrolling = <T, >({
  items, rowKey, RenderItem, ...props
}: React.PropsWithChildren<InfiniteScrollingSingleProps<T>>): React.ReactElement => {
  //  Memoize our React.FC for better performances
  const Item = useMemo(() => React.memo(RenderItem), [RenderItem])

  return (
    <InfiniteScrollingWrapper {...props}>
      <Virtuoso<T, {}>
        computeItemKey={useCallback((index, item) => rowKey(item), [rowKey])}
        data={items}
        increaseViewportBy={200}
        // overscan={4}
        itemContent={useCallback((index, item) => <Item index={index} item={item} />, [Item])}
      />
    </InfiniteScrollingWrapper>
  )
}

type FormattedGroups = Pick<InfiniteScrollingGroupsProps<any>['groups'][0], 'rowKey' | 'RenderGroup' | 'RenderItem' | 'items'> & { firstIndex: number }
export const InfiniteScrollingGrouped = <T, >({
  groups, ...props
}: React.PropsWithChildren<InfiniteScrollingGroupsProps<T>>): React.ReactElement => {
  //  Memoize our React.FC for better performances
  const virtuosoProps = useMemo<Pick<GroupedVirtuosoProps<T, {}>, 'data' | 'computeItemKey' | 'itemContent' | 'groupContent' | 'groupCounts'>>(() => {
    const groupCounts: number[] = []
    const formattedGroups: FormattedGroups[] = []

    let size = 0
    groups.forEach(({ items, rowKey, RenderGroup, RenderItem }) => {
      if (items.length > 0) {
        const firstIndex = size
        size += items.length
        groupCounts.push(items.length)

        formattedGroups.push({
          firstIndex,
          rowKey,
          RenderGroup: React.memo(RenderGroup),
          RenderItem: React.memo(RenderItem),
          items
        })
      }
    })

    //  We do have a weird issue with virtuoso in grouped mode it does call with the index = data.length (meaning non existing element)
    const empty: any = {
      rowKey: () => '__NO_ITEM__', RenderItem: () => null, firstIndex: 0, items: {}
    }
    const globalIndexToGroup = (globalIndex: number): FormattedGroups => {
      let groupIndex = 0
      let currentGroup = formattedGroups[groupIndex]

      while (groupIndex < formattedGroups.length && globalIndex >= currentGroup.firstIndex + groupCounts[groupIndex]) {
        groupIndex += 1
        currentGroup = formattedGroups[groupIndex]
      }

      return currentGroup || empty
    }

    const computeItemKey: GroupedVirtuosoProps<T, {}>['computeItemKey'] = globalIndex => {
      //  A virtuozo guard
      if (globalIndex >= size) { return undefined as any }

      const { rowKey, firstIndex, items } = globalIndexToGroup(globalIndex)
      return rowKey(items[globalIndex - firstIndex])
    }

    const itemContent: GroupedVirtuosoProps<T, {}>['itemContent'] = globalIndex => {
      //  A virtuozo guard
      if (globalIndex >= size) { return null }

      const { RenderItem, firstIndex, items } = globalIndexToGroup(globalIndex)
      const index = globalIndex - firstIndex

      return <RenderItem index={index} item={items[index]} />
    }

    const groupContent: GroupedVirtuosoProps<T, {}>['groupContent'] = groupIndex => {
      const { RenderGroup } = formattedGroups[groupIndex]

      return <RenderGroup />
    }

    return {
      computeItemKey, itemContent, groupContent, groupCounts
    }
  }, [groups])

  return (
    <InfiniteScrollingWrapper {...props}>
      <GroupedVirtuoso {...virtuosoProps} overscan={4} />
    </InfiniteScrollingWrapper>
  )
}

export default InfiniteScrolling
