import React, { useCallback, useMemo, useRef } from 'react'
import uniqid from 'uniqid'
import { dequal } from 'dequal'
import EditorWrapper, { EditorWrapperProps } from './EditorWrapper'
import Editor, { EditorProps } from './Editor'
import { DefaultSlateContent } from '../core'

const noop = () => {} // eslint-disable-line

interface FormEditorProps {
  value?: EditorProps['value']
  onChange?: EditorProps['onChange']
  inlinesOnly?: EditorWrapperProps['inlinesOnly']
}

const FormEditor: React.FC<FormEditorProps> = ({
  value = DefaultSlateContent,
  onChange = noop,
  inlinesOnly
}) => {
  const timeoutRef = useRef<NodeJS.Timeout>()
  const onChangeRef = useRef(onChange)
  onChangeRef.current = onChange

  const prevValRef = useRef(value)
  const lastValRef = useRef(value)
  const save = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = undefined
    }
    if (!dequal(prevValRef.current, lastValRef.current)) {
      onChangeRef.current(lastValRef.current)
    }
  }, [])

  const wrappedChange = useCallback(nodes => {
    lastValRef.current = nodes

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = undefined
    }

    timeoutRef.current = setTimeout(save, 1000)
  }, [save])

  //  Reset editorID when the value changed externally (fix the initial value to come delayed)
  const editorRef = useRef(uniqid())
  useMemo(() => {
    if (!dequal(value, lastValRef.current) && !dequal(value, prevValRef.current)) {
      editorRef.current = uniqid()
      lastValRef.current = value
    }
    prevValRef.current = value
  }, [value])

  return (
    <div className='formEditor' onBlur={save}>
      <EditorWrapper inlinesOnly={inlinesOnly} noSearch>
        <Editor editorID={editorRef.current} onChange={wrappedChange} value={value} />
      </EditorWrapper>
    </div>
  )
}

export default FormEditor
