import React, { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Trans } from '@lingui/macro'
import uniqid from 'uniqid'

import { Section } from '@top-legal/datastore'

import RestService from '../../../RestService'
import { SAVE_CONTRACT } from '../../Contract/redux/ContractActions'
import { SAVE_SECTIONS, UPDATE_TEMPLATE } from '../../Template/redux/TemplateActionsType'
import { STORE_INSTANCES_COMMENTS } from '../../Organisations/redux/OrganisationsActions'
import { ContractContext, TemplateContext } from '../../Contract/ContractEditor/Contexts'
import { SectionsContext } from '../../Contract/ContractEditor/editorRefactoredPart/sectionComponents/ContractEditorSection'
import { defaultCounterparty, defaultUser, UserAndCompanyDataContext } from '../../Layouts/Constants'

/** **************************************************************
 *       Some custom redux actions for internal side
 ************************************************************** */
type CreateAndShowExternalView = () => void

export const useCreateAndShowExternalView = (): CreateAndShowExternalView | undefined => {
  const { company: organisation } = useContext(UserAndCompanyDataContext)
  const { allParties, contract: {
    contractID, contractName, parties, createdBy, lastPublishDate, publishHistory
  } } = useContext<any>(ContractContext)
  const { template } = useContext<any>(TemplateContext)
  const sectionMap = useContext(SectionsContext)
  const comments = useSelector(state => state.organisation.instanceComments[contractID])


  //  First find a party to simulate
  const [counterParty, counterPartyKey, counterPartyObj] = useMemo(() => {
    let counterParty_
    let partyKey_
    let partyObj_
    if (Object(parties) === parties) {
      Object.entries<any>(parties).some(([partyKey, party]) => {
        const partyID = party && (party.partyID || party.contactID || party)
        if (typeof partyID === 'string' && (partyID.startsWith('contact-') || partyID.startsWith('party-'))) {
          counterParty_ = allParties[partyID]
          partyKey_ = partyKey
          partyObj_ = party
          return counterParty_
        }
        return false
      })
    }
    return [counterParty_, partyKey_, partyObj_]
  }, [allParties, parties])


  //  Then build the click function
  const onClick = useCallback<CreateAndShowExternalView>(() => {
    //  Verify we can simulate an external user
    if (!counterParty) {
      (window as any).notification.info({
        message: <Trans>Cannot find a counterparty</Trans>,
        description: <Trans>We cannot open the external deal room without a external counterparty on the contract.</Trans>
      })
      return
    }

    //  Build the sections
    const sections = {}
    const loadSection = ({ sectionID, subSections = null }) => {
      const section = sectionMap.get(sectionID) || { sectionID, name: '' } as Section
      sections[sectionID] = {
        sectionID,
        name: section.name,
        yDoc: section.yDoc,
        history: (Array.isArray((section as any).history) ? (section as any).history : []).map(({ changedDate, delta, type }) => ({
          changedDate,
          delta,
          ...(type === 'suggestion' ? { type } : {})
        })).filter(({ changedDate }) => changedDate <= lastPublishDate)
      }

      if (Array.isArray(subSections)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return { sectionID, subSections: subSections.map(loadSection) }
      }

      return { sectionID }
    }

    const formattedTemplate: any = {
      templateID: template.templateID,
      name: template.name,
      lang: template.lang,
      schemaVersion: template.schemaVersion
    }
    formattedTemplate.header = (loadSection({ sectionID: typeof template.header === 'string' ? template.header : `${template.templateID}-header` })).sectionID
    formattedTemplate.sections = Array.isArray(template.sections) ? template.sections.map(loadSection) : []
    formattedTemplate.footer = (loadSection({ sectionID: typeof template.footer === 'string' ? template.footer : `${template.templateID}-footer` })).sectionID
    formattedTemplate.annexes = Array.isArray(template.annexes) ? template.annexes.map(loadSection) : []

    //  Build internal data
    const internalData: any = {
      contractID,
      contractName,
      publishHistory: (Array.isArray(publishHistory) ? publishHistory : []).map(({ date }) => date),
      template: formattedTemplate,
      sections,
      companyName: organisation.name
    }

    //  Build the comments
    const internalComments = comments.map(comm_ => {
      if (comm_.date <= lastPublishDate && comm_.organisationID === '__EVERYONE__') {
        const comm = { ...comm_ }
        const isMember = organisation.members[comm.userID]

        if (comm.rate) {
          delete comm.description

          //  We cleanup rating data from a redline with a rate and that is from internal side
          if (isMember) {
            delete comm.rate
            delete comm.managedBy
            delete comm.managedDate
          } else {
            comm.managedBy = defaultCounterparty.userID
            if (comm.rate !== 'rejected') {
              comm.rate = 'accepted'
            }
          }

          if (!isMember) {
            comm.externalyManagedBy = defaultUser.userID
          } else {
            delete comm.externalRate
            delete comm.externalyManagedBy
            delete comm.externalyManagedDate
          }
        }

        if (!isMember) {
          comm.userID = defaultUser.userID
        } else {
          comm.userID = defaultCounterparty.userID
        }

        return comm
      }
      return null
    }).filter(comm => comm)

    //  Build a fake token
    const token = uniqid('token-')
    localStorage.setItem(`${token}-data`, JSON.stringify({
      token,
      partyKey: counterPartyKey,
      expireIn: Date.now() + 3600 * 1000 * 24 * 60,
      internalData,
      internalComments,
      organisation,
      externalRole: 'redlining',
      contact: counterParty,
      parties: {
        [counterPartyKey]: counterPartyObj
      },
      contractOwner: { userID: createdBy },
      fake: true
    }))

    //  Open a new tab
    window.open(`${window.location.origin}${window.location.pathname}/external-view/${token}`, '_blank')
  }, [
    counterParty, counterPartyKey, counterPartyObj, comments, contractID, contractName, createdBy,
    lastPublishDate, organisation, publishHistory, sectionMap,
    template.annexes, template.footer, template.header, template.lang, template.name,
    template.schemaVersion, template.sections, template.templateID
  ])

  return counterParty ? onClick : undefined
}


/** **************************************************************
 *            Some custom redux actions
 ************************************************************** */
export const loadContractFromToken = (token: string, parties: any, internalData?: any, internalComments?: any) => async dispatch => {
  const {
    contractID,
    contractName,
    publishHistory,
    template,
    sections,
    covers,
    additionalDocuments
  } = internalData || await RestService('get', `/token/${token}/contract`, null, false)
  const comments = internalComments || await RestService('get', `/token/${token}/comments`, null, false)

  dispatch({
    type: SAVE_SECTIONS,
    payload: sections
  })
  dispatch({
    type: SAVE_CONTRACT,
    payload: {
      contractID,
      contractName,
      contractStatus: 'drafted',
      publishHistory,
      token,
      parties,
      covers,
      additionalDocuments,
      fieldsResponse: {}
    }
  })
  if (!template.fields) {
    template.fields = {}
  }
  dispatch({
    type: UPDATE_TEMPLATE,
    payload: template
  })
  dispatch({
    type: STORE_INSTANCES_COMMENTS,
    payload: { [contractID]: comments }
  })
}
