/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useMemo } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'

import { ContractEditorContextProvider } from '@top-legal/editor'

import { Section, useBatchGet, useSectionsCollection } from '@top-legal/datastore'
import { UserAndCompanyDataContext } from '../../../Layouts/Constants'
import { ContractContext, TemplateContext } from '../Contexts'
import { ExternalContext } from '../../../ExternalViews/ExternalContext'
import { SectionsContext } from './sectionComponents/ContractEditorSection'
import useEntityGetters, { ProvideFieldsResponseScope } from './utils/useEntityGetters'
import useOnTemplateEntityClick from './utils/useOnTemplateEntityClick'
import useEntityHandlers from './useEntityHandlers'


export type UseSectionsFromStoreReturn = [Map<string, Section> | undefined, boolean]

const mapCloner = <T1, T2 extends { toJSON: any }>(map: Map<T1, T2>): Map<T1, T2> => {
  const entries = [...map.entries()]
  const newEntries: typeof entries = []
  entries.forEach(dat => {
    if (dat[1]) {
      dat[1] = dat[1].toJSON()
      newEntries.push(dat)
    }
  })
  return new Map(newEntries)
}
const useSectionsFromRxDBStore = (sectionIDs: string[]): UseSectionsFromStoreReturn => {
  const sectionsCollection = useSectionsCollection()
  return useBatchGet<Section>(sectionsCollection, sectionIDs, mapCloner)
}


/** ******************************************************************
 *           The contract editor context provider
 ****************************************************************** */
const emptyMap = new Map()
export const ProvideContractEditorContext: React.FC<{ useSectionsFromStore?: (sectionIDs: string[]) => UseSectionsFromStoreReturn }> = ({
  useSectionsFromStore = useSectionsFromRxDBStore,
  children
}) => {
  const { user: { userID } } = useContext(UserAndCompanyDataContext)
  const { template } = useContext<any>(TemplateContext)
  const { onEntityAdded, onEntityRemoved } = useEntityHandlers()
  const { lang, header, sections, footer, annexes, obfuscationMapping } = template
  const { contract: { contractID, fieldsResponse, draftedDate, contractStatus }, internalUpdate } = useContext<any>(ContractContext)
  const [activeField] = useQueryParam('activeField', StringParam)
  const { tokenData } = useContext(ExternalContext)
  const currentUserID = (tokenData?.contactID) || userID
  const redliningEnabled = (draftedDate && !['frozen', 'signed'].includes(contractStatus))
    || (tokenData && !tokenData.requestSigning)
    || window.location.href.includes('/publish')

  //  Trigger saving each 2sec on template editor but 1sec for contract redlining
  const debounceSavingDelay = contractID === '__preview__' ? 2000 : 500

  const [sectionsMap = emptyMap, loading] = useSectionsFromStore(useMemo(() => {
    const arr: string[] = [header, footer]

    const processSections = tmp => Array.isArray(tmp) && tmp.forEach(({ sectionID, subSections }) => {
      arr.push(sectionID)
      processSections(subSections)
    })
    processSections(sections)
    processSections(annexes)

    return arr
  }, [header, sections, footer, annexes]))


  //  Bind section map to the template for the iterators
  useMemo(() => {
    if (process.env.NODE_ENV === 'development') {
      console.info('Bind sectionMap to template', template, sectionsMap)
    }

    Object.defineProperty(template, 'sectionMap', {
      enumerable: false,
      configurable: true,
      value: sectionsMap
    })

    if (typeof internalUpdate === 'function') {
      internalUpdate()
    }
  }, [internalUpdate, sectionsMap, template])


  return (
    <SectionsContext.Provider value={sectionsMap}>
      <ProvideFieldsResponseScope scope={fieldsResponse}>
        <ContractEditorContextProvider
          ProvideFieldsResponseScope={ProvideFieldsResponseScope}
          contractLang={lang}
          currentField={activeField}
          debounceSavingDelay={debounceSavingDelay}
          obfuscationMapping={obfuscationMapping}
          onEntityAdded={onEntityAdded}
          onEntityRemoved={onEntityRemoved}
          redliningEnabled={redliningEnabled}
          useEntityGetters={useEntityGetters}
          useOnTemplateEntityClick={useOnTemplateEntityClick}
          userID={currentUserID}
        >
          {children}
        </ContractEditorContextProvider>
      </ProvideFieldsResponseScope>
    </SectionsContext.Provider>
  )
}


/** ******************************************************************
 *           Component wrapper
 ****************************************************************** */
const withContractEditorContext = <Props, >(Component: React.ComponentType<Props>): React.FC<Props> => {
  const Wrapped: React.FC<Props> = props => (
    <ProvideContractEditorContext>
      <Component {...props} />
    </ProvideContractEditorContext>
  )
  Wrapped.displayName = Component.displayName

  return Wrapped
}

export default withContractEditorContext
