import React from 'react'
import './styles/SimpleLoadingSpinner.scss'

const SimpleLoadingSpinner: React.FC<{ theme: 'green' | 'white' | 'darkBlue' }> = ({ theme }) => (
  <div className={`simpleLoadingSpinner ${theme}`}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
)

export default SimpleLoadingSpinner
