import { Button, Form } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import React, {useContext, useMemo, useState} from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'

import { GoogleFontSelector } from '@top-legal/react-helpers'

import { StickyMainContent } from '../../../Layouts/MainLayout/MainContainer'
import { LayoutSidebarWidthContext } from '../../../Layouts/LayoutSidebar'
import SignaturePhoneDE from '../../../../img/SignaturePhoneDE.svg'
import SignaturePhoneEN from '../../../../img/SignaturePhoneEN.svg'
import SignatureEmailDE from '../../../../img/SignatureEmailDE.svg'
import SignatureEmailEN from '../../../../img/SignatureEmailEN.svg'
import PinCodeInput from '../Sidebar/SidebarToolboxes/PinCodeInput'
import loadingModalFeedback from '../../../Alert/LoadingModalFeedback'
import { confirmSigning, requestSigningPin } from '../../redux/ContractActions'
import CustomFormWrapper, { requiredRules } from '../../../SharedComponents/CustomFormWrapper'
import { PhoneInput } from '../../../SharedComponents/FormComponents'
import { ContractContext } from '../Contexts'
import { UserAndCompanyDataContext } from '../../../Layouts/Constants'
import Portal from '../../../SharedComponents/Portal'
import { Signature } from './Utils/Types'
import { userFullName } from '../../../Organisations/UserRoleDisplay/UserAvatarList'
import { ScrollbarWidthContext } from '../../../../GlobalContext'
import {toJson, useContractingPartiesCollection, useDoc} from "@top-legal/datastore";

interface SigningDrawerProps {
  signature: Signature
  visible: boolean
  close: () => void
  onFinishSigning?: () => void
}

const capitalize = (str, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase())


const SigningPhoneDrawer: React.FC<SigningDrawerProps> = ({ signature, visible, close, onFinishSigning }) => {
  const { lang } = useParams()
  const { user: internalUser } = useContext(UserAndCompanyDataContext)
  const partiesCollection = useContractingPartiesCollection()
  const [party] = useDoc(
    useMemo(() => partiesCollection.findOne(signature.partyID), [partiesCollection, signature.partyID]),
    toJson
  )
  const user = party || internalUser
  const [phoneAsked, setPhoneAsked] = useState(0)
  const dispatch = useDispatch()
  const { contract } = useContext<any>(ContractContext)
  const signatureType = contract.signaturesHolder?.signingFlow || 'email'

  //  Handwriting signature part
  const [selectedHandwriting, setSelectedHandwriting] = useState<string>()
  const username = capitalize(userFullName(user), true)
  const needHandwriting = contract.signaturesHolder?.withHandwritingSignature

  const width = useContext(LayoutSidebarWidthContext)
  const right = `${useContext(ScrollbarWidthContext)}px`

  let content: React.ReactNode = null
  if (phoneAsked) {
    content = (
      <PinCodeInput
        email={signature.email}
        initialSignatureType={signatureType}
        onPhoneChange={phoneNumber => {
          signature.phoneNumber = phoneNumber
          setPhoneAsked(val => val + 1)
        }}
        phoneNumber={signature.phoneNumber}
        sendPinCode={type => dispatch(requestSigningPin({ ...signature, type, lang }))}
        validatePinCode={(passCode, codeToken) => {
          if (needHandwriting && !selectedHandwriting) {
            throw new Error('Missing handwriting signature')
          }

          return loadingModalFeedback({
            loadingTitle: <Trans>Accept and sign contract</Trans>,
            successTitle: <Trans>Contract signed</Trans>,
            errorTitle: <Trans>Cannot sign the contract</Trans>,
            autoSuccessClose: 2000
          })(async () => {
            await dispatch(confirmSigning(signature.signatureID, {
              passCode,
              codeToken,
              lang,
              selectedHandwriting
            }))
            onFinishSigning?.()
          })
        }}
      >
        {needHandwriting && (
          <div className='handwritingSignature'>
            <h4><Trans>Please select your signature</Trans></h4>
            <GoogleFontSelector
              className='simple-line'
              dropdownClassName='handwritingSelectDropdown'
              onChange={val => setSelectedHandwriting(val)}
              placeholder={username}
              previewText={username}
              value={selectedHandwriting}
            />
          </div>
        )}
      </PinCodeInput>
    )
  } else if (signatureType === 'phone') {
    content = (
      <>
        <h4>
          <Trans>Sign this contract securely with your mobile number.</Trans>
        </h4>

        <CustomFormWrapper
          onSubmit={async ({ phoneNumber }) => {
            signature.phoneNumber = phoneNumber
            setPhoneAsked(1)
          }}
        >
          <Form.Item initialValue={user.phoneNumber || signature.phoneNumber} name='phoneNumber' rules={requiredRules}>
            <PhoneInput />
          </Form.Item>
          <Button block htmlType='submit' type='primary'>
            <Trans>Sign now</Trans>
          </Button>
        </CustomFormWrapper>
      </>
    )
  } else {
    content = (
      <>
        <h4>
          <Trans>Sign this contract securely via email.</Trans>
        </h4>
        <p>{user.email}</p>
        <Button block onClick={() => setPhoneAsked(1)} type='primary'>
          <Trans>Sign now</Trans>
        </Button>
      </>
    )
  }

  return (
    <Portal cssSelector='body'>
      <StickyMainContent
        className={`fixedContent commentThread ${visible ? 'open' : 'close'} signingToolbox`}
        onClick={evt => evt.stopPropagation()}
        style={{ width: `calc(${width} + ${right})`, maxWidth: '28rem' }}
      >
        <div className='threadTitle'>
          <Button
            className='noBorder' ghost icon={<ArrowLeftOutlined />} onClick={close}
            shape='circle' type='default'
          />
          <h2>
            <Trans>Signing</Trans>
          </h2>
        </div>
        <div className='signaturePhoneContainer'>
          {signatureType === 'phone' ? (
            (lang === 'de' && <img alt='signature phone' src={SignaturePhoneDE} />)
              || <img alt='signature phone' src={SignaturePhoneEN} />
          ) : (
            (lang === 'de' && <img alt='signature email' src={SignatureEmailDE} />)
            || <img alt='signature email' src={SignatureEmailEN} />
          )}
          {content}
        </div>
      </StickyMainContent>
    </Portal>
  )
}

export default SigningPhoneDrawer
