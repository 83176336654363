/* eslint-disable react/display-name */
import React, { useContext, useMemo, useRef } from 'react'
import { useParams, useRouteMatch } from 'react-router'
import { Link } from 'react-router-dom'
import { useQueryParam, StringParam } from 'use-query-params'
import { Button, Dropdown, Menu, Tag } from 'antd'
import {
  AlignLeftOutlined,
  CheckCircleOutlined,
  EllipsisOutlined,
  HistoryOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  SisternodeOutlined,
  UserSwitchOutlined
} from '@ant-design/icons'
import moment from 'moment'
import { Trans } from '@lingui/macro'

import { Contract, Template } from '@top-legal/datastore'

import { DeviceContext } from '../../../GlobalContext'
import { UserAndCompanyDataContext } from '../../Layouts/Constants'
import { SidebarStateContext } from '../../../ContractTemplateRoutes'
import { TemplateListingContext } from './TemplateContainerWrapper'
import ListingContext from '../ListingContext'
import { UserAvatarByID } from '../../Organisations/UserRoleDisplay/UserAvatarList'
import ButtonLink from '../../SharedComponents/ButtonLink'
import { ListItem } from '../Types'
import { useGlobalTags } from '../SearchHelpers'

import './TemplateItemStyles.scss'
import TemplatePlaceholder from '../../../img/templatePlaceholder.png'

/// ////////////////////////////////////////////////////
//               Preview
/// ////////////////////////////////////////////////////
// const hiddenImage: any = { opacity: 0, pointerEvents: 'none', position: 'absolute' }
// export const CardCover = ({ item }) => {
//   const [status, setStatus] = useState('loading')
//   const statusProps = useMemo(() => ({
//     onError: () => setStatus('error'),
//     onLoad: () => setStatus('success')
//   }), [])
//
//   return (
//     <div className='loadingCardPlaceholder'>
//       {status !== 'success' && <Image preview={false} src={TemplatePlaceholder} />}
//       {/* Loading animation is adding a huge lag spike to the listing */}
//       {/* {status === 'loading' && <SimpleLoadingSpinner theme='darkBlue' />} */}
//       {status !== 'error' && (
//         <Image
//           {...statusProps}
//           alt='preview'
//           loading='lazy'
//           preview={false}
//           src={item.previewUrl}
//           style={status === 'loading' ? hiddenImage : undefined}
//         />
//       )}
//     </div>
//   )
// }

export const CardCover: React.FC<{ url: string | undefined }> = React.memo(({ url }) => {
  const imgRef = useRef<HTMLImageElement>(null)
  useMemo(() => {
    if (url) {
      const img = new window.Image()
      img.onload = () => {
        if (imgRef.current) {
          imgRef.current.src = url
        }
      }
      img.src = url
    }
  }, [url])

  return (
    <div className='imageWrapper'>
      <img ref={imgRef} src={TemplatePlaceholder} />
    </div>
  )
})

export const previewBox: ListItem<any> = {
  Element: React.memo(({ item }) => (
    <div className='listBox previewBox'>
      <CardCover url={item.previewUrl} />
    </div>
  )),
  width: 0.8,
  key: 'preview'
}

/// ////////////////////////////////////////////////////
//                Main Content
/// ////////////////////////////////////////////////////
export const MainContentBox: React.FC<{ item: Contract & Template; titleDesc?: any }> = ({ item, titleDesc, children }) => {
  const isPhone = useContext(DeviceContext) === 'phone'
  const [tag, setTag] = useQueryParam('tag', StringParam)
  const { Highlight } = useContext(ListingContext)
  const globalTags = useGlobalTags()

  return (
    <div className='listBox mainContentBox'>
      {titleDesc && <p className='boxTitleDesc'>{titleDesc}</p>}
      <h1>
        <Highlight text={item.name || item.contractName} />
      </h1>
      <p className='lastUpdate'>
        <UserAvatarByID autoSide userID={item.updatedBy || item.createdBy} /> <Trans>Updated</Trans> {moment(item.dateUpdated).fromNow()}
      </p>
      <div className='labels'>
        {[globalTags[item.lang], globalTags[item.category as any], ...(Array.isArray(item.tags) ? item.tags : [])].map(
          label => label && (
            <Tag
              key={label}
              className={label === tag ? 'searchActive' : ''}
              onClick={() => setTag(label === tag ? undefined as any : label)}
            >
              {label}
            </Tag>
          )
        )}
      </div>
      <div className={`actions ${isPhone ? 'ant-btn-group ant-btn-group-sm' : ''}`}>{children}</div>
    </div>
  )
}

const mainContent: ListItem<Template> = {
  Element: React.memo(({ item }) => {
    const { url } = useRouteMatch()
    const sidebarStateContext = useContext(SidebarStateContext)
    const templateUrl = `${url.replace('/listing/', '/')}/${item.templateID}`

    return (
      <MainContentBox
        item={item as any}
        titleDesc={
          <p className='boxTitleDesc'>
            <Trans>Playbook</Trans>
          </p>
        }
      >
        {window.parent !== window ? (
          //  Dealroom
          <ButtonLink href={url.replace('/listing/templates', `/templates/${item.templateID}`)} type='primary'>
            <Trans>Open Playbook</Trans>
          </ButtonLink>
        ) : (
          //  Automate
          <ButtonLink href={url.replace('/listing/templates', `/contracts/${item.templateID}/new`)} icon={<PlusOutlined />} type='primary'>
            <Trans>Draft Contract</Trans>
          </ButtonLink>
        )}
        {['Owner', 'FullAccess', 'Contributor'].includes(item.role) && (
          <Dropdown
            arrow
            overlay={
              <Menu key='menu'>
                <Menu.Item>
                  <Link to={`${templateUrl}?tab=automate`}>
                    <SisternodeOutlined />
                    <span>
                      <Trans>Edit Automation</Trans>
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to={`${templateUrl}?tab=automate&sidebar=users`}>
                    <UserSwitchOutlined />
                    <span>
                      <Trans>Change User Access</Trans>
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to={`${templateUrl}?tab=approval`}>
                    <CheckCircleOutlined />
                    <span>
                      <Trans>Configure Approval Workflow</Trans>
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to={`${templateUrl}?tab=introVideo`}>
                    <PlayCircleOutlined />
                    <span>
                      <Trans>Record Video</Trans>
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to={`${templateUrl}?tab=structure`}>
                    <AlignLeftOutlined />
                    <span>
                      <Trans>Edit Document Structure</Trans>
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to={`${templateUrl}?tab=automate&sidebar=activities`}>
                    <HistoryOutlined />
                    <span>
                      <Trans>View Activity</Trans>
                    </span>
                  </Link>
                </Menu.Item>
              </Menu>
            }
            placement='bottomCenter'
            trigger={['click']}
          >
            <Button
              className='moreButton ant-btn-grey'
              ghost
              // eslint-disable-next-line no-script-url
              href='javascript:void(0)'
              icon={<EllipsisOutlined />}
              type='default'
            />
          </Dropdown>
        )}
      </MainContentBox>
    )
  }),
  width: 2,
  key: 'mainContent'
}

/// ////////////////////////////////////////////////////
// Instructions
/// ////////////////////////////////////////////////////
const instructions: ListItem<Template> = {
  Element: React.memo(({ item }) => {
    const { setOpenedVideo } = useContext(TemplateListingContext)
    const { Highlight } = useContext(ListingContext)

    return (
      <div className='listBox templateInstructions'>
        <p className='boxTitleDesc'>
          <Trans>How to use this playbook</Trans>
        </p>
        <h3>
          <Trans>Instructions</Trans>
        </h3>
        <p className='description'>
          <Highlight text={item.description} />
        </p>
        {item.introVideo && (
          <div className='videoLink'>
            <Button className='noBorder ant-btn-link' ghost onClick={() => setOpenedVideo(item.introVideo)} type='primary'>
              <Trans>View Explanation Video</Trans>
            </Button>
          </div>
        )}
      </div>
    )
  }),
  width: 2,
  key: 'instructions'
}

/// ////////////////////////////////////////////////////
// Users
/// ////////////////////////////////////////////////////
const users: ListItem<Template> = {
  Element: React.memo(({ item }) => (
    <div className='listBox templateUsers'>
      <p className='boxTitleDesc'>
        <Trans>Roles</Trans>
      </p>
      <h3>
        <Trans>Users</Trans>
      </h3>
      <p className='usersAccess'>
        <Trans>Access</Trans>
      </p>
      <div className='contractMembersWrapper'>
        {Array.isArray(item.roles)
          && item.roles.map(
            role => role.role !== 'NoAccess' && (
              <UserAvatarByID
                key={role.userID} className='intern' role={role} size='2rem'
                userID={role.userID}
              />
            )
          )}
      </div>
      {item.needSigningApproval && item.signingApprovalUsers && (
        <>
          <p className='usersApproval'>
            <Trans>Approvers</Trans>
          </p>
          <div className='contractMembersWrapper'>
            {item.signingApprovalUsers.map(approver => (
              <UserAvatarByID key={approver} className='intern' size='2rem' userID={approver} />
            ))}
          </div>
        </>
      )}
    </div>
  )),
  width: 1,
  key: 'users'
}

/// ////////////////////////////////////////////////////
//                Contracts
/// ////////////////////////////////////////////////////
const emptyObject = {}
const contracts: ListItem<Template> = {
  Element: React.memo(({ item }) => {
    const {
      user: { userID }
    } = useContext(UserAndCompanyDataContext)
    const { templateStatistics } = useContext(TemplateListingContext)
    const { drafting, negotiate, approval, signing, signed } = templateStatistics[item.templateID] || emptyObject
    const total = drafting || 0 + negotiate || 0 + approval || 0 + signing || 0 + signed || 0

    const { lang } = useParams()
    const showContractsUrl = `/${lang}/listing/contracts?templateID=${item.templateID}`

    return (
      <div className='listBox draftedContracts'>
        <p className='boxTitleDesc'>
          <Trans>Drafted based on this Playbook</Trans>
        </p>
        <h3>
          <Trans>Contracts</Trans>
        </h3>
        <table className='contracts'>
          <tr>
            <td>
              <p>{drafting || 0}</p>
            </td>
            <td className='term'>
              <p className='userStatus colorDrafting'>
                <Trans>Drafting</Trans>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>{negotiate || 0}</p>
            </td>
            <td className='term'>
              <p className='userStatus colorNegotiate'>
                <Trans>Negotiate</Trans>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>{approval || 0}</p>
            </td>
            <td className='term'>
              <p className='userStatus colorApproval'>
                <Trans>Approval</Trans>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>{signing || 0}</p>
            </td>
            <td className='term'>
              <p className='userStatus colorSigning'>
                <Trans>Signing</Trans>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>{signed || 0}</p>
            </td>
            <td className='term'>
              <p className='userStatus colorSigned'>
                <Trans>Signed</Trans>
              </p>
            </td>
          </tr>
          {/* <tr> */}
          {/*  <td><p>{rejected || 0}</p></td> */}
          {/*  <td className='term'><p className='userStatus colorRejected'><Trans>Rejected</Trans></p></td> */}
          {/* </tr> */}
        </table>
        {/* Disable this in iframe for dealroom */}
        {total > 0 && window.parent === window && (
          <ButtonLink
            className='boxMoreButton ant-btn-link'
            href={showContractsUrl}
            onClick={() => {
              //  Some analytics tracking
              if ((window as any).analytics) {
                (window as any).analytics.track('searchListRowClick', {
                  userID,
                  rowID: item.templateID,
                  location: 'template contracts box',
                  attribute: 'templateID',
                  value: item.templateID
                })
              }
            }}
            size='small'
          >
            <Trans>+ see all</Trans>
          </ButtonLink>
        )}
      </div>
    )
  }),
  width: 1,
  key: 'contracts'
}

export default {
  components: [previewBox, mainContent, instructions, users, contracts],
  tabletComponents: [{ ...previewBox, width: 1 }, mainContent, instructions],
  phoneComponents: [previewBox, { ...mainContent, width: 1.2 }]
}
