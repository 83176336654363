import {
  FETCHING_ORGANISATION_DATA,
  LOAD_ORGANISATION_FROM_LOCAL_STORAGE,
  SET_SELECTED_ORGANISATION_INDEX,
  STORE_INVITE_DATA,
  STORE_ORGANISATIONS,
  STORE_SELECTED_CLIENT_ORGA,
  STORE_SELECTED_ORGA,
  UPDATE_COMPANY_ROLES,
  UPDATE_INSTANCE_ROLES,
  UPDATE_ORGANISATION_EVENTS,
  UPDATE_LAWYER_ORGANISATION,
  STORE_EXTERNAL_USER,
  UPDATE_INSTANCE_EVENTS,
  UPDATE_REMINDERS,
  STORE_INSTANCES_COMMENTS,
  UPDATE_ORGANISATION_FIELDS,
  FETCH_ORGANISATION_FIELDS,
  FETCH_ORGANISATION_CONDITIONAL_TEXTS,
  UPDATE_ORGANISATION_CONDITIONAL_TEXTS,
  FETCH_ORGANISATION_FIELDS_RESPONSE,
  UPDATE_ORGANISATION_FIELDS_RESPONSE,
  SAVE_MEDIA_LIBRARY, STORE_INSTANCES_EVENTS
} from './OrganisationsActions'

const initState = {
  loading: true,
  selectedOrganisation: {},
  myOrganisations: [],
  clientOrganisation: {},
  fetchingOrganisationData: false,
  selectedOrganisationIndex: null,
  inputFields: {},
  conditionalTexts: {},
  fieldResponses: {},
  mediaLibrary: {},

  //  User management
  companyRoles: { teams: [], otherTeams: [] },
  instanceRoles: {},
  instanceComments: {},
  instanceEvents: {},

  inviteData: null,

  // Activity logs
  events: false,

  //  External user data
  externalUsers: {
    __JohnSmith__: {
      userID: '__JohnSmith__',
      title: '-',
      firstName: 'John',
      lastName: 'Smith',
      email: 'john.smith@world.earth'
    },
    __AgataSmith__: {
      userID: '__AgataSmith__',
      title: '-',
      firstName: 'Agata',
      lastName: 'Smith',
      email: 'agata.smith@world.earth'
    },
    //  Avoid external party to fetch non exiting user
    __GUEST__: {},
    __COUNTERPARTY__: {}
  }
}

const REDUCER_ACTIONS = {
  [STORE_EXTERNAL_USER]: (state, payload) => {
    state.externalUsers = {
      ...state.externalUsers,
      [payload.userID || payload.partyID]: payload
    }
  },
  [STORE_ORGANISATIONS]: (state, payload) => {
    state.myOrganisations = payload
  },
  [STORE_SELECTED_ORGA]: (state, payload) => {
    if (state.selectedOrganisation) {
      if (state.selectedOrganisation.organisationID === payload.organisationID) {
        if (state.selectedOrganisation.team && !payload.team) {
          payload.team = state.selectedOrganisation.team
        }
        if (state.selectedOrganisation.role && !payload.role) {
          payload.role = state.selectedOrganisation.role
        }
      }
    }
    state.selectedOrganisation = payload
  },
  [STORE_SELECTED_CLIENT_ORGA]: (state, payload) => {
    state.clientOrganisation = payload
  },
  [FETCHING_ORGANISATION_DATA]: (state, payload) => {
    state.fetchingOrganisationData = payload
  },
  [SET_SELECTED_ORGANISATION_INDEX]: (state, payload) => {
    state.selectedOrganisationIndex = payload
  },
  [UPDATE_COMPANY_ROLES]: (state, payload) => {
    state.companyRoles = payload
  },
  [UPDATE_INSTANCE_ROLES]: (state, payload) => {
    state.instanceRoles = payload
  },
  [STORE_INSTANCES_COMMENTS]: (state, newComments) => {
    state.instanceComments = {
      ...state.instanceComments,
      ...newComments
    }
  },
  [STORE_INSTANCES_EVENTS]: (state, newEvents) => {
    state.instanceEvents = {
      ...state.instanceEvents,
      ...newEvents
    }
  },
  [UPDATE_LAWYER_ORGANISATION]: (state, payload) => {
    state.selectedOrganisation = payload
  },
  [LOAD_ORGANISATION_FROM_LOCAL_STORAGE]: (state, payload) => {
    state.selectedOrganisation = payload
  },
  [STORE_INVITE_DATA]: (state, payload) => {
    state.inviteData = payload
  },
  [UPDATE_ORGANISATION_EVENTS]: (state, { lastElement, Items, resetCache }) => {
    state.events = { Items: resetCache ? Items : [...(state.events.Items || []), ...Items], lastElement }
  },
  [UPDATE_INSTANCE_EVENTS]: (state, { lastElement, Items, resetCache }) => {
    state.instanceEvents = { Items: resetCache ? Items : [...(state.instanceEvents.Items || []), ...Items], lastElement }
  },
  [UPDATE_REMINDERS]: (state, payload) => {
    state.remindersEvents = payload
  },
  [FETCH_ORGANISATION_FIELDS]: (state, payload) => {
    state.inputFields = payload
  },
  [UPDATE_ORGANISATION_FIELDS]: (state, field) => {
    state.inputFields = {
      ...state.inputFields,
      [field.inputFieldID]: {
        ...(state.inputFields[field.inputFieldID] || {}),
        [field.version]: field
      }
    }
  },
  [FETCH_ORGANISATION_CONDITIONAL_TEXTS]: (state, payload) => {
    state.conditionalTexts = payload
  },
  [UPDATE_ORGANISATION_CONDITIONAL_TEXTS]: (state, field) => {
    state.conditionalTexts = {
      ...state.conditionalTexts,
      [field.conditionalTextID]: {
        ...(state.conditionalTexts[field.conditionalTextID] || {}),
        [field.version]: field
      }
    }
  },
  [FETCH_ORGANISATION_FIELDS_RESPONSE]: (state, payload) => {
    state.fieldResponses = payload
  },
  [UPDATE_ORGANISATION_FIELDS_RESPONSE]: (state, payload) => {
    state.fieldResponses = {
      ...state.fieldResponses,
      [payload.contractID]: {
        ...(state.fieldResponses[payload.contractID] || {}),
        [payload.inputFieldID]: payload
      }
    }
  },
  [SAVE_MEDIA_LIBRARY]: (state, payload) => {
    state.mediaLibrary = payload
  }
}

export default function (state = initState, action) {
  state = { ...state }
  const handler = REDUCER_ACTIONS[action.type]
  if (handler) {
    state = handler(state, action.payload) || state
  }
  return state
}
