/**
 * Product & Service field configuration (based on stripe format)
 * Specs: https://docs.google.com/document/d/15g2LaTZ40qzfU9rXHQ6xW5QSJ6553k-kWRpcJ5N90Uk/edit#heading=h.61thmppex9ui
 *
 * Restrictions:
 *  - name: max 250 chars
 *  - desc: max 250 chars
 *  - unit label: max 12chars
 *  - name, desc & unit label is unicode
 *  - package unit number: min 2
 *  - money < 1 000 000
 *  - subscriptions can have only same billing period & tax
 */

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  Button, Form, FormInstance, Input, InputNumberProps, Radio, Tooltip
} from 'antd'
import { CloseOutlined, InfoOutlined, PlusOutlined } from '@ant-design/icons'
import { t, Trans } from '@lingui/macro'

//  Editor library
import { RenderOnlyEditor, renderProductsToSlate } from '@top-legal/editor'

import { TemplateContext } from '../../../Contexts'
import CustomFormWrapper, { requiredRules } from '../../../../../SharedComponents/CustomFormWrapper'
import { CheckboxBoolean, Select } from '../../../../../SharedComponents/FormComponents'
import { buildContractInputQuestion, InputNumber } from '../../../../../Template/TemplateHelperFunctions'
import Portal from '../../../../../SharedComponents/Portal'

import './ProductFieldConfigurationStyles.scss'

interface ProductFieldConfigurationProps {
  initialValues?: any
  onComplete: (values: any) => void
  onCancel: () => void
}


const RenderMainHelp: React.FC = ({ children }) => (
  <Portal cssSelector='.inputFieldEditor .inputPreview' position='first'>{children}</Portal>
)


// ==================================================================================================


/** *************************************************************************
 *                      Basic product configuration
 ************************************************************************** */
const ProductFieldConfigurationBasicStep: React.FC<{ initialValues: any }> = ({ initialValues }) => (
  <>
    <fieldset>
      <Form.Item
        initialValue={initialValues.productName}
        label={<h4><Trans>Name</Trans></h4>}
        name='productName'
        rules={[...requiredRules, {
          min: 3,
          max: 100
        }]}
      >
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 1 }}
          className='simple-line noResize'
          maxLength={100}
          onKeyDown={useCallback(evt => {
            if (evt.key === 'Enter') {
              evt.preventDefault()
            }
          }, [])}
          placeholder='Premium Plan, Software, etc.'
          showCount
        />
      </Form.Item>
      {/* max 250 chars */}
      <Form.Item
        initialValue={initialValues.productDescription}
        label={<h4><Trans>Description</Trans> <i><Trans>optional</Trans></i></h4>}
        name='productDescription'
      >
        <Input.TextArea
          autoSize
          className='simple-line'
          maxLength={250}
          placeholder={t`Description`}
          showCount
        />
      </Form.Item>
    </fieldset>

    <RenderMainHelp>
      <div className='productFieldConfigurationExplanations'>
        <div className='explanationItem'>
          <h4><Trans>What's a product?</Trans></h4>
          <p><Trans>Products are the goods or services you sell. If you sell subscriptions, you might call them "plans."</Trans></p>
          <div className='example'>
            <h5><Trans>Simple subscriptions</Trans></h5>
            <p><i><Trans>A video streaming service offers a monthly subscription. This would be a product with one price.</Trans></i></p>
            <h5><Trans>Varying subscriptions</Trans></h5>
            <p><i><Trans>A productivity tool sells three plans with monthly or annual subscriptions. Each plan becomes a product with two prices.</Trans></i></p>
          </div>
        </div>
        <div className='explanationItem'>
          <h4><Trans>Name & Description</Trans></h4>
          <p><Trans>Both are shown on the final contract and future invoices.</Trans></p>
        </div>
      </div>
    </RenderMainHelp>
  </>
)


// ==================================================================================================


export const pricingModels = {
  standard: {
    name: <Trans>Standard pricing</Trans>,
    explanation: (
      <>
        <Trans>Select standard pricing if you charge the same price for each unit.</Trans><br />
        <i><Trans>For example, you might charge €1234,99 per unit.</Trans></i>
      </>
    )
  },
  package: {
    name: <Trans>Package pricing</Trans>,
    explanation: (
      <>
        <Trans>Select package pricing if you charge by the package, or group of units.</Trans><br />
        <i>
          <Trans>
            For example, say you charge €25,00 for every 5 units. Purchases are rounded up by default, so a customer buying 8 units would pay €50,00.
          </Trans>
        </i>
      </>
    )
  },
  graduated: {
    name: <Trans>Graduated pricing</Trans>,
    explanation: (
      <>
        <Trans>Select graduated pricing if you use pricing tiers that may result in a different price for some units in an order.</Trans><br />
        <i><Trans>For example, you might charge €10.00 per unit for the first 100 units and then €5,00 per unit for the next 50.</Trans></i>
      </>
    )
  },
  volume: {
    name: <Trans>Volume pricing</Trans>,
    explanation: (
      <>
        <Trans>Select volume pricing if you charge the same price for each unit based on the total number of units sold.</Trans><br />
        <i><Trans>For example, you might charge €10,00 per unit for 50 units, and €7,00 per unit for 100 units.</Trans></i>
      </>
    )
  }
}

export const currencies = {
  eur: <span className='currency'>EUR<span> - Euro</span></span>,
  usd: <span className='currency'>USD<span> - US Dollar</span></span>,
  gbp: <span className='currency'>GBP<span> - British Pound</span></span>
}

const currencySymbolMapping = {
  eur: '€',
  usd: '$',
  gbp: '£'
}

const moneyProps = {
  className: 'simple-line productMoneyInput',
  min: 0,
  max: 999_999.99,
  step: 0.01,
  placeholder: '0,00'
}

const billingPeriods = {
  daily: <Trans>Daily</Trans>,
  weekly: <Trans>Weekly</Trans>,
  monthly: <Trans>Monthly</Trans>,
  threeMonths: <Trans>Every 3 months</Trans>,
  sixMonths: <Trans>Every 6 months</Trans>,
  yearly: <Trans>Yearly</Trans>
  // custom: <Trans>Custom</Trans>
}

const meteredMetrics = {
  sum: <Trans>Sum of usage values during period</Trans>,
  recent: <Trans>Most recent usage during period</Trans>,
  latest: <Trans>Latest usage record</Trans>,
  max: <Trans>Maximum usage during period</Trans>
}


export const InputNumberBlurOnChange: React.FC<InputNumberProps<number>> = ({ value, onChange, ...props }) => {
  const [internalValue, setInternalValue] = useState(value)
  useEffect(() => {
    setInternalValue(value)
  }, [value])

  return (
    <InputNumber
      {...props}
      onBlur={() => onChange?.(internalValue as any)}
      onChange={setInternalValue}
      value={internalValue}
    />
  )
}


const PricingTable: React.FC<{ value?: any, onChange?: (values: any) => void, unitLabel: React.ReactNode }> = ({
  value, onChange, unitLabel
}) => {
  if (!Array.isArray(value)) {
    setTimeout(() => {
      onChange?.([{ max: 1 }, {}])
    })
    return null
  }

  const getUpdate = (index: number, prop: string) => (newVal: number) => {
    const newArr = [...value]
    const oldVal = newArr[index][prop]
    newArr[index][prop] = newVal

    //  Increment above values if needed
    if (prop === 'max' && newVal > oldVal && index < newArr.length - 2) {
      let min = newVal + 1

      for (let idx = index + 1; idx <= newArr.length - 2; idx += 1) {
        if (newArr[idx].max < min) {
          newArr[idx].max = min
          min += 1
        } else {
          break
        }
      }
    }

    onChange?.(newArr)
  }

  return (
    <table className='productPricingTable'>
      <tr>
        <th><Trans>Up to</Trans></th>
        <th><Trans>Per {unitLabel}</Trans></th>
        <th>
          <Trans>Flat fee</Trans>
          <Tooltip
            title={(
              <Trans>
                You can also specify a flat fee to add to the invoice.
                For example, you can have a flat fee that increases when certain usage thresholds are met.
              </Trans>
            )}
          >
            <Button className='explanationButton' ghost icon={<InfoOutlined />} size='small' />
          </Tooltip>
        </th>
        <th>&nbsp;</th>
      </tr>
      {value.map(({ max, unit, flat }, index) => (
        <tr key={`${index}`}>
          <td>
            {index === value.length - 1 ? (
              <Input className='simple-line infinity' disabled value='∞' />
            ) : (
              <InputNumberBlurOnChange
                className='simple-line'
                min={index === 0 ? 1 : value[index - 1].max + 1}
                onChange={getUpdate(index, 'max')}
                step={1}
                value={max}
              />
            )}
          </td>
          <td>
            <InputNumberBlurOnChange
              {...moneyProps}
              onChange={getUpdate(index, 'unit')}
              value={unit}
            />
          </td>
          <td>
            <InputNumberBlurOnChange
              {...moneyProps}
              onChange={getUpdate(index, 'flat')}
              value={flat}
            />
          </td>
          <td>
            {(() => {
              if (index === 0 || index === value.length - 1) {
                return null
              }
              return (
                <Button
                  className='noBorder'
                  ghost
                  icon={<CloseOutlined />}
                  onClick={() => {
                    const newValue = [...value]
                    newValue.splice(index, 1)
                    onChange?.(newValue)
                  }}
                  size='small'
                  type='default'
                />
              )
            })()}
          </td>
        </tr>
      ))}
      <tr>
        <td className='addPricingTier' colSpan={3}>
          <Button
            className='noBorder'
            ghost
            icon={<PlusOutlined />}
            onClick={() => {
              const newValue = [...value]
              const last = { ...newValue[newValue.length - 2] }
              last.max += 1
              newValue.splice(newValue.length - 1, 0, last)
              onChange?.(newValue)
            }}
            size='small'
            type='default'
          >
            <Trans>Add pricing tier</Trans>
          </Button>
        </td>
      </tr>
    </table>
  )
}


/** *************************************************************************
 *                        Pricing configuration
 ************************************************************************** */
const ProductFieldConfigurationPricingModel: React.FC<{ initialValues: any }> = ({ initialValues }) => (
  <Form.Item
    noStyle
    shouldUpdate={(prev, next) => prev.currency !== next.currency}
  >
    {_form => (
      <div style={{ '--productConfigurationCurrency': JSON.stringify(currencySymbolMapping[_form.getFieldValue('currency') || 'eur'] || '') } as any}>
        <fieldset>
          {/* Unit configuration */}
          <div className='unitConfiguration'>
            <Form.Item
              initialValue={initialValues.unitLabel}
              label={(
                <h4>
                  <Trans>Unit label</Trans>
                  <Tooltip
                    title={(
                      <Trans>
                        Adding a unit label describes how you sell your product.
                        For example, if you charge by the seat and use "seat" as the unit label, it will be included in the line item as the price “per seat”.
                        Unit label appears in contract and future invoices.
                      </Trans>
                    )}
                  >
                    <Button className='explanationButton' ghost icon={<InfoOutlined />} size='small' />
                  </Tooltip>
                </h4>
              )}
              name='unitLabel'
            >
              <Input className='simple-line' placeholder={t`Optional`} />
            </Form.Item>
            <Form.Item
              initialValue={initialValues.currency || 'eur'}
              label={<h4><Trans>Currency</Trans></h4>}
              name='currency'
            >
              <Select className='simple-line' items={currencies} noSearch />
            </Form.Item>
          </div>
        </fieldset>

        <fieldset>
          {/* Pricing model */}
          <Form.Item
            initialValue={initialValues.pricingModel || 'standard'}
            label={<h4><Trans>Pricing model</Trans></h4>}
            name='pricingModel'
            rules={requiredRules}
          >
            <Select className='simple-line' items={pricingModels} label='name' noSearch />
          </Form.Item>

          {/* Pricing configuration */}
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => prev.pricingModel !== next.pricingModel || prev.unitLabel !== next.unitLabel}
          >
            {form => {
              const pricingModel = form.getFieldValue('pricingModel')

              if (pricingModel === 'standard') {
                return (
                  <div className='unitConfiguration'>
                    <Form.Item
                      initialValue={initialValues.fixedPrice}
                      label={<h4><Trans>Fixed price</Trans></h4>}
                      name='fixedPrice'
                      rules={requiredRules}
                    >
                      <InputNumber {...moneyProps} />
                    </Form.Item>
                  </div>
                )
              }

              if (pricingModel === 'package') {
                const unitLabel = form.getFieldValue('unitLabel') || <Trans>units</Trans>
                return (
                  <div className='unitConfiguration'>
                    <Form.Item
                      initialValue={initialValues.unitPrice}
                      label={<h4><Trans>Price</Trans></h4>}
                      name='unitPrice'
                      rules={requiredRules}
                    >
                      <InputNumber {...moneyProps} />
                    </Form.Item>
                    <Form.Item
                      initialValue={initialValues.unitNumber}
                      label={<h4><Trans>Per {unitLabel}</Trans></h4>}
                      name='unitNumber'
                      rules={requiredRules}
                    >
                      <InputNumber
                        className='simple-line'
                        min={2}
                        placeholder='10'
                        step={1}
                      />
                    </Form.Item>
                  </div>
                )
              }

              return (
                <Form.Item
                  initialValue={initialValues.pricingTiers}
                  name='pricingTiers'
                  rules={requiredRules}
                >
                  <PricingTable unitLabel={form.getFieldValue('unitLabel') || <Trans>units</Trans>} />
                </Form.Item>
              )
            }}
          </Form.Item>
        </fieldset>

        <fieldset>
          {/* Subscription type */}
          <Form.Item
            initialValue={initialValues.subscriptionType || 'recurring'}
            name='subscriptionType'
            rules={requiredRules}
          >
            <Radio.Group>
              <Radio.Button value='recurring'><Trans>Recurring</Trans></Radio.Button>
              <Radio.Button value='oneTime'><Trans>One time</Trans></Radio.Button>
            </Radio.Group>
          </Form.Item>
        </fieldset>

        <fieldset>
          {/* Recurring subscription */}
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => prev.subscriptionType !== next.subscriptionType || prev.isMeteredUsage !== next.isMeteredUsage}
          >
            {form => (form.getFieldValue('subscriptionType') === 'recurring' ? (
              <>
                {/* Billing period */}
                <Form.Item
                  initialValue={initialValues.billingPeriod || 'monthly'}
                  label={<h4><Trans>Billing period</Trans></h4>}
                  name='billingPeriod'
                  rules={requiredRules}
                >
                  <Select className='simple-line' items={billingPeriods} noSearch />
                </Form.Item>

                {/* Custom period */}

                {/* Metered usage */}
                <Form.Item
                  initialValue={initialValues.isMeteredUsage || false}
                  name='isMeteredUsage'
                >
                  <CheckboxBoolean>
                    <Trans>Usage is metered</Trans>
                    <Tooltip title={<Trans>Metered billing lets you charge customers based on reported usage at the end of each billing period.</Trans>}>
                      <Button className='explanationButton' ghost icon={<InfoOutlined />} size='small' />
                    </Tooltip>
                  </CheckboxBoolean>
                </Form.Item>

                {/* Metered config */}
                {(form.isFieldsTouched(['isMeteredUsage']) ? form.getFieldValue('isMeteredUsage') : initialValues.isMeteredUsage) && (
                  <Form.Item
                    initialValue={initialValues.meteredMetric || 'sum'}
                    label={<h4><Trans>Charge for a metered usage by</Trans></h4>}
                    name='meteredMetric'
                    rules={requiredRules}
                  >
                    <Select className='simple-line' items={meteredMetrics} noSearch />
                  </Form.Item>
                )}
              </>
            ) : null)}
          </Form.Item>
        </fieldset>

        <fieldset>
          <Form.Item
            initialValue={initialValues.tax}
            label={<h4><Trans>Tax %</Trans></h4>}
            labelCol={{ span: 24 }}
            name='tax'
            rules={requiredRules}
          >
            <InputNumber className='simple-line' max={100} min={0} />
          </Form.Item>
          <Form.Item
            initialValue={initialValues.taxMode || 'inclusive'}
            label={(
              <h4 className='taxModeLabel'>
                <span><Trans>Tax mode</Trans></span>
                <Tooltip placement='top' title={<Trans>Select if the tax is already added (inclusive) or need to be added on top (exclusive)</Trans>}>
                  <Button ghost icon={<InfoOutlined />} size='small' />
                </Tooltip>
              </h4>
            )}
            labelCol={{ span: 24 }}
            name='taxMode'
            rules={requiredRules}
          >
            <Radio.Group className='autoFit'>
              <Radio.Button value='inclusive'><Trans>Inclusive</Trans></Radio.Button>
              <Radio.Button value='exclusive'><Trans>Exclusive</Trans></Radio.Button>
            </Radio.Group>
          </Form.Item>
        </fieldset>

        <RenderMainHelp>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.pricingModel !== next.pricingModel}>
            {form => {
              const pricingModel = form.getFieldValue('pricingModel')

              return (
                <div className='productFieldConfigurationExplanations'>
                  {Object.entries(pricingModels).map(([key, { name, explanation }]) => (
                    <div
                      key={key}
                      className={`explanationItem selectable ${pricingModel === key ? 'active' : ''}`}
                      onClick={() => form.setFieldsValue({ pricingModel: key })}
                    >
                      <h4>{name}</h4>
                      <p>{explanation}</p>
                    </div>
                  ))}
                </div>
              )
            }}
          </Form.Item>
        </RenderMainHelp>
      </div>
    )}
  </Form.Item>
)


// ==================================================================================================


/** *************************************************************************
 *                 Render the preview of the field
 ************************************************************************** */
const ProductFieldConfigurationPreview: React.FC<{ initialValues: any, form: FormInstance }> = ({ initialValues, form }) => {
  const { template } = useContext<any>(TemplateContext)

  const value = form.getFieldValue('preview')
  const nodes = useMemo(() => {
    const table = renderProductsToSlate([initialValues], value, template.lang)
    return table ? [table] : undefined
  }, [initialValues, value, template.lang])

  return (
    <>
      <h2><Trans>Preview of the product & service field</Trans></h2>
      {nodes && <RenderOnlyEditor nodes={nodes} />}

      <RenderMainHelp>
        <div className='productFieldConfigurationExplanations'>
          <div className='explanationItem previewItem'>
            <h2><Trans>Insert number to test the pricing model</Trans></h2>
            {buildContractInputQuestion(template, 'preview', form, {}, initialValues, template.lang, false)}

            <div className='resetButtonWrapper'>
              <Button ghost onClick={() => form.resetFields()} type='default'>
                <Trans>Reset form value</Trans>
              </Button>
            </div>
          </div>
        </div>
      </RenderMainHelp>
    </>
  )
}


// ==================================================================================================


/** *************************************************************************
 *                 Render the current configuration step
 ************************************************************************** */
const ProductFieldConfigurationCurrentStep_: React.FC<{ configStep: number, initialValues: any }> = ({ configStep, initialValues }) => {
  if (configStep === 0) {
    return <ProductFieldConfigurationBasicStep initialValues={initialValues} />
  }
  if (configStep === 1) {
    return <ProductFieldConfigurationPricingModel initialValues={initialValues} />
  }
  if (configStep === 2) {
    return (
      <Form.Item noStyle shouldUpdate>
        {form => <ProductFieldConfigurationPreview form={form as any} initialValues={initialValues} />}
      </Form.Item>
    )
  }
  return null
}

export const ProductFieldConfigurationCurrentStep: React.FC<{ configStep: number, initialValues: any }> = props => (
  <div className='productFieldConfiguration'><ProductFieldConfigurationCurrentStep_ {...props} /></div>
)


// ==================================================================================================


/** *************************************************************************
 *                   Product field configuration form
 ************************************************************************** */
const ProductFieldConfiguration: React.FC<ProductFieldConfigurationProps> = ({ initialValues, onCancel, onComplete }) => {
  const [values, setValues] = useState<any>(initialValues || {})
  const [configStep, setConfigStep] = useState(0)

  const onSubmit = useCallback(async newVal => {
    setValues(oldVal => ({ ...oldVal, ...newVal }))
  }, [])

  return (
    <CustomFormWrapper layout='horizontal' onSubmit={onSubmit}>
      <ProductFieldConfigurationCurrentStep configStep={configStep} initialValues={values} />
    </CustomFormWrapper>
  )
}

export default ProductFieldConfiguration
