import React from 'react'
import { Trans } from '@lingui/macro'
import { FileOutlined } from '@ant-design/icons'
import ExternalMainContainer from './ExternalMainContainer'

const ExternalPageListing = () => {
  const a = 1

  return (
    <ExternalMainContainer
      mainContentClass='aboutTopLegal'
      topbarContent={(
        <div className='topbarMainContent'>
          <FileOutlined className='headerIcon' twoToneColor='#3DBD7D' />
          <h1 className='title'><Trans>My contracts</Trans></h1>
        </div>
      )}
    />
  )
}

export default ExternalPageListing
