import React, { useCallback, useContext, useLayoutEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from '@lingui/macro'
import { Button, Modal } from 'antd'
import { useParams } from 'react-router'
import { AuditOutlined, MenuOutlined } from '@ant-design/icons'

//  Contexts
import { SidebarToolboxes } from '../Contract/ContractEditor/Sidebar/Sidebar'
import { PhoneSidebarContext } from '../Contract/ContractEditor/Sidebar/PhoneSidebar'
import { ExternalContext } from './ExternalContext'
import { ContractContext, TemplateContext } from '../Contract/ContractEditor/Contexts'

//  Components
import ExternalMainContainer, { ExternalLangSwitchButtons } from './ExternalMainContainer'
import LayoutSidebar from '../Layouts/LayoutSidebar'
import ContractLayout from '../Contract/ContractDiplay/ContractLayout'
import { PdfContract } from '../Contract/ContractEditor/ContractAdditionalDocuments'
import { MediaCard } from '../Media/MediaLibrary'

//  Toolboxes
import { ExternalWelcomeToolboxSigning } from './Toolboxes/ExternalWelcomeToolbox'
import ChatToolbox from '../Contract/ContractEditor/Sidebar/SidebarToolboxes/ChatToolbox'
import CheckEmailToolbox from './Toolboxes/CheckEmailToolbox'
import ExternalUserManagementToolbox from './Toolboxes/ExternalUserManagementToolbox'

//  Actions
import RestService from '../../RestService'
import { noop } from '../Defaults'
import loadingModalFeedback from '../Alert/LoadingModalFeedback'
import { SAVE_CONTRACT } from '../Contract/redux/ContractActions'

//  Styles
import '../Contract/styles/ExternalContractSigningStyles.scss'
import { DeviceContext } from '../../GlobalContext'


export const DummySidebar: React.FC = () => <LayoutSidebar className='templateEditPage-toolbox' position='left' sidebarName='dummySidebar'><div /></LayoutSidebar>

export const DefaultSigningVideo: React.FC = () => {
  const { lang } = useParams()

  return (
    <div className='vimeoVideo'>
      <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
        <iframe
          allow='autoplay; fullscreen' allowFullScreen frameBorder='0' src={{
            en: 'https://player.vimeo.com/video/393487217',
            de: 'https://player.vimeo.com/video/520697249'
          }[lang]}
          style={{
            position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'
          }}
        />
      </div>
    </div>
  )
}

export const ExternalVideoModal: React.FC = () => {
  const { tokenData, setTokenData } = useContext(ExternalContext)
  const isPhone = useContext(DeviceContext) === 'phone'

  const closeVideo = async () => {
    setTokenData(old => ({ ...old, videoHasBeenShown: true }))
    await RestService('PUT', `/token/${tokenData.token}/updateData`, { videoHasBeenShown: true }, false)
  }

  return (
    <Modal
      className='externalSigningVideoModal mediumModal'
      closable={false}
      destroyOnClose
      footer={null}
      maskClosable={false}
      visible={!isPhone && tokenData.instanceID && !tokenData.videoHasBeenShown}
    >
      {tokenData.introVideo ? <MediaCard media={tokenData.introVideo} viewerOnly /> : <DefaultSigningVideo />}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
        <Button onClick={closeVideo} type='primary'>
          <Trans>Let's continue</Trans>
        </Button>
      </div>
    </Modal>
  )
}

const ExternalPageSigning: React.FC = () => {
  const { tokenData } = useContext(ExternalContext)
  const { signature } = useContext<any>(ContractContext)
  const hasBeenSigned = signature && signature.signedDate
  const isSigning = signature && ['frozen', 'signed'].includes(tokenData.contractStatus)
  const showSigningBox = hasBeenSigned || (isSigning && tokenData.videoHasBeenShown)

  useLayoutEffect(() => {
    document.body.classList.add('noBottomMarginContract')
    return () => document.body.classList.remove('noBottomMarginContract')
  }, [])

  const chatToolbox = useMemo(() => ChatToolbox(tokenData.instanceID), [tokenData.instanceID])

  /** ******************************************************************
   *                    Rendering part
   ****************************************************************** */
  return (
    <>
      <SidebarToolboxes.Provider
        value={useMemo(
          () => (tokenData.requestSigning ? {
            defaultKey: CheckEmailToolbox.key,
            defaultOpened: true,
            toolboxes: [CheckEmailToolbox]
          } : {
            defaultKey: showSigningBox ? ExternalUserManagementToolbox.key : ExternalWelcomeToolboxSigning.key,
            defaultOpened: true,
            toolboxes: [
              ...(hasBeenSigned ? [] : [ExternalWelcomeToolboxSigning]),
              ExternalUserManagementToolbox,
              chatToolbox
            ]
          }),
          [tokenData.requestSigning, showSigningBox, hasBeenSigned, chatToolbox]
        )}
      >
        <ContractLayout LeftSidebar={DummySidebar}>
          <div className='contractEditorComponent'><PdfContract url={tokenData.pdf} /></div>
        </ContractLayout>
      </SidebarToolboxes.Provider>
      <ExternalVideoModal />
    </>
  )
}

export const useConfirmAndSignAction = (): (() => Promise<void>) => {
  const { tokenData, setTokenData } = useContext(ExternalContext)

  return useCallback(async () => loadingModalFeedback({
    loadingTitle: <Trans>Making signing request</Trans>,

    successTitle: <Trans>Signing request sent</Trans>,
    successDescription: <Trans>We gonna email you with the final pdf contract to sign.</Trans>,

    errorTitle: <Trans>Error while making signing request</Trans>,
    errorDescription: <Trans>We got an unexpected error while trying to prepare the document for signing.</Trans>,

    autoSuccessClose: 4000
  })(async () => {
    await RestService('POST', `/token/${tokenData?.token}/signingRequest`, undefined, false)
    setTokenData(old => ({ ...old, requestSigning: true }))
  }), [tokenData?.token, setTokenData])
}

const ExternalSigningActions: React.FC = () => (
  <>
    <div id='topBarSignButton' />
    <ExternalLangSwitchButtons />
  </>
)

const ExternalSigningHeader: React.FC = () => {
  const isPhone = useContext(DeviceContext) === 'phone'
  const { openSidebarRef } = useContext(PhoneSidebarContext)
  const { tokenData } = useContext(ExternalContext)

  return (
    <>
      <div className='topbarMainContent'>
        <AuditOutlined className='headerIcon' twoToneColor='#3DBD7D' />
        {!isPhone && <h1>{tokenData.instanceTitle}</h1>}
      </div>
      <div id='topbarExtraContent' />
      <div className='topbarActions'>
        {isPhone ? (
          <Button
            className='noBorder phoneSidebarDrawerButton'
            ghost
            icon={<MenuOutlined />}
            onClick={() => openSidebarRef.current()}
            type='default'
          />
        ) : (
          <ExternalSigningActions />
        )}
      </div>
    </>
  )
}

const ExternalSigningPage: React.FC = () => {
  const openSidebarRef = useRef<() => void>(noop)
  const { tokenData } = useContext(ExternalContext)
  const dispatch = useDispatch()
  const contract = useSelector(state => state.contract.contractEditing)

  useMemo(() => {
    dispatch({
      type: SAVE_CONTRACT,
      payload: {
        contractID: tokenData.instanceID,
        contractStatus: tokenData.contractStatus || 'frozen',
        parties: tokenData.parties,
        signaturesHolder: tokenData.signaturesHolder
      }
    })
  }, [dispatch, tokenData.contractStatus, tokenData.instanceID, tokenData.parties, tokenData.signaturesHolder])


  /** ******************************************************************
   *            Provide some fake context
   ****************************************************************** */
  const templateContext = useMemo(() => ({
    template: {
      name: <Trans>Contract</Trans>, header: 'template-styling-one-small-paragraph', sections: [], footer: 'template-styling-one-small-paragraph'
    },
    sectionMapping: {},
    readOnly: true
  }), [])
  const contractContext = useMemo(() => ({
    contract,
    signature: contract.signaturesHolder?.signatures.find(({ partyID }) => partyID === tokenData.contact.partyID)
  }), [contract, tokenData.contact.partyID])

  return (
    <ContractContext.Provider value={contractContext}>
      <TemplateContext.Provider value={templateContext}>
        <PhoneSidebarContext.Provider value={{ ActionsButtons: ExternalSigningActions as any, openSidebarRef }}>
          <ExternalMainContainer mainContentClass='externalContractSigning' topbarContent={<ExternalSigningHeader />}>
            <ExternalPageSigning />
          </ExternalMainContainer>
        </PhoneSidebarContext.Provider>
      </TemplateContext.Provider>
    </ContractContext.Provider>
  )
}

export default ExternalSigningPage
