import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { InfoCircleOutlined, LeftOutlined, MailOutlined, RightOutlined, SendOutlined } from '@ant-design/icons'
import {
  Button, Form, Input, Modal, Steps, Tag, Tooltip
} from 'antd'
import { t, Trans } from '@lingui/macro'

import { teamRoles, teamRolesUserOverride } from './UsersManagement'
import { sendInvitation } from './redux/OrganisationsActions'
import { UserAndCompanyDataContext } from '../Layouts/Constants'
import CustomFormWrapper from '../SharedComponents/CustomFormWrapper'
import { Select } from '../SharedComponents/FormComponents'

import './UserInviteStyles.scss'

export const RoleSelectInput = ({ roles, label = <Trans>Role</Trans>, ...props }) => (
  <Select
    {...props}
    className='teamRoleSelect simple-line'
    dropdownClassName='teamRoleSelectOptionDropdown'
    dropdownMatchSelectWidth={false}
    items={roles}
    label={item => (item.text ? (
      <div className='teamRoleSelectOption'>
        <p className='userTeamGroup'>
          <Tag color={item.color}>{item.icon} {item.text}<br />
            {item.description ? <span className='teamDescription'>{item.description}</span> : null}
          </Tag>
        </p>
      </div>
    ) : null)}
    noSearch
    placeholder={<> - {label} - </>}
  />
)

export const RoleSelect = ({ label, roleKey, initialValues, defaultValue, roles }) => (
  <div>
    <h3 className='categoryHeader'>{label}</h3>
    <Form.Item
      initialValue={initialValues[roleKey] || defaultValue}
      name={roleKey}
      rules={[{
        required: true,
        message: <Trans>Please select a role.</Trans>
      }]}
    >
      <RoleSelectInput label={label} roles={roles} />
    </Form.Item>
  </div>
)

export const FeatureRolesSelect = ({ roles, defaultValue, initialValues, ...props }) => (
  <div className='modalSelectsContainer' {...props}>
    <RoleSelect
      defaultValue={defaultValue} initialValues={initialValues} label={<Trans>Playbooks</Trans>}
      roleKey='templateRole' roles={roles}
    />
    <RoleSelect
      defaultValue={defaultValue} initialValues={initialValues} label={<Trans>Contracts</Trans>}
      roleKey='contractRole' roles={roles}
    />
  </div>
)

const UserInvite = ({ visible, onFinish, onCancel, forInstance, instanceID, instanceType, initialValues }) => {
  const { user, company: { organisationID } } = useContext(UserAndCompanyDataContext)
  const [step, setStep] = useState(0)
  const [roleStep, setRoleStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({})
  const teamMapping = useSelector(state => state.organisation.companyRoles.teamMapping || {})
  const formRef = useRef()

  /** **********************************************************************
   *            Take care of resetting state when opening the modal
   ********************************************************************** */
  useEffect(() => {
    if (visible) {
      setStep(0)
      setRoleStep(0)
      setLoading(false)
      setValues(initialValues || {})
      //  After rerendering
      setTimeout(() => {
        if (formRef.current) {
          formRef.current.resetFields()
        }
      })
    }
  }, [visible, initialValues])

  /** **********************************************************************
   *                  Rendering invite
   ********************************************************************** */
  const userDisplayName = `${user.firstName} ${user.lastName}`
  const emailTextPlaceholder = t`Dear Sir/Madam,
I would like to join me to collaborate on contracts on top.legal. It is a secure and easy to use. Please follow the button below to register.
Kind regards,
${userDisplayName}`
  const renderStepInvite = () => (
    <>
      <Form.Item
        className='inviteFormItem simple-line'
        initialValue={values.inviteEmail || values.email}
        label={<Trans>Invite email</Trans>}
        name='inviteEmail'
        rules={[{
          required: true,
          type: 'email',
          message: <Trans>Please enter the email of the person you would like to invite</Trans>
        }]}
      >
        <Input className='simple-line' placeholder={t`Email`} prefix={<MailOutlined />} />
      </Form.Item>
      <Form.Item
        className='inviteFormItem'
        initialValue={values.inviteText || emailTextPlaceholder}
        label={<Trans>Invite text</Trans>}
        name='inviteText'
        rules={[{
          required: true,
          message: <Trans>Please enter an invitation message</Trans>
        }]}
      >
        <Input.TextArea autoSize className='simple-line' />
      </Form.Item>
    </>
  )

  /** **********************************************************************
   *                  Rendering roles
   ********************************************************************** */
  const renderStepRole = ({ getFieldValue }) => {
    if (forInstance) {
      const { NoAccess, ...roles } = teamRoles // eslint-disable-line
      return (
        <>
          <p className='explanation'><Trans>Now which permissions you want to grant to the new user?</Trans></p>
          <RoleSelect initialValues={values} label={<Trans>User role</Trans>} roleKey='role' roles={roles} />
        </>
      )
    }

    //  Get the select team and create a display for
    const currentTeam = forInstance && teamMapping[getFieldValue('team') || values.team || '__NoTeam']
    const teamRolesDisplay = currentTeam ? (
      <fieldset className='teamRolesBox'>
        <p className='legend'>
          <Trans>Current permissions for the selected user group</Trans>
          <Tooltip title={<Trans>The following is an overview of the permissions that have been granted to this user group displayed per category.</Trans>}>
            <InfoCircleOutlined />
          </Tooltip>
        </p>
        <div className='teamRolesDisplay'>
          <div>
            <h3 className='categoryHeader'><Trans>Templates</Trans></h3>
            <p className='permissionLevel'>
              {teamRoles[currentTeam.templateRole].text}
            </p>
            <p className='permissionDescription'>{teamRoles[currentTeam.templateRole].description}</p>
          </div>
          <div>
            <h3 className='categoryHeader'><Trans>Contracts</Trans></h3>
            <p className='permissionLevel'>
              {teamRoles[currentTeam.contractRole].text}
            </p>
            <p className='permissionDescription'>{teamRoles[currentTeam.contractRole].description}</p>
          </div>
        </div>
      </fieldset>
    ) : null

    //  Team selection
    if (!forInstance && roleStep === 0) {
      return (
        <>
          <Form.Item
            initialValue={values.team || '__NoTeam'}
            label={<Trans>Please select a team</Trans>}
            name='team'
            rules={[{
              required: true,
              message: <Trans>Please enter the team name.</Trans>
            }]}
            style={{ marginBottom: 50 }}
          >
            <Select className='simple-line' items={teamMapping} label='name' placeholder={<> - <Trans>User team</Trans> - </>} />
          </Form.Item>
          {teamRolesDisplay}
        </>
      )
    }

    //  Role override
    if (forInstance || roleStep === 1) {
      return (
        <>
          {teamRolesDisplay}
          <fieldset className='userAccessManagement teamRolesBox'>
            <p className='legend'>
              <Trans>Additional user rights</Trans>
              <Tooltip
                title={
                  <Trans>
                    Use the selection below to change the level of permission for this user.
                    You may also select to inherit from the permission rights from the team.
                  </Trans>
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            </p>
            <FeatureRolesSelect defaultValue='TeamInheritance' initialValues={values} roles={teamRolesUserOverride} />
          </fieldset>
        </>
      )
    }
    return null
  }

  /** **********************************************************************
   *                  Rendering confirmation
   ********************************************************************** */
  const renderConfirm = () => (
    <div className='previewBox'>
      <h2><Trans>Send a invite email to {values.inviteEmail}?</Trans></h2>
      <h3 className='previewMessageHeader'><Trans>Your custom message:</Trans></h3>
      <p className='previewMessage' dangerouslySetInnerHTML={{ __html: (values.inviteText || '').replace(/\n/g, '<br/>') }} />
    </div>
  )

  /** **********************************************************************
   *                  Wizard handlers
   ********************************************************************** */
  const next = formValues => {
    setValues({ ...values, ...formValues })
    if (!forInstance) {
      if (step === 0) {
        setRoleStep(0)
        setStep(1)
      } else if (step === 1) {
        if (roleStep === 1) {
          setStep(2)
        } else {
          setRoleStep(1)
        }
      } else {
        setStep(step + 1)
      }
    } else {
      setStep(step + 1)
    }
  }

  const previous = () => {
    if (!forInstance) {
      if (step === 2) {
        setRoleStep(1)
        setStep(1)
      } else if (step === 1) {
        if (roleStep === 0) {
          setStep(0)
        } else {
          setRoleStep(0)
        }
      } else {
        setStep(step - 1)
      }
    } else {
      setStep(step - 1)
    }
  }

  /** **********************************************************************
   *                  Send invite handler
   ********************************************************************** */
  const sendInvite = async () => {
    setLoading(true)
    try {
      const invite = { ...values }
      if (forInstance) {
        invite.instanceID = instanceID
        invite.instanceType = instanceType
      } else {
        invite.organisationID = organisationID
      }
      const res = await sendInvitation(invite)
      window.notification.success({
        message: <Trans>User invite</Trans>,
        description: <Trans>Invite successfully sent to the user</Trans>
      })
      onFinish({ ...invite, ...res, info: { email: invite.inviteEmail } })
      setLoading(false)
    } catch (err) {
      console.error('Error when invite user', err)
      setLoading(false)
      throw err
    }
  }

  /** **********************************************************************
   *                  Rendering invite modal
   ********************************************************************** */
  return (
    <Modal
      className='inviteUserModal mediumModal'
      footer={null}
      maskClosable={false}
      onCancel={onCancel}
      title={<Trans>Invite a new user</Trans>}
      visible={visible}
      zIndex={10010}
    >
      <Steps className='steps' current={step} direction='horizontal'>
        <Steps.Step title={<Trans>Invite</Trans>} />
        <Steps.Step title={<Trans>Set role</Trans>} />
        <Steps.Step title={<Trans>Send</Trans>} />
      </Steps>
      <CustomFormWrapper className='inviteUserForm' layout='vertical' onSubmit={next}>
        {
          form => {
            formRef.current = form
            return (
              <>
                {
                  [renderStepInvite, renderStepRole, renderConfirm][step](form)
                }
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {step === 0
                    ? <span />
                    : <Button icon={<LeftOutlined />} onClick={previous} type='default'><Trans>Previous</Trans></Button>}
                  {step === 2
                    ? <Button icon={<SendOutlined />} loading={loading} onClick={sendInvite} type='primary'><Trans>Send invite</Trans></Button>
                    : <Button htmlType='submit' icon={<RightOutlined />} type='default'><Trans>Next</Trans></Button>}
                </div>
              </>
            )
          }
        }
      </CustomFormWrapper>
    </Modal>
  )
}

export default UserInvite
