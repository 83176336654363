import React, { useContext, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import {Button, Form, Modal, Tooltip} from 'antd'
import {InfoCircleOutlined, LoadingOutlined} from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import uniqid from 'uniqid'

import { saveInStore, useContractsCollection } from '@top-legal/datastore'

// custom components
import { UserAndCompanyDataContext } from '../Layouts/Constants'
import { FileUploader } from '../SharedComponents/FormComponents/index'
import RestService from '../../RestService'
import CustomFormWrapper from '../SharedComponents/CustomFormWrapper'
import MultiFileUploader from '../SharedComponents/FormComponents/MultiFileUploader'

// styles
import './ContractUploader.scss'
import loadingModalFeedback from '../Alert/LoadingModalFeedback'


interface ContractUploaderProps {
  isTemplateUploader?: boolean
  visible: boolean
  close?: () => any
  timeoutRef?: any
}

const FileUploader_ = FileUploader as any


// ==================================================================================================
// ==================================================================================================

const ContractUploader: React.FC<ContractUploaderProps> = ({ isTemplateUploader, visible, close, timeoutRef }) => {
  const [loading, setLoading] = useState(false)
  const { user, company: { organisationID } } = useContext(UserAndCompanyDataContext)
  const history = useHistory()
  const userRef = useRef()
  userRef.current = user
  const { lang } = useParams()

  /** *******************************************************************
   *                 Handler for template
   ******************************************************************* */

  const handleTemplateUpload = async values => {
    setLoading(true)
    const notifID = uniqid('notif-')
    const templateID = uniqid('template-')
    try {
      const data = {
        organisationID, job: 'uploadJob', notifID, ...values, templateID
      }

      await RestService('POST', '/upload/automate', { ...data, lang })

      ;(window as any).notification.info({
        key: `conversionNotification-${templateID}`,
        message: <Trans>Conversion in progress</Trans>,
        description: (
          <Trans>
            We are currently uploading and converting your Word document. This may take some time depending on the complexity of the document.
          </Trans>
        ),
        icon: <LoadingOutlined />,
        duration: 20
      })
      // closes the modal
      if (typeof close === 'function') {
        await close()
      }

      // this is the fallback solution, if the websocket does not fire
      // after 35s this will run, unless canceled by the websocket
      timeoutRef.current = setTimeout(async () => {
        const template = await RestService('GET', `/template/${templateID}/automate`)
        const roles = await RestService('GET', `/organisation/${organisationID}/instance-roles/template/${templateID}`)

        if (template && roles) {
          (window as any).notification.close(`conversionNotification-${templateID}`)
          ;(window as any).notification.success({
            key: `conversionSuccess-${template.templateID}`,
            message: <Trans>Playbook Uploader</Trans>,
            description: <Trans>Playbook successfully created</Trans>,
            btn: (
              <Button
                onClick={() => {
                  (window as any).notification.close(`conversionSuccess-${template.templateID}`)
                  history.push(`${history.location.pathname}/${templateID}`)
                }}
                size='small'
                type='primary'
              >
                <Trans>To playbook</Trans>
              </Button>
            ),
            duration: 15
          })
        }
      }, 100000)
      setLoading(false)
    } catch (err) {
      console.error('Upload failed', err)
      setLoading(false)
      throw err
    }
  }

  /** *******************************************************************
   *                 Handler for contract
   ******************************************************************* */
  const contractCollections = useContractsCollection()
  const handleContractUpload = async values => {
    if (typeof close === 'function') {
      await close()
    }

    return loadingModalFeedback({
      loadingTitle: <Trans>Uploading you contracts</Trans>,
      successTitle: <Trans>Contracts uploaded</Trans>,
      errorTitle: <Trans>Failed to upload you contracts</Trans>,
      autoSuccessClose: 2000
    })(async () => {
      const data = { job: 'automateJob', organisationID, ...values }
      const newContracts = await RestService('POST', '/upload/document', data)

      if (Array.isArray(newContracts)) {
        newContracts.forEach(contract => {
          contract.role = 'Owner'
        })
        await saveInStore(contractCollections, newContracts)
      }
    })
  }

  /** *******************************************************************
   *             Define content for template or contracts
   ******************************************************************* */
  let content
  if (isTemplateUploader) {
    content = (
      <div className='contractUploader'>
        <h1><Trans>Playbook Uploader</Trans></h1>
        <p>
          <Trans>
            Use our Machine Learning models to parse word contracts into smart top.legal playbooks.
          </Trans>
          <Tooltip
            placement='top'
            title={<Trans>.doc not supported? Please use word to save your file as .docx, thanks.</Trans>}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </p>
        <MultiFileUploader
          accept='.docx'
          onFinished={([fileKey]) => handleTemplateUpload({ fileKey })}
          title={<Trans>Upload word</Trans>}
          useFeedbackModal
        />
      </div>
    )
  } else {
    content = (
      <div className='contractUploader'>
        <h1> <Trans>Contract Uploader</Trans></h1>
        <p>
          <Trans>
            The contract uploader enables you to store all your contract in one place, share them with other people, set reminders, and label them.
          </Trans>
        </p>
        <MultiFileUploader
          accept='.doc,.docx,.pdf'
          multiple
          onFinished={contractFiles => handleContractUpload({ contractFiles })}
          useFeedbackModal
          withNames
        />
      </div>
    )
  }

  /** *******************************************************************
   *                 Render modal with content
   ******************************************************************* */
  return (
    <Modal
      className='contractUploaderModal smallModal'
      destroyOnClose
      footer={null}
      onCancel={close}
      visible={visible}
    >
      {content}
    </Modal>
  )
}

export default ContractUploader
