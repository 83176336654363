import React, { useContext, useLayoutEffect } from 'react'
import { Alert } from 'antd'
import { Trans } from '@lingui/macro'

//  Contexts
import { ContractContext } from './ContractEditor/Contexts'
import { IdPrefixContext } from './ContractDiplay/ContractLayout'

//  Components
import { DocxContract, PdfContract } from './ContractEditor/ContractAdditionalDocuments'
import ContractSummary from './ContractEditor/ContractSummary'

/**
 * display uploaded pdfs (legacy contracts).
 */
const ContractView: React.FC = () => {
  const { contract } = useContext<any>(ContractContext)
  const idPrefix = useContext<string>(IdPrefixContext)

  useLayoutEffect(() => {
    document.body.classList.add('noBottomMarginContract')
    return () => document.body.classList.remove('noBottomMarginContract')
  }, [])

  let content = (
    <Alert
      description={<Trans>We are sorry, we cannot show you a preview of your file.</Trans>}
      message={<Trans>Unsupported file format</Trans>}
      showIcon
      style={{ margin: '1rem' }}
      type='error'
    />
  )

  if (contract && contract.contractID && contract.fileKey && contract.fileUrl) {
    const [ext] = contract.fileKey.split('.').slice(-1)
    if (ext === 'pdf') {
      content = <PdfContract url={contract.fileUrl} />
    } else if (ext === 'doc' || ext === 'docx') {
      content = <DocxContract url={contract.fileUrl} />
    }
  }

  return (
    <div className='contractEditorComponent contractDocument' id={`${idPrefix}__GLOBAL__`}>
      {contract.templateID && <ContractSummary />}
      {content}
    </div>
  )
}

export default ContractView
