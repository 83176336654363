import React, { useCallback, useContext, useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { ReadOutlined } from '@ant-design/icons'

import { InstanceLogs } from '../../../../Dashboard/Logs'
import TransactionAudit from './TransactionAudit'
import { ContractContext } from '../../Contexts'

import './ActivityToolboxStyles.scss'


const ActivityToolbox: React.FC<{ instanceType: string, instanceID: string, instanceName: string, isSigned: boolean }> = ({
  instanceType,
  instanceID,
  instanceName,
  isSigned
}) => (
  <div className='activityToolboxContent'>
    <InstanceLogs
      instanceID={instanceID}
      instanceName={instanceName}
      instanceType={instanceType}
      onData={useCallback(
        data => (isSigned
          ? [{ eventID: 'transaction_audit', _render: <TransactionAudit /> }, ...data]
          : data),
        [isSigned]
      )}
    />
  </div>
)

const useActivitiesToolbox = (instanceType, instanceID, instanceName) => {
  const { contract } = useContext<any>(ContractContext)
  const isSigned = !!contract && contract.contractStatus === 'signed'

  return useMemo(() => ({
    key: 'activities',
    className: 'activitiesLogToolbox',
    text: isSigned ? <Trans>Transaction Audit</Trans> : <Trans>Audit Trail</Trans>,
    helpText: <Trans>Get an overview on the user activity made on this document.</Trans>,
    icon: <ReadOutlined />,
    // eslint-disable-next-line react/display-name
    Component: () => (
      <ActivityToolbox
        instanceID={instanceID}
        instanceName={instanceName}
        instanceType={instanceType}
        isSigned={isSigned}
      />
    )
  }), [instanceID, instanceName, instanceType, isSigned])
}

export default useActivitiesToolbox
