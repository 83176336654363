import React from 'react'

import { NumberInput } from '@top-legal/form-components'

import { AssistantInputProps } from './Types'
import InputQuestion from './inputElements/InputQuestion'
import FieldExplanation from './inputElements/FieldExplanation'
import useFieldSaving from './inputElements/useFieldSaving'

const NumberFieldInput: React.FC<AssistantInputProps> = props => (
  <>
    <InputQuestion field={props.field} />
    <NumberInput
      className='simple-line'
      placeholder={props.field.name}
      {...useFieldSaving(props, true)}
    />
    <FieldExplanation field={props.field} />
  </>
)

export default NumberFieldInput
