import React, { useEffect, useRef, useState } from 'react'
import { CheckOutlined, CloseOutlined, ExclamationOutlined, LoadingOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import * as microsoftTeams from '@microsoft/teams-js'
import EmptyHeader from '../Layouts/EmptyHeader'
import RestService from '../../RestService'

import './TeamsConfigStyles.scss'

type States = 'loading' | 'done' | 'error' | 'notTeams'

const TeamsConfigPage: React.FC = () => {
  const [state, setState] = useState<States>('loading')
  const [msContext, setMsContext] = useState<microsoftTeams.Context>()

  const contextRef = useRef<microsoftTeams.Context>()
  contextRef.current = msContext
  useEffect(() => {
    microsoftTeams.initialize(() => {
    })
    microsoftTeams.getContext(context => {
      if (context == null) {
        setState('notTeams')
      } else {
        microsoftTeams.settings.setValidityState(true)
        microsoftTeams.appInitialization.notifySuccess()
        setMsContext(context)
      }
    })

    microsoftTeams.settings.registerOnSaveHandler(saveEvent => {
      microsoftTeams.settings.setSettings({
        entityId: contextRef.current?.entityId || '',
        contentUrl: `${window.location.origin}${window.location.pathname}?name={loginHint}&tenant={tid}&group={groupId}`
      })

      microsoftTeams.settings.getSettings(async getSettings => {
        try {
          await RestService('post', '/test/integrations/teams', {
            msContext: contextRef.current, getSettings
          }, false)
          setState('done')
          setTimeout(() => saveEvent.notifySuccess(), 1000)
        } catch (err: any) {
          console.error('MS teams error', err)
          setState('error')
          setTimeout(() => saveEvent.notifyFailure(err), 1000)
        }
      })
    })
  }, [])

  return (
    <div className='teamsConfigPage'>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <EmptyHeader />
      <div className='stateWrapper'>
        <div className={`loadingState ${state === 'loading' ? 'active' : ''}`}>
          <div className='stateIcon'><LoadingOutlined spin /></div>
          <h6><Trans>Setuping your connector</Trans></h6>
        </div>
        <div className={`doneState ${state === 'done' ? 'active' : ''}`}>
          <div className='stateIcon'><CheckOutlined /></div>
          <h6><Trans>Connector setup done</Trans></h6>
        </div>
        <div className={`notTeamsState ${state === 'notTeams' ? 'active' : ''}`}>
          <div className='stateIcon'><ExclamationOutlined /></div>
          <h6><Trans>Need to be used in teams</Trans></h6>
        </div>
        <div className={`errorState ${state === 'error' ? 'active' : ''}`}>
          <div className='stateIcon'><CloseOutlined /></div>
          <h6><Trans>Sorry we got an error</Trans></h6>
        </div>
      </div>
    </div>
  )
}

export default TeamsConfigPage
