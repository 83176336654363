import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { Trans } from '@lingui/macro'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { Button } from 'antd'
import Storage from '@aws-amplify/storage'

//  Custom components
import { createMedia } from '../Organisations/redux/OrganisationsActions'
import AudioVideoPlayerRecorder from './AudioVideoPlayerRecorder'

//  Styles
import './AudioVideoUploaderStyles.scss'
import useStorageUploadFeedback from '../../hooks/useStorageUploadFeedback'

interface AudioVideoMedia {
  fileKey: string,
  type: string,
  posterID?: string
}

interface CreateAudioVideoMediaProps {
  filePrefix: string
  audio?: boolean
  video?: boolean
  initialValue?: AudioVideoMedia
  visible: boolean
  next?: (mediaID: string) => void
  onStartRecord?: () => void
  posterUrl?: string
}

interface UploadedFile {
  fileKey?: string,
  file?: File,
  type: string,
  url: string,
  isObjectUrl: boolean
}

const expires = 60 * 60 * 6 // 6hours the max for an identity
const urlCache = {}
export const downloadProtectedMedia = async (key: string): Promise<string> => {
  const split = key.split('/')
  split.shift()
  const userID = split.shift()
  const rest = split.join('/')
  try {
    const now = Date.now()
    if (!urlCache[key] || urlCache[key].expires < now) {
      const url = await Storage.get(rest, { level: 'protected', identityId: userID, expires })
      urlCache[key] = { url, expires: now + (expires * 1000) }
    }
    return urlCache[key].url as string
  } catch (error) {
    console.error('Download error', error)
    throw error
  }
}

const CreateAudioVideoMedia: React.FC<CreateAudioVideoMediaProps> = ({
  audio,
  next,
  onStartRecord,
  initialValue,
  visible,
  posterUrl
}) => {
  const playerRef = useRef<any>()
  const dispatch = useDispatch()
  const [showButtons, setShowButtons] = useState(true)
  const [uploadedFile, setUploadedFile] = useState<UploadedFile>()
  const [uploadFeedback, upload] = useStorageUploadFeedback()

  //  Download the existing media for preview
  useLayoutEffect(() => {
    if (initialValue) {
      (async () => {
        const url = await downloadProtectedMedia(initialValue.fileKey)
        setUploadedFile(prevValue => {
          if (prevValue && prevValue.isObjectUrl) {
            window.URL.revokeObjectURL(prevValue.url)
          }
          return {
            url, fileKey: initialValue.fileKey, type: initialValue.type, isObjectUrl: false
          }
        })
      })()
    } else {
      setUploadedFile(undefined)
    }
  }, [initialValue])


  // ==================================================================================================


  const onFinished = useCallback(async (file: File) => {
    try {
      // uploading the document to the s3 bucket
      const reelKey = await upload(`${(new Date()).toISOString()}.${file.name.split('.').pop()}`, file)
      setUploadedFile(prevValue => {
        if (prevValue && prevValue.isObjectUrl) {
          window.URL.revokeObjectURL(prevValue.url)
        }
        return {
          url: window.URL.createObjectURL(file),
          file,
          type: file.type,
          isObjectUrl: true
        } as UploadedFile
      })

      const media_ = await dispatch(createMedia({ type: file.type, fileKey: reelKey, hidden: true }))

      next?.(media_.mediaID)
    } catch {}
  }, [dispatch, next, upload])

  const onPlayer = useCallback(player => { playerRef.current = player }, [])
  const onStart = useCallback(() => {
    setShowButtons(false)
    onStartRecord?.()
  }, [onStartRecord])


  // ==================================================================================================

  return uploadFeedback || (
    <div className={`top-legal-player-uploader ${uploadedFile ? 'hasFile' : 'noFile'}`}>
      {visible && (
        <>
          <AudioVideoPlayerRecorder
            audioOnly={audio}
            file={uploadedFile}
            onFinished={onFinished}
            onPlayer={onPlayer}
            onStart={onStart}
            poster={posterUrl}
            recorder={true}
          />
          <div className='mediaUploadActions'>
            <span />
            <Button
              disabled={showButtons}
              icon={<ArrowRightOutlined />}
              onClick={() => playerRef.current.record().stop()}
              type='primary'
            >
              <Trans>Continue</Trans>
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default CreateAudioVideoMedia
