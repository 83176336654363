import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Trans } from '@lingui/macro'
import { FileAddOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons'

import { UserAndCompanyDataContext } from '../../../../../../Layouts/Constants'
import InstanceUsersContext, { InternalUserRole } from '../Context/InstanceUsersContext'
import { RoleSelect } from '../../../../../../Organisations/UsersManagement'
import UserCard from './UserCard'


interface InternalRoleProps {
  userRole: InternalUserRole
}

export const templateRoles = {
  FullAccess: {
    text: <Trans>Automate</Trans>,
    description: <Trans>Automate the workflow</Trans>,
    icon: <SettingOutlined />,
    color: ''
  },
  Viewer: {
    text: <Trans>Draft</Trans>,
    description: <Trans>Use the playbook to draft contracts</Trans>,
    icon: <FileAddOutlined />,
    color: ''
  }
}

//  Template roles has been simplified to Configurator (can do whatever he wants) or Drafting (can only use the playbook for making contracts)
const roleRemapping = {
  FullAccess: 'FullAccess',
  Contributor: 'FullAccess',
  Reporter: 'Viewer',
  Viewer: 'Viewer'
}

const TemplateRoleCard: React.FC<InternalRoleProps> = ({ userRole: { userID, role } }) => {
  const { user: { userID: currentUserID }, company: { members } } = useContext(UserAndCompanyDataContext)
  const deletable = userID !== currentUserID //    We cannot delete ourself
    && role !== 'Owner' //                         We cannot delete the owner of the contract
    && userID !== members[currentUserID]?.team //  We cannot delete our team

  const [buttonLoading, setButtonLoading] = useState(false)
  const { updateInternalRole, deleteInternalRole } = useContext(InstanceUsersContext)

  /** **********************************************************************
   *           Build the delete user action
   ********************************************************************** */
  const deleteUser = useCallback(async () => {
    setButtonLoading(true)
    try {
      await deleteInternalRole(userID)
    } catch (err) {
      console.error('Error when removing a user', err)
    }
    setButtonLoading(false)
  }, [deleteInternalRole, userID])

  /** **********************************************************************
   *           Return a final user card
   ********************************************************************** */
  const partyInfo: any = useMemo(() => ({
    partyData: { partyID: userID }
  }), [userID])
  return (
    <UserCard
      deleteUser={deletable ? deleteUser : undefined}
      partyInfo={partyInfo}
    >
      {role === 'Owner' ? (
        <p style={{ padding: '0 0.5rem' }}><UserOutlined /> <Trans>Owner</Trans></p>
      ) : (
        <RoleSelect
          buttonLoading={buttonLoading}
          disabled={userID === currentUserID || role === 'Owner'}
          onChange={newRole => updateInternalRole(userID, newRole)}
          roles={templateRoles as any}
          setButtonLoading={setButtonLoading}
          value={roleRemapping[role]}
        />
      )}
    </UserCard>
  )
}

export default TemplateRoleCard
