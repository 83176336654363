import React, { useCallback, useEffect, useState } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { Button, Col, Layout, Modal, Radio, Row } from 'antd'
import Auth from '@aws-amplify/auth'
import { Trans } from '@lingui/macro'
import { PhoneOutlined, RightOutlined } from '@ant-design/icons'
import { useHistory, useLocation, useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import * as Sentry from '@sentry/browser'

import { LANGS_AVAILABLE } from '../../Routes'

//  Components
import LoginForm from './LoginForm'
import RegistrationForm from './RegistrationForm'
import EmptyHeader from '../Layouts/EmptyHeader'
import { LocalLoadingAlert } from '../Alert/LoadingAlert'
import { MediaCard } from '../Media/MediaLibrary'
import ButtonLink from '../SharedComponents/ButtonLink'

//  Actions
import { getUserInvitedData, invitedUserCompleted } from '../Organisations/redux/OrganisationsActions'
import { getUserProfileData } from '../../actions/action_user'

//  Styles
import './LoginRegistrationStyles.scss'
import RestService from '../../RestService'


export const loginRegistrationPoster = (
  <Col
    className='lawyerRegistrationHero'
    md={{ span: 12, order: 1 }}
    sm={{ span: 24, order: 2 }}
    xs={{ span: 24, order: 2 }}
  >
    {
      //  TODO: Move to whitelabel version instead of HardCoded one for transferinitiative
      window.IS_TRANSFERINITIATIVE ? (
        <div className='transferinitiativeRegistrationContent'>
          <div className='vimeoVideo'>
            <div style={{ padding: '56.25% 0 0 0', position: 'relative', marginBottom: '1rem' }}>
              <iframe
                allow='autoplay; fullscreen'
                allowFullScreen
                frameBorder='0'
                src='https://player.vimeo.com/video/505272998'
                style={{
                  position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'
                }}
                title='Introduction Video top.legal'
              />
            </div>
          </div>
        </div>
      ) : (
        <div className='contentWrapper'>
          <span className='contentTitle greenDotText'><Trans>Increase the value in your contractual relationships</Trans></span>
        </div>
      )
    }
  </Col>
)


/** ***************************************************************************
 *                    Langs buttons
 *************************************************************************** */
const noop = () => {}
export const LangSwitchButtons = ({ onClick = noop }) => {
  const { lang } = useParams()
  const { pathname, search, hash } = useLocation()
  const history = useHistory()

  return (
    <Button.Group className='languageSwitchButtons'>
      {LANGS_AVAILABLE.map(otherLang => (
        <Button
          key={otherLang}
          className='LangButton'
          ghost={lang !== otherLang}
          onClick={() => {
            history.replace(pathname.replace(`/${lang}/`, `/${otherLang}/`) + search + hash)
            if (typeof onClick === 'function') {
              onClick(otherLang)
            }
          }}
          type='primary'
        >
          {otherLang.toUpperCase()}
        </Button>
      ))}
    </Button.Group>
  )
}

/** ***************************************************************************
 *                    Invite modal
 *************************************************************************** */

const InviteModal = ({ inviteData, userIsLogged, modalHasBeenClosed, close }) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { lang } = useParams()
  const { pathname, search, hash } = useLocation()
  const dispatch = useDispatch()

  if (inviteData && !modalHasBeenClosed) {
    const { instanceTitle, instanceType, introVideo } = inviteData
    const reason = {
      template: <Trans>in order to collaborate on the template {instanceTitle}</Trans>,
      contract: <Trans>in order to collaborate on the contract {instanceTitle}</Trans>,
      project: <Trans>in order to collaborate on the project {instanceTitle}</Trans>,
      organisation: <Trans>in order to join the company {instanceTitle}</Trans>
    }[instanceType]

    if (userIsLogged) {
      return (
        <Modal
          cancelButtonProps={{ loading }}
          cancelText={<Trans>New registration</Trans>}
          className='mediumModal'
          okButtonProps={{ loading }}
          okText={<Trans>Accept on this account</Trans>}
          onCancel={async () => {
            await Auth.signOut()
            const base = `/${lang}/register`
            dispatch({ type: 'USER_LOGOUT' }) // Cleanup store
            close()
            if (!pathname.startsWith(base)) {
              history.replace(base + search + hash)
            }
          }}
          onOk={async () => {
            try {
              setLoading(true)
              await invitedUserCompleted(inviteData.token)
              window.notification.success({
                message: <Trans>Invitation accepted</Trans>,
                description: <Trans>You have successfully accepted the invitation. You will be directed to the contract/playbook shortly.</Trans>
              })
            } catch (err) {
              close(true)
              console.error('Error while processing invite', inviteData, err)
              throw err
            }
          }}
          visible
          width='60vw'
        >
          <h1 className='invitationHeader'><Trans>Welcome back to top.legal</Trans></h1>
          <p><Trans>You have been invited to top.legal {reason}.</Trans></p>
          <p><Trans>Would you like to accept the invitation with your current account or would you prefer to create a new one?</Trans></p>
        </Modal>
      )
    }

    return (
      <Modal
        cancelButtonProps={{ style: { display: 'none' } }}
        className='mediumModal'
        footer={false}
        onCancel={() => close()}
        visible
      >
        <div style={{ minWidth: '50vw' }}>
          {
            introVideo
              ? <MediaCard media={introVideo} viewerOnly />
              : (
                <div className='vimeoVideo'>
                  <div style={{ marginTop: '10px', padding: '56.25% 0 0 0', position: 'relative' }}>
                    <iframe
                      allow='autoplay; fullscreen' allowFullScreen frameBorder='0' src={lang === 'de' ? 'https://player.vimeo.com/video/393487196' : 'https://player.vimeo.com/video/393487217'}
                      style={{
                        position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'
                      }}
                    />
                  </div>
                </div>
              )
          }
        </div>
      </Modal>
    )
  }
  return null
}


export const noRegistrationTokenModal = () => {
  const modal = window.modal.info({
    className: 'smallModal noTokenRegistrationModal',
    title: <h1><Trans>Registration token not found</Trans></h1>,
    content: (
      <div className='noTokenRegistrationModalContent'>
        <p><Trans>It looks like you don’t have access to top.legal yet.</Trans></p>
        <p><Trans>Book a free call with our sales team and discover how to unlock the value of your contracts.</Trans></p>
        <div className='buttonsWrapper'>
          <ButtonLink
            href='https://meetings.hubspot.com/alexander-baron/15-minuten-vorstellung'
            icon={<PhoneOutlined />}
            noRouter
            onClick={() => modal.destroy()}
            rel='noreferrer'
            target='_blank'
            type='primary'
          >
            <Trans>Book Demo</Trans>
          </ButtonLink>
        </div>
      </div>
    )
  })
}


/** ***************************************************************************
 *                    Login & Registration Page
 *************************************************************************** */

const LoginRegistrationPage = ({ userType }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { lang, actionType } = useParams()
  const { pathname, search, hash } = useLocation()
  const displayLogin = actionType.includes('login')
  const hideButtons = !window.IS_TRANSFERINITIATIVE && !actionType.includes('_')

  const [loading, setLoading] = useState(false)
  const [inviteData, setInviteData] = useState(null)
  const [modalHasBeenClodes, setModalHasBeenClosed] = useState(false)
  const [userLoggedIn, setUserLoggedIn] = useState(false)

  //  From the url: Invite token (from invite link), code & state (from OAuth process), next for redirect to the initial page
  const [inviteToken = ''] = useQueryParam('inviteToken', StringParam)
  const [next = ''] = useQueryParam('next', StringParam)
  const [errorDesc] = useQueryParam('error_description', StringParam)
  const [source, setSource] = useQueryParam('source', StringParam)

  useEffect(() => {
    if (errorDesc) {
      history.replace(`/${lang}/login`)

      const match = errorDesc.match(/"NEED_TO_RE_LOGIN_(.+?)"/)
      if (match && match.length === 2) {
        window.modal.info({
          className: 'smallModal migrationModal',
          title: <h1><Trans>Migration done</Trans></h1>,
          content: (
            <div className='noTokenRegistrationModalContent'>
              <p><Trans>You have already created an account with your email address.</Trans></p>
              <p><Trans>You can now use both your email and {0} to login.</Trans></p>
              <p><Trans>Please authanticate again.</Trans></p>
              <div className='buttonsWrapper'>
                <Button
                  icon={<RightOutlined />}
                  onClick={() => Auth.federatedSignIn({ provider: match[1] })}
                  type='primary'
                >
                  <Trans>Continue</Trans>
                </Button>
              </div>
            </div>
          )
        })
      } else if (errorDesc.includes('NO_REGISTER_TOKEN')) {
        noRegistrationTokenModal()
      }
    }
  }, [history, errorDesc, lang])

  //  Load eventual invite data
  useEffect(() => {
    if (inviteToken) {
      setLoading(true)
      dispatch(getUserInvitedData(inviteToken)).then(setInviteData).finally(() => {
        setLoading(false)
      })
    }
  }, [dispatch, inviteToken])

  const getUserData = useCallback(async () => {
    //  Redirection
    if (!window.IS_TRANSFERINITIATIVE) {
      const user = await RestService.getCurrentCognitoSession(false)
      const isZurich = window.location.hostname.startsWith('ch-')
      const shouldBeZurich = user.attributes['custom:distributor'] === 'top.legal Zurich'

      //  Redirect user to the right instance according to the distributor
      if (shouldBeZurich !== isZurich) {
        const url = new URL(window.location.href)
        url.hostname = shouldBeZurich ? 'ch-automate.top.legal' : 'app.top.legal'
        window.location.href = url.href
        return
      }
    }

    try {
      const user = await dispatch(getUserProfileData())

      //  Setting the user for sentry
      Sentry.setUser({
        email: user.email,
        id: user.userID,
        username: `${user.firstName} ${user.lastName}`
      })

      //  Identify user in segment
      if (window.analytics) {
        window.analytics.identify(user.userID, {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email
        }, {
          integrations: {
            Intercom: {
              user_hash: user.intercomHash
            }
          }
        })
      }
      //  Product fruits
      if (window.$productFruits) {
        window.$productFruits.push(['init', 'IbkrfDXtlKGbv7Yv', 'en', {
          username: user.userID,
          email: user.email,
          firstname: user.firstName,
          lastname: user.lastName
        }])
      }

      //  TODO: Move to whitelabel version instead of HardCoded one for transferinitiative
      if (user.distributor === 'transferinitiative' && !window.IS_TRANSFERINITIATIVE) {
        await Auth.signOut()
        window.location.href = `https://${window.TRANSFERINITIATIVE_DOMAIN}/${user.preferredLanguage || lang}/login?source=top.legal`
      } else {
        //  As of today we redirect to dealrrom and it takes consideration of the different users for us
        let url = process.env.DEAL_DOMAIN
        if (next) {
          try {
            url = atob(next)
          } catch (err) {
            console.error('Invalid base64 next url', next, err)
          }
        }

        if (url.startsWith('/')) {
          history.push(url)
        } else {
          (window.top || window).location.href = url
        }
      }
    } catch (err) {
      console.error('Got error when trying to fetch user profile data')
    }
  }, [dispatch, history, next, lang])

  //  Try to get an active user session
  useEffect(() => {
    setLoading(true)
    Auth.currentSession()
      .then(() => {
        setUserLoggedIn(true)
        return getUserData()
      })
      .catch(() => {
        //  TODO: Move to whitelabel version instead of HardCoded one for transferinitiative
        if (window.IS_TRANSFERINITIATIVE && source === 'top.legal') {
          const modal = window.modal.info({
            className: 'smallModal migrationModal',
            title: <h1><Trans>Redirected to Transferinitiative RLP</Trans></h1>,
            content: (
              <div className='noTokenRegistrationModalContent'>
                <p>
                  <Trans>You have been redirected to Transferinitiative RLP please authenticate again to login.</Trans>
                </p>
                <div className='buttonsWrapper'>
                  <Button
                    onClick={() => {
                      modal.destroy()
                      setSource(undefined)
                    }}
                    type='primary'
                  >
                    <Trans>Continue</Trans>
                  </Button>
                </div>
              </div>
            )
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [getUserData, source])

  return (
    <Layout className='loginPage'>
      <EmptyHeader>
        <div className='languageSwitchHeader'>
          <LangSwitchButtons />
        </div>
      </EmptyHeader>
      <Layout.Content>
        <Row justify='center' style={{ height: '100%' }} type='flex'>
          {loginRegistrationPoster}
          <Col
            className='loginRegistrationContent'
            md={{ span: 12, order: 2 }}
            sm={{ span: 24, order: 1 }}
            xs={{ span: 24, order: 1 }}
          >
            {!hideButtons && (
              <Row align='middle' className='loginRegisterSwitch' justify='center' type='flex'>
                <Col
                  lg={18} md={22} sm={23} xl={16}
                  xs={24}
                >
                  <Radio.Group
                    buttonStyle='solid'
                    className='fullSpace'
                    defaultValue={displayLogin ? 'login' : 'register'}
                    onChange={event => history.replace(
                      pathname.replace(new RegExp(`${displayLogin ? 'login' : 'register'}(-client|-lawyer)?`), event.target.value)
                      + search + hash
                    )}
                    size='large'
                    type='default'
                  >
                    <Radio.Button value='login'><Trans id='Login' /></Radio.Button>
                    <Radio.Button value='register'><Trans id='Register' /></Radio.Button>
                  </Radio.Group>
                </Col>
              </Row>
            )}
            <Row align='middle' className='loginRegistrationInput' justify='center' type='flex'>
              <Col
                lg={20} md={22} sm={24} xl={16}
                xs={24}
              >
                <LocalLoadingAlert lightTheme loading={loading} message={<Trans>Checking your user session</Trans>}>
                  {
                    displayLogin
                      ? <LoginForm finishLogin={getUserData} />
                      : <RegistrationForm inviteData={inviteData} userType={userType} />
                  }
                </LocalLoadingAlert>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout.Content>
      <InviteModal
        close={redirect => { setModalHasBeenClosed(true); redirect && getUserData() }}
        inviteData={inviteData} modalHasBeenClosed={modalHasBeenClodes} userIsLogged={userLoggedIn}
      />
    </Layout>
  )
}

export default LoginRegistrationPage
