import React, { useContext, useLayoutEffect, useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { Button } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

import { StickyMainContent } from '../../../../../Layouts/MainLayout/MainContainer'
import { LayoutSidebarWidthContext } from '../../../../../Layouts/LayoutSidebar'

import { CHILDID_CHAR_SEPARATOR, CommentDisplayProps, CommentsToolboxConfigContext, FeedbackComment } from './Types'
import { RenderComments } from './CommentsToolbox'
import { PartCommentContext } from './PartComment'
import NewComment from './NewComment'
import Portal from '../../../../../SharedComponents/Portal'
import { ScrollbarWidthContext } from '../../../../../../GlobalContext'

interface ThreadProps extends CommentDisplayProps {
  open: boolean,
  close: () => void
  className?: string
}

const Thread: React.FC<ThreadProps> = ({ node, open, close, className = '' }) => {
  const { readOnly } = useContext(CommentsToolboxConfigContext)

  const width = useContext(LayoutSidebarWidthContext)
  const right = `${useContext(ScrollbarWidthContext)}px`
  const { getSectionElem } = useContext(PartCommentContext)

  useLayoutEffect(() => {
    let section = getSectionElem()
    section = section && section.querySelector('.sectionText')

    const hasNotActive = section && !section.classList.contains('activeSection')

    if (section && open) {
      section.classList.add('activeSection')
    }

    return () => {
      if (section && hasNotActive) {
        section.classList.remove('activeSection')
      }
    }
  }, [getSectionElem, open])

  useLayoutEffect(() => {
    if (!open) {
      return undefined
    }

    document.addEventListener('click', close)
    return () => document.removeEventListener('click', close)
  }, [open, close])

  const fakeRootNode = useMemo<any>(() => ({
    comments: {
      [(node.comment as any).date]: node
    }
  }), [node])

  const feedback = node.comment as FeedbackComment

  return (
    <Portal cssSelector='body'>
      <StickyMainContent
        className={`fixedContent commentThread ${open ? 'open' : 'close'} ${className}`}
        onClick={evt => evt.stopPropagation()}
        style={{ width: `calc(${width} + ${right})` }}
      >
        <div className='threadTitle'>
          <Button
            className='noBorder'
            ghost
            icon={<ArrowLeftOutlined />}
            onClick={close}
            shape='circle'
            type='default'
          />
          <h2><Trans>Thread</Trans></h2>
        </div>
        <div className='comments'>
          <RenderComments noThread node={fakeRootNode} />
          <p className='replies'><span><Trans>{node.comments ? Object.keys(node.comments).length : 0} replies</Trans></span></p>
          <div className='commentThreadList'>
            <RenderComments noThread node={node} />
            {!readOnly && (
              <NewComment
                childID={`${node.comment?.childID}${CHILDID_CHAR_SEPARATOR}${node.comment?.date}`}
                focus={open}
                initialData={feedback.isFeedback ? ({ isFeedback: true, contractID: feedback.contractID } as any) : undefined}
                noTask
              />
            )}
          </div>
        </div>
      </StickyMainContent>
    </Portal>
  )
}

export default Thread
