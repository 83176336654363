import React, { useCallback, useContext, useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { Tooltip } from 'antd'

import { TemplateEntityProps } from '../../../core/customPlugins/TemplateEntities'

import EditorContractContext from '../../yjsEditor/EditorContractContext'
import SelectedEntity from '../SelectedEntity'


const SectionReference: React.FC<TemplateEntityProps> = ({ dataKey, attributes, children }) => {
  const { useEntityGetters, useOnTemplateEntityClick } = useContext(EditorContractContext)
  const { getSectionRef } = useEntityGetters()
  const onTemplateEntityClick = useOnTemplateEntityClick()
  const sectionRef = useMemo(() => getSectionRef(dataKey), [dataKey, getSectionRef])
  const onClick = useCallback(() => onTemplateEntityClick('sectionReference', dataKey), [dataKey, onTemplateEntityClick])

  if (sectionRef) {
    return (
      <SelectedEntity {...attributes} Elem='span' onClick={onClick}>
        {children}
        ({sectionRef})
        {children}
      </SelectedEntity>
    )
  }

  return (
    <SelectedEntity {...attributes} Elem='span'>
      {children}
      <Tooltip title={<Trans>Your playbook logic has deactivated this section. This reference will be hidden in the final document.</Trans>}>
        <span contentEditable={false} style={{ fontWeight: 700, letterSpacing: 0.5 }}>
          <Trans>Inactive Reference</Trans>
        </span>
      </Tooltip>
      {children}
    </SelectedEntity>
  )
}

export default SectionReference
