import React from 'react'

class EditorErrorHandler extends React.Component<{ id: string }> {
  componentDidCatch (error: Error) {
    //  Ignore certain slate error and force re-rendering when it happen
    if (
      error.message.includes('Cannot resolve a Slate point from DOM')
      || error.message.includes('Cannot resolve a DOM point from Slate')
    ) {
      //  Completely ignore these errors
      throw error
    } else if (
      error.message.includes('Cannot find a descendant at path')
      || error.message.includes('Cannot apply an incomplete "set_selection" operation properties')
    ) {
      console.log('EditorErrorHandler -', this.props.id, error)
      //this.forceUpdate()
      throw error
    } else {
      console.error('EditorErrorHandler -', this.props.id, error)
      throw error
    }
  }

  render () {
    return this.props.children
  }
}

export default EditorErrorHandler
