import React from 'react'
import { NodeEntry, Range } from 'slate'
import { PlatePlugin } from '@udecode/plate-core'

export type Decorate = (nodeEntry: NodeEntry) => Range[]

interface EditorContextProps {
  plugins: PlatePlugin[]
  decorates?: Decorate[]
  readOnly?: boolean
  inlinesOnly?: boolean
  withSearch?: boolean
  fireSearchRef?: React.RefObject<((str: string) => void) | undefined>
}

const EditorContext = React.createContext<EditorContextProps>({} as any)

export default EditorContext
