import React, { PropsWithChildren, useContext, useMemo } from 'react'
import { ListItem } from '../../Listing/Types'
import { DeviceContext } from '../../../GlobalContext'


interface ListRowProps<T> {
  components: ListItem<T>[]
  tabletComponents?: ListItem<T>[]
  phoneComponents?: ListItem<T>[]
  item: T
}


const ListRow: <T>(props: PropsWithChildren<ListRowProps<T>>) => React.ReactElement = ({
  components: desktopComponents,
  tabletComponents,
  phoneComponents,
  item
}) => {
  const device = useContext(DeviceContext)
  let components = desktopComponents
  if (device === 'tablet') { components = tabletComponents || desktopComponents }
  if (device === 'phone') { components = phoneComponents || tabletComponents || desktopComponents }

  const gridTemplateColumns = useMemo(() => {
    let str = ''
    components.forEach(column => {
      str += `${column.width}fr `
    })
    return str
  }, [components])

  return (
    <div className='newListRowWrapper'>
      <div
        className='newListRow'
        style={useMemo(() => ({
          display: 'grid',
          gridTemplateColumns,
          alignItems: 'start'
        }), [gridTemplateColumns])}
      >
        {components.map(({ key, Element }) => <Element key={key} item={item} />)}
      </div>
    </div>
  )
}


export default ListRow
