import React, { useContext, useEffect, useMemo } from 'react'
//  Components
import ContractEditorComponent from '../../Contract/ContractEditor/ContractEditorComponent'
//  Contexts
import { TemplateContext } from '../../Contract/ContractEditor/Contexts'
import { SidebarToolboxes } from '../../Contract/ContractEditor/Sidebar/Sidebar'
//  Toolboxes
import TemplateEntitiesToolbox from '../../Contract/ContractEditor/Sidebar/SidebarToolboxes/TemplateEntitiesToolbox/TemplateEntitiesToolbox'
import CommentsToolbox from '../../Contract/ContractEditor/Sidebar/SidebarToolboxes/Commenting/CommentsToolbox'
import SectionSearchToolbox from '../../Contract/ContractEditor/Sidebar/SidebarToolboxes/SectionSearchToolbox'
import UserManagementToolbox from '../../Contract/ContractEditor/Sidebar/SidebarToolboxes/UserManagement/TemplateUsersManagementToolbox'
import useActivityToolbox from '../../Contract/ContractEditor/Sidebar/SidebarToolboxes/ActivityToolbox'
import ContractLayout from '../../Contract/ContractDiplay/ContractLayout'
import TableOfContent from '../../Contract/ContractEditor/TableOfContent/TableOfContent'
import { ProvideFieldsResponseScope } from '../../Contract/ContractEditor/editorRefactoredPart/utils/useEntityGetters'
import ContractEditorSectionContext
  from '../../Contract/ContractEditor/editorRefactoredPart/sectionComponents/ContractEditorSectionContext'
import { emptyArr, noop } from '../../Defaults'
import { useClausesStore } from '../../Contract/ContractEditor/editorRefactoredPart/sectionComponents/Clause'


// ==================================================================================================
/**
 * Template editor root component
 */
const empty = {}
const emptySection = { sectionID: '' } as any
const TemplateEditing: React.FC = () => {
  //  Other variable from context and ref for components
  const { template } = useContext<any>(TemplateContext)
  const activityToolbox = useActivityToolbox('template', template.templateID, template.name)

  /** ******************************************************************
   *            Manage clauses replacement from dealroom
   ****************************************************************** */
  //  Get the section saving for when we add new section
  const { useContractEditorActions } = useContext(ContractEditorSectionContext)
  const { updateSection } = useContractEditorActions('', emptyArr, emptySection, noop, noop, noop)

  useEffect(() => {
    //  Only enable this in the iframe of dealroom
    if (window.parent === window) { return undefined }

    const handler = (evt: MessageEvent) => {
      if (evt.origin === process.env.DEAL_DOMAIN) {
        //  Handle the clauseSelected event
        if (evt.data?.action === 'clauseSelected') {
          const { clause, sectionID } = evt.data.payload || {}
          if (!sectionID || !clause || !clause.clauseID || !clause.title || !clause.content) {
            console.error('Cannot replace section by clause, got invalid data', JSON.stringify(evt.data, null, 2))
          }

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          updateSection({ sectionID, clauseID: clause.clauseID })
          useClausesStore.setState(old => ({ ...old, clauses: { ...old.clauses, [clause.clauseID]: clause } }))
        }
      }
    }
    window.addEventListener('message', handler)
    return () => window.removeEventListener('message', handler)
  }, [updateSection])


  /** ******************************************************************
   *            Rendering children with all contexts
   ****************************************************************** */
  return (
    <SidebarToolboxes.Provider
      value={{
        defaultKey: 'inputField',
        defaultOpened: true,
        toolboxes: useMemo(() => [
          TemplateEntitiesToolbox,
          ...(template.templateID ? [
            CommentsToolbox('template', template.templateID),
            // TODO: Reactivate later
            // ReminderToolbox('template', template.templateID),
            SectionSearchToolbox,
            UserManagementToolbox(template.templateID),
            activityToolbox
          ] : [])
        ], [template.templateID, activityToolbox])
      }}
    >
      {/* Provide empty fields response in template editing to avoid preview data to be injected */}
      <ProvideFieldsResponseScope scope={empty}>
        <ContractLayout LeftSidebar={TableOfContent}>
          <ContractEditorComponent />
        </ContractLayout>
      </ProvideFieldsResponseScope>
    </SidebarToolboxes.Provider>
  )
}

export default TemplateEditing
