import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Trans } from '@lingui/macro'
import {
  Avatar, Button, Card, Dropdown, Menu, Select, Tooltip
} from 'antd'
import {
  BankOutlined, CheckOutlined, DeleteOutlined, GlobalOutlined, MoreOutlined, TeamOutlined, UserAddOutlined, UserOutlined
} from '@ant-design/icons'

import { useContractingPartiesCollection, useDoc, useList } from '@top-legal/datastore'

import { UserAndCompanyDataContext } from '../../../../../Layouts/Constants'
import { ContractContext, TemplateContext } from '../../../Contexts'

import SelectContact from '../../../InviteContactToContract/SelectContact'
import RestService from '../../../../../../RestService'
import { saveContract } from '../../../../redux/ContractActions'
import { AddressDisplay } from '../../../../../ContactList/ContactList'
import { useAvatarStyle, userFullName } from '../../../../../Organisations/UserRoleDisplay/UserAvatarList'
import { loadContractFieldResponses } from '../../../../../Organisations/redux/OrganisationsActions'
import loadingModalFeedback from '../../../../../Alert/LoadingModalFeedback'
import CreateContactForm from '../../../InviteContactToContract/CreateContactForm'
import CreateCompanyForm from '../../../InviteContactToContract/CreateCompanyForm'
import { ConfirmMenuItem } from '../../../../../SharedComponents/FormComponents'
import InputUsersAndTeams from '../../../../../SharedComponents/FormComponents/InputUsersAndTeams'

// styles
import './InputPartiesContact.scss'
import { DeviceContext } from '../../../../../../GlobalContext'

interface DisplayPartyProps {
  partyID: string
  partyField?: string
  customField?: any
  removeParty?: () => void
}

interface InputPartyProps {
  party: string
  setParty: (partyID: string, personData: any, companyData?: any) => void
  userAddressRequired?: boolean
  companyRequired?: boolean
}

const validAddress = (address: any = {}): boolean => (!!(address && address.address && address.city && address.postcode && address.country))


/** ****************************************************************************************
 *                        Display the title of the party card
 **************************************************************************************** */
export const PartyTitle: React.FC<Omit<DisplayPartyProps, 'partyID'>> = ({
  partyField = '',
  customField,
  removeParty
}) => {
  const { template } = useContext<any>(TemplateContext)
  const field = customField || template.fields[partyField]

  const avatarStyle = useAvatarStyle('1.5rem')

  const moreButton = removeParty && (
    <Dropdown
      overlay={(
        <Menu key='menu'>
          <ConfirmMenuItem onClick={removeParty}>
            <DeleteOutlined />
            <span><Trans>Delete</Trans></span>
          </ConfirmMenuItem>
        </Menu>
      )}
      placement='bottomRight'
      trigger={['click']}
    >
      <Button
        className='noBorder moreButton'
        ghost
        // eslint-disable-next-line no-script-url
        href='javascript:void(0)'
        icon={<MoreOutlined />}
        type='default'
      />
    </Dropdown>
  )

  if (field && field.name) {
    if (field.type === 'company') {
      const companyAvatar = (
        <Tooltip title={<Trans>Legal entity</Trans>}>
          <Avatar style={avatarStyle}><BankOutlined /></Avatar>
        </Tooltip>
      )

      return (
        <>{companyAvatar} <b>{field.name}</b> {moreButton}</>
      )
    }

    const personAvatar = (
      <Tooltip title={<Trans>Natural person</Trans>}>
        <Avatar style={avatarStyle}><UserOutlined /></Avatar>
      </Tooltip>
    )

    return <>{personAvatar} <b>{field.name}</b> {moreButton}</>
  }
  return null
}


/** ****************************************************************************************
 *                        Display a party card
 **************************************************************************************** */
export const DisplayParty: React.FC<DisplayPartyProps> = ({
  partyField = '',
  partyID,
  customField = null,
  removeParty,
  children
}) => {
  const { company } = useContext(UserAndCompanyDataContext)
  const { template } = useContext<any>(TemplateContext)

  const partiesCollection = useContractingPartiesCollection()
  const [contact] = useDoc(useMemo(() => partiesCollection.findOne(partyID), [partiesCollection, partyID]))
  const [companyDetails] = useDoc(useMemo(() => partiesCollection.findOne(contact?.companyPartyID || ''), [partiesCollection, contact]))

  const field = customField || template.fields[partyField]
  if (field && field.name) {
    if (field.type === 'company') {
      return (
        <Card
          key={field.name}
          className='betterCard partyCard'
          size='small'
          title={<PartyTitle customField={customField} partyField={partyField} removeParty={removeParty} />}
        >
          {(() => {
            if (partyID) {
              if (typeof partyID === 'string' && (partyID.startsWith('contact-') || partyID.startsWith('party-'))) {
                if (contact && contact.type === 'person') {
                  return (
                    <div className='organisationPartyData'>
                      <h4 className='name'>{userFullName(contact)}</h4>
                      <p className='userEmail'>{contact.email}</p>
                      {companyDetails && (
                        <>
                          <h4 className='name'>{companyDetails.name}</h4>
                          {companyDetails.address && <AddressDisplay address={companyDetails.address} />}
                        </>
                      )}
                    </div>
                  )
                }
              }
              const userParty = company.members[partyID]
              if (userParty) {
                return (
                  <div className='organisationPartyData'>
                    <h4 className='name'>{userFullName(userParty)}</h4>
                    <p className='userEmail'>{userParty.email}</p>
                    <h4 className='name'>{company.name}</h4>
                    <AddressDisplay address={company} />
                  </div>
                )
              }
            }

            return null
          })()}
          {children}
        </Card>
      )
    }

    return (
      <Card
        key={field.name}
        className='betterCard partyCard'
        size='small'
        title={<PartyTitle customField={customField} partyField={partyField} removeParty={removeParty} />}
      >
        {(() => {
          if (partyID) {
            if (typeof partyID === 'string' && (partyID.startsWith('contact-') || partyID.startsWith('party-'))) {
              if (contact) {
                return (
                  <div className='userPartyData'>
                    <h4 className='name'>{userFullName(contact)}</h4>
                    <p className='userEmail'>{contact.email}</p>
                    {contact.address && <AddressDisplay address={contact.address} />}
                  </div>
                )
              }
            }
            const userParty = company.members[partyID]
            if (userParty) {
              return (
                <div className='userPartyData'>
                  <h4 className='name'>{userFullName(userParty)}</h4>
                  <p className='userEmail'>{userParty.email}</p>
                  <AddressDisplay address={userParty} />
                </div>
              )
            }
          }
          return null
        })()}
        {children}
      </Card>
    )
  }
  return null
}


/** ****************************************************************************************
 *              Display default party data via type & current user data
 **************************************************************************************** */
export const DefaultParty: React.FC<{ type: 'person' | 'company' }> = ({ type }) => {
  const { user: { userID } } = useContext(UserAndCompanyDataContext)

  if (type === 'person') {
    return <DisplayParty partyField='__my_user' partyID={userID} />
  }
  if (type === 'company') {
    return <DisplayParty partyField='__my_company' partyID={userID} />
  }
  return null
}


const userAddressRequiredNotif = () => (window as any).notification.info({
  message: <Trans>Address required</Trans>,
  description: <Trans>We need the address for filling this contract</Trans>
})

const companyRequiredNotif = () => (window as any).notification.info({
  message: <Trans>Company required</Trans>,
  description: <Trans>We need a company attached to this user for filling this contract</Trans>
})

/** ****************************************************************************************
 *                        Input a person party
 **************************************************************************************** */
const emptyArray = []
export const InputParty: React.FC<InputPartyProps> = ({ party, setParty, userAddressRequired, companyRequired }) => {
  const { user, company } = useContext(UserAndCompanyDataContext)
  const isPhone = useContext(DeviceContext) === 'phone'

  const partiesCollection = useContractingPartiesCollection()
  const [contactsStore = emptyArray] = useList(useMemo(() => partiesCollection.find().sort({ updatedAt: 'desc' }), [partiesCollection]))

  //  States
  const [currentEditContact, setCurrentEditContact] = useState<any>()
  const [currentEditCompany, setCurrentEditCompany] = useState<any>()
  const [showCreateContact, setShowCreateContact] = useState(false)
  const [showCreateCompany, setShowCreateCompany] = useState(false)
  const [contactListOpened, setContactListOpened] = useState(false)

  //  On select contact handler
  const onSelectContact = useCallback(data_ => {
    //  Transform RxDoc to json object for more safety right now
    const data = data_ && (typeof data_.toJSON === 'function' ? data_.toJSON() : data_)

    if (userAddressRequired && !validAddress(data.address)) {
      setCurrentEditContact(data)
      setShowCreateContact(true)
      userAddressRequiredNotif()
      return
    }

    let companyData
    if (companyRequired) {
      if (!data.companyPartyID) {
        setCurrentEditContact(data)
        setShowCreateContact(true)
        companyRequiredNotif()
        return
      }
      if (Array.isArray(contactsStore)) {
        const companyData_ = contactsStore.find(({ partyID }) => partyID === data.companyPartyID)

        //  Transform RxDoc to json object for more safety right now
        companyData = companyData_ && (typeof companyData_.toJSON === 'function' ? companyData_.toJSON() : companyData_)

        if (companyData && !validAddress(companyData.address)) {
          setCurrentEditContact(data)
          setCurrentEditCompany(companyData)
          setShowCreateCompany(true)
          userAddressRequiredNotif()
          return
        }
      }
    }

    setCurrentEditContact(undefined)
    setCurrentEditCompany(undefined)
    setShowCreateContact(false)
    setShowCreateCompany(false)
    setContactListOpened(false)
    setParty(data.partyID, data, companyData)
  }, [userAddressRequired, companyRequired, contactsStore, setParty])

  const [memberListOpened, setMemberListOpened] = useState(false)
  const onSelectMember = useCallback(selectedUserID => {
    setParty(selectedUserID, company.members[selectedUserID], company)
    setMemberListOpened(false)
  }, [setParty, company])

  return (
    <>
      <Dropdown
        overlay={(
          <Menu key='dropdown'>
            <Menu.Item onClick={() => setMemberListOpened(true)}>
              <TeamOutlined />
              <span><Trans>Company members</Trans></span>
            </Menu.Item>
            <Menu.Item onClick={() => setContactListOpened(true)}>
              <GlobalOutlined />
              <span><Trans>Contact list</Trans></span>
            </Menu.Item>
            <Menu.Item onClick={() => setParty(user.userID, user, company)}>
              <UserOutlined />
              <span><Trans>Add {userFullName(user)}</Trans></span>
            </Menu.Item>
            <Menu.Item onClick={() => setShowCreateContact(true)}>
              <UserAddOutlined />
              <span><Trans>Create new contact</Trans></span>
            </Menu.Item>
          </Menu>
        )}
        overlayClassName='dropdown'
        placement={isPhone ? 'topCenter' : 'bottomCenter'}
        trigger={['click']}
      >
        {memberListOpened ? (
          <InputUsersAndTeams
            autoFocus
            onBlur={() => setMemberListOpened(false)}
            onChange={async ([userID]) => {
              setMemberListOpened(false)
              onSelectMember(userID)
            }}
            personsOnly
            placeholder={<Trans>Select someone</Trans>}
            selectOnly
            value={emptyArray}
          />
        ) : (
          party ? (
            <Button ghost size='small' type='primary'><Trans>Change</Trans></Button>
          ) : (
            <Button type='primary'><Trans>Select a contact</Trans></Button>
          )
        )}
      </Dropdown>
      <SelectContact
        companyRequired={companyRequired}
        onCancel={useCallback(() => setContactListOpened(false), [])}
        onFinish={onSelectContact}
        userAddressRequired={userAddressRequired}
        visible={contactListOpened}
      />
      <CreateContactForm
        companyRequired={companyRequired}
        initialValue={currentEditContact}
        onClose={useCallback(() => {
          setShowCreateContact(false)
          setCurrentEditContact(undefined)
        }, [])}
        onFinish={onSelectContact}
        userAddressRequired={userAddressRequired}
        visible={showCreateContact}
      />
      <CreateCompanyForm
        initialValue={currentEditCompany}
        onClose={useCallback(() => {
          setShowCreateCompany(false)
          setCurrentEditCompany(undefined)
          setCurrentEditContact(undefined)
        }, [])}
        onFinish={useCallback(() => onSelectContact(currentEditContact), [currentEditContact])}
        visible={showCreateCompany}
      />
    </>
  )
}


/** ****************************************************************************************
 *                        Input parties for a contract
 **************************************************************************************** */
const InputPartiesContacts: React.FC<{ hasNoParties?: boolean }> = ({ hasNoParties }) => {
  const { template } = useContext<any>(TemplateContext)
  const { contract } = useContext<any>(ContractContext)
  const { user: { userID }, company: { organisationID } } = useContext(UserAndCompanyDataContext)
  const dispatch = useDispatch()

  const [parties, setParties] = useState<any>({ ...contract.parties })
  //  Additional signatures
  const [selectedSignature, setSelectedSignature] = useState<string>('person')
  const [addedSignatures, setAddedSignatures] = useState<any[]>([])

  const allPartiesKeys = useMemo(() => {
    const partiesKeys = {}
    //  Insert all template parties key in dictionary
    if (template.parties) {
      Object.keys(template.parties).forEach(key => {
        const [baseKey] = key.split('.')
        partiesKeys[baseKey] = 1
      })
    }
    if (contract.parties) {
      Object.keys(contract.parties).forEach(key => {
        const [baseKey] = key.split('.')
        partiesKeys[baseKey] = 1
      })
    }
    //  Continue to wizard if it's empty or ask for party
    return Object.keys(partiesKeys).sort()
  }, [contract.parties, template.parties])


  const addressNeeds = useMemo(() => {
    const needs = {}

    if (Object(template.parties) === template.parties) {
      Object.keys(template.parties).forEach(key => {
        const [partyKey, ...rest] = key.split('.')
        if (rest.length === 0 || rest[0] === 'full_address') {
          needs[partyKey] = true
        }
      })
    }

    return needs
  }, [template.parties])


  //  Prefill default parties with the user default data
  useEffect(() => {
    if (allPartiesKeys.includes('__my_company')) {
      setParties(oldParties => ({
        ...oldParties,
        __my_company: userID
      }))
    }
    if (allPartiesKeys.includes('__my_user')) {
      setParties(oldParties => ({
        ...oldParties,
        __my_user: userID
      }))
    }
  }, [allPartiesKeys, organisationID, userID])

  const disableSubmit = (allPartiesKeys.some(partyKey => !parties[partyKey])) || (addedSignatures.some(party => !party.partyID))

  if (hasNoParties) {
    return (
      <>
        <div className='addSignature'>
          <div className='top'><strong><UserAddOutlined /><Trans>Add signature</Trans></strong></div>
          <Card className='addSignatureCard'>
            <div className='cardTop'>
              <h3><Trans>Who is signing this contract?</Trans></h3>
            </div>
            <Select className='simple-line' defaultValue='person' onChange={value => setSelectedSignature(value)}>
              <Select.Option value='person'><UserOutlined /> &nbsp; <Trans>Person</Trans></Select.Option>
              <Select.Option value='company'><BankOutlined /> &nbsp; <Trans>Company</Trans></Select.Option>
            </Select>
            <Button
              onClick={() => setAddedSignatures(oldSignatures => ([...oldSignatures, { type: selectedSignature }]))}
              type='primary'
            >
              <Trans>Add Signature</Trans>
            </Button>
          </Card>
        </div>
        <div className='partyCardsWrapper'>
          {addedSignatures.map((newSignature, index) => {
            const onSelect = contact => {
              setAddedSignatures(oldParties => {
                oldParties = [...oldParties]
                oldParties[index].partyID = contact.contactID
                // oldParties[index].name = `Signer ${index + 1}`
                oldParties[index].type = newSignature.type
                return oldParties
              })
            }

            const setParty = value => {
              if (Object(value) === value) {
                onSelect(contract)
              } else {
                setAddedSignatures(oldParties => {
                  oldParties = [...oldParties]
                  oldParties[index].partyID = value
                  // oldParties[index].name = `Signer ${index + 1}`
                  oldParties[index].type = newSignature.type
                  return oldParties
                })
              }
            }

            const removeParty = key => {
              setAddedSignatures(oldParties => {
                oldParties = [...oldParties]
                return oldParties.filter(party => oldParties.indexOf(party) !== key)
              })
            }

            return (
              <DisplayParty
                key={`newSignature-${index}`}
                customField={{
                  name: <Trans>Signer {index + 1}</Trans>,
                  type: newSignature.type
                }}
                partyID={newSignature.partyID}
                removeParty={() => removeParty(index)}
              >
                <InputParty
                  companyRequired={newSignature.type === 'company'}
                  party={newSignature.partyID}
                  setParty={setParty}
                />
              </DisplayParty>
            )
          })}
        </div>
        <div className='submitContainer'>
          <Button
            disabled={disableSubmit || (Object.keys(addedSignatures).length === 0)}
            icon={<CheckOutlined />}
            onClick={() => loadingModalFeedback({
              loadingTitle: <Trans>Filling contract with selected parties data</Trans>,
              successTitle: <Trans>We filled the data from the selected parties</Trans>,
              errorTitle: <Trans>Cannot fill contract with selected parties</Trans>,
              errorDescription: <Trans>An error error occurred while filling the contract data with selected parties. Please try again later.</Trans>,
              autoSuccessClose: 2000
            })(async () => {
              if (contract.contractID) {
                await RestService('POST', `/contract/${contract.contractID}/parties`, {
                  organisationID,
                  parties: {},
                  addedSignatures
                })
                await dispatch(loadContractFieldResponses(contract.contractID))
              } else {
                //  loadContractFieldResponses is used inside no need to do it there
                await dispatch(saveContract({
                  parties: {},
                  addedSignatures
                }))
              }
            })}
            type='primary'
          >
            <Trans>Continue</Trans>
          </Button>
        </div>
      </>
    )
  }


  const onSelect = (partyKey, partyID) => setParties(oldParties => ({
    ...oldParties,
    [partyKey]: partyID
  }))

  return (
    <>
      <h2 className='partyFillingTitle'><Trans>Add contracting party</Trans></h2>
      <div className='partyCardsWrapper'>
        {allPartiesKeys.map(partyKey => {
          const party = parties[partyKey]
          const field = template.fields[partyKey]
          if (field && field.name) {
            return (
              <DisplayParty key={partyKey} partyField={partyKey} partyID={party}>
                <InputParty
                  companyRequired={field.type === 'company'}
                  party={party}
                  setParty={partyID => onSelect(partyKey, partyID)}
                  userAddressRequired={field.type === 'person' && addressNeeds[partyKey]}
                />
              </DisplayParty>
            )
          }
          return null
        })}
      </div>
      <div className='submitContainer'>
        <Button
          disabled={disableSubmit || hasNoParties}
          icon={<CheckOutlined />}
          onClick={() => loadingModalFeedback({
            loadingTitle: <Trans>Filling contract with selected parties data</Trans>,
            successTitle: <Trans>We filled the data from the selected parties</Trans>,
            errorTitle: <Trans>Cannot fill contract with selected parties</Trans>,
            errorDescription: <Trans>An error error occurred while filling the contract data with selected parties. Please try again later.</Trans>,
            autoSuccessClose: 2000
          })(async () => {
            if (contract.contractID) {
              await RestService('POST', `/contract/${contract.contractID}/parties`, {
                organisationID,
                parties
              })
              await dispatch(loadContractFieldResponses(contract.contractID))
            } else {
              //  loadContractFieldResponses is used inside no need to do it there
              await dispatch(saveContract({ parties }))
            }
          })}
          type='primary'
        >
          <Trans>Continue</Trans>
        </Button>
      </div>
    </>
  )
}

export default InputPartiesContacts
