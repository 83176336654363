import { useEffect } from 'react'

const analyticsProviders = [
  //  Segment Analytics
  'https://analytics.top.legal/analytics.js/v1/QfAEgy7yy16ayD0HXzJNVeijYBDlLIBK/analytics.min.js',

  //  HubSpot Analytics & Chat
  // 'https://js.hs-scripts.com/20415819.js',

  //  Product fruits
  'https://app.productfruits.com/static/script.js'
]


const additionalProviders = [
  //  OGV webm support for safari
  'https://cdn.jsdelivr.net/npm/ogv@1.8.4/dist/ogv.js',

  //  Google place API for address auto complete
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyCd5fu7-LFDgmyZI_n4zHeoTlGX70G5yUE&libraries=places'
]


//  Only add analytics providers when not in E2E testing
if (!process.env.REACT_APP_E2E_TESTS) {
  additionalProviders.push(...analyticsProviders)

  ;(window as any).productFruits = (window as any).productFruits || {}
  ;(window as any).productFruits.scrV = '2'
  ;(window as any).$productFruits = []
}


//  Provide a hook that gonna mount and unmount the scripts
export const useAdditionalProviders = () => useEffect(() => {
  const elements = additionalProviders.map(src => {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    script.defer = true
    document.head.appendChild(script)
    return script
  })

  return () => elements.forEach(elm => elm.remove())
}, [])
