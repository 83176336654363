/* eslint-disable max-lines */
/* eslint-disable react/display-name */
import React, { useCallback } from 'react'
import { Alert, Form, Input, InputNumber as InputNumber_ } from 'antd'
import { Trans } from '@lingui/macro'
import { ConsoleLogger as Logger } from '@aws-amplify/core'

//  Editor library
import { FormEditor } from '@top-legal/editor'

import {
  CheckboxGroup,
  CheckboxGroupIcons,
  GoogleAutoCompleteAddress,
  InlineRadioGroupButtons,
  PhoneInput,
  RadioGroup,
  RadioGroupIcons,
  RadioGroupIconsClick,
  Select,
  SortableListComponent
} from '../SharedComponents/FormComponents/index'

import * as _TemplateHelperFunctions from './_TemplateHelperFunctions'
import { getElem, getValue } from './_TemplateHelperFunctions'
import PartExplanation from './PartExplanation'
import { DatePicker, TimePicker } from '../SharedComponents/DateTimePicker'
import ProductsCompletionInput
  from '../Contract/ContractEditor/TemplateEntities/TemplateEntitiesForms/InputFieldConfigurations/ProductsCompletionInput'


const logger = new Logger('TemplateHelperFunctions')


export const generateCompanyEntity = (fieldKey, fieldName) => {
  const _field = _TemplateHelperFunctions.generateCompanyEntity(fieldKey, fieldName)
  const field = {
    [fieldKey]: {
      items: {
        name: {
          question: <Trans>Please enter the company Name</Trans>
        },
        industry: {
          question: <Trans>Please select the company industry</Trans>
        },
        employees: {
          question: <Trans>Please select the number of employees</Trans>
        },
        full_address: {
          question: <Trans>Please select the address</Trans>,
          component: function autoCompleteAdress ({ initialValue, ...props }) {
            const children = _field[fieldKey].items.full_address.items
            const prefix = '' // `${fieldKey}-full_address-`
            const reelInitialValue = {
              [`${prefix}country`]: children.country.value(initialValue),
              [`${prefix}postcode`]: children.postcode.value(initialValue),
              [`${prefix}city`]: children.city.value(initialValue),
              [`${prefix}address`]: children.address.value(initialValue)
            }
            return (<GoogleAutoCompleteAddress {...props} initialValue={reelInitialValue} prefix={prefix} required />)
          },
          items: {
            country: {
              question: <Trans>Please enter the country of your company</Trans>
            },
            postcode: {
              question: <Trans>Please enter the postcode of your company</Trans>
            },
            city: {
              question: <Trans>Please enter the city of your company</Trans>
            },
            address: {
              question: <Trans>Please enter the street name of your company</Trans>
            }
          }
        },
        vatID: {
          question: <Trans>Please enter your company‘s VAT-ID</Trans>
        },
        commercialID: {
          question: <Trans>Please enter your company‘s commercial ID</Trans>
        }
      }
    }
  }
  const mergedField = _TemplateHelperFunctions.mergeObjects(_field, field)

  Object.defineProperty(mergedField[fieldKey].items, 'signature', {
    configurable: true,
    enumerable: false,
    value: _field[fieldKey].items.signature
  })

  return mergedField
}

export const generateUserEntity = (fieldKey, fieldName, getLang) => {
  const _field = _TemplateHelperFunctions.generateUserEntity(fieldKey, fieldName, getLang)
  const field = {
    [fieldKey]: {
      items: {
        full_name: {
          question: <Trans>Please enter the full name of the person</Trans>,
          items: {
            firstName: {
              question: <Trans>Please enter the first name of the person</Trans>
            },
            lastName: {
              question: <Trans>Please enter the last name of the person</Trans>
            }
          }
        },
        email: {
          question: <Trans>Please enter the email of the person</Trans>
        },
        gender: {
          question: <Trans>Please select the gender of the person</Trans>
        },
        dateOfBirth: {
          question: <Trans>Please enter the date of birth of the person</Trans>
        },
        full_address: {
          question: <Trans>Please enter the full address of the person</Trans>,
          component: function autoCompleteAdress ({ initialValue, ...props }) {
            const children = _field[fieldKey].items.full_address.items
            const prefix = '' // `${fieldKey}-full_address-`
            const reelInitialValue = {
              [`${prefix}country`]: children.country.value(initialValue),
              [`${prefix}postcode`]: children.postcode.value(initialValue),
              [`${prefix}city`]: children.city.value(initialValue),
              [`${prefix}address`]: children.address.value(initialValue)
            }
            return (<GoogleAutoCompleteAddress {...props} initialValue={reelInitialValue} prefix={prefix} required />)
          },
          items: {
            country: {
              question: <Trans>Please enter the country of the person</Trans>
            },
            postcode: {
              question: <Trans>Please enter the postcode of the person</Trans>
            },
            city: {
              question: <Trans>Please enter the city of the person</Trans>
            },
            address: {
              question: <Trans>Please enter the address of the person</Trans>
            }
          }
        }
      }
    }
  }
  const mergedField = _TemplateHelperFunctions.mergeObjects(_field, field)

  Object.defineProperty(mergedField[fieldKey].items, 'signature', {
    configurable: true,
    enumerable: false,
    value: _field[fieldKey].items.signature
  })

  return mergedField
}

const WrappedDateTimePicker = ({ type = 'date', ...props }) => {
  const Element = {
    date: DatePicker,
    time: TimePicker
  }[type]

  return <Element {...props} inputYearMonthDate noDateConversion />
}

const controlCodes = ['ArrowLeft', 'ArrowRight', 'Delete', 'Backspace']
const validNumberRegex = /[0-9.,]/
const numberInputExtraProps = {
  formatter: value => value && new Intl.NumberFormat('de-DE').format(value),
  parser: value => {
    const float = parseFloat(value.replace(/,/g, 'ß').replace(/[^\dß]/g, '').replace('ß', '.'))
    return value[0] === '-' ? -float : float
  },
  onKeyDown: evt => {
    if (!controlCodes.includes(evt.code) && !validNumberRegex.exec(evt.key)) {
      evt.preventDefault()
    }
  }
}

export const InputNumber = ({ allowNegative = false, ...props }) => (
  <InputNumber_
    {...props}
    {...numberInputExtraProps}
    // Allow number to be negative
    onKeyDown={useCallback(evt => {
      if (evt.key === '-') {
        if (!allowNegative || evt.target.selectionStart !== 0) {
          evt.preventDefault()
        }
      } else {
        numberInputExtraProps.onKeyDown(evt)
      }
    }, [allowNegative])}
  />
)


/**
 * This mapping is for generating the correct component with the given string
 */
export const ComponentsMapping = {
  yesNo: () => (
    <InlineRadioGroupButtons
      items={{
        yes: <Trans>Yes</Trans>,
        no: <Trans>No</Trans> // eslint-disable-line
      }}
    />
  ),
  // input for the function is static, pls don't delete variables
  singleText: (_, language, placeholder) => <Input className='simple-line' placeholder={placeholder || ''} />,
  singleTextArea: (_, language, placeholder) => <FormEditor placeholder={placeholder || ''} />,

  number: (_, language, placeholder) => (
    <InputNumber
      allowNegative
      className='simple-line'
      placeholder={placeholder || ''}
      step={0.01}
    />
  ),
  date: (_, language, placeholder) => <WrappedDateTimePicker className='simple-line' placeholder={placeholder} type='date' />,
  time: (_, language, placeholder) => <WrappedDateTimePicker className='simple-line' placeholder={placeholder} type='time' />,

  phone: (_, language, placeholder) => <PhoneInput className='simple-line' placeholder={placeholder || ''} />,

  amount: (_, language, placeholder) => (
    <InputNumber
      className='simple-line moneyInput'
      min={0}
      placeholder={placeholder || ''}
      step={0.01}
    />
  ),

  checkboxGroup: (options, language, placeholder, clickIcon, label = 'text') => (
    <CheckboxGroup clickIcon={clickIcon} items={options} label={label} language={language} />
  ),
  radioGroup: (options, language, placeholder, clickIcon, label = 'text') => (
    <RadioGroup clickIcon={clickIcon} items={options} label={label} language={language} />
  ),
  checkboxGroupIcons: (options, language, placeholder, clickIcon, label = 'text') => (
    <CheckboxGroupIcons clickIcon={clickIcon} items={options} label={label} language={language} />
  ),
  radioGroupIconsClick: (options, language, placeholder, clickIcon, label = 'text') => (
    <RadioGroupIconsClick clickIcon={clickIcon} items={options} label={label} language={language} />
  ),
  radioGroupIcons: (options, language, placeholder, clickIcon, label = 'text') => (
    <RadioGroupIcons clickIcon={clickIcon} items={options} label={label} language={language} />
  ),
  selectBoxSingle: (options, language, placeholder, _, label = 'text') => (
    <Select
      className='simple-line'
      items={options}
      label={label}
      language={language}
      placeholder={placeholder}
    />
  ),

  selectBoxMultiple: (options, language, placeholder, _, label = 'text') => (
    <Select
      className='simple-line'
      items={options}
      label={label}
      language={language}
      multiple
      placeholder={placeholder}
    />
  ),
  sortList: (options, language, placeholder, clickIcon, label = 'text') => (
    <SortableListComponent clickIcon={clickIcon} items={options} label={label} language={language} />
  )
}

// ==================================================================================================

const NO_COMPONENT = () => (<Input disabled placeholder={<Trans>Error undefined form element!</Trans>} />)


/** *************************************************************************************************
 *                    Types & constants for Template & Contract generators
 ************************************************************************************************* */

// const CombinedListFilling = () => null

// /////////////////////////////////////////////////////////
//          Easier type mapping for template
// /////////////////////////////////////////////////////////
export const templateInputsType = {
  company: <span><span><Trans>Company</Trans> </span><i style={{ fontSize: '0.7rem' }}><Trans>(optional electronic signature)</Trans></i></span>,
  person: <span><span><Trans>Person</Trans> </span><i style={{ fontSize: '0.7rem' }}><Trans>(optional electronic signature)</Trans></i></span>,
  product: <Trans>Product & Services</Trans>,
  yesNo: <Trans>Yes / No</Trans>,
  oneLineText: <Trans>Single Line Text</Trans>,
  multipleLineText: <Trans>Multi Line Text</Trans>,
  number: <Trans>Number</Trans>,
  phone: <Trans>Phone Number</Trans>,
  date: <Trans>Date</Trans>,
  time: <Trans>Time</Trans>,
  //  The last two ones require some configuration
  list: <Trans>List</Trans>,
  listOfFormattedText: <Trans>Combined List</Trans>,
  amount: <Trans>Amount</Trans>
}

// ==================================================================================================
// ==================================================================================================

function getFormComponent (field, lang) {
  if (field.type === 'list') {
    const values = field.values[lang] || field.values
    const isLongList = (Array.isArray(values) ? values.length : Object.keys(values).length) > 15
    if (field.isMultipleChoices) {
      if (isLongList) {
        return ComponentsMapping.selectBoxMultiple
      }
      return ComponentsMapping.checkboxGroup
    }
    if (isLongList) {
      return ComponentsMapping.selectBoxSingle
    }
    return ComponentsMapping.radioGroup
  }

  return {
    yesNo: ComponentsMapping.yesNo,
    oneLineText: ComponentsMapping.singleText,
    multipleLineText: ComponentsMapping.singleTextArea,
    number: ComponentsMapping.number,
    phone: ComponentsMapping.phone,
    date: ComponentsMapping.date,
    time: ComponentsMapping.time,
    amount: ComponentsMapping.amount,
    product: () => <ProductsCompletionInput fieldKey={field.fieldKey} products={[field]} />
  }[field.type] || NO_COMPONENT
}

// /////////////////////////////////////////////////////////
//        END Easier type mapping for template
// /////////////////////////////////////////////////////////

export const dateTypes = {
  days: <Trans>Days</Trans>,
  months: <Trans>Months</Trans>,
  years: <Trans>Years</Trans>
}
export const timeTypes = {
  minutes: <Trans>Minutes</Trans>,
  hours: <Trans>Hours</Trans>
}

const makeCategory = text => ({ text, color: 'blue' })
export const templateCategories = {
  startup: makeCategory(<Trans>Startup</Trans>),
  ip: makeCategory(<Trans>Intellectual property</Trans>), // eslint-disable-line
  compliance: makeCategory(<Trans>Compliance</Trans>),
  investment: makeCategory(<Trans>Investments</Trans>),
  mergersAcquisitions: makeCategory(<Trans>Mergers Acquisitions</Trans>),
  realEstate: makeCategory(<Trans>Real Estate</Trans>),
  internet: makeCategory(<Trans>Internet</Trans>),
  dataPrivacy: makeCategory(<Trans>Data privacy</Trans>),
  corporate: makeCategory(<Trans>Corporate</Trans>),
  commercial: makeCategory(<Trans>Commercial</Trans>),
  labour: makeCategory(<Trans>Labour</Trans>),
  banking: makeCategory(<Trans>Bank</Trans>),
  competition: makeCategory(<Trans>Competition</Trans>),
  litigation: makeCategory(<Trans>Litigation</Trans>),
  patent: makeCategory(<Trans>Patents</Trans>),
  tax: makeCategory(<Trans>Tax</Trans>),
  other: makeCategory(<Trans>Other</Trans>)
}

/** *************************************************************************************************
 *                    Utils functions for Template & Contract generators
 ************************************************************************************************* */

// ==================================================================================================


/**
 * This function is used for building inputs from a inputField definition
 * It's used either in TemplateTextEditor as preview and ContractWizard form building form
 *
 * @param {object} template contains the complete set of information for this template
 * @param {string} fieldKey input element name fetched from the data-key of the input elmeent
 * @param {object} form form object created in the parent component
 * @param {object} initialValue
 * @param {boolean} isGroupMember
 * @param {object} inputFieldProperties
 * @param {string} lang language
 * @param {boolean} isRequired sets whether the form item needs to be validated (which is not necessary
 * for the preview of form items)
 * @returns {*}
 */
//  TODO: Remove the entire dependency of buildContractInputQuestion and old contract wizard
export const buildContractInputQuestion = (
  template,
  fieldKey,
  form,
  initialValue = {},
  inputFieldProperties = null,
  lang = 'de',
  isRequired = true
) => {
  // /////////////////////////////////////////////////////////////////////////
  //                      Constants definition
  // /////////////////////////////////////////////////////////////////////////
  //  Get inputFieldProperties if the parent don't already got it (it is just for efficiency don't get two times the same things)
  if (!inputFieldProperties) {
    inputFieldProperties = getElem(fieldKey, template.fields)
  }

  //  If no fields was found display a a input error
  if (!inputFieldProperties) {
    return <Alert message={<Trans>Error invalid field!</Trans>} showIcon type='error' />
  }

  //  If explanations are provided build the tips box
  let explanationSection = null
  if (inputFieldProperties.explanationText || inputFieldProperties.explanationVideo) {
    explanationSection = <PartExplanation isInputField media={inputFieldProperties.explanationVideo} text={inputFieldProperties.explanationText} />
  }

  // /////////////////////////////////////////////////////////////////////////
  //                  Signature stamp is a hidden field
  // /////////////////////////////////////////////////////////////////////////
  if (inputFieldProperties.type === 'signatureStamp') {
    return <span className={`signatureStamp ${lang}`} data-name={inputFieldProperties.name} />
  }

  // /////////////////////////////////////////////////////////////////////////
  //                      List of formatted text
  // /////////////////////////////////////////////////////////////////////////
  if (inputFieldProperties.type === 'listOfFormattedText') {
    //  TODO: Remove the entire dependency of buildContractInputQuestion and old contract wizard
    return null
  }

  // /////////////////////////////////////////////////////////////////////////
  //           Fields that have predefined component to use
  // /////////////////////////////////////////////////////////////////////////
  if (inputFieldProperties.component) {
    //  If I have component use this one as form component
    const InnerComponent = inputFieldProperties.component
    return (
      <div>
        {inputFieldProperties.question && <h2 className='questionHeader'>{inputFieldProperties.question}</h2>}
        <InnerComponent
          fieldKey={fieldKey}
          fields={template.fields}
          form={form}
          initialValue={getValue(template.fields, fieldKey, initialValue, false)}
        />
        {explanationSection}
      </div>
    )
  }

  // /////////////////////////////////////////////////////////////////////////
  //            Fields that have nested fields (Company & Person)
  // /////////////////////////////////////////////////////////////////////////
  if (inputFieldProperties.items) {
    //  If I have items I need to render all my children fields
    return Object.keys(inputFieldProperties.items)
      .map(key => {
        const innerRequired = isRequired && !inputFieldProperties.items[key].optional
        if (inputFieldProperties.items[key].hide) {
          return null
        }
        return (
          <div key={`${fieldKey}-${key}`}>
            {buildContractInputQuestion(template, `${fieldKey}.${key}`, form, initialValue, undefined, lang, innerRequired)}
          </div>
        )
      })
  }

  // /////////////////////////////////////////////////////////////////////////
  //                   For al other normal fields
  // /////////////////////////////////////////////////////////////////////////

  logger.debug('inputFieldProperties: ', inputFieldProperties)
  //  Build a acceptable key for the form
  const newFieldKey = fieldKey.split('.').slice(-1)[0]
  //  Get the correct component from the input type
  const elementCreatorFunction = getFormComponent(new Proxy(inputFieldProperties, {
    get: (target, prop) => (prop === 'fieldKey' ? newFieldKey : target[prop]) //  Simple hack to give access to the newFieldKey
  }), lang)

  //  Render it
  return (
    <div className='formElementWrapper'>
      <fieldset>
        {inputFieldProperties.unit
          ? <h2 className='questionHeader'>{inputFieldProperties.question} {`(${inputFieldProperties.unit})`}</h2>
          : <h2 className='questionHeader'>{inputFieldProperties.question}</h2>}
        <Form.Item
          className='questionFormItem'
          initialValue={getValue(template.fields, fieldKey, initialValue, false)}
          name={newFieldKey}
          rules={isRequired ? [{
            required: true,
            message: <Trans>The input is required</Trans>
          }] : null}
        >
          {elementCreatorFunction(
            inputFieldProperties.values && (inputFieldProperties.values[lang] || inputFieldProperties.values),
            template.lang,
            inputFieldProperties.name,
            null,
            null
          )}
        </Form.Item>
        {explanationSection}
      </fieldset>
    </div>
  )
}
