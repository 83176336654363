import React from 'react'
import { Input } from 'antd'

import { AssistantInputProps } from './Types'
import useFieldSaving from './inputElements/useFieldSaving'

const PersonFullNameInput: React.FC<AssistantInputProps> = props => {
  const { field, fieldKey } = props as any

  return (
    <>
      <Input
        className='simple-line'
        placeholder={field.items.firstName.name}
        {...useFieldSaving<string>({ ...props, field: field.items.firstName, fieldKey: `${fieldKey}.firstName` })}
      />
      <Input
        className='simple-line'
        placeholder={field.items.lastName.name}
        {...useFieldSaving<string>({ ...props, field: field.items.lastName, fieldKey: `${fieldKey}.lastName` })}
      />
    </>
  )
}

export default PersonFullNameInput
