import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadOrganisationEvents } from './components/Dashboard/Logs'
import { defaultCompany } from './components/Layouts/Constants'

export const promises = {}


const loadData = () => (dispatch, getState) => {
  //  First cleanup promises
  delete promises.organisationEvents

  //  Then load if we have a userID and an organisationID
  if (getState().user.ownProfileData.userID && getState().organisation.selectedOrganisation.organisationID) {
    //  Load organisation events
    promises.organisationEvents = dispatch(loadOrganisationEvents(true)).then(() => {
      promises.organisationEvents.done = true
    })
  }
}

const PreLoadData = () => {
  const dispatch = useDispatch()
  const { organisationID } = useSelector(state => state.organisation.selectedOrganisation)

  //  Handle change of organisationID for preloading data
  useEffect(() => {
    if (organisationID && organisationID !== defaultCompany.organisationID) {
      setTimeout(() => dispatch(loadData()))
    }
  }, [dispatch, organisationID])

  return null
}

export default PreLoadData
