import React, { useContext, useMemo } from 'react'
import { TRenderElementProps } from '@udecode/plate-core'
import { Trans } from '@lingui/macro'

import { MarkerTypeBlock, MarkerTypeInline, SectionRefType, TemplateEntityType as SlateTemplateEntityType } from '../../core/Constants'
import { TemplateEntityNode, TemplateEntityProps } from '../../core/customPlugins/TemplateEntities'

import EditorContractContext from '../yjsEditor/EditorContractContext'
import SelectedEntity from './SelectedEntity'
import InputField from './templateEntitiesComponents/InputField'
import ConditionalText from './templateEntitiesComponents/ConditionalText'
import SectionReference from './templateEntitiesComponents/SectionReference'


const mapping = {
  // eslint-disable-next-line react/display-name
  inputField: props => <InputField {...props} />,
  // eslint-disable-next-line react/display-name
  conditionalText: props => <ConditionalText {...props} />,
  // eslint-disable-next-line react/display-name
  sectionReference: props => <SectionReference {...props} />,
  // eslint-disable-next-line react/display-name
  INVALID: ({ attributes }) => (
    <span {...attributes} className='invalidTemplateEntity' contentEditable={false}>
      /!\ <Trans>Invalid entity</Trans> /!\
    </span>
  )
}


const TemplateEntity: React.FC<Omit<TRenderElementProps, 'children'> & { renderOnly?: boolean, className?: string }> = ({
  element,
  attributes,
  className,
  renderOnly,
  children
}) => {
  const { obfuscationMapping, useEntityGetters } = useContext(EditorContractContext)
  const { getField } = useEntityGetters()
  const intVersion = useMemo(() => {
    const num = parseInt(element.version, 10)
    return Number.isNaN(num) ? 1 : num
  }, [element.version])

  attributes = { ...attributes }
  if (className) {
    (attributes as any).className = `${(attributes as any).className || ''} ${className}`
  }


  const renameRefElm = (attrs: any = attributes) => {
    //  Rename the elem ref for passing down to the dom element in child component
    attrs.elmRef = attrs.ref
    delete attrs.ref
  }


  if ([MarkerTypeBlock, MarkerTypeInline].includes(element.type)) {
    const newAttributes = attributes as any
    let Elem: any = element.type === MarkerTypeInline ? 'span' : 'div'
    const classes = [newAttributes.className || '', element.type === MarkerTypeInline ? 'inlineElement' : 'blockElement']

    if (obfuscationMapping) {
      const [entityType, key] = obfuscationMapping.obfuscatedToEntity[element._ as number] || []
      if (entityType && key) {
        classes.push(entityType)

        //  Quick class injection for product field
        if (entityType === 'inputField') {
          const field = getField(key)
          if (field?.type === 'product' || field?.type === 'products') {
            classes.push('fullWidthEntity')
          }
        }

        newAttributes['data-key'] = key
        newAttributes.Elem = Elem
        Elem = SelectedEntity
        renameRefElm(newAttributes)
      }
    } else if (element.type === MarkerTypeBlock) {
      //  For the obfuscated version let's put the block in full width
      classes.push('fullWidthEntity')
    }

    return <Elem {...newAttributes} className={classes.join(' ')} style={{ cursor: 'auto' }}>{children}</Elem>
  }

  //  For al the other case rename the ref
  renameRefElm()

  if (element.type === SectionRefType) {
    const { key } = element as any as TemplateEntityNode
    const newAttributes = { attributes } as any
    newAttributes.dataKey = key

    if (obfuscationMapping) {
      newAttributes.attributes.className = 'sectionReference'
      newAttributes.attributes['data-key'] = key
    }

    return <SectionReference {...newAttributes}>{children}</SectionReference>
  }

  if (element.type === SlateTemplateEntityType) {
    //  In cas eof render only remove children
    if (renderOnly) { children = '' }

    const { entityType, key, version } = element as any as TemplateEntityNode
    const Component: React.FC<TemplateEntityProps> = mapping[entityType] || mapping.INVALID

    //  Increment data into our html attributes
    attributes = {
      ...attributes,
      className: entityType,
      'data-key': key,
      'data-version': version
    } as any

    return (
      <Component attributes={attributes} dataKey={key} version={intVersion}>
        {children}
      </Component>
    )
  }

  return null
}

export default TemplateEntity
