import React from 'react'
import { t, Trans } from '@lingui/macro'
import { Button, Input, InputNumber } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

//  Editor library
import { FormEditor } from '@top-legal/editor'

import { DatePicker } from '../../../../SharedComponents/DateTimePicker'
import { ConfirmButton, Select } from '../../../../SharedComponents/FormComponents'
import { dateTypes, timeTypes } from '../../../../Template/TemplateHelperFunctions'

const ConditionalTextEntriesEditing: React.FC<any> = ({ value, onChange, type, isRelativeToContractFillingDate, conditional }) => {
  const isArray = Array.isArray(value)

  return (
    <div className='conditionalTextEntriesList'>
      {Object.entries<any>(value).map(([key, initialValue]) => (
        <div key={key} className='conditionalTextEntry'>
          {/(date|time|number|amount|listOfFormattedText)/.test(type) ? (
            <>
              <h3><Trans>Less or equal to</Trans></h3>
              <div className='inputs'>
                {/(date|time)/.test(type) ? (
                  ((type === 'time' || isRelativeToContractFillingDate) && (
                    <Input.Group compact>
                      <InputNumber
                        onChange={newVal => {
                          const array = [...value]
                          array[key].value = newVal
                          onChange(array)
                        }}
                        placeholder={t`Value`}
                        size='small'
                        style={{ width: '50%' }}
                        value={initialValue.value}
                      />
                      <Select
                        items={type === 'date' ? dateTypes : timeTypes}
                        noSearch
                        onChange={newVal => {
                          const array = [...value]
                          array[key].type = newVal
                          onChange(array)
                        }}
                        placeholder={t`- Type -`}
                        size='small'
                        style={{ width: '50%' }}
                        value={initialValue.type}
                      />
                    </Input.Group>
                  )) || (
                    <DatePicker
                      inputYearMonthDate
                      noDateConversion
                      onChange={newVal => {
                        const array = [...value]
                        array[key].value = newVal
                        onChange(array)
                      }}
                      style={{
                        borderRadius: 0,
                        width: '100%',
                        padding: '0 1rem'
                      }}
                      value={initialValue.value}
                    />
                  )
                ) : (
                  <InputNumber
                    onChange={newVal => {
                      const array = [...value]
                      array[key].value = newVal
                      onChange(array)
                    }}
                    placeholder={t`Enter a number`}
                    size='small'
                    style={{ width: '100%' }}
                    value={initialValue.value}
                  />
                )}
                {Array.isArray(value) && (
                  <ConfirmButton
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      const array = [...value]
                      array.splice(parseInt(key, 10), 1)
                      onChange(array)
                    }}
                    size='small'
                    type='primary'
                  />
                )}
              </div>
            </>
          ) : <h3>{initialValue.label}</h3>}
          <FormEditor
            inlinesOnly={conditional.slateType === 'inline'}
            onChange={nodes => {
              const data = isArray ? [...value] : { ...value }
              data[key].text = nodes
              onChange(data)
            }}
            value={initialValue.text}
          />
        </div>
      ))}
      {isArray && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
          <Button
            className='addEntry'
            icon={<PlusOutlined />}
            onClick={() => onChange([...value, {}])}
            type='primary'
          >
            <Trans>Add a new option</Trans>
          </Button>
        </div>
      )}
    </div>
  )
}

export default ConditionalTextEntriesEditing
