import React from 'react'
import { Trans } from '@lingui/macro'

import {noop} from "../Defaults";

export interface InstanceRenderingProps {
  instance: any
  Highlight: React.FC<{ text: string }>
  search: string
}

export type Category = '__ALL__' | 'contracts' | 'templates' | 'parties' | 'conditionalTexts' | 'inputFields' | 'clauses' | 'media'

type CategoryMapping = { [key in Category]: React.ReactNode }

export interface AdditionalFilter {
  Component: React.ReactNode
  filterInstance: (instance: any) => boolean
}

export interface UseSearchReturn {
  category: Category
  filterInstances: (search: string) => any[]
  InstanceRendering: React.FC<InstanceRenderingProps>
  getID: (instance: any) => string
  loading?: boolean
  loadingText?: React.ReactNode
  AdditionalFilters?: React.FC
}

export const categoryMapping: CategoryMapping = {
  __ALL__: <Trans>All</Trans>,
  contracts: <Trans>Contracts</Trans>,
  templates: <Trans>Playbooks</Trans>,
  parties: <Trans>Contracting parties</Trans>,
  inputFields: <Trans>Input Fields</Trans>,
  conditionalTexts: <Trans>Conditional Texts</Trans>,
  clauses: <Trans>Clauses</Trans>,
  media: <Trans>Media</Trans>
}

interface SearchModalContextProps {
  searchOpen: boolean
  setSearchOpen: (open: boolean) => void
  setSearch: (search: string) => void
  setCategory: (cat: Category) => void
}

export const SearchModalContext = React.createContext<SearchModalContextProps>({
  searchOpen: false,
  setSearchOpen: noop,
  setSearch: noop,
  setCategory: noop
})

