/* eslint-disable max-lines */
import React, { useContext, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t, Trans } from '@lingui/macro'
import {
  ClockCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MenuOutlined,
  MoreOutlined,
  ReadOutlined,
  SettingOutlined,
  StarFilled,
  StarOutlined,
  StopOutlined,
  SyncOutlined,
  TeamOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserOutlined
} from '@ant-design/icons'
import {
  Alert, Button, Card, Drawer, Dropdown, Menu, Tag, Tooltip, Typography
} from 'antd'
import moment from 'moment'

//  Components
import { withUserData } from '../Layouts/AuthenticatedPage'
import { UserAndCompanyDataContext } from '../Layouts/Constants'
import { GlobalLoadingAlert } from '../Alert/LoadingAlert'
import MainContainer from '../Layouts/MainLayout/MainContainer'
import useLoadData from '../../hooks/useLoadData'
import { ConfirmButton, Select } from '../SharedComponents/FormComponents'
import { UserAvatar, UserAvatarByID, UserFullName, userFullName } from './UserRoleDisplay/UserAvatarList'
import UserInvite from './UserInvite'

//  Actions
import {
  addInvite,
  createOrganisationTeam,
  deleteOrganisationTeam,
  deleteOrganisationUserRole,
  retrieveOrganisationRoles,
  updateOrganisationTeam,
  updateOrganisationUserRole
} from './redux/OrganisationsActions'

//  Styles
import './UsersManagementStyles.scss'
import TempEntityEndorsementDrawer from './TempEntityEndorsementDrawer'
import { DeviceContext } from '../../GlobalContext'


/** **********************************************************************
 *                        Define roles
 ********************************************************************** */
export const teamRoles = {
  FullAccess: {
    text: <Trans>Full access</Trans>,
    description: <Trans>Admin, creation and editing rights</Trans>,
    color: 'red',
    icon: <UsergroupAddOutlined />
  },
  Contributor: {
    text: <Trans>Contributor</Trans>,
    description: <Trans>Editing rights</Trans>,
    color: 'orange',
    icon: <EditOutlined />
  },
  Reporter: {
    text: <Trans>Reporter</Trans>,
    description: <Trans>Fill-in, signing and commenting rights</Trans>,
    color: 'gold',
    icon: <ReadOutlined />
  },
  Viewer: {
    text: <Trans>Viewer</Trans>,
    description: <Trans>Fill-in and signing rights</Trans>,
    color: 'lime',
    icon: <EyeOutlined />
  }
}

export const teamRolesUserOverride = {
  TeamInheritance: {
    text: <Trans>Team inheritance</Trans>,
    description: <Trans>Take permission from the team that this user is member.</Trans>,
    color: 'cyan'
  },
  ...teamRoles
}

export const allRoles = {
  NoAccess: {
    text: <Trans>No access</Trans>,
    description: <Trans>No access rights</Trans>,
    color: '#d5d5d5',
    icon: <StopOutlined />
  },
  Owner: {
    text: <Trans>Owner</Trans>,
    description: <Trans>Owner and full rights</Trans>,
    color: 'red',
    icon: <UserOutlined />
  },
  ...teamRolesUserOverride
}
// export const Owner = {
//   text: <Trans>Owner</Trans>,
//   description: <Trans>Admin, creation and editing rights</Trans>,
//   color: 'red',
//   icon: <UsergroupAddOutlined />
// }

export const RoleTag = ({ role }) => {
  const tag = allRoles[role] || allRoles.NoAccess
  return <span className='roleTag' style={{ color: tag.color }}>{tag.icon} {tag.text}</span>
}
export const CustomTag = ({ status, color }) => (<span className={`userStatus ${color}`}>{status}</span>)
/** **********************************************************************
 *                  Role selector
 ********************************************************************** */
export const RoleSelect = ({ roles = teamRoles, buttonLoading, setButtonLoading, value, onChange, useMaxWidth = false, disabled = false }) => (
  <Select
    className='teamRoleSelect simple-line'
    disabled={buttonLoading || disabled}
    dropdownClassName='teamRoleSelectOptionDropdown'
    dropdownMatchSelectWidth={!useMaxWidth}
    items={(disabled && roles === teamRoles) ? allRoles : roles}
    label={item => (item.text ? (
      <div className={`teamRoleSelectOption ${useMaxWidth ? 'maxWidth' : ''}`}>
        <p className='userTeamGroup'><span style={{ color: item.color }}>{item.icon} {item.text}</span></p>
        {item.description ? <p className='teamDescription'>{item.description}</p> : null}
      </div>
    ) : null)}
    noSearch
    onChange={async newRole => {
      if (newRole !== value) {
        setButtonLoading(true)
        window.notification.open({
          key: 'permsLoading',
          message: <Trans>Changing permission</Trans>,
          icon: <SyncOutlined spin />,
          duration: 0
        })
        try {
          if (typeof onChange === 'function') {
            await onChange(newRole)
          }
          window.notification.close('permsLoading')
          window.notification.success({
            message: <Trans>Permission modified</Trans>
          })
        } catch (err) {
          console.error('Error when changing permission', err)
          window.notification.close('permsLoading')
        }
        setButtonLoading(false)
      }
    }}
    placeholder={<> - <Trans>Role</Trans> - </>}
    size='small'
    value={value}
  />
)


/** **********************************************************************
 *                  Quick date display component
 ********************************************************************** */
export const DateDisplay = ({ date, absolute }) => {
  let formattedDate = date
  if (typeof formattedDate === 'string') {
    formattedDate = new Date(formattedDate)
  }
  if (isNaN(formattedDate)) {
    return '-'
  }
  const mmDate = moment(formattedDate)
  return absolute ? mmDate.format('ll') : mmDate.fromNow()
}

export const UserAddedDetails = ({ details }) => (
  <div className='userAddedDetails'>
    {details.updatedDate && (
      <p>
        <b><Trans>Updated on:</Trans></b>
        <span className='absoluteDate'><DateDisplay absolute date={details.updatedDate} /></span>
        <span className='relativeDate'>(<DateDisplay date={details.updatedDate} />)</span>
      </p>
    )}
    {details.updatedBy && (
      <p className='inviter'>
        <b><Trans>Updated by:</Trans></b>
        <UserAvatarByID autoSide noTooltip userID={details.updatedBy} />
        <span className='userFullName'><UserFullName userID={details.updatedBy} /></span>
      </p>
    )}
    {details.addedDate && (
      <p>
        <b><Trans>Added on:</Trans></b>
        <span className='absoluteDate'><DateDisplay absolute date={details.addedDate} /></span>
        <span className='relativeDate'>(<DateDisplay date={details.addedDate} />)</span>
      </p>
    )}
    {details.addedBy && (
      <p className='inviter'>
        <b><Trans>Added by:</Trans></b>
        <UserAvatarByID autoSide noTooltip userID={details.addedBy} />
        <span className='userFullName'><UserFullName userID={details.addedBy} /></span>
      </p>
    )}
    {details.invitedDate && (
      <p className='inviter'>
        <b><Trans>Invited on:</Trans></b>
        <span className='absoluteDate'><DateDisplay absolute date={details.invitedDate} /></span>
        <span className='relativeDate'>(<DateDisplay date={details.invitedDate} />)</span>
      </p>
    )}
    {details.invitedBy && (
      <p className='inviter'>
        <b><Trans>Invited by:</Trans></b>
        <UserAvatarByID autoSide noTooltip userID={details.invitedBy} />
        <span className='userFullName'><UserFullName userID={details.invitedBy} /></span>
      </p>
    )}
    {typeof details.deleteAfter === 'number' && (
      <p>
        <b><Trans>Expire</Trans></b>
        <span className='absoluteDate'><DateDisplay date={details.deleteAfter * 1000} /></span>
      </p>
    )}
  </div>
)

export const UserClockDetails = ({ user }) => {
  const details = user.organisationRole || user

  if (details.addedDate) {
    return (
      <Tooltip title={<UserAddedDetails details={details} />}>
        <ClockCircleOutlined />
      </Tooltip>
    )
  }

  return null
}


/** **********************************************************************
 *                  Users card title builder
 ********************************************************************** */
export const UserCardTitle = ({ user, size = '1.5rem', noTooltip, extraTitle = null, tooltipTitle = null }) => {
  //  Build a proper user avatar
  const name = user.name || (
    user.token
      ? <span className='email'>{user.info.email}</span>
      : <span className='userFullName'>{userFullName(user)}</span>
  )
  const title = tooltipTitle || (
    <div className='userTooltipContent'>
      <div className='name'><UserAvatar noTooltip size='2rem' user={user} /> {name}</div>
    </div>
  )
  const userName = (
    <h3 className='userName'>
      <UserAvatar noTooltip={noTooltip} size={size} tooltipProps={{ title, placement: 'left' }} user={user} /> {name}
    </h3>
  )

  //  user.name is in case of it's a team
  return <>{(user.name && <span className='teamName'>{user.name}</span>) || userName} {extraTitle}</>
}


/** **********************************************************************
 *                  The user card
 ********************************************************************** */
const UserCard = ({ user, noDrag = false, noTeamLeader = false, readOnly = false }) => {
  const [buttonLoading, setButtonLoading] = useState(false)
  const dispatch = useDispatch()
  const role = user.organisationRole || user

  return (
    <Card
      className={`betterCard ${(role.role === 'Owner' || (!noTeamLeader && role.isTeamLeader)) ? 'teamLeader' : ''} ${noDrag ? '' : 'hoverable draggable'}`}
      draggable={!noDrag}
      extra={readOnly ? null : (
        <>
          {/* Currently nothing implemented behind this so just disable this for now */}
          {false && !noTeamLeader && (
            <Tooltip title={role.isTeamLeader ? <Trans>Removing managing right</Trans> : <Trans>Granting managing right</Trans>}>
              <Button
                icon={role.isTeamLeader ? <StarFilled /> : <StarOutlined />}
                loading={buttonLoading}
                onClick={async () => {
                  setButtonLoading(true)
                  window.notification.open({
                    key: 'userLeaderLoading',
                    message: <Trans>Changing the rights</Trans>,
                    icon: <SyncOutlined spin />,
                    duration: 0
                  })
                  try {
                    await dispatch(updateOrganisationUserRole({ ...role, isTeamLeader: !role.isTeamLeader }))
                    window.notification.close('userLeaderLoading')
                    window.notification.success({
                      message: <Trans>User modified</Trans>
                    })
                  } catch (err) {
                    console.error('Error when changing user team leader attr', err)
                    window.notification.close('userLeaderLoading')
                  }
                  setButtonLoading(false)
                }}
                size='small'
                type={user.isTeamLeader ? 'default' : null}
              />
            </Tooltip>
          )}
          <ConfirmButton
            confirmMessage={<Trans>Are you sure you want to remove this user from your organisation?</Trans>}
            danger
            ghost
            icon={<DeleteOutlined />}
            loading={buttonLoading}
            onClick={async () => {
              setButtonLoading(true)
              window.notification.open({
                key: 'userDeleteLoading',
                message: <Trans>Revoking the user access</Trans>,
                icon: <SyncOutlined spin />,
                duration: 0
              })
              try {
                await dispatch(deleteOrganisationUserRole(user))
                window.notification.close('userDeleteLoading')
                window.notification.success({
                  message: <Trans>User access revoked</Trans>
                })
              } catch (err) {
                console.error('Error when removing a user', err)
                window.notification.close('userDeleteLoading')
              }
              setButtonLoading(false)
            }}
            size='small'
          />

        </>

      )}
      onDragStart={noDrag ? null : evt => {
        evt.dataTransfer.clearData()
        evt.dataTransfer.setData('application/json', JSON.stringify(role))
      }}
      size='small'
      title={<UserCardTitle noTooltip size='2rem' user={user} />}
    >
      <UserAddedDetails details={role} />
    </Card>
  )
}

/** **********************************************************************
 *                  Drop container styles
 ********************************************************************** */
const dropContainerStyleHandler = {
  onDragEnter: evt => {
    const elm = evt.currentTarget
    setTimeout(() => elm.classList.add('dragOver'), 0)
    //  Having drop enable
    evt.preventDefault()
    return false
  },
  onDragOver: evt => {
    //  Having drop enable
    evt.preventDefault()
    return false
  },
  onDragLeave: evt => {
    evt.currentTarget.classList.remove('dragOver')
  }
}


/** **********************************************************************
 *                  Users management component
 ********************************************************************** */
const UsersManagement = () => {
  //  Responsive design
  const isPhone = useContext(DeviceContext) === 'phone'

  //  Context & redux
  const dispatch = useDispatch()
  const { company: { organisationID }, user: { userID: currentUser } } = useContext(UserAndCompanyDataContext)
  const { teams, otherTeams, teamMapping, currentOrganisationID } = useSelector(state => state.organisation.companyRoles)
  const [sidebarOpen, setSidebarOpen] = useState(false)

  //  Data loading when organisationID change
  const prevOrgaID = useRef()
  const [loading, error] = useLoadData(
    () => dispatch(retrieveOrganisationRoles(organisationID)),
    [organisationID],
    organisationID && organisationID !== '__GUEST_COMPANY__' && organisationID !== prevOrgaID.current && organisationID !== currentOrganisationID
  )
  prevOrgaID.current = organisationID

  //  Local states
  const [buttonLoading, setButtonLoading] = useState(false)
  const [showInviteUser, setShowInviteUser] = useState(undefined)
  const [showTestRights, setShowTestRights] = useState(false)

  const openTestRights = () => setShowTestRights(true)


  /** **********************************************************************
   *                  Create new team handler
   ********************************************************************** */
  const createNewTeam = async () => {
    setButtonLoading(true)
    window.notification.open({
      key: 'teamCreateLoading',
      message: <Trans>Creating a new team</Trans>,
      icon: <SyncOutlined spin />,
      duration: 0
    })
    try {
      await dispatch(createOrganisationTeam(t`New team`))
      window.notification.close('teamCreateLoading')
      window.notification.success({
        message: <Trans>Team added</Trans>
      })
      setButtonLoading(false)
    } catch (err) {
      console.error('Failed to create a team', err)
      window.notification.close('teamCreateLoading')
      setButtonLoading(false)
      throw err
    }
  }

  /** **********************************************************************
   *         Create drop event handler for moving a user in a team
   ********************************************************************** */
  const onDropFactory = team => async evt => {
    evt.currentTarget.classList.remove('dragOver')
    evt.preventDefault()
    if (evt.dataTransfer.types.includes('application/json')) {
      try {
        const role = JSON.parse(evt.dataTransfer.getData('application/json'))
        if (role.team !== team.userID) {
          window.notification.open({
            key: 'userMoveLoading',
            message: <Trans>Moving your user to the new team</Trans>,
            icon: <SyncOutlined spin />,
            duration: 0
          })
          try {
            await dispatch(updateOrganisationUserRole({ ...role, team: team.userID }))
            window.notification.close('userMoveLoading')
            window.notification.success({
              message: <Trans>User moved</Trans>
            })
          } catch (err) {
            console.error('Error when moving a user to a team', err)
            window.notification.close('userMoveLoading')
          }
        }
      } catch { // Just ignore it it's not a dragable content build by us but something els
      }
    }
  }

  /** **********************************************************************
   *                  Rendering the view
   ********************************************************************** */
  return (
    <MainContainer
      mainContentClass='usersManagementBoard'
      topbarContent={(
        <>
          {!isPhone ? (
            <>
              <div className='topbarMainContent'>
                <TeamOutlined className='headerIcon' twoToneColor='#3DBD7D' />
                <h1 className='title'><Trans>User management</Trans></h1>
              </div>
              <div className='topbarActions'>
                <Button
                  icon={<UserAddOutlined />}
                  loading={buttonLoading}
                  onClick={() => setShowInviteUser(true)}
                  type='primary'
                >
                  <Trans>Invite a user</Trans>
                </Button>
                <Dropdown
                  overlay={(
                    <Menu key='menu'>
                      <Menu.Item onClick={() => createNewTeam()}>
                        <UsergroupAddOutlined />
                        <span><Trans>New team</Trans></span>
                      </Menu.Item>
                      <Menu.Item onClick={openTestRights}>
                        <SettingOutlined />
                        <span><Trans>Test rights</Trans></span>
                      </Menu.Item>
                    </Menu>
                  )}
                  placement='bottomRight'
                  trigger={['click']}
                >
                  <Button
                    className='noBorder moreButton'
                    ghost
                    href='javascript:void(0)'
                    // eslint-disable-next-line no-script-url
                    icon={<MoreOutlined />}
                    loading={buttonLoading}
                  />
                </Dropdown>
              </div>
            </>
          ) : (
            <>
              <Button
                className='noBorder phoneSidebarDrawerButton'
                ghost
                icon={<MenuOutlined />}
                onClick={() => setSidebarOpen(true)}
                type='default'
              />
              <Drawer
                className='phoneSidebarDrawer'
                onClose={() => setSidebarOpen(false)}
                placement='right'
                title={<h1 className='title'><Trans>User management</Trans></h1>}
                visible={sidebarOpen}
                width='60vw'
                zIndex={1000}
              >
                <div className='sidebarMainActions'>
                  <Button
                    ghost
                    icon={<UserAddOutlined />}
                    loading={buttonLoading}
                    onClick={() => {
                      setShowInviteUser(true)
                      setSidebarOpen(false)
                    }}
                  >
                    <Trans>Invite a user</Trans>
                  </Button>
                  <Button
                    ghost
                    icon={<UsergroupAddOutlined />}
                    loading={buttonLoading}
                    onClick={() => {
                      createNewTeam()
                      setSidebarOpen(false)
                    }}
                  >
                    <Trans>New team</Trans>
                  </Button>
                  <Button
                    ghost
                    icon={<SettingOutlined />}
                    loading={buttonLoading}
                    onClick={() => {
                      openTestRights()
                      setSidebarOpen(false)
                    }}
                    type='primary'
                  >
                    <Trans>Test rights</Trans>
                  </Button>
                </div>
              </Drawer>
            </>
          )}
        </>
      )}
    >
      {
        error ? (
          <Alert
            description={<Trans>An unexpected error occurred while gathering your organisation members. Please try again later.</Trans>}
            message={<Trans>Organisation members</Trans>}
            showIcon
            type='error'
          />
        ) : (
          <GlobalLoadingAlert description={<Trans>Loading your organisation members</Trans>} loading={loading}>
            <div className='teamsWrapper noTextSelect'>
              <div className='teams' disableSelection>
                {[
                  ...teams.map(team => (
                    <div {...dropContainerStyleHandler} key={team.userID} className='team' onDrop={onDropFactory(team)}>
                      <div className='teamHeader'>
                        <ConfirmButton
                          className='teamDeleteButton'
                          confirmMessage={<Trans>Are you sure you want to delete this team (users are keep)?</Trans>}
                          danger
                          ghost
                          icon={<DeleteOutlined />}
                          loading={buttonLoading}
                          onClick={async () => {
                            setButtonLoading(true)
                            window.notification.open({
                              key: 'teamDeleteLoading',
                              message: <Trans>Deleting the team</Trans>,
                              icon: <SyncOutlined spin />,
                              duration: 0
                            })
                            try {
                              await dispatch(deleteOrganisationTeam(team))
                              window.notification.close('teamDeleteLoading')
                              window.notification.success({
                                message: <Trans>Team deleted</Trans>
                              })
                            } catch (err) {
                              console.error('Error when deleting the team', err)
                              window.notification.close('teamDeleteLoading')
                            }
                            setButtonLoading(false)
                          }}
                          size='small'
                        />
                        <div className='title'>
                          <Typography.Title
                            editable={{
                              onChange: async newName => {
                                if (newName !== team.name) {
                                  window.notification.open({
                                    key: 'teamRenameLoading',
                                    message: <Trans>Renaming the team</Trans>,
                                    icon: <SyncOutlined spin />,
                                    duration: 0
                                  })
                                  try {
                                    await dispatch(updateOrganisationTeam({ ...team, name: newName }))
                                    window.notification.close('teamRenameLoading')
                                    window.notification.success({
                                      message: <Trans>Team renamed</Trans>
                                    })
                                  } catch (err) {
                                    console.error('Error when changing the team name', err)
                                    window.notification.close('teamRenameLoading')
                                  }
                                }
                              }
                            }}
                            level={2}
                          >
                            {team.name}
                          </Typography.Title>
                        </div>
                        <div className='description'>
                          <p>
                            <Trans>
                              Members of {team.name} see contracts and playbooks from this team and files shared company-wide.
                              Optionally, users can be invited directly to content
                            </Trans>
                          </p>
                          <UserAddedDetails details={team} />
                        </div>
                      </div>
                      <div className='users'>
                        {team.users.map(user => <UserCard key={user.userID} readOnly={user.userID === currentUser} user={user} />)}
                      </div>
                    </div>
                  )),
                  ...otherTeams.map(team => {
                    const otherProps = {
                      className: 'team ',
                      onDrop: onDropFactory(team)
                    }

                    let Extra = () => null
                    const readOnly = !['__NoTeam', '__Admin'].includes(team.userID)
                    if (readOnly) {
                      otherProps.onDrop = evt => {
                        evt.preventDefault()
                        evt.currentTarget.classList.remove('dragOver')
                      }
                      otherProps.className += 'noDrop'
                    }

                    if (team.userID === '__PendingInvites') {
                      // eslint-disable-next-line
                      Extra = ({ user }) => (
                        <div>
                          <h3><Trans>Team</Trans></h3>
                          <Tag color='blue'>{(teamMapping[user.team] || teamMapping.__NoTeam).name}</Tag>
                        </div>
                      )
                    }

                    return (
                      <div {...dropContainerStyleHandler} {...otherProps} key={team.userID}>
                        <div className='teamHeader'>
                          <div className='title'><h2>{team.name}</h2></div>
                          {team.description && <div className='description'><p>{team.description}</p></div>}
                        </div>
                        <div className='users'>
                          {
                            team.users.map(user => {
                              const reelUser = team.userID === '__Admin' ? {
                                ...user,
                                organisationRole: {
                                  ...user.organisationRole,
                                  templateRole: 'FullAccess',
                                  contractRole: 'FullAccess'
                                }
                              } : user
                              return (
                                <UserCard
                                  key={reelUser.userID} extra={<Extra user={reelUser} />} noDrag={readOnly}
                                  noTeamLeader readOnly={readOnly || reelUser.userID === currentUser || team.readOnly} user={reelUser}
                                />
                              )
                            })
                          }
                        </div>
                      </div>
                    )
                  })
                ]}
              </div>
            </div>
            <UserInvite
              onCancel={() => setShowInviteUser(false)}
              onFinish={invite => {
                setShowInviteUser(false)
                dispatch(addInvite(invite))
              }}
              visible={showInviteUser}
            />
            <TempEntityEndorsementDrawer onClose={() => setShowTestRights(false)} open={showTestRights} />
          </GlobalLoadingAlert>
        )
      }
    </MainContainer>
  )
}

const UserManagementSwitch = () => {
  const { company: { members = {} }, user: { userID } } = useContext(UserAndCompanyDataContext)

  if (members[userID] && members[userID].organisationRole.team === '__Admin') {
    return <UsersManagement />
  }

  return (
    <MainContainer
      mainContentClass='usersManagementBoard'
      topbarContent={(
        <div className='topbarMainContent'>
          <TeamOutlined className='headerIcon' twoToneColor='#3DBD7D' />
          <h1 className='title'><Trans>User management</Trans></h1>
        </div>
      )}
    >
      <Alert
        message={<Trans>You are not allowed to manage this organisation.</Trans>}
        showIcon
        style={{ margin: '2rem' }}
        type='error'
      />
    </MainContainer>
  )
}

const Wrapped = props => {
  const Component = useMemo(() => withUserData(UserManagementSwitch), [])
  return <Component {...props} />
}

export default Wrapped
