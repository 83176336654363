/* eslint-disable no-unused-expressions */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { NumberParam, StringParam, useQueryParam } from 'use-query-params'
import { useDispatch } from 'react-redux'
import { Trans } from '@lingui/macro'
import { CompassOutlined, EditOutlined } from '@ant-design/icons'

import { ContractAssistant, ContractAssistantProps } from '@top-legal/contract-assistant'
import {
  Template,
  toJson,
  useContractsCollection,
  useDoc,
  useSectionsCollection,
  useTemplatesCollection
} from '@top-legal/datastore'
import { ConfirmButton } from '@top-legal/form-components'

//  Contexts
import { Skeleton, Tooltip } from 'antd'
import { UserAndCompanyDataContext } from '../../../../../Layouts/Constants'
import { ContractContext, TemplateContext } from '../../../Contexts'

//  Components
import { DisplayParty, InputParty } from './InputPartiesContacts'
import { batchUpdateContractFields } from '../../../../../Organisations/redux/OrganisationsActions'
import { userFullName } from '../../../../../Organisations/UserRoleDisplay/UserAvatarList'
import MultiFileUploader from '../../../../../SharedComponents/FormComponents/MultiFileUploader'
import { prepareContractLoadingModal } from '../../../../ContractPageContext'
import { prepareContractAction } from '../../../../redux/ContractActions'
import { openShowDealroomModal } from './DealroomModal'


const cleanAddress = (obj, res) => {
  if (Object(obj.address) === obj.address) {
    cleanAddress(obj.address, res)
  } else {
    ['address', 'city', 'postcode', 'country'].forEach(key => {
      if (obj[key] != null) {
        res[key] = obj[key]
      }
    })
  }
}

const cleanPersonData = obj => {
  const res = {}

  if (Object(obj) === obj) {
    ['firstName', 'lastName', 'dateOfBirth', 'gender', 'email'].forEach(key => {
      if (obj[key] != null) {
        res[key] = obj[key]
      }
    })
    cleanAddress(obj, res)
  }

  return res
}

const cleanCompanyData = obj => {
  const res = {}

  if (Object(obj) === obj) {
    ['name', 'vatID', 'commercialID', 'employees', 'industry'].forEach(key => {
      if (obj[key] != null) {
        res[key] = obj[key]
      }
    })
    cleanAddress(obj, res)
  }

  return res
}


export const PartyFieldInput: React.FC<any> = ({ field, fieldKey, value, onChange, companyRequired, userAddressRequired }) => {
  const partyID = value?.partyID
  const setParty = useCallback((_, personData, companyData) => onChange({
    ...cleanPersonData(personData),
    ...cleanCompanyData(companyData),
    partyID: personData.partyID || personData.userID
  }), [onChange])

  return (
    <DisplayParty customField={field} partyField={fieldKey} partyID={partyID}>
      <InputParty
        companyRequired={companyRequired}
        party={partyID}
        setParty={setParty}
        userAddressRequired={userAddressRequired}
      />
    </DisplayParty>
  )
}


const PdfUploader: React.FC<{ attr: string, title: React.ReactElement }> = ({ attr, title }) => {
  const { contract, updateContract } = useContext<any>(ContractContext)

  const value = contract[attr]
  const saveFileKeys = useCallback((files: any[]) => {
    updateContract({ [attr]: [...(value || []), ...files] })
  }, [updateContract, attr, value])

  return (
    <MultiFileUploader
      accept='.pdf'
      multiple
      onFinished={saveFileKeys}
      title={title}
      useFeedbackModal
      withNames
      withUrls
    />
  )
}
const buttonStyling = { marginTop: '1rem' }


export const AssistantToolbox: React.FC = () => {
  const { user, company } = useContext(UserAndCompanyDataContext)
  //  We have to use the template from the store rather than the one on redux to avoid desync
  const { template: { templateID = '__NO_TEMPLATE__' } } = useContext<any>(TemplateContext)
  const templatesColl = useTemplatesCollection()
  const [template] = useDoc<Template, {}, Template|undefined>(
    useMemo(() => templatesColl.findOne(templateID), [templateID, templatesColl]),
    toJson
  )
  const { contract, previewMode, fillingStats, setFillingStats } = useContext<any>(ContractContext)
  const hasCover = Array.isArray(contract.covers)
  const hasAttcm = Array.isArray(contract.additionalDocuments)


  const [activeField, setActiveField] = useQueryParam('activeField', StringParam)
  const [activeSection, setActiveSection] = useQueryParam('activeSection', StringParam)
  const [, setActivePage] = useQueryParam('activePage', NumberParam)
  const [caOnly] = useQueryParam('caOnly', StringParam)
  const currentRef = useRef({ activeSection, activeField, contract })
  currentRef.current = { activeSection, activeField, contract }

  const onFieldOpened = useCallback<NonNullable<ContractAssistantProps['onFieldOpened']>>(
    (fieldKey, sectionID) => {
      if (currentRef.current.activeSection !== sectionID) {
        setActiveSection(sectionID)
      }
      if (currentRef.current.activeField !== fieldKey) {
        setActiveField(fieldKey || undefined as any)
      }
    },
    [setActiveSection, setActiveField]
  )


  const dispatch = useDispatch()
  const contractsCollection = useContractsCollection()
  const updateFiledResponse = useCallback<ContractAssistantProps['updateFiledResponse']>(
    async update => {
      await dispatch(batchUpdateContractFields(update, contractsCollection, !previewMode))
    },
    [dispatch, contractsCollection, previewMode]
  )

  const messageParent = useCallback((action: string) => {
    if (caOnly && window.parent) {
      window.parent.postMessage({ action, contractID: contract.contractID }, process.env.DEAL_DOMAIN!)
    }
  }, [caOnly, contract.contractID])


  const sectionsCollection = useSectionsCollection()
  const [loading, setLoading] = useState(false)
  const finish = useCallback(async () => prepareContractLoadingModal((async () => {
    setLoading(true)

    try {
      messageParent('contractAssistantDrafted')
      await dispatch(prepareContractAction(sectionsCollection))
      messageParent('contractAssistantCloned')
      if (!company.hideDealroom && !caOnly) { openShowDealroomModal(contract.contractID, contract.contractName) }
      //  Go back to first page of the contract
      setActiveSection(undefined as any)
      setActiveField(undefined as any)
      setActivePage(1)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      throw err
    }
  })()), [messageParent, dispatch, sectionsCollection, company.hideDealroom, caOnly, setActiveSection, setActiveField, setActivePage, contract.contractID, contract.contractName])

  //  Forward to parent window the new drafted contractID
  useEffect(() => {
    messageParent('contractAssistantInit')
  }, [messageParent])

  const disabled = !fillingStats || fillingStats.done !== fillingStats.total
  const continueToEditButton = useMemo(() => {
    const btn = (
      <ConfirmButton
        block
        confirmMessage={<Trans>Are you sure you want to continue?</Trans>}
        disabled={disabled}
        icon={company.hideDealroom ? <EditOutlined /> : undefined}
        loading={loading}
        onClick={finish}
        style={buttonStyling}
        type='primary'
      >
        {company.hideDealroom ? <Trans>Edit Contract</Trans> : <Trans>Finish drafting</Trans>}
      </ConfirmButton>
    )

    if (disabled) {
      return (
        <Tooltip placement='top' title={<Trans>Complete above questions to Edit</Trans>}>
          <div>{btn}</div>
        </Tooltip>
      )
    }

    return btn
  }, [company.hideDealroom, disabled, finish, loading])


  const before = useMemo(() => (caOnly ? undefined : [{
    key: 'covers',
    title: <span><Trans>Add Covers</Trans> (<Trans>optional</Trans>)</span>,
    required: false,
    done: hasCover,
    children: <PdfUploader attr='covers' title={<Trans>Add Covers</Trans>} />
  }]), [hasCover, caOnly])
  const after = useMemo(() => (caOnly ? undefined : [{
    key: 'additionalDocuments',
    title: <span><Trans>Add Attachments</Trans> (<Trans>optional</Trans>)</span>,
    required: false,
    done: hasAttcm,
    children: <PdfUploader attr='additionalDocuments' title={<Trans>Add Attachments</Trans>} />
  }]), [hasAttcm, caOnly])

  return (
    <>
      {template ? (
        <ContractAssistant
          InputParty={PartyFieldInput}
          after={after}
          before={before}
          contract={contract}
          currentCompanyName={company.name}
          currentPersonName={userFullName(user)}
          initialFieldID={activeField as any}
          onFieldOpened={onFieldOpened}
          onStats={setFillingStats}
          template={template}
          updateFiledResponse={updateFiledResponse}
        />
      ) : <Skeleton active />}
      {!previewMode && continueToEditButton}
    </>
  )
}

export default {
  key: 'assistant',
  className: 'assistantToolbox',
  text: <Trans>Contract assistant</Trans>,
  icon: <CompassOutlined />,
  Component: AssistantToolbox,
  phoneDrawerPlacement: 'right',
  doNotReturnMenu: true
}
