import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { dequal } from 'dequal'

import { InputField } from '@top-legal/datastore'

import ContractAssistantContext from '../../ContractAssistantContext'
import { AssistantInputProps } from '../Types'
import getFieldValue from '../../utils/getFieldValue'


interface FieldSaving<T> {
  value: T | undefined
  onChange: (val: T | { target: any } | undefined) => void
  onKeyDown?: React.KeyboardEventHandler
}


const useFieldSaving = <T, >({ fieldKey, goNextField }: AssistantInputProps, goNextEnter = false, goNextSet = false): FieldSaving<T> => {
  const { contract, fieldsMap, updateFiledResponse } = useContext(ContractAssistantContext)
  const [value, setValue] = useState<T>()

  //  Create a saving method
  const contractRef = useRef(contract)
  contractRef.current = contract
  const save = useCallback(async (val: T | undefined) => {
    //  Only save if different
    const prev = getFieldValue(fieldsMap, fieldKey, contractRef.current.fieldsResponse, false)?.[0]
    if (val !== prev) {
      const [baseKey, ...rest] = fieldKey.split('.')
      const field = fieldsMap.get(baseKey)

      if (field) {
        if (field.type === 'company' || field.type === 'person') {
          const values = contractRef.current.fieldsResponse[baseKey] || {}
          const nestedFieldKey = rest.pop() || baseKey

          const hasChanges = values[nestedFieldKey] !== val
          if (hasChanges) {
            let newValues = { ...values }

            if (nestedFieldKey === baseKey && Object(val) === val) {
              newValues = { ...values, ...val }
            } else {
              newValues[nestedFieldKey] = val
            }

            //  Should update the contract state
            await updateFiledResponse({ [baseKey]: newValues })
          }
        } else if (field.type === 'listOfFormattedText') {
          // Combined list the values are read and saved on the parent one
          let currentKey = baseKey
          let currentField: InputField | undefined = field

          while (currentField && currentField.previousListField) {
            currentKey = currentField.previousListField
            currentField = fieldsMap.get(currentKey)
          }

          if (currentField && currentKey) {
            const hasChanges = contractRef.current.fieldsResponse[currentKey] !== val
            if (hasChanges) {
              //  Should update the contract state
              await updateFiledResponse({ [currentKey]: val })
            }
          }
        } else {
          const hasChanges = contractRef.current.fieldsResponse[baseKey] !== val
          if (hasChanges) {
            //  Should update the contract state
            await updateFiledResponse({ [baseKey]: val })
          }
        }

        if (goNextSet) {
          goNextField()
        }
      }
    }
  }, [fieldKey, fieldsMap, goNextField, goNextSet, updateFiledResponse])


  //  Manage the onChange and debounce the saving
  const isSavingRef = useRef(false)
  const timeoutRef = useRef<NodeJS.Timeout>()
  const saveWrapped = useCallback<FieldSaving<T>['onChange']>(evt => {
    // Some form element has a change evt and some give the value directly
    const tVal = (evt as any)?.target?.value
    const val = tVal == null ? evt : tVal
    setValue(val)

    if (!isSavingRef.current) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = setTimeout(() => {
        isSavingRef.current = true
        save(val).finally(() => {
          isSavingRef.current = false
        })
      }, 500)
    }
  }, [save])

  //  Listen to the change of the field value from the store
  const storeValue = getFieldValue(fieldsMap, fieldKey, contract.fieldsResponse, false)?.[0]
  const preRef = useRef(undefined)
  useEffect(() => {
    if (!dequal(storeValue, preRef.current)) {
      preRef.current = storeValue
      setValue(storeValue)
    }
  }, [storeValue])

  const onKeyDown = useMemo<FieldSaving<T>['onKeyDown']>(() => {
    if (goNextEnter) {
      return evt => {
        if (evt.key === 'Enter') {
          evt.preventDefault()
          goNextField()
        }
      }
    }
    return undefined
  }, [goNextEnter, goNextField])

  //  Return to the component the current field value and change handler
  return { value, onChange: saveWrapped, onKeyDown }
}

export default useFieldSaving
