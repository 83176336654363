/* eslint-disable max-lines */
import { saveInStore } from '@top-legal/datastore'
import RestService from '../../../RestService'
import * as types from './TemplateActionsType'

import { SAVE_CONTRACT } from '../../Contract/redux/ContractActions'
import { STORE_INSTANCES_COMMENTS } from '../../Organisations/redux/OrganisationsActions'
import { getHistory } from '../../../BrowserHistory'
import { store } from '../../../ReduxStore'

// //////////////////////////////////////////////////////////////////////////////////////////////
//                            Template update functions
// //////////////////////////////////////////////////////////////////////////////////////////////

// ==================================================================================================
/**
 * updates the redux store by taking in the template fields (all of them) and saving them
 * to the createTemplate store
 * @param {object} values
 */
export const updateTemplate = values => async (dispatch, getState) => {
  if (values.templateID) {
    if (typeof values.header !== 'string') {
      values.header = (values.header && values.header.sectionID) || `${values.templateID}-header`
    }
    if (typeof values.footer !== 'string') {
      values.footer = (values.footer && values.footer.sectionID) || `${values.templateID}-footer`
    }
  }

  const template = { ...getState().template.templateCreator, ...values }

  await dispatch({
    type: types.UPDATE_TEMPLATE,
    payload: template
  })
  return template
}
// ==================================================================================================

export const resetTemplate = () => async dispatch => {
  await dispatch({ type: types.RESET_TEMPLATE })
  await dispatch(updateTemplate({}))
}

// ==================================================================================================

/**
 * Copies a top.legal template into the user's company templates
 * @param templateID
 * @returns {Function}
 */
export const duplicateTemplate = templateID => async (dispatch, getState) => {
  const { organisationID } = getState().organisation.selectedOrganisation
  const { template } = await RestService('POST', '/template/duplicate', {
    organisationID,
    templateID
  })
  return template
}

// ==================================================================================================
/**
 * function fetches the meta data and a secure link to the complete contract text saved under
 * the s3 object storage. The fucntion retrieves this json file from the storage and saves it to
 * the store together with the meta data.
 * @param {string} templateID
 */
export const getPublicTemplateByID = templateID => async dispatch => {
  let template = await RestService('GET', `/public/template/${templateID}`, null, false)

  // resets the template to the original state using the standard template information on the store
  dispatch({
    type: types.RESET_TEMPLATE
  })

  if (template) {
    template = await dispatch(updateTemplate(template))
  }
  return template
}

// ==================================================================================================
/**
 * function retrieves a template from the DB provided its template id.
 */
export const loadTemplate = (templateID, templatesCollection) => async dispatch => {
  let template = await RestService('GET', `/template/${templateID}`)

  // resets the template to the original state using the standard template information on the store
  dispatch({
    type: types.RESET_TEMPLATE
  })

  // getting the global static values for the current user and the current company
  if (template) {
    template = await dispatch(updateTemplate(template))

    const prev = await templatesCollection.findOne(templateID).exec()
    if (prev) {
      template = { ...prev.toJSON(), ...template }
    }

    await saveInStore(templatesCollection, template)
  }

  return template
}

// ==================================================================================================

export const getCommunityTemplates = () => async (dispatch, getState) => {
  const orgCache = {}
  const getOrg = organisationID => {
    if (!orgCache[organisationID]) {
      orgCache[organisationID] = RestService('GET', `/organisation/${organisationID}`)
    }
    return orgCache[organisationID]
  }

  const { organisationID } = getState().organisation.selectedOrganisation

  if (getState().template.communityTemplates === undefined) {
    const templates = await RestService('GET', '/template/community')
    const mapping = {}

    templates.forEach(template => {
      if (!mapping[template.organisationID]) {
        mapping[template.organisationID] = {
          organisationID: template.organisationID,
          templates: []
        }
      }
      mapping[template.organisationID].templates.push(template)
    })


    //  TODO: Move to whitelabel version instead of HardCoded one for transferinitiative
    if (window.IS_TRANSFERINITIATIVE && mapping[window.TRANSFERINITIATIVE_ORG_ID]) {
      const playbooks = mapping[window.TRANSFERINITIATIVE_ORG_ID]
      playbooks.isActive = true
      playbooks.name = (await getOrg(playbooks.organisationID)).name

      dispatch({
        type: types.COMMUNITY_TEMPLATES,
        payload: [playbooks]
      })
    } else {
      dispatch({
        type: types.COMMUNITY_TEMPLATES,
        payload: await Object.values(mapping).asyncForEach(async data => ({
          ...data,
          name: (await getOrg(data.organisationID)).name,
          isActive: data.organisationID === organisationID
        }))
      })
    }
  }
}

// ==================================================================================================

export const deleteTemplate = templateID => async (dispatch, getState) => {
  await RestService('DELETE', `/template/${templateID}`)
}

// ==================================================================================================

const makeRequest = async (method, url, data) => {
  if (getHistory()) {
    const contractInStore = store.getState().contract.contractEditing
    const editingContractID = contractInStore.contractID
    const templateID = (data && data.templateID) || store.getState().template.templateCreator.templateID
    if (editingContractID && contractInStore.templateID === templateID) {
      const res = await RestService(method, `${url}${(url.includes('?') ? '&' : '?')}editingContractID=${editingContractID}`, data)
      if (Object(res) === res && res.__template__) {
        await store.dispatch(updateTemplate(res.__template__))
        const contract = { ...contractInStore, templateID: res.templateID }
        if (contract.template && contract.template.templateID) {
          contract.template.templateID = res.templateID
        }
        await store.dispatch({
          type: SAVE_CONTRACT,
          payload: {
            ...contract,
            dateUpdated: (new Date()).toISOString(),
            contractStatus: 'editing'
          }
        })
        return res.__result__
      }
      return res
    }
  }

  return RestService(method, url, data)
}

// ==================================================================================================

export const deleteSection = sectionID => async (dispatch, getState) => {
  const { templateID } = getState().template.templateCreator
  const { contractID, templateID: contractTemplateID } = getState().contract.contractEditing
  const { userID } = getState().user.ownProfileData

  //  In case of public/demo mode we can't call the backend for that but we can save it in the store
  if (userID) {
    await makeRequest('DELETE', `/template/${templateID}/${sectionID}?contractID=${templateID === contractTemplateID ? contractID || '' : ''}`)
  }
  dispatch({
    type: types.DELETE_SECTION_ORG,
    payload: sectionID
  })
}

// ==================================================================================================

export const updateSection = (data, saveDB = true) => async (dispatch, getState) => {
  const { templateID } = getState().template.templateCreator
  const { contractID, templateID: contractTemplateID } = getState().contract.contractEditing
  const { userID } = getState().user.ownProfileData

  const section = { ...getState().template.sections[data.sectionID], ...data }

  let res = {}
  //  In case of public/demo mode we can't call the backend for that but we can save it in the store
  if (userID && saveDB) {
    res = await makeRequest(
      'PUT',
      `/template/${templateID}/${data.sectionID}?contractID=${templateID === contractTemplateID ? contractID || '' : ''}`,
      { dateUpdated: (new Date()).toISOString(), ...section }
    )
    res.content = section.content //  It is important for the frontend to keep the same array reference for the equality test
    res.content = section.content //  It is important for the frontend to keep the same array reference for the equality test
  }

  // dispatch({
  //   type: types.SAVE_SECTION,
  //   payload: { ...section, dateUpdated: (new Date()).toISOString() }
  // })
  dispatch({
    type: types.UPDATE_TEMPLATE,
    payload: { ...getState().template.templateCreator, dateUpdated: (new Date()).toISOString() }
  })
}

// ==================================================================================================

const toVersion = data => (Number.isInteger(data) ? data : 1)
export const saveTemplate = values => async (dispatch, getState) => {
  const template = { ...getState().template.templateCreator, ...values }

  //  Format back entities before sending to server
  if (Object(template.fields) === template.fields) {
    const fields = {}
    Object.entries(template.fields).forEach(([key, obj]) => {
      fields[key] = toVersion(obj && obj.version)
    })
    template.fields = fields
  }
  if (Object(template.conditionalTexts) === template.conditionalTexts) {
    const conds = {}
    Object.entries(template.conditionalTexts).forEach(([key, obj]) => {
      conds[key] = toVersion(obj && obj.version)
    })
    template.conditionalTexts = conds
  }

  const { contractID, templateID: contractTemplateID } = getState().contract.contractEditing
  const { userID } = getState().user.ownProfileData

  //  In case of public/demo mode we can't call the backend for that but we can save it in the store
  if (userID) {
    if (!template.templateID) {
      //  Create a new template in the database
      template.organisationID = getState().organisation.selectedOrganisation.organisationID
      const newTemplate = await RestService('POST', '/template', template)

      newTemplate.dateCreated = (new Date()).toISOString()
      newTemplate.dateUpdated = (new Date()).toISOString()

      return dispatch(updateTemplate(newTemplate))
    }
    //  Just update existing data
    await makeRequest(
      'PUT',
      `/template/${template.templateID}?contractID=${template.templateID === contractTemplateID ? contractID || '' : ''}`,
      template
    )

    const newTemplate = { ...template, dateUpdated: (new Date()).toISOString() }
    return dispatch(updateTemplate(newTemplate))
  }
}

// ==================================================================================================

export const manageSuggestion = (sectionsCollection, { instanceID, childID, date }, reason) => async (dispatch, getState) => {
  const { templateCreator: template } = getState().template
  const { contractID, templateID: contractTemplateID, ...contract } = getState().contract.contractEditing
  const { userID } = getState().user.ownProfileData

  if (userID && template.templateID) {
    const { suggestion, section } = await makeRequest(
      'PUT',
      `/comments/?contractID=${template.templateID === contractTemplateID ? contractID || '' : ''}`,
      {
        instanceID, childID, date, templateID: template.templateID, ...reason
      }
    )

    if (Object(section) === section && section.sectionID) {
      await saveInStore(sectionsCollection, section)
      await new Promise(resolve => setTimeout(resolve, 100))
    }

    const comments = [...(getState().organisation.instanceComments[instanceID] || [])]
    const index = comments.findIndex(comm => comm.date === date)

    if (suggestion.rate === 'accepted' && !suggestion.appliedDate) {
      suggestion.showOverlay = true

      if (instanceID === contractID && !contract.firstRedlineDate) {
        dispatch({
          type: SAVE_CONTRACT,
          payload: {
            ...contract,
            contractID,
            templateID: contractTemplateID,
            firstRedlineDate: (new Date()).toISOString()
          }
        })
      }
    }

    comments[index] = suggestion

    dispatch({
      type: STORE_INSTANCES_COMMENTS,
      payload: { [instanceID]: comments }
    })
  }
}

// ==================================================================================================

export const updateFieldTemplate = (inputFieldID, version) => async (dispatch, getState) => {
  if (inputFieldID && !inputFieldID.startsWith('__')) {
    const fields = { ...getState().template.templateCreator.fields }

    fields[inputFieldID] = version
    await dispatch(saveTemplate({ fields }))
  }
}

// ==================================================================================================

export const updateConditionalTextTemplate = (conditionalTextID, conditionalTextVersion) => async (dispatch, getState) => {
  const template = getState().template.templateCreator
  const conditionalText = getState().organisation.conditionalTexts[conditionalTextID][conditionalTextVersion]

  const fields = { ...template.fields }
  const conditionalTexts = { ...template.conditionalTexts }

  conditionalTexts[conditionalTextID] = conditionalTextVersion
  const { field, fieldVersion } = conditionalText
  if (field && !fields[field]) {
    const [base] = field.split('.')
    fields[base] = fieldVersion || 1
  }

  await dispatch(saveTemplate({ conditionalTexts, fields }))
}
