import React, { useCallback, useMemo, useState } from 'react'
import { Collection, Doc, Model, TypeConfiguration } from '../Types'
import { useDoc } from '../Helpers'
import DatabaseContext from '../DatabaseContext'

export interface File extends Model {
  id: string //           contract-rkfhztrg$$contract.preview
  instanceID: string //   contract-rkfhztrg
  fieldPath: string //    contract.preview
  url: string //          Generated temp url
  type: string //         image/png
  expiresAt: string //    Expiration date of the url
}

const config: TypeConfiguration<File> = {
  key: 'files',
  schema: {
    version: 0,
    primaryKey: 'id',
    type: 'object',
    properties: {
      id: {
        type: 'string'
      },
      instanceID: {
        type: 'string'
      },
      fieldPath: {
        type: 'string'
      },
      url: {
        type: 'string'
      },
      type: {
        type: 'string'
      },
      expiresAt: {
        type: 'string'
      }
    },
    required: [
      'id',
      'instanceID',
      'fieldPath',
      'url',
      'type',
      'expiresAt'
    ],
    attachments: { encrypted: false } // Only applied if the database use encryption
  }
}

const useFilesCollection = (): Collection<File> => React.useContext(DatabaseContext)[config.key] as Collection<File>

export const useFile = (instanceID: string, fieldPath: string): [File, string | undefined] | undefined => {
  const [data, setData] = useState<[File, string | undefined]>()

  const collection = useFilesCollection()
  useDoc<File, {}, undefined>(
    useMemo(() => collection.findOne(`${instanceID}$$${fieldPath}`), [collection, instanceID, fieldPath]),
    useCallback((file: Doc<File> | null) => {
      if (file) {
        (async () => {
          try {
            const blob = await file.getAttachment('file')?.getData()

            if (blob) {
              const reader = new FileReader()
              reader.addEventListener('load', () => {
                setData([file, reader.result as string])
              })
              reader.readAsDataURL(blob as Blob)
            }
          } catch {}
        })()
      }

      return undefined
    }, [])
  )

  return data
}

export const fileGQLQuery = `
{
  url
  expiresAt
}`

export default config
