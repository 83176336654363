import { Editor, Node, Path, Transforms } from 'slate'

import { isCollapsed, PlatePlugin } from '@udecode/plate-core'
import { createExitBreakPlugin, createSoftBreakPlugin } from '@udecode/plate-break'

/** ******************************************************************
 *                 Configure break plugins
 ****************************************************************** */
const breakPlugin: PlatePlugin = {
  key: 'breakPlugin',
  //  Treat when the user do deletion around a void block element
  withOverrides: editor => {
    const { deleteBackward, deleteForward, normalizeNode } = editor

    //  Custom normalization for certain nodes
    editor.normalizeNode = ([node, path]) => {
      if (['d', 's', 'li'].includes(node.type) && Array.isArray(node.children) && (
        node.children.length === 0
        || (node.children.length === 1 && node.children[0].text === '')
      )) {
        Transforms.removeNodes(editor, { at: path })
        return
      }

      normalizeNode([node, path])
    }

    const handleDeletion = (unit: Parameters<typeof deleteBackward>[0], selFunc: typeof Editor.before, baseFunc: typeof deleteBackward) => {
      const { selection } = editor

      if (selection && isCollapsed(selection) && selection.anchor.offset === 0) {
        const nodeEntry = Editor.parent(editor, selection)
        if (
          nodeEntry && nodeEntry[0].type === 'p' && nodeEntry[0].children.length === 1
          && nodeEntry[0].children[0]?.text?.length === 0
        ) {
          const newSel = selFunc(editor, selection, { unit: 'character' })
          Editor.withoutNormalizing(editor, () => {
            Transforms.removeNodes(editor, { at: nodeEntry[1] })
            if (newSel) {
              Transforms.setSelection(editor, { anchor: newSel, focus: newSel })
            }
          })
          return
        }
      }
      baseFunc(unit)
    }

    editor.deleteBackward = unit => handleDeletion(unit, () => undefined, deleteBackward)
    editor.deleteForward = unit => handleDeletion(unit, (_, sel) => (sel as any).anchor, deleteForward)

    return editor
  },

  handlers: {
    //  Treat when the user do enter to insert node to the right place
    onKeyDown: editor => {
      const softBreak = createSoftBreakPlugin().handlers?.onKeyDown?.(editor, breakPlugin as any)
      const exitBreak = createExitBreakPlugin().handlers?.onKeyDown?.(editor, breakPlugin as any)

      return evt => {
        if (evt.key !== 'Enter' || !editor.selection || !isCollapsed(editor.selection)) {
          return
        }

        const createNode = (insertPath: Path) => {
          evt.preventDefault()

          Transforms.insertNodes(editor, { type: 'p', children: [{ text: '' }] }, {
            at: insertPath,
            select: true
          })
        }

        const { anchor: { path, offset } } = editor.selection
        const nodeEntries = [...Editor.nodes(editor, { at: path, mode: 'all' })]
        nodeEntries.shift() //  Skip editor nodeEntry at beginning

        ;(() => {
          const leaf = nodeEntries.pop()
          if (!leaf) {
            return
          }

          //  Treat void block elements
          if (!editor.isInline(leaf[0] as any) && editor.isVoid(leaf[0] as any)) {
            const newPath = [...leaf[1]]
            if (!evt.ctrlKey) {
              newPath[newPath.length - 1] += 1
            }
            createNode(newPath)
            return
          }

          //  Then treat normal editing
          const { text } = leaf[0]
          if (text == null) {
            return
          }


          const checkEdge = (eql: (index: number, parent: Node) => boolean): Path | undefined => {
            const stack = [...nodeEntries]
            let child = leaf
            let parent = stack.pop()
            let selectedPath: Path | undefined

            do {
              if (!parent) {
                break
              }
              const [childIndex] = child[1].slice(-1)

              if (!parent[0] || !Array.isArray(parent[0].children) || !eql(childIndex, parent[0])) {
                break
              }
              if (parent[0].type === 'li') {
                selectedPath = undefined
                break
              } // Leave li treatment intact
              if (parent[0].type !== 'd' && parent[0].type !== 'p') {
                break
              }

              selectedPath = parent[1]
              child = parent
              parent = stack.pop()
            } while (true)

            return selectedPath
          }


          //  Checking ending edge
          if (offset === leaf[0].text.length) {
            let selectedPath = checkEdge((index, parent) => index === parent.children.length - 1)
            if (selectedPath) {
              selectedPath = [...selectedPath]
              selectedPath[selectedPath.length - 1] += 1
              createNode(selectedPath)
              return
            }
          }

          //  Checking starting edge
          if (offset === 0) {
            const selectedPath = checkEdge(index => index === 0)
            if (selectedPath) {
              createNode(selectedPath)
            }
          }
        })()

        //  If not proceed looks the other options
        if (!evt.defaultPrevented) {
          softBreak?.(evt)
        }
        if (!evt.defaultPrevented) {
          exitBreak?.(evt)
        }
      }
    }
  }
}

export default breakPlugin
