import React from 'react'
import { Checkbox, Radio } from 'antd'

import { AssistantInputProps } from './Types'
import InputQuestion from './inputElements/InputQuestion'
import FieldExplanation from './inputElements/FieldExplanation'
import useFieldSaving from './inputElements/useFieldSaving'

import './ListFieldInputStyles.scss'

const ListFieldInput: React.FC<AssistantInputProps> = props => {
  const { field } = props
  const List = field.isMultipleChoices ? Checkbox : Radio

  return (
    <>
      <InputQuestion field={field} />
      <List.Group className='contractAssistantListField' {...useFieldSaving<any>(props, false, !field.isMultipleChoices)}>
        {Object(field.values) === field.values && Object.entries(field.values).map(([key, val]) => {
          if (typeof val === 'string') {
            return <List key={key} value={key}>{val}</List>
          }
          return null
        })}
      </List.Group>
      <FieldExplanation field={field} />
    </>
  )
}

export default ListFieldInput
