import React, { useEffect, useState } from 'react'
import { Card, Image, Tag, Tooltip } from 'antd'
import { CaretRightOutlined, DeploymentUnitOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import TemplatePlaceholder from '../../img/templatePlaceholder.png'
import TLogo from '../../img/t-logo white.svg'
import SimpleLoadingSpinner from '../Alert/SimpleLoadingSpinner'

import '../Contract/styles/ContractCardStyles.scss'

// ==================================================================================================
// ==================================================================================================

export const genExtraTags = record => {
  const tags = []
  if (record.community === 'true') {
    let title = <Trans>Community playbook</Trans>
    //  TODO: Move to whitelabel version instead of HardCoded one for transferinitiative
    if (window.IS_TRANSFERINITIATIVE) {
      title = <Trans>Transferinitiative RLP playbook</Trans>
    }
    tags.push(
      <Tooltip key='commuTag' title={title}>
        <Tag className='commuTag' color='green'><DeploymentUnitOutlined /></Tag>
      </Tooltip>
    )
  }
  if (record.public === 'true') {
    tags.push(
      <Tooltip key='publicTag' title={<Trans>top.legal playbook</Trans>}>
        <Tag className='publicTag' color='#1c3556'>
          <img
            alt='TLogo'
            src={TLogo}
            style={{
              maxHeight: '100%', maxWidth: '100%', width: 'auto', height: 'auto'
            }}
          />
        </Tag>
      </Tooltip>
    )
  }
  if (record.demo === 'true') {
    tags.push(
      <Tooltip key='demoTag' title={<Trans>Demo playbook</Trans>}>
        <Tag className='demoTag' color='orange'><CaretRightOutlined /></Tag>
      </Tooltip>
    )
  }
  return tags
}

const cancelEvent = evt => {
  evt.preventDefault()
  evt.stopPropagation()
}


export const LoadingCardPlaceholder = () => (
  <div className='loadingCardPlaceholder'>
    <Image preview={false} src={TemplatePlaceholder} />
    <SimpleLoadingSpinner theme='darkBlue' />
  </div>
)

export const CardCover = ({ src }) => {
  const [status, setStatus] = useState('loading')

  useEffect(() => {
    if (typeof src === 'string' && src) {
      setStatus('loading')
      const img = new window.Image()
      img.onload = () => setStatus('done')
      img.onabort = () => setStatus('error')
      img.onerror = () => setStatus('error')
      img.src = src
    } else {
      setStatus('error')
    }
  }, [src])

  if (status === 'loading') {
    return <LoadingCardPlaceholder />
  }

  if (status === 'error') {
    return <Image preview={false} src={TemplatePlaceholder} />
  }

  return (
    <Image
      alt='preview'
      preview={false}
      src={src}
    />
  )
}


/**
 *
 * card that is used for playbooks.
 */
const TemplateCard = ({ record, children, ...props }) => (
  <Card
    {...props}
    className='templateCard'
    cover={<CardCover src={record.preview} />}
    extra={<>{children[3]}{genExtraTags(record)}</>}
    hoverable
  >
    <div className='labelsWrapper' onClick={cancelEvent} onContextMenu={cancelEvent}>{children[4]}</div>
    <div className='userListWrapper' onClick={cancelEvent} onContextMenu={cancelEvent}>{children[5]}</div>
    <Card.Meta
      description={(
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
          {children[2]}
          <span>{children[1]}</span>
        </div>
      )}
      title={children[0]}
    />
  </Card>
)

export default TemplateCard
