import React, { useCallback, useMemo, useState } from 'react'
import { Trans } from '@lingui/macro'
import { useSelector } from 'react-redux'
import { Button, DatePicker, Select } from 'antd'
import * as jsontocsv from 'jsonexport/dist'
import { UserAvatarByID } from '../../Organisations/UserRoleDisplay/UserAvatarList'

import './ExportStyles.scss'

const Export = ({ templatesTable, data, userCompanyData }) => {
  const { inputFields, fieldResponses } = useSelector(state => state.organisation)

  const contacts = useSelector(state => (state.dataTable['all-contacts'] && state.dataTable['all-contacts'].data) || [])
  const [dateStart, setDateStart] = useState(null)
  const [dateEnd, setDateEnd] = useState(null)
  const [templateFilter, setTemplateFilter] = useState('all')
  const [contactFilter, setContactFilter] = useState('all')
  let partyIndex = 0

  const contractSigned = useMemo(
    () => data.filter(contract => {
      const signedDate = contract.signedDate && new Date(contract.signedDate)
      if (!signedDate) {
        return false
      }
      if (dateStart && dateEnd && (signedDate < dateStart || signedDate > dateEnd)) {
        return false
      }
      if (templateFilter !== 'all' && templateFilter !== contract.editingOrgininalTemplateID) {
        return false
      }
      if (contactFilter !== 'all') {
        const parties = Object.values(contract.parties)
        if (contactFilter !== 'internal') {
          if (!parties.includes(contactFilter)) {
            return false
          }
        }
        if (!parties.includes(userCompanyData.company.organisationID) && !parties.includes(userCompanyData.user.userID)) {
          return false
        }
      }
      return true
    })
    , [data, dateStart, dateEnd, templateFilter, contactFilter, userCompanyData]
  )

  const exportData = useCallback(
    () => contractSigned.map(contract => {
      let contractObject = { name: contract.contractName }
      fieldResponses[contract.contractID] && Object.keys(fieldResponses[contract.contractID]).forEach(fieldKey => {
        if (inputFields[fieldKey]) {
          if (inputFields[fieldKey][1].type === 'user' || inputFields[fieldKey][1].type === 'company') {
            partyIndex += 1
            const { name, firstName, lastName } = fieldResponses[contract.contractID][fieldKey].value
            contractObject = {
              ...contractObject, [`party_${partyIndex}`]: name || `${firstName} ${lastName}`
            }
          }
        }
        if (fieldKey.startsWith('__')) {
          partyIndex += 1
          const { name, firstName, lastName } = fieldResponses[contract.contractID][fieldKey].value
          contractObject = {
            ...contractObject, [`party_${partyIndex}`]: name || `${firstName} ${lastName}`
          }
        }
      })
      partyIndex = 0
      contractObject = {
        ...contractObject, name: contract.contractName, signing_date: contract.signedDate
      }
      fieldResponses[contract.contractID] && Object.keys(fieldResponses[contract.contractID]).forEach(fieldKey => {
        if (inputFields[fieldKey]) {
          if (inputFields[fieldKey][1].type !== 'user' && inputFields[fieldKey][1].type !== 'company') {
            contractObject = {
              ...contractObject,
              // eslint-disable-next-line max-len
              [inputFields[fieldKey][1].unit ? `${inputFields[fieldKey][1].name} (${inputFields[fieldKey][1].unit})` : inputFields[fieldKey][1].name]: fieldResponses[contract.contractID][fieldKey].value
            }
          }
        }
      })
      return contractObject
    }),
    [contractSigned, fieldResponses, inputFields]
  )


  return (
    <div className='exportCard'>
      <div className='selectFilter'>
        <h4><Trans>Filter with signing period:</Trans></h4>
        <DatePicker.RangePicker
          onCalendarChange={dates => {
            setDateStart(dates ? new Date(dates[0]) : null)
            setDateEnd(dates ? new Date(dates[1]) : null)
          }}
        />
      </div>
      <div className='selectFilter'>
        <h4><Trans>Filter with playbook:</Trans></h4>
        <Select
          dropdownClassName='dropDown'
          onChange={value => setTemplateFilter(value)}
          value={templateFilter}
        >
          <Select.Option key='all' value='all'> <span><Trans>All playbooks</Trans></span></Select.Option>
          {templatesTable.map(template => (
            <Select.Option key={template.templateID} value={template.templateID}>
              <span>{template.name}</span><UserAvatarByID autoSide size='1.5rem' userID={template.createdBy} />
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className='selectFilter'>
        <h4><Trans>Filter with Contracting parties:</Trans></h4>
        <Select
          dropdownClassName='dropDown'
          onChange={value => setContactFilter(value)}
          value={contactFilter}
        >
          <Select.Option key='all' value='all'> <span><Trans>All contracting parties</Trans></span></Select.Option>
          <Select.Option key='internal' value='internal'> <span><Trans>Internal Party: {userCompanyData.company.name}</Trans></span></Select.Option>
          {contacts.map(contact => (
            <Select.Option key={contact.partyID || contact.organisationID} value={contact.partyID || contact.organisationID}>
              <span>{contact.companyName || `${contact.firstName} ${contact.lastName}`}</span>
            </Select.Option>
          ))}
        </Select>
      </div>
      <Button
        className='downloadButton'
        onClick={async () => {
          const tmp = document.createElement('a')
          const csv = await jsontocsv(exportData())
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' })
          const url = URL.createObjectURL(blob)
          tmp.href = url
          tmp.download = 'statistics'
          tmp.target = '_blank'
          document.body.appendChild(tmp)
          tmp.click()
          setTimeout(() => {
            document.body.removeChild(tmp)
            URL.revokeObjectURL(url)
          }, 50)
        }}
        type='primary'
      >Download CSV
      </Button>
    </div>
  )
}

export default {
  key: 'export',
  title: <Trans>Export Contracts Data</Trans>,
  Component: Export,
  width: 4,
  height: 5
}

