/* eslint-disable max-lines */
import React, {
  useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState
} from 'react'
import { useParams } from 'react-router'
import { Button, Input, InputNumber, Select, Tooltip } from 'antd'
import { DownloadOutlined, FilePdfOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import Storage from '@aws-amplify/storage'
import { useDispatch, useSelector } from 'react-redux'

import { GoogleFontSelector, GoogleFontSelectorProvider } from '@top-legal/react-helpers'

import withPhoneLayoutWarning from '../SharedComponents/withPhoneLayoutWarning'
import { withUserData } from '../Layouts/AuthenticatedPage'
import { UserAndCompanyDataContext } from '../Layouts/Constants'
import MainContainer from '../Layouts/MainLayout/MainContainer'
import useMediaQueries from '../../hooks/useMediaQueries'
import SwipableContainer from '../SharedComponents/SwipableContainer'

import { SwitchInput } from '../SharedComponents/FormComponents/index'
import RestService from '../../RestService'
import ButtonLink from '../SharedComponents/ButtonLink'
import ImageUploader from '../SharedComponents/FormComponents/ImageUploader'
import { saveOrganisation } from './redux/OrganisationsActions'
import ContractEditorComponent from '../Contract/ContractEditor/ContractEditorComponent'
import { IdPrefixContext } from '../Contract/ContractDiplay/ContractLayout'
import { ContractContext, TemplateContext } from '../Contract/ContractEditor/Contexts'
import loadingModalFeedback from '../Alert/LoadingModalFeedback'
import { ProvideContractEditorContext } from '../Contract/ContractEditor/editorRefactoredPart/withContractEditorContext'
import { useComputeSectionMapping } from '../Contract/Utils'

import './ContractStylingStyles.scss'
import Logo from '../../img/top-legal-logo-blue.svg'

const AllFonts = () => (
  <ButtonLink
    href='https://fonts.google.com/?category=Serif,Sans+Serif'
    noRouter rel='noreferrer' target='_blank'
    type='default'
  >
    <Trans>Have a look</Trans>
  </ButtonLink>
)

//  Small hack to create uniqu sections from all the same
const useSectionsFromStore = () => {
  const sections = useSelector(state => state.template.sections)
  const get = useCallback(sectionID => {
    const secID = sectionID.split(':')[0]
    return { ...sections[secID], sectionID }
  }, [sections])
  //  Create a fake map using a object with get func
  return useMemo(() => [{ get }, false], [get])
}

const ContractStylingContent = () => {
  useComputeSectionMapping()

  return (
    <IdPrefixContext.Provider value='template-styling'>
      <ContractEditorComponent noUpload />
    </IdPrefixContext.Provider>
  )
}

const ValueSelect = ({ styling, setStyling, yPosition, xPosition, companyLogoUrl }) => {
  const { lang } = useParams()

  return (
    <Select
      bordered={false} className={`simple-line ${xPosition}`}
      onChange={value => setStyling(obj => ({
        ...obj,
        [yPosition]: {
          ...obj[yPosition],
          [xPosition]: value
        }
      }))}
      size='small'
      value={styling[yPosition][xPosition]}

    >
      <Select.Option value=''>&nbsp;</Select.Option>
      <Select.Option style={{ textAlign: xPosition }} value='title'>Contract Title</Select.Option>
      <Select.Option style={{ textAlign: xPosition }} value='pages'><Trans>Page 1 of 10</Trans></Select.Option>
      <Select.Option style={{ textAlign: xPosition }} value='date'>
        {
          new Intl.DateTimeFormat(lang, {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
          }).format(new Date())
        }
      </Select.Option>
      <Select.Option style={{ textAlign: xPosition }} value='parties'>John Smith (Google) / Joe Johnson (Amazon)</Select.Option>
      <Select.Option style={{ textAlign: xPosition }} value='logo'><img alt='logo' height='18mm' src={companyLogoUrl || Logo} width='auto' /></Select.Option>
    </Select>
  )
}

const MyInput = ({ position, styling, setStyling }) => (
  <Input.Group className='inputWithUnit' compact>
    <InputNumber
      min={0}
      onChange={val => setStyling(old => ({
        ...old,
        [position]: val
      }))}
      step={0.1} value={styling[position]}
    />
    <Input disabled style={{ width: '4rem' }} value='mm' />
  </Input.Group>
)


const ContractStyling = () => {
  const template = useSelector(state => state.template.stylingTemplate)

  const { company } = useContext(UserAndCompanyDataContext)
  const isSmall = useMediaQueries(['(max-width: 1000px)'], [true], false)
  const Container = isSmall ? SwipableContainer : 'div'
  const documentRef = useRef()
  const [showGuide, setShowGuide] = useState(false)
  const [companyLogoUrl, setCompanyLogoUrl] = useState()
  const [styling, setStyling] = useState({
    left: 25,
    right: 25,
    top: 20,
    bottom: 20,

    headerSpacingInner: 3,
    headerSpacingOuter: 5,
    footerSpacingInner: 3,
    footerSpacingOuter: 5,
    headerLine: true,
    footerLine: true,

    header: {
      left: 'title',
      center: '',
      right: 'date'
    },
    footer: {
      left: 'parties',
      center: '',
      right: 'pages'
    },

    companyLogo: company.companyLogo || null,

    font: 'Nunito Sans',
    fontSize: 3.3,
    lineHeight: 4.0,
    paragraphSpacing: 2.5,
    sectionSpacing: 4.5,
    sectionTitleSpacing: 1.4,

    //  Override defaults with organisation contractStyling
    ...(company.contractStyling || {})
  })

  const dispatch = useDispatch()
  const saveChanges = useCallback(
    () => loadingModalFeedback({
      loadingTitle: <Trans>Saving your changes</Trans>,
      successTitle: <Trans>Saved</Trans>,
      errorTitle: <Trans>Failed to save</Trans>,
      errorDescription: <Trans>An error occurred while saving your changes</Trans>,
      autoSuccessClose: 1000
    })(() => {
      const { companyLogo, ...contractStyling } = styling
      return dispatch(saveOrganisation({ companyLogo, contractStyling, organisationID: company.organisationID }))
    }),
    [styling, dispatch, company.organisationID]
  )

  useEffect(() => {
    if (company.companyLogo) {
      const split = company.companyLogo.split('/')
      split.shift()
      const identityId = split.shift()
      const expires = 60 * 60 * 6 // 6hours the max for an identity
      Storage.get(split.join('/'), { level: 'protected', identityId, expires }).then(setCompanyLogoUrl)
    }

    return () => setCompanyLogoUrl(null)
  }, [company.companyLogo])

  //  Setting the css attribute for the font family
  useEffect(() => {
    documentRef.current.style.setProperty('--font-family', styling.font)
    documentRef.current.style.setProperty('--font-size', `${styling.fontSize}mm`)
    documentRef.current.style.setProperty('--line-height', `${styling.lineHeight}mm`)
    documentRef.current.style.setProperty('--paragraph-spacing', `${styling.paragraphSpacing}mm`)
    documentRef.current.style.setProperty('--section-spacing', `${styling.sectionSpacing}mm`)
    documentRef.current.style.setProperty('--section-title-spacing', `${styling.sectionTitleSpacing}mm`)
  }, [styling.font, styling.fontSize, styling.lineHeight, styling.paragraphSpacing, styling.sectionSpacing, styling.sectionTitleSpacing])

  //  Adjusting the document zoom factor
  useLayoutEffect(() => {
    let ratio
    const adjustDocumentZoom = () => {
      if (documentRef.current && documentRef.current.parentNode) {
        documentRef.current.style.transition = null
        documentRef.current.style.transform = null
        setTimeout(() => {
          const rectPar = documentRef.current.parentNode.getBoundingClientRect()
          const rect = documentRef.current.getBoundingClientRect()
          const widthRatio = rectPar.width / rect.width
          const heightRatio = rectPar.height / rect.height
          ratio = Math.min(widthRatio, heightRatio) - 0.1 // Take 10% margin
          documentRef.current.style.transition = 'all ease 0.5s'
          documentRef.current.style.transform = `scale(${ratio})`
          documentRef.current.style.setProperty('--border-width', `${Math.ceil(1 / ratio)}px`)
          documentRef.current.style.setProperty('--header-line', `${Math.ceil(1 / ratio)}px`)
        })
      }
    }
    adjustDocumentZoom()

    const elm = documentRef.current
    const translateHandler = evt => {
      const rect = elm.parentNode.getBoundingClientRect()
      const relativeX = evt.pageX - rect.x - rect.width / 2
      const relativeY = evt.pageY - rect.y - rect.height / 2
      documentRef.current.style.transform = `scale(1) translate3d(${-relativeX}px, ${-relativeY}px, 0)`
      if (documentRef.current.style.transition) {
        setTimeout(() => {
          documentRef.current.style.transition = null
          documentRef.current.style.setProperty('--header-line', '1px')
        }, 500)
      }
    }
    const resetTranslate = () => {
      documentRef.current.style.transform = `scale(${ratio})`
      documentRef.current.style.transition = 'all ease 0.5s'
      documentRef.current.style.setProperty('--header-line', `${Math.ceil(1 / ratio)}px`)
    }

    if (elm) {
      elm.parentNode.addEventListener('mousemove', translateHandler)
      elm.parentNode.addEventListener('mouseleave', resetTranslate)
    }
    window.addEventListener('resize', adjustDocumentZoom)
    return () => {
      if (elm) {
        elm.parentNode.removeEventListener('mousemove', translateHandler)
        elm.parentNode.removeEventListener('mouseleave', resetTranslate)
      }
      window.removeEventListener('resize', adjustDocumentZoom)
    }
  }, [])

  /** ****************************************************************************************
   *                Generates all styling objects for the view
   **************************************************************************************** */
  const genHFStyle = (height, padding, direction, hasLine) => ({
    minHeight: `${height}mm`,
    height: `${height}mm`,
    manHeight: `${height}mm`,
    [`padding${direction}`]: `${padding}mm`,
    [`border${direction}`]: hasLine ? 'var(--header-line) solid #000' : 'var(--header-line) solid transparent'
  })
  const headerStyle = genHFStyle(styling.top, styling.headerSpacingInner, 'Bottom', styling.headerLine)
  const footerStyle = genHFStyle(styling.bottom, styling.footerSpacingInner, 'Top', styling.footerLine)

  const documentStyle = {
    paddingLeft: `${styling.left}mm`,
    paddingRight: `${styling.right}mm`
  }
  const documentContentStyle = {
    paddingTop: `${styling.headerSpacingOuter}mm`,
    paddingBottom: `${styling.footerSpacingOuter}mm`
  }

  const gen = yPosition => {
    const props = {
      yPosition,
      styling,
      setStyling,
      companyLogoUrl
    }
    return (
      <table>
        <tr>
          <td><ValueSelect {...props} xPosition='left' /></td>
          <td><ValueSelect {...props} xPosition='center' /></td>
          <td><ValueSelect {...props} xPosition='right' /></td>
        </tr>
      </table>
    )
  }
  const download = async () => {
    window.notification.info({
      message: <Trans>Preparing download</Trans>,
      description: <Trans>We are currently preparing your document for download. We will send you an email with the .pdf sample</Trans>
    })

    await RestService('POST', '/utils/convert-document', { type: 'pdf', templateID: 'template-userStyleSample' })
  }

  const actionButtons = (
    <>
      <Button ghost onClick={() => download('pdf')} type='primary'>
        <DownloadOutlined />
        <Trans>Download .pdf Sample</Trans>
      </Button>
      <Button onClick={saveChanges} style={{ marginLeft: '5px' }} type='primary'>
        <Trans>Save Styling</Trans>
      </Button>
    </>
  )

  const [sectionMapping, setSectionMapping] = useState({})

  /** ****************************************************************************************
   *                Rendering the view
   **************************************************************************************** */
  return (
    <MainContainer
      mainContentClass='contractStylingWrapper'
      topbarContent={(
        <>
          <div className='topbarMainContent'>
            <FilePdfOutlined className='headerIcon' twoToneColor='#3DBD7D' />
            <h1 className='title'><Trans>Contract styling</Trans></h1>
          </div>
          <div className='topbarActions contractListingActions' />
          {actionButtons}
        </>
      )}
    >
      <Container className='contractStyling' disableSelection>
        <div className='stylingConfiguration'>
          <div className='configurationBox'>
            {/* Come at later point */}
            {/* <div className='styleCategory'> */}
            {/*  <h2><Trans>Global styling</Trans></h2> */}
            {/*  <Radio.Group value='dynamic'> */}
            {/*    <Radio.Button value='dynamic'><Trans>Dynamic align</Trans></Radio.Button> */}
            {/*    <Radio.Button value='static'><Trans>Static align</Trans></Radio.Button> */}
            {/*    <Radio.Button value='constant'><Trans>Constant size</Trans></Radio.Button> */}
            {/*  </Radio.Group> */}
            {/* </div> */}
            <div className='styleCategory'>
              <h2><Trans>Page margin</Trans></h2>
              <table className='marginTable'>
                <tr>
                  <td><Trans>Show grid?</Trans> <Tooltip title={<Trans>Only for the preview</Trans>}><InfoCircleOutlined /></Tooltip></td>
                  <td><SwitchInput onChange={setShowGuide} value={showGuide} /></td>
                </tr>
                <tr>
                  <td colSpan={2} style={{ padding: '0.5rem' }} />
                </tr>
                <tr>
                  <td><Trans>Top</Trans></td>
                  <td><MyInput position='top' setStyling={setStyling} styling={styling} /></td>
                </tr>
                <tr>
                  <td><Trans>Bottom</Trans></td>
                  <td><MyInput position='bottom' setStyling={setStyling} styling={styling} /></td>
                </tr>
                <tr>
                  <td><Trans>Left</Trans></td>
                  <td><MyInput position='left' setStyling={setStyling} styling={styling} /></td>
                </tr>
                <tr>
                  <td><Trans>Right</Trans></td>
                  <td><MyInput position='right' setStyling={setStyling} styling={styling} /></td>
                </tr>
                <tr>
                  <td colSpan={2} style={{ padding: '0.5rem' }} />
                </tr>
                <tr>
                  <td><Trans>Header inner</Trans></td>
                  <td><MyInput position='headerSpacingInner' setStyling={setStyling} styling={styling} /></td>
                </tr>
                <tr>
                  <td><Trans>Header outer</Trans></td>
                  <td><MyInput position='headerSpacingOuter' setStyling={setStyling} styling={styling} /></td>
                </tr>
                <tr>
                  <td><Trans>Footer outer</Trans></td>
                  <td><MyInput position='footerSpacingOuter' setStyling={setStyling} styling={styling} /></td>
                </tr>
                <tr>
                  <td><Trans>Footer inner</Trans></td>
                  <td><MyInput position='footerSpacingInner' setStyling={setStyling} styling={styling} /></td>
                </tr>
                <tr>
                  <td colSpan={2} style={{ padding: '0.5rem' }} />
                </tr>
                <tr>
                  <td><Trans>Header line?</Trans></td>
                  <td><SwitchInput
                    onChange={val => setStyling(old => ({
                      ...old,
                      headerLine: val
                    }))} value={styling.headerLine}
                  />
                  </td>
                </tr>
                <tr>
                  <td><Trans>Footer line?</Trans></td>
                  <td><SwitchInput
                    onChange={val => setStyling(old => ({
                      ...old,
                      footerLine: val
                    }))} value={styling.footerLine}
                  />
                  </td>
                </tr>
              </table>
            </div>
            <div className='styleCategory'>
              <h2><Trans>Company logo</Trans></h2>
              <ImageUploader
                accept='.svg, .png, .jpg'
                className='logoUploader'
                filePrefix='CompanyLogo'
                onChange={key => setStyling(obj => ({ ...obj, companyLogo: key }))}
                placeholder={<Trans>Upload your company logo</Trans>}
                storageLevel='protected'
                value={styling.companyLogo}
              />
            </div>
            <div className='styleCategory'>
              <h2><Trans>Text</Trans></h2>
              <table className='marginTable'>
                <tr>
                  <td>
                    <Trans>Font</Trans>
                    <Tooltip title={<Trans>Would you like to see all fonts at the same time? <AllFonts /></Trans>}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </td>
                  <td>
                    <GoogleFontSelector
                      onChange={font => setStyling(obj => ({ ...obj, font }))}
                      showSearch
                      value={styling.font}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} style={{ padding: '0.5rem' }} />
                </tr>
                <tr>
                  <td><Trans>Font size</Trans></td>
                  <td><MyInput position='fontSize' setStyling={setStyling} styling={styling} /></td>
                </tr>
                <tr>
                  <td><Trans>Line height</Trans></td>
                  <td><MyInput position='lineHeight' setStyling={setStyling} styling={styling} /></td>
                </tr>
                <tr>
                  <td><Trans>Paragraph spacing</Trans></td>
                  <td><MyInput position='paragraphSpacing' setStyling={setStyling} styling={styling} /></td>
                </tr>
                <tr>
                  <td><Trans>Section spacing</Trans></td>
                  <td><MyInput position='sectionSpacing' setStyling={setStyling} styling={styling} /></td>
                </tr>
                <tr>
                  <td><Trans>Section title spacing</Trans></td>
                  <td><MyInput position='sectionTitleSpacing' setStyling={setStyling} styling={styling} /></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className={`stylingViewer ${showGuide ? 'showGuide' : ''}`}>
          <div ref={documentRef} className='documentWrapper' style={documentStyle}>
            <div className='document'>
              <div className='documentHeaderWrapper' style={headerStyle}>
                <div className='documentHeader'>
                  {gen('header')}
                </div>
              </div>
              <div className='documentContentWrapper' style={documentContentStyle}>
                <div className='documentContent'>
                  <ContractContext.Provider value={useMemo(() => ({ contract: {} }), [])}>
                    <TemplateContext.Provider
                      value={useMemo(() => ({
                        readOnly: true,
                        template,
                        sectionMapping,
                        setSectionMapping,
                        sectionDeltasMapping: {},
                        setSectionDeltasMapping: () => null
                      }), [sectionMapping, template])}
                    >
                      <ProvideContractEditorContext useSectionsFromStore={useSectionsFromStore}>
                        <ContractStylingContent />
                      </ProvideContractEditorContext>
                    </TemplateContext.Provider>
                  </ContractContext.Provider>
                </div>
              </div>
              <div className='documentFooterWrapper' style={footerStyle}>
                <div className='documentFooter'>
                  {gen('footer')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </MainContainer>

  )
}


const googleFontOptions = {
  fontCategories: ['serif', 'sans-serif'],
  variants: 'regular',
  subsets: 'latin',
  nunitoSansAsDefault: true
}

const Wrapped = props => {
  const Component = useMemo(() => withPhoneLayoutWarning(withUserData(ContractStyling)), [])
  return (
    <GoogleFontSelectorProvider {...googleFontOptions}>
      <Component {...props} />
    </GoogleFontSelectorProvider>
  )
}

export default Wrapped
