import React, { useCallback, useContext, useLayoutEffect, useMemo, useRef } from 'react'
import { t, Trans } from '@lingui/macro'
import { Node } from 'slate'
import { HistoryEditor } from 'slate-history'
import { createPlugins, Plate, PlateEditor, PlateProps, usePlateEditorRef } from '@udecode/plate-core'
import uniqid from 'uniqid'

import { withValue2InitialValue } from '@top-legal/react-helpers'

import { DefaultSlateContent, isEmpty, ParagraphType } from '../core'

import EditorErrorHandler from './EditorErrorHandler'
import EditorContext, { Decorate } from './EditorContext'
import plateComponents from './PlateComponents'
import { useEditorEvents } from './Helpers'
import { RenderOnlyEditor } from './index'

//  Rendering of Delta cannot be assured by plugins for now
import { DELTA_MARK } from './yjsEditor/YjsDelta'
import DeltaComponent from './customPluginsComponents/DeltaComponent'

import './EditorStyles.scss'


/** ******************************************************************
 *            Props & Defaults definitions
 ****************************************************************** */
const PlateMemo = React.memo(Plate)

type EditableProps = PlateProps['editableProps']

interface IEditorProps {
  editorID?: string
  onChange: (nodes: Node[]) => void
  placeholder?: string
  noContentCheck?: boolean
  editableProps?: Omit<EditableProps, 'readOnly' | 'placeholder'>
  forceFullEditor?: boolean
}

export interface EditorProps extends IEditorProps {
  value: Node[]
}

interface InternalEditorProps extends IEditorProps {
  initialValue: Node[]
}

//  The provider prevent the Editor component to get rerender only this one get update when we switch editors
const ProvidePlateEditorRef: React.FC<{ editorID: string, editorRef: React.MutableRefObject<PlateEditor | undefined> }> = ({
  editorID, editorRef
}) => {
  editorRef.current = usePlateEditorRef(editorID)
  return null
}


/** ******************************************************************
 *                    Slate editor
 ****************************************************************** */
const disableCorePlugins = { history: true }
const Editor: React.FC<InternalEditorProps> = ({
  editorID: chosenEditorID,
  initialValue,
  onChange,
  placeholder = t`Enter some text...`,
  noContentCheck,
  editableProps: editablePropsOverride,
  forceFullEditor
}) => {
  const { plugins, decorates, readOnly, inlinesOnly } = useContext(EditorContext)
  const pluginsWithComponents = useMemo(
    () => {
      //  Decorates from plugins are ignored since it cause such more issues
      //  Please use instead EditorContext.decorates to provide some
      //  This will also improve performances since plugins wont changes but decorates will
      plugins.forEach(pl_ => { delete pl_.decorate })
      return createPlugins(plugins, { components: plateComponents })
    },
    [plugins]
  )
  const internalID = useMemo(() => chosenEditorID || uniqid(), [chosenEditorID])
  const internalCountRef = useRef(0)
  const editorRef = useRef<PlateEditor>()


  //  Build a custom internal value to support editor with inlines only
  const internalValue = useMemo(() => {
    if (inlinesOnly) {
      if (Array.isArray(initialValue) && initialValue.length > 0) {
        return [{ type: ParagraphType, children: initialValue }]
      }
    } else if (!noContentCheck && (!Array.isArray(initialValue) || !initialValue[0]?.type)) {
      internalCountRef.current += 1
      return JSON.parse(JSON.stringify(DefaultSlateContent))
    }
    return initialValue
  }, [inlinesOnly, initialValue, noContentCheck])

  //  Custom change handler to differentiate for inlinesOnly
  const changeHandler = useCallback(nodes => {
    if (editorRef.current && nodes !== internalValue) {
      if (inlinesOnly) {
        if (nodes.length > 1) {
          (editorRef.current as unknown as HistoryEditor).undo()
          ;(window as any).notification.error({
            message: <Trans>Action canceled</Trans>,
            description: <Trans>Inline element can only have text and other inline elements</Trans>
          })
        } else if (nodes.length === 1) {
          onChange(nodes[0].children)
        } else {
          onChange([{ text: '' }])
        }
      } else {
        onChange(nodes)
      }
    }
  }, [inlinesOnly, onChange, internalValue])


  //  Editable props readOnly & placeholder
  const editorID = `${internalID}-${internalCountRef.current}`
  const events = useEditorEvents(editorID)
  const editableProps = useMemo(() => ({
    ...editablePropsOverride,
    ...events,
    readOnly,
    placeholder
  }), [editablePropsOverride, placeholder, readOnly, events])

  //  The only way I found to have decorates working properly is overriding the renderEditable
  //  Same for rendering empty leaves (like deletion delta)
  const renderEditableRef = useRef<any>({})
  const renderEditable = useMemo<PlateProps['renderEditable'] | undefined>(() => {
    if (decorates) {
      //  Make only one ref of renderLeaf using the ref of the prevRenderLeaf
      if (!renderEditableRef.current.renderLeaf) {
        renderEditableRef.current.renderLeaf = props => {
          const children = renderEditableRef.current.prevRenderLeaf(props)
          if (props.leaf[DELTA_MARK]) {
            return <DeltaComponent {...props} editorRef={editorRef}>{children}</DeltaComponent>
          }
          return children
        }
      }

      //  Make only one ref of renderLeaf using the ref of the prevRenderLeaf
      const decorate = nodeEntry => {
        const ranges: ReturnType<Decorate> = []
        decorates.forEach(decorator => {
          const newRanges = decorator(nodeEntry)
          if (newRanges) {
            ranges.push(...newRanges)
          }
        })
        return ranges
      }

      return (element: any) => {
        renderEditableRef.current.prevRenderLeaf = element.props.renderLeaf

        return React.cloneElement(element, {
          ...element.props,
          decorate,
          renderLeaf: renderEditableRef.current.renderLeaf
        })
      }
    }
    return undefined
  }, [decorates])

  //  Memoize the ref component
  const editorRefComp = useMemo(() => <ProvidePlateEditorRef editorID={editorID} editorRef={editorRef} />, [editorID])


  //  Return nothing if we have not yet the needed data
  if (!noContentCheck && readOnly && isEmpty(internalValue)) {
    return null
  }

  //  Finally render the editor
  return (
    <EditorErrorHandler id={editorID}>
      <div className={`editor ${readOnly ? 'readOnly' : ''}`}>
        {(!forceFullEditor && readOnly) ? (
          <RenderOnlyEditor nodes={internalValue} />
        ) : (
          <PlateMemo
            key={editorID}
            disableCorePlugins={disableCorePlugins}
            editableProps={editableProps}
            id={editorID}
            initialValue={internalValue}
            onChange={changeHandler}
            plugins={pluginsWithComponents}
            renderEditable={renderEditable}
          >
            {editorRefComp}
          </PlateMemo>
        )}
      </div>
    </EditorErrorHandler>
  )
}
const Wrapped = withValue2InitialValue<Node[], IEditorProps>(Editor)
Wrapped.displayName = 'Editor'
export default Wrapped
