import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { Button, ButtonProps, Popconfirm, PopconfirmProps } from 'antd'

const InnerButton: React.FC<ButtonProps> = ({ onClick, ...props }) => (
  <Button
    {...props}
    onClick={event => {
      event.preventDefault()
      event.stopPropagation()
      onClick?.(event)
    }}
  />
)

const ConfirmButton: React.FC<ButtonProps & Pick<PopconfirmProps, 'placement'> & { confirmMessage?: React.ReactElement }> = ({
  onClick, confirmMessage,
  disabled,
  loading: loading_,
  placement,
  ...buttonProps
}) => {
  const [loading, setLoading] = useState(false)

  return (
    <Popconfirm
      cancelText={<Trans>No</Trans>}
      disabled={loading || disabled || loading_ as any}
      okText={<Trans>Yes</Trans>}
      onConfirm={async event => {
        setLoading(true)
        try {
          await onClick?.(event as any)
        } finally {
          setLoading(false)
        }
      }}
      overlayClassName='confirmButtonTooltip'
      placement={placement || 'topLeft'}
      title={confirmMessage || <Trans>Are you sure to want delete this item?</Trans>}
    >
      <InnerButton {...buttonProps} disabled={disabled} loading={loading || loading_} />
    </Popconfirm>
  )
}

export default ConfirmButton
