import {
  CondDependency,
  ConditionalText,
  EntityRef, InputDependency, InputField,
  Section,
  SectionDependency,
  Template,
  TemplateFlaternisation
} from '@top-legal/datastore'

import { EditorContent } from '@top-legal/editor'
import TemplateContentVisitor, { EntityType, Getters } from './TemplateContentVisitor'


class TemplateDependenciesVisitor extends TemplateContentVisitor {
  dependencies: TemplateFlaternisation = {} as any

  prevDependencies?: TemplateFlaternisation = undefined

  children: EntityRef[] = []

  known: Record<string, boolean> = {}

  condDependency: CondDependency = {} as any

  //  Helper to have a living state only during the call of a function and receive generated EntityRef array
  withEntityRefs (func: () => void): EntityRef[] {
    //  Avoid duplicating the entity in the array
    this.known = {}
    this.children = []
    func()
    const refs = this.children
    this.children = []
    return refs
  }

  async visitTemplate (template: Template, prevDependencies?: TemplateFlaternisation): Promise<void | Template> {
    this.prevDependencies = prevDependencies

    return super.visitTemplate(template)
  }

  processSections (template: Template) {
    this.dependencies = {
      flatID: `flat-${template.templateID}`,
      dateUpdated: template.dateUpdated,

      fieldsDependencies: {},
      condsDependencies: {},
      sectionDependencies: {},

      sectionIDs: this.sectionIDs
    }

    super.processSections(template)
  }

  visitSection (section: Section): void | null | Section {
    const prevDeps = this.prevDependencies?.sectionDependencies?.[section.sectionID]

    //  Try to reuse previous computation when possible
    if (prevDeps && prevDeps.dateUpdated === section.dateUpdated) {
      //  Reuse the entities
      if (prevDeps.field) {
        this.visitEntitiesRef('inputField', prevDeps.field)
      }
      prevDeps.children?.forEach(({ type, key }) => this.visitEntitiesRef(type, key))

      this.dependencies.sectionDependencies[section.sectionID] = prevDeps
    } else {
      const deps: SectionDependency = {
        dateUpdated: section.dateUpdated || ''
      }

      if (section.isDynamicSection && section.field) {
        deps.field = section.field
      }

      const children = this.withEntityRefs(() => super.visitSection(section))
      if (children.length > 0) {
        deps.children = children
      }

      if (deps.field || deps.children) {
        this.dependencies.sectionDependencies[section.sectionID] = deps
      }
    }
  }

  visitEntitiesRef (entityType: EntityType, key: string): void | null | string | EditorContent[0] {
    //  We only push the entity ref if it has not been seen before
    if (!this.known[key]) {
      this.known[key] = true
      this.children.push({ type: entityType, key })
    }
    return super.visitEntitiesRef(entityType, key)
  }

  visitCond (cond: ConditionalText): void | ConditionalText | null {
    //  Define the condDependency
    this.condDependency = { field: cond.field, values: {} }

    super.visitCond(cond)

    this.dependencies.condsDependencies[cond.conditionalTextID] = this.condDependency

    //  Reset the condDependency
    this.condDependency = {} as any
  }

  visitCondValue (key: string, value: EditorContent) {
    const children = this.withEntityRefs(() => super.visitCondValue(key, value))

    if (children.length > 0) {
      this.condDependency.values[key] = children
    }
  }

  visitField (field: InputField): void | InputField | null {
    if (field.inputFieldID && !field.inputFieldID.startsWith('_')) {
      const children = this.withEntityRefs(() => super.visitField(field))

      const dep: InputDependency = {}
      if (children.length > 0) {
        dep.children = children
      }
      this.dependencies.fieldsDependencies[field.inputFieldID] = dep
    }
  }
}

export default TemplateDependenciesVisitor
