import { InputField } from '@top-legal/datastore'
import { parseDate } from '@top-legal/form-components'

const gender = {
  de: {
    m: 'männlich',
    f: 'weiblich'
  },
  en: {
    m: 'male',
    f: 'female'
  }
}

interface PersonField extends Omit<InputField, 'inputFieldID' | 'question' | 'isMultipleChoices'> {
  inputFieldID?: string
  value: (user: any, textFormatted: boolean) => any
  items: Record<string, PersonField>
  slateType?: 'block' | 'inline'
  optional?: boolean
}

const genPersonField = (fieldName: string, lang: string, inputFieldID: string): PersonField => {
  const field: PersonField = {
    inputFieldID,
    name: fieldName,
    type: 'person',
    slateType: 'block',
    value: (user, textFormatted) => {
      if (textFormatted) {
        const children = field.items as Record<string, PersonField>

        const name = children.full_name.value(user, textFormatted)
        const dob = children.dateOfBirth.value(user, textFormatted)
        const address = children.full_address.value(user, textFormatted)

        if (name && address) {
          const nodes = [{ type: 'p', children: [{ text: name }] }]
          if (dob && dob.getTime && !Number.isNaN(dob.getTime())) {
            const formattedDob = new Intl.DateTimeFormat(lang || 'en', {
              day: '2-digit',
              month: 'long',
              year: 'numeric'
            }).format(dob)
            nodes.push({ type: 'p', children: [{ text: formattedDob }] })
          }
          nodes.push(address[0])
          return nodes
        }
        return undefined
      }

      return user
    },
    items: {
      full_name: {
        name: `${fieldName} full name`,
        type: 'full_name',
        value: (user, textFormatted) => {
          if (textFormatted) {
            const children = field.items.full_name.items

            const firstName = children.firstName.value(user, textFormatted)
            const lastName = children.lastName.value(user, textFormatted)

            if (firstName && lastName) {
              return `${firstName} ${lastName}`
            }
            return undefined
          }

          return user
        },
        slateType: 'inline',
        items: {
          firstName: {
            name: `${fieldName} first name`,
            type: 'oneLineText',
            value: user => user?.firstName,
            items: {}
          },
          lastName: {
            name: `${fieldName} last name`,
            type: 'oneLineText',
            value: user => user?.lastName,
            items: {}
          }
        }
      },
      email: {
        name: `${fieldName} email`,
        type: 'oneLineText',
        value: user => user?.email,
        items: {}
      },
      gender: {
        name: `${fieldName} gender`,
        type: 'list',
        value: (user, textFormatted) => {
          if (textFormatted) {
            return (gender[lang] || gender.en)[user?.gender]
          }
          return user?.gender
        },
        values: gender[lang] || gender.en,
        optional: true,
        items: {}
      },
      dateOfBirth: {
        name: `${fieldName} date of birth`,
        type: 'date',
        value: (user, textFormatted) => (textFormatted ? parseDate(user?.dateOfBirth) : user?.dateOfBirth),
        items: {}
      },
      full_address: {
        name: `${fieldName} full address`,
        type: 'full_address',
        value: (user, textFormatted) => {
          if (textFormatted) {
            const children = field.items.full_address.items

            const country = children.country.value(user, textFormatted)
            const postcode = children.postcode.value(user, textFormatted)
            const city = children.city.value(user, textFormatted)
            const address = children.address.value(user, textFormatted)

            if (country && postcode && city && address) {
              return [{ type: 'p', children: [{ text: `${address}\n${postcode} ${city}\n${country}` }] }]
            }
            return undefined
          }

          return user
        },
        slateType: 'block',
        items: {
          country: {
            name: `${fieldName} country`,
            type: 'oneLineText',
            value: user => user?.country,
            items: {}
          },
          postcode: {
            name: `${fieldName} postcode`,
            type: 'oneLineText',
            value: user => user?.postcode,
            items: {}
          },
          city: {
            name: `${fieldName} city`,
            type: 'oneLineText',
            value: user => user?.city,
            items: {}
          },
          address: {
            name: `${fieldName} address`,
            type: 'oneLineText',
            value: user => user?.address,
            items: {}
          }
        }
      }
    }
  }

  return field
}

export default genPersonField
