import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Layout } from 'antd'
import { useDispatch } from 'react-redux'

import EmptyHeader from './components/Layouts/EmptyHeader'
import ErrorPage from './components/Error/ErrorPage'

import { withUserData } from './components/Layouts/AuthenticatedPage'
import withTemplateContext from './components/Contract/ContractEditor/TemplateContext'
import withContractContext from './components/Contract/ContractEditor/ContractContext'
import { ContractContext } from './components/Contract/ContractEditor/Contexts'

import TemplatePage from './components/Template/TemplatePage'
import TemplatePreview from './components/Template/TemplatePreview'

import ContractPage from './components/Contract/ContractPage'
import ContractPublishPage from './components/Contract/ContractPublish/ContractPublishPage'
import ContractExternalViewFromInside from './components/Contract/ContractExternalViewFromInside'

import { resetContract } from './components/Contract/redux/ContractActions'
import { resetTemplate } from './components/Template/redux/TemplateActions'
import withTemplateEntitiesContext from './components/Contract/ContractEditor/TemplateEntitiesContext'
import withContractEditorContext from './components/Contract/ContractEditor/editorRefactoredPart/withContractEditorContext'

export interface SidebarStateType {
  openedToolbox: string,
  switchToolbox: (key: string) => void
}

export const defaultSidebarStateValue: SidebarStateType = {
  openedToolbox: 'none',
  switchToolbox: () => null
}
export const SidebarStateContext = React.createContext<React.RefObject<SidebarStateType>>({ current: defaultSidebarStateValue })

export const OpenManagementToolboxContext = React.createContext<React.MutableRefObject<(side: string)=> void>>({ current: () => undefined })

const TemplateRoutes: React.FC<any> = ({ match }) => {
  const dispatch = useDispatch()
  const { contract: { contractID } } = useContext<any>(ContractContext)

  //  Making a special idea for playbook preview
  useEffect(() => {
    dispatch(resetContract({ contractID: '__preview__', contractStatus: 'filling' }))
    return () => dispatch(resetContract())
  }, [dispatch])

  if (contractID !== '__preview__') {
    return null
  }

  return (
    <Switch>
      {/* Templates routes */}
      <Route component={TemplatePage} exact path={`${match.path}`} />
      <Route component={TemplatePreview} exact path={`${match.path}/preview`} />

      {/* Error page */}
      <Route
        component={() => (
          <Layout>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <EmptyHeader />
            <ErrorPage />
          </Layout>
        )}
      />
    </Switch>
  )
}


const ContractRoutes: React.FC<any> = ({ match }) => (
  <Switch>
    {/* Create contract => contractID is the templateID */}
    <Route component={ContractPage} exact path={`${match.path}/new`} />
    <Route component={ContractPage} exact path={`${match.path}`} />
    <Route component={ContractPublishPage} exact path={`${match.path}/publish`} />
    <Route component={ContractExternalViewFromInside} exact path={`${match.path}/external-view/:tokenOrContractID`} />

    {/* Error page */}
    <Route
      component={() => (
        <Layout>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <EmptyHeader />
          <ErrorPage />
        </Layout>
      )}
    />
  </Switch>
)

const ContractTemplateRoutes: React.FC<any> = ({ match }) => {
  const { entity } = match.params
  const dispatch = useDispatch()
  const sidebarStateRef = useRef<SidebarStateType>(defaultSidebarStateValue)
  const openManagementToolboxRef = useRef<(side: string)=> void>(() => undefined)

  //  Redux store cleanup
  useEffect(() => () => {
    dispatch(resetContract())
    dispatch(resetTemplate())
  }, [dispatch])

  /* Template page */
  if (entity === 'templates') {
    return <TemplateRoutes match={match} />
  }

  /* Contract page */
  if (entity === 'contracts') {
    return <ContractRoutes match={match} />
  }

  /* Error page */
  return (
    <OpenManagementToolboxContext.Provider value={openManagementToolboxRef}>
      <SidebarStateContext.Provider value={sidebarStateRef}>
        <Layout>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <EmptyHeader />
          <ErrorPage />
        </Layout>
      </SidebarStateContext.Provider>
    </OpenManagementToolboxContext.Provider>
  )
}

const Wrapped: React.FC<any> = props => {
  const Component = useMemo(
    () => withUserData(withTemplateEntitiesContext(withContractContext(withTemplateContext(withContractEditorContext(ContractTemplateRoutes))))),
    []
  )
  return <Component {...props} />
}

export default Wrapped
