import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { ContractEditorContextProps } from '@top-legal/editor'
import { TemplateContext } from '../../Contexts'
import { getConditionalTextValue, getElem, getValue } from '../../../../Template/_TemplateHelperFunctions'


/** ******************************************************************
 *            Some types
 ****************************************************************** */
type Getters = ReturnType<ContractEditorContextProps['useEntityGetters']>
type ScopedData = 'getFieldValue' | 'getConditionalTextValue'
type SectionRefGetters = Getters['getSectionRef']
type ScopedDataGetters = Pick<Getters, ScopedData>
type GlobalDataGetters = Omit<Getters, ScopedData | 'getSectionRef'>


/** ******************************************************************
 *            Fields response context
 ****************************************************************** */
const FieldsResponseContext = React.createContext<Record<string, any>>({})
export const ProvideFieldsResponseScope: React.FC<{ scope: any }> = ({
  scope,
  children
}) => (
  <FieldsResponseContext.Provider value={scope}>
    {children}
  </FieldsResponseContext.Provider>
)

const emptyObject = {}
const useGettersWithScopedData = (globalGetters: GlobalDataGetters): ScopedDataGetters => {
  const { template } = useContext<any>(TemplateContext)
  const currentRef = useRef(template)
  currentRef.current = template
  const values = useContext(FieldsResponseContext) || emptyObject

  const { getField } = globalGetters
  const fieldsGetter = useMemo(() => new Proxy(template.fields, {
    get: (parent, fieldKey) => parent[fieldKey] || getField(fieldKey as any)
  }), [template.fields, getField])

  return useMemo<ScopedDataGetters>(() => {
    const scopedGetters: ScopedDataGetters = {
      getFieldValue: (fieldKey, formatting = true) => getValue(
        fieldsGetter,
        fieldKey,
        values,
        formatting,
        currentRef.current.lang
      ),
      getConditionalTextValue: condKey => {
        const cond = globalGetters.getConditionalText(condKey)

        if (cond && cond.field) {
          const field = globalGetters.getField(cond.field)

          if (field) {
            const value = (scopedGetters.getFieldValue as any)(cond.field, false)

            if (value != null) {
              const condValue = getConditionalTextValue(field, cond, value, currentRef.current.lang)

              if (condValue) {
                return typeof condValue === 'string' ? [{ text: condValue }] : condValue
              }
            }
          }
        }

        return undefined
      }
    }

    return scopedGetters
  }, [fieldsGetter, globalGetters, values])
}


/** ******************************************************************
 *     useEntityGetters build global and gather scoped
 ****************************************************************** */
const useEntityGetters = (): Getters => {
  const { template, inputFields, conditionalTexts, sectionMapping } = useContext<any>(TemplateContext)

  //  Build some global getters that will be the exact same function for everyone
  const globalGetters: GlobalDataGetters = useMemo(() => ({
    getField: (fieldKey, version) => {
      const field = getElem(fieldKey, template.fields)
      if (field) {
        return field
      }

      const [baseKey, ...rest] = fieldKey.split('.')
      if (inputFields[baseKey]) {
        const tmp = inputFields[baseKey][version as any]
        return (getElem(fieldKey, inputFields) || {})[version || 1] || getElem(fieldKey, template.fields) || (getElem(rest, tmp))
      }
      return (getElem(fieldKey, inputFields) || {})[version || 1] || getElem(fieldKey, template.fields)
    },
    getConditionalText: (condKey, version) => (
      template.conditionalTexts[condKey]
      || (conditionalTexts[condKey] || {})[version || 1]
    )
  }), [conditionalTexts, inputFields, template.conditionalTexts, template.fields])

  //  Build section getter
  const getSectionRef: SectionRefGetters = useCallback(sectionKey => sectionMapping[sectionKey], [sectionMapping])

  //  Build scope specific getters
  const scopedGetters = useGettersWithScopedData(globalGetters)

  return useMemo(() => ({ ...globalGetters, ...scopedGetters, getSectionRef }), [globalGetters, scopedGetters, getSectionRef])
}

export default useEntityGetters
