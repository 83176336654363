import React, { useCallback, useContext } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { Avatar } from 'antd'

import InstanceUsersContext from '../Context/InstanceUsersContext'
import Portal from '../../../../../../SharedComponents/Portal'
import { useAvatarStyle, UserAvatarByID } from '../../../../../../Organisations/UserRoleDisplay/UserAvatarList'


const size = '2rem'
const RenderTemplateAvatars: React.FC = () => {
  const { internRoles } = useContext(InstanceUsersContext)
  const userStyle = useAvatarStyle(size)

  /** **********************************************************************
   *           Create the button actions
   ********************************************************************** */
  const [sidebar, setSidebar] = useQueryParam('sidebar', StringParam)
  const switchToolBox = useCallback(() => {
    if (sidebar !== 'users') {
      setSidebar('users')
    }
  }, [sidebar, setSidebar])


  /** **********************************************************************
   *       Rendering a portal to put avatars on the template bar
   ********************************************************************** */
  if (internRoles.length > 0) {
    return (
      <Portal cssSelector='#topbarExtraContent'>
        <div className='contractMembersWrapper'>
          {internRoles.slice(0, internRoles.length === 3 ? 3 : 2).map(({ userID }) => (
            <UserAvatarByID
              key={userID}
              className='intern userAvatar'
              onClick={switchToolBox}
              size={size}
              userID={userID}
            />
          ))}
          {internRoles.length > 3 && (
            <Avatar className='extraMembers' style={userStyle}>
              {`+${internRoles.length - 2}`}
            </Avatar>
          )}
        </div>
      </Portal>
    )
  }

  return null
}

export default RenderTemplateAvatars
