import React, { useCallback, useContext, useRef } from 'react'
import uniqid from 'uniqid'

import { saveInStore, Section, useSectionsCollection } from '@top-legal/datastore'

import { TemplateContext } from '../../Contexts'

export interface SectionProps {
  section: Section
  updateSection?: (update: Partial<Section>) => void
}

export interface ContractEditorSectionProps {
  sectionID: string
  path: any[]
  title?: string
  titlePrefix?: string
  addAbove?: () => void
  addAboveTitle?: React.ReactNode
  addBellow?: () => void
  addBellowTitle?: React.ReactNode
  addInside?: () => void
  addInsideTitle?: React.ReactNode
  deleteSection?: () => void
}

export const useNewSection = () => {
  const { template } = useContext<any>(TemplateContext)
  const templateIDRef = useRef()
  templateIDRef.current = template?.templateID

  const sectionsCollection = useSectionsCollection()

  return useCallback(async (sID: string = uniqid(), templateID = templateIDRef.current) => {
    const sectionID = `${templateID}-${sID}`
    await saveInStore(sectionsCollection, { sectionID, templateID } as any)
    await new Promise(resolve => setTimeout(resolve, 100))
    return sectionID
  }, [sectionsCollection])
}
