import React from 'react'
import { getPreventDefaultHandler, someNode } from '@udecode/plate-core'
import { ELEMENT_OL, ELEMENT_UL, toggleList } from '@udecode/plate-list'
import { ToolbarButton, ToolbarButtonProps } from '@udecode/plate-ui-toolbar'
import { OrderedListOutlined, UnorderedListOutlined } from '@ant-design/icons'

import { useFocusedEditor } from '../../Helpers'

import Group from './GroupFormat'

const ToolbarList: React.FC<ToolbarButtonProps> = ({ type, ...props }) => {
  const currentEditor = useFocusedEditor()

  return (
    <ToolbarButton
      {...props}
      active={currentEditor && !!type && someNode(currentEditor, { match: { type } })}
      onMouseDown={currentEditor ? getPreventDefaultHandler(toggleList, currentEditor as any, {
        ...props,
        type
      }) : undefined}
      type={type}
    />
  )
}

export default (
  <Group key='slateListFormat'>
    <ToolbarList
      icon={<UnorderedListOutlined />}
      type={ELEMENT_UL}
    />
    <ToolbarList
      icon={<OrderedListOutlined />}
      type={ELEMENT_OL}
    />
  </Group>
)
