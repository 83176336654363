import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { t } from '@lingui/macro'
import { noop } from '../Defaults'

// styles
import './styles/LoadingAlert.scss'

const disableBubbling = evt => evt.stopPropagation()

const LoadingAlert = ({
  description, lightTheme = false, loading = true,
  // Params below are used by LocalLoadingAlert and GlobalLoadingAlert you don't have to set it
  local = false, onFinish = noop
}) => {
  const backgroundClass = lightTheme ? 'lightBackground' : 'darkBackground'
  const spinnerClass = lightTheme ? 'darkSpinner' : 'lightSpinner'

  /**
   * Store data in a ref to avoid re rendering
   */
  const state = useRef({
    loadingDiv: `loadingAlert ${backgroundClass} ${local ? 'localMode' : 'globalMode'}`,
    spinner: `spinner ${spinnerClass} loading loadingAnim`,
    description
  })
  //  Update loading state
  state.current.loading = loading
  state.current.onFinish = onFinish
  state.current.timeout = useMemo(() => (loading ? undefined : setTimeout(() => state.current.onFinish(), 1500)), [loading])

  /**
   * Handler when the animation is finish
   */
  const parent = useRef()
  const handleAnimationEnd = useCallback(evt => {
    const spinner = evt.target
    //  Remove loading anim
    spinner.classList.remove('loadingAnim')
    spinner.classList.remove('tReconstructAnim')

    if (state.current.loading) {
      //  Restart animation
      setTimeout(() => spinner.classList.add('loadingAnim'))
    } else {
      if (state.current.timeout) {
        clearTimeout(state.current.timeout)
        state.current.timeout = undefined
      }

      if (!state.current.tReconstructAnim) {
        spinner.classList.remove('loading')
        spinner.classList.add('tReconstruct')
        state.current.tReconstructAnim = true
        parent.current.querySelector('h1').innerHTML = t`Data loaded`
        //  Start T reconstruct animation
        setTimeout(() => spinner.classList.add('tReconstructAnim'))
      } else {
        //  Call parent end
        spinner.classList.add('tReconstructed')
        setTimeout(() => {
          parent.current && parent.current.classList.add('finished')
          setTimeout(() => state.current.onFinish(), 500) // For destroy
        }, 500)
      }
    }
  }, [])

  /**
   * Use a memo with no deps to avoid the animation to be broken
   */
  return useMemo(() => (
    <div ref={parent} className={state.current.loadingDiv}>
      <div className='loadingAlertContent'>
        <h1>{t`Loading data`}</h1>
        <p>{state.current.description}</p>
        <div className='spinnerWrapper' onAnimationEnd={handleAnimationEnd}>
          <div className={state.current.spinner}>
            <span onAnimationEnd={disableBubbling} />
            <span onAnimationEnd={disableBubbling} />
            <span onAnimationEnd={disableBubbling} />
            <span onAnimationEnd={disableBubbling} />
          </div>
        </div>
      </div>
    </div>
  ), [handleAnimationEnd])
}

export const LocalLoadingAlert = ({ loading, children, forceRender = false, ...props }) => {
  const [visible, setVisible] = useState()

  //  Display the loading alert each time loading become true
  useEffect(() => {
    if (loading) {
      setVisible(true)
    }
  }, [loading])

  //  Return loading or null
  return (
    <>
      {visible && <LoadingAlert {...props} loading={loading} local onFinish={() => setVisible(false)} />}
      {(forceRender || !loading) && children}
    </>
  )
}

const newContainer = () => {
  const div = document.createElement('div')
  document.body.appendChild(div)
  return div
}

export const GlobalLoadingAlert = ({ loading, children, forceRender = false, ...props }) => {
  const [visible, setVisible] = useState()
  const container = useRef()

  //  Display the loading alert each time loading become true
  useEffect(() => {
    if (loading) {
      container.current = newContainer()
      setVisible(true)
    }
  }, [loading])

  //  Return loading or null
  return (
    <>
      {visible && ReactDOM.createPortal(
        <LoadingAlert
          {...props}
          loading={loading}
          onFinish={() => {
            setVisible(false)
            setTimeout(() => container.current.remove(), 500)
          }}
        />,
        container.current
      )}
      {(forceRender || !loading) && children}
    </>
  )
}
