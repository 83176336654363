import React from 'react'
import { BoldOutlined, ItalicOutlined, UnderlineOutlined } from '@ant-design/icons'
import { getPreventDefaultHandler, isMarkActive, toggleMark } from '@udecode/plate-core'
import { MarkToolbarButtonProps, ToolbarButton } from '@udecode/plate-ui-toolbar'
import { MARK_BOLD, MARK_ITALIC, MARK_UNDERLINE } from '@udecode/plate-basic-marks'

import { useFocusedEditor } from '../../Helpers'

import Group from './GroupFormat'

const ToolbarMark: React.FC<MarkToolbarButtonProps> = ({ type, clear, ...props }) => {
  const currentEditor = useFocusedEditor()

  return (
    <ToolbarButton
      {...props}
      active={currentEditor && isMarkActive(currentEditor, type)}
      onMouseDown={currentEditor ? getPreventDefaultHandler(toggleMark, currentEditor, { key: type, clear }) : undefined}
    />
  )
}

export default (
  <Group key='slateTextStyleFormat'>
    <ToolbarMark icon={<BoldOutlined />} type={MARK_BOLD} />
    <ToolbarMark icon={<ItalicOutlined />} type={MARK_ITALIC} />
    <ToolbarMark icon={<UnderlineOutlined />} type={MARK_UNDERLINE} />
  </Group>
)
