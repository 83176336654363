import React, { useContext, useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { HourglassOutlined } from '@ant-design/icons'

import { ContractContext } from './ContractEditor/Contexts'

interface UseContractProgressHelperReturn {
  progressPercent?: number
  progressText?: React.ReactNode
}

const useContractProgressHelper = (): UseContractProgressHelperReturn => {
  const { contract: { sentOverDate, draftedDate, signaturesHolder, fileUrl }, fillingStats } = useContext<any>(ContractContext)
  const { approvals, neededApprovals = 0, signatures, signatureHashes } = signaturesHolder || {}


  return useMemo<UseContractProgressHelperReturn>(() => {
    //  Imported document (no status)
    if (fileUrl) {
      return {}
    }

    //  Approval status
    if (Object(approvals) === approvals) {
      const nbApprovals = Object.keys(approvals).length

      if (nbApprovals < neededApprovals) {
        const percent = Math.floor((nbApprovals * 100) / neededApprovals)
        return {
          progressPercent: percent,
          progressText: <span><HourglassOutlined /> <Trans>Waiting for approval ({nbApprovals} out of {neededApprovals})</Trans></span>
        }
      }
    }

    //  Signing status
    if (Array.isArray(signatures)) {
      const signed = signatures.reduce((acc, { signedDate }) => acc + (signedDate ? 1 : 0), 0)
      const nbSignatures = signatures.length

      if (signed === nbSignatures) {
        return {
          progressPercent: 100,
          progressText: <Trans>Fully signed</Trans>
        }
      }
      return {
        progressPercent: (signed * 100) / nbSignatures,
        progressText: <Trans>{signed} out {nbSignatures} signed</Trans>
      }
    }

    if (draftedDate) {
      //  Negotiating
      if (sentOverDate) {
        const progress = 75
        return {
          progressPercent: progress,
          progressText: <Trans>{progress}% to be signed</Trans>
        }
      }

      //  Internal editing
      const progress = 50
      return {
        progressPercent: progress,
        progressText: <Trans>{progress}% to be signed</Trans>
      }
    }

    //  Drafting
    if (fillingStats) {
      let progress = 0
      if (fillingStats.done === fillingStats.total) {
        progress = 100
      } else if (fillingStats.total > 0) {
        progress = Math.round((fillingStats.done * 100) / fillingStats.total)
      }
      return {
        progressPercent: progress,
        progressText: <Trans>{progress}% drafted</Trans>
      }
    }

    //  If we have no info return nothing
    return {}
  }, [approvals, fileUrl, fillingStats, neededApprovals, sentOverDate, signatureHashes, signatures, draftedDate])
}

export default useContractProgressHelper
