import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  ContactsOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  LoadingOutlined,
  MenuOutlined,
  PlusOutlined,
  SearchOutlined
} from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import {
  Button, Drawer, Dropdown, Form, Menu, Tabs, Tag
} from 'antd'

import { useContractingPartiesCollection, useList } from '@top-legal/datastore'

import { ConfirmMenuItem, Select } from '../SharedComponents/FormComponents'


// custom components
import RestService from '../../RestService'
import { withUserData } from '../Layouts/AuthenticatedPage'
import MainContainer from '../Layouts/MainLayout/MainContainer'
import { createColumn, DataTable } from '../SharedComponents/DataTable'
import { SearchModalContext } from '../Search/Types'
import { UserAvatarByID, UserFullName } from '../Organisations/UserRoleDisplay/UserAvatarList'
import CreateCompanyForm from '../Contract/ContractEditor/InviteContactToContract/CreateCompanyForm'
import CreateContactForm from '../Contract/ContractEditor/InviteContactToContract/CreateContactForm'
import { UserAndCompanyDataContext } from '../Layouts/Constants'
import { requiredRules } from '../SharedComponents/CustomFormWrapper'
import { emptyArray } from '../Listing/SearchHelpers'

// styles
import '../Contract/styles/ContractListing.scss'
import './ContactList.scss'
import { DeviceContext } from '../../GlobalContext'
import { store } from '../../ReduxStore'


// ==================================================================================================

const createColumn_ = createColumn as any
const DataTable_ = DataTable as any

// ==================================================================================================

const NameMappingContext = React.createContext({})

// ==================================================================================================

const ContactActions = ({ record }) => {
  const [buttonLoading, setButtonLoading] = useState(false)
  const [showCreateContact, setShowCreateContact] = useState(false)
  const [showCreateCompany, setShowCreateCompany] = useState(false)

  return (
    <>
      {!buttonLoading ? (
        <Dropdown
          overlay={(
            <Menu key='menu'>
              <Menu.Item
                onClick={() => {
                  if (record.type === 'person') {
                    setShowCreateContact(true)
                  } else {
                    setShowCreateCompany(true)
                  }
                }}
              >
                <EditOutlined />
                <span><Trans>Edit</Trans></span>
              </Menu.Item>
              <ConfirmMenuItem
                confirmMessage={<Trans>Are you sure you want to delete this contact?</Trans>}
                danger
                onClick={async () => {
                  setButtonLoading(true)
                  try {
                    await RestService('DELETE', `/contact/${record.partyID}`)
                  } catch (err) {
                    console.error('Failed to delete contact', record, err)
                  }
                  setButtonLoading(false)
                } }
              >
                <DeleteOutlined />
                <span><Trans>Delete</Trans></span>
              </ConfirmMenuItem>
            </Menu>
          )}
          trigger={['click']}
        >
          <Button className='noBorder centredDropdown' ghost icon={<EllipsisOutlined />} />
        </Dropdown>
      ) : (
        <LoadingOutlined spin />
      )}
      <CreateCompanyForm
        initialValue={record}
        onClose={() => setShowCreateCompany(false)}
        onFinish={() => setShowCreateCompany(false)}
        visible={showCreateCompany}
      />
      <CreateContactForm
        initialValue={record}
        onClose={() => setShowCreateContact(false)}
        onFinish={() => setShowCreateContact(false)}
        visible={showCreateContact}
      />
    </>
  )
}


// ==================================================================================================


const COMMON_COLUMN = [
  createColumn_({
    columnKey: 'owner',
    columnName: <Trans>Owner</Trans>,
    render: (_, record) => (
      <div className='contract-users'>
        {record.createdBy && (
          <UserAvatarByID autoSize size='1.5rem' userID={record.createdBy} />
        )}
        {record.owningTeam && (
          <Tag color='blue'>
            <UserFullName userID={record.owningTeam} />
          </Tag>
        )}
      </div>
    )
  }),
  createColumn_({
    columnKey: 'updatedAt',
    columnName: <Trans>Last update</Trans>,
    withSort: true,
    withDate: true
  }),
  createColumn_({
    columnKey: 'tags',
    columnName: <Trans>Labels</Trans>,
    getData: record => record.tags || [],
    withLabels: 'party'
  }),
  createColumn_({
    columnKey: 'action',
    columnName: <Trans>Actions</Trans>,
    fixed: 'right',
    render: (_, record) => <ContactActions record={record} />
  })
]


// ==================================================================================================


export const CONTACT_LISTING_COLUMNS = [
  createColumn_({
    columnKey: 'contactName',
    className: 'itemName',
    columnName: <Trans>Person name</Trans>,
    withSort: true,
    fixed: true,
    getData: item => (item.userID ? <UserFullName userID={item.userID} /> : `${item.firstName} ${item.lastName}`)
  }),
  createColumn_({
    columnKey: 'email',
    columnName: <Trans>Email</Trans>,
    withSort: true
  }),
  createColumn_({
    columnKey: 'companyName',
    columnName: <Trans>Organisation name</Trans>,
    withSort: true,
    // eslint-disable-next-line consistent-return
    render: (_, record) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const nameMapping = useContext(NameMappingContext)
      if (record.companyPartyID) {
        return <span>{nameMapping[record.companyPartyID]}</span>
      }
      return <span>-</span>
    }
  }),
  ...COMMON_COLUMN
]


// ==================================================================================================


export const COMPANY_LISTING_COLUMNS = [
  createColumn_({
    columnKey: 'name',
    className: 'itemName',
    columnName: <Trans>Company name</Trans>,
    withSort: true,
    fixed: 'left'
  }),
  ...COMMON_COLUMN
]


// ==================================================================================================


export const completeContactData = promise => promise.then(async ({ Items, lastElement }) => {
  const { members = {} } = store.getState().organisation.selectedOrganisation || {}

  await Items.asyncForEach(async contact => {
    contact.tags = await RestService('GET', `/labels/${contact.partyID}?instanceType=party&organisationID=${contact.organisationID}`).then(
      array => array.map(({ tag }) => tag)
    )
    contact.role = 'Contributor'

    if (contact.userID) {
      const user = members[contact.userID] || await RestService('GET', `/user/profile/${contact.userID}`)
      contact.firstName = user.firstName
      contact.lastName = user.lastName
      contact.email = user.email

      if (user.address && user.city && user.postcode && user.country) {
        contact.address = {
          address: user.address,
          city: user.city,
          postcode: user.postcode,
          country: user.country
        }
      }
    }
  })

  return { Items, lastElement }
})


// ==================================================================================================


export const addressToString = ({ country, postcode, city, address }) => {
  if (country && postcode && city && address) {
    return `${address}, ${postcode} ${city}, ${country}`
  }
  return ''
}


export const OwningTeamFormItem: React.FC<{ initialValue: any }> = ({ initialValue }) => {
  const { user: { userID }, company: { members, teams, organisationID } } = useContext(UserAndCompanyDataContext)
  const allTeams = useMemo(() => ({
    ...teams,
    __Admin: { userID: '__Admin', name: <Trans>Managers</Trans> },
    [organisationID]: { userID: organisationID, name: <Trans>Other company members</Trans> }
  }), [organisationID, teams])

  if (
    initialValue && initialValue.partyID
    && members[userID] && members[userID].organisationRole
    && members[userID].organisationRole.team === '__Admin'
  ) {
    return (
      <>
        <h5 className='styleTitle'><Trans>Owned by team:</Trans></h5>
        <Form.Item name='owningTeam' rules={requiredRules}>
          <Select
            className='simple-line'
            items={allTeams}
            label='name'
            noSearch
            style={{ width: '100%' }}
          />
        </Form.Item>
      </>
    )
  }

  return null
}


// ==================================================================================================


export const AddressDisplay = ({ address: { country, postcode, city, address }, Highlight = ({ text }) => text }) => {
  if (country && postcode && city && address) {
    return (
      <p className='addressDisplay'>
        <Highlight text={address} /><br />
        <Highlight text={postcode} /> <Highlight text={city} /><br />
        <Highlight text={country} />
      </p>
    )
  }
  return null
}


// ==================================================================================================


const DisplayTable = ({ items, contactColumns }) => (
  <DataTable_
    data={items}
    defaultView='list'
    noViewSwitcher
    pagination={false}
    rowKey={useCallback(item => item.partyID, [])}
    scroll={{ x: true }}
  >
    {contactColumns}
  </DataTable_>
)


// ==================================================================================================


const ContactListing: React.FC = () => {
  const partiesCollection = useContractingPartiesCollection()
  const [partiesStore = emptyArray] = useList(useMemo(() => partiesCollection.find().sort({ updatedAt: 'desc' }), [partiesCollection]))
  const onProfileUser = useSelector(state => state.user.ownProfileData)

  const instancesNameMapping = useMemo(() => {
    const mapping = {}
    if (partiesStore) {
      partiesStore.forEach(contact => {
        if (contact.type === 'company') {
          mapping[contact.partyID] = contact.name
        }
      })
    }
    return mapping
  }, [partiesStore])

  // ==================================================================================================

  return (
    <NameMappingContext.Provider value={instancesNameMapping}>
      <Tabs className='autoHeight' destroyInactiveTabPane>
        <Tabs.TabPane key='1' tab={<Trans>All contacts</Trans>}>
          <DisplayTable
            contactColumns={CONTACT_LISTING_COLUMNS}
            items={useMemo(
              () => partiesStore.filter(contact => contact.type === 'person'),
              [partiesStore]
            )}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key='2' tab={<Trans>My contacts</Trans>}>
          <DisplayTable
            contactColumns={CONTACT_LISTING_COLUMNS}
            items={useMemo(
              () => partiesStore.filter(contact => (contact.createdBy === onProfileUser.userID) && (contact.type === 'person')),
              [partiesStore, onProfileUser.userID]
            )}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key='3' tab={<Trans>All companies</Trans>}>
          <DisplayTable
            contactColumns={COMPANY_LISTING_COLUMNS}
            items={useMemo(
              () => partiesStore.filter(contact => contact.type === 'company'),
              [partiesStore]
            )}
          />
        </Tabs.TabPane>
      </Tabs>
    </NameMappingContext.Provider>
  )
}


// ==================================================================================================


const ContactForm: React.FC = () => {
  const [showCreateContact, setShowCreateContact] = useState(false)
  const [showCreateCompany, setShowCreateCompany] = useState(false)
  const isPhone = useContext(DeviceContext) === 'phone'
  return (
    <>
      {!isPhone && (
        <div className='topbarMainContent'>
          <ContactsOutlined className='headerIcon' twoToneColor='#3DBD7D' />
          <h1 className='title'><Trans>Contracting parties</Trans></h1>
        </div>
      )}
      {isPhone && (<p><Trans>Create new:</Trans></p>)}
      <div className={isPhone ? 'sidebarMainActions' : 'topbarActions'}>
        <Button
          href='javascript:void(0)' icon={<PlusOutlined />}
          onClick={() => setShowCreateCompany(true)}
          type='primary'
        >
          <Trans>New company</Trans>
        </Button>
        <Button
          href='javascript:void(0)' icon={<PlusOutlined />}
          onClick={() => setShowCreateContact(true)}
          type='primary'
        >
          <Trans>New contact</Trans>
        </Button>
      </div>
      <CreateCompanyForm
        onClose={() => setShowCreateCompany(false)}
        onFinish={() => setShowCreateCompany(false)}
        visible={showCreateCompany}
      />
      <CreateContactForm
        onClose={() => setShowCreateContact(false)}
        onFinish={() => setShowCreateContact(false)}
        visible={showCreateContact}
      />
    </>
  )
}


// ==================================================================================================


const ContactPage : React.FC = () => {
  const isPhone = useContext(DeviceContext) === 'phone'
  const { setSearchOpen } = useContext(SearchModalContext)
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <MainContainer
      mainContentClass='contactListing'
      topbarContent={!isPhone ? (
        <ContactForm />
      ) : (
        <div className='searchPhone'>
          <Button
            className='noBorder'
            ghost
            icon={<SearchOutlined />}
            onClick={() => setSearchOpen(true)}
          />
          <Button
            className='noBorder phoneSidebarDrawerButton'
            ghost
            icon={<MenuOutlined />}
            onClick={() => setSidebarOpen(true)}
            type='default'
          />
        </div>
      )}
    >
      <ContactListing />
      <Drawer
        className='phoneSidebarDrawer'
        onClose={() => setSidebarOpen(false)}
        placement='right'
        title={<h1 className='title'><Trans>Contacts</Trans></h1>}
        visible={sidebarOpen}
        width='60vw'
        zIndex={1000}
      >
        <ContactForm />
      </Drawer>
    </MainContainer>
  )
}

const Wrapped: React.FC = props => {
  const Component = useMemo(() => withUserData(ContactPage), [])
  return <Component {...props} />
}

export default Wrapped
