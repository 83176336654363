import React, { useState } from 'react'
import { ArrowLeftOutlined, UserOutlined } from '@ant-design/icons'
import { Alert, Button, Col, Form, Input, Row } from 'antd'
import { t, Trans } from '@lingui/macro'
import Auth from '@aws-amplify/auth'
import { ConsoleLogger as Logger } from '@aws-amplify/core'
import AmplifyError from '../Error/AmplifyError'
import CustomFormWrapper from '../SharedComponents/CustomFormWrapper'

// definition
const logger = new Logger('RestPassword')

// ==================================================================================================
/**
 * function returns the password reset view
 * @param {object} props
 */
const ResetPassword = ({ back }) => {
  const [resetStep, setResetStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [errorReset, setErrorReset] = useState(null)

  // ==================================================================================================

  const handlePasswordReset = async values => {
    // setting everything to loading
    setIsLoading(true)


    try {
      // start the password reset process for cognito
      // let data = await forgotPassword(values.userNameRecovery)
      await Auth.forgotPassword(values.emailReset)

      // turn off loading and go to the next step
      setIsLoading(false)
      setResetStep(1)

      /// ////////////////////////////////////////////////////
      //                Segment Tracking
      /// ////////////////////////////////////////////////////
      if (window.analytics) {
        window.analytics.track('userPasswordReset', {
          userEmail: values.emailReset
        })
      }
    } catch (error) {
      logger.error('Reset password error', error)

      // switch off loading and display error message
      setIsLoading(false)
      setErrorReset(error.code)

      // rethrow error to parent function
      throw error
    }
  }

  // ==================================================================================================
  return (
    <Row className='resetPasswordPage' justify='center' type='flex'>
      <Col span={24}>
        <div className='headerRow'>
          <h1 className='headerTitle'><Trans id='Reset password' /></h1>
        </div>
        {
          resetStep === 1
            ? (
              <Alert
                className='passwordSuccessMessage'
                description={<Trans id='email.verification.sent' />}
                message={<Trans id='email.verification' />}
                showIcon
                style={{ marginBottom: '3rem' }}
                type='success'
              />
            )
            : (
              <>
                <AmplifyError errorCode={errorReset} />
                <CustomFormWrapper onSubmit={handlePasswordReset}>
                  <Form.Item
                    name='emailReset' rules={[{
                      required: true,
                      type: 'email',
                      message: t`Please provide your Email`
                    }]}
                  >
                    <Input
                      placeholder={t`Email`}
                      prefix={<UserOutlined style={{ fontSize: 13 }} />}
                    />
                  </Form.Item>
                  <Row className='passwordResetButtonGroup' justify='end' type='flex'>
                    <Col span={24}>
                      <Button
                        className='buttonLink'
                        ghost
                        icon={<ArrowLeftOutlined />}
                        loading={isLoading}
                        onClick={back}
                        type='primary'
                      >
                        <Trans id='Go back' />
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Button
                        block
                        className='resetSubmitButton'
                        htmlType='submit'
                        loading={isLoading}
                        type='primary'
                      >
                        <Trans id='Reset password' />
                      </Button>
                    </Col>
                  </Row>
                </CustomFormWrapper>
              </>
            )
        }
      </Col>
    </Row>
  )
}

export default ResetPassword
