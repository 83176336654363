import React from 'react'
import { Trans } from '@lingui/macro'
import {
  AuditOutlined,
  BellOutlined,
  CheckOutlined,
  EditOutlined,
  EyeOutlined,
  IdcardOutlined,
  IssuesCloseOutlined,
  MailOutlined,
  MessageOutlined,
  SwapOutlined,
  UndoOutlined
} from '@ant-design/icons'
import { Tooltip } from 'antd'
import moment from 'moment'

import { Contract, Event } from '@top-legal/datastore'

import { UserAvatar, UserAvatarByID, UserFullName } from '../../Organisations/UserRoleDisplay/UserAvatarList'
import { emptyArray, emptyObject } from '../SearchHelpers'

import './ContractActivities.scss'

const identity = elm => elm
const eventSizeNumber = 2
const eventSize = `${eventSizeNumber}rem`
const avatarSize = `${eventSizeNumber * 0.9}rem` // The 0.1em is the outline color set in css, em = 0.5 size in rem

const renderAvatars = (arr: any[], className: 'intern' | 'extern') => arr.map((userID, index) => {
  const props = {
    userID, size: avatarSize, noTooltip: true, className, style: { marginTop: '0.1em' }
  }

  if (typeof userID === 'string' && userID) {
    return <div className='avatarWrapper'><UserAvatarByID key={userID} {...props} autoSide /></div>
  }
  if (Object(userID) === userID) {
    return <div className='avatarWrapper'><UserAvatar key={index} {...props} autoSide user={userID} /></div>
  }

  return null
})

const displayDate = (dateStr: any): string => {
  const date = moment(dateStr)
  return Number.isNaN(date.valueOf()) ? '' : date.fromNow()
}

const iconStyle = { height: eventSize, width: eventSize, fontSize: '0.9rem' }
const RenderEvent: React.FC<{ leftUsers?: any[], icon: React.ReactNode, rightUsers?: any[], date: string }> = ({
  children,
  leftUsers = emptyArray,
  icon,
  rightUsers = emptyArray,
  date
}) => (
  <Tooltip title={children}>
    <div
      className='event'
      style={{
        borderRadius: eventSize,
        transform: rightUsers.length === leftUsers.length ? undefined : [
          //  Shift to right the size of right part
          `translateX(calc(${rightUsers.length * 0.5} * ${eventSize}))`,
          `translateX(calc(${Math.max(0, rightUsers.length - 1) * -0.2} * ${eventSize}))`,
          //  Shift to left the size of left part
          `translateX(calc(${leftUsers.length * -0.5} * ${eventSize}))`,
          `translateX(calc(${Math.max(0, leftUsers.length - 1) * 0.2} * ${eventSize}))`
        ].join('')
      }}
    >
      <span className={`date ${rightUsers.length < leftUsers.length ? 'right' : 'left'}`}>{displayDate(date)}</span>
      <div className='leftUsers'>{renderAvatars(leftUsers, 'intern')}</div>
      <div className='iconWrapper' style={iconStyle}>{icon}</div>
      <div className='rightUsers'>{renderAvatars(rightUsers, 'extern')}</div>
    </div>
  </Tooltip>
)

const phaseMapping = {
  drafting: <Trans>Drafting</Trans>,
  negotiate: <Trans>Negotiating</Trans>,
  approval: <Trans>Approval</Trans>,
  signing: <Trans>Signing</Trans>,
  signed: <Trans>Signed</Trans>,
  imported: <Trans>Uploaded</Trans>
}

const varStyle = { '--eventSize': eventSize } as any
const RenderBox: React.FC<{ phase: string }> = ({ phase, children }) => (
  <div className={`contractPhaseBox ${phase}`} style={varStyle}>
    <h3>{phaseMapping[phase]}</h3>
    {children && (
      <div className='events'>
        {children}
      </div>
    )}
  </div>
)

const buildActivitiesCard = (events: Event[]): React.ReactNode[] => {
  if (!Array.isArray(events) || events.length === 0) {
    return emptyArray
  }

  const boxes: React.ReactNode[] = []
  let phase
  let items

  let idx = 0
  let externBeenInvited = false

  let userSigned: any[][] = [[], []]

  //  Find drafting phase
  items = []
  for (; idx < events.length && !phase; idx += 1) {
    const { eventID, eventType, userID, date } = events[idx] || emptyObject
    if (eventType === 'create') {
      phase = 'drafting'
      items.unshift(
        <RenderEvent key={eventID} date={date} icon={<EditOutlined />} leftUsers={[userID]}>
          <Trans>Has started the contract</Trans>
        </RenderEvent>
      )
    }
  }

  //  Find a change of phase
  const savePhase = (nextPhase: string, item?: React.ReactNode) => {
    //  Phase changed
    if (nextPhase !== phase) {
      if (items.length > 0) {
        const children = items
        boxes.unshift(<RenderBox key={boxes.length} phase={phase}>{children}</RenderBox>)
      }

      items = []
      phase = nextPhase

      if (item) { items.unshift(item) }
    }
  }


  while (idx < events.length) {
    let nextPhase = phase
    for (; idx < events.length && nextPhase === phase; idx += 1) {
      const { eventID, eventType, userID, thirdParty, date } = events[idx] || emptyObject


      //  Event that change the phase
      if (eventType === 'contractFrozen') {
        if (phase !== 'signing' || items.length === 0) { // requestSigning can already move to signing
          savePhase(
            'signing', (
              <RenderEvent key={eventID} date={date} icon={<CheckOutlined />} leftUsers={[userID]}>
                <Trans><UserFullName userID={userID} /> started the signing process</Trans>
              </RenderEvent>
            )
          )
        }
      } else if (eventType === 'requestSigning') {
        savePhase(
          'signing', (
            <RenderEvent key={eventID} date={date} icon={<CheckOutlined />} rightUsers={[userID]}>
              <Trans><UserFullName userID={userID} /> has requested signing</Trans>
            </RenderEvent>
          )
        )
      } else if (eventType === 'signatureInvite') {
        if (phase !== 'signing') {
          savePhase('signing')
        }
      } else if (eventType === 'contractUnfrozen') {
        savePhase(
          externBeenInvited ? 'negotiate' : 'drafting', (
            <RenderEvent key={eventID} date={date} icon={<UndoOutlined />} leftUsers={[userID]}>
              <Trans><UserFullName userID={userID} /> has reverted to editing</Trans>
            </RenderEvent>
          )
        )
        userSigned = [[], []]
      } else if (eventType === 'approve') {
        if (phase === 'signing') { // If we considered frozen as signing at the end this is approval
          phase = 'approval'
        }
        nextPhase = 'approval'
        items.unshift(
          <RenderEvent key={eventID} date={date} icon={<IssuesCloseOutlined />} leftUsers={[userID]}>
            <Trans><UserFullName userID={userID} /> has approved the contract</Trans>
          </RenderEvent>
        )
      } else if (eventType === 'signed') {
        savePhase(
          'signed', (
            <RenderEvent
              key={eventID} date={date} icon={<AuditOutlined />}
              leftUsers={userSigned[0]}
              rightUsers={userSigned[1]}
            >
              <Trans>Contract fully signed</Trans>
            </RenderEvent>
          )
        )
      } // eslint-disable-line brace-style


      //  Event that do not change the phase
      else if (eventType === 'contractInvite') { // Exception this one may change the phase
        externBeenInvited = true

        const item = (
          <RenderEvent
            key={eventID} date={date} icon={<MailOutlined />}
            leftUsers={[userID]}
            rightUsers={[thirdParty]}
          >
            <Trans><UserFullName userID={userID} /> has invited <UserFullName userID={thirdParty} /> to the contract</Trans>
          </RenderEvent>
        )

        if (phase === 'drafting') {
          savePhase('negotiate', item)
        } else {
          items.unshift(item)
        }
      } else if (eventType === 'externalInvited') {
        items.unshift(
          <RenderEvent key={eventID} date={date} icon={<MailOutlined />} rightUsers={[userID, thirdParty]}>
            <Trans><UserFullName userID={userID} /> has invited <UserFullName userID={thirdParty} /> to the contract</Trans>
          </RenderEvent>
        )
      } else if (eventType === 'externalViewed') {
        items.unshift(
          <RenderEvent key={eventID} date={date} icon={<EyeOutlined />} rightUsers={[userID]}>
            <Trans><UserFullName userID={userID} /> has viewed the contract</Trans>
          </RenderEvent>
        )
      } else if (eventType === 'update') {
        items.unshift(
          <RenderEvent key={eventID} date={date} icon={<EditOutlined />} leftUsers={[userID]}>
            <Trans><UserFullName userID={userID} /> has edited the contract</Trans>
          </RenderEvent>
        )
      } else if (eventType === 'sign') {
        if (userID) { userSigned[0].push(userID) }
        if (events[idx].user) { userSigned[1].push(events[idx].user) }

        if (phase !== 'signing') {
          savePhase('signing')
        }

        items.unshift(
          <RenderEvent
            key={eventID} date={date} icon={<IdcardOutlined />}
            leftUsers={[userID].filter(identity)}
            rightUsers={[events[idx].user].filter(identity)}
          >
            <Trans><UserFullName userID={userID || events[idx].user} /> has signed the contract</Trans>
          </RenderEvent>
        )
      } else if (eventType === 'task') {
        if (phase === 'signed') {
          items.unshift(
            <RenderEvent key={eventID} date={date} icon={<BellOutlined />} leftUsers={[userID]}>
              <Trans><UserFullName userID={userID} /> has added a reminder to the contract</Trans>
            </RenderEvent>
          )
        }
      } else if (eventType === 'comment') {
        items.unshift(
          <RenderEvent key={eventID} date={date} icon={<MessageOutlined />} leftUsers={[userID]}>
            <Trans><UserFullName userID={userID} /> has comment on the contract</Trans>
          </RenderEvent>
        )
      } else if (eventType === 'suggestion') {
        if (typeof userID === 'string' && userID && (userID.startsWith('contact-') || userID.startsWith('party-'))) {
          items.unshift(
            <RenderEvent key={eventID} date={date} icon={<SwapOutlined />} rightUsers={[userID]}>
              <Trans><UserFullName userID={userID} /> has suggested a change on the contract</Trans>
            </RenderEvent>
          )
        }
      }
    }
  }

  if (items.length > 0) {
    savePhase('__END__')
  }

  return boxes
}


export const buildContractActivityBoxes = (contract: Contract, events: Event[]): React.ReactNode[] => {
  const activities = buildActivitiesCard(events)

  if (activities.length > 0 && !(contract as any).toJSON().dealID) {
    return activities
  }

  //  Try to compute a default box if none are there
  if (contract.contractStatus === 'imported') { return [<RenderBox key='defaultBox' phase='imported' />] }
  if (contract.contractStatus === 'signed') { return [<RenderBox key='defaultBox' phase='signed' />] }
  if (contract.contractStatus === 'frozen') { return [<RenderBox key='defaultBox' phase='signing' />] }
  if (contract.sentOverDate) { return [<RenderBox key='defaultBox' phase='negotiate' />] }
  return [<RenderBox key='defaultBox' phase='drafting' />]
}
