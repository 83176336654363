import React from 'react'
import { Layout } from 'antd'
import { HomeOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'

import ButtonLink from '../SharedComponents/ButtonLink'

// assets
import LogoImage from '../../img/top-legal-logo-white.svg'
import TransferLogo from '../../Transferinitiative/transferinitiative-logo.png'
import './EmptyHeader.scss'

const EmptyHeader: React.FC = ({ children }) => (
  <Layout.Header className='mainLayout-header emptyHeader'>
    <a href={(window as any).IS_TRANSFERINITIATIVE ? '/' : 'https://www.top.legal'}>
      {
        //  TODO: Move to whitelabel version instead of HardCoded one for transferinitiative
        (window as any).IS_TRANSFERINITIATIVE ? (
          <img alt='top.legal logo' className='top-legal-logo' src={TransferLogo} />
        ) : (
          <img alt='top.legal logo' className='top-legal-logo' src={LogoImage} />
        )
      }
    </a>
    {
      //  TODO: Move to whitelabel version instead of HardCoded one for transferinitiative
      (window as any).IS_TRANSFERINITIATIVE && (
        <div className='aboutUsButtonWrapper'>
          <ButtonLink
            href='https://transferinitiative-rlp.de/ueber-uns/'
            icon={<HomeOutlined />}
            noRouter
            target='_blank'
            type='default'
          >
            <Trans>About us</Trans>
          </ButtonLink>
        </div>
      )
    }
    {children || null}
  </Layout.Header>
)

export default EmptyHeader
