import React, { useContext, useMemo, useState } from 'react'
import { BellOutlined, InfoCircleOutlined, MenuOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'

import { Button, Drawer } from 'antd'
import MainContainer from '../Layouts/MainLayout/MainContainer'
import { OrganisationLogs, RemindersLogs } from './Logs'
import { withUserData } from '../Layouts/AuthenticatedPage'
import DiscoverBox from './DiscoverBox'

import './DashboardStyles.scss'
import { DeviceContext } from '../../GlobalContext'

const Dashboard = () => {
  const device = useContext(DeviceContext)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [taskShow, setTaskShow] = useState(false)

  return (
    <MainContainer
      mainContentClass='dashboardContentWrapper'
      topbarContent={(
        <>
          <div className='topbarMainContent'>
            <InfoCircleOutlined className='headerIcon' twoToneColor='#3DBD7D' />
            {device !== 'phone' && <h1 className='title'><Trans>Activity</Trans></h1>}
          </div>

          {device !== 'desktop' && (
            <div className='topbarActions'>
              <div className='searchPhone'>
                <Button
                  className='noBorder phoneSidebarDrawerButton'
                  ghost
                  icon={<MenuOutlined />}
                  onClick={() => setSidebarOpen(true)}
                  type='default'
                />
              </div>
              <Drawer
                className='phoneSidebarDrawer'
                onClose={() => setSidebarOpen(false)}
                placement='right'
                title={<h1 className='title'><Trans>Activity</Trans></h1>}
                visible={sidebarOpen}
                width='60vw'
                zIndex={1000}
              >
                <div className='sidebarToolboxButtons'>
                  <Button
                    className='noBorder'
                    ghost
                    icon={<InfoCircleOutlined className='headerIcon' twoToneColor='#3DBD7D' />}
                    onClick={() => {
                      setTaskShow(false)
                      setSidebarOpen(false)
                    }}
                  >
                    <span><Trans>Activities</Trans></span>
                  </Button>
                  <Button
                    className='noBorder'
                    ghost
                    icon={<BellOutlined />}
                    onClick={() => {
                      setTaskShow(true)
                      setSidebarOpen(false)
                    }}
                  >
                    <span><Trans>My Tasks</Trans></span>
                  </Button>
                </div>
              </Drawer>
            </div>
          )}
        </>
      )}
    >
      {(() => {
        if (device !== 'desktop') {
          if (taskShow) {
            return (
              <div className='dashboardCards'>
                <RemindersLogs />
              </div>
            )
          }

          return (
            <div className='dashboardContent'>
              <OrganisationLogs />
            </div>
          )
        }

        return (
          <div className='dashboardContent'>
            <OrganisationLogs />
            <div className='dashboardCards'>
              <DiscoverBox />
            </div>
          </div>
        )
      })()}
    </MainContainer>
  )
}
const Wrapped = props => {
  const Component = useMemo(() => withUserData(Dashboard), [])
  return <Component {...props} />
}

export default Wrapped
