import React, {
  useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState
} from 'react'
import ReactDOM from 'react-dom'
import { Button, Tooltip } from 'antd'
import { MessageOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'

import { IdPrefixContext } from '../../../../ContractDiplay/ContractLayout'
import { TemplateContext } from '../../../Contexts'
import { IntersectionObserverContext, RenderComments } from './CommentsToolbox'
import NewComment from './NewComment'
import PartHistory from './PartHistory'
import { CommentTreeCommentNode, CommentTreePartNode } from './Types'
import { noop } from '../../../../../Defaults'


interface PartCommentProps {
  instanceID: string,
  instanceType: string,
  node: CommentTreePartNode,
  historyNode: CommentTreePartNode,
  switchToolbox: (key: string) => void,
  currentBoxRef: React.MutableRefObject<string>,
  side: 'internal' | 'external',
  canAlwaysComment?: boolean
}

export interface PartCommentContextContent {
  instanceID: string,
  instanceType: string,
  part: any,
  switchToolbox: (key: string) => void,
  currentBoxRef: React.MutableRefObject<string>,
  side: 'internal' | 'external',
  flashElem: (elem?: HTMLElement | null) => void,
  scrollElem: (elem?: HTMLElement | null) => void,
  getSectionElem: () => HTMLElement | null
}

export const PartCommentContext = React.createContext<PartCommentContextContent>({
  instanceID: '',
  instanceType: '',
  part: {},
  switchToolbox: noop,
  currentBoxRef: { current: 'none' },
  side: 'internal',
  flashElem: noop,
  scrollElem: noop,
  getSectionElem: noop
})

export const usePartCommentElements = (node: CommentTreePartNode) => {
  const { template } = useContext<any>(TemplateContext)
  const idPrefix = useContext(IdPrefixContext)

  //  Auto scroling to comment if url has some query params
  const currentSectionID = useMemo(() => node.part.sectionID.replace(/template-.+?-/, ''), [node.part.sectionID])
  const getSectionElem = useCallback(() => document.getElementById(`${idPrefix}${template.templateID}-${currentSectionID}`), [currentSectionID, idPrefix, template.templateID])

  const [domElement, setDomElement] = useState<HTMLElement>()
  useEffect(() => {
    let tries = 0
    const getElem = () => {
      const parent = getSectionElem()
      const elem = parent && parent.querySelector('.sectionFloatingButtons')
      if (elem) {
        setDomElement(elem as HTMLElement)
      } else {
        tries += 1
        if (tries < 5) {
          setTimeout(getElem, 500)
        }
      }
    }
    setTimeout(getElem, 500)
    return () => setDomElement(undefined)
  }, [currentSectionID, getSectionElem, idPrefix])

  return [currentSectionID, getSectionElem, domElement]
}

export const CommentButton: React.FC<{ node: CommentTreePartNode, onClick: () => void }> = ({ node, onClick }) => {
  const lenOf = (node_: CommentTreePartNode | CommentTreeCommentNode): number => {
    if (!node_.comments) {
      return 0
    }

    let len = 0
    Object.values(node_.comments).forEach(child => {
      len += 1
      len += lenOf(child)
    })
    return len
  }

  const len = lenOf(node)
  return (
    <Tooltip placement='left' title={<Trans>Comment</Trans>}>
      <Button
        className={`ant-btn-icon-only commentIndicator ${len === 0 ? 'empty' : ''}`}
        ghost
        icon={<MessageOutlined />}
        onClick={evt => {
          evt.preventDefault()
          evt.stopPropagation()
          onClick()
        }}
        type='primary'
      >
        <span className='indicatorCount'>{len > 9 ? '9+' : len}</span>
      </Button>
    </Tooltip>
  )
}

export const ShowHistoryContext = React.createContext(false)
const PartComment: React.FC<PartCommentProps> = ({
  instanceID,
  instanceType,
  node,
  historyNode,
  switchToolbox,
  currentBoxRef,
  side,
  canAlwaysComment
}) => {
  const [newComment, setNewComment] = useState(false)

  const [currentSectionID, getSectionElem, domElement] = usePartCommentElements(node)
  const commentsRef = useRef<HTMLDivElement>(null)

  const flashElem = useCallback((elem: HTMLElement | null = commentsRef.current) => {
    if (commentsRef.current && elem === commentsRef.current) {
      elem = commentsRef.current.querySelector('.ant-card') || commentsRef.current
    }
    if (elem) {
      elem.classList.add('blink-outline')
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setTimeout(() => elem.classList.remove('blink-outline'), 1000)
    }
  }, [])

  // =====================================================================================================================================================

  const scrollElem = useCallback((elem: HTMLElement | null = commentsRef.current) => {
    if (commentsRef.current && elem === commentsRef.current) {
      elem = commentsRef.current.querySelector('.ant-card') || commentsRef.current
    }
    if (elem) {
      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }, [])

  const observer = useContext(IntersectionObserverContext)
  useLayoutEffect(() => {
    let elem = getSectionElem()
    elem = elem && elem.querySelector('.sectionText')
    if (elem) {
      (elem as any).comment = commentsRef.current
      observer.observe(elem)
      return () => observer.unobserve(elem as HTMLElement)
    }
    return undefined
  }, [getSectionElem, observer])

  return (
    <PartCommentContext.Provider
      value={{
        instanceType,
        instanceID,
        part: node.part,
        side,
        currentBoxRef,
        switchToolbox,
        scrollElem,
        flashElem,
        getSectionElem
      }}
    >
      <div ref={commentsRef} className='partComments' id={`${currentSectionID}-partComments`}>
        {domElement && ReactDOM.createPortal(
          <CommentButton
            node={node}
            onClick={() => {
              if (commentsRef.current) {
                setNewComment(true)
                if (currentBoxRef.current !== 'comments') {
                  switchToolbox('comments')
                  setTimeout(scrollElem, 550)
                } else {
                  scrollElem()
                }
              }
            }}
          />,
          domElement
        )}
        {canAlwaysComment && !newComment && (
          <Button
            block
            onClick={evt => {
              evt.stopPropagation()
              evt.preventDefault()
              setNewComment(true)
            }}
            type='primary'
          >
            <Trans>New topic</Trans>
          </Button>
        )}
        <div className='comments'>
          <RenderComments node={node} />
        </div>
        {newComment && (
          <div className='addNewComment'>
            <NewComment close={() => setNewComment(false)} focus />
          </div>
        )}
      </div>
      <PartHistory domElement={domElement} node={historyNode} />
    </PartCommentContext.Provider>
  )
}

export default PartComment
