import React, { useContext } from 'react'
import { useParams } from 'react-router'
import { Trans } from '@lingui/macro'
import { Button } from 'antd'
import { CheckCircleFilled, EditOutlined, GlobalOutlined, MessageOutlined, UserAddOutlined, WechatOutlined } from '@ant-design/icons'
//  Components
import { MediaCard } from '../../Media/MediaLibrary'
import { DefaultSigningVideo } from '../ExternalPageSigning'
import { ExternalContext } from '../ExternalContext'

const ExternalWelcomeToolboxSigningComponent = ({ switchToolbox }) => {
  const { tokenData } = useContext(ExternalContext)

  return (
    <div className='signingContent'>
      {tokenData.introVideo ? <MediaCard media={tokenData.introVideo} viewerOnly /> : <DefaultSigningVideo />}
      <div className='contractDraftedBox'>
        <h4 className='done'><CheckCircleFilled /> <Trans>Contract proposed</Trans></h4>
        <ul>
          <li>
            <Button className='noBorder' ghost icon={<WechatOutlined />} onClick={() => switchToolbox('chat')}>
              <Trans>Chat</Trans>
            </Button>
          </li>
          <li>
            <Button className='noBorder' ghost icon={<UserAddOutlined />} onClick={() => switchToolbox('users')}>
              <Trans>Share contract</Trans>
            </Button>
          </li>
          {tokenData.signer !== false && <li id='externalWelcomeBoxSigning' />}
        </ul>
      </div>
    </div>
  )
}

const ExternalWelcomeToolboxEditingComponent = ({ switchToolbox }) => {
  const { access } = useParams()
  const { tokenData } = useContext(ExternalContext)

  return (
    <div className='signingContent'>
      {tokenData.introVideo ? <MediaCard media={tokenData.introVideo} viewerOnly /> : <DefaultSigningVideo />}
      <div className='contractDraftedBox'>
        <h4 className='done'><CheckCircleFilled /> <Trans>Contract proposed</Trans></h4>
        <ul>
          <li>
            <Button className='noBorder' ghost icon={<WechatOutlined />} onClick={() => switchToolbox('chat')}>
              <Trans>Chat</Trans>
            </Button>
          </li>
          <li>
            <Button className='noBorder' ghost icon={<MessageOutlined />} onClick={() => switchToolbox('comments')}>
              <Trans>Comment</Trans>
            </Button>
          </li>
          {(access !== 'view' && (
            <li>
              <Button className='noBorder' ghost icon={<EditOutlined />} onClick={() => switchToolbox('comments')}>
                <Trans>Redline</Trans>
              </Button>
            </li>
          ))}
          <li>
            <Button className='noBorder' ghost icon={<UserAddOutlined />} onClick={() => switchToolbox('users')}>
              <Trans>Share contract</Trans>
            </Button>
          </li>
          {tokenData.signer !== false && <li id='externalWelcomeBoxSigning' />}
        </ul>
      </div>
    </div>
  )
}

const Text = () => {
  const { tokenData } = useContext(ExternalContext)
  return tokenData.introVideo ? <Trans>Your personal message</Trans> : <Trans>Intro video</Trans>
}

const toolboxConfig = {
  key: 'welcome',
  className: 'externalWelcomeToolbox',
  Text,
  icon: <GlobalOutlined />
}

export const ExternalWelcomeToolboxSigning = {
  ...toolboxConfig,
  Component: ExternalWelcomeToolboxSigningComponent
}

export const ExternalWelcomeToolboxEditing = {
  ...toolboxConfig,
  Component: ExternalWelcomeToolboxEditingComponent
}
