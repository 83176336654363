import React, { useContext, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import { Button } from 'antd'
import { Trans } from '@lingui/macro'

import { UserAndCompanyDataContext } from '../../../../../Layouts/Constants'

import { CommentDisplayProps, CommentsToolboxConfigContext, TaskComment } from './Types'
import { PartCommentContext } from './PartComment'
import { CommentTitleDisplay, FormatText, useAutoScrollToComment } from './CommentDisplay'
import Thread from './Thread'
import NewComment from './NewComment'
import { ActionTask } from '../../../../../Tasks/Tasks'

// =====================================================================================================================================================
/**
 * Exports the task component with click and scroll functionality.
 */

const TaskDisplay: React.FC<CommentDisplayProps & { withNewTabLink?: boolean }> = ({ node, noThread, withNewTabLink, onClick }) => {
  const { noLink, readOnly } = useContext(CommentsToolboxConfigContext)

  const task = node.comment as TaskComment
  const [threadOpen, setThreadOpen] = useState(false)

  const { user, company } = useContext<any>(UserAndCompanyDataContext)
  const { scrollElem, flashElem, getSectionElem } = useContext(PartCommentContext)
  const elemRef = useRef<HTMLDivElement>(null)

  // =====================================================================================================================================================
  /**
   * highlighting the task with a yellow border when clicking on the comment in the sidebox
   */

  const highlightAndScroll = useMemo(
    () => onClick
      || (() => {
        let section = getSectionElem()
        section = (section && section.querySelector('.sectionText')) || section
        if (noThread) {
          scrollElem(section)
        }
        scrollElem(elemRef.current)
        flashElem(elemRef.current)
        flashElem(section)
      }),
    [onClick, getSectionElem, noThread, scrollElem, flashElem]
  )

  const { part } = useContext(PartCommentContext)
  useAutoScrollToComment(part.sectionID, task, highlightAndScroll, setThreadOpen)

  const editable = !readOnly && new Date().valueOf() - new Date(task.date).valueOf() < 1000 * 60 * 60 && company.members[task.userID]
  const [editing, setEditing] = useState(false)

  if (editing) {
    return (
      <div ref={elemRef}>
        <NewComment close={() => setEditing(false)} focus initialData={task} />
      </div>
    )
  }

  const isMine = task.assignedTo === user.userID
  const classes = ['comment', 'task']
  if (isMine) {
    classes.push('myTask')
  }
  if (task.done) {
    classes.push('done')
  }

  return (
    <div ref={elemRef} className={classes.join(' ')} onClick={highlightAndScroll}>
      <CommentTitleDisplay comment={task}>
        {isMine && (
          <span className='assignedToYou'>
            <Trans>Assigned to you</Trans>
          </span>
        )}
        {(editable || !noLink || !task.done || withNewTabLink) && (
          <div className='actions' onClick={evt => evt.stopPropagation()}>
            <ActionTask record={task} />
          </div>
        )}
      </CommentTitleDisplay>

      <p>
        <FormatText text={task.text} />
      </p>

      {task.dueDate && (
        <p className='dueDate'>
          <Trans>
            Due date <span>{moment(task.dueDate).calendar() === 'Invalid date' ? ' ' : moment(task.dueDate).calendar()}</span>
          </Trans>
        </p>
      )}

      {!noThread && (
        <>
          <Button
            //  eslint-disable-next-line no-script-url
            href='javascript:void(0)'
            onClick={evt => {
              evt.stopPropagation()
              setThreadOpen(true)
            }}
            size='small'
            type='link'
          >
            {node.comments ? <Trans>{Object.keys(node.comments).length} comments</Trans> : <Trans>Comment</Trans>}
          </Button>
          <Thread
            close={() => {
              setThreadOpen(false)
              flashElem(elemRef.current)
            }}
            node={node}
            open={threadOpen}
          />
        </>
      )}
    </div>
  )
}

export default TaskDisplay
