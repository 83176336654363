import React from 'react'

interface ListingContextProps {
  Highlight: React.FC<{ text: any }>
}

const ListingContext = React.createContext<ListingContextProps>({
  Highlight: ({ text }) => text
})

export default ListingContext
