import { Node } from 'slate'
import { createHistoryPlugin, PlatePlugin } from '@udecode/plate-core'
import { createBoldPlugin, createItalicPlugin, createUnderlinePlugin } from '@udecode/plate-basic-marks'
import { createListPlugin, ELEMENT_LIC } from '@udecode/plate-list'
import { createLinkPlugin } from '@udecode/plate-link'
import { createImagePlugin } from '@udecode/plate-image'
import { createTablePlugin } from '@udecode/plate-table'
import { createDeserializeDocxPlugin } from '@udecode/plate-serializer-docx'

import { MarkerTypeBlock, ParagraphType, TemplateEntityType } from './Constants'
import breakPlugin from './customPlugins/BreakPlugin'
import makeTemplateEntitiesPlugin from './customPlugins/TemplateEntities'


/** ******************************************************************
 *                      Default plugins
 ****************************************************************** */
const paragraphPlugin: PlatePlugin = {
  key: ParagraphType,
  isElement: true,
  deserializeHtml: {
    getNode: elm => {
      if (elm.nodeName === 'P') {
        return ({
          type: ParagraphType, align: elm.style.textAlign || 'left', children: [{ text: '' }]
        })
      }
      return undefined
    }
  }
}
const textStylePlugin: PlatePlugin[] = [
  createBoldPlugin(),
  createItalicPlugin(),
  createUnderlinePlugin()
]

const listPlugin: PlatePlugin = {
  ...createListPlugin(undefined, {
    [ELEMENT_LIC]: {
      type: ParagraphType
    }
  }),
  options: {
    validLiChildrenTypes: [ParagraphType, TemplateEntityType, MarkerTypeBlock]
  }
}
const linkPlugin: PlatePlugin = { ...createLinkPlugin() }
const imagePlugin: PlatePlugin = { ...createImagePlugin() }
const tablePlugin: PlatePlugin = { ...createTablePlugin() }

//  Top level needed plugins
const historyPlugin: PlatePlugin = { ...createHistoryPlugin() }
const docxDeserialiser: PlatePlugin = { ...createDeserializeDocxPlugin() }


/** ******************************************************************
 *                      Get plugins
 ****************************************************************** */

export interface EnabledPlugins {
  list: boolean
  link: boolean
  image: boolean
  table: boolean
  history: boolean
  templateEntities?: {
    getField: (key: string) => any
    getCond: (key: string) => any
    onEntityAdded?: (node: Node) => void
    onEntityRemoved?: (node: Node) => void
  }
}

export const getPlugins = (enabledPlugins: Partial<EnabledPlugins>): PlatePlugin[] => {
  const plugins = [
    breakPlugin,
    docxDeserialiser,
    paragraphPlugin,
    ...textStylePlugin
  ]

  if (enabledPlugins.history) {
    plugins.push(historyPlugin)
  }
  if (enabledPlugins.list) {
    plugins.push(listPlugin)
  }
  if (enabledPlugins.link) {
    plugins.push(linkPlugin)
  }
  if (enabledPlugins.image) {
    plugins.push(imagePlugin)
  }
  if (enabledPlugins.table) {
    plugins.push(tablePlugin)
  }
  if (enabledPlugins.templateEntities) {
    plugins.push(makeTemplateEntitiesPlugin(enabledPlugins.templateEntities))
  }

  return plugins
}
