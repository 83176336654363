/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Trans } from '@lingui/macro'
import * as Sentry from '@sentry/browser'
import { useDispatch } from 'react-redux'

//  Contexts
import { defaultCompany, defaultUser, UserAndCompanyDataContext } from './Constants'

//  Custom components
import RestService from '../../RestService'
import { GlobalLoadingAlert } from '../Alert/LoadingAlert'
import OrganisationCreateModal from '../Dashboard/OrganisationCreateModal'
import TempEntityEndorsementBar from '../Organisations/TempEntityEndorsementBar'

//  Actions
import { loadMediaLibrary, STORE_SELECTED_ORGA } from '../Organisations/redux/OrganisationsActions'
import { SAVE_USER_PROFILE } from '../../actions/action_user'


// ==================================================================================================

/**
 * This component wraps the entire app to fetch the user data. The user data is fetched only once
 * from the REST API at the first time when the component mounts.
 */
export const AuthenticatedPage: React.FC<{ checkOnly?: boolean }> = ({ children, checkOnly }) => {
  const { user, company, setUser, setCompany, currentDate } = useContext<any>(UserAndCompanyDataContext)
  const { userID, currentSelectedOrganisationID: selectedOrgID } = user
  const { organisationID } = company
  const [loading, setLoading] = useState((userID === defaultUser.userID || !organisationID) && !checkOnly)


  // ==================================================================================================


  //  Load user profile
  useEffect(() => {
    if (!checkOnly && (!currentDate || !user.lastFetch || user.lastFetch < currentDate)) {
      (async () => {
        const loggedUser = await RestService.getCurrentCognitoSession(true)

        if (!(window as any).IS_TRANSFERINITIATIVE) {
          //  Redirect user if needed
          const isZurich = window.location.hostname.startsWith('ch-')
          const shouldBeZurich = loggedUser.attributes['custom:distributor'] === 'top.legal Zurich'

          //  Redirect user to the right instance according to the distributor
          if (shouldBeZurich !== isZurich) {
            const url = new URL(window.location.href)
            url.hostname = shouldBeZurich ? 'ch-automate.top.legal' : 'app.top.legal'
            window.location.href = url.href
          }
        }

        if (loggedUser && (
          userID === defaultUser.userID || loggedUser.attributes.sub !== userID || user.lastFetch !== currentDate
        )) {
          setLoading(loggedUser.attributes.sub !== userID)
          const localUser = await RestService('GET', '/user/profile')
          localUser.lastFetch = currentDate
          await setUser(() => localUser)
        }
      })()
    }
  }, [checkOnly, userID, user, setUser, user.lastFetch, currentDate])


  //  Load organisation data
  useEffect(() => {
    if (!checkOnly && userID !== defaultUser.userID && selectedOrgID && (
      organisationID !== selectedOrgID
      || !company.lastFetch || !currentDate || company.lastFetch < currentDate
    )) {
      (async () => {
        setLoading(organisationID !== selectedOrgID)
        const org = await RestService('get', `/organisation/${selectedOrgID}`)

        org.lastFetch = currentDate
        setCompany(() => org)
      })()
    }
  }, [checkOnly, userID, selectedOrgID, organisationID, setCompany, company.lastFetch, currentDate])


  //  Remove loading once all done
  useEffect(() => {
    if (userID && selectedOrgID && selectedOrgID === organisationID) {
      setLoading(false)
    }
  }, [userID, selectedOrgID, organisationID])


  //  Auto switch to user lang if not on local dev env
  if (process.env.NODE_ENV !== 'development') {
    const history = useHistory()
    const { lang } = useParams()
    const langRef = useRef(lang)
    langRef.current = lang

    useEffect(() => {
      if (user.preferredLanguage && langRef.current && user.preferredLanguage !== langRef.current) {
        const newLoc = new URL(window.location.href)
        newLoc.pathname = newLoc.pathname.replace(langRef.current, user.preferredLanguage)
        history.replace(newLoc.href.replace(newLoc.origin, ''))
      }
    }, [history, user.preferredLanguage, user.distributor])
  }


  // ==================================================================================================


  //  Update sentry & analytics
  useEffect(() => {
    if (user.userID && user.email) {
      //  Identify user in segment
      if ((window as any).analytics) {
        (window as any).analytics.identify(user.userID, {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email
        }, {
          integrations: {
            Intercom: {
              user_hash: user.intercomHash
            }
          }
        })
      }
      //  Product fruits
      if ((window as any).$productFruits) {
        (window as any).$productFruits.push(['init', 'IbkrfDXtlKGbv7Yv', 'en', {
          username: user.userID,
          email: user.email,
          firstname: user.firstName,
          lastname: user.lastName
        }])
      }
      //  Setting the user for sentry
      Sentry.setUser({
        email: user.email,
        id: user.userID,
        username: `${user.firstName} ${user.lastName}`
      })
    }
  }, [user.userID, user.email, user.firstName, user.lastName, user.intercomHash])


  // ==================================================================================================
  /**
   * Sync old redux for now
   */
  const dispatch = useDispatch()
  useEffect(() => dispatch({
    type: SAVE_USER_PROFILE,
    payload: user
  }), [dispatch, user])
  useEffect(() => dispatch({
    type: STORE_SELECTED_ORGA,
    payload: company
  }), [company, dispatch])
  useEffect(() => {
    if (organisationID !== defaultCompany.organisationID) {
      dispatch(loadMediaLibrary())
    }
  }, [dispatch, organisationID])


  // ==================================================================================================
  /**
   * Rendering children with the context
   */
  return (
    <GlobalLoadingAlert description={<Trans>Loading your user information</Trans>} loading={loading}>
      {checkOnly ? children : (
        <>
          {user.tempEntityEndorsement ? <TempEntityEndorsementBar entID={user.tempEntityEndorsement} /> : null}
          {children}
          <OrganisationCreateModal />
        </>
      )}
    </GlobalLoadingAlert>
  )
}

// ==================================================================================================

// export component as HOC
// eslint-disable-next-line react/display-name
export const withUserData = (Component, checkOnly = false) => props => (
  <AuthenticatedPage checkOnly={checkOnly}>
    <Component {...props} />
  </AuthenticatedPage>
)
