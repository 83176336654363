import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { Trans } from '@lingui/macro'
import { saveInStore, useContractingPartiesCollection } from '@top-legal/datastore'
import RestService from '../../RestService'

//  Contexts
//  Components
import { GlobalLoadingAlert } from '../Alert/LoadingAlert'

//  Actions
import useBrowserStorage from '../../hooks/useBrowserStorage'
import { STORE_EXTERNAL_USER } from '../Organisations/redux/OrganisationsActions'
import { SAVE_USER_PROFILE } from '../../actions/action_user'
import { LANGS_AVAILABLE } from '../../Routes'
import { defaultUser } from '../Layouts/Constants'

export type TokenData = any

interface ExternalContextProps {
  tokenData: TokenData,
  setTokenData: React.Dispatch<React.SetStateAction<TokenData>>
}

export const ExternalContext = React.createContext<ExternalContextProps>({
  tokenData: undefined,
  setTokenData: () => undefined
})

export const NO_TOKEN_DATA = '__NO_TOKEN_DATA__'
const ExternalContextProvider: React.FC<{ tokenID: string }> = ({ tokenID, children }) => {
  const [tokenData, setTokenData] = useBrowserStorage<TokenData | typeof NO_TOKEN_DATA>(`${tokenID}-data`, NO_TOKEN_DATA, window.localStorage)
  const { lang } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  //  Check if the token has a preferredLanguage and redirect to it
  useEffect(() => {
    //  Redirect the user to his preferred language if possible
    if (tokenData.preferredLanguage && LANGS_AVAILABLE.includes(tokenData.preferredLanguage) && tokenData.preferredLanguage !== lang) {
      history.replace(window.location.pathname.replace(`/${lang}/`, `/${tokenData.preferredLanguage}/`))
    }
    //  We do not want to have recursive redirect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenData.preferredLanguage])


  /** ******************************************************************
   *                 Take care of loading token data
   ****************************************************************** */
  const partiesCollection = useContractingPartiesCollection()
  const [loading, setLoading] = useState(!tokenData.internalData)
  const tryRef = useRef(false)
  const hasInternalData = !!tokenData?.internalData
  useEffect(() => {
    if (tokenID && !tryRef.current && !hasInternalData) {
      tryRef.current = true
      ;(async () => {
        setLoading(true)
        try {
          const data = await RestService('GET', `/user/invite/${tokenID}`, undefined, false, NO_TOKEN_DATA)
          setTokenData(data)

          //  Save parties (is a mix of internal and external for simplification today)
          //  The store of external side does not store on disk so no issue for having this quick way
          if (Object(data.partiesData) === data.partiesData) {
            await saveInStore(partiesCollection, Object.values(data.partiesData))
            await new Promise(resolve => { setTimeout(resolve, 200) })
          }

          //  Save user information
          if (data.contact) {
            dispatch({
              type: STORE_EXTERNAL_USER,
              payload: {
                userID: defaultUser.userID,
                ...data.contact
              }
            })
          }
          dispatch({
            type: SAVE_USER_PROFILE,
            payload: { token: tokenID, tokenData: data }
          })
        } catch (err) {
          console.error('Failed to load token data', err)
          setTokenData(NO_TOKEN_DATA)
        }
        setLoading(false)
      })()
    } else {
      setLoading(false)
    }
  }, [tokenID, hasInternalData, setTokenData, dispatch, partiesCollection])


  /** ******************************************************************
   *            Provide the context for token data
   ****************************************************************** */
  const context = useMemo(() => ({
    tokenData: tokenData as TokenData,
    setTokenData
  }), [tokenData, setTokenData])

  return (
    <GlobalLoadingAlert description={<Trans>Loading your invitation data</Trans>} loading={loading}>
      <ExternalContext.Provider value={context}>
        {children}
      </ExternalContext.Provider>
    </GlobalLoadingAlert>
  )
}

export default ExternalContextProvider
