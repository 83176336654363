import React from 'react'
import { Node } from 'slate'
import { MARK_SEARCH_HIGHLIGHT } from '@udecode/plate-find-replace'

import plateComponents from './PlateComponents'

const Identity = ({ children }) => children

const SearchText: React.FC<{ text: string, search?: string }> = ({ text, search }) => {
  if (!search) {
    return text
  }

  const splits = text.toLowerCase().split(search.toLowerCase())
  if (splits.length === 1) {
    return text
  }

  const Mark = plateComponents[MARK_SEARCH_HIGHLIGHT] as any
  let offset = 0
  const children: any[] = []
  splits.forEach((str, index) => {
    children.push(text.slice(offset, str.length))
    offset += str.length
    if (index !== splits.length - 1) {
      children.push(<Mark key={index}>{text.slice(offset, search.length)}</Mark>)
    }
  })
  return children as any
}

const emptyObj: any = {}
const RenderNode: React.FC<{ node: Node, search?: string }> = ({ node, search }) => {
  const Component = plateComponents[node.type] || Identity
  return (
    <Component
      attributes={emptyObj}
      className=''
      editor={emptyObj}
      element={node as any}
      leaf={node as any}
      renderOnly
      text={(node as any).text}
    >
      {typeof node.text === 'string' && <SearchText search={search} text={node.text} />}
      {/* eslint-disable-next-line no-use-before-define */}
      {Array.isArray(node.children) && <RenderNodes nodes={node.children} />}
    </Component>
  )
}

const RenderNodes: React.FC<{ nodes: Node[], search?: string }> = ({ nodes, search }) => (
  <>
    {Array.isArray(nodes) && nodes.map((node, index) => <RenderNode key={index} node={node} search={search} />)}
  </>
)

const RenderRoot: React.FC<{ nodes: Node[], search?: string }> = ({ nodes, search }) => (
  <div className='renderOnlyEditor'>
    <RenderNodes nodes={nodes} search={search} />
  </div>
)

export default RenderRoot
