import videojs from 'video.js'

/**
 * From: https://github.com/hartman/videojs-ogvjs
 * VideoJsOGV Media Controller - Wrapper for OGV Media API
 * Support of webm for safari via WebAssembly
 */

const Tech = videojs.getComponent('Tech')

class VideoJsOGV extends Tech {
  constructor (options, ready) {
    super(options, ready)

    // Set initial state of player
    if (options.source && options.source.src) {
      this.el_.src = options.source.src
    }
    VideoJsOGV.setIfAvailable(this.el_, 'autoplay', options.autoplay)
    VideoJsOGV.setIfAvailable(this.el_, 'loop', options.loop)
    VideoJsOGV.setIfAvailable(this.el_, 'poster', options.poster)
    VideoJsOGV.setIfAvailable(this.el_, 'preload', options.preload)

    this.triggerReady()
  }

  dispose () {
    this.el_.removeEventListener('framecallback', this.onFrameUpdate)
    super.dispose()
  }

  createEl () {
    window.OGVLoader.base = 'https://cdn.jsdelivr.net/npm/ogv@1.8.4/dist'
    const el = new window.OGVPlayer({ webGL: true })

    if (!el.hasOwnProperty('preload')) {
      // simulate timeupdate events for older ogv.js versions pre 1.1 versions
      // needed for subtitles. preload is only defined in 1.1 and later,
      this.lastTime = 0
      el.addEventListener('framecallback', this.onFrameUpdate.bind(this))
    }

    el.className += ' vjs-tech'
    this.options_.tag = el

    return el
  }

  onFrameUpdate () {
    const timeupdateInterval = 0.25
    const now = this.el_ ? this.el_.currentTime : this.lastTime

    // Don't spam time updates on every frame
    if (Math.abs(now - this.lastTime) >= timeupdateInterval) {
      this.lastTime = now
      this.trigger('timeupdate')
      this.trigger('durationchange')
    }
  }

  play () {
    setTimeout(() => this.el_.play())
  }

  pause () {
    setTimeout(() => this.el_.pause())
  }

  paused () {
    return this.el_.paused
  }

  currentTime () {
    return this.el_.currentTime
  }

  setCurrentTime (seconds) {
    try {
      this.el_.currentTime = seconds
    } catch (e) {
      videojs.log(e, 'Video is not ready. (Video.js)')
    }
  }

  duration () {
    return this.el_.duration || 0
  }

  buffered () {
    return this.el_.buffered
  }

  volume () {
    return this.el_.hasOwnProperty('volume') ? this.el_.volume : 1
  }

  setVolume (percentAsDecimal) {
    if (this.el_.hasOwnProperty('volume')) {
      this.el_.volume = percentAsDecimal
    }
  }

  muted () {
    return this.el_.muted
  }

  setMuted (muted) {
    this.el_.muted = !!muted
  }

  width () {
    return this.el_.offsetWidth
  }

  height () {
    return this.el_.offsetHeight
  }

  src (src) {
    if (typeof src === 'undefined') {
      return this.el_.src
    }
    // Setting src through `src` instead of `setSrc` will be deprecated
    this.setSrc(src)
  }

  setSrc (src) {
    this.el_.src = src
  }

  load () {
    this.el_.load()
  }

  currentSrc () {
    if (this.currentSource_) {
      return this.currentSource_.src
    }
    return this.el_.currentSrc
  }

  poster () {
    return this.el_.poster
  }

  setPoster (val) {
    this.el_.poster = val
  }

  preload () {
    return this.el_.preload || 'none'
  }

  setPreload (val) {
    if (this.el_.hasOwnProperty('preload')) {
      this.el_.preload = val
    }
  }

  autoplay () {
    return this.el_.autoplay || false
  }

  setAutoplay (val) {
    if (this.el_.hasOwnProperty('autoplay')) {
      this.el_.autoplay = !!val
    }
  }

  controls () {
    return this.el_controls || false
  }

  setControls (val) {
    if (this.el_.hasOwnProperty('controls')) {
      this.el_.controls = !!val
    }
  }

  loop () {
    return this.el_.loop || false
  }

  setLoop (val) {
    if (this.el_.hasOwnProperty('loop')) {
      this.el_.loop = !!val
    }
  }

  error () {
    return this.el_.error
  }

  seeking () {
    return this.el_.seeking
  }

  seekable () {
    return this.el_.seekable
  }

  ended () {
    return this.el_.ended
  }

  defaultMuted () {
    return this.el_.defaultMuted || false
  }

  playbackRate () {
    return this.el_.playbackRate || 1
  }

  played () {
    return this.el_.played
  }

  setPlaybackRate (val) {
    if (this.el_.hasOwnProperty('playbackRate')) {
      this.el_.playbackRate = val
    }
  }

  networkState () {
    return this.el_.networkState
  }

  readyState () {
    return this.el_.readyState
  }

  videoWidth () {
    return this.el_.videoWidth
  }

  videoHeight () {
    return this.el_.videoHeight
  }

  supportsFullScreen () {
    return false
  }
}

VideoJsOGV.setIfAvailable = function (el, name, value) {
  if (el.hasOwnProperty(name)) {
    el[name] = value
  }
}

VideoJsOGV.isSupported = function () {
  return window.OGVCompat.supported('OGVPlayer')
}

VideoJsOGV.canPlayType = function (type) {
  if (type.includes('ogg') || type.includes('webm')) {
    return 'maybe'
  }
  return ''
}

VideoJsOGV.canPlaySource = function (srcObj) {
  return VideoJsOGV.canPlayType(srcObj.type)
}

VideoJsOGV.canControlVolume = function () {
  return true
}

VideoJsOGV.canControlPlaybackRate = function () {
  return false
}

VideoJsOGV.supportsNativeTextTracks = function () {
  return false
}

VideoJsOGV.Events = [
  'loadstart',
  'suspend',
  'abort',
  'error',
  'emptied',
  'stalled',
  'loadedmetadata',
  'loadeddata',
  'canplay',
  'canplaythrough',
  'playing',
  'waiting',
  'seeking',
  'seeked',
  'ended',
  'durationchange',
  'timeupdate',
  'progress',
  'play',
  'pause',
  'ratechange',
  'volumechange'
]

VideoJsOGV.prototype.featuresVolumeControl = VideoJsOGV.canControlVolume()
VideoJsOGV.prototype.featuresPlaybackRate = VideoJsOGV.canControlPlaybackRate()
VideoJsOGV.prototype.featuresFullscreenResize = true
VideoJsOGV.prototype.featuresProgressEvents = true
VideoJsOGV.prototype.featuresNativeTextTracks = VideoJsOGV.supportsNativeTextTracks()

Tech.registerTech('VideoJsOGV', VideoJsOGV)
window.VideoJsOGV = VideoJsOGV

