/* eslint-disable import/no-webpack-loader-syntax,import/no-unresolved,@typescript-eslint/ban-ts-comment,import/extensions */
import React, { useMemo } from 'react'
import { en, de } from 'make-plural/plurals'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import moment from 'moment'
import 'moment/locale/de'

//  Translations catalog
// @ts-ignore
import { messages as DeTranslation } from './locales/de/messages.po'
// @ts-ignore
import { messages as EnTranslation } from './locales/en/messages.po'

i18n.loadLocaleData({
  en: { plurals: en },
  de: { plurals: de },
})
i18n.load({
  de: DeTranslation,
  en: EnTranslation
})

if (process.env.NODE_ENV === 'development') {
  (window as any).i18n = i18n
  console.info('window.i18n available')
}

const TranslationsProvider: React.FC<{ lang: string }> = ({ lang, children }) => {
  // Set the moment locale for the whole app
  useMemo(() => {
    moment.locale(lang)
    i18n.activate(lang)
  }, [lang])

  return (
    <I18nProvider i18n={i18n}>
      {children}
    </I18nProvider>
  )
}

export default TranslationsProvider
