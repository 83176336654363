import { useCallback, useEffect, useRef } from 'react'

type Comparator<T> = (valA: T, valB: T) => boolean

const defaultComparator = <T>(valA: T, valB: T) => valA === valB

const useDebounceSaving = <T>(
  delay: number,
  propsData: T,
  currentData: T,
  setLocalState: (data: T) => void,
  saveData: (data: T) => Promise<any> | any,
  comparator: Comparator<T> = defaultComparator
): { saveTimeout: () => void, forceSave: () => void } => {
  /** ***********************************************************
   *    All the refs we need for avoiding too much updates
   *********************************************************** */
  const saveRef = useRef<NodeJS.Timeout>()
  const prevRef = useRef<T>()
  const saveDataRef = useRef<T>()
  const currRef = useRef<T>()
  currRef.current = currentData
  const savePromiseRef = useRef<Promise<any>>()

  /** ***********************************************************************************
   *   Take care of updating local state if the props data is different from prev one
   *********************************************************************************** */
  useEffect(() => {
    if (
      (prevRef.current == null && saveDataRef.current == null) || ( //  We don't have either prev value nor current saving value
        (prevRef.current == null || !comparator(prevRef.current, propsData)) //  Or we do have a prev value but different of the props one
        && (saveDataRef.current == null || !comparator(saveDataRef.current, propsData)) // And the saving value is also different
      )
    ) {
      prevRef.current = propsData
      setLocalState(propsData)
    }
  }, [comparator, setLocalState, propsData])

  /** ****************************************************************************************
   *           The saving function, keep saveDataRef and prevRef up to date
   * The savePromiseRef is there to avoid parrallel saving and so conflict from props value
   **************************************************************************************** */
  const save = useCallback(() => {
    if (saveRef.current) {
      clearTimeout(saveRef.current)
    }

    //  We only start saving if values are different and we are not in saving already (avoiding conflict with props data)
    if (prevRef.current == null || (currRef.current != null && !comparator(prevRef.current, currRef.current) && !savePromiseRef.current)) {
      const data = currRef.current as T
      saveDataRef.current = data
      savePromiseRef.current = Promise.resolve(saveData(data)).finally(() => {
        savePromiseRef.current = undefined
        prevRef.current = data
      })
    }
  }, [comparator, saveData])

  /** ***********************************************************
   *            Simple timeout for delayed saving
   *********************************************************** */
  const saveTimeout = useCallback(() => {
    if (saveRef.current) {
      clearTimeout(saveRef.current)
    }
    saveRef.current = setTimeout(save, delay)
  }, [delay, save])

  return { saveTimeout, forceSave: save }
}

export default useDebounceSaving
