import { SAVE_DYNAMIC_CONFIG, SAVE_USER_PROFILE } from '../actions/action_user'

const initState = {
  ownProfileData: {},
  dynamicConfigs: {}
}

const REDUCER_ACTIONS = {
  [SAVE_DYNAMIC_CONFIG]: (state, payload) => {
    state.dynamicConfigs[payload.configID] = payload
  },
  [SAVE_USER_PROFILE]: (state, payload) => {
    state.ownProfileData = payload
  }
}

export default function (state = initState, action) {
  state = { ...state }
  const handler = REDUCER_ACTIONS[action.type]
  if (handler) {
    state = handler(state, action.payload) || state
  }
  return state
}
