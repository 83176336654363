import React, { useContext, useMemo } from 'react'

import ContractAssistantContext from '../ContractAssistantContext'
import { AssistantInputProps } from './Types'
import useFieldSaving from './inputElements/useFieldSaving'
import AssistantFieldInput from './AssistantFieldInput'


const PartiesFieldInput: React.FC<AssistantInputProps> = props => {
  const { InputParty } = useContext(ContractAssistantContext)
  const { value, onChange } = useFieldSaving<any>(props, false, true)
  const { fieldKey, field } = props

  const child = useMemo(() => {
    const [, ...rest] = fieldKey.split('.')
    let current: any = field
    let next: any

    //  Get access to the children
    while (rest.length > 0) {
      next = rest.shift()
      current = current?.items?.[next]
    }

    return current || {}
  }, [field, fieldKey])

  if (child.type === 'company') {
    return (
      <InputParty
        companyRequired
        field={field}
        fieldKey={fieldKey}
        onChange={onChange}
        value={value}
      />
    )
  }

  if (child.type === 'person') {
    return (
      <InputParty
        field={field}
        fieldKey={fieldKey}
        onChange={onChange}
        userAddressRequired
        value={value}
      />
    )
  }

  return <AssistantFieldInput {...props} field={child} />
}

export default PartiesFieldInput
