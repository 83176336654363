/* eslint-disable id-length,max-lines,max-len */
let moment
try {
  moment = require('moment') // eslint-disable-line
} catch {
  try {
    moment = require('dayjs') // eslint-disable-line
  } catch {}
}

const { renderProductsToSlate } = require('@top-legal/editor')

/**
 * This file is also used in REST backend so don't move it and take care about Browser and NodeJs compatibility
 */
const _employeesOptions = {
  employees_1_10: '1 - 10',
  employees_11_25: '11 - 25',
  employees_26_50: '26 - 50',
  employees_51_200: '51 - 200',
  employees_201_500: '201 - 500',
  employees_500_more: '500+'
}

const gender = {
  de: {
    m: 'männlich',
    f: 'weiblich'
  },
  en: {
    m: 'male',
    f: 'female'
  }
}

const employeesOptions = {
  en: {
    alone: 'I work alone',
    ..._employeesOptions
  },
  de: {
    alone: 'Ich arbeite allein',
    ..._employeesOptions
  }
}

const industryOptions = {
  de: {
    biotechnology: 'Biotechnologie',
    construction: 'Baugewerbe',
    retail: 'Einzelhandel & Großhandel',
    entertainment: 'Entertainment & Kunst',
    finance: 'Finanzen',
    health: 'Gesundheitswesen',
    internet: 'Internet und E-Commerce',
    legal: 'Rechtsberatung',
    manufacturing: 'Herstellendes Gewerbe',
    media: 'Medien & Verlagswesen',
    professionalServices: 'Professional Services',
    food: 'Lebensmittel',
    realestateResidential: 'Immobilien - Vermietung',
    software: 'Software',
    technology: 'Technologie',
    other: 'Andere Industrie'
  },
  en: {
    biotechnology: 'Biotechnology',
    construction: 'Construction',
    retail: 'Retail',
    entertainment: 'Entertainment',
    finance: 'Finance',
    health: 'Health care',
    internet: 'Internet and E-Commerce',
    legal: 'Legal advisory',
    manufacturing: 'Manufacturing',
    media: 'Media and Telecoms',
    professionalServices: 'Professional Services',
    food: 'Food',
    realestateResidential: 'Real Estate',
    software: 'Software',
    technology: 'Technologie',
    other: 'Other'
  }
}

const timeMapping = {
  minutes: 1000 * 60,
  hours: 1000 * 60 * 60,
  days: 1000 * 60 * 60 * 24,
  months: 1000 * 60 * 60 * 24 * 30,
  years: 1000 * 60 * 60 * 24 * 365
}

const SECTION_PREFIX = {
  de: '§ ',
  en: 's. ',
  fr: 'Article '
}
const ANNEXE_PREFIX = {
  de: 'A',
  en: 'A',
  fr: 'A'
}
const ANNEXE_PART_NAME = {
  de: 'Appendix',
  en: 'Annexes',
  fr: 'Annexes'
}

/**
 * this function returns the requested fields element from the template
 * @param {string || [string]} path
 * @param {object} parent can be the template fields object
 */
const _getElem = (path, parent) => {
  if (typeof path === 'string') {
    return getElem(path.split('.'), parent)
  }
  if (Array.isArray(path)) {
    if (path.length && parent) {
      const index = path.shift()
      return getElem(path, (
        parent.subSections
        || parent.items
        || parent
      )[index])
    }
    return parent
  }
  return parent
}
const getElem = (path, parent) => {
  if (typeof path === 'string') {
    return _getElem(path.split('.'), parent)
  }
  if (Array.isArray(path)) {
    return _getElem([...path], parent)
  }
  return null
}

const buildKey = string => string.toLowerCase().replace(/ /g, '_').replace(/[^a-z_0-9]/g, '')

const mergeValues = (defaultValues, newValues = {}) => {
  Object.keys(defaultValues).forEach(key => {
    if (newValues[key]) {
      defaultValues[key] = newValues[key]
    }
  })
  return defaultValues
}

const isEmpty = children => Array.isArray(children) && (
  children.length === 0 || (
    children.length === 1 && (
      children[0].text === '' || (children[0].type === 'p' && isEmpty(children[0].children))
    )
  )
)

const generateCompanyEntity = (fieldKey, fieldName) => {
  const field = {
    [fieldKey]: {
      name: fieldName,
      type: 'company',
      slateType: 'block',
      value: company => {
        const children = field[fieldKey].items

        const name = children.name.value(company)
        const address = children.full_address.value(company)
        const vatID = children.vatID.value(company)

        if (name && address) {
          return [
            { type: 'p', children: [{ text: name }] },
            address[0],
            ...(vatID ? [{ type: 'p', children: [{ text: `\nVatID: ${vatID}` }] }] : [])
          ]
        }
        return null
      },
      items: {
        name: {
          name: `${fieldName} name`,
          type: 'oneLineText',
          value: company => company.name
        },
        industry: {
          name: `${fieldName} industry`,
          type: 'list',
          value: company => company.industry,
          values: industryOptions,
          optional: true
        },
        employees: {
          name: `${fieldName} employees number`,
          type: 'list',
          value: company => company.employees,
          values: employeesOptions,
          optional: true
        },
        full_address: {
          name: `${fieldName} full address`,
          type: 'generated',
          value: company => {
            const children = field[fieldKey].items.full_address.items

            const country = children.country.value(company)
            const postcode = children.postcode.value(company)
            const city = children.city.value(company)
            const address = children.address.value(company)

            if (country && postcode && city && address) {
              return [{ type: 'p', children: [{ text: `${address}\n${postcode} ${city}\n${country}` }] }]
            }
            return null
          },
          slateType: 'block',
          items: {
            country: {
              name: `${fieldName} country`,
              type: 'oneLineText',
              value: company => company.country
            },
            postcode: {
              name: `${fieldName} postcode`,
              type: 'oneLineText',
              value: company => company.postcode
            },
            city: {
              name: `${fieldName} city`,
              type: 'oneLineText',
              value: company => company.city
            },
            address: {
              name: `${fieldName} address`,
              type: 'oneLineText',
              value: company => company.address
            }
          }
        },
        vatID: {
          name: `${fieldName} vatID`,
          type: 'oneLineText',
          value: company => company.vatID,
          optional: true
        },
        commercialID: {
          name: `${fieldName} commercialID`,
          type: 'oneLineText',
          value: company => company.commercialID,
          optional: true
        }
      }
    }
  }
  return field
}

const generateUserEntity = (fieldKey, fieldName, getLang) => {
  const field = {
    [fieldKey]: {
      name: fieldName,
      type: 'person',
      slateType: 'block',
      value: user => {
        const children = field[fieldKey].items

        const name = children.full_name.value(user)
        const dob = children.dateOfBirth.value(user)
        const address = children.full_address.value(user)

        if (name && address) {
          const nodes = [{ type: 'p', children: [{ text: name }] }]
          if (dob && dob.getTime && !Number.isNaN(dob.getTime())) {
            const formattedDob = new Intl.DateTimeFormat(getLang() || 'en', { // Used by lingui but it's direct and not a react component
              day: '2-digit',
              month: 'long',
              year: 'numeric'
            }).format(dob)
            nodes.push({ type: 'p', children: [{ text: formattedDob }] })
          }
          nodes.push(address[0])
          return nodes
        }
        return null
      },
      items: {
        full_name: {
          name: `${fieldName} full name`,
          type: 'generated',
          value: user => {
            const children = field[fieldKey].items.full_name.items

            const firstName = children.firstName.value(user)
            const lastName = children.lastName.value(user)

            if (firstName && lastName) {
              return `${firstName} ${lastName}`
            }
            return null
          },
          slateType: 'inline',
          items: {
            firstName: {
              name: `${fieldName} first name`,
              type: 'oneLineText',
              value: user => user.firstName
            },
            lastName: {
              name: `${fieldName} last name`,
              type: 'oneLineText',
              value: user => user.lastName
            }
          }
        },
        email: {
          name: `${fieldName} email`,
          type: 'oneLineText',
          value: user => user.email
        },
        gender: {
          name: `${fieldName} gender`,
          type: 'list',
          value: user => user.gender,
          values: gender,
          optional: true
        },
        dateOfBirth: {
          name: `${fieldName} date of birth`,
          type: 'date',
          value: user => {
            const value = user.dateOfBirth
            return value && (value === Object(value) ? value : new Date(value))
          }
        },
        full_address: {
          name: `${fieldName} full address`,
          type: 'generated',
          value: user => {
            const children = field[fieldKey].items.full_address.items

            const country = children.country.value(user)
            const postcode = children.postcode.value(user)
            const city = children.city.value(user)
            const address = children.address.value(user)

            if (country && postcode && city && address) {
              return [{ type: 'p', children: [{ text: `${address}\n${postcode} ${city}\n${country}` }] }]
            }
            return null
          },
          slateType: 'block',
          items: {
            country: {
              name: `${fieldName} country`,
              type: 'oneLineText',
              value: company => company.country
            },
            postcode: {
              name: `${fieldName} postcode`,
              type: 'oneLineText',
              value: company => company.postcode
            },
            city: {
              name: `${fieldName} city`,
              type: 'oneLineText',
              value: company => company.city
            },
            address: {
              name: `${fieldName} address`,
              type: 'oneLineText',
              value: company => company.address
            }
          }
        }
      }
    }
  }
  return field
}

const jsonParse = data => {
  try {
    return JSON.parse(data)
  } catch {
    return {}
  }
}

const fillTemplateEntities = (
  template,
  userName = 'My user',
  companyName = 'My company',
  genUser = generateUserEntity,
  genComp = generateCompanyEntity,
  userLang = template.lang
) => {
  //  Convert data if needed
  if (typeof template.fields === 'string') {
    template.fields = jsonParse(template.fields)
  }
  if (typeof template.conditionalTexts === 'string') {
    template.conditionalTexts = jsonParse(template.conditionalTexts)
  }

  //  Format fields
  let formattedFields = {}
  if (Object(template.fields) === template.fields) {
    Object.keys(template.fields).forEach(key => {
      const field = template.fields[key]
      if (key.startsWith('__')) {
        delete template.fields[key]
      } else if (field.type === 'company') {
        delete template.fields[key]
        formattedFields = {
          ...formattedFields,
          [key]: { ...field, ...genComp(key, field.name)[key] }
        }
      } else if (field.type === 'person') {
        delete template.fields[key]
        formattedFields = {
          ...formattedFields,
          [key]: { ...field, ...genUser(key, field.name, () => template.lang)[key] }
        }
      }
    })
  }

  template.fields = {
    ...(template.fields || {}),
    ...genComp('__my_company', companyName),
    ...genUser('__my_user', userName, () => template.lang),
    ...formattedFields
  }

  const productTableTrans = {
    en: {
      name: 'Product & Services',
      question: 'What are you selling?',

      productName: 'Sample product',
      productDescription: 'The products will be selected during the contract configuration by the sales rep.',
      unitLabel: 'License'
    },
    de: {
      name: 'Produkte & Services',
      question: 'Was verkaufen Sie?',

      productName: 'Musterprodukt',
      productDescription: 'Die Produkte werden bei der Vertragskonfiguration durch den Vertriebsmitarbeiter ausgewählt.',
      unitLabel: 'Lizenz'
    }
  }

  //  Define a special field
  Object.defineProperty(template.fields, '__products', {
    configurable: true,
    enumerable: true,
    value: {
      key: '__products',
      type: 'productTable',
      pricingModel: 'volume',
      currency: 'eur',

      ...(productTableTrans[userLang] || productTableTrans.en),

      pricingTiers: [{
        max: 5, unit: 0, flat: 0
      }, {
        max: 100, unit: 25, flat: 1000
      }, {
        max: 500, unit: 20, flat: 700
      }, {
        unit: 18, flat: 500
      }],

      subscriptionType: 'recurring',
      billingPeriod: 'monthly'
    }
  })
}


// =================================================================================


// =================================================================================


// =================================================================================


// =================================================================================


//  Don't touch the string contains a invisible unicode char used by quill for wrapping embed span
const QUILL_CHAR = '﻿'
const templateEntities = ['inputField', 'conditionalText', 'sectionReference'].join('|')
const regexPart = `(class=\\\\?"(${templateEntities}).*?\\\\?"|data-key=\\\\?"(.*?)\\\\?")`
const templateEntitiesRegex = new RegExp(`<span.*?${regexPart} ?${regexPart}.*?>${QUILL_CHAR}?(<span[^<>]?>)?([^<>]*?)(</span>)?${QUILL_CHAR}?</span>`, 'g')

//  Case of Quill html (async since callback is async)
const quilTextIterator = (groups, matchStr, callback) => {
  if (groups.length >= 4) {
    let type = null
    let key = null
    let mark = null
    groups.some(str => {
      if (typeof str === 'string' && str) {
        if (str.includes('data-key=')) {
          mark = 'DATA_KEY'
        } else if (str.includes('class=')) {
          mark = 'CLASS'
        } else if (mark === 'DATA_KEY') {
          key = str
          mark = null
        } else if (mark === 'CLASS') {
          type = str
          mark = null
        }
      }
      return type && key
    })

    if (type && key) {
      return callback(type, key, matchStr)
    }
  }
  return null
}

const parseTemplateEntitiesInHtml = (html, callback) => {
  if (html) {
    if (Array.isArray(html)) {
      const processNode = node => {
        if (node.type === 'TemplateEntity') {
          // eslint-disable-next-line radix
          callback(node.entityType, node.key, parseInt(node.version))
        }
        if (Array.isArray(node.children)) {
          node.children.forEach(processNode)
        }
      }
      html.forEach(processNode)
    } else {
      let match
      // eslint-disable-next-line no-cond-assign
      while (match = templateEntitiesRegex.exec(html)) {
        const [matchedStr, ...groups] = match
        quilTextIterator(groups, matchedStr, callback)
      }
    }
  }
}


// =================================================================================


// =================================================================================


const formatValue = (value, formatting, lang = 'de', type, unit) => {
  if (value != null && formatting) {
    if (value === Object(value)) {
      const isMoment = typeof value.toDate === 'function'
      if (isMoment || value.constructor.name === 'Date') {
        if (type === 'date') {
          return new Intl.DateTimeFormat(lang, { // Used by lingui but it's direct and not a react component
            day: '2-digit',
            month: 'long',
            year: 'numeric'
          }).format(isMoment ? value.toDate() : value)
        }
        if (type === 'time') {
          return new Intl.DateTimeFormat(lang, { // Used by lingui but it's direct and not a react component
            hour: '2-digit',
            minute: '2-digit'
          }).format(isMoment ? value.toDate() : value)
        }
      }
    } else if (type === 'amount') {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
    } else if (typeof value === 'number') {
      if (unit) {
        return `${new Intl.NumberFormat('de-DE').format(value)} ${unit}` // Used by lingui but it's direct and not a react component
      }
      return new Intl.NumberFormat('de-DE').format(value) // Used by lingui but it's direct and not a react component
    }
  }
  return value
}


// =================================================================================


// =================================================================================


const getValue = (fields, fieldKey, values, formatting, lang = 'de') => {
  if (!values) { return null }
  const field = getElem(fieldKey, fields)
  const [baseKey] = fieldKey.split('.')
  fieldKey = fieldKey.replace(/\./g, '-')
  if (field && isPartiesEntity(fields[baseKey])) {
    values = values[baseKey] || {}
  }
  let value = values[fieldKey]
  const unit = field && field.unit
  const type = field && field.type
  if (field) {
    if (field.type === 'product') {
      if (formatting) {
        const table = renderProductsToSlate([field], value, lang)
        if (table) {
          return [table]
        }
      }
      return value
    }

    if (field.type === 'productTable') {
      value = (value && value.__products) || value
      if (formatting && Array.isArray(value)) {
        const products = []
        const quantities = {}

        value.forEach(({ fieldKey: pdt, qty }, index) => {
          products.push(fields[pdt])
          if (qty != null) {
            quantities[index] = qty
          }
        })

        const table = renderProductsToSlate(products, quantities, lang)
        if (table) {
          return [table]
        }
      }
      return value
    }

    if (field.type === 'listOfFormattedText') {
      let currentKey = fieldKey
      let currentField = field
      while (currentField && currentField.previousListField) {
        currentKey = currentField.previousListField
        currentField = getElem(currentKey, fields)
      }
      value = values[currentKey]
      return Array.isArray(value) ? value : null
    }

    if (typeof field.value === 'function') {
      if (formatting) {
        return formatValue(field.value(values), formatting, lang)
      }
      return field.component ? values : field.value(values)
    }

    if (value != null) {
      if (field.type === 'date') {
        if (formatting) {
          value = parseDate(value)
        }
      } else if (field.type === 'time') {
        if (formatting) {
          value = parseTime(value)
        }
      } else if (field.type === 'list') {
        if (formatting) {
          if (field.isMultipleChoices) {
            if (Array.isArray(value)) {
              const children = []

              value.forEach(fieldValue => {
                const val = field.values[fieldValue]
                if (val && !isEmpty(val)) {
                  children.push({ type: 'li', children: [{ type: 'p', children: [{ text: val }] }] })
                }
              })

              if (children.length > 0) {
                return [{ type: 'ul', children }]
              }
            }
            return null
          }
          const fieldValues = field.values[lang] || field.values
          value = fieldValues[value]
        }
        return value
      } else if (field.type === 'multipleLineText') {
        return value
      }
    }
  }
  return formatValue(value, formatting, lang, type, unit)
}


// ==================================================================================================


// ==================================================================================================


const checkSection = (section, template, currentContext) => {
  if (section.isDynamicSection && section.field) {
    const field = getElem(section.field, template.fields)
    let value = getValue(template.fields, section.field, currentContext.fieldsResponse || {}, false)

    if (field == null || value == null) {
      return false
    }
    if (field.type === 'yesNo') {
      if (value !== section.active) {
        return false
      }
    } else if (field.type === 'list') {
      if (field.isMultipleChoices && Array.isArray(value)) {
        return section.activeValues.some(activeChoice => value.includes(activeChoice))
      }
      return section.activeValues.includes(value)
    } else if (/(date|time|number|amount|listOfFormattedText)/.test(field.type)) {
      if (field.type === 'date') {
        if (section.isRelativeToContractFillingDate) {
          value = parseDate(value)
          if (!value) {
            return false
          }
          value = value.valueOf()
          value -= Date.now()
        } else {
          const lower = section.inferiorBorder
          const upper = section.superiorBorder
          return value && (!lower || value >= lower) && (!upper || value <= upper)
        }
      } else if (field.type === 'time') {
        value = parseTime(value)
      } else if (field.type === 'listOfFormattedText') {
        value = value.length
      }

      const timesToType = type => timeMapping[type] || 1
      if (section.inferiorBorder != null) {
        if (value < section.inferiorBorder * timesToType(section.inferiorBorderType)) {
          return false
        }
      }
      if (section.superiorBorder != null) {
        if (value > section.superiorBorder * timesToType(section.superiorBorderType)) {
          return false
        }
      }
    }
  }
  return true
}


// ==================================================================================================


// ==================================================================================================


const getConditionalTextValue = (field, conditionalText, value, lang, emptyText = '') => {
  if (value == null) { // null or undefined return empty text
    return emptyText
  }

  const values = conditionalText.values[lang] || conditionalText.values

  let reelText = ''
  if (/(date|time|number|amount|listOfFormattedText)/.test(field.type)) {
    let getValue = obj => obj.value
    const timesToType = type => timeMapping[type] || 1

    if (field.type === 'date') {
      if (conditionalText.isRelativeToContractFillingDate) {
        value = parseDate(value)
        if (!value) {
          return false
        }
        value = value.valueOf()
        value -= Date.now()
        getValue = obj => obj.value * timesToType(obj.type)
      }
    } else if (field.type === 'time') {
      value = parseTime(value)
      getValue = obj => obj.value * timesToType(obj.type)
    } else if (field.type === 'listOfFormattedText') {
      value = value.length
    }

    if (value != null) { // not null or undefined but can be 0
      const match = conditionalText.values.find(obj => value <= getValue(obj))
      if (match) {
        reelText = match.text
      }
    }
  } else if (/(list|yesNo)/.test(field.type)) {
    /**
     * The conditional text values is well formatted to access to the correct text just with the choice of the user for more info look below
     * @see ConditionalTextForm.renderConditionDefinitionStep
     */
    // if the field is a list or of type boolean
    if (field.isMultipleChoices) {
      // if the field is list that allows multiple choices
      if (Array.isArray(value)) {
        const children = []

        value.forEach(val => {
          if (Array.isArray(values[val]) && !isEmpty(values[val])) {
            children.push({
              type: 'd',
              children: values[val]
            })
          }
        })

        if (!isEmpty(children)) {
          reelText = children
        }
      }
    } else {
      reelText = values[value]
    }
  }

  return reelText || emptyText
}

const mergeObjects = (...objects) => {
  const newObj = {}

  objects.forEach(obj => {
    if (Object(obj) === obj) {
      Object.keys(obj).forEach(key => {
        const value = obj[key]
        if (value) {
          if (typeof value === 'object' && Object(value) === value && !value.$$typeof) {
            newObj[key] = mergeObjects(newObj[key] || {}, value)
          } else {
            newObj[key] = value
          }
        }
      })
    }
  })

  return newObj
}

const isPartiesEntity = field => field && (field.type === 'company' || field.type === 'person')


const parrallelToSequentialFactory = () => {
  let previousPromise = Promise.resolve()
  return asyncCallback => {
    previousPromise = previousPromise.then(asyncCallback)
    return previousPromise
  }
}


/** ******************************************************************
 *                  Date helper functions
 ****************************************************************** */
const dateFormat = 'DD.MM.YYYY'
const dateFormatDB = 'YYYY-MM-DD'
const timeFormatDB = 'HH:mm'

const parseDate = date => {
  if (date) {
    let mmDate = moment(date, dateFormatDB)
    if (mmDate.isValid()) {
      return mmDate
    }
    mmDate = moment(date)
    if (mmDate.isValid()) {
      return mmDate
    }
  }
  return undefined
}

const parseTime = date => {
  if (date) {
    let mmDate = moment(date, timeFormatDB)
    if (mmDate.isValid()) {
      return mmDate
    }
    mmDate = moment(date)
    if (mmDate.isValid()) {
      return mmDate
    }
  }
  return undefined
}

const displayDate = date => parseDate(date).format(dateFormat)
const displayTime = time => parseTime(time).format(timeFormatDB)


export {
  QUILL_CHAR,
  employeesOptions,
  industryOptions,
  SECTION_PREFIX,
  ANNEXE_PREFIX,
  ANNEXE_PART_NAME,
  timeMapping,
  buildKey,
  mergeValues,
  getElem,
  getValue,
  getConditionalTextValue,
  checkSection,
  parseTemplateEntitiesInHtml,
  parrallelToSequentialFactory,
  generateCompanyEntity,
  generateUserEntity,
  fillTemplateEntities,
  mergeObjects,
  isPartiesEntity,

  dateFormat,
  dateFormatDB,
  timeFormatDB,
  parseDate,
  parseTime,
  displayDate,
  displayTime
}
