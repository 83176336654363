import React, { useContext, useMemo, useState } from 'react'
import { t, Trans } from '@lingui/macro'
import { Button, Dropdown, Menu, Select } from 'antd'
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons'
import { UserAndCompanyDataContext } from '../../Layouts/Constants'
import { UserAvatar, UserFullName, userFullName } from '../../Organisations/UserRoleDisplay/UserAvatarList'
import { useHighlight } from '../../Listing/SearchHelpers'

import './InputUsersAndTeamsStyles.scss'

interface InputUsersAndTeamsProps {
  value?: string[] //                             Current selected IDs (users or teams)
  onChange?: (selection: string[]) => void //     Change handler with list updated
  selectOnly?: boolean //                         We can choose that the component render the selected entities or just fire onChange
  personsOnly?: boolean //                        We can choose if we want to enable only user selection and not teams
  placeholder?: React.ReactNode //                The placeholder text of the input (has default value)
  autoFocus?: boolean //                          We can choose to make the focus of the element at mount
  onBlur?: () => void //                          We can also add a blur event to do extra actions (like removing the component)
}

const UserCard: React.FC<{ userID: string, removeSelection: (key: string) => void }> = ({ userID, removeSelection }) => {
  const { company: { members, teams, organisationID } } = useContext(UserAndCompanyDataContext)
  let data
  if (userID === '__Admin') {
    data = { name: <Trans>Managers</Trans> }
  } else if (userID === organisationID) {
    data = { name: <Trans>Company wide</Trans> }
  } else {
    data = teams[userID] || members[userID]
  }

  const moreButton = (
    <Dropdown
      overlay={(
        <Menu key='menu'>
          <Menu.Item className='dangerMenuItem' onClick={() => removeSelection(userID)}>
            <DeleteOutlined />
            <span><Trans>Remove selection</Trans></span>
          </Menu.Item>
        </Menu>
      )}
      placement='bottomRight'
      trigger={['click']}
    >
      <Button
        className='noBorder'
        ghost
        icon={<MoreOutlined />}
      />
    </Dropdown>
  )

  if (!data) {
    return null
  }

  if (data.name) {
    return (
      <div className='userCard'>
        <UserAvatar size='2.1rem' user={data} />
        <div className='text'>
          <h5>{data.name}</h5>
          <p><Trans>Any team members</Trans></p>
        </div>
        {moreButton}
      </div>
    )
  }

  const team = teams[data.organisationRole.team] || data.organisationRole.team
  return (
    <div className='userCard'>
      <UserAvatar size='2.1rem' user={data} />
      <div className='text'>
        <h5>{userFullName(data)}</h5>
        {team && (
          team === '__Admin' ? <p><Trans>Managers</Trans></p> : <p>{team.name}</p>
        )}
        {!team && <p>-</p>}
      </div>
      {moreButton}
    </div>
  )
}

const trueFunc = () => true
const emptyArray = []
const InputUsersAndTeams: React.FC<InputUsersAndTeamsProps> = ({
  value = emptyArray,
  onChange,
  selectOnly,
  personsOnly,
  autoFocus,
  onBlur,
  placeholder = <Trans>Add person or team</Trans>
}) => {
  const { company: { members, teams, organisationID } } = useContext(UserAndCompanyDataContext)
  const allTeams = useMemo(() => ({
    ...teams,
    __Admin: { userID: '__Admin', name: t`Managers` },
    [organisationID]: { userID: organisationID, name: t`Other company members` }
  }), [organisationID, teams])
  const teamsArray = useMemo(() => Object.entries<any>(allTeams), [allTeams])
  let userArray = useMemo(() => Object.entries<any>(members), [members])

  const internalValue = useMemo(() => {
    const selectedTeams = {}
    const selectedUsers = {}

    if (value) {
      value.forEach(key => {
        if (key.startsWith('team-') || key === '__Admin' || key === organisationID) {
          selectedTeams[key] = true
        } else {
          selectedUsers[key] = true
        }
      })
      Object.keys(selectedUsers).forEach(key => {
        if (selectedTeams[members[key].organisationRole.team]) {
          delete selectedUsers[key]
        }
      })
    }

    return { selectedTeams, selectedUsers }
  }, [members, organisationID, value])

  const [search, setSearch] = useState('')
  const Highlight = useHighlight(search)
  const searchArray = search.toLowerCase().split(' ')

  const renderUsers = teamID => {
    const selected: any[] = []
    const keep: [string, any][] = []

    userArray.forEach(([userID, user]) => {
      if (!internalValue.selectedUsers[userID]) {
        const team = allTeams[user.organisationRole.team] || allTeams[organisationID]
        if (team.userID === teamID) {
          if (search) {
            const name = (`${user.firstName} ${user.lastName}`).toLowerCase()
            if (!searchArray.some(str => name.includes(str))) {
              return
            }
          }
          selected.push(
            <Select.Option key={userID} value={userID}>
              <UserAvatar size='1.5rem' user={user} /> <span><UserFullName Highlight={Highlight} userID={userID} /></span>
            </Select.Option>
          )
        } else {
          keep.push([userID, user])
        }
      }
    })

    userArray = keep
    return selected
  }

  const renderTeam = (teamID, team) => {
    const users = renderUsers(teamID)

    if (internalValue.selectedTeams[teamID]) {
      return null
    }

    if ((personsOnly || search) && users.length === 0) {
      const name = typeof team.name === 'string' ? team.name.toLowerCase() : ''

      if (personsOnly || !searchArray.some(str => name.includes(str))) {
        return null
      }
    }

    return (
      <Select.OptGroup key={teamID} label={<span><UserAvatar size='1.5rem' user={team} /> <span><Highlight text={team.name} /></span></span>}>
        {!personsOnly && (
          <Select.Option value={teamID}>
            <UserAvatar size='1.5rem' user={team} /> <Trans>Any team members</Trans>
          </Select.Option>
        )}
        {users}
      </Select.OptGroup>
    )
  }

  const removeSelection = key => {
    delete internalValue.selectedUsers[key]
    delete internalValue.selectedTeams[key]
    onChange?.([...Object.keys(internalValue.selectedTeams), ...Object.keys(internalValue.selectedUsers)])
  }

  return (
    <div className='inputUsersAndTeams'>
      <Select
        autoFocus={autoFocus}
        className='simple-line'
        defaultOpen={autoFocus}
        filterOption={trueFunc}
        onBlur={onBlur}
        onChange={(key: string) => {
          //  Select only send the selected key to the parent
          if (selectOnly) {
            onChange?.([key])
            return
          }

          if (key.startsWith('team-') || key === '__Admin' || key === organisationID) {
            internalValue.selectedTeams[key] = true
          } else {
            internalValue.selectedUsers[key] = true
          }
          Object.keys(internalValue.selectedUsers).forEach(key2 => {
            if (internalValue.selectedTeams[members[key2].organisationRole.team]) {
              delete internalValue.selectedUsers[key2]
            }
          })
          onChange?.([...Object.keys(internalValue.selectedTeams), ...Object.keys(internalValue.selectedUsers)])
        }}
        onSearch={setSearch}
        placeholder={placeholder}
        searchValue={search}
        showSearch
        value={null as any}
      >
        {teamsArray.map(([teamID, team]) => renderTeam(teamID, team))}
      </Select>
      {!selectOnly && (
        <div className='selectedList'>
          {Object.keys(internalValue.selectedTeams).map(key => (
            <UserCard key={key} removeSelection={removeSelection} userID={key} />
          ))}
          {Object.keys(internalValue.selectedUsers).map(key => (
            <UserCard key={key} removeSelection={removeSelection} userID={key} />
          ))}
        </div>
      )}
    </div>
  )
}

export default InputUsersAndTeams
