/* eslint-disable @typescript-eslint/no-empty-function,class-methods-use-this,no-await-in-loop */

import { ConditionalText, InputField, Section, Template } from '@top-legal/datastore'
import { getSlateContent, iterateTemplateEntities, EditorContent } from '@top-legal/editor'
import incrementWithGlobalFields from '../incrementWithGlobalFields/incrementWithGlobalFields'

export type EntityType = 'inputField' | 'conditionalText' | 'sectionReference'

export interface Getters {
  //  Section needs to have yDoc string
  getSections: (sectionIDs: string[]) => Promise<Map<string, Section>>
  getFields: (fieldIDs: string[]) => Promise<Map<string, InputField>>
  getConds: (condIDs: string[]) => Promise<Map<string, ConditionalText>>
}


class TemplateContentVisitor {
  sectionIDs: string[] = []

  sectionsMap: Map<string, Section> = new Map<string, Section>()

  fieldsMap: Map<string, InputField> = new Map<string, InputField>()

  condsMap: Map<string, ConditionalText> = new Map<string, ConditionalText>()

  missingFields: Record<string, boolean> = {}

  missingConds: Record<string, boolean> = {}

  knownEntities: Record<string, boolean> = {}

  getters: Getters

  force: boolean

  constructor (getters: Getters, force = false) {
    this.getters = getters
    this.force = force
  }

  //  This part loop over the template structure to get the sectionIDs to fetch and provide the sectionsMap
  async prepareSectionData ({ header, sections, footer, annexes }: Template): Promise<void> {
    this.sectionIDs = []
    this.sectionsMap = new Map<string, Section>()
    this.fieldsMap = new Map<string, InputField>()
    this.condsMap = new Map<string, ConditionalText>()

    const known: Record<string, boolean> = {}

    const addSectionID = sectionID => {
      if (sectionID && !known[sectionID]) {
        this.sectionIDs.push(sectionID)
        known[sectionID] = true
      }
    }

    const processArray = (items: any[]) => items.forEach(({ sectionID, subSections }) => {
      addSectionID(sectionID)
      if (Array.isArray(subSections)) {
        processArray(subSections)
      }
    })

    addSectionID(header)
    if (Array.isArray(sections)) {
      processArray(sections)
    }
    addSectionID(footer)
    if (Array.isArray(annexes)) {
      processArray(annexes)
    }

    if (this.sectionIDs.length > 0) {
      this.sectionsMap = await this.getters.getSections(this.sectionIDs)

      if (this.sectionsMap.size !== this.sectionIDs.length) {
        console.error('Miss some sections try again')
        await new Promise(resolve => { setTimeout(resolve, 200) })

        this.sectionsMap = await this.getters.getSections(this.sectionIDs)

        //  We dont have all the sections
        if ((this.sectionIDs.length - this.sectionsMap.size) / this.sectionIDs.length && !this.force) {
          console.error('Cannot load sections of the template', this.sectionIDs, this.sectionsMap && Array.from(this.sectionsMap.entries()))
          throw new Error('Cannot load the sections of the template')
        }
      }
    }
  }

  //  This part loop over the sections after they have been fetched and find the entities they depend on
  processSections (template: Template) {
    const known: Record<string, null | Section> = {}

    const visitSectionID = sectionID => {
      if (known[sectionID] === undefined) {
        const section = this.sectionsMap.get(sectionID)
        if (section) {
          const res = this.visitSection(section)
          known[sectionID] = res === undefined ? section : res
        } else { // istanbul ignore next
          known[sectionID] = null
        }
      }
      return known[sectionID]?.sectionID
    }

    const processArray = (items: any[]) => {
      const indicesToDelete: number[] = []

      items.forEach((item, index) => {
        item.sectionID = visitSectionID(item.sectionID)
        if (item.sectionID) {
          if (Array.isArray(item.subSections)) {
            processArray(item.subSections)
          }
        } else {
          indicesToDelete.unshift(index)
        }
      })

      indicesToDelete.forEach(index => items.splice(index, 1))
    }

    template.header = visitSectionID(template.header)
    if (Array.isArray(template.sections)) {
      processArray(template.sections)
    }
    template.footer = visitSectionID(template.footer)
    if (Array.isArray(template.annexes)) {
      processArray(template.annexes)
    }
  }

  //  This part gonna loop over the sections entities dependencies and recursively on the entities
  //  to fetch all the needed template entities used by this template (use batch get as much as possible)
  async processEntities (template: Template) {
    let condsIDs = Object.keys(this.missingConds)
    let fieldsIDs = Object.keys(this.missingFields)

    const unknownFields: string[] = []

    //  Loop till we got everything
    while (condsIDs.length > 0 || fieldsIDs.length > 0) {
      //  Reset missing content
      this.missingConds = {}
      this.missingFields = {}

      //  Then process the conds
      if (condsIDs.length > 0) {
        const map: Map<string, ConditionalText> = await this.getters.getConds(condsIDs)

        condsIDs.forEach(condID => {
          const cond = map.get(condID)
          if (condID && cond) {
            this.condsMap.set(condID, cond)
            this.visitCond(cond)
          } else {
            console.warn('Conditional not found', condID)
          }
        })
      }


      //  And process the fields
      if (fieldsIDs.length > 0) {
        const map: Map<string, InputField> = await this.getters.getFields(fieldsIDs)

        fieldsIDs.forEach(fieldID => {
          const field = map.get(fieldID)
          if (fieldID && field) {
            this.fieldsMap.set(fieldID, field)

            //  Parties fields needs to be incremented so add for later
            if (field.type === 'person' || field.type === 'company') {
              unknownFields.push(fieldID)
            } else {
              this.visitField(field)
            }
          } else {
            unknownFields.push(fieldID)
          }
        })
      }


      //  Reset missing arrays
      fieldsIDs = Object.keys(this.missingFields)
      condsIDs = Object.keys(this.missingConds)
    }

    incrementWithGlobalFields(this.fieldsMap, template.lang)

    //  Process unknown fields that might be from global fields
    unknownFields.forEach(fieldID => {
      const field = this.fieldsMap.get(fieldID)
      if (fieldID && field) {
        this.fieldsMap.set(fieldID, field)
        this.visitField(field)
      } else {
        console.warn('Field not found', fieldID)
      }
    })
  }


  //


  //  Now we define a collection of visitor to affect our walk over the template via subclasses
  async visitTemplate (template: Template): Promise<void | Template> {
    this.knownEntities = {}
    await this.prepareSectionData(template)
    this.processSections(template)
    await this.processEntities(template)
  }

  visitSection (section: Section): void | null | Section {
    if (section.isDynamicSection && section.field) {
      const res = this.visitEntitiesRef('inputField', section.field)
      if (typeof res === 'string') {
        section.field = res
      } else if (res === null || Object(res) === res) {
        delete section.field
        delete section.isDynamicSection
      }
    }

    //  Iterate over the section content if any
    if (section.yDoc) {
      this.visitSectionYDoc(section, this.sectionYDocToContent(section))
    }
  }

  sectionYDocToContent (section: Section): EditorContent {
    if (section.yDoc) { return getSlateContent(section.yDoc) }
    return []
  }

  visitSectionYDoc (section: Section, content: EditorContent) {
    this.visitSlateContent(content)
  }

  visitSlateContent (slateNodes: EditorContent): void {
    //  This function already take care of the replacement
    iterateTemplateEntities(slateNodes, this.visitEntitiesRef.bind(this))
  }

  visitEntitiesRef (entityType: EntityType, key: string): void | null | string | EditorContent[0] {
    if (entityType === 'inputField') {
      const [baseKey] = key.split('.')
      if (!this.knownEntities[baseKey]) {
        this.knownEntities[baseKey] = true
        this.missingFields[baseKey] = true
      }
    } else if (entityType === 'conditionalText') {
      if (!this.knownEntities[key]) {
        this.knownEntities[key] = true
        this.missingConds[key] = true
      }
    }
  }

  visitCond (cond: ConditionalText): void | null | ConditionalText {
    const res = this.visitEntitiesRef('inputField', cond.field)
    if (res === null || Object(res) === res) { // istanbul ignore next
      console.error('Conditional text must have a field ref', cond, res)
      throw new Error('Conditional text must have a field ref')
    } else if (typeof res === 'string') {
      cond.field = res
    }

    if (Object(cond.values) === cond.values) {
      Object.entries<any>(cond.values).forEach(([key, value]) => {
        const text = value?.text || value
        if (Array.isArray(text)) {
          this.visitCondValue(key, text)
        }
      })
    }
  }

  visitCondValue (key: string, value: EditorContent) {
    this.visitSlateContent(value)
  }

  visitField (field: InputField): void | null | InputField {
    if (field.previousListField) {
      const res = this.visitEntitiesRef('inputField', field.previousListField)
      if (res === null || Object(res) === res) { // istanbul ignore next
        console.error('Combined list previousListField can only be a field ref', field, res)
        throw new Error('Combined list previousListField can only be a field ref')
      } else if (typeof res === 'string') {
        field.previousListField = res
      }
    }
    if (Array.isArray(field.formattedText)) {
      this.visitSlateContent(field.formattedText)
    }
  }
}

export default TemplateContentVisitor
