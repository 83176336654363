import { RxReplicationStateBase } from 'rxdb/plugins/replication'
import { RxCollection, RxDatabase } from 'rxdb/plugins/core'

interface BatchWriter {
  dispatch: (item: any) => void
  write: () => Promise<void>
}

const batchWrite = (writer: (docs: any[]) => Promise<any>): BatchWriter => {
  let prePromise = Promise.resolve()
  let arr: any[] = []
  let docs

  // eslint-disable-next-line no-return-assign
  const write = () => (prePromise = prePromise.then(async () => {
    (docs = arr) && (arr = []) // eslint-disable-line
    if (docs.length > 0) {
      await writer(docs)
    }
  }))

  return { dispatch: (item: any) => arr.push(item), write }
}


class DatabaseWriters {
  private readonly writers: { [key: string]: RxReplicationStateBase<any> } = {}

  private readonly batchWriters: { [key: string]: BatchWriter } = {}

  constructor(
    public readonly database: RxDatabase,
    public readonly collections: { [key: string]: RxCollection },
  ) {
    //  Bind all writers for each collection
    Object.entries(this.collections).forEach(([key, collection]) => {
      this.writers[key] = new RxReplicationStateBase<any>(`__DB_WRITER_${key}__`, collection)

      //  Make a unique guard for parties
      if (key === 'parties') {
        let map = {}
        let docs
        let promise = Promise.resolve()
        this.batchWriters[key] = {
          dispatch: item => {
            map[item.partyID] = item
          },
          // eslint-disable-next-line no-return-assign
          write: () => (promise = promise.then(async () => {
            // eslint-disable-next-line no-unused-expressions
            (docs = Object.values(map)) && (map = {})
            if (docs.length > 0) {
              await this.write(key, docs)
            }
          }))
        }
      } else {
        this.batchWriters[key] = batchWrite(docs => this.write(key, docs))
      }
    })
  }

  async write<T>(key: string, docs: T | T[]) {
    if (!this.writers[key]) {
      throw new Error(`Collection not found for '${key}'`)
    }

    //  Support both single object and array of object
    if (!Array.isArray(docs)) {
      docs = [docs]
    }

    try {
      if (process.env.NODE_ENV === 'development') {
        console.info('Write', docs.length, key)
      }

      //  Lokijs need a _deleted to be a boolean https://github.com/pubkey/rxdb/issues/3670
      docs.forEach((doc: any) => {
        doc._deleted = !!doc.deleted
      })

      await this.writers[key].handleDocumentsFromRemote(docs)
    } catch (err: any) {
      console.error('Failed to write', docs.length, key, err.message)

      const arr = (docs as any)
      if (!arr.__RETRY__) {
        arr.__RETRY__ = true
        console.info('Retry saving')
        await (new Promise(resolve => {
          setTimeout(() => resolve(this.write(key, arr)), 200)
        }))
      } else if (process.env.NODE_ENV === 'development') {
        const win = window as any
        if (!win.erroredData) {
          win.erroredData = []
        }
        console.info('Data added to window.erroredData size', win.erroredData.push(docs))
      }
    }
  }

  dispatchItem<T>(key: string, item: T) {
    if (!this.batchWriters[key]) {
      throw new Error(`Collection not found for '${key}'`)
    }
    this.batchWriters[key].dispatch(item)
  }

  async dispatchWrite(key: string) {
    if (!this.batchWriters[key]) {
      throw new Error(`Collection not found for '${key}'`)
    }
    await this.batchWriters[key].write()
  }
}

export default DatabaseWriters
