//  Rename entity to implement

import { ContractParty, Signature, SignaturesHolder } from './Types'

interface GettersSetters {
  //  Getters
  getEntityDetail: (entityID: string) => Promise<any>

  //  Setters
  saveParties: (parties: Record<string, ContractParty>) => Promise<void>
  saveSignature?: (signature: Signature, party: ContractParty) => Promise<void>
  deleteSignature?: (signature: Signature, party: ContractParty) => Promise<void>
  saveSignaturesHolder?: (holder: SignaturesHolder) => Promise<void>
}

interface TokenData {
  token: string
  email: string
  inviteText: string
}

interface Helpers {
  addNewParty: (partyID: string, signing: boolean) => Promise<string>
  addInviteInfo: (partyPath: string, tokenData: TokenData, getSignature?: (partyID: string) => Promise<string>) => Promise<void>
  switchSigning: (partyPath: string, signing: boolean) => Promise<void>
}

export const nestedPartyCharSeparator = '§$§'

const getSignatureHelpers = (contract: any, gettersSetters: GettersSetters): Helpers => {
  if (!contract || typeof contract.contractID !== 'string' || !contract.contractID || contract.contractID === '__preview__') {
    throw new Error('Provided contract is not a right instance')
  }
  if (contract.contractStatus === 'signed') {
    throw new Error('The contract is fully signed and cannot be modified')
  }

  let parties = contract.parties as Record<string, ContractParty>
  if (Object(parties) !== parties) {
    parties = {}
    contract.parties = parties
  }


  /** *****************************************************************
   *                Add a new party helper
   ***************************************************************** */
  const addNewParty: Helpers['addNewParty'] = async (partyID, signing) => {
    const user = await gettersSetters.getEntityDetail(partyID)

    let partyKey = ''
    let partyType: ContractParty['type'] = 'person'

    //  If the counterparty is belong to a company
    if (user.companyPartyID) {
      const company = await gettersSetters.getEntityDetail(user.companyPartyID)
      partyKey = company.name
      partyType = 'company'
    } else if (user.currentSelectedOrganisationID) { // Else if it is a registered user
      const company = await gettersSetters.getEntityDetail(user.currentSelectedOrganisationID)
      partyKey = company.name
      partyType = 'company'
    } else { // Otherwise use the user fullname as key if available
      const name = user.firstName && user.lastName && `${user.firstName} ${user.lastName}`
      if (name && !parties[name]) {
        partyKey = name
      }
    }

    //  If no party key found let's build one
    if (!partyKey) {
      let counter = 1
      do {
        partyKey = `Party ${counter}`
        counter += 1
      } while (parties[partyKey])
    }

    //  Update the signature party key
    const party: ContractParty = {
      partyID, signer: signing, type: partyType, entityName: partyKey
    }

    //  Add the new party
    let partyPath = partyKey
    if (parties[partyKey]) {
      //  Check first that this is this party
      if (parties[partyKey].partyID === partyID) {
        parties[partyKey].signer = signing
      } else {
        if (!Array.isArray(parties[partyKey].additionalUsers)) {
          parties[partyKey].additionalUsers = []
        }

        const arr = parties[partyKey].additionalUsers
        if (arr) {
          const found = arr.find(elm => elm.partyID === partyID)
          if (found) {
            found.signer = signing
          } else {
            arr.push(party)
            partyPath += `${nestedPartyCharSeparator}${arr.length - 1}`
          }
        }
      }
    } else {
      parties[partyKey] = party
    }

    await gettersSetters.saveParties(parties)

    //  Return the path of the new party
    return partyPath
  }


  //  Throw an error with some logs
  const error = (partyPath: string) => {
    console.error('Cannot update party for contract', contract.contractID, parties, partyPath)
    throw new Error(`Cannot find the party '${partyPath}' in the contract '${contract.contractID}'`)
  }

  const getParty = (partyPath: string): [ContractParty, string] => {
    const [mainPartyKey, indexStr] = partyPath.split(nestedPartyCharSeparator)
    const mainParty = parties[mainPartyKey]

    //  Update the party
    let party = mainParty
    if (indexStr) {
      party = mainParty?.additionalUsers?.[indexStr]
    }
    if (!party) { error(partyPath) }

    return [party, mainPartyKey]
  }


  /** *****************************************************************
   *                Switch a party signing helper
   ***************************************************************** */
  const addInviteInfo: Helpers['addInviteInfo'] = async (partyPath, tokenData, getSignatureID) => {
    const [party] = getParty(partyPath)

    //  Update that party with invite info
    party.token = tokenData.token
    party.inviteEmail = tokenData.email
    party.inviteText = tokenData.inviteText
    party.signatureID = (party.signer !== false && getSignatureID) ? (await getSignatureID(party.partyID)) : ''

    //  Save the parties
    await gettersSetters.saveParties(parties)
  }


  /** *****************************************************************
   *                Switch a party signing helper
   ***************************************************************** */
  const switchSigning: Helpers['switchSigning'] = async (partyPath, signing) => {
    const [party] = getParty(partyPath)
    party.signer = signing

    await gettersSetters.saveParties(parties)
  }


  /** *****************************************************************
   *                Return our helpers
   ***************************************************************** */
  return { addNewParty, addInviteInfo, switchSigning }
}

export default getSignatureHelpers
