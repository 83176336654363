import React, { useCallback, useContext, useMemo } from 'react'
import { Trans } from '@lingui/macro'
import { Tooltip } from 'antd'

import { isInlineField } from '../../../core'
import { TemplateEntityProps } from '../../../core/customPlugins/TemplateEntities'
import { renderProductsToSlate } from '../../../core/slateRenderers/ProductFieldRendering'

import EditorContractContext from '../../yjsEditor/EditorContractContext'
import RenderOnlyEditor from '../../RenderOnlyEditor'

import SelectedEntity from '../SelectedEntity'
import RecursiveTerminationContext from './RecursiveTerminationContext'


/** ******************************************************************
 *            Render special field listOfFormattedText
 ****************************************************************** */
const key = '__listOfFormattedText__'
const keys = [key]
const SafetyFormattedList = ({ children }) => {
  const recursion = useContext(RecursiveTerminationContext)

  if (recursion.includes(key)) {
    return (
      <Tooltip title={<Trans>The playbook presents a circular configuration. A combined list cannot be inside another combined list.</Trans>}>
        <span className='invalidTemplateEntity' contentEditable={false}>
          /!\ <Trans>Self-referring logic</Trans> /!\
        </span>
      </Tooltip>
    )
  }

  return (
    <RecursiveTerminationContext.Provider value={keys}>
      {children}
    </RecursiveTerminationContext.Provider>
  )
}

export const CombinedListItem: React.FC<{ field: any, values: any }> = ({ field, values }) => {
  const { ProvideFieldsResponseScope } = useContext(EditorContractContext)

  return (
    <div>
      <ProvideFieldsResponseScope scope={values}>
        <RenderOnlyEditor nodes={field.formattedText} />
      </ProvideFieldsResponseScope>
    </div>
  )
}


/** ******************************************************************
 *            InputField rendering
 ****************************************************************** */
const InputField: React.FC<TemplateEntityProps> = ({ dataKey, attributes, children, version }) => {
  const { useEntityGetters, useOnTemplateEntityClick, currentField, contractLang } = useContext(EditorContractContext)
  const { getField, getFieldValue } = useEntityGetters()
  const onTemplateEntityClick = useOnTemplateEntityClick()

  /**
   * Setup all variables use memo for avoiding too many updates
   */
  const field = useMemo(() => getField(dataKey, version), [dataKey, getField, version])
  const fieldValue = useMemo(() => getFieldValue(dataKey), [dataKey, getFieldValue])

  const className = useMemo<string>(
    () => {
      const classes: string[] = [attributes.className || '']
      if (currentField && currentField.key === dataKey) {
        classes.push('blink')
      }
      if (isInlineField(field)) {
        classes.push('inlineElement')
      } else {
        classes.push('blockElement')
      }
      if (field?.type === 'product') {
        classes.push('fullWidthEntity')
      }
      return classes.join(' ')
    },
    [attributes.className, currentField, dataKey, field]
  )

  /**
   * On click handler
   */
  const onClick = useCallback(() => onTemplateEntityClick('inputField', dataKey), [dataKey, onTemplateEntityClick])
  const Elem = isInlineField(field) ? 'span' : 'div'

  /**
   * Return a memoized content
   */
  const content = useMemo(() => {
    if (field) {
      //  Product & Service field
      if (field.type === 'product' || field.type === 'productTable') {
        const table = (Array.isArray(fieldValue) && fieldValue[0]) || renderProductsToSlate([field as any], undefined, contractLang)
        if (!table) {
          return null
        }
        return <RenderOnlyEditor nodes={[table]} />
      }

      if (fieldValue != null) {
        //  If it's a combined list return more specific things
        if (field.type === 'listOfFormattedText') {
          return (
            <SafetyFormattedList>
              {fieldValue.map((values, index) => <CombinedListItem key={index} field={field} values={values} />)}
            </SafetyFormattedList>
          )
        }
        return typeof fieldValue === 'string' ? fieldValue : <RenderOnlyEditor nodes={fieldValue} />
      }

      //  Else render display the name
      return field.name
    }

    //  The ultimate case is a invalid field key
    return <span className='invalidTemplateEntity'>/!\ <Trans>Invalid field!</Trans> /!\</span>
  }, [field, fieldValue, contractLang])

  return (
    <SelectedEntity {...attributes} Elem={Elem} className={className} onClick={onClick}>
      {children}
      {content}
      {children}
    </SelectedEntity>
  )
}

export default InputField
