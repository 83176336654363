import moment, { Moment } from 'moment'

moment.suppressDeprecationWarnings = true

/** ******************************************************************
 *                  Date/Time helpers
 ****************************************************************** */
export const dateFormat = 'DD.MM.YYYY'
export const dateFormatDB = 'YYYY-MM-DD'
export const timeFormatDB = 'HH:mm'

export const timeMapping = {
  minutes: 1000 * 60,
  hours: 1000 * 60 * 60,
  days: 1000 * 60 * 60 * 24,
  months: 1000 * 60 * 60 * 24 * 30,
  years: 1000 * 60 * 60 * 24 * 365
}

export const parseDate = (date: any): Moment | undefined => {
  if (date) {
    let mmDate = moment.utc(date)
    if (mmDate.isValid()) {
      return mmDate
    }
    mmDate = moment(date)
    if (mmDate.isValid()) {
      return mmDate
    }
  }
  return undefined
}

function valueOfTime (this: Moment) {
  return ((((this.hours() * 60) + this.minutes()) * 60) + this.seconds()) * 1000
}

export const parseTime = (date: any): Moment | undefined => {
  if (date) {
    let mmDate = moment(date, timeFormatDB)
    if (!mmDate.isValid()) {
      mmDate = moment(date)
      if (!mmDate.isValid()) {
        return undefined
      }
    }
    mmDate.valueOf = valueOfTime
    return mmDate
  }
  return undefined
}

export const displayDate = (date: any): string => parseDate(date)?.format(dateFormat) || ''
export const displayTime = (time: any): string => parseTime(time)?.format(timeFormatDB) || ''
