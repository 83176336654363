import React from 'react'

import { AssistantInputProps } from './Types'

import DateFieldInput from './DateFieldInput'
import YesNoFieldInput from './YesNoFieldInput'
import NumberFieldInput from './NumberFieldInput'
import ListFieldInput from './ListFieldInput'
import TimeFieldInput from './TimeFieldInput'
import SingleTextFieldInput from './SingleTextFieldInput'
import MultiTextFieldInput from './MultiTextFieldInput'
import AmountFieldInput from './AmountFieldInput'
import PhoneFieldInput from './PhoneFieldInput'
import CombinedListFieldInput from './CombinedListFieldInput'
import ProductFieldInput from './ProductFieldInput'
import PartiesFieldInput from './PartiesFieldInput'

import FieldError from './FieldError'
import PersonFullNameInput from './PersonFullNameInput'


const fieldInputsMapping: Record<string, React.FC<AssistantInputProps>> = {
  yesNo: YesNoFieldInput,
  oneLineText: SingleTextFieldInput,
  multipleLineText: MultiTextFieldInput,
  number: NumberFieldInput,
  amount: AmountFieldInput,
  phone: PhoneFieldInput,
  date: DateFieldInput,
  time: TimeFieldInput,
  list: ListFieldInput,
  listOfFormattedText: CombinedListFieldInput,
  productTable: ProductFieldInput,
  company: PartiesFieldInput,
  person: PartiesFieldInput,
  full_name: PersonFullNameInput
}

const AssistantFieldInput: React.FC<AssistantInputProps> = props => {
  const FieldInput = fieldInputsMapping[props.field.type]

  if (FieldInput) {
    return <FieldInput {...props} />
  }

  //  istanbul ignore next
  if (process.env.NODE_ENV !== 'production') {
    if (process.env.NODE_ENV === 'development') {
      console.error('Cannot find a FieldInput for this field', props.field)
    }
    return <FieldError>Cannot find rendering for {props.field.inputFieldID} [{props.field.type}]</FieldError>
  }

  //  istanbul ignore next
  return null
}

export default AssistantFieldInput
