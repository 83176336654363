import React, { useContext } from 'react'
import { useParams } from 'react-router'

import { TemplateContext } from '../../../Contexts'
import { MediaCard } from '../../../../../Media/MediaLibrary'

export const ContractDefaultIntroVideo: React.FC = () => {
  const { lang } = useParams()

  return (
    <div className='vimeoVideo'>
      <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
        <iframe
          allow='autoplay; fullscreen'
          allowFullScreen frameBorder='0'
          src={lang === 'de' ? 'https://player.vimeo.com/video/393957884' : 'https://player.vimeo.com/video/393934669'}
          style={{
            position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'
          }}
          title='Introduction Video top.legal'
        />
      </div>
    </div>
  )
}
export const ContractIntroVideo: React.FC = () => {
  const { template } = useContext<any>(TemplateContext)

  return template.introVideo ? <MediaCard media={template.introVideo} viewerOnly /> : <ContractDefaultIntroVideo />
}
