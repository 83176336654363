import { InputField } from '@top-legal/datastore'
import { isEmpty, renderProductsToSlate } from '@top-legal/editor'
import { displayDate, displayTime, parseDate, parseTime } from '@top-legal/form-components'


const productsToTable = (products: InputField[], quantities: any, lang: string) => {
  const table = renderProductsToSlate(products as any, quantities, lang)
  return table ? [table] : undefined
}

const numberFormatter = new Intl.NumberFormat('de-DE')
const amountFormatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })


type FieldFormatter = (val: any, textFormatted: boolean, field: InputField, lang: string, fields: Map<string, InputField>, responses: Record<string, any>) => any
const fieldsFormatters: Record<string, FieldFormatter> = {
  date: (date, textFormatted) => {
    if (textFormatted) {
      return displayDate(date)
    }
    return parseDate(date)
  },
  time: (time, textFormatted) => {
    if (textFormatted) {
      return displayTime(time)
    }
    return parseTime(time)
  },
  number: (value, textFormatted, field) => {
    if (textFormatted && typeof value === 'number') {
      if ((field as any).unit) {
        return `${numberFormatter.format(value)} ${(field as any).unit}`
      }
      return numberFormatter.format(value)
    }
    return value
  },
  amount: (value, textFormatted) => ((textFormatted && typeof value === 'number') ? amountFormatter.format(value) : value),
  productTable: (value, textFormatted, field, lang, fields) => {
    value = (value && value.__products) || value
    if (textFormatted && Array.isArray(value)) {
      const products: InputField[] = []
      const quantities: any = {}

      value.forEach(({ fieldKey: pdt, qty }, index) => {
        const pdtField = fields.get(pdt)
        if (pdtField) {
          products.push(pdtField)
          if (qty != null) {
            quantities[index] = qty
          }
        }
      })

      return productsToTable(products, quantities, lang)
    }
    return value
  },
  product: (value, textFormatted, field, lang) => productsToTable([field], value, lang),
  listOfFormattedText: (value, textFormatted, field, lang, fields, responses) => {
    let currentKey = field.inputFieldID
    let currentField: InputField | undefined = field
    while (currentField?.previousListField) {
      currentKey = currentField.previousListField
      currentField = fields.get(currentKey)
    }
    value = responses[currentKey]

    if (Array.isArray(value)) {
      //  We cannot produce a formatted display for that one because it needs entities replacement
      return value
    }
    return undefined
  },
  list: (value, textFormatted, field, lang) => {
    //  Some fields today are translated { en: { ... }, de: { ... } }
    const fieldValues = field.values[lang] || field.values

    if (field.isMultipleChoices) {
      if (Array.isArray(value)) {
        if (textFormatted) {
          const children: any = []

          value.forEach(fieldValue => {
            const val = fieldValues[fieldValue]
            if (val && !isEmpty(val)) {
              children.push({ type: 'li', children: [{ type: 'p', children: [{ text: val }] }] })
            }
          })

          if (children.length > 0) {
            return [{ type: 'ul', children }]
          }
        }
        return value
      }
      return undefined
    }

    if (typeof value !== 'string') {
      return undefined
    }

    return textFormatted ? fieldValues[value] : value
  }
}


// =================================================================================


// =================================================================================


const getFieldValue = (fields: Map<string, InputField>, fieldKey: string, responses: Record<string, any>, textFormatted: boolean, lang = 'de'): [any, InputField] | undefined => {
  const [baseKey, ...rest] = fieldKey.split('.')
  let field = fields.get(baseKey)

  //  Reroute Contract parties field to the right child
  if (field && (field.type === 'company' || field.type === 'person')) {
    let child: any = field
    let next: any

    //  Get access to the children
    while (rest.length > 0) {
      next = rest.shift()
      child = child?.items?.[next]
    }

    field = child
    responses = responses[baseKey]
  }

  //  Process the current field
  if (field) {
    //  If the field has nested types
    const fieldNested = field as any
    if (typeof fieldNested.value === 'function') {
      return [fieldNested.value(responses, textFormatted, lang), fieldNested]
    }

    const response = responses?.[field.inputFieldID]

    //  Use a field formatter if it exists
    const formatter = fieldsFormatters[field.type]
    if (formatter) {
      return [
        formatter(response, textFormatted, field, lang, fields, responses),
        field
      ]
    }

    //  Return by default the value
    return [response, field]
  }

  return undefined
}

export default getFieldValue
