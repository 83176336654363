import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { Trans } from '@lingui/macro'
import { UploadOutlined } from '@ant-design/icons'

import { Button } from 'antd'
import { ContractContext } from './ContractEditor/Contexts'
import { SidebarToolboxes } from './ContractEditor/Sidebar/Sidebar'

import ContractLayout, { IdPrefixContext } from './ContractDiplay/ContractLayout'
import { PdfContractTableOfContent } from './ContractEditor/TableOfContent/TableOfContentV2'
import ContractEditorComponent from './ContractEditor/ContractEditorComponent'
import MultiFileUploader from '../SharedComponents/FormComponents/MultiFileUploader'
import InfoBox from '../Alert/InfoBox'
import { PdfContract } from './ContractEditor/ContractAdditionalDocuments'
import SignatureStampList from './ContractEditor/Signatures/SignatureStampList'
import UserManagementToolbox from './ContractEditor/Sidebar/SidebarToolboxes/UserManagement/ContractUsersManagementToolbox'
import { prepareContractLoadingModal } from './ContractPageContext'
import { pdfDocumentOptions } from '../Media/Pdf'
import CommentsToolbox from './ContractEditor/Sidebar/SidebarToolboxes/Commenting/CommentsToolbox'
import ChatToolbox from './ContractEditor/Sidebar/SidebarToolboxes/ChatToolbox'


/** *******************************************************************
 *                Upload toolbox
 ******************************************************************* */
interface PromiseContextProps {
  createPromise?: Promise<void>
  setIsUpload: (bool: boolean) => void
}

const PromiseContext = React.createContext<PromiseContextProps>(undefined as any)
const UploadToolbox: React.FC = () => {
  const { createPromise, setIsUpload } = useContext(PromiseContext)
  return (
    <>
      <Button
        block
        className='addSigningPartiesBtn'
        disabled={!createPromise}
        onClick={async () => {
          try {
            if (createPromise) {
              if (!(createPromise as any).done) {
                await prepareContractLoadingModal(createPromise)
              } else {
                await createPromise
              }
              setIsUpload(false)
            }
          } catch (err) {
            console.error('Cannot start a pdf signing contract', err)
            throw err
          }
        }}
        size='large'
        type='primary'
      >
        <Trans>Add signing parties</Trans>
      </Button>
      <InfoBox>
        <Trans>Please drag & drop the .pdf file on the left that you would like to sign.</Trans>
      </InfoBox>
    </>
  )
}
const uploadToolboxConfig = {
  key: 'uploadToolbox',
  className: 'uploadToolbox',
  text: <Trans>Upload Contract</Trans>,
  icon: <UploadOutlined />,
  // eslint-disable-next-line react/display-name
  Component: UploadToolbox
}


const ContractPdfPageInner: React.FC<{ url: string }> = ({ url }) => {
  const idPrefix = useContext<string>(IdPrefixContext)

  return (
    <div id={`${idPrefix}pdfSigningFile`}>
      <PdfContract noDownload url={url} />
    </div>
  )
}


//  ===============================================================================================


//  ===============================================================================================


/** *******************************************************************
 *                Pdf contract page
 ******************************************************************* */
const ContractPdfPage: React.FC = () => {
  const { contract, updateContract } = useContext<any>(ContractContext)

  useLayoutEffect(() => {
    document.body.classList.add('noBottomMarginContract')
    return () => document.body.classList.remove('noBottomMarginContract')
  }, [])

  //  Initiate a contract name
  useEffect(() => {
    if (!contract.contractName) {
      updateContract({
        templateID: 'template-blank-pdf-signing',
        pdfSigningMode: true
      }, false)
    }
  }, [updateContract, contract.contractName])


  //  When uploading the main contract
  const [createPromise, setCreatePromise] = useState<Promise<void>>()
  const onFinished = useCallback(([file]) => {
    const promise = updateContract({ pdfSigningFile: file }, true).finally(() => { promise.done = true })
    setCreatePromise(promise)
  }, [updateContract])

  const { signatures, signatureHashes } = contract.signaturesHolder || {}
  const [isUpload, setIsUpload] = useState(Object(contract.pdfSigningFile) !== contract.pdfSigningFile)
  const toolboxContext = useMemo(() => {
    const context: any = {
      defaultOpened: true
    }

    let percent = 0
    if (isUpload) {
      context.toolboxes = [uploadToolboxConfig]
      percent = 50
    } else {
      const userToolbox = UserManagementToolbox(contract.contractID)
      const commentToolbox = CommentsToolbox('contract', contract.contractID)
      commentToolbox.fullSize = false //  It is a pdf then we cannot comment on any sections
      context.toolboxes = [userToolbox, commentToolbox]
      percent = 90
    }

    context.defaultKey = context.toolboxes[0].key
    context.progressPercent = percent
    context.progressText = context.progressText || <Trans>{percent}% to sent</Trans>

    //  Add chat when sent over
    if (contract.sentOverDate) {
      context.toolboxes.push(ChatToolbox(contract.contractID))
    }

    return context
  }, [contract.contractID, contract.sentOverDate, isUpload])

  //  Change progress data
  const toolboxContextFinal = useMemo(() => {
    if (Array.isArray(signatures)) {
      const context = { ...toolboxContext }
      const signed = Object(signatureHashes) === signatureHashes ? Object.keys(signatureHashes).length : 0
      const nbSignatures = signatures.length

      if (signed === nbSignatures) {
        context.progressPercent = 100
        context.progressText = <Trans>Fully signed</Trans>
      } else {
        context.progressPercent = (signed * 100) / nbSignatures
        context.progressText = <Trans>{signed} out {nbSignatures} signed</Trans>
      }
      return context
    }
    return toolboxContext
  }, [signatureHashes, signatures, toolboxContext])

  let content = (
    <ContractEditorComponent>
      <div className='contractPdfUploaderWrapper'>
        <MultiFileUploader
          accept='.pdf'
          onFinished={onFinished}
          title={<Trans>Add a Contract</Trans>}
          useFeedbackModal
          withNames
          withUrls
        />
      </div>
    </ContractEditorComponent>
  )

  const pdfUrl = contract.signaturesHolder?.pdf
  if (pdfUrl) {
    content = <PdfContract url={pdfUrl} />
  } else if (Object(contract.pdfSigningFile) === contract.pdfSigningFile) {
    let innerContent = <ContractPdfPageInner url={contract.pdfSigningFile.url} />
    if (!contract.pdfSigningFile.url) {
      innerContent = pdfDocumentOptions.noData
    }
    content = (
      <ContractEditorComponent>
        {innerContent}
        <div className='templateSignaturesWrapper'>
          <SignatureStampList />
        </div>
      </ContractEditorComponent>
    )
  }

  return (
    <PromiseContext.Provider value={{ createPromise, setIsUpload }}>
      <SidebarToolboxes.Provider value={toolboxContextFinal}>
        <ContractLayout LeftSidebar={PdfContractTableOfContent} className={`contractPdfPage nbToolbox${toolboxContextFinal.toolboxes.length}`} openedLeftSidebar>
          {content}
        </ContractLayout>
      </SidebarToolboxes.Provider>
    </PromiseContext.Provider>
  )
}

export default ContractPdfPage
